import React, { Component } from "react";
import { toast } from "react-toastify";
import * as PaymentServiceAPI from "../../services/payment/PaymentServiceAPI";
import Breadcrumb from "./Breadcrumb";
import SimpleModal from "../../shared/Modal/SimpleModal";
import { Fragment } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Grid, Typography, Link, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import { CheckCircleOutline } from '@mui/icons-material';
import * as APIUrl from '../../services/APIUrl';
import { withRouter } from 'react-router-dom';
const styles = (theme) => ({
  root: {
    height: '100vh',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {

    maxWidth: 800,
    padding: theme.spacing(2),
    textAlign: 'left',
  },
});


class index extends Component {


  state = {
    isLoading: false,
    checked: ['wifi'],
    terms: {}
  };

  componentDidMount = () => {
    const queryParams = new URLSearchParams(this.props.location.search);
    const paramValue = queryParams.get('id');
    if (paramValue) {
      this.fetchAccountDetails(paramValue);
    }else{
     console.log("No Id retrieved.")
    }

  };





  fetchAccountDetails = async (payload) => {

    let url = process.env.REACT_APP_API_BASE_URL + APIUrl.FETCH_TERMS_CONDITIONS + `${payload}`;


    try {
      const response = await fetch(url); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log(result)
      if (result?.status) {
        console.log(result.data)
        this.setState({ terms: result?.data });
      }


    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };






  render() {
    const { classes, } = this.props;
    const { terms } = this.state;
    return (
      <Fragment>
        <div className={classes.root}>
          <Paper elevation={3} style={{ padding: '16px', maxWidth: '700px', margin: 'auto', overflow: "auto" }}>
            <Grid container className={classes.contentContainer}>
              <Typography variant="h4" gutterBottom>
                Terms and Conditions for Pay By Link
              </Typography>

              {/* Add the rest of your content here */}
              {/* ... */}

              <Typography variant="h5" gutterBottom>

                <b> {terms?.property_trading_name} Pay By Link Terms </b>
              </Typography>



              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutline />
                  </ListItemIcon>
                  <ListItemText primary={"This payment is being made to " + terms?.property_trading_name } />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutline />
                  </ListItemIcon>
                  <ListItemText
                    primary="Upon providing card details, the initial payment amount is deducted; if you sign up for recurring revenue, the payment will be taken on set days as described in your agreement."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutline />
                  </ListItemIcon>
                  <ListItemText primary="This payment is for services/goods rendered." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutline />
                  </ListItemIcon>
                  <ListItemText
                    primary={"The legal entity you are paying is registered in England and Wales under the name of " + terms.property_trading_name + " whose address is " + terms.address}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutline />
                  </ListItemIcon>
                  <ListItemText primary={"Only UK law applies to the payments made to " + terms?.property_trading_name  }/>
                </ListItem>
              </List>

              <Typography variant="h5" gutterBottom>

                <b> Our delivery information
                </b>
              </Typography>
              <Typography variant="body2" gutterBottom>
                If you have procured goods, we will ship to your required address and will provide you with a follow-up email with the tracking ID. If you have purchased services, the services will be rendered as per the description.
              </Typography>

              <Typography variant="h5" gutterBottom>

                <b> Our refund and cancellation policy

                </b>
              </Typography>

              <Typography variant="body2" gutterBottom>
                Our refund and cancellation policy can be found on our website.

                <Link style={{ visibility: "hidden" }} href="/terms-and-conditions">here</Link>
              </Typography>

              <Typography variant="h5" gutterBottom>

                <b> Contact information


                </b>
              </Typography>
              <br />
              <Typography style={{  width: "100%" }} variant="body2" gutterBottom>
                You can contact us by using any of the below methods.


                <Link style={{ visibility: "hidden", width: "100%" }} href="/terms-and-conditions">here</Link>
              </Typography>


              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutline />
                  </ListItemIcon>
                  <ListItemText primary={"Post: " + terms?.property_trading_name + " – " + terms?.address} />

                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutline />
                  </ListItemIcon>
                  <ListItemText primary={"Email: " + terms?.email} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutline />
                  </ListItemIcon>
                  <ListItemText primary={"Phone Number: " + terms?.phone_number} />
                </ListItem>

              </List>
              <Typography variant="body2" gutterBottom>
                To view the full terms and conditions, please visit the website.

              </Typography>
            </Grid>
          </Paper>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(index));