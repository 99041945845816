import React, { Component, Fragment, useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Breadcrumb from './Breadcrumb';
import './style.css';
import { FlapperSpinner } from "react-spinners-kit";
import 'react-image-crop/dist/ReactCrop.css'
import MultipleImageCropper from '../../shared/Cropper/MultipleImageCropper';
import * as PaymentServiceAPI from "../../../src/services/payment/PaymentServiceAPI";
import * as SecurityServiceAPI from "../../../src/services/user/SecurityServiceAPI";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import DeleteIcon from '@mui/icons-material/Delete';
import Geocode from "react-geocode";
import IconButton from '@mui/material/IconButton';
import { Grid, Typography, Paper } from '@mui/material';
import {getCurrencySymbol} from "../../config";
import { useSelector } from 'react-redux';
// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyAbquQkju85Ho0VUlMI_TgWP6hUQlzZ7UI");

// set response language. Defaults to english.
Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("EU");

// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.
Geocode.enableDebug();


export default function Content(props) {

    const defaultValues = { property_name: props.state.property_name };

    const { register, handleSubmit, watch, setValue, formState: { errors, isDirty } } = useForm();

    const [able, setAble] = useState(false);

    const [stop, setStop] = useState(false);

    const propertyId = useSelector(state => state.user.propertyId);


    // console.log(watch("property_name"));
    //  console.log(props.state.property_name);
    // console.log(props);
    const onSubmit = (data) => {
        if (data.property_address) {
            Geocode.fromAddress(data.property_address).then(
                (response) => {
                    console.log(response);
                    const { lat, lng } = response.results[0].geometry.location;
                    console.log(lat, lng);
                    props.onSubmitHandler();
                },
                (error) => {
                    toast.warn(
                        'Please enter proper address.');
                    return false;
                    //  console.error(error);
                }
            );
        }

        console.log(data);
    }


    useEffect(() => {
        // Update the document title using the browser API
        setValue("property_name", props.state.property_name);
        setValue("property_trading_name", props.state.property_trading_name);

        setValue("property_address", props.state.property_address);

        setValue("postal_code", props.state.postal_code);
        setValue("property_city", props.state.property_city);


        setValue("postal_coed", props.state.postal_code);
        setValue("property_country", props.state.property_country);

        setValue("currency_code", props.state.currency_code);

        if (props.state.postal_code == '' || props.state.property_name == ''
            || props.state.property_address == '' || props.state.postal_code == '' || props.state.property_city == '' || props.state.property_country == ''
            || props.state.currency_code == ''
        ) {
            setStop(true)
        } else {
            setStop(false)
        }


        const dump = {
            property_id: localStorage.getItem("property_id"),
            username: localStorage.getItem("username")
        }
        fetchAccountDetails(dump);


    });


    const fetchAccountDetails = (payload) => {
        if (payload) {
            PaymentServiceAPI.fetchAccountStatus(payload)
                .then((response) => {
                    if (response.data.status) {
                        //                    console.log(response.data.data.adyen_info)
                        if (response.data.data.account_status == "ACCOUNT COMPLETED") {
                            setAble(true)
                        }
                    }
                })
                .catch((error) => {
                    this.setState({ isLoading: false });
                });
        } else {
            this.setState({ isLoading: false });
        }


    };
    /* 
        const handleDeleteImage = (url) => {
            const payload = {
                property_id: props.propertyId || localStorage.getItem("property_id"),
                cover_image_url: url
            }
            SecurityServiceAPI.deleteCoverImage(payload)
                .then((response) => {
                    if (response.data.status) {
                        toast.success("Image deleted. ");
                        window.location.reload();
                    } else {
                        toast.warn("Couldn't delte the image.  ")
                    }
                })
                .catch((error) => {
                    this.setState({ isLoading: false });
                    toast.warn("Something went wrong. ")
                });
    
        } */



    return (
        <Fragment>
            <div className="Dashboard general_setting_page">
                <div className="col-md-12">
                    <Breadcrumb />
                </div>
                <div className="ms-content-wrapper">
                    {props.state.loading ?
                        <FlapperSpinner size={75} color="#233cad" loading={props.state.loading} /> :
                        <div className="row">
                            <div className="col-xl-6 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-header">
                                        <h6>Business Info</h6>
                                    </div>
                                    <div className="ms-panel-body">
                                        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation clearfix" noValidate>

                                            <div className="form-row">

                                                <div className="col-md-6 ">
                                                    <label htmlFor="validationCustom01">Business Name</label>
                                                    <div className="input-group">
                                                        <input type="text"
                                                            className="form-control"
                                                            id="validationCustom01"
                                                            disabled={able}
                                                            placeholder="Property name"
                                                            {...register("property_name", { required: true })}
                                                            value={props.state.property_name}
                                                            onChange={props.onChangeHandler}
                                                            required />
                                                    </div>
                                                    {errors.property_name && <span style={{ color: "red" }} >This field is required</span>}
                                                </div>

                                                <div className="col-md-6 ">
                                                    <label htmlFor="validationCustom04">Business Trading Name</label>
                                                    <div className="input-group">
                                                        <input type="text"
                                                            className="form-control"
                                                            disabled={able}
                                                            id="validationCustom04"
                                                            placeholder="Property trading name"
                                                            name="property_trading_name"
                                                            {...register("property_trading_name", { required: true })}
                                                            value={props.state.property_trading_name}
                                                            onChange={props.onChangeHandler}
                                                            required />
                                                    </div>
                                                    {errors.property_trading_name && <span style={{ color: "red" }} >This field is required</span>}
                                                </div>

                                                <div className="col-md-12 ">
                                                    <label htmlFor="validationCustom02">Business Address</label>
                                                    <div className="input-group">
                                                        <input type="text"
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            disabled={able}
                                                            placeholder="First Line"
                                                            name="property_address"
                                                            {...register("property_address", { required: true })}
                                                            value={props.state.property_address}
                                                            onChange={props.onChangeHandler}
                                                            required />
                                                    </div>
                                                    {errors.property_address && <span style={{ color: "red" }}  >This field is required</span>}
                                                </div>

                                                <div className="col-md-6 ">
                                                    <label htmlFor="validationCustom05">Postal Code</label>
                                                    <div className="input-group">
                                                        <input type="text"
                                                            className="form-control"
                                                            id="validationCustom05"
                                                            disabled={able}
                                                            name="postal_coed"
                                                            placeholder="123456"
                                                            {...register("postal_coed", { required: true })}
                                                            value={props.state.postal_code}
                                                            onChange={props.onChangeHandler}
                                                            required />
                                                    </div>
                                                    {errors.postal_coed && <span style={{ color: "red" }} >This field is required</span>}
                                                </div>

                                                <div className="col-md-6 ">
                                                    <label htmlFor="validationCustom05">City</label>
                                                    <div className="input-group">
                                                        <input type="text"
                                                            className="form-control"
                                                            id="validationCustom05"
                                                            name="property_city"
                                                            disabled={able}
                                                            placeholder="City"
                                                            {...register("property_city")}
                                                            value={props.state.property_city}
                                                            onChange={props.onChangeHandler}
                                                            required />
                                                    </div>
                                                    {errors.property_city && <span style={{ color: "red" }}  >This field is required</span>}
                                                </div>

                                                <div className="col-md-6 ">
                                                    <label htmlFor="validationCustom04">Country</label>
                                                    <div className="input-group">
                                                        <input type="text"
                                                            className="form-control"
                                                            id="validationCustom05"
                                                            disabled={able}
                                                            name="property_country"
                                                            {...register("property_country", { required: true })}
                                                            value={props.state.property_country}
                                                            onChange={props.onChangeHandler}
                                                            required />
                                                    </div>
                                                    {errors.property_country && <span style={{ color: "red" }}  >This field is required</span>}
                                                </div>

                                                <div className="col-md-6 ">
                                                    <label htmlFor="validationCustom05">Currency</label>
                                                    <div className="input-group">
                                                        <input type="text"
                                                            className="form-control"
                                                            id="validationCustom05"
                                                            name="currency_code"
                                                            disabled={able}
                                                            {...register("currency_code", { required: true })}
                                                            value={props.state.currency_code}
                                                            onChange={props.onChangeHandler}
                                                            required />
                                                    </div>
                                                    {errors.currency_code && <span style={{ color: "red" }}   >This field is required</span>}
                                                </div>

                                                <div className="col-md-6 ">
                                                    <label htmlFor="validationCustom03">Business Type</label>
                                                    <div className="input-group">
                                                        <select className="form-control" id="validationCustom05" disabled={props.state.is_business_type_not_editable} name="business_type" value={props.state.business_type} onChange={props.onChangeHandler}>
                                                            {props.state.business_types.map(businessType =>

                                                                <option key={businessType} value={businessType}>{businessType}</option>
                                                            )}
                                                            <option value="">Select</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 ">
                                                    <label htmlFor="validationCustom05">Contact Name</label>
                                                    <div className="input-group">
                                                        <input type="text"
                                                            className="form-control"
                                                            id="validationCustom05"
                                                            name="contact_name"
                                                            placeholder="Contact name"
                                                            value={props.state.contact_name}
                                                            onChange={props.onChangeHandler}
                                                            required />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 ">
                                                    <label htmlFor="validationCustom05">Contact Number</label>

                                                    <div className="input-group">
                                                        <ReactPhoneInput
                                                            inputExtraProps={{
                                                                name: 'contact_number',
                                                                required: true,
                                                                autoFocus: false,
                                                            }}
                                                            inputStyle={{ width: '99%' }}
                                                            searchPlaceholder="Search Country Code"
                                                            enableSearchField
                                                            country={"gb"}
                                                            // placeholder="9090909090"
                                                            name="contact_number"
                                                            value={props.state.contact_number}
                                                            onChange={props.handleOnChange}
                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-md-6 ">
                                                    <label htmlFor="validationCustom05">Parking Availabity</label>
                                                    <div className="input-group">
                                                        <select className="form-control" name="parking_details" value={props.state.parking_details}
                                                            onChange={props.onChangeHandler}>
                                                            <option value="">Select</option>
                                                            <option value="Parking Available">Yes</option>
                                                            <option value="No Parking Available">No</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 ">
                                                    <label htmlFor="validationCustom05">Cost Approx</label>
                                                    <div className="input-group">
                                                        <div className="input-group-append"> <span className="input-group-text"> {getCurrencySymbol(localStorage.getItem('currency_code'))} </span></div>
                                                        <input type="text"
                                                            className="form-control"
                                                            id="validationCustom05"
                                                            name="cost_estimation"
                                                            placeholder="100"
                                                            value={props.state.cost_estimation}
                                                            onChange={props.onChangeHandler}
                                                            required />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 ">
                                                    <label htmlFor="validationCustom05">No Of People</label>
                                                    <div className="input-group">
                                                        <input type="text"
                                                            className="form-control"
                                                            id="validationCustom05"
                                                            name="no_of_people"
                                                            placeholder="2"
                                                            value={props.state.no_of_people}
                                                            onChange={props.onChangeHandler}
                                                            required />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 ">
                                                    <label htmlFor="validationCustom05">Application Fee</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled
                                                            className="form-control"
                                                            value={props.state.applicationFeeType}
                                                            required />
                                                        <div className="input-group-append"> <span className="input-group-text">%</span></div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 ">
                                                    <label htmlFor="validationCustom05">Rating</label>
                                                    <div className="input-group">
                                                        <input type="number"
                                                            className="form-control"
                                                            id="validationCustom05"
                                                            name="rating"
                                                            placeholder="2"
                                                            min='1'
                                                            max='5'
                                                            maxLength='1'
                                                            value={props.state.rating}
                                                            onChange={props.onChangeHandler}
                                                            required
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 ">
                                                    <label htmlFor="validationCustom05">Property Id</label>
                                                    <div className="input-group">
                                                        <input type="text"
                                                            className="form-control"
                                                            id="propertyId"                                                   
                                                            value={propertyId}
                                                            required
                                                            disabled
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12 ">
                                                    <label htmlFor="validationCustom05">Excerpt</label>
                                                    <div className="input-group">
                                                        <textarea type="text"
                                                            className="form-control"
                                                            name="excerpt"
                                                            row="3"
                                                            value={props.state.excerpt}
                                                            onChange={props.onChangeHandler}
                                                            required ></textarea>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 ">
                                                    <label htmlFor="validationCustom05">Description</label>
                                                    <div className="input-group">
                                                        <textarea type="text"
                                                            className="form-control"
                                                            name="description"
                                                            row="3"
                                                            value={props.state.description}
                                                            onChange={props.onChangeHandler}
                                                            required ></textarea>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="ms-panel-header new">
                                                        <p className="medium">Take Away</p>
                                                        <div className="col-md-6">
                                                            <label className="ms-switch">
                                                                <input type="checkbox" name="is_take_away_active" onChange={() => props.toggleHandler('is_take_away_active')} checked={props.state.is_take_away_active} />
                                                                <span className="ms-switch-slider round" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="ms-panel-header new">
                                                        <p className="medium">Collection</p>
                                                        <div className="col-md-6">
                                                            <label className="ms-switch">
                                                                <input type="checkbox" name="is_collection_active" onChange={() => props.toggleHandler('is_collection_active')} checked={props.state.is_collection_active} />
                                                                <span className="ms-switch-slider round" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="ms-panel-header new">
                                                        <p className="medium">Dine In</p>
                                                        <div className="col-md-6">
                                                            <label className="ms-switch">
                                                                <input type="checkbox" name="is_dine_in_active" checked={props.state.is_dine_in_active}
                                                                    onChange={() => props.toggleHandler('is_dine_in_active')} />
                                                                <span className="ms-switch-slider round" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*           <div className="col-md-6">
                                                    <div className="ms-panel-header new">
                                                        <p className="medium">Table Reservation</p>
                                                        <div className="col-md-6">
                                                            <label className="ms-switch">
                                                                <input type="checkbox" name="is_table_reservation_active" onChange={() => props.toggleHandler('is_table_reservation_active')} checked={props.state.is_table_reservation_active} />
                                                                <span className="ms-switch-slider round" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="ms-panel-header new">
                                                        <p className="medium">Kayana In Park</p>
                                                        <div className="col-md-6">
                                                            <label className="ms-switch">
                                                                <input type="checkbox" name="is_delivery_to_park" onChange={() => props.toggleHandler('is_delivery_to_park')} checked={props.state.is_delivery_to_park} />
                                                                <span className="ms-switch-slider round" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div> */}




                                                <Grid container spacing={3}>
                                                    {/* Image */}


                                                    {/* Multi-Cropper */}
                                                    <Grid item xs={12} md={6}>

                                                        <MultipleImageCropper data={{
                                                            key: 'dine',
                                                            label: 'Upload Dine In Image',
                                                            isMultiple: false,
                                                            getCroppedImages: props.handleDineImageChange
                                                        }}
                                                            height={300}
                                                            width={300}
                                                            disabled={able}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={12} md={6}>

                                                        <MultipleImageCropper data={{
                                                            key: 'takeaway',
                                                            label: 'Upload Takeaway Image',
                                                            isMultiple: false,
                                                            getCroppedImages: props.handleTakeAwayImageChange
                                                        }}
                                                            height={300}
                                                            width={300}
                                                            disabled={able}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={12} md={6}>

                                                        {
                                                            props.state.dine_image ?
                                                                <img className="add-product-images" style={{ width: "300px", height: "300px", objectFit: "contain" }} src={props.state.dine_image} />
                                                                :
                                                                props.state.dine_preview_url ? <img className="add-product-images" style={{ width: "300px", height: "300px", objectFit: "contain" }} src={props.state.dine_preview_url} />
                                                                    : null
                                                        }

                                                    </Grid>
                                                    <Grid item xs={12} md={6}>

                                                        {
                                                            props.state.takeaway_image ?
                                                                <img style={{ width: "300px", height: "300px", objectFit: "contain" }} className="add-product-images" src={props.state.takeaway_image} />
                                                                :
                                                                props.state.takeaway_preview_url ? <img className="add-product-images" style={{ width: "300px", height: "300px", objectFit: "contain" }} src={props.state.takeaway_preview_url} />
                                                                    : null
                                                        }

                                                    </Grid>

                                                    <Grid item xs={12} md={6}>

                                                        <MultipleImageCropper data={{
                                                            key: 'collection',
                                                            label: 'Upload Collection Image',
                                                            isMultiple: false,
                                                            getCroppedImages: props.handleCollectionImageChange
                                                        }}
                                                            height={300}
                                                            width={300}
                                                            disabled={able}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={12} md={6}>

                                                        {
                                                            props.state.collection_image ?
                                                                <img style={{ width: "300px", height: "300px", objectFit: "contain" }} className="add-product-images" src={props.state.collection_image} />
                                                                :
                                                                props.state.collection_preview_url ? <img className="add-product-images" style={{ width: "300px", height: "300px", objectFit: "contain" }} src={props.state.collection_preview_url} />
                                                                    : null
                                                        }

                                                    </Grid>

                                                    {/* Multi-Cropper */}

                                                </Grid>






                                            </div>

                                            <div className="col-md-12">
                                                <button className="btn btn-primary d-block float-right" disabled={stop} type="submit">Update</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-header">
                                        <h6>Logo</h6>
                                    </div>
                                    <div className="ms-panel-body">
                                        {
                                            props.state.logo_image ?
                                                <img className="add-product-images" src={props.state.logo_image} />
                                                :
                                                props.state.logo_preview_url ? <img className="add-product-images" src={props.state.logo_preview_url} />
                                                    : null
                                        }

                                        {/* <button className="btn btn-primary ">Select Logo
                                                <input className="hide_browse"
                                                    accept=".jpeg,.jpg,.png,.svg"
                                                    type="file"
                                                    name="cover"
                                                    onChange={props.handleLogoImageChange}
                                                />
                                            </button> */}

                                        {/* Logo Cropper */}

                                        <br></br>

                                        <MultipleImageCropper data={{
                                            key: 'logo',
                                            label: 'Upload Logo',
                                            isMultiple: false,
                                            getCroppedImages: props.handleLogoImageChange
                                        }}
                                            height={300}
                                            width={300}
                                            disabled={able}
                                        />



                                        {/* <ReactCrop
                                                crop={this.state.crop}
                                                src={props.state.logo_preview_url}
                                                onImageLoaded={this.onImageLoaded}
                                                onCom
                                                plete={this.onCropComplete}
                                                onChange={this.handleOnCrop}
                                            /> */}

                                        <h6 className="mt-5">Cover Image</h6>
                                        {(props.state.cover_images || props.fileObj) && (
                                            <React.Fragment>
                                                <Carousel id="imagesSlider" className="ms-image-slider carousel slide" data-ride="carousel" indicators={false}>
                                                    {(props.fileObj ? props.fileObj : []).concat(props.state.cover_images).map((url, i) => (
                                                        <Carousel.Item key={i}>
                                                            {url && <IconButton style={{ float: "right" }} onClick={() => props.handleDeleteImage(url)} aria-label="Delete">
                                                                <DeleteIcon />
                                                            </IconButton>}
                                                            <img className="add-product-images" src={url} />
                                                        </Carousel.Item>
                                                    ))}
                                                </Carousel>
                                            </React.Fragment>
                                        )}


                                        {/* <button className="btn btn-primary">Select Cover
                                                <input className="hide_browse"
                                                    accept=".jpeg,.jpg,.png,.svg"
                                                    type="file"
                                                    name="cover"
                                                    onChange={props.handleMultipleImageChange}
                                                    multiple
                                                />
                                            </button> */}
                                        {/* Cover Cropper */}
                                        <MultipleImageCropper data={{
                                            key: 'cover',
                                            label: 'Upload Cover Photos',
                                            isMultiple: false,
                                            getCroppedImages: props.handleMultipleImageChange
                                        }}
                                            height={300}
                                            width={300}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>}
                </div>

            </div>
        </Fragment>
    )

}