import React, { Fragment } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import OTP from "../../shared/Otp/OTP";

const ConfirmSignupForm = (props) => {
  return (
    <Fragment>
      <div className="ms-body ms-primary-theme ms-logged-out">
        <main className="body-content">
          <div className="ms-content-wrapper ms-auth">
            <div className="ms-auth-container">
              <div className="ms-auth-col">
                <div className="ms-auth-bg" />
              </div>
              <div className="ms-auth-col">
                <div className="ms-auth-form">
                  <form
                    className="needs-validation"
                    onSubmit={props.confirmSignupHandler}
                    noValidate
                  >
                    <div className="card confirmSignupCardBody ">
                      <div className="card-header">
                        <h3
                          className="roboto-font"
                          style={{
                            fontSize: "30px",
                            marginTop: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          Account Verification
                        </h3>
                        <p className="subheading">
                          To continue please enter the 6 digit code which has
                          been sent to your email
                          <strong>{props.state.email}</strong>{" "}
                        </p>
                      </div>

                      {props.state.loading ? (
                        <div className="signupSpinner cardBody card-body">
                          <FlapperSpinner
                            size={90}
                            color="#233cad"
                            loading={props.state.loading}
                          />
                        </div>
                      ) : (
                        <div className="confirmSignupCard">
                          <TextField
                            error={false}
                            margin="normal"
                            sx={{
                              mb: 2,
                            }}
                            onInput={(event) => {
                              event.target.value = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
                            }}
                            inputProps={{ maxLength: 6 }}
                            required
                            fullWidth
                            type="text"
                            id="otp"
                            label="OTP"
                            name="otp"
                            autoComplete="otp"
                            size="small"
                            value={props.state.otp}
                            onChange={props.onChange}
                            helperText={null}
                          />
                          <div className="col-md-12 ml-0 pl-0 pr-0">
                            <button
                              disabled={props.state.otp?.trim().length == 0 || props.state.otp?.trim().length > 6 || props.state.otp?.trim().length < 6}
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                              Verify OTP
                            </button>
                            <label
                              style={{ float: "right" }}
                              className="d-block mt-3"
                            >
                              <Link
                                className="btn-link"
                                to="#"
                                onClick={props.resendOtp}
                              >
                                Resend OTP
                              </Link>
                            </label>

                            <label
                              style={{ float: "left" }}
                              className="d-block mt-3"
                            >
                              <Link
                                className="btn-link"
                                to="/login" 
                              >
                                Login
                              </Link>
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Fragment>
  );
};

export default ConfirmSignupForm;
