import axios from 'axios';
import * as APIUrl from '../APIUrl';

export const addSuite = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.ADD_SUITE + `${payload.property_id}&suite_name=${payload.suite_name}&description=${payload.description}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}


export const fetchAllSuite = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_ALL_SUITE + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}
export const deleteSuite = (payload) => {
    try {
        return axios({
            method: "put",
            url: APIUrl.DELETE_SUITE + `${payload}`,
        });
    } catch (error) {
        throw new Error(error);
    }
};

export const updateSuite = (payload) => {

    try {
        return axios({
            method: 'put',
            url: APIUrl.UPDATE_SUITE+ `${payload.suite_id}&suite_name=${payload.suite_name}&description=${payload.description}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}


export const fetchAllBookedSuite = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_ALL_BOOKED_SUITE + `${payload.property_id}&start_date=${payload.start_date}&end_date=${payload.end_date}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchAllUnBookedSuite = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_UNBOOKED_SUITE + `${payload.property_id}&date=${payload.date}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const intitiateBooking = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.INTITIATE_BOOKING,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const deleteBooking = (payload) => {

    try {
        return axios({
            method: 'put',
            url: APIUrl.DELETE_BOOKING + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const updateBooking = (payload) => {

    try {
        return axios({
            method: 'put',
            url: APIUrl.UPDATE_BOOKING/*  `${payload.suite_id}&suite_name=${payload.suite_name}&description=${payload.description}`, */,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}


export const getSuiteBookingReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_SUITE_BOOKING_REPORT + `${payload}`,
            responseType: 'blob',
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}