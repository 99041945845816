/* // Backend Connectivity URL
 // Development Environment
export const APIBaseURL =   ENVIRONMENT.apply.retur "http://18.133.81.78:8086/business";

// // Production Environment
// export const APIBaseURL='https://backend.kayana.co.uk/business';
 */

  export const APIBaseURL = process.env.REACT_APP_API_BASE_URL;
  console.log(APIBaseURL);

// Fetch Location-API URL
export const FETCH_LOCATION = "https:/ipapi.co/json/";

// SIGN-IN Service Call URL
export const SIGNIN = "/business/authentication/signin";
export const FORGOT_PASSWORD = "/business/authentication/forgot-password/";
export const CONFIRM_FORGOT_PASSWORD =
  "/business/authentication/confirm-forgot-password/";
export const SIGNUP = "/business/authentication/signup";
export const CONFIRM_SIGNUP = "/business/authentication/confirm-signup";
export const MFA_ENABLED = "/business/authentication/fetch-mfa-details-by-username";
export const FETCH_TERMS_AND_CONDITION = "/business/authentication/fetch-tnc";

export const ADD_USER = "/business/user/create-business-staff";
export const FETCH_USERS = "/business/user/fetch-business-users?propertyId=";
export const UPDATE_USER_STATUS = "/business/user/update-business-user?property_id=";
export const UPDATE_USER = "/business/user/update-business-user?property_id=";
export const RECREATE_USER = "/business/user/recreate-user/";

export const FETCH_TERMS_AND_CONDITION_ACCEPTANCE =
  "/business/property/fetch-business-property-details-by-id?property_id=";
export const UPDATE_TERMS_AND_CONDITION_STATUS =
  "/business/property/update-tnc?tnc_accepatance=";

export const RESEND_OTP = "/business/authentication/resend-otp/";
export const SIGNOUT = "/business/authentication/signout";
export const VERIFY_TOKEN = "/business/authentication/verify-token";
export const UPDATE_EMAIL =
  "/business/authentication/update-business-user-email?new_email=";
export const RESET_PASSWORD = "/business/authentication/reset-password";
export const FETCH_MFA_DETAILS_BY_EMAIL =
  "/business/authentication/fetch-mfa-details-by-email/";
export const NEED_PASSWORD_CHANGE_BY_EMAIL =
  "/business/authentication/need-password-change/";
export const NEED_PASSWORD_CHANGE_UPDATE =
  "/business/authentication/force-password-change-signin";

export const ADD_BUSINESS_PROPERTY = "/business/property/add-business-property";
export const DELETE_BUSINESS_PROPERTY = "/business/property/add-business-property";
export const DELETE_COVER_IMAGE = "/business/property/delete-cover-image?property_id=";
export const FETCH_BUSINESS_PROPERTY =
  "/business/property/fetch-business-property-details/";
export const UPDATE_BUSINESS_PROPERTY = "/business/property/update-business-property";

export const UPDATE_OWN_DELIVERY_STATUS =
  "/business/order/update-own-delivery-status?order-id=";


export const ADD_STORE = "/business/adyen/create-store?property_id=";

export const CREATE_BUSINESS_LINE = "/business/adyen/create-business-line";

export const UPDATE_BUSINESS_LINE = "/business/adyen/update-business-line";

export const ADD_LEGAL_ENTITY = "/business/adyen/create-legal-entity";

export const UPDATE_LEGAL_ENTITY = "/business/adyen/update-legal-entity";

export const ADD_ACCOUNT_HOLDER = "/business/adyen/create-account-holder";

export const UPDATE_ACCOUNT_HOLDER = "/business/adyen/update-account-holder";

export const CREATE_BALANCE_ACCOUNT = "/business/adyen/create-balance-account";

export const UPDATE_BALANCE_ACCOUNT = "/business/adyen/update-balance-account";

export const FETCH_LEGAL_ENTITY ="/business/adyen/fetch-legal-entity/?property_id=";

export const CREATE_ADYEN_ONBOARDING_LINK ="/business/adyen/legal-entity/";

export const FETCH_AYDEN_STATUS = "/business/setup?step=";

export const VERIFY_EMAIL = "/business/authentication/verify-business-user-email?otp=";

export const ADD_PROFILE_DETAILS = "/business/profile/upload-profile-data";
export const UPDATE_PROFILE_DETAILS = "/business/profile/upload-profile-data";
export const UPDATE_PROFILE_PHOTO = "/business/profile/upload-profile-data";
export const DELETE_ADMIN_PROFILE_PHOTO = "/business/profile/delete-profile-photo";
export const UPLOAD_PROFILE_PHOTO = "/business/profile/upload-profile-photo";
export const UPLOAD_COVER_PHOTO = "/business/profile/upload-cover-photo";
export const FETCH_PROFILE_DETAILS = "/business/profile/fetch-profile-details/";
export const UPDATE_SUPER_ADMIN_PROFILE_DETAILS =
  "/business/profile/upload-profile-data";
export const UPDATE_SUPER_ADMIN_PROFILE_PHOTO = "/business/profile/upload-profile-photo";
export const DELETE_SUPER_ADMIN_PROFILE_PHOTO = "/business/profile/delete-profile-photo";
export const FETCH_SUPER_ADMIN_PROFILE_DETAILS =
  "/business/profile/fetch-profile-details/";

export const FETCH_BUSINESS_TYPE = "/business/property/fetch-all-business-type";
export const FETCH_LATITUDE_AND_LONGITUDE_BY_ADDRESS =
  "/business/property/fetch-longitude-and-latitude-by-address?address=";
export const FETCH_ALL_CURRENCIES = "/business/property/fetch-all-currencies";
export const FETCH_GOOGLE_API_KEY = "/business/google/fetch-google-api-key";

export const ADD_MENU = "/business/menu/add-menu";
export const UPDATE_MENU = "/business/menu/update-menu";
export const FETCH_ALL_MENU = "/business/menu/fetch-all-menu?property_id=";
export const DELETE_MENU = "/business/menu/delete-menu?menu_id=";
export const FETCH_MENU_NAMES = "/business/menu/fetch-all-menu-names?property_id=";

export const ADD_CATEGORY = "/business/category/add-category";
export const FETCH_CATEGORY = "/business/category/fetch-all-categories?menu_id=";
export const DELETE_CATEGORY = "/business/category/delete-category?category_id=";
export const UPDATE_CATEGORY = "/business/category/update-category";
export const FETCH_CATEGORY_NAMES =
  "/business/category/fetch-all-category-names?menu_id=";

export const ADD_ITEM = "/business/item/add-item";
export const UPDATE_ITEM = "/business/item/update-item";
export const GENERATE_BARCODE = "/business/item/generate-barcode";
export const DELETE_BAR_CODE = "/business/item/delete-barcode?item_id=";
export const FETCH_ITEM = "/business/item/fetch-all-item?category_id=";
export const FETCH_COMBO_STATUS = "/business/combo/item/is-combo?item_id=";
export const DELETE_ITEM = "/business/item/delete-item?item_id=";
export const FETCH_ITEM_BY_ITEM_ID = "/business/item/fetch-item-by-item-id?item_id=";
export const FETCH_ADDONS_BY_ITEMID = "/business/addon/fetch-all-addons?item_id=";

// Addon & Extras
export const ADD_OPTIONS_EXTRAS = "/business/addon/add-addon";
export const ADD_COMBO_ITEMS = "/business/combo/item";
export const UPDATE_COMBO_ITEM = "/business/combo/item";
export const DELETE_COMBO_ITEM = "/business/combo/item?combo_group_id=";
export const DELETE_OPTIONS_EXTRAS = "/business/addon/delete-addon?addon_id=";
export const UPDATE_OPTIONS_EXTRAS = "/business/addon/update-addon";
export const FETCH_ADDON_BY_ADDONID =
  "/business/addon/fetch-addon-by-addon-id?addon_id=";
export const FETCH_COMBO_ITEM_BY_ID =
  "/business/combo/item/fetch-combo-item?combo_group_id=";

export const FETCH_ORDER = "/business/order/fetch-orders?page=";
export const SEARCH_ORDER = "/business/order/filter-orders?page=";
export const FETCH_TODAYS_ORDER = "/business/order/fetch-todays-orders?page=";
export const FETCH_ORDER_DETAILS_BY_ORDER_ID =
  "/business/order/fetch-order-item-details?order_id=";
export const UPDATE_ORDER_STATUS = "/business/order/refund?order-id=";
export const UPDATE_PARTIAL_ORDER_STATUS = "/business/order/partial-refund?order_id=";
export const REFUND_ORDER_SLIP = "/report/order/refund-letter?order_id=";
export const UPDATE_ORDER_ITEM_STATUS =
  "/business/order/update-order-item-status?order-id=";
export const FETCH_BUSINESS_TIMING =
  "/business/timing/fetch-business-timings?property_id=";
export const FILTER_ORDER = "/business/order/filter-orders?page=";
export const FILTER_ORDER_BY_DATE = "/business/order/filter-orders-by-date?page=";

export const UPDATE_DELIVERY_TIMING = "/business/timing/update-delivery-timings";
export const UPDATE_DINE_IN_TIMING = "/business/timing/update-dine-in-timings";
export const UPDATE_TAKEAWAY_TIMING = "/business/timing/update-take-away-timings";
export const UPDATE_PROPERTY_TIMING = "/business/timing/update-property-timings";

export const CREATE_PAYMENT_ACCOUNT = "/business/account/create-account";
export const FETCH_ACCOUNT_DETAILS = "/business/account/fetch-account?property_id=";
export const FETCH_ACCOUNT_SETTINGS = "/business/setting/fetch?property_id=";
export const FETCH_FRANCHISE_INFO = "/business/property/fetch-franchises?property_id=";
export const FETCH_VENDOR_INFO = "/business/property/fetch-vendors?property_id=";
export const FETCH_SPLIT_PAYMENTS = "/business/property/fetch-split-payments-by-id?property_id=";
export const UPDATE_SPLIT_PAYMENTS = "business/property/update-split-payments";
export const FETCH_ACCOUNT_ID = "/business/property/fetch-account-id/";
export const UPDATE_BANK_DETAILS = "/business/account/update-bank-details";
export const UPDATE_COMPANY_DETAILS = "/business/account/update-company-details";
export const UPDATE_PERSONAL_DETAILS = "/business/account/create-person-with-documents";
export const UPDATE_TERMS_AND_CONDITION =
  "/account/update-tos-acceptance?property_id=";
  export const UPDATE_RECEIPT ="/business/setting/add-reciept-note?property_id=";
  export const FETCH_RECEIPT ="/business/setting/fetch-reciept-note?property_id=";
export const FETCH_MCC_MERCHANT_CATEGORY_CODE = "/business/account/fetch-merchant-codes";
export const FETCH_STRIPE_TERMS_AND_CONDITION =
  "/business/account/fetch-tos-details?country_code=";

export const FETCH_PAYOUT_DETAILS = "/business/account/fetch-payout-details?payout_id=";
export const FETCH_PAYOUT = "/business/account/fetch-payouts?property_id=";
export const FILTER_TRANSACTIONS_BY_DATE = "/business/report/fetch-transactions?page=";
export const FILTER_TRANSACTIONS_BY_SEARCH = "/business/report/filter-transactions?page=";
export const FETCH_TRANSACTIONS = "/business/report/fetch-transactions?property_id=";
export const FETCH_TRANSACTIONS_DETAILS = "/business/report/fetch-transaction-details?transaction_id=";
export const FETCH_PAYOUT_REPORT = "/report/business/invoice?payout_id=";
export const FETCH_ORDER_DETAILS_BY_PAYOUT_ID =
  "/business/account/fetch-payment-details?payment_id=";
export const UPDATE_TAX_DETAILS = "/business/property/update-tax-details";


export const FETCH_ALL_NOTIFICATION = "/business/profile/fetch-all-notification?page=";
export const PUSH_ORDER_NOTIFICATION_TO_USER =
  "/business/notification/notify-user-on-order-update";
export const FETCH_ORDER_MESSAGES = "/business/order/fetch-order-messages";
export const UPDATE_NOTIFICATION_SETTING =
  "/business/notification/update-notification-settings";

export const FETCH_LOGIN_SESSION = "/authentication/fetch-login-sessions/";
export const UPLOAD_FEATURED_IMAGE = "/business/property/add-preferred-image-or-video";
export const DUPLICATE_ITEM = "/business/item/copy-item?item_id=";
export const DUPLICATE_ADDON = "/business/addon/copy-addon?source_addon_id=";

export const GET_NOTIFICATION_SETTING =
  "/business/notification/get-notification-settings?property_id=";

export const FETCH_CATEGORY_DETAILS = "/business/filter/fetch-category-details";
export const ADD_ITEM_FILTER = "/business/filter/add-item-filter";
export const UPDATE_ITEM_FILTER = "/business/filter/update-item-filter";
export const FETCH_ITEM_FILTERS = "/business/filter/fetch-item-filters?item_id=";
export const FETCH_ITEM_TAGS = "/business/tag";


export const ADD_ADDON_CATEGORY = "/business/addon/add-category";
export const ADD_COMBO = "/business/combo/group";
export const DELETE_ADDON_CATEGORY =
  "/business/addon/delete-category?addon_category_id=";
  export const DELETE_COMBO_GROUP =
  "/business/combo/group?combo_group_id=";
export const FETCH_ALL_ADDON_CATEGORY = "/business/addon/fetch-all-categories?item_id=";
export const FETCH_ALL_COMBOS= "/business/combo/group?item_id=";
export const FETCH_ALL_COMBO_ITEMS = "/business/combo/item/fetch-all-combo-groups-and-items?item_id=";
export const UPDATE_ADDON_CATEGORY = "/business/addon/update-category";
export const UPDATE_COMBO = "/business/combo/group";



export const FETCH_UPSELL_ITEMS = "/business/item/fetch-all-categories-and-items?property_id=";





// DASHBOARD Service Call URL

export const FETCH_DASHBOARD_SUMMARY = "/business/report/fetch-dashboard-data?";


export const FETCH_ORDER_PROCESSONG_TIME = "/business/report/fetch-orders-processing-time?";


export const FETCH_FREQUENTLY_ORDERED_ITEMS = "/business/report/fetch-frequent-ordered-items?";

export const FETCH_SOLD_OUT_ITEMS = "/business/report/fetch-out-of-stock-items?";



//THEME

export const FETCH_ALL_THEMES = "/business/theme/fetch/all/themes";

export const ASSIGN_THEME = "/business/theme/assign-theme?kiosk_theme_id=";

export const FETCH_THEME = "/business/theme/fetch/theme?property_id=";



export const ADD_DISCOUNT = "/business/discount";
export const EDIT_DISCOUNT = "/business/discount/fetch-discount-code?discount_id=";
export const ADD_DISCOUNT_CODE = "/business/discount/code";
export const FETCH_ALL_DISCOUNTS = "/business/discount?property_id=";
export const DELETE_DISCOUNT ="/business/discount?discount_id=";
export const UPDATE_DISCOUNT= "/business/discount";



export const FETCH_ALL_HAPPY_HOUR_DISCOUNTS = "/business/activity/discount/find-all?property_id=";
export const ADD_HAPPY_HOUR_DISCOUNT = "/business/activity/discount/add";
export const UPDATE_HAPPY_HOUR_DISCOUNT = "/business/activity/discount/update";
export const DELETE_HAPPY_HOUR_DISCOUNT ="/business/activity/discount/delete?activity_id=";
export const RETRIEVE_HAPPY_HOUR_DISCOUNT_ITEMS ="/business/activity/discount/retrieve-non-discounted-items?property_id=";
export const FIND_HAPPY_HOUR_DISCOUNT ="/business/activity/discount/find-by-id?activity_id=";


export const FETCH_ITEMS_REPORT= "/business/sales/total-item-sales-report?end_date=";

export const FETCH_TOTAL_ITEMS_SALES_REPORT= "/business/sales/total-sales-report?end_date=";

export const FETCH_TOP_SELLING_ITEMS_REPORT= "/business/sales/top-selling-item-report?end_date=";

export const FETCH_TRENDING_SALES_ITEM_REPORT= "/business/sales/trending-item-sales-report?end_date=";

export const FETCH_NON_SELLING_ITEMS_REPORT = "/business/sales/non-selling-item-report?end_date=";

export const FETCH_Z_REPORT= "/report/business/z-report?end_date=";

export const FETCH_WWEKLY_BUSINESS_REPORT= "/report/business/fetch-wbReport?end_date=";

export const DOWNLOAD_WWEKLY_BUSINESS_REPORT= "/report/business/download-wbReport?end_date=";

export const FETCH_PLU_SALES_REPORT= "/report/business/fetch-pluReport?end_date=";

export const DOWNLOAD_PLU_SALES_REPORT= "/report/business/download-pluReport?end_date=";


export const DOWNLOAD_ACTIVITY_REPORT= "/report/business/activity-report?end_date=";

export const FETCH_ACTIVITY_REPORT  = "/report/fetch-business-activity-report?end_date=";

export const DOWNLOAD_SUMMARY_REPORT= "/report/business/summary-report?end_date=";

export const FETCH_SUMMARY_REPORT= "/report/fetch-business-report?end_date=";

export const FETCH_REPORT= "/report/business/fetch-zreport?end_date=";

export const FETCH_DATA_REPORT= "/report/business/data-entry-report?end_date=";
export const FETCH_EXCEL_DATA_REPORT= "/report/business/download/data-entry-report?end_date=";

export const DOWNLOAD_ITEM_CUSTOMISED_REPORT= "/report/download-item-customised-report?end_date=";

export const FETCH_ITEM_CUSTOMISED_REPORT= "/report/fetch-item-customised-report?end_date=";


export const DOWNLOAD_EXCEL = "/business/order/download-order-csv?end_date=";

export const DOWNLOAD_TRANSACTIONS_CSV = "/business/order/download-transaction-csv?end_date=";

export const ADD_PAYMENT_LINK = "/business/payment-link";
export const FETCH_ALL_PAYMENT_LINKS = "/business/payment-link/fetch-all?property_id=";
export const FETCH_PAYMENT_LINK_TRANSACTION = "/business/payment-link/fetch-pay-by-link-transaction-details?payment_link_id=";
export const REFUND_TRANSACTION_ORDER= "/business/payment-link/refund?reference=";
export const DELETE_PAYMENT_LINKS ="/business/payment-link?payment_link_id=";
export const FETCH_PAYMENT_LINK= "/business/payment-link/fetch-by-payment_link_id?payment_link_id=";
export const CAPTURE_PRE_AUTH = "/business/payment-link/capture-amount?captured_amount=";
export const UPDATE_PRE_AUTH = "/business/payment-link/update-amount?updated_amount=";
export const CANCEL_TRANSACTION = "/business/payment-link/cancel?";




export const ADD_ROOM = "/business/property/add-room-info";
export const EDIT_ROOM = "/business/property/update-room-info-by-id";
export const FETCH_ALL_ROOMS = "/business/property/fetch-room-info?property_id=";
export const DELETE_ROOM ="/business/property/delete-room?room_id=";
export const UPDATE_ROOM= "/business/property/update-room-info";




export const ADD_TABLE = "/business/property/add-table-info";
export const DELETE_TABLE ="/business/property/delete-table?table_id=";
export const UPDATE_TABLE= "/business/property/update-table-info";
export const EDIT_TABLE = "/business/property/update-room-info-by-id";
export const FETCH_TABLE_DETAILS = "/business/property/fetch-requested-table-info?page=";
export const FETCH_VACANT_TABLE_DETAILS = "/business/property/fetch-table-info?property_id=";
export const APPROVE_TABLE_REQUEST = "/businessproperty/approve-table-request/";
export const REJECT_TABLE_REQUEST = "/business/property/reject-table-request/";
export const UPDATE_TABLE_RESERVATION_DETAILS = "/property/update-table-info-by-id";


export const GENERATE_DELIVERY_DYNAMIC_LINK = "/business/dynamic-link/generate-delivery-dynamic-link?property_id=";
export const GENERATE_PROPERTY_DYNAMIC_LINK = "/business/dynamic-link/generate-property-dynamic-link?property_id=";
export const GENERATE_ROOM_DYNAMIC_LINK = "/business/dynamic-link/generate-room-dynamic-link?property_id=";
export const GENERATE_TABLE_DYNAMIC_LINK = "/business/dynamic-link/generate-table-dynamic-link?property_id=";
export const GENERATE_TAKEAWAY_DYNAMIC_LINK = "/business/dynamic-link/generate-takeaway-dynamic-link?property_id=";
export const GENERATE_DINING_DYNAMIC_LINK = "/business/dynamic-link/generate-dinein-dynamic-link?property_id=";





export const FETCH_PLANS_ACTIVE = "/business/qr/fetch-plans-active?property_id=";
export const FETCH_ALL_PLANS = "/business/qr/fetch-plans?property_id=";
export const SELECT_PLAN = "business/qr/select-plan";
export const CANCEL_QR_PLAN = "business/qr/cancel-plan?subscription_id=";

export const FETCH_GRANTS = "/business/grant?property_id=";
export const FETCH_ALL_GRANTS = "/business/grant/fetch-all?property_id=";
export const ACCEPT_GRANT = "/business/grant?property_id=";
export const FETCH_GRANT_REPAYMENTS = "/business/grant/repayments?id=";
export const FETCH_ACTIVE_GRANT = "/business/grant/fetch-active?id=";

export const FETCH_TERMS_CONDITIONS = "/business/property/fetch-details?property_id=";


export const FETCH_TERMS_CONDITIONS_LINK = "business/dynamic-link/generate-terms-and-conditions-link?property_id=";



export const FETCH_ALL_CHARGE = "/business/delivery-charge/fetch-all-charges?property_id="

export const ADD_CHARGE = "/business/delivery-charge/add-charge"


export const UPDATE_CHARGE = "/business/delivery-charge/update-charge"

export const DELETE_CHARGE = "/business/delivery-charge/delete-charge?charge_id="




export const FETCH_WEB_ORDER_TERMS = "/business/web/fetch-amount-terms-and-conditions?property_id=";
export const INTIATE_PAYMENT = "/business/web/initiate-payment";
export const FETCH_WEB_URL = "business/web/fetch-web-url?property_id=";
export const FETCH_NASH_ORDER_TERMS = "/business/nash/fetch-amount-terms-and-conditions?property_id=";
export const FETCH_NASH_PLAN = "/business/nash/fetch-plan?property_id=";
export const FETCH_WEB_PLAN = "/business/web/fetch-plan?property_id=";
export const CANCEL_NASH_PLAN = "/business/nash/cancel-plan?nash_id=";
export const CANCEL_WEB_PLAN = "/business/web/cancel-plan?web_id=";


export const COLLECT_USER_INFO = "/business/interest/add-details";
export const CHECK_USER_INFO  ="/business/interest/fetch-details?username=";


export const FETCH_ALL_SHIFT = "/business/shift?property_id="

export const ADD_SHIFT = "/business/shift/add"

export const UPDATE_SHIFT = "/business/shift/update"

export const DELETE_SHIFT = "/business/shift/delete?shift_id="

export const FETCH_WEB_INVOICE = "/business/invoice/fetch-web-subscription?property_id=";
export const FETCH_QR_INVOICE = "/business/invoice/fetch-qr-subscription?property_id=";
export const FETCH_QR_REPORT = "/report/download/business/qr-subcription/invoice?subscription_id=";
export const FETCH_WEB_REPORT = "/report/download/business/web/invoice?web_id=";
export const APPLY_DISCOUNT = "/business/web/apply-discount?";
export const APPLY_QR_DISCOUNT = "/business/qr/apply-discount?";
export const FETCH_NASH_INVOICE = "business/invoice/fetch-nash-subscription?property_id=";
export const FETCH_NASH_REPORT = "/report/download/nash/invoice?nash_id=";
export const REMOVE_QR_DISCOUNT = "/business/qr/cancel-discount?";
export const REMOVE_DISCOUNT = "/business/web/cancel-discount?";
export const BACK_BUTTON_CALL = "/business/qr/change-upgraded-plan-status?property_id=";

export const DAR_REPORT = "/business/sales/download-total-item-sales-report?";
export const NASH_SUBSCRIBE = "/business/nash/subscribe-plan?";
export const NASH_DISCOUNT = "/business/nash/apply-discount?";
export const REMOVE_NASH_DISCOUNT = "/business/nash/cancel-discount?";


export const FETCH_EPOS_NOW_TERMS = "/business/integration/fetch-amount-terms-and-conditions?property_id=";
export const INTIATE_PAYMENT_EPOS_NOW = "/business/integration/subscribe-plan";
export const APPLY_DISCOUNT_EPOS_NOW = "/business/integration/apply-discount?";
export const REMOVE_DISCOUNT_EPOS_NOW = "/business/integration/cancel-discount?";
export const FETCH_EPOS_PLAN = "/business/integration/fetch-plan?property_id=";
export const CANCEL_EPOS_PLAN = "/business/integration/cancel-plan?integration_id=";
export const FETCH_EPOSNOW_INVOICE = "/business/invoice/fetch-integration-subscription?property_id=";
export const FETCH_EPOSNOW_REPORT = "/report/download/integration/invoice?integration_id=";
export const SAVE_EPOS_NOW = "/business/setting/update-epos-now-settings";
export const FETCH_EPOS_INFO = "/business/setting/fetch?property_id=";



export const SAVE_TIP = "/business/setting/update-tip-settings";

export const FETCH_DISPUTED_ORDERS = "/business/dispute/fetch-all-disputed-orders?property_id=";
export const ACCEPT_DISPUTED_ORDER = "/business/dispute/accept-dispute?order_id=";
export const DEFEND_DISPUTED_ORDER = "/business/dispute/defend-dispute?order_id=";
export const GET_DEFEND_REASONS = "/business/dispute/retrieve-applicable-defense-reasons?order_id=";
export const SUPPLY_DEFEND_DOCS = "/business/dispute/supply-defense-document";


export const SAVE_SCREENSAVER = "/business/setting/update-screensaver-setting";

export const DELTE_SCREENSAVER = "/business/setting/delete-screensaver-image-or-video?property_id=";



export const SAVE_VAT = "/business/setting/update-vat-setting";




export const FETCH_ALL_SUITE = "/business/suite?property_id="

export const ADD_SUITE = "/business/suite/add?property_id="

export const UPDATE_SUITE = "/business/suite/update?suite_id="

export const DELETE_SUITE = "/business/suite/delete?suite_id="


export const FETCH_ALL_BOOKED_SUITE = "/business/suite/fetch-all-suites?property_id=";

export const FETCH_UNBOOKED_SUITE = "/business/suite/fetch-suites?property_id=";



export const INTITIATE_BOOKING = "business/suite/initiate-booking";

export const DELETE_BOOKING = "business/suite/delete-booking?booking_id=";

export const UPDATE_BOOKING = "business/suite/update-booking";


export const FETCH_SUITE_BOOKING_REPORT = "/report/suite/download-suite-summary?order_id=";


// INVENTORY

// STOCK
export const FETCH_ALL_INVENTORY_STOCK_ITEMS = "/business/inventory/stock?property_id=";

export const  ADD_STOCK = "/business/inventory/stock/add";
export const  UPDATE_STOCK = "/business/inventory/stock/update";

export const  DELETE_STOCK = "/business/inventory/stock/delete?item_id=";

export const FETCH_ALL_UNITS = "/business/inventory/stock/units?property_id=";


// BATCH
export const FETCH_ALL_BATCHES = "/business/inventory/batch?stock_id=";


export const  ADD_BATCH = "/business/inventory/batch/add?stock_id=";

export const  UPDATE_BATCH = "/business/inventory/batch/update";

export const  DELETE_BATCH = "/business/inventory/batch/delete?batch_id=";



export const FETCH_INVENTORY_TERMS = "/business/inventory/fetch-amount-terms-and-conditions?property_id=";
export const INTIATE_INVENTORY_PAYMENT =  "/business/inventory/subscribe-plan";
export const FETCH_INVENOTRY_PLAN = "/business/inventory/fetch-plan?property_id=";
export const CANCEL_INVENOTRY_PLAN = "/business/inventory/cancel-plan?integration_id=";
export const APPLY_INVENTORY_DISCOUNT = "/business/inventory/apply-discount?";
export const REMOVE_INVENTORY_DISCOUNT = "/business/inventory/cancel-discount";



export const FETCH_SCREENSAVER_PLAN = "/business/screensaver/fetch-plan?property_id=";
export const FETCH_SCREENSAVER_TERMS = "/business/screensaver/fetch-amount-terms-and-conditions?property_id=";
export const INTIATE_SCREENSAVER_PAYMENT =  "/business/screensaver/subscribe-plan";
export const APPLY_SCREENSAVER_DISCOUNT = "/business/screensaver/apply-discount?";
export const REMOVE_SCREENSAVER_DISCOUNT = "/business/screensaver/cancel-discount";
export const CANCEL_SCREENSAVER_PLAN = "/business/screensaver/cancel-plan?integration_id=";

export const FETCH_TAGS = "/business/tag?property_id=";
export const ADD_TAG = "/business/tag/add?property_id=";
export const UPDATE_TAG = "/business/tag/update?property_id=";
export const DELETE_TAG = "/business/tag/delete?property_id=";


export const FETCH_ALL_REASONS = "/business/reason?property_id=";



export const  ADD_RESAON = "/business/reason/add?property_id=";
export const  UPDATE_RESAON = "/business/reason/update?";
export const  DELETE_REASON = "/business/reason/delete?reason_id=";



export const FETCH_DOG_DOLLAR_CORRECTION_REPORT= "/report/business/dog-dollar-correction-report?end_date=";

export const FETCH_TOTAL_SALES_YEARLY_REPORT= "/report/business/yearly-sale-report?end_date=";

export const FETCH_CHARLIE_EMAIL_REPORT= "/report/business/email-to-charlie-report?end_date=";

export const FETCH_GROSS_SALES_BY_STORE_REPORT = "/report/business/gross-sales-by-store-and-payment-method-report?end_date=";


export const FETCH_PAYMENT_METHOD_REPORT = "/report/business/payment-method-sale-analysis-by-store-report?end_date=";







export const FETCH_DELEVERICT_PLAN = "/business/deliverect/fetch-plan?property_id=";
export const INTIATE_PAYMENT_DELEVERICT = "/business/deliverect/subscribe-plan";
export const FETCH_DELEVIRECT_TERMS = "/business/deliverect/fetch-amount-terms-and-conditions?property_id=";
export const REMOVE_DISCOUNT_DELEVIRECT = "/business/integration/cancel-discount?";
export const APPLY_DELEVIRECT_DISCOUNT = "/business/screensaver/apply-discount?";
export const SAVE_DELIVRECT_NOW = "/business/setting/update-deliverect-settings";




