import React, { Component, Fragment } from "react";
import * as OrderServiceAPI from "../../../../services/order/OrderServiceAPI";
import * as DashboardServiceAPI from "../../../../services/dashboard/DashboardServiceAPI";
import * as ReportServiceAPI from "../../../../services/reports/ReportServiceAPI";
import DataTableComponet from "react-data-table-component";
import Pagination from "../../../../shared/Pagination/Pagination";
import Breadcrumb from "./Breadcrumb";
import "../../../../index.css";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Loader from "../../../../shared/Loader";
import $ from "jquery";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DownloadingIcon from "@mui/icons-material/Downloading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import { Column } from "@ant-design/plots";
import Typography from "@mui/material/Typography";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Checkbox,
  OutlinedInput,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Papa from "papaparse";
import { Grid, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { connect } from "react-redux";
import Alert from "@mui/material/Alert";
import ReactGA from "react-ga";
import { DatePicker, Space } from "antd";
import * as PaymentServiceAPI from "../../../../services/payment/PaymentServiceAPI";

const { RangePicker } = DatePicker;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


class Content extends Component {
  state = {
    changeBool: null,
    from_date: null,
    to_date: null,
    date_options_dd: null,
    isLoading: false,
    isPdfLoading: false,
    count: 0,
    hideFilter: false,
    start: dayjs(new Date()).startOf("week"),
    end: dayjs(new Date()),
    data: null,
    topSellingItems: [],
    trendingItemsSalesReport: [],
    tab: 0,
    max: new Date(),
    min: dayjs(new Date()).subtract(3, "year"),
    zReport: "",
    filterBy: 0,
    propertyId: "",
    filter: 0,
    defaultRange: [dayjs(new Date()).startOf("week"), dayjs()],
    tempRange: [dayjs(new Date()).startOf("week"), dayjs()],
    selectedProperties: [],
    franchises: [],
    open: false,
    dateKeys: []
  };

  constructor(props) {
    super(props);
    // this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setState({
      propertyId: this.props.propertyId,
      selectedProperties: [this.props.propertyId],
    });
    this.fetchFranchiseInformation();
    // this.getTrendingReport();
    //this.getDataReport();

    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#reports").addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#reports").addClass("active");
    }
  };
  handleExportCSV = () => {
    if (this.state.zReport) {
      var link = document.createElement("a");
      link.href = this.state.zReport;
      link.download = "zReport";
      link.click();
    } else {
      toast.warn("No report found.");
    }
  };

  fnCallback = (e) => {
    this.setState({ hideFilter: false, filter: e?.target?.value });
    if (!e?.target?.value) {
      this.filterAllOrders();
    } else if (e?.target?.value == 1) {
      // Today
      let today = dayjs(new Date());

      this.setState(
        {
          start: dayjs().startOf("day"),
          end: today,
          defaultRange: [dayjs().startOf("day"), dayjs()],
        },
        () => {
          //    this.getTrendingSalesReport();
          //    this.getTrendingReport();
        }
      );
    } else if (e?.target?.value == 2) {
      // Yesterday
      // var today = dayjs(new Date()).subtract(1, 'day');
      let yesterday = dayjs(new Date()).subtract(1, "day");

      this.setState(
        {
          start: yesterday,
          end: yesterday,
          defaultRange: [
            dayjs(new Date()).subtract(1, "day").startOf("day"),
            dayjs(new Date()).subtract(1, "day").endOf("day"),
          ],
        },
        () => {
          //    this.getTrendingSalesReport();
          //     this.getTrendingReport();
        }
      );
    } else if (e?.target?.value == 3) {
      // Last 7 Days
      let today = dayjs(new Date()).subtract(1, "week").startOf("week");
      let last7 = dayjs(new Date()).subtract(1, "week").endOf("week");
      this.setState(
        { start: today, end: last7, defaultRange: [today, last7] },
        () => {
          //    this.getTrendingSalesReport();
          //     this.getTrendingReport();
        }
      );
    } else if (e?.target?.value == 4) {
      // Last 30 Days
      let today = dayjs(new Date()).startOf("week");
      let last30 = dayjs(new Date());
      this.setState(
        { start: today, end: last30, defaultRange: [today, last30,] },
        () => {
          //    this.getTrendingSalesReport();
          //      this.getTrendingReport();
        }
      );
    } else if (e?.target?.value == 9) {
      // Last 30 Days
      this.setState({ filterBy: 9, end: this.state.start }, () => {
        //      this.getTrendingSalesReport();
        //    this.getTrendingReport();
      });
    } else if (e?.target?.value == 5) {
      // Last 30 Days
      let today = dayjs(new Date());
      let last30 = dayjs(new Date()).subtract(1, "year").startOf("year");
      if (this.state.selectTimePeriod == "HOURLY") {
        this.setState(
          { start: dayjs(new Date()).startOf("week"), end: dayjs(new Date()) },
          () => {
            //       this.getTrendingSalesReport();
          }
        );
      } else {
        this.setState({ start: last30, end: today }, () => {
          //     this.getTrendingSalesReport();
        });
      }
    } else if (e?.target?.value == 6) {
      // Last 30 Days
      let today = dayjs(new Date());
      let firstDayOfMonth = dayjs(new Date()).startOf("month");

      if (this.state.selectTimePeriod == "HOURLY") {
        this.setState(
          { start: dayjs(new Date()).startOf("week"), end: dayjs(new Date()) },
          () => {
            //      this.getTrendingSalesReport();
          }
        );
      } else {
        this.setState({ start: firstDayOfMonth, end: today }, () => {
          //    this.getTrendingSalesReport();
        });
      }
    } else if (e?.target?.value == 7) {
      // Last 30 Day
      this.setState({ hideFilter: true });
    }
  };

  

  getDataReport = () => {
    this.setState({ isLoading: true });
    const property_id = (this.state.selectedProperties && this.state.selectedProperties.length > 0)
      ? this.state.selectedProperties
      : this.props.propertyId;
    let payload = {
      start_date:
        this.state.filter == 1 || this.state.filter == 2
          ? this.state?.start?.format("DD-MM-YYYY") + " 00:00:00"
          : this.state?.start?.format("DD-MM-YYYY HH:mm:ss"),
      end_date:
        (this.state.filter == 2 &&
          this.state.end?.format("DD-MM-YYYY") + " 23:59:59") ||
        (this.state.filter == 1 &&
          this.state.end?.format("DD-MM-YYYY HH:mm:ss")) ||
        this.state.end?.format("DD-MM-YYYY HH:mm:ss"),
      property_id: property_id,
    };

    ReportServiceAPI.getDogDollarCorrectionReport(payload)
      .then((response) => {
        if (response?.data?.status) {
          this.setState({ isLoading: false, data: response.data.data });
          if (response.data && response.data.data && Object.keys(response.data.data).length === 0) {
            toast.warn("No data retrieved.");
          } 
        } else {
          toast.warn("No data retrieved.");
          this.setState({ isLoading: false, data: null });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        this.setState({ isLoading: false, data: null });
        toast.error(error);
      });
  };


 

  handleTabChange = (event, newValue) => {
    this.setState({ tab: newValue });
  };

  handleToDate = (e) => {
    //       const ctrl_name = e.target?.name;
    let toDat = dayjs(e);
    this.setState({ end: toDat, start: dayjs(toDat).startOf("week") }, () => {
      //  this.getTrendingSalesReport();
      this.getTrendingReport();
    });
  };

  handleFromDate = (e) => {
    //    const ctrl_name = e.target?.name;
    let today = dayjs(e);
    const endOfWeek = dayjs(today).add(7, "day");
    const result = endOfWeek.isAfter(new Date())
      ? dayjs(new Date())
      : endOfWeek;

    if (this.state.filter == 9) {
      this.setState({ end: today, start: today, filter: 9 }, () => {
        //  this.getTrendingSalesReport();
        this.getTrendingReport();
      });
    } else {
      this.setState({ end: result, start: today, filter: 0 }, () => {
        //    this.getTrendingSalesReport();
        this.getTrendingReport();
      });
    }
  };

  handleRangeChange = (value) => {
    // Handle the selected range here
    if (value && value.length === 2) {
      const [startDate, endDate] = value.map((date) =>
        dayjs(date, "DD-MM-YYYY HH:mm:ss")
      );

      this.setState(
        {
          start: startDate,
          end: endDate,
          defaultRange: [startDate, endDate],
          filter: 0,
        },
        () => {
          this.getTrendingReport();
        }
      );
    }
  };

  disabledDate = (current) => {
    // Get the current date and time
  };
  disabledRangeTime = (current, type) => {
    const currentTime = dayjs();
    const startDate = this.state.start; // Assuming this is where you store your start date
    const isEndDateSameAsStart = current && current.isSame(startDate, "day");

    // Disable times before the current time on the selected date
    if (type === "start" && current.isSame(currentTime, "day")) {
      return {
        disabledHours: () => [...Array(currentTime.hour()).keys()],
        disabledMinutes: (selectedHour) => {
          if (selectedHour === currentTime.hour()) {
            return [...Array(currentTime.minute()).keys()];
          }
          return [];
        },
        disabledSeconds: (selectedHour, selectedMinute) => {
          if (
            selectedHour === currentTime.hour() &&
            selectedMinute === currentTime.minute()
          ) {
            return [...Array(currentTime.second()).keys()];
          }
          return [];
        },
      };
    } else if (type === "end" && isEndDateSameAsStart) {
      // Disable times before the selected start time
      const startHour = startDate.hour();
      const startMinute = startDate.minute();
      const startSecond = startDate.second();
      return {
        disabledHours: () => [...Array(startHour).keys()],
        disabledMinutes: (selectedHour) => {
          if (selectedHour === startHour) {
            return [...Array(startMinute).keys()];
          }
          return [];
        },
        disabledSeconds: (selectedHour, selectedMinute) => {
          if (selectedHour === startHour && selectedMinute === startMinute) {
            return [...Array(startSecond).keys()];
          }
          return [];
        },
      };
    }

    return {};
  };

  handlePropChange = (event) => {
    const { value } = event.target;
    this.setState({ selectedProperties: value }, () => {
      //this.getTrendingReport();
    });
  };

  handleZreport = () => {
    // Your handler logic here
    console.log('Z report submitted');
    this.getDataReport();
  };

  fetchFranchiseInformation() {
    const dump = {
      property_id: this.props.propertyId,
    };

    PaymentServiceAPI.fetchFranchiseInfomation(dump)
      .then((response) => {
        if (response.data.status) {
          this.setState({
            franchises: [
              ...response.data.data,
              {
                property_id: this.props.propertyId,
                property_name: this.props.user.user.business_trading_name,
              },
            ],
          });
        } else {
          this.setState({ franchises: [] });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        console.log(error);
        this.setState({ franchises: [] });
      });
  }

  handleOpenChange = (open) => {
    if (!open) {
      // When the picker closes, update the state and trigger the report
      const { tempRange } = this.state;
      if (tempRange?.length === 2) {
        const [startDate, endDate] = tempRange.map((date) =>
          dayjs(date)
        );

        this.setState(
          {
            start: startDate,
            end: endDate,
            defaultRange: [
              startDate,
              endDate,
            ],
            filter: 0,
          },
          () => {
            // this.getTrendingReport();
          }
        );
      } else {
        this.setState({
          start: null,
          end: null,
          defaultRange: [dayjs().startOf('week'), dayjs()],
          filter: 0,
        });
      }
    }
  };

  cleanTag = (tag) => {
    if (typeof tag !== 'string') {
      return '';
    }
    return tag.replace(/^\[|\]$/g, '');
  };

  handleExportCSV = () => {

    const csvString = Papa.unparse(this.state?.data);

    const dataUrl = `data:text/csv;charset=utf-8,${encodeURIComponent(csvString)}`;

    const downloadLink = document.createElement('a');
    downloadLink.href = dataUrl;
    downloadLink.download = 'dataDogDollarCorrection.csv';
    downloadLink.click();
  };

  render() {
    const {
      tab,
      propertyId,
      defaultRange,
      selectedProperties,
      franchises,
      dateKeys,
      data
    } = this.state;


    return (
      <Fragment>
        <Breadcrumb />
        {propertyId ? (
          <React.Fragment>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <AppBar position="static" color="default">
                    <Tabs
                      value={tab}
                      onChange={this.handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      TabIndicatorProps={{ style: { background: "#5773ff" } }}
                    >
                      <Tab label="DogDollar Correction Report" />
                      {/* <Tab label="Unsold Items" /> */}
                    </Tabs>
                    <TabPanel value={tab} index={0} className="tabPanel">
                      <div className="ms-panel">
                        <div className="ms-panel-body">
                          <div className="row">
                            <div className="col-xl-12 col-md-12 col-sm-12">
                              <div className="row m-2">
                                <div className="col-md-2">
                                  <div className="mt-4">
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        Filter By
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label" /* style={{ height: 40 }} */
                                        id="demo-simple-select"
                                        value={this.state.filter}
                                        label="Filter By"
                                        onChange={this.fnCallback}
                                      >
                                        <MenuItem value={0}>
                                          {" "}
                                          Filter By
                                        </MenuItem>
                                        <MenuItem value={1}>Today</MenuItem>
                                        <MenuItem value={2}>Yesterday</MenuItem>
                                        <MenuItem value={4}>This Week</MenuItem>
                                        <MenuItem value={3}>Last Week</MenuItem>
                                        {/*  <MenuItem value={9}>Any Single Day</MenuItem> */}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>



                                <div className="col-md-3">
                                  <div className="mt-4">
                                    <Space style={{}}>
                                      <RangePicker
                                        showTime
                                        id="zReport"
                                        name="zReport"
                                        size={"mini"}
                                        placement={"bottomLeft"}
                                        allowClear={false}
                                        //disabledTime={this.disabledRangeTime}
                                        onOpenChange={this.handleOpenChange}
                                        onCalendarChange={(value, dateStrings) => {
                                          // Handle the selected range here
                                          if (value && value.length === 2) {
                                            this.setState({
                                              tempRange: value,
                                            });
                                          }
                                        }}
                                        changeOnBlur={true}
                                        showOk={false}
                                        format="DD-MM-YYYY"
                                        value={defaultRange}
                                        // onChange={this.handleRangeChange}
                                        style={{ height: "50px" }}
                                      />
                                    </Space>


                                  </div>
                                </div>

                                {this.props.settings.is_master_franchise && (
                                  <div className="col-md-3">

                                    <div className="mt-3">
                                      <FormControl
                                        style={{
                                          margin: "8px",
                                          width: "300px",
                                        }}
                                      >
                                        <InputLabel id="demo-multiple-checkbox-label">
                                          Franchise
                                        </InputLabel>
                                        <Select
                                          labelId="demo-multiple-checkbox-label"
                                          id="demo-multiple-checkbox"
                                          multiple
                                          value={selectedProperties} // Use selectedProperties here
                                          onChange={this.handlePropChange}
                                          input={
                                            <OutlinedInput label="Franchise" />
                                          }
                                          renderValue={(selected) =>
                                            selected
                                              .map((id) => {
                                                const property =
                                                  franchises.find(
                                                    (item) =>
                                                      item.property_id === id
                                                  );
                                                return property
                                                  ? property.property_name
                                                  : "";
                                              })
                                              .join(", ")
                                          }
                                          MenuProps={MenuProps}
                                        >
                                          {franchises.map((item) => (
                                            <MenuItem
                                              key={item.property_id}
                                              value={item.property_id}
                                            >
                                              <Checkbox
                                                checked={
                                                  selectedProperties.indexOf(
                                                    item.property_id
                                                  ) > -1
                                                }
                                              />
                                              <ListItemText
                                                primary={item.property_name}
                                              />
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </div>

                                  </div>
                                )}


                                <div className="col-md-3">
                                  <div className="mt-3" >
                                    <Button type="submit" sx={{ mt: 2 }} variant="contained" onClick={this.handleZreport} color="primary">
                                      GENERATE
                                    </Button>
                                  </div>
                                </div>


                              </div>

                              <div
                                className=""
                                style={{ marginTop: "100px !important" }}
                              >
                                {this.state.isLoading ? (
                                  <div
                                    style={{
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      marginBottom: "40px",
                                    }}
                                  >
                                    {" "}
                                    <Loader size={50} />{" "}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <br></br>
                                    {true && (
                                      <React.Fragment>
                                        {this.state.data?.length > 0 && (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <Button
                                              variant="contained"
                                              style={{ float: "right" }}
                                              color="primary"
                                              onClick={
                                                this.handleExportCSV
                                              }
                                            >
                                              Download Report
                                            </Button>
                                          </div>
                                        )}
                                        <br></br>
                                        {this.state.data?.length > 0 && (
                                        <Paper
                                          elevation={3}
                                          style={{
                                            padding: "20px",
                                            margin: "0px",
                                          }}
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item xs={12} lg={6}>
                                              <Typography variant="body1">
                                                DogDollar Correction Report
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              lg={6}
                                              style={{ textAlign: "right" }}
                                            >
                                              <Typography variant="body1">
                                                {" "}
                                                {data?.generatedDate}
                                              </Typography>
                                            </Grid>
                                            <Grid item lg={4}></Grid>
                                            <Grid
                                              xs={12}
                                              item
                                              lg={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Typography variant="h5">
                                                {" "}
                                                {data?.businessName}
                                              </Typography>
                                              <Typography variant="h5">
                                                {" "}
                                                {data?.businessAdress}
                                              </Typography>
                                            </Grid>
                                            <Grid item lg={4}></Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Divider />
                                            </Grid>

                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              {/*  <Typography variant="h6">
                                                DATA REPORT BREAKDOWN{" "}
                                              </Typography> */}
                                              <Divider />
                                            </Grid>
                                            <TableContainer component={Paper}>
                                              <Table aria-label="simple table" size="small">
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Event</TableCell>
                                                    <TableCell>Dog Dollar Gross</TableCell>
                                                    <TableCell>Dog Dollar Net</TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {data.map((row, index) => (
                                                    <TableRow key={index}>
                                                      <TableCell>{row.date}</TableCell>
                                                      <TableCell>{row?.businessName}</TableCell>
                                                      <TableCell>{Number(row?.actualAmount).toFixed(2)}</TableCell>
                                                      <TableCell>{Number(row?.dogDollarAmount).toFixed(2)}</TableCell>
                                                    </TableRow>
                                                  ))}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>





                                          </Grid>
                                        </Paper>)}
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                      <div className="ms-panel">
                        <div className="ms-panel-body">
                          <div className="col-md-12">
                            <Typography variant="h5" gutterBottom style={{}}>
                              Inventory of Unsold Items
                            </Typography>

                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </AppBar>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Alert severity="warning">
            To access this feature please complete your account setup.
          </Alert>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
  settings: state.user.settings,
});

export default connect(mapStateToProps, {})(Content);
