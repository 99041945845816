import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, Component } from "react";
import "react-phone-input-2/lib/style.css";
import ReactPhoneInput from "react-phone-input-2";
import Select from "react-select";
import * as SecurityServiceAPI from "../../../services/user/SecurityServiceAPI";
import { toast } from "react-toastify";
import * as message from "../../../utils/Message";
import * as Schema from "../../../utils/Schema";
import { FlapperSpinner } from "react-spinners-kit";
import "../../../assets/css/style.css";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: "0",
  }),
};

export default class BusinessPlaceForm extends Component {
  state = {
    name: "",
    phone_number: "",
    email: "",
    property_name: "",
    property_trading_name: "",
    property_address: "",
    second_line_address: "",
    property_city: "",
    property_country: "",
    property_country_code: "",
    country: "",
    postal_code: "",
    business_type: "Cafe",
    contact_name: "",
    contact_number: "",
    phone: "",
    password: "",
    confirm_password: "",

    city: "",
    loading: false,
    country_options: [],
    business_types: [],
    username: "",
    currency_code: "",
    currency_symbol: "",
    currency_details: [],
    files: [null],
    cover_image: "",
    logoFile: null,
    imagesPreviewUrl: "",
    main_image: null,
    loading: false,
  };

  componentDidMount = () => {
    const property_details = JSON.parse(
      localStorage.getItem("property_details")
    );
    if (property_details && property_details.property_id) {
      this.setState({
        property_id: property_details.property_id,
        username: property_details.username,
        property_name: property_details.property_name,
        contact_name: property_details.contact_name,
        contact_number: property_details.contact_number,
        property_trading_name: property_details.property_trading_name,
        business_type: property_details.business_type,
        property_address: property_details.property_address,
        second_line_address: property_details.second_line_address,
        property_country: property_details.country,
        property_country_code: property_details.country_code,
        property_city: property_details.city,
        postal_code: property_details.postal_code,
        currency_code: this.state.currency_code,
      });
    } else {
      console.log("Property Id Not Available");
    }
  };

  onChangeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleoPhoneChangeHandler = (value) => {
    this.setState({
      contact_number: value,
    });
  };

  handleCountryOnChange = (value) => {
    this.setState({
      country: value,
      property_country: value.label,
      property_country_code: value.value,
    });
  };

  handleOnChange = (value) => {
    this.setState({
      phone: value,
    });
  };

  businessPlaceFormHandler = (values) => {
    this.setState({ loading: true });
    var getCountry = require("country-currency-map").getCountry;
    const currency = getCountry(this.state.country.label);
    this.setState({ currency_code: currency.abbreviation });
    const getCurrency = require("country-currency-map").getCurrency;
    const tmp_username = localStorage.getItem("username");

    const payload = {
      property_name: this.state.property_name,
      property_trading_name: this.state.property_trading_name,
      business_type: this.state.business_type,
      property_address:
        this.state.property_address + ", " + this.state.second_line_address,
      property_country_code: this.state.country.value,
      property_country: this.state.country.label,
      property_city: this.state.property_city,
      contact_name: this.state.contact_name,
      contact_number: "+" + this.state.contact_number,
      postal_code: this.state.postal_code,
      currency_code: currency.currency,
      username: tmp_username,
    };

    SecurityServiceAPI.addBusinessPlace(payload)
      .then((response) => {
        if (response.data.status) {
          if (
            response.data.data.property_id != "" &&
            response.data.data.username != ""
          ) {
            localStorage.setItem("property_id", response.data.data.property_id);
            localStorage.setItem("username", response.data.data.username);
            localStorage.setItem(
              "business_trading_name",
              this.state.property_trading_name
            );
          }
          toast.success("Account Details Added Successfully");
          this.setState({ loading: false });
          // this.props.handleActivePage(1);
          window.location.reload(true);
        } else {
          console.log("response.data.message", response.data.message);
          toast.error(response.data.message);
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        // toast.error(message.CATCH_ERROR_MESSAGE)
        this.setState({ loading: false });
      });
  };

  handleUpdateBusiness = () => {
    this.setState({ loading: true });

    const businessPlaceForm = {
      property_id: this.state.property_id,
      username: this.state.username,
      property_name: this.state.property_name,
      property_trading_name: this.state.property_trading_name,
      business_type: this.state.business_type,
      property_address: this.state.property_address,
      property_country_code: this.state.property_country_code,
      property_country: this.state.property_country,
      currency_code: this.state.currency_code,
      property_city: this.state.property_city,
      contact_name: this.state.contact_name,
      contact_number: this.state.contact_number,
      postal_code: this.state.postal_code,
    };

    let formData = new FormData();
    formData.append("property_details ", JSON.stringify(businessPlaceForm));

    SecurityServiceAPI.updateBusinessPlace(formData)
      .then((response) => {
        if (response.data.status) {
          toast.success("Account Details Updated Successfully");
          this.props.handleActivePage(1);
          this.setState({ loading: false });
        } else {
          console.log("response.data.message : ", response.data.message);
          toast.warn(response.data.message);
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log("Update business profile ===> ", error);
        toast.error("Oops! Something went wrong");
        this.setState({ loading: false });
      });
  };

  printState = () => {
    console.log(this.state);
  };

  render() {
    return (
      <Fragment>
        {this.state.loading ? (
          <div className="spinner cardBody card-body">
            <FlapperSpinner
              size={75}
              color="#233cad"
              loading={this.state.loading}
            />
          </div>
        ) : (
          <div className="needs-validation" noValidate>
            <div>
              <div className="card-body cardBody">
                <Formik
                  initialValues={{
                    contact_name: this.state.contact_name,
                    contact_number: this.state.contact_number,
                    email: this.state.email,
                    property_name: this.state.property_name,
                    property_trading_name: this.state.property_trading_name,
                    business_type: this.state.business_type,
                    property_address: this.state.property_address,
                    second_line_address: this.state.second_line_address,
                    property_country: this.state.property_country,
                    property_city: this.state.property_city,
                    postal_code: this.state.postal_code,
                  }}
                  validateOnBlur
                  validateOnMount
                  enableReinitialize
                  validationSchema={Schema.businessPlaceSchema}
                  onSubmit={(values) => {
                    this.state.property_id
                      ? this.handleUpdateBusiness()
                      : this.businessPlaceFormHandler(values);
                  }}
                >
                  {({ errors, touched, handleBlur }) => (
                    <Form>
                      {
                        <div className="test">
                          <div>
                            <div className="form-row">
                              <div className="col-md-4">
                                <label htmlFor="contact_name">
                                  Contact Name
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="contact_name"
                                    placeholder=""
                                    name="contact_name"
                                    value={this.state.contact_name}
                                    onChange={this.onChangeHandler}
                                    onBlur={handleBlur}
                                  />
                                  {/* <div className="valid-feedback">
                                                                            Looks good!
                                                                        </div> */}

                                  {touched.contact_name ? (
                                    <div className="error-message">
                                      {errors.contact_name}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <label htmlFor="contact_number">
                                  Contact Number
                                </label>
                                <div className="input-group">
                                  <ReactPhoneInput
                                    inputExtrathis={{
                                      name: "contact_number",
                                      required: true,
                                      autoFocus: false,
                                    }}
                                    inputStyle={{
                                      width: "100%",
                                      border: "1px solid rgba(0, 0, 0, 0.1)",
                                    }}
                                    country={"gb"}
                                    searchPlaceholder="Search Country Code"
                                    enableSearchField
                                    // placeholder="0207*******"
                                    value={this.state.contact_number}
                                    onChange={this.handleoPhoneChangeHandler}
                                    onBlur={handleBlur}
                                  />
                                  <div className="invalid-feedback">
                                    Please provide a password.
                                  </div>
                                  {touched.contact_number ? (
                                    <div className="error-message">
                                      {errors.contact_number}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <label htmlFor="property_name">
                                  Business Name
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="property_name"
                                    placeholder=""
                                    name="property_name"
                                    value={this.state.property_name}
                                    onChange={this.onChangeHandler}
                                    onBlur={handleBlur}
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  {touched.property_name ? (
                                    <div className="error-message">
                                      {errors.property_name}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <label htmlFor="property_trading_name">
                                  Business Trading Name
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="property_trading_name"
                                    placeholder=""
                                    name="property_trading_name"
                                    value={this.state.property_trading_name}
                                    onChange={this.onChangeHandler}
                                    onBlur={handleBlur}
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  {touched.property_trading_name ? (
                                    <div className="error-message">
                                      {errors.property_trading_name}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <label htmlFor="business_type">
                                  Business Type
                                </label>
                                <div className="input-group">
                                  <select
                                    className="form-control"
                                    id="business_type"
                                    name="business_type"
                                    value={this.state.business_type}
                                    onChange={this.onChangeHandler}
                                  >
                                    {this.props.state.business_types.map(
                                      (businessType) => (
                                        <option
                                          key={businessType}
                                          value={businessType}
                                        >
                                          {businessType}
                                        </option>
                                      )
                                    )}
                                  </select>

                                  {/* <div className="invalid-feedback">
                                                                            Please provide a valid email!
                                                                        </div> */}
                                  {touched.business_type ? (
                                    <div className="error-message">
                                      {errors.business_type}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="col-md-12">
                                {this.state.property_id ? (
                                  <label htmlFor="property_address">
                                    Business Address
                                  </label>
                                ) : (
                                  <label htmlFor="property_address">
                                    Business Address First Line
                                  </label>
                                )}
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="property_address"
                                    placeholder=""
                                    name="property_address"
                                    value={this.state.property_address}
                                    onChange={this.onChangeHandler}
                                    onBlur={handleBlur}
                                  />
                                  {/* <div className="valid-feedback">Looks good!</div> this doesnt look right here! */}
                                  {touched.property_address ? (
                                    <div className="error-message">
                                      {errors.property_address}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              {this.state.property_id ? null : (
                                <div className="col-md-12">
                                  <label htmlFor="second_line_address">
                                    Business Address Second Line
                                  </label>
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      id="second_line_address"
                                      name="second_line_address"
                                      value={this.state.second_line_address}
                                      onChange={this.onChangeHandler}
                                      onBlur={handleBlur}
                                    />
                                    <div className="valid-feedback">
                                      Looks good!
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="col-md-4">
                                <label htmlFor="property_country">
                                  Country
                                </label>
                                {this.state.property_country != undefined ? (
                                  <Select
                                    id="property_country"
                                    name="property_country"
                                    options={this.props.state.country_options}
                                    value={{
                                      label: this.state.property_country,
                                      value: this.state.property_country_code,
                                    }}
                                    onChange={this.handleCountryOnChange}
                                    styles={colourStyles}
                                  />
                                ) : null}
                                {touched.property_country ? (
                                  <div className="error-message">
                                    {errors.property_country}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-4">
                                <label htmlFor="property_city">City</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="property_city"
                                    name="property_city"
                                    placeholder=""
                                    value={this.state.property_city}
                                    onChange={this.onChangeHandler}
                                    onBlur={handleBlur}
                                  />
                                  <div className="invalid-feedback">
                                    Please provide a password.
                                  </div>
                                  {touched.property_city ? (
                                    <div className="error-message">
                                      {errors.property_city}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <label htmlFor="postal_code">Post Code</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="postal_code"
                                    name="postal_code"
                                    placeholder=""
                                    value={this.state.postal_code}
                                    onChange={this.onChangeHandler}
                                    onBlur={handleBlur}
                                  />
                                  <div className="invalid-feedback">
                                    Please provide a password!
                                  </div>
                                  {/* <ErrorMessage name="postal_code" style={{'bottom': 'calc(100% + 10px)',
  'right': '0',
  'text-align': 'right',
  'position': 'absolute', 'color': 'red'}} /> */}

                                  {touched.postal_code ? (
                                    <div className="error-message">
                                      {errors.postal_code}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="col-md-12">
                                <button
                                  className="btn btn-primary mt-4 d-block float-right"
                                  type="submit"
                                >
                                  {this.state.property_id ? "Next" : "Next"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
