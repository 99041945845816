import React from 'react';
import { List, ListItem, ListItemText, Button, ListItemIcon } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import Avatar from '@mui/material/Avatar';
import DataTable from 'react-data-table-component';
export class ItemList extends React.Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            currentPage: 1,
            itemsPerPage: 10 // Number of items to display per page
        };
    }

    // Calculate total number of pages
    totalPages = () => {
        const { items } = this.props;
        const { itemsPerPage } = this.state;
        return Math.ceil(items.length / itemsPerPage);
    }

    // Get current items to display on the current page
    currentItems = () => {
        const { items } = this.props;
        const { currentPage, itemsPerPage } = this.state;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        return items.slice(indexOfFirstItem, indexOfLastItem);
    }

    // Function to handle page change
    handlePageChange = (newPage) => {
        this.setState({ currentPage: newPage });
    }

    render() {
        const { currentPage } = this.state;
        const { items } = this.props;

        return (
            <React.Fragment>
                {/* 
                <List
                    sx={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap', bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"

                >
                    {this.currentItems().map((item, index) => (
                        <ListItem key={index} sx={{ display: 'flex', flexDirection: 'row', width: '30%', flexWrap: 'wrap' }}>
                            <ListItemIcon>
                                <Avatar>{((currentPage - 1) * 10) + index + 1}</Avatar>
                            </ListItemIcon>
                            <ListItemText primary={item} />
                        </ListItem>
                    ))}
                </List>
                <Button
                    disabled={currentPage === 1} variant='contained'
                    onClick={() => this.handlePageChange(currentPage - 1)}
                >
                    Previous
                </Button>
                <Button sx={{ marginLeft: "20px" }}
                    disabled={currentPage === this.totalPages() || items.length == 0} variant='contained'
                    onClick={() => this.handlePageChange(currentPage + 1)}
                >
                    Next
                </Button> */}


                <DataTable
                    columns={this.order_list_columns}
                    pagination
                    paginationRowsPerPageOptions={[10, 25, 50, 100, 250]}
                    data={this.state.topSellingItems}
                    customStyles={this.customStyles}
                />
            </React.Fragment>
        );
    }
}

export default ItemList;
