import React, { Component } from "react";
import DataTable from "react-data-table-component";
import * as PaymentServiceAPI from "../../services/payment/PaymentServiceAPI";
import Pagination from "../../shared/Pagination/Pagination";
import Loader from "../../shared/Loader";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { Button, IconButton } from "@mui/material";
import DownloadingIcon from "@mui/icons-material/Downloading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import NativeSelect from "@mui/material/NativeSelect";
import ReactGA from "react-ga";
import { Grid } from "@mui/material";
import $ from "jquery";
import {getCurrencySymbol} from "../../config";
class Content extends Component {
  state = {
    property_id: "",
    data: [],
    isLoading: true,
  };

  componentDidMount = () => {
    let prop_id = this.props.propertyId;
    if (prop_id) {
      this.setState({ property_id: prop_id }, this.filterAllNashInvoices());
    } else {
      this.setState({ isLoading: false });
    }
  };

  filterAllNashInvoices = (payload) => {
    const property = this.props.propertyId;
    this.setState({ property: property });
    if (property) {
      const payload = {
        property_id: property,
      };

      PaymentServiceAPI.fetchNashInvoice(payload)
        .then((response) => {
          if (response.data.status) {
            this.setState({
              data: response.data.data,
              isLoading: false,
            });
          } else {
            this.setState({
              data: [],
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          toast.warning("Error : ", err);
          console.log("Error: ", err);
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  fetchWebReport = (row) => {
    const payload = {
      nash_id: row.nash_id,
    };

    PaymentServiceAPI.fetchNashReports(payload)
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file); //Open the URL on new Window
        window.open(fileURL);
        this.downloadFile(fileURL);
        //   window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  downloadFile(filePath) {
    var link = document.createElement("a");
    link.href = filePath;
    link.download = "Nash invoice";
    link.click();
  }

  render() {
    const customStyles = {
      rows: {
        style: {
          minHeight: "40px", // override the row height
        },
      },
      headCells: {
        style: {
          justifyContent: "center",
          textAlign: "center",
          backgroundColor: "#5773ff",
          fontSize: "14px",
          color: "white",
          paddingRight: "0px",
        },
      },
      cells: {
        style: {
          pointer: "cursor",
          justifyContent: "center",
          textAlign: "center",
          paddingRight: "0px",
        },
      },
    };

    const columns = [
      {
        name: "START DATE",
        selector: (row) =>
          dayjs(row.subscription_start_date).format("DD-MM-YYYY"),
        sortable: false,
      },
      {
        name: "END DATE",
        selector: (row) =>
          dayjs(row.subscription_end_date).format("DD-MM-YYYY"),
        sortable: false,
      },
      {
        name: "TOTAL",
        selector: "amount",
        sortable: false,
        cell: (row) => (
          <div>
            <b>{getCurrencySymbol(localStorage.getItem('currency_code')) + Math.abs(row.amount).toFixed(2)}</b>
          </div>
        ),
      },
      {
        name: "Download",
        cell: (row) => (
          <div>
            <Stack direction="row" spacing={1}>
              {/* <IconButton
                color="primary"
                aria-label="download"
                onClick={() => this.fetchWebReport(row)}
              >
                <DownloadingIcon />
              </IconButton> */}
              <Button variant="outlined" startIcon={<DownloadingIcon color="primary" />} onClick={() => this.fetchWebReport(row)}sx={{alignItems:"flex-start"}}>Download</Button>

            </Stack>
          </div>
        ),
      },
    ];

    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-body">
                <div className="table-responsive">
                  {this.state.isLoading ? (
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "40px",
                      }}
                    >
                      {" "}
                      <Loader size={50} />{" "}
                    </div>
                  ) : (
                    <React.Fragment>
                      <DataTable
                        columns={columns}
                        data={this.state.data}
                        state={this.state.data}
                        customStyles={customStyles}
                        handleChange={this.changeCurrentPage}
                        pagination
                        paginationRowsPerPageOptions={[
                          10, 25, 50, 100, 500, 1000,
                        ]}
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default connect(mapStateToProps, {})(Content);
