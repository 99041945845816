import * as React from 'react';
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    Button,
    CardActions,
    TextField,
} from '@mui/material';
import {  makeStyles } from "@mui/styles";
import Jumbo from '../../../assets/img/apex.jpg'
import {getCurrencySymbol} from "../../../config";
const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
    },
    bronzeCard: {
        borderRadius: '20px', // Adjust the value to achieve the desired shape
     //   border: '2px solid #5773ff', // Bronze color
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5)',
      },
    model: {
        color: "grey",
        fontSize: "14px"
    },
    media: {
        width: '350px',
        height: '350px',
        backgroundSize: "contain",
        padding: "5px"
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    description: {
        marginBottom: theme.spacing(2),
    },
    quantityField: {
        // marginBottom: theme.spacing(1),
        width: "100px"
    },
    addButton: {
        marginTop: 'auto',
      //  marginLeft: theme.spacing(5),
      //  color: "white",
        borderRadius: '10px'
    },
    price: {
        marginTop: '20px;',
        padding: 0,
        verticalAlign: "top",
        color: "#5773ff",
        fontSize: "26px",
        fontWeight: 600,
        marginTop: "40px"
    },
    block_price__currency: {
        padding: 0,
        margin: 0,
        verticalAlign: "top",
        color: "#5773ff",
        fontSize: "26px",
        fontWeight: 600
    },
    title: {
        position: "relative", // Set the parent element to a relative position
        padding: "10px", // Add some padding to the element

        "&::before": {
            content: "''", // Add content to the pseudo-element
            position: "absolute",
            bottom: "0",
            width: "40%", // Adjust the width as needed
            borderBottom: "5px solid #5773ff",
        }
    }

}));
const NewCard = ({ product }) => {
    const classes = useStyles();
    return (
       
        <Card className={classes.bronzeCard}>
            <CardMedia
                sx={{ height: 240, padding: 5, backgroundSize: "contain" }}
                image={product.imageUrl}
                title="green iguana"
            />
            <CardContent>
                <Typography variant="h6" className={classes.model}>
                    {product.model}
                </Typography>
                <Typography gutterBottom variant="h5" component="div" className={classes.title}>
                 {product.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                {product.description}
                </Typography>
                <Typography className={classes.price} variant="body1" color="textSecondary">
                    {getCurrencySymbol(localStorage.getItem('currency_code'))} 1000
                </Typography>
                <div className={classes.price}>
                    <TextField
                        size='small'
                        label="Quantity"
                        variant="outlined"
                        type="number"
                        defaultValue={1}
                        InputProps={{
                            inputProps: {
                                min: 1, // minimum value
                            },
                        }}
                        className={classes.quantityField}
                    />
                </div>

            </CardContent>
            <CardActions>

                <Button
                    color="primary"
                    variant="outlined"

                    className={classes.addButton}
                    fullWidth
                >
                    Add to Cart
                </Button>
            </CardActions>
        </Card>
        
    );
}
export default NewCard;