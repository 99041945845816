import React, { Component } from "react";
import { toast } from "react-toastify";
import * as CashServiceAPI from "../../services/cash/CashServiceAPI";
import SimpleModal from "../../shared/Modal/SimpleModal";
import { Fragment } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loader from "../../shared/Loader";
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import adyen from '../../assets/img/adyen.png';
import $ from "jquery";
import noGrants from '../../assets/img/noGrants.png';
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

class index extends Component {


  state = {
    isLoading: false,
    payment_method_type: [],
    checked: ['wifi'],
    selectedCard: 0,
    grants: []
  };

  componentDidMount = () => {
    function setActiveMenuItem() {

      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }

    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#capitalMenu").addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#capitalMenu").addClass("active");
    }
    const dump = this.props.propertyId;
    this.fetchGrant(dump);
    this.fetchGrants(dump);

    console.log(this.state)
  };

  handleCardSelect = (index) => {

  };



  handleToggle = (value) => () => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked });
  };


  fetchGrants = (payload) => {
    if (payload) {
      CashServiceAPI.fetchAllGrants(payload)
        .then((response) => {
          if (response.data.status) {
            this.setState({ grants: response.data.data, isLoading: false });
          } else {
            this.setState({ grants: [], isLoading: false });
          }

        })
        .catch((error) => {
          this.setState({ isLoading: false, grants: [], });
        });
    } else {
      this.setState({ isLoading: false, grants: [] });
    }


  };

  fetchGrant = (payload) => {
    this.setState({ isLoading: true });
    if (payload) {
      CashServiceAPI.fetcchGrants(payload)
        .then((response) => {
          if (response.data.status) {
            this.setState({ isLoading: false });
          }

          const grant = response.data.data.find(item => item.status === "Active" || item.status === "Pending");
          if (grant) {
            this.props.history.push({
              pathname: "/grant-details",
            });
          }


        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ isLoading: false });
    }


  };

  fetchActiveGrant = () => {
    const payload = this.props.propertyId
    this.setState({ isLoading: true });
    if (payload) {
      CashServiceAPI.fetchActiveGrant(payload)
        .then((response) => {
          if (response.data.status) {
            this.setState({ isLoading: false });
            this.props.history.push({
              pathname: "/grant-details",
            });
          } else {
            this.setState({ isLoading: false });

          }

        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ isLoading: false });
    }
  };



  handleCardClick = (plan, index) => {
    console.log(plan);
    this.setState({ selectedCard: index });
    this.props.history.push({
      pathname: "/grant-accept",
      state: { plan: plan }  // Wrap 'plan' in an object
    });
  };


  render() {

    const { selectedCard, grants } = this.state;
    return (
      <Fragment>
        {this.state.isLoading ? <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "40px" }}>   <Loader size={50} /> </div> :
          <div className="">
            {grants && grants.length > 0 ? (<div className="">
              <div className="">
                <br />

                <div className="">
                  <Typography variant="h4" >
                    Looking for business financing?
                  </Typography>
                  <h5>
                    You are two clicks away from getting funds.   Accept the terms and conditions and get your cash in minutes.
                  </h5>
                </div>

                <br /><br />

                <Grid container spacing={3} >

                  {grants.map((plan, index) => (

                    <Grid item xs={12} lg={3} >
                      <Link to="#" onClick={(e) => e.preventDefault()}>
                        <Card
                          sx={{
                            position: "relative",

                            "&:hover": {
                              border: "2px solid #5773ff",
                            },
                            borderRadius: "16px",
                            minWidth: 275, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'
                          }}
                          onClick={() => this.handleCardClick(plan, index)}
                        >
                          <CardContent>
                            <Radio
                              checked={selectedCard === index}
                              size="small"
                              onChange={() => this.handleCardSelect(index)}
                              value={index.toString()}
                            />
                            <br /> <br />
                            <Typography variant="h4" component="div">
                              <b> {plan?.principal_amount} </b>
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">

                            </Typography>
                            <Typography variant="body2">
                              you will return {plan?.repayment_percentage}% of your daily sales until you have remitted {plan?.total_repayment_amount}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <br />   <br /><br />   <br />
                            <Button style={{ borderRadius: "6px" }} fullWidth variant="contained" size="small">Request {plan?.principal_amount}</Button>
                          </CardActions>
                        </Card>
                      </Link>
                    </Grid>

                  ))}

                </Grid>
                <br />
                <Stack sx={{ width: '75%' }} spacing={3}>
                  <Alert severity="info">These proposals are valid until {grants[0]?.valid_upto} .</Alert>
                </Stack>
                <br />
                <Grid container>
                  <Grid item xs={12} lg={12}>
                    <Typography variant="h5" >
                      <b>FAQ ( Frequently Asked Questions)</b>
                    </Typography>
                    <br />
                    <p><b>General</b></p>
                  </Grid>
                  <br />
                  <Grid item xs={12} lg={8}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>What is Adyen Capital / [Platform] Capital?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Adyen Capital allows you to build an embedded financing offering for your eligible users for their operational needs.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Who is Adyen?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Adyen is the facilitating partner behind the Capital product. We advise platforms to explicitly make clear we are the facilitating bank behind the service.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Who is eligible for Capital??</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          To be eligible for Capital, you must meet the following requirements:
                          <ol>
                            <li>You must have been actively trading with us for at least 3 months.</li>
                            <li>The volume of trades must exceed £1000 per month for 3 consecutive months.
                              .</li>
                            <li> You are a business or sole proprietorship.</li>
                          </ol>

                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Does the Capital offer expire?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Yes the offers do expire. Please view the validity period listed with the offer to see how long you can think about the offer before it expires.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>How long does it take to receive funds on my account?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Funds are made available to your account within minutes of agreeing to the terms and conditions.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>I haven’t been offered Capital, does that mean I’m not eligible??</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Not necessarily. To be eligible for Capital, you must meet the following requirements:

                          <ol>
                            <li> You must have been actively trading with us for at least 3 months.</li>
                            <li> The volume of trades must exceed £1000 per month for 3 consecutive months.</li>
                            <li> You are a business or sole proprietorship..</li>
                          </ol>


                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Will applying for Capital affect my credit rating?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          The Capital product does not require you to provide your personal credit rating and does not affect your credit score.

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>How do you determine a capital size for my business?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Adyen provides proactive risk assessments based on your payments processing data. The outcome of the assessment results in the offers displayed to you.

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>What can I use the funds for?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          The business financing provided through Capital can be used for business purposes. This can range from buying stock, buying new machines, investing in advertisement to covering for temporary operational disruptions.

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>What are the costs of getting business financing?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          The amount you remit is the business financing including a flat transparent fee. This means the costs of the service are communicated in advance of the service to you.

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Where will the financing be paid?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          This is a platform specific question.

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <br />
                    <p><b>Repayment</b></p>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>How does repayment work?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          The repayment amount is a fixed percentage deducted from your sales before daily payout. The repayments are deducted daily from net captured volume — captures minus refunds and chargebacks. The percentage we deduct is specific to the received capital and is communicated to you in the capital offer.

                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>When do I need to repay the amount I owe?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          You repay a repayment percentage over your daily sales. This means you repay more on busy days and repay less when business is slow.

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Can I pay my Capital early?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Currently this functionality is not available yet. The amounts due for repayment will be deducted from the payouts due to you on a daily basis.

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Can I change the payment terms?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          We understand that sometimes unexpected events occur and we are here to assist. Please direct your request to the following email address: creditriskops@adyen.com. Adyen, as the ultimate capital provider, will assist in addressing your query.

                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>What does the Threshold amount entail?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          The threshold repayment amount is the amount against which we monitor the repayment. If the total 30-day repayment drops below this threshold, we may take additional action to make sure that the user remains on track.

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>What do the following terms mean?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          We recommend you to review the terms from the Capital Terms and Conditions and list the terms that may be new to your user for further explanation.

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>

              </div>
              <br />  <br />
            </div>)
              : 
            <Stack
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', // Center items horizontally
                justifyContent: 'center', // Center items vertically
                pt: 4
              }}
              spacing={2}
            >
           {/*    <Alert severity="warning">No grants found.</Alert> */}
              <Typography variant="h5">
                <b> Unfortunately, there are currently no available offers for your consideration at this time. </b>
              </Typography>
              <p style={{color: "black", fontSize: "16px"}}>  Revisit later to discover upcoming promotions. </p>
              <img src={noGrants} style={{ width: '35vw' }} />
            </Stack>}

            <Typography variant="body2" style={{ position: "fixed", bottom: "10px", right: "10px", display: "flex", alignItems: "center", fontFamily: "Roboto", fontSize: "16px", fontWeight: "bold", color: "#333" }}>
              Powered by <img style={{ width: "100px", marginLeft: "5px" }} src={adyen} alt="Adyen Logo" />
            </Typography>



          </div>
        }
      </Fragment >
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId
});

export default connect(mapStateToProps, {})(index);