import React, { Component } from "react";
import { toast } from "react-toastify";
import * as CashServiceAPI from "../../../services/cash/CashServiceAPI";
import { Fragment } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { connect } from "react-redux";
import FolderIcon from '@mui/icons-material/Folder';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';
import Loader from "../../../shared/Loader";
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { Line } from '@ant-design/plots';
import Breadcrumb from "./Breadcrumb";
import $ from "jquery";
import { ThirtyFpsSelect } from "@mui/icons-material";
import { Modal } from "antd";
import adyen from '../../../assets/img/adyen.png';
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const salesData = [
  { label: 'Product A', amount: '$50000' },
  { label: 'Product B', amount: '$30000' },
  { label: 'Product C', amount: '$20000' },
];

const dat = [{ "amount": "4.88", "booking_date": "07-11-2023" }, { "amount": "544", "booking_date": "08-11-2023" }, { "amount": "1088", "booking_date": "00-11-2023" }];

class index extends Component {


  state = {
    isLoading: false,
    payment_method_type: [],
    checked: ['wifi'],
    selectedCard: false,
    grants: [],
    dense: false,
    isChecked: false,
    plan: {},
    grant: null,
    repayments: [],
    activeGrant: {},
    isModalOpen: false,
  };

  componentDidMount = () => {
    const dump = this.props.propertyId
    const plan = this.props?.history?.location?.state?.plan;
    const activeGrant = this.props?.history?.location?.state?.activeGrant;
    this.setState({ plan: plan, grant: activeGrant });
    if (activeGrant && activeGrant.id) {
      this.fetchGrantRepayment(activeGrant.id);
    } else {
      //this.fetchActiveGrant();
      this.fetchGrants(dump);
    }

    function setActiveMenuItem() {

      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }

    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }

  };

  fetchActiveGrant = () => {
    this.setState({ isLoading: true });
    CashServiceAPI.fetchActiveGrant(this.state.activeGrant.id)
      .then((response) => {
        if (response.data.status) {
          this.setState({ isLoading: false, grant: response.data.data })
        } else {
          this.setState({ isLoading: false, grant: {} });
          /*  this.props.history.push({
             pathname: "/grants",
           }); */
        }



      })
      .catch((error) => {
        this.setState({ isLoading: false, grant: {} });
      });

  };





  fetchGrants = (payload) => {
    this.setState({ isLoading: true });
    if (payload) {
      CashServiceAPI.fetcchGrants(payload)
        .then((response) => {
          if (response.data.status) {
            this.setState({ grants: response.data.data });
            const grant = response.data.data.find(item => item.status === "Active" || item.status === "Pending");
            if (grant) {
              this.setState({ grant: grant, isLoading: false });
              this.fetchGrantRepayment(grant.id);
            } else {
              this.setState({ isLoading: false });
              this.props.history.push({
                pathname: "/grants",
              });
            }

          } else {
            this.setState({ isLoading: false });
            this.props.history.push({
              pathname: "/grants",
            });
          }



        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ isLoading: false });
    }


  };

  fetchGrantRepayment = (payload) => {
    this.setState({ isLoading: true });
    if (payload) {
      CashServiceAPI.fetchGrantRepayments(payload)
        .then((response) => {
          if (response.data.status) {
            const repayments = response.data?.data.map(item => ({
              ...item,
              amount: parseFloat(item.amount)
            }));
            this.setState({ isLoading: false, repayments: repayments })
            console.log(response.data.data)

          } else {
            this.setState({ isLoading: false, repayments: [] });

          }

        })
        .catch((error) => {
          this.setState({ isLoading: false, repayments: [] });
        });
    } else {
      this.setState({ isLoading: false, repayments: [] });
    }


  };




  handleCheckboxChange = () => {

  };

  handleSignRequest = () => {
    this.setState({ isLoading: true });
    const payload = {
      property_id: this.props.propertyId,
      grant_offer_id: this.state.plan.id
    }

    CashServiceAPI.requestGrant(payload)
      .then((response) => {
        if (response.data.status) {
          console.log(response.data.data)
          this.setState({ isLoading: false });
          toast.success("Grant requested.")
        } else {
          toast.success("Grant requested failed.")
        }

      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });


  };


  handleOk = () => {
    this.setState({ isModalOpen: false });
  };
  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };
  showCapitalDetailedSummary = () => {
    this.setState({ isModalOpen: true });
  };

  render() {


    /*    const trendingReportConfig = {
         title: {
           visible: true,
           text: 'Daily Chart',
         },
         data: this.state.repayments,
         padding: 'auto',
         yField: 'amount',
         xField: 'booking_date',
         
         xAxis: {
           title: {
             visible: true,
             text: 'Repayment Date',
           },
         },
         yAxis: {
           title: {
             visible: true,
             text: 'Amount',
           },
         },
       }; */
    /* 
        const data = [
            {
                "amount": "4.96",
                "booking_date": "11-11-2023"
            },
            {
                "amount": "1.67",
                "booking_date": "14-11-2023"
            },
            {
                "amount": "0.83",
                "booking_date": "15-11-2023"
            }
        ] */
    const config = {
      data: this.state.repayments,
      xField: 'booking_date',
      yField: 'amount',
      label: {
        // 可手动配置 label 数据标签位置
        position: 'middle',
        // 'top', 'bottom', 'middle',
        // 配置样式
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      xAxis: {
        title: {
          visible: true,
          text: 'Repayment Date',
        },
      },
      yAxis: {
        title: {
          visible: true,
          text: 'Payment Amount',
        },
      },
    };

    const { selectedCard, grants, dense, isChecked, plan, grant, isLoading, repayments, isModalOpen } = this.state;
    const titleStyle = {
      borderBottom: '5px solid #5773ff', // Adjust the line style as needed
      paddingBottom: '8px', // Add some padding to create space between the line and text
      widtj: "280px"
    };

    return (
      <Fragment>
        {isLoading ? <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "40px" }}>   <Loader size={50} /> </div> :
          <div className="">
            <div className="">
              <div className="">
                <div className="col-md-12">
                  <Breadcrumb />
                </div>
                <br />

                <div className="col-md-6">
                  <Typography variant="h5" style={{ marginBottom: '1rem', fontWeight: 'bold' }}>
                    Capital Details
                    &nbsp; &nbsp; <span>
                      <Link to="#" onClick={() => { this.showCapitalDetailedSummary() }}>
                        View Detailed Summary
                      </Link>
                    </span>
                  </Typography>

                </div>
                <div style={{ margin: '1rem 0' }}>
                  <Divider />
                </div>

                <Grid container spacing={4} justifyContent="space-around">
                  {/* First Card - 1/5th of the width */}
                  <Grid item xs={12} lg={2}>
                    <Card style={{ background: "#5773ff4d" }}>
                      <CardContent>
                        <Typography variant="subtitle1">Outstanding Amount</Typography>
                        <Typography style={{ fontWeight: "bold" }} variant="h5">{grant?.out_standing_amount}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Second Card - 4/5th of the width */}
                  <Grid item xs={12} lg={10}>
                    <Grid container spacing={0}>

                      <Grid item xs={12} lg={2}>
                        <Card>
                          <CardContent>
                            <Typography variant="subtitle1">Total Grant</Typography>
                            <Typography style={{ fontWeight: "bold" }} variant="h5">{grant?.principal_amount || Number(0).toFixed(2)}</Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <Card>
                          <CardContent>
                            <Typography variant="subtitle1">Paid Amount</Typography>
                            <Typography style={{ fontWeight: "bold" }} variant="h5">{grant?.repaid_amount || Number(0).toFixed(2)}</Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <Card>
                          <CardContent>
                            <Typography variant="subtitle1">Fee</Typography>
                            <Typography style={{ fontWeight: "bold" }} variant="h5">{grant?.fee || Number(0).toFixed(2)}</Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <Card>
                          <CardContent>
                            <Typography variant="subtitle1">Percentage</Typography>
                            <Typography style={{ fontWeight: "bold" }} variant="h5">{grant?.repayment_percentage || 0} %</Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <Card>
                          <CardContent>
                            <Typography variant="subtitle1">Total Repayment</Typography>
                            <Typography style={{ fontWeight: "bold" }} variant="h5">{grant?.total_repayment_amount || 0} </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      {grant?.status == "Active" || grant?.status == "Pending" ? (<Grid item xs={12} lg={2}>
                        <Card>
                          <CardContent>
                            <Typography variant="subtitle1">Status</Typography>
                            <Typography style={{ fontWeight: "bold" }} variant="h5">{grant?.status}</Typography>
                          </CardContent>
                        </Card>
                      
                      </Grid>) : ""}

                    </Grid>

                  </Grid>
                </Grid>
                <br /><br /><br />
                <div className="col-md-3">
                  <Typography variant="h6" style={{ marginBottom: '1rem', fontWeight: 'bold' }}>
                    Repayment History
                  </Typography>
                </div>
                {repayments.length > 0 ? <Column {...config} />  :
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="warning">No repayment history found. </Alert>
                  </Stack>}
                  <br/> <br/>  

              </div> </div> </div>}

        <Modal style={{ fontWeight: "bold" }} title="Capital Detailed Summary" open={isModalOpen} onCancel={this.handleCancel}>
          <Divider></Divider>
          <div
            style={{
              margin: "0 auto",
              padding: "5px",
              maxWidth: "600px",
            }}
          >

            <List>
              <ListItem>
                <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: 'black', marginRight: '10px' }} />
                <ListItemText style={{ fontWeight: "bold" }} primary={"Financing Amount : " + grant?.principal_amount} />
              </ListItem>
              <ListItem>
                <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: 'black', marginRight: '10px' }} />
                <ListItemText style={{ fontWeight: "bold" }} primary={"Withholding Percentage : " + grant?.repayment_percentage + " %"} />
              </ListItem>
              <ListItem>
                <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: 'black', marginRight: '10px' }} />
                <ListItemText style={{ fontWeight: "bold" }} primary={"Threshold Amount  : " + grant?.total_repayment_amount} />
              </ListItem>
              {/* Add more ListItems as needed */}
            </List>


            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >



            </Stack>
          </div>
        </Modal>
        <br/> <br/>
        <Typography variant="body2" style={{ position: "fixed", bottom: "10px", right: "10px", display: "flex", alignItems: "center", fontFamily: "Roboto", fontSize: "16px", fontWeight: "bold", color: "#333" }}>
          Powered by <img style={{ width: "100px", marginLeft: "5px" }} src={adyen} alt="Adyen Logo" />
        </Typography>
      </Fragment >
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId
});

export default withRouter(connect(mapStateToProps, {})(index));