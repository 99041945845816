export const settings = {
    APP_VERSION:  "1.2.5",
    GOOGLE_API_KEY:"AIzaSyAbquQkju85Ho0VUlMI_TgWP6hUQlzZ7UI",
  };

export const getCurrencySymbol = (currencyCode) => {
    switch(currencyCode) {
      case 'USD':
        return '$ ';
      case 'GBP':
        return '£ ';
      // Add more cases for other currency codes as needed
      default:
        return ''; // Return empty string for unsupported currency codes
    }
  };
  export const getCountrySymbol = (currencyCode) => {
    switch(currencyCode) {
      case 'USD':
        return 'US';
      case 'GBP':
        return 'GB';
      default:
        return '';
    }
  };