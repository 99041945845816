import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from "@mui/material/colors";
import $ from "jquery";
import Breadcrumb from "./Breadcrumb";
import { Jumbotron, Container } from "react-bootstrap";
import ApiIcon from "@mui/icons-material/Api";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import table from "../../assets/img/qr/table.png";
import bed from "../../assets/img/qr/beds.png";
import property from "../../assets/img/qr/property.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {
  CardActionArea,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Input from "@mui/material/Input";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { withRouter } from "react-router-dom";
import Loader from "../../shared/Loader";
import * as Yup from "yup";
import countryList from "react-select-country-list";
import * as PlanServiceAPI from "../../services/plans/PlanServiceAPI";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import Paper from "@mui/material/Paper";
import { Modal } from "antd";
import { Result } from "antd";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Box,
  Grid,
  useTheme,
  withStyles,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DiscountIcon from "@mui/icons-material/LocalOffer";
import DeleteIcon from "@mui/icons-material/Delete";
import { getCountrySymbol, getCurrencySymbol } from "../../config";
const customTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#5773ff",
          color: "#ededed",
        },
      },
    },
  },
});

const { Meta } = Card;
var checkout = null;
var dropin = null;

const styles = {
  card: {
    maxWidth: 300,
    margin: "auto",
    marginTop: 20,
  },
  media: {
    height: 140,
  },
};

const customStyles = {
  control: (base) => ({
    ...base,
    height: 56,
    minHeight: 56,
  }),
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  country: Yup.string().required("Country is required"),
  house_number: Yup.string().required("House Number is required"),
  street: Yup.string().required("Street is required"),
  city: Yup.string().required("City is required"),
  postal_code: Yup.string().required("Postal Code is required"),
});

class Content extends Component {
  constructor(props) {
    super(props);

    this.paymentContainer = React.createRef();
  }

  state = {
    showPaymentFailedDialog: false,
    isLoading: false,
    session: {},
    payload: {},
    showPaymentFailedDialog: false,
    order: {},
    country: {},
    formData: {
      first_name: "",
      last_name: "",
      country: "",
      house_number: "",
      street: "",
      city: "",
      postal_code: "",
    },
    errors: {},
    discountCode: "",
    showApplyDiscount: false,
    discountData: {},
    showDiscount: false,
    subscription_id: "",
    discountFree: false,
  };
  componentDidMount = () => {
    const currencyCode = localStorage.getItem("currency_code");
    const country = getCountrySymbol(currencyCode);

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        country: country,
      },
    }));

    function setActiveMenuItem() {
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }
    const payload = this.props?.history?.location?.state?.payload;
    const order = this.props?.history?.location?.state?.order;
    this.setState({ payload: payload, order: order }, () => {
      // this.createCheckout();
    });
    this.selectPlan(payload)
  };

  selectPlan = async (data) => {
    try {
      const response = await PlanServiceAPI.selectPlan(data);

      if (response.data.status) {
        if (response.data.data) {
          this.setState(
            {
              session: response.data.data,
              showApplyDiscount: true,
              subscription_id: response.data.data.subscription_id,
            },
            () => {
              this.createCheckout();
            }
          );
        }
      } else {
        const err = response.data.message || "Something went wrong.";
        toast.warn(err);
      }
    } catch (error) {
      const err = "Something went wrong.";
      toast.warn(err);
      throw error;
    }
  };

  createCheckout = async () => {
    const { session } = this.state;
    try {
      if (dropin) {
        dropin.unmount();
        dropin = null;
      }
      checkout = await AdyenCheckout({
        environment: process?.env?.REACT_APP_ENVIR,
        session: {
          id: session?.session_id,
          sessionData: session?.session_data,
        },
        clientKey: process?.env.REACT_APP_KEY,
        analytics: {
          enabled: true,
        },
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            name: "Credit or debit card",
            billingAddressRequired: true,
          },
        },
        onPaymentCompleted: (response, component) => {
          if (response?.resultCode === "Authorised") {
            toast.success("Your plan has been activated.");
            this.props.history.push({
              pathname: "/manage-qr-flow",
            });
            this.setState({ isLoading: false });
          } else {
            this.setState({ isLoading: false });
            component.handleError();
          }
        },
        onError: (error, component) => {
          this.setState({ showPaymentFailedDialog: true, isLoading: false });
          component.unmount();
        },
      });

      if (this.paymentContainer.current) {
        dropin = checkout.create("dropin").mount(this.paymentContainer.current);
      }
      this.setState({ isLoading: false });
    } catch (e) {
      toast.warn("Something went Wrong.");
      this.setState({ isLoading: false });
    }
  };

  handleFullDiscount = () => {
    this.setState({ discountFree: true });
  };
  handleDone = () => {
    this.props.history.push({
      pathname: "/manage-qr-flow",
    });
  };
  handleCancel = () => {
    this.props.history.push({
      pathname: "/manage-qr-flow",
    });
  };
  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    validationSchema
      .validate(this.state.formData, { abortEarly: false })
      .then(() => {
        let data = {
          ...this.state.payload,
          ...this.state.formData,
          property_id: this.props.propertyId,
        };
        this.selectPlan(data);
      })
      .catch((errors) => {
        const errorDetails = {};
        errors.inner.forEach((error) => {
          errorDetails[error?.path] = error?.message;
        });
        this.setState({ errors: errorDetails });
      });
  };
  handleDiscoundChange = (event) => {
    const { value } = event.target;
    this.setState({ discountCode: value.toUpperCase() });
  };
  handleBackClick = async () => {
    try {
      const payload = {
        property_id: this.props.property_id,
      };
      const response = await PlanServiceAPI.backButtonCall(payload);
      if (response.data.status) {
        this.paymentContainer.current.innerHTML = "";

        setTimeout(() => {
          this.setState({
            session: {},
            showApplyDiscount: false,
            discountData: "",
          });
        }, 1000);
      }
    } catch (error) {
      console.log(`${error.message}`);
    }
  };
  handleRemoveDiscount = async () => {
    this.setState({ isLoading: true });
    try {
      const payload = {
        subscription_id: this.state.subscription_id,
      };
      const response = await PlanServiceAPI.removeQrDiscount(payload);
      if (response.data.status) {
        this.setState(
          {
            session: response.data.data,
            discountData: "",
            discountCode: "",
            isLoading: false,
          },
          () => {
            this.createCheckout();
          }
        );

        toast.success("Discount removed successfully");
      }
    } catch (error) {
      this.setState({ isLoading: false });
      toast.error(
        `Error removing discount: ${error.message}. Please try again.`
      );
    }
  };
  handleDiscountSubmit = async () => {
    const { discountCode, session } = this.state;
    this.setState({ isLoading: true });
    if (!discountCode.trim()) {
      toast.warning("Discount code cannot be empty");
      this.setState({ isLoading: false });
      return;
    }

    try {
      const payload = {
        discount_code: discountCode,
        subscription_id: session.subscription_id,
      };

      const response = await PlanServiceAPI.applyQrDiscount(payload);

      if (response.data.status) {
        this.setState(
          {
            session: response.data.data,
            discountData: response.data.data,
            showDiscount: true,
            isLoading: false,
          },
          () => {
            if (response.data.data.is_payment_required) {
              this.createCheckout();
            } else {
              this.handleFullDiscount();
            }
          }
        );

        toast.success("Discount applied successfully");
      } else {
        toast.error(response.data.message);
        this.setState({ isLoading: false }, () => {
          this.createCheckout();
        });
      }
    } catch (error) {
      toast.warn("Error applying discount. Please try again.");
      this.setState({ isLoading: false });
    }
  };

  calculateTotal = () => {
    const { order, discountData } = this.state;

    // Extract raw amount and handle non-numeric characters
    const rawAmount =
      parseFloat((order?.charges || "").replace(/[^\d.-]/g, "")) || 0;

    // Extract raw discount amount and handle non-numeric characters
    const rawDiscount =
      parseFloat(
        (discountData?.discounted_amount || "").replace(/[^\d.-]/g, "")
      ) || 0;

    // Calculate the total amount after applying the discount
    const calculatedAmount = (rawAmount - rawDiscount).toFixed(2);

    return calculatedAmount;
  };

  render() {
    const {
      showPaymentFailedDialog,
      isLoading,
      order,
      formData,
      errors,
      session,
      country,
      showApplyDiscount,
      discountData,
      discountFree,
    } = this.state;
    const { classes } = this.props;
    /*     const rawAmount = Number((order?.charges || "").replace(/[^\d.-]/g, ""));
    const rawDiscount = Number(discountData.discounted_amount || 0);
    const calculatedAmount = discountData?.discounted_amount
      ? (rawAmount - rawDiscount).toFixed(2)
      : rawAmount.toFixed(2); */
    return (
      <Box
        sx={{
          "& .adyen-checkout__button": {
            backgroundColor: "#5773ff",
            color: "#ededed",
            transition: "background-color 0.3s ease",
            "&:hover": {
              backgroundColor: "#4059b2",
            },
          },
        }}
        className="payment-container"
      >
        {" "}
        <Fragment>
          {isLoading ? (
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "40px",
              }}
            >
              {" "}
              <Loader size={50} />
            </div>
          ) : (
            <div className="ms-content-wrapper Dashboard">
              <div className="col-md-12">
                <Breadcrumb />
              </div>

              <Grid container spacing={4}>
                <Grid item lg={8}>
                  <Paper elevation={3}>
                 
                  
                    <Box
                      sx={{
                        "& .adyen-checkout__button": {
                          backgroundColor: "#5773ff",
                          color: "#ededed",
                          transition: "background-color 0.3s ease",
                          "&:hover": {
                            backgroundColor: "#4059b2",
                          },
                        },
                      }}
                      className="payment-container"
                    >
                   
                      <div
                        ref={this.paymentContainer}
                        className="payment"
                      ></div>
                    </Box>
                    {showPaymentFailedDialog && (
                      <Dialog
                        onClose={(e, reason) => {
                          if (reason === "backdropClick") {
                            return;
                          }
                          this.setState({ showPaymentFailedDialog: false });
                        }}
                        open={showPaymentFailedDialog}
                      >
                        <DialogContent>
                          <Box textAlign="center">
                            <WarningAmberIcon
                              color="error"
                              sx={{ fontSize: "4rem" }}
                            />
                            <Typography color="error" variant="h5">
                              Something went wrong
                            </Typography>
                            <Button
                              sx={{ my: 2 }}
                              fullWidth
                              onClick={() => {
                                this.createCheckout();
                                this.setState({
                                  showPaymentFailedDialog: false,
                                });
                              }}
                            >
                              Retry
                            </Button>
                            <Button
                              fullWidth
                              onClick={() =>
                                this.props.history.push({
                                  pathname: "/pricing",
                                })
                              }
                            >
                              Restart
                            </Button>
                          </Box>
                        </DialogContent>
                      </Dialog>
                    )}
                  </Paper>
                </Grid>

                <Grid item xs={12} lg={4}>
                  <Paper elevation={3} sx={{ width: "380px" }}>
                    <Card>
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{
                          background: "#5773ff",
                          textAlign: "center",
                          color: "white",
                          margin: "0px",
                          padding: "6px 0px",
                        }}
                      >
                        Order Details
                      </Typography>
                      <CardContent>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography color="textPrimary" paragraph>
                            <b>{order?.plan_name}</b>
                          </Typography>
                          <Typography
                            color="textPrimary"
                            style={{
                              background: "#5773ff",
                              textAlign: "center",
                              color: "white",
                              borderRadius: "7px",
                              padding: "6px 6px",
                            }}
                            paragraph
                          >
                            {getCurrencySymbol(
                              localStorage.getItem("currency_code")
                            ) + order?.charges}
                          </Typography>
                        </Box>
                        <Divider />
                        <br />
                        {/* <Typography color="textPrimary" paragraph>
                        QR Code Number: 
                      </Typography>
 */}

                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          sx={{ my: 1 }}
                        >
                          <Grid item xs={3} style={{ textAlign: "center" }}>
                            <Typography
                              sx={{
                                background: "#ededed",
                                padding: "5px",
                                borderRadius: "8px",
                                fontWeight: 500,
                              }}
                            >
                              <b>{order?.qr_code_number}</b>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            style={{
                              /* textAlign: 'right', */ position: "relative",
                              top: "5px",
                            }}
                          >
                            <Typography
                              color="textPrimary"
                              paragraph
                              style={{
                                marginTop: "4px",
                              }}
                            >
                              <b> Dynamic QR Codes </b>
                            </Typography>
                          </Grid>
                          <Grid item xs={3} style={{ textAlign: "center" }}>
                            <Typography
                              sx={{
                                background: "#ededed",
                                padding: "5px",
                                borderRadius: "8px",
                                fontWeight: 500,
                              }}
                            >
                              <b>∞</b>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            style={{
                              /* textAlign: 'right',  */ position: "relative",
                              top: "5px",
                            }}
                          >
                            <Typography color="textPrimary" paragraph>
                              <b> Scans </b>
                            </Typography>
                          </Grid>
                          <Grid item xs={3} style={{ textAlign: "center" }}>
                            <Typography
                              sx={{
                                background: "#ededed",
                                padding: "5px",
                                borderRadius: "8px",
                                fontWeight: 500,
                              }}
                            >
                              <b>{order?.users}</b>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            style={{
                              /* textAlign: 'right', */ position: "relative",
                              top: "5px",
                            }}
                          >
                            <Typography color="textPrimary" paragraph>
                              <b> Users </b>
                            </Typography>
                          </Grid>
                        </Grid>

                        <br />

                        <b>
                          {" "}
                          <Divider />
                        </b>
                        <br />
                        {this.state.discountData.discounted_amount ? (
                          <Grid
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography color="textPrimary" paragraph>
                                <b>Discount </b>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography color="textPrimary" paragraph>
                                <b>
                                  {" "}
                                  {
                                    this.state.discountData.discounted_amount
                                  }{" "}
                                </b>
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}

                        <Grid
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography color="textPrimary" paragraph>
                              <b>Total</b>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography color="textPrimary" paragraph>
                              <b>
                                {" "}
                                {getCurrencySymbol(
                                  localStorage.getItem("currency_code")
                                )}{" "}
                              </b>{" "}
                              <b>{this.calculateTotal()}</b>
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Paper>
                  {showApplyDiscount ? (
                    <div style={{ marginTop: "20px", width: "380px" }}>
                      <TextField
                        fullWidth
                        placeholder="Apply Discount Code"
                        variant="outlined"
                        value={this.state.discountCode}
                        onChange={this.handleDiscoundChange}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "3px dashed #5773ff",
                          },
                          "& input": {
                            fontSize: "16px",
                            fontWeight: "bold",
                            "&::placeholder": {
                              fontWeight: "bold",
                            },
                            borderColor: "#5773ff",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ pointerEvents: "none" }}
                            >
                              <DiscountIcon color="primary" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <Stack direction="row">
                                {discountData.discounted_amount ? (
                                  <DeleteIcon
                                    color="primary"
                                    fontSize="large"
                                    onClick={this.handleRemoveDiscount}
                                  />
                                ) : (
                                  ""
                                )}
                                <Button
                                  disabled={discountData.discounted_amount}
                                  variant="contained"
                                  sx={{ width: "80px", marginLeft: "10px" }}
                                  onClick={this.handleDiscountSubmit}
                                >
                                  {discountData.discounted_amount
                                    ? "APPLIED"
                                    : "APPLY"}
                                </Button>
                              </Stack>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>
                {discountFree && (
                  <Grid container>
                    <Grid item lg={12}>
                      <Modal
                        title=""
                        open={discountFree}
                        onCancel={this.handleCancel}
                      >
                        <Result
                          style={{ color: "#5773ff", marginBottom: "0px" }}
                          status="success"
                          title={"Your plan has been activated"}
                          subTitle={
                            <Typography style={{ fontSize: "16px" }}>
                              We have activated this QR plan on your account.
                            </Typography>
                          }
                        />
                        <div style={{ textAlign: "center", marginTop: "0px" }}>
                          <Button
                            size="large"
                            variant="contained"
                            className="white-text"
                            sx={{ height: "40px", width: "180px" }}
                            onClick={this.handleDone}
                          >
                            Done
                          </Button>
                        </div>
                      </Modal>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          )}
        </Fragment>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
  country: state.user.country,
  countryCode: state.user.countryCode,
});
export default withRouter(connect(mapStateToProps, {})(Content));
