import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import "./CategoryModal.css";
import { FlapperSpinner } from "react-spinners-kit";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import * as Schema from "../../../utils/Schema";
import MultipleImageCropper from "../../../shared/Cropper/MultipleImageCropper";

const MenuModal = (props) => {
  return (
    <Fragment>
      <Modal
        className="modal-min"
        show={props.state.show}
        onHide={props.handleModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">
          <h1>{props.title}</h1>
          <button type="button" className="close" onClick={props.handleModal}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <Modal.Body>
          {props.state.loading ? (
            <FlapperSpinner
              size={90}
              color="#233cad"
              loading={props.state.loading}
            />
          ) : (
            <Formik
              initialValues={{
                name: props.state.identifier,
                description: props.state.description,
                sort_order: props.state.sort_order,
                total_selectable_items: props.state.total_selectable_items,
              }}
              validateOnMount
              validationSchema={Schema.categorySchema}
              onSubmit={(values, errors, status, touched) => {
                // same shape as initial values
                props.onClickHandler(values);
              }}
            >
              {({ values, errors, status, touched }) => (
                <Form>
                  <div className="form-row">
                    <div className="col-md-12 ">
                      <div className="ms-card-img">
                        {props.state.imagesPreviewUrl ? (
                          <img src={props.state.imagesPreviewUrl} />
                        ) : null}

                        {/* Cropper 1 */}
                        <MultipleImageCropper
                          data={{
                            key: "category",
                            isMultiple: false,
                            getCroppedImages: props.handleImageChange,
                          }}
                          height={300}
                          width={300}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mt-4">
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <Field
                          name="name"
                          type="text"
                          placeholder="Name"
                          className="form-control"
                        />
                        {/* <ErrorMessage name="name" className="invalid-feedback" /> */}
                        {touched.name ? (
                          <div className="error-message">{errors.name}</div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="validationCustom02">Description</label>
                        <Field
                          name="description"
                          type="text"
                          placeholder="Description"
                          className="form-control"
                        />
                        {/* <ErrorMessage name="description" className="invalid-feedback" /> */}
                        {touched.description ? (
                          <div className="error-message">
                            {errors.description}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="validationCustom02">Sort Order</label>
                      <div className="input-group">
                        <Field
                          name="sort_order"
                          type="number"
                          className="form-control"
                        />
                        {/* <ErrorMessage name="sort_order" className="invalid-feedback" /> */}
                        {touched.sort_order ? (
                          <div className="error-message">
                            {errors.sort_order}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {props.state.is_buffet_category ? (
                      <div className="col-md-12">
                        <label htmlFor="validationCustom02">
                          Total Selectable Items
                        </label>
                        <div className="input-group">
                          <Field
                            name="total_selectable_items"
                            type="number"
                            className="form-control"
                          />
                          {/* <ErrorMessage name="total_selectable_items" className="invalid-feedback" /> */}
                          {touched.total_selectable_items ? (
                            <div className="error-message">
                              {errors.total_selectable_items}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <button
                      className="btn btn-secondary d-block float-right"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default MenuModal;
