import React, { Component, Fragment } from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import * as OrderServiceAPI from "../../../services/order/OrderServiceAPI";
import * as DashboardServiceAPI from "../../../services/dashboard/DashboardServiceAPI";
import * as ReportServiceAPI from "../../../services/reports/ReportServiceAPI";
import * as PaymentLinksServiceAPI from "../../../services/paymentLinks/PaymentLinksServiceAPI";
import DataTableComponet from "react-data-table-component";
import Pagination from "../../../shared/Pagination/Pagination";
import Breadcrumb from "./Breadcrumb";
import "../../../index.css";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { Card, CardContent, IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Loader from "../../../shared/Loader";
import $ from "jquery";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DownloadingIcon from "@mui/icons-material/Downloading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import { Column } from "@ant-design/plots";
import Typography from "@mui/material/Typography";
import { List, ListItem, ListItemText, Button } from "@mui/material";
import { ItemList } from "./ItemList/ItemList.js";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Grid, Paper } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { connect } from "react-redux";
import Switch from "@mui/material/Switch";
import { Form, Formik } from "formik";
import * as schema from "../../../utils/Schema";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormHelperText } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Alert from "@mui/material/Alert";
import ReactGA from "react-ga";
import { tableCellClasses } from "@mui/material/TableCell";
import { useHistory, withRouter } from "react-router-dom";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ShareIcon from "@mui/icons-material/Share";
import IosShareIcon from "@mui/icons-material/IosShare";
import * as APIUrl from "../../../services/APIUrl";
import CircleIcon from "@mui/icons-material/Circle";
import {getCurrencySymbol} from "../../../config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Shopper Name", "Shopper Email", "Telephone Number"];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#5773ff",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ExpandableComponent = ({ data, props }) => {
  const [expandedData, setExpandedData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    // Make the API call when the component is expanded
    if (data && data?.payment_link_id) {
      fetchPaymentLinkTransactions(data?.payment_link_id);
    }
  }, [data]);

  if (!expandedData) {
    return <div>Loading...</div>;
  }

  const fetchTransaction = (row) => {
    const payload = {
      row: row,
    };
    history.push({
      pathname: "/pay-by-link-transaction-details",
      state: payload,
    });
  };

  const fetchPaymentLinkTransactions = async (payment_link_id) => {
    try {
      //    this.setState({ isLoading: true });

      const response =
        await PaymentLinksServiceAPI.fetchPaymentLinkTransactions(
          payment_link_id
        );

      if (response && response.data && response.data.status) {
        //    this.setState({ paymentLinks: response.data.data, isLoading: false });
        setExpandedData(response.data.data);
        // this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
        // toast.success("Discount added. ")
      } else {
        toast.warn("There are no transaction associated with the pay by link.");
        //     this.setState({ paymentLinks: [], isLoading: false });
        //   this.setState({ paymentLinks: [] });
      }
    } catch (error) {
      toast.error("Something has gone wrong.");
      //  this.setState({ paymentLinks: [], isLoading: false });
      throw error;
    }
  };

  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          {/* Render the fetched data */}
          {/*   <TableContainer component={Paper}>
                <Table aria-label="simple table" size="small" dense >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Name</StyledTableCell>
                            <StyledTableCell align="center">Email</StyledTableCell>
                            <StyledTableCell align="center">PSP Refrence</StyledTableCell>
                            <StyledTableCell align="center">Transaction Status</StyledTableCell>
                            <StyledTableCell align="center">Transaction Id</StyledTableCell>
                            <StyledTableCell align="center">Value</StyledTableCell>
                            <StyledTableCell align="center">Reference</StyledTableCell>
                            <StyledTableCell align="center">Card No</StyledTableCell>

                            <StyledTableCell align="center">Phone No</StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {(expandedData || []).map((item, index) => (
                            <TableRow >
                                <StyledTableCell align="center" >{item?.name}</StyledTableCell>
                                <StyledTableCell align="center"  >{item?.email}</StyledTableCell>
                                <StyledTableCell align="center"  >{item?.psp_reference}</StyledTableCell>
                                <StyledTableCell align="center"  >{item?.transaction_status}</StyledTableCell>
                                <StyledTableCell align="center"  >{item?.transaction_id}</StyledTableCell>
                                <StyledTableCell align="center" >{item?.value}</StyledTableCell>
                                <StyledTableCell align="center" >{item?.reference}</StyledTableCell>
                                <StyledTableCell align="center" >{item?.card_number}</StyledTableCell>
                                <StyledTableCell align="center" >{item?.phone_number}</StyledTableCell>
                            </TableRow>
                        ))}

                       
                    </TableBody>


                </Table>
            </TableContainer> */}

          <table class="table table-responsive table-striped ">
            {expandedData.length > 0 && (
              <thead>
                <tr>
                  <th align="center">No.</th>
                  <th align="center">Name</th>
                  {/* <th align="center">Email</th> */}
                  <th align="center">PSP Refrence</th>
                  <th align="center">Status</th>
                  {/*      <th align="center">Transaction Id</th> */}
                  <th align="center">Value</th>
                  <th align="center">Action</th>
                  {/*               <th align="center">Reference</th> */}
                  {/*      <th align="center">Card No</th> */}
                  {/*       <th align="center">Phone No</th> */}
                </tr>
              </thead>
            )}
            <tbody>
              {(expandedData || []).map((item, index) => (
                <tr>
                  <th align="center">{index + 1}.</th>
                  <td align="center">{item.name}</td>
                  {/*      <td align="center">{item.email}</td> */}
                  <td align="center">{item.psp_reference}</td>
                  <td align="center">{item.transaction_status}</td>
                  {/*   <td align="center">{item.transaction_id}</td> */}
                  <td align="center">  {getCurrencySymbol(localStorage.getItem('currency_code')) + item.value?.toFixed(2)}</td>
                  <td align="center">
                    {" "}
                    <IconButton
                      color="primary"
                      aria-label="view"
                      onClick={() => fetchTransaction(item)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </td>
                  {/*    <td align="center">{item.reference}</td> */}
                  {/*   <td align="center">{item.card_number}</td> */}
                  {/*    <td align="center">{item.phone_number}</td> */}
                </tr>
              ))}
            </tbody>
          </table>

          {/* Other content */}
        </div>
      </div>
    </div>
  );
};

class Content extends Component {
  async copyToClipboard(text) {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        toast.success("Text copied to clipboard.");
      } else {
        // Fallback for browsers that do not support the Clipboard API
        const textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        toast.success("Text copied to clipboard.");
      }
    } catch (err) {
      console.error("Unable to copy text to clipboard", err);
    }
  }

  async shareLink(shareData) {
    if (navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        console.error("Error sharing link:", error);
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
      // alert('Web Share API is not supported in your browser. You can manually share the link.');
    }
  }

  handleCopyClick = async (link) => {
    const middle = "/pbl-tnc?property_id=";
    const url = process.env.REACT_APP_API_BASE + middle + this.props.propertyId;

    const copyText = `To make payment to ${localStorage.getItem(
      "business_trading_name"
    )} . Please click on the link below. \n${link}
This payment is made by Pay by link, and here are our terms and conditions. \n${
      this.state.termsConditionsLink
    }`;

    await this.copyToClipboard(copyText);

    const shareData = {
      title: "Payment Link",
      text: "Check out this link:",
      url: link,
    };

    await this.shareLink(shareData);
  };

  customValiditySort = (rowA, rowB) => {
    const dateA = new Date(rowA.link_validity);
    const dateB = new Date(rowB.link_validity);

    return dateA - dateB; // Compare Date objects
  };

  customStyles = {
    rows: {
      style: {
        //   minHeight: '70px',
      },
    },
    headCells: {
      style: {
        //                   minHeight: '65px',

        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        justifyContent: "left",
        "&:hover div": {
          backgroundColor: "transparent",
          color: "white !important",
        },
        div: {
          color: "white !important",
        },
        span: {
          color: "white !important",
        },
      },
    },
    cells: {
      style: {
        pointer: "cursor",
      },
    },
  };

  columns = [
    {
      name: "Payment Link",
      selector: "payment_link_id",
      sortable: true,
      justifyContent: "center",
      Width: "320px",
      cell: (row) => (
        <div className="payment-link-container">
          {row.kbsd_status === "ACTIVE" ? (
            <a href={row.url} target="_blank">
              {row.payment_link_id}
            </a>
          ) : (
            row.payment_link_id
          )}
          {row.kbsd_status === "ACTIVE" && (
            <IconButton
              color="primary"
              onClick={() => this.handleCopyClick(row.url)}
            >
              <FileCopyIcon />
            </IconButton>
          )}
          {/* <div>
            {row.is_pre_auth ? (
              <span
                style={{
                  color: "#ededed",
                  background: "#5773ff",
                  margin: "8px",
                  borderRadius: "5px",
                  height: "30px",
                  fontSize: "14px",
                  alignItems: "center",
                  display: "flex",
                  padding: "8px",
                }}
              >
                Pre-Auth
              </span>
            ) : (
              ""
            )}
          </div> */}
        </div>
      ),
    },

    {
      name: "Merchant reference",
      selector: "merchant_reference",
      sortable: true,
      justifyContent: "center",
      minWidth: "180px",
      cell: (row) => (
        <div>
          <div>{row.merchant_reference}</div>
        </div>
      ),
    },

    {
      name: "Amount",
      selector: "value",
      sortable: true,
      justifyContent: "center",
      minWidth: "160px",
      cell: (row) => (
        <div>
          <div>{row.currency + " " + row.value?.toFixed(2)}</div>
        </div>
      ),
    },

    {
      name: "Type",
      selector: "link_type",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {row.link_type}</div>,
    },

    {
      name: "Validity",
      selector: "link_validity",
      sortable: true,
      justifyContent: "center",
      sortFunction: this.customValiditySort,
      Width: "150px",
      cell: (row) => <div> {row.link_validity}</div>,
    },
    {
      name: "Status",
      selector: "kbsd_status",
      sortable: true,
      justifyContent: "center",
      Width: "150px",
      cell: (row) => <div> {row.kbsd_status}</div>,
    },
    {
      name: "Action",
      selector: "action",
      justifyContent: "center",
      width:"180px",
      cell: (row) => (
        <div>
          {/* <IconButton
            color="primary"
            onClick={() => this.fetchTransaction(row)}
          >
            <VisibilityIcon />
          </IconButton> */}
          <Button variant="outlined" startIcon={<VisibilityIcon color="primary" />} onClick={() => this.fetchTransaction(row)} sx={{alignItems:"flex-start"}}>
View
          </Button>
        </div>
      ),
    },
  ];

  customHeader = ({ column }) => {
    return (
      <div style={{ textAlign: column.center ? "center" : "" }}>
        {column.name}
      </div>
    );
  };

  state = {
    initialValues: {},
    page: 0,
    order_details: [],
    current_page: 0,
    current_orders_page: 0,
    number_of_elements: 15,
    page_size: 10,
    total_elements: 0,
    total_pages: 0,
    total_order_pages: 0,
    property_id: "",
    search_text: "",
    changeBool: null,
    from_date: null,
    to_date: null,
    date_options_dd: null,
    isLoading: false,
    count: 0,
    hideFilter: false,
    start: dayjs(new Date()).startOf("month"),
    end: dayjs(new Date()),
    data: [],
    topSellingItems: [],
    trendingItemsSalesReport: [],
    unSoldItems: [],
    currentPage: 1,
    itemsPerPage: 10,
    tab: 0,
    selectTimePeriod: "DAILY",
    selectReport: "Item",
    max: new Date(),
    min: dayjs(new Date()).subtract(3, "year"),
    filterToday: false,
    filterYesterday: false,
    filterLastWeek: false,
    filterLastMonth: false,
    filterLastYear: false,
    paymentLinks: [],
    validity: dayjs()/* .utcOffset(1) */.add(24, "hour"),
    expireValidity: dayjs()
    //  .utcOffset(1)
      .add(24, "hour")
      .format("MMM D, YYYY, HH:mm:ss"),
    showForm: false,
    customValue: 1,
    period: "day",
    customTimePeriodFields: false,
    linkValidityPeriod: 24,
    bool: false,
    selection: "",
    selections: [],
    maxDays: 70,
    description: 0,
    termsConditionsLink: "",
  };

  constructor(props) {
    super(props);
    // this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount = () => {
    this.setInitalValues();

    this.fetchAllPaymentLinks();

    this.fetchTermsContidionsLink();

    setInterval(this.updateTimestamp, 1000);

    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }
  };

  fetchTransaction = (row) => {
    const payload = {
      row: row,
    };
    this.props.history.push({
      pathname: "/pay-by-link-transactions",
      state: payload,
    });
  };

  updateTimestamp = () => {
    this.setState({
      validity: dayjs()/* .utcOffset(1) */.add(this.state.customValue, "day"),
      expireValidity: dayjs()
     //   .utcOffset(1)
        .add(this.state.customValue, "day")
        .format("MMM D, YYYY, HH:mm:ss"),
    });

    /*   if (this.state.linkValidityPeriod !== "custom") {
              this.setState({
                  validity: dayjs().utcOffset(1).add(this.state.linkValidityPeriod, 'hour'),
                  expireValidity: dayjs().utcOffset(1).add(this.state.linkValidityPeriod, 'hour').format('MMM D, YYYY, HH:mm:ss')
              })
          } else {
              if (this.state.period == "hour") {
                  this.setState({
                      validity: dayjs().utcOffset(1).add(this.state.customValue, 'hour'),
                      expireValidity: dayjs().utcOffset(1).add(this.state.customValue, 'hour').format('MMM D, YYYY, HH:mm:ss')
                  })
              } else if (this.state.period == "minute") {
                  this.setState({
                      validity: dayjs().utcOffset(1).add(this.state.customValue, 'minute'),
                      expireValidity: dayjs().utcOffset(1).add(this.state.customValue, 'minute').format('MMM D, YYYY, HH:mm:ss')
                  })
              } else if (this.state.period == "day") {
                  this.setState({
                      validity: dayjs().utcOffset(1).add(this.state.customValue, 'day'),
                      expireValidity: dayjs().utcOffset(1).add(this.state.customValue, 'day').format('MMM D, YYYY, HH:mm:ss')
                  })
              }
          } */
  };

  setInitalValues = () => {
    let addInitialValues = {
      merchant_reference: "",
      value: "",
      description: "",
      reusable: false,
      is_pre_auth: false,
      property_id: "",
      link_validity:
        this.state.expireValidity /* .format('YYYY-MM-DDTHH:mm:ssZ') */,
    };
    this.setState({
      initialValues: addInitialValues,
    });
  };

  showForm = () => {
    if (!this.state.showForm) {
      this.setState({
        showForm: true,
        selections: [],
      });
    } else {
      this.setState({
        showForm: false,
        customTimePeriodFields: false,
        linkValidityPeriod: 24,
        selections: [],
      });
    }
  };

  fetchAllPaymentLinks = async () => {
    try {
      this.setState({ isLoading: true });
      let propertId = this.props.propertyId;
      const response = await PaymentLinksServiceAPI.fetchAllPaymentLinks(
        propertId
      );

      if (response && response.data && response.data.status) {
        this.setState({ paymentLinks: response.data.data, isLoading: false });
        // this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
        // toast.success("Discount added. ")
      } else {
        toast.warn("There are no pay by link records..");
        this.setState({ paymentLinks: [], isLoading: false });
        //   this.setState({ paymentLinks: [] });
      }
    } catch (error) {
      toast.error("Something has gone wrong.");
      this.setState({ paymentLinks: [], isLoading: false });
      throw error;
    }
  };

  fetchTermsContidionsLink = async () => {
    try {
      this.setState({ isLoading: true });
      let propertId = this.props.propertyId;
      const response = await PaymentLinksServiceAPI.fetchTermsConditons(
        propertId
      );

      if (response && response.data && response.data.status) {
        this.setState({
          termsConditionsLink:
            response.data.data.property_terms_and_conditions_link,
          isLoading: false,
        });
        // this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
        // toast.success("Discount added. ")
      } else {
        //  toast.warn("There are no pay by link records..")
        this.setState({ termsConditionsLink: "", isLoading: false });
        //   this.setState({ paymentLinks: [] });
      }
    } catch (error) {
      toast.error("Something has gone wrong.");
      this.setState({ termsConditionsLink: null, isLoading: false });
      throw error;
    }
  };

  addPaymentLink = async (values) => {
    try {
      this.setState({ bool: true });
      let propertId = this.props.propertyId;
      values.property_id = this.props.propertyId;
      values.link_validity = this.state.validity.format("YYYY-MM-DDTHH:mm:ss");
      let shopper_fields = [];

      if (this.state.selections.includes("Shopper Name"))
        shopper_fields.push("shopperName");
      if (this.state.selections.includes("Telephone Number"))
        shopper_fields.push("telephoneNumber");
      if (this.state.selections.includes("Shopper Email"))
        shopper_fields.push("shopperEmail");

      values.shopper_fields = shopper_fields;
      /*     delete values.phone;
                delete values.email;
                delete values.name; */

      let response = await PaymentLinksServiceAPI.addPaymentLink(values);

      if (response.data.status) {
        //   this.setState({ paymentLinks: response.data.data });
        // this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
        toast.success("Payment Link Created. ");
        this.fetchAllPaymentLinks();
        this.setState({
          showForm: false,
          customTimePeriodFields: false,
          linkValidityPeriod: 24,
          customValue: 1,
          bool: false,
          selections: [],
        });
      } else {
        toast.warn("Couldn't create a payment link.");
        this.setState({ bool: false });
        //      this.setState({ paymentLinks: response.data.data });
        //   this.setState({ paymentLinks: [] });
      }
    } catch (error) {
      this.setState({ bool: false });
      toast.error("Something has gone wrong.");
      throw error;
    }
  };

  deletePaymentLink = (payment_link_id) => {
    PaymentLinksServiceAPI.deleteHPaymentLink(payment_link_id)
      .then((response) => {
        if (response.data.status) {
          toast.success("Payment Link  deleted. ");
          this.setState({ open: false });
          this.fetchAllPaymentLinks();
        } else {
          toast.warn("Couldn't delete. ");
          this.setState({ isLoading: false, open: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, open: false });
        toast.error("Something went wrong. ");
      });
  };

  handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    this.setState(
      {
        // On autofill we get a stringified value.
        selections: typeof value === "string" ? value.split(",") : value,
      },
   
    );
  };

  selectTrenidngReportTimePeriod = (e) => {
    /*  if (e.target.value == "HOURLY") {
             this.setState({ selectTimePeriod: e.target.value, start: dayjs(new Date()).startOf('week'), end: dayjs(new Date()) }, () => {
                 this.getTrendingSalesReport();
 
             });
 
         } else { */
    this.setState({ selectTimePeriod: e.target.value }, () => {
      this.getTrendingSalesReport();
    });
    //     }
  };

  getTrendingSalesReport = () => {
    this.setState({ isLoading: true });

    let payload = {
      start_date: this.state.start.format("DD-MM-YYYY"),
      end_date: this.state.end.format("DD-MM-YYYY"),
      property_id: localStorage.getItem("property_id"),
      time_interval: this.state.selectTimePeriod,
    };

    ReportServiceAPI.trendingItemSalesReport(payload)
      .then((response) => {
        if (response.data.status) {

          let trending = response.data.data;

          if (this.state.selectTimePeriod == "HOURLY") {
            trending.sort((a, b) => {
              let [hourA, minuteA, periodA] = a.Hour.split(/[ :]/);
              let [hourB, minuteB, periodB] = b.Hour.split(/[ :]/);

              if (periodA === periodB) {
                if (parseInt(hourA) === 12) {
                  hourA = "00";
                }
                if (parseInt(hourB) === 12) {
                  hourB = "00";
                }
                let timeA = parseInt(hourA) * 60 + parseInt(minuteA);
                let timeB = parseInt(hourB) * 60 + parseInt(minuteB);
                return timeA - timeB;
              } else if (periodA === "AM") {
                return -1;
              } else {
                return 1;
              }
            });
          }


          this.setState({
            trendingItemsSalesReport: trending,
            isLoading: false,
          });
        } else {
          //     toast.error(response.data.message);
          this.setState({ isLoading: false, trendingItemsSalesReport: [] });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  getTimeValue = (hourStr) => {
    const [hour, minute, period] = hourStr.split(/[:\s]/);
    const hourValue = parseInt(hour, 10) % 12;
    const minuteValue = parseInt(minute, 10);
    const periodValue = period.toUpperCase() === "AM" ? 0 : 1;
    return hourValue * 60 + minuteValue + periodValue * 12 * 60;
  };

  getUnSoldItems = () => {
    let payload = {
      start_date: this.state.start.format("DD-MM-YYYY"),
      end_date: this.state.end.format("DD-MM-YYYY"),
      property_id: localStorage.getItem("property_id"),
    };

    ReportServiceAPI.fetchUnSoldItems(payload)
      .then((response) => {
        if (response.data.status) {

          this.setState({ unSoldItems: response.data.data, isLoading: false });
        } else {
          //     toast.error(response.data.message);
          this.setState({ isLoading: false, unSoldItems: [] });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tab: newValue });
  };

  handleViewInstructions = () => {
    window.scroll({
      top:  document.documentElement.scrollHeight, 
      behavior: "smooth", 
    });
  };
  handleToDate = (e) => {
    let toDat = dayjs(e);
    /*    this.setState({ end: toDat }, () => {
               this.getTrendingSalesReport();
           });
    */
    /*  if (this.state.selectTimePeriod == "HOURLY") {
             this.setState({ start:  dayjs(toDat).startOf('week'), end: dayjs(toDat).endOf('week') }, () => {
                 this.getTrendingSalesReport();
 
             });
 
         } else { */
    this.setState({ end: toDat }, () => {
      this.getTrendingSalesReport();
    });
    //     }
  };

  handleFromDate = (e) => {
    let toDat = dayjs(e);
    /*   this.setState({ start: toDat }, () => {
  
              this.getTrendingSalesReport();
          }); */

    /*     if (this.state.selectTimePeriod == "HOURLY") {
                this.setState({ start:  dayjs(toDat).startOf('week'), end: dayjs(toDat).endOf('week') }, () => {
                    this.getTrendingSalesReport();
    
                });
    
            } else { */
    this.setState({ start: toDat }, () => {
      this.getTrendingSalesReport();
    });
    //    }
  };

  handleValidity = (event) => {
    let selectedValue = event.target.value;
    this.setState({ linkValidityPeriod: selectedValue });
    if (selectedValue == 24) {
      this.setState({
        customTimePeriodFields: false,
        validity: dayjs()/* .utcOffset(1) */.add(selectedValue, "hour"),
        expireValidity: dayjs()
         /*  .utcOffset(1) */
          .add(selectedValue, "hour")
          .format("MMM D, YYYY, HH:mm:ss"),
      });
    } else if (selectedValue == 48) {
      this.setState({
        customTimePeriodFields: false,
        validity: dayjs()/* .utcOffset(1) */.add(selectedValue, "hour"),
        expireValidity: dayjs()
          /* .utcOffset(1) */
          .add(selectedValue, "hour")
          .format("MMM D, YYYY, HH:mm:ss"),
      });
    } else if (selectedValue === "custom") {
      this.setState({ customTimePeriodFields: true });
    }

    // Handle the selected value here
  };

  handleCustomValidity = (e) => {
    const { value } = e.target;
    this.setState({ customValue: value });
    this.setState({
      customTimePeriodFields: true,
      validity: dayjs()/* .utcOffset(1) */.add(value, "day"),
      expireValidity: dayjs()
        // .utcOffset(1)
        .add(value, "day")
        .format("MMM D, YYYY, HH:mm:ss"),
    });
    /*  if (this.state.period == "day") {
             this.setState({ customTimePeriodFields: true, validity: dayjs().utcOffset(1).add(value, 'day'), expireValidity: dayjs().utcOffset(1).add(value, 'day').format('MMM D, YYYY, HH:mm:ss') });
         } else if (this.state.period == "hour") {
             this.setState({ customTimePeriodFields: true, validity: dayjs().utcOffset(1).add(value, 'hour'), expireValidity: dayjs().utcOffset(1).add(value, 'hour').format('MMM D, YYYY, HH:mm:ss') });
         } else if (this.state.period == "minute") {
             this.setState({ customTimePeriodFields: true, validity: dayjs().utcOffset(1).add(value, 'minute'), expireValidity: dayjs().utcOffset(1).add(value, 'minute').format('MMM D, YYYY, HH:mm:ss') });
         } */
  };

  handleTimePeriod = (e) => {
    const { value } = e.target;
    this.setState({ period: value });
    if (this.state.customValue && value == "day") {
      this.setState({
        customTimePeriodFields: true,
        validity: dayjs()./* utcOffset(1) */add(this.state.customValue, "day"),
        expireValidity: dayjs()
       //   .utcOffset(1)
          .add(this.state.customValue, "day")
          .format("MMM D, YYYY, HH:mm:ss"),
      });
    } else if (this.state.customValue && value == "hour") {
      this.setState({
        customTimePeriodFields: true,
        validity: dayjs().utcOffset(1).add(this.state.customValue, "hour"),
        expireValidity: dayjs()
     //     .utcOffset(1)
          .add(this.state.customValue, "hour")
          .format("MMM D, YYYY, HH:mm:ss"),
      });
    } else if (this.state.customValue && value == "minute") {
      this.setState({
        customTimePeriodFields: true,
        validity: dayjs()/* .utcOffset(1) */.add(this.state.customValue, "minute"),
        expireValidity: dayjs()
     //     .utcOffset(1)
          .add(this.state.customValue, "minute")
          .format("MMM D, YYYY, HH:mm:ss"),
      });
    }
  };

  render() {
    const {
      initialValues,
      expireValidity,
      showForm,
      customValue,
      period,
      customTimePeriodFields,
      bool,
      selection,
      selections,
    } = this.state;

    return (
      <Fragment>
        <Breadcrumb />
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              {/*                    <AppBar position="static" color="default">
                                <Tabs
                                    value={tab}
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    TabIndicatorProps={{ style: { background: "#5773ff" } }}
                                >
                                    <Tab label="X Report" />
                                 <Tab label="Unsold Items" /> *
                                </Tabs>
                                <TabPanel value={tab} index={0} className="tabPanel"> */}
              {this.state.isLoading ? (
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "40px",
                  }}
                >
                  {" "}
                  <Loader size={50} />{" "}
                </div>
              ) : (
                <div className="ms-panel">
                  <div className="ms-panel-body">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12">
                        {!showForm && (
                          <React.Fragment>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xs={12} md={6} lg={6}>
                                <h5>Payment Links</h5>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={6}
                                lg={6}
                                style={{ textAlign: "right" }}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={this.showForm}
                                >
                                  Create Payment Link
                                </Button>
                              </Grid>
                            </Grid>
                            <br />
                            <Divider />
                          </React.Fragment>
                        )}

                        {showForm && (
                          <React.Fragment>
                            <Typography variant="h6" gutterBottom>
                              <div style={{ marginBottom: "30px" }}>
                                Pay By Link
                                <Button
                                  variant="contained"
                                  style={{ float: "right" }}
                                  color="primary"
                                  onClick={this.showForm}
                                >
                                  Back
                                </Button>
                              </div>
                            </Typography>
                            <Divider />
                            <br /> <br />
                            <Grid container sx={{ display: "flex" }}>
                              <Grid container xs={12} md={8} lg={8}>
                                <div style={{ display: "block" }}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                      mb: 2,
                                      mr: "0px",
                                      width: "220px",
                                      height: "40px",
                                      display: { xs: "block", md: "none" },
                                    }}
                                    onClick={this.handleViewInstructions}
                                  >
                                    View Instructions
                                  </Button>{" "}
                                  <Typography variant="h6" gutterBottom>
                                    Create Payment Link
                                  </Typography>
                                  <p>
                                    Complete the following details to create
                                    your payment link.
                                  </p>
                                </div>
                                <Formik
                                  enableReinitialize="true"
                                  initialValues={initialValues}
                                  validationSchema={schema.paymentLinkSchema}
                                  onSubmit={(values, { resetForm }) => {
                                    this.addPaymentLink(values);
                                  }}
                                >
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue,
                                  }) => (
                                    <Form onSubmit={handleSubmit}>
                                      <Grid
                                        container
                                        spacing={3}
                                        direction="row"
                                      >
                                        <Grid item xs={12} md={10} lg={10}>
                                          <FormControl fullWidth>
                                            <TextField
                                              required
                                              error={
                                                touched.merchant_reference &&
                                                errors.merchant_reference
                                                  ? true
                                                  : false
                                              }
                                              id="merchant_reference"
                                              label="Merchant reference"
                                              type="text"
                                              name="merchant_reference"
                                              variant="standard"
                                              value={values.merchant_reference}
                                              onChange={handleChange}
                                              helperText={
                                                touched.merchant_reference &&
                                                errors.merchant_reference
                                                  ? errors.merchant_reference
                                                  : null
                                              }
                                            />
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={5} lg={5}>
                                          <FormControl fullWidth>
                                            <TextField
                                              required
                                              error={
                                                touched.value && errors.value
                                                  ? true
                                                  : false
                                              }
                                              id="value"
                                              label="Amount"
                                              type="text"
                                              name="value"
                                              variant="standard"
                                              value={values.value}
                                              onInput={(event) => {
                                                if (
                                                  !isNaN(event.target.value)
                                                ) {
                                                  event.target.value = !isNaN(
                                                    event.target.value
                                                  )
                                                    ? event.target.value
                                                    : "";
                                                } else {
                                                  event.target.value = "";
                                                }
                                              }}
                                              onChange={handleChange}
                                              helperText={
                                                touched.value && errors.value
                                                  ? errors.value
                                                  : null
                                              }
                                            />
                                          </FormControl>
                                        </Grid>

                                        {/*       <Grid item xs={12} md={3} lg={3} style={{ display: 'flex', flexDirection: "row", alignItems: 'left' }}>
                                                                        <FormControl >
                                                                            <FormControlLabel style={{ display: 'flex', flexDirection: "row", alignItems: 'left' }}
                                                                                control={
                                                                                    <Switch
                                                                                        checked={values.reusable === true}
                                                                                        onChange={handleChange}
                                                                                        name="reusable"
                                                                                        color="primary"
                                                                                        inputProps={{
                                                                                            "aria-label": "reusable checkbox",
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                labelPlacement="start"
                                                                                label="Reusable"
                                                                            />
                                                                            <FormHelperText>
                                                                                Reusable links allow multiple payments.
                                                                            </FormHelperText>
                                                                        </FormControl>


                                                                    </Grid> */}

                                        {/*      <Grid item xs={12} md={3} lg={4} >
                                                                        <FormControl>
                                                                            <FormLabel id="demo-radio-buttons-group-label">Link Validity</FormLabel>
                                                                            <RadioGroup
                                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                                defaultValue={this.state.linkValidityPeriod}
                                                                                name="radio-buttons-group"
                                                                                onChange={this.handleValidity}
                                                                                style={{ display: 'flex', flexDirection: 'row' }}
                                                                            >
                                                                                <FormControlLabel value="24" control={<Radio />} label="24 Hours" />
                                                                                <FormControlLabel value="48" control={<Radio />} label="48 Hours" />
                                                                                <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                                                                            </RadioGroup>


                                                                        </FormControl>
                                                                        <p>  Expires on {expireValidity}   &nbsp; &nbsp; (United Kingdom)</p>

                                                                    </Grid> */}

                                        {/*    {customTimePeriodFields &&

                                                                        <React.Fragment> */}

                                        <Grid item xs={12} md={5} lg={5}>
                                          <FormControl fullWidth>
                                            <TextField
                                              required
                                              id="bumbber"
                                              label="Days"
                                              type="number"
                                              name="number"
                                              variant="standard"
                                              value={customValue}
                                              onChange={
                                                this.handleCustomValidity
                                              }
                                              InputProps={{
                                                inputProps: {
                                                  min: 1, // minimum value
                                                  max:
                                                    this.state.period === "day"
                                                      ? 70
                                                      : this.state.period ===
                                                        "hour"
                                                      ? 1680
                                                      : 100800, // maximum value
                                                },
                                              }}
                                            />
                                          </FormControl>
                                           {/*  <p style={{ color: "grey" }}> 70 days (max limit - no of days ).</p>
                                            <p style={{ color: "grey" }}>  Expires on {expireValidity}   &nbsp; &nbsp; (United Kingdom)</p>  */}
                                        </Grid>

                                        <Grid item xs={12} md={10} lg={10}>
                                          <FormControl fullWidth>
                                            <TextField
                                              id="ddescription"
                                              label="Description"
                                              type="text"
                                              name="description"
                                              variant="standard"
                                              value={values.description}
                                              onChange={(e) => {
                                                //  handleChange();
                                                setFieldValue(
                                                  "description",
                                                  e.target.value
                                                );
                                                this.setState({
                                                  description:
                                                    e.target.value.length,
                                                });
                                              }}
                                              multiline
                                              rows={4}
                                              fullWidth
                                            />
                                          </FormControl>
                                          <span style={{ color: "red" }}>
                                            {300 - this.state.description}
                                          </span>{" "}
                                          / 300
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          md={5}
                                          lg={5}
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "left",
                                          }}
                                        >
                                          <FormControl>
                                            <FormControlLabel
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "left",
                                                fontWeight: "600",
                                              }}
                                              control={
                                                <Switch
                                                  checked={
                                                    values.is_pre_auth === true
                                                  }
                                                  onChange={handleChange}
                                                  name="is_pre_auth"
                                                  color="primary"
                                                  inputProps={{
                                                    "aria-label":
                                                      "reusable checkbox",
                                                  }}
                                                />
                                              }
                                              labelPlacement="start"
                                              label={
                                                <Typography
                                                  variant="body1"
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  Pre-Authorization
                                                </Typography>
                                              }
                                            />
                                          </FormControl>
                                        </Grid>
                                        {/*   <p>  Expires on {expireValidity}   &nbsp; &nbsp; (United Kingdom)</p> */}

                                        <Grid item xs={12} md={3} lg={3}>
                                          <FormControl
                                            style={{ visibility: "hidden" }}
                                            fullWidth
                                          >
                                            <InputLabel id="demo-simple-select-label">
                                              Select
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={period}
                                              label="Select"
                                              onChange={this.handleTimePeriod}
                                            >
                                              <MenuItem value={"minute"}>
                                                Minute
                                              </MenuItem>
                                              <MenuItem value={"hour"}>
                                                Hour
                                              </MenuItem>
                                              <MenuItem value={"day"}>
                                                Day
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={10} lg={10}>
                                          <div>
                                            <Button
                                              disabled={bool}
                                              variant="contained"
                                              type="submit"
                                              color="primary"
                                              sx={{
                                                mb: 2,
                                                ml: "25px",
                                                width: "250px",
                                                height: "40px",
                                              }}
                                            >
                                              Create Payment Link
                                            </Button>
                                          </div>
                                        </Grid>

                                        {/*        </React.Fragment>} */}

                                        {/*   <Grid item xs={12} md={3} lg={12} >
                                                                        <Typography variant="h6" gutterBottom>

                                                                            Other Additional details (Optional).


                                                                        </Typography>
                                                                    </Grid> */}

                                        {/*   <Grid item xs={12} md={3} lg={4} >
                                                                

                                                                        <FormControl fullWidth>
                                                                            <InputLabel id="demo-multiple-checkbox-label">Select</InputLabel>
                                                                            <Select
                                                                                labelId="demo-multiple-checkbox-label"
                                                                                id="demo-multiple-checkbox"
                                                                                multiple
                                                                                value={selections}
                                                                                onChange={this.handleSelectChange}
                                                                                input={<OutlinedInput label="Select" />}
                                                                                renderValue={(selected) => selected.join(', ')}
                                                                                MenuProps={MenuProps}
                                                                            >
                                                                                {names.map((name) => (
                                                                                    <MenuItem key={name} value={name}>
                                                                                        <Checkbox checked={selections.indexOf(name) > -1} />
                                                                                        <ListItemText primary={name} />
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                      

                                                                    </Grid> */}
                                      </Grid>
                                    </Form>
                                  )}
                                </Formik>
                              </Grid>
                              <Grid container xs={12} md={4} lg={4}>
                                <Grid item xs={12} md={12} lg={12}>
                                  <Box
                                    sx={{ padding: "20px", paddingTop: "0" }}
                                  >
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "auto",
                                      }}
                                    >
                                      <Card>
                                        <Typography
                                          variant="h5"
                                          gutterBottom
                                          style={{
                                            background: "#5773ff",
                                            textAlign: "center",
                                            color: "white",
                                            margin: "0px",
                                            padding: "6px 0px",
                                          }}
                                        >
                                          Instructions
                                        </Typography>
                                        <CardContent>
                                          <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                          >
                                            <Typography
                                              color="textPrimary"
                                              paragraph
                                            >
                                              <b>
                                                You can create your payment
                                                links to receive payments from
                                                your customers and keep track of
                                                its status.
                                              </b>
                                            </Typography>
                                          </Box>
                                          <Grid
                                            container
                                            spacing={1}
                                            alignItems="center"
                                            sx={{ my: 1 }}
                                          >
                                            <Grid
                                              item
                                              xs={12}
                                              md={12}
                                              lg={12}
                                              style={{ textAlign: "left" }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: 500,
                                                }}
                                              >
                                                <b> Merchant Reference</b>
                                              </Typography>
                                              <Typography
                                                color="textPrimary"
                                                paragraph
                                              >
                                                Merchant Reference typically
                                                refers to a unique identifier
                                                assigned to this link.
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              md={12}
                                              lg={12}
                                              style={{ textAlign: "left" }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: 500,
                                                }}
                                              >
                                                <b> Amount & Days </b>
                                              </Typography>
                                              <Typography
                                                color="textPrimary"
                                                paragraph
                                              >
                                                These refer to the monetary
                                                value and active days associated
                                                with this link.
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              md={12}
                                              lg={12}
                                              style={{ textAlign: "left" }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: 500,
                                                }}
                                              >
                                                <b> Payment Description</b>
                                              </Typography>
                                              <Typography
                                                color="textPrimary"
                                                paragraph
                                              >
                                                For further brief description
                                                about the payment.
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              md={12}
                                              lg={12}
                                              style={{ textAlign: "left" }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: 500,
                                                }}
                                              >
                                                <b> Pre-Authorization </b>
                                              </Typography>
                                              <Typography
                                                color="textPrimary"
                                                paragraph
                                              >
                                                If you want to authorize a
                                                payment before capturing it,
                                                enable the Pre-Authorization
                                                option.
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                    </Paper>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}

                        {!showForm && (
                          <Grid item xs={12} md={3} lg={12}>
                            <Typography
                              style={{
                                textAlign: "right",
                                padding: "10px",
                                margin: "10px",
                                alignItems: "center",
                                fontWeight: "bold",
                              }}
                            >
                              <CircleIcon
                                size="large"
                                sx={{ color: "#9EDDFF", marginRight: "8px" }}
                              />
                              Pre-Authorised Links
                            </Typography>
                            <DataTable
                              columns={this.columns}
                              customStyles={this.customStyles}
                              pagination
                              paginationRowsPerPageOptions={[
                                10, 25, 50, 100, 500, 1000,
                              ]}
                              data={this.state.paymentLinks}
                              conditionalRowStyles={[
                                {
                                  when: (row) => row.is_pre_auth,
                                  style: {
                                    backgroundColor: "#9EDDFF",
                                  },
                                },
                              ]}
                            />
                          </Grid>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/*   </TabPanel>
                                <TabPanel value={tab} index={1}>
                                    <div className="ms-panel">
                                        <div className="ms-panel-body">
                                            <div className="col-md-12">

                                                <Typography variant="h5" gutterBottom style={{}}>
                                                    Inventory of Unsold Items

                                                </Typography>
                                                <ItemList items={this.state.unSoldItems} />
                                            </div>

                                        </div>
                                    </div>
                                </TabPanel>

                            </AppBar> */}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default connect(mapStateToProps, {})(Content);
