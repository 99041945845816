import React from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import * as schema from "../../../utils/Schema";
import MultipleImageCropper from "../../../shared/Cropper/MultipleImageCropper";
import {
  addCategory,
  updateCategory,
} from "../../../services/product/category/CategoryServiceAPI";
import {
  setSelectedMenu,
  setSelectedCategory,
} from "../../../redux/menu/menuSlice";
import Loader from "../../../shared/Loader";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import { FormControlLabel, Switch } from "@mui/material";
import coming from "../../../assets/img/default.jpg";
class AddEditCategory extends React.Component {
  constructor(props) {
    super(props);
    console.log(props, "<<<<<PORPS CAT>>>>>");
    this.state = {
      file: null,
      imagesPreviewUrl: null,
      submitting: false,
      selectedMenu: "",
      categoryToEdit: this.props.categoryToEdit
        ? this.props.categoryToEdit
        : null,
      initialValues: {},
      categoryIdentifier: this.props.categoryToEdit
        ? this.props.categoryToEdit.category_identifier.length
        : 0,
      categoryDesc: this.props.categoryToEdit
        ? this.props.categoryToEdit.category_description?.length
        : 0,
    };
  }

  componentDidMount() {
    this.setInitalValues();
  }

  setInitalValues = async () => {
    let addInitialValues = {
      category_description: this.state.categoryToEdit
        ? this.state.categoryToEdit.category_description
        : "",
      category_identifier: this.state.categoryToEdit
        ? this.state.categoryToEdit.category_identifier
        : "",
      category_sort_order: this.state.categoryToEdit
        ? this.state.categoryToEdit.category_sort_order
        : "",
      menu_id: this.props.selectedMenu,
      total_selectable_items: this.state.categoryToEdit
        ? this.state.categoryToEdit.total_selectable_items
        : 0,
      mobile_availability: this.state.categoryToEdit
        ? this.state?.categoryToEdit?.category_availability?.mobile_availability
        : true,
      kiosk_availability: this.state.categoryToEdit
        ? this.state.categoryToEdit?.category_availability?.kiosk_availability
        : true,
      epos_availability: this.state.categoryToEdit
        ? this.state.categoryToEdit?.category_availability?.epos_availability
        : true,
      web_availability: this.state.categoryToEdit
        ? this.state.categoryToEdit?.category_availability?.web_availability
        : true,
        business_app_availability: this.state.categoryToEdit
        ? this.state.categoryToEdit?.category_availability?.business_app_availability
        : true,
    };
    this.setState({
      initialValues: addInitialValues,
      selectedMenu: this.props.selectedMenu,
      imagesPreviewUrl: this.state.categoryToEdit
        ? this.state.categoryToEdit.category_image
        : null,
    });

    if (!this.state.categoryToEdit) {
      try {
        const response = await fetch(coming);

        if (!response.ok) {
          throw new Error("Failed to fetch the image.");
        }

        const blob = await response.blob();
        const reader = new FileReader();
        const file = new File([blob], "coming.jpg", { type: blob.type });

        reader.onloadend = () => {
          // Convert the image to a data URL
          const base64Data = reader.result;
          console.log(reader);
          this.setState({
            imagesPreviewUrl: base64Data,
            file: file,
          });
        };

        reader.onerror = (error) => {
          console.error("Error occurred while reading the image:", error);
        };

        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error occurred while fetching the image:", error);
      }
    }
  };

  handleMenu = (values, resetForm) => {
    if (this.state.categoryToEdit) {
      this.updateCategory(values);
    } else {
      this.submitCategory(values, resetForm);
    }
  };

  submitCategory = (values, resetForm) => {
    this.setState({ submitting: true });
    let formData = new FormData();
    formData.append("category_description", values.category_description);
    formData.append("category_identifier", values.category_identifier);
    formData.append("category_sort_order", values.category_sort_order);
    formData.append("menu_id", values.menu_id);
    formData.append("total_selectable_items", values.total_selectable_items);
    if (this.state.file) {
      formData.append("category_image", this.state.file);
    }

    let category_availability = {
      kiosk_availability: values.kiosk_availability,
      epos_availability: values.epos_availability,
      mobile_availability: values.mobile_availability,
      web_availability: values.web_availability,
      business_app_availability: values.business_app_availability,
    };

    formData.append(
      "category_availability",
      JSON.stringify(category_availability)
    );

    addCategory(formData)
      .then((response) => {
        if (response.data.status) {
          this.setState(
            {
              submitting: false,
            },
            () => {          
              this.props.setSelectedMenu(values.menu_id);
              this.props.fetchMenuNames(values.menu_id);
            }
          );
          resetForm({ values: "" });
          this.setState({ imagesPreviewUrl: "", selectedMenu: null });
          toast.success("Category Added");
          this.props.handleQuickAdd("Close");
        } else {
          this.setState({
            submitting: false,
          });
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        });
        toast.error("something went wrong");
      });
  };

  updateCategory = (values) => {
    this.setState({ submitting: true });
    let formData = new FormData();
    formData.append("menu_id", values.menu_id);
    formData.append("category_description", values.category_description);
    formData.append("category_identifier", values.category_identifier);
    formData.append("category_sort_order", values.category_sort_order);
    formData.append("category_id", this.state.categoryToEdit.category_id);
    formData.append("total_selectable_items", values.total_selectable_items);
    if (this.state.file) {
      formData.append("category_image", this.state.file);
    }

    let category_availability = {
      kiosk_availability: values.kiosk_availability,
      epos_availability: values.epos_availability,
      mobile_availability: values.mobile_availability,
      web_availability: values.web_availability,
      business_app_availability: values.business_app_availability,
    };
    this.props.setSelectedCategory(this.state.categoryToEdit.category_id);
    delete values.kiosk_availability;
    delete values.epos_availability;
    delete values.mobile_availability;
    delete values.web_availability;
    delete values.business_app_availability;

    formData.append(
      "category_availability",
      JSON.stringify(category_availability)
    );

    updateCategory(formData)
      .then((response) => {
        if (response.data.status) {
          this.setState(
            {
              submitting: false,
            },
            () => {
              this.props.fetchCategoryNames(values.menu_id);
            }
          );
          toast.success("Category updated.");
          this.props.handleQuickAdd("Close");
          this.props.fetchItemsIndividually(this.state.categoryToEdit.category_id);
        } else {
          this.setState({
            submitting: false,
          });
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        });
        toast.error("something went wrong");
      });
  };

  // Invoked from Multi Cropper Child Component, returns cropped form data
  getCroppedImages = (croppedImagesFormData, key, imageArray, file) => {
    if (key === "menu") {
      this.setState({
        file: file[0],
        imagesPreviewUrl: imageArray[0],
      });
    } else {
      console.log("No cropper key returned!");
    }
  };

  handleMenuChange = (e, setFieldValue) => {
    setFieldValue("menu_id", e.target.value);
    this.setState({ selectedMenu: e.target.value });
  };

  render() {
    const { submitting, initialValues, selectedMenu } = this.state;
    return (
      <>
        <Grid container spacing={3} style={{ padding: 10 }}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" className={"title"}>
              {this.props.title}
              {this.props.showClose && (
                <span style={{ float: "right" }}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => this.props.handleQuickAdd("Close")}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={schema.categorySchema}
              onSubmit={(values, { resetForm }) => {
                this.handleMenu(values, resetForm);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl
                        error={touched.menu_id && errors.menu_id ? true : false}
                      >
                        <InputLabel id="menu_id">Menu</InputLabel>
                        <Select
                          labelId="menu_id"
                          id="menu_id"
                          name="menu_id"
                          input={<OutlinedInput id="menu_id" label="Menu" />}
                          value={selectedMenu}
                          defaultValue={selectedMenu}
                          className="formControl"
                          disabled={!this.props.menuNames}
                          onChange={(e, val) =>
                            this.handleMenuChange(e, setFieldValue)
                          }
                        >
                          {this.props.menuNames &&
                            this.props.menuNames.map((item) => {
                              return (
                                <MenuItem
                                  key={item.menu_id}
                                  value={item.menu_id}
                                >
                                  <span>
                                    {" "}
                                    ( {item.menu_sort_order} ){" "}
                                    {item.menu_identifier}
                                  </span>
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText error>
                          {touched.menu_id && errors.menu_id
                            ? errors.menu_id
                            : null}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        error={
                          touched.category_identifier &&
                          errors.category_identifier
                            ? true
                            : false
                        }
                        margin="normal"
                        type="text"
                        id="category_identifier"
                        label="Name"
                        name="category_identifier"
                        autoComplete="category_identifier"
                        autoFocus
                        size="small"
                        fullWidth
                        variant="standard"
                        onBlur={handleBlur}
                        value={values.category_identifier}
                        onChange={(e) => {
                          handleChange(e);
                          console.log(40 - e.target.value.length);
                          this.setState({
                            categoryIdentifier: e.target.value.length,
                          });
                          console.log(this.state.categoryIdentifier);
                        }}
                        aria-describedby="component-error-text"
                        helperText={
                          touched.category_identifier &&
                          errors.category_identifier
                            ? errors.category_identifier
                            : null
                        }
                      />
                      <span style={{ color: "red" }}>
                        {40 - this.state.categoryIdentifier}
                      </span>{" "}
                      / 40
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        error={
                          touched.category_sort_order &&
                          errors.category_sort_order
                            ? true
                            : false
                        }
                        margin="normal"
                        type="number"
                        id="category_sort_order"
                        label="Sort order"
                        name="category_sort_order"
                        autoComplete="category_sort_order"
                        fullWidth
                        size="small"
                        variant="standard"
                        style={{ position: "relative", bottom: "11px" }}
                        onBlur={handleBlur}
                        value={values.category_sort_order}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 3);
                        }}
                        aria-describedby="component-error-text"
                        InputProps={{
                          inputProps: {
                            min: 0,
                            step: "1",
                          },
                        }}
                        helperText={
                          touched.category_sort_order &&
                          errors.category_sort_order
                            ? errors.category_sort_order
                            : null
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      error={
                        touched.category_description &&
                        errors.category_description
                          ? true
                          : false
                      }
                      margin="normal"
                      type="text"
                      fullWidth
                      multiline
                      rows={5}
                      id="category_description"
                      label="Description"
                      name="category_description"
                      autoComplete="category_description"
                      size="small"
                      variant="standard"
                      onBlur={handleBlur}
                      value={values.category_description}
                      onChange={(e) => {
                        handleChange(e);
                        console.log(300 - e.target.value.length);
                        this.setState({
                          categoryDesc: e.target.value.length,
                        });
                        console.log(this.state.categoryDesc);
                      }}
                      aria-describedby="component-error-text"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                      helperText={
                        touched.category_description &&
                        errors.category_description
                          ? errors.category_description
                          : null
                      }
                    />
                    <span style={{ color: "red" }}>
                      {300 - this.state.categoryDesc}
                    </span>{" "}
                    / 300
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item xs={6} md={6} sx={{display:"none"}}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.is_buffet == true}
                            onChange={handleChange}
                            name="is_buffet"
                            color="primary"
                            inputProps={{
                              "aria-label": "is_buffet checkbox",
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Is buffet"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={6} lg={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.mobile_availability}
                            onChange={handleChange}
                            name="mobile_availability"
                            color="primary"
                            inputProps={{
                              "aria-label": "mobile_availability checkbox",
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Enable Mobile "
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.kiosk_availability}
                            onChange={handleChange}
                            name="kiosk_availability"
                            color="primary"
                            inputProps={{
                              "aria-label": "kiosk_availability checkbox",
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Enable Kiosk"
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.epos_availability}
                            onChange={handleChange}
                            name="epos_availability"
                            color="primary"
                            inputProps={{
                              "aria-label": "epos_availability checkbox",
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Enable Epos"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.web_availability}
                            onChange={handleChange}
                            name="web_availability"
                            color="primary"
                            inputProps={{
                              "aria-label": "web_availability checkbox",
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Enable web"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.business_app_availability}
                            onChange={handleChange}
                            name="business_app_availability"
                            color="primary"
                            inputProps={{
                              "aria-label":
                                "business_app_availability checkbox",
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Enable Business App"
                      />
                    </Grid>
                  </Grid>

                  <Grid>
                    <MultipleImageCropper
                      data={{
                        key: "menu",
                        isMultiple: false,
                        getCroppedImages: this.getCroppedImages,
                      }}
                      height={300}
                      width={300}
                    />
                    <br></br> <br></br>
                    <img
                      className="cover"
                      src={
                        this.state.imagesPreviewUrl
                          ? this.state.imagesPreviewUrl
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12} style={{ paddingTop: 5 }}>
                    {Array.isArray(errors) ||
                      (Object.values(errors).toString() != "" && (
                        <Alert severity="warning">
                          {Object.values(errors).toString()}
                        </Alert>
                      ))}
                    {submitting ? (
                      <Loader size={30} />
                    ) : (
                      <Button
                        type="submit"
                        color="primary"
                        block
                        sx={{ mt: 2, mb: 2 }}
                      >
                        {this.props.title == "Add Category"
                          ? "Add Category"
                          : "Update Category"}
                      </Button>
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  propertyId: state.user.propertyId,
  menuNames: state.menu.menuNames,
  selectedMenu: state.menu.selectedMenu,
});

const mapDispatchToProps = {
  setSelectedMenu,
  setSelectedCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCategory);
