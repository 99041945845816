import React from "react";
import "./loader.css";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loader(props) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={props.size} />
    </div>
  );
}
