import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

class QR extends Component {
  constructor(props) {
    super(props);

    this.state = {
      age: ''
    };
  }



  handleChange = (event) => {
    this.setState({ age: event.target.value })
  }

  render() {

    const { age } = this.state;

    return (
      <React.Fragment>
        <Grid container>
        <Grid item lg={3}>
        <Box sx={{ marginTop: '50px'}}>
          <FormControl fullWidth >
            <InputLabel id="demo-simple-select-label">Select QR Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Select QR Type"
              onChange={this.handleChange}
            >
              <MenuItem value={10}>Room</MenuItem>
              <MenuItem value={20}>Table</MenuItem>
              <MenuItem value={30}>Property</MenuItem>
            </Select>
          </FormControl>
        </Box>
        </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default QR;
