import axios from 'axios';
import * as APIUrl from '../APIUrl';

export const fetchDashboardSummary = (payload) => {
    try {
        return Promise.all ([
            axios({
            method: 'get',
            url: APIUrl.FETCH_DASHBOARD_SUMMARY + `start_date=${payload.from}&end_date=${payload.to}&property_id=${payload.property_id}`,
        }) ,
        axios({
            method: 'get',
            url: APIUrl.FETCH_ORDER_PROCESSONG_TIME + `start_date=${payload.from}&end_date=${payload.to}&property_id=${payload.property_id}`,
        }) ,
    ])
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}


export const fetchFrequentOrders = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_FREQUENTLY_ORDERED_ITEMS  + `start_date=${payload.from}&end_date=${payload.to}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const fetchSoldOutItems = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_SOLD_OUT_ITEMS  + `property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}


