import React, { Component } from "react";
import { toast } from "react-toastify";
import * as PaymentServiceAPI from "../../services/payment/PaymentServiceAPI";
import Breadcrumb from "./Breadcrumb";
import SimpleModal from "../../shared/Modal/SimpleModal";
import { Fragment } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Switch from "@mui/material/Switch";
import WifiIcon from "@mui/icons-material/Wifi";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CreditCardIcon from "@mui/icons-material/CreditCard";
export default class index extends Component {
  state = {
    isLoading: false,
    payment_method_type: [],
    checked: ["wifi"],
  };

  componentDidMount = () => {
    const dump = {
      property_id: localStorage.getItem("property_id"),
      username: localStorage.getItem("username"),
    };
    this.fetchAccountDetails(dump);

    console.log(this.state);
  };

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked });
  };

  fetchAccountDetails = (payload) => {
    if (payload) {
      PaymentServiceAPI.fetchAccountStatus(payload)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data.data.adyen_info.payment_methods_type);
            this.setState({
              payment_method_type:
                response.data.data.adyen_info.payment_methods_type,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    console.log(this.state?.payment_method_type, "saved state");
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6>Payment Methods Active</h6>
              </div>
              <div className="ms-panel-body"></div>

              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                subheader={
                  <ListSubheader>
                  </ListSubheader>
                }
              >
                {Object.entries(this.state?.payment_method_type).map(
                  ([type, methods], i) => (
                    <React.Fragment key={i}>
                      <ListSubheader sx={{fontWeight:"800",fontSize:"20px",padding:"10px",marginLeft:"10px",color:"black"}}>{type.toUpperCase()}</ListSubheader>
                      {methods.map((object, j) => (
                        <ListItem key={j}>
                          <ListItemAvatar>
                            <Avatar>
                              <CreditCardIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={object?.toUpperCase()} />
                        </ListItem>
                      ))}
                    </React.Fragment>
                  )
                )}
              </List>
            </div>{" "}
          </div>{" "}
        </div>
      </Fragment>
    );
  }
}
