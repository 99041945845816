import React, { Component } from "react";
import { toast } from "react-toastify";
import * as PaymentServiceAPI from "../../../services/payment/PaymentServiceAPI";
import Breadcrumb from "./Breadcrumb";
import SimpleModal from "../../../shared/Modal/SimpleModal";
import { Fragment } from "react";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from "@mui/material/Tooltip";
import OutlinedInput from '@mui/material/OutlinedInput';
import { FormControl, InputAdornment, } from '@mui/material';
import * as schema from "../../../utils/Schema";
import { Form, Formik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import Button from '@mui/material/Button';
import { AppBar, Toolbar, } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import { connect } from "react-redux";
import IconButton from '@mui/material/IconButton';
import JumbotronWithImage from "./JumbotronWithImage";
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ProductCard from "./ProductCard";
import NewCard from "./NewCard";
import apex from '../../../assets/img/apex.jpg';
import avant from '../../../assets/img/avant.jpg';
import expanse from '../../../assets/img/Expanse.jpg';
import benchmark from '../../../assets/img/benchmark.jpg';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SearchIcon from '@mui/icons-material/Search';
const products = [{
  name: 'Apex',
  description: 'Peak performance with active noise cancelation. Sennheisers new MOMENTUM Wireless - Closed circumauralheadphone featuring Bluetooth® wireless technology and NoiseGard Hybrid active noise cancelation ',
  imageUrl: apex, // Replace with your image URL
  model: "Model: 505791"
},
{
  name: 'Avant',
  description: 'Peak performance with active noise cancelation. Sennheisers new MOMENTUM Wireless - Closed circumauralheadphone featuring Bluetooth® wireless technology and NoiseGard Hybrid active noise cancelation ',
  imageUrl: avant, // Replace with your image URL
  model: "Model: 505792"
},
{
  name: 'BenchMark',
  description: 'Peak performance with active noise cancelation. Sennheisers new MOMENTUM Wireless - Closed circumauralheadphone featuring Bluetooth® wireless technology and NoiseGard Hybrid active noise cancelation ',
  imageUrl: benchmark, // Replace with your image URL
  model: "Model: 505793"
},
{
  name: 'Expanse',
  description: 'Peak performance with active noise cancelation. Sennheisers new MOMENTUM Wireless - Closed circumauralheadphone featuring Bluetooth® wireless technology and NoiseGard Hybrid active noise cancelation ',
  imageUrl: expanse, // Replace with your image URL
  model: "Model: 505795"
}
];
class index extends Component {


  state = {
    isLoading: false,
    payment_method_type: [],
    item: ""
  };

  componentDidMount = () => {
    this.fetchReceipt();
  };


  submitReceipt = (values, resetForm) => {


    let propId = this.props.propertyId;

    let payload = { "property_id": propId, "receipt_note": values.receipt_note }
    if (payload) {
      PaymentServiceAPI.updateReceipt(payload)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data.data);
            //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
            toast.success("Receipt note added. ");
            resetForm();
            this.fetchReceipt();
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.warn("Could not add receipt note.. ")
        });
    } else {
      this.setState({ isLoading: false });
      toast.error("Something went wrong. ")
    }

  };

  removeReceipt = () => {


    let propId = this.props.propertyId;

    let payload = { "property_id": propId, "receipt_note": "" }
    if (payload) {
      PaymentServiceAPI.updateReceipt(payload)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data.data);
            //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
            toast.success("Receipt note removed. ");
            this.fetchReceipt();
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.warn("Could not remove receipt note.. ")
        });
    } else {
      this.setState({ isLoading: false });
      toast.error("Something went wrong. ")
    }

  };

  fetchReceipt = (values, resetForm) => {

    let propId = this.props.propertyId;

    let payload = { "property_id": propId }
    if (payload) {
      PaymentServiceAPI.fetchReceipt(payload)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data.data);
            this.setState({ isLoading: false, item: response.data.data.receiptNote });
            //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
          } else {
            this.setState({ isLoading: false, item: "" });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, item: "" });
          toast.warn("Could not add receipt note.. ")
        });
    } else {
      this.setState({ isLoading: false, item: "" });
      toast.error("Something went wrong. ")
    }



  };





  render() {

    return (
      <Fragment>
        <AppBar position="static" style={{ backgroundColor: 'transparent', boxShadow: 'none' }} >
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>

            </Typography>
           {/*  <TextField
              variant="outlined"
              label="Search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            /> */}
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="primary"
            >
              <Badge badgeContent={5} color="primary">
                <AddShoppingCartIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <JumbotronWithImage />
        <br /> <br />
        <Grid container spacing={2}>
          {products.map((product) => (
            <Grid item xs={12} sm={6} md={6} lg={6} key={product.id}>
              <ProductCard product={product} />
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={2}>
          {products.map((product) => (
            <Grid item xs={12} sm={6} md={6} lg={3} key={product.id}>
              <NewCard product={product} />
            </Grid>
          ))}
        </Grid>
      </Fragment>

    );
  }
}


const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId
});

export default connect(mapStateToProps, {})(index)
