import React, { Component, Fragment } from "react";
import Menucatelogue from "../Menucatelogue/Menu";
import Menulist from "../Menulist/Listcontent";
import Menugrid from "../Menugrid/Gridcontent";

class MenuMaster extends Component {
  state = {
    show_setup_products: true,
    show_setup_categories: false,
    show_setup_items: false,

    menu_id: null,
    category_id: null,
    item_id: null,
  };

  componentDidMount = (event) => {};

  setupMenuHandler = (menu_state, val) => {
    switch (menu_state) {
      case 0:
        this.setState({
          show_setup_products: true,
          show_setup_categories: false,
          show_setup_items: false,
        });
        break;
      case 1:
        this.setState({
          show_setup_products: false,
          show_setup_categories: true,
          show_setup_items: false,
          menu_id: val,
        });
        break;
      case 2:
        this.setState({
          show_setup_products: false,
          show_setup_categories: false,
          show_setup_items: true,
          category_id: val,
        });
        break;
    }
  };

  render() {
    return (
      <Fragment>
        {/* Menu */}
        {this.state.show_setup_products ? (
          <Menucatelogue
            is_setup={true}
            setupMenuHandler={this.setupMenuHandler}
          />
        ) : null}

        {/* Category */}
        {this.state.show_setup_categories ? (
          <Menulist
            is_setup={true}
            menu_id={this.state.menu_id}
            setupMenuHandler={this.setupMenuHandler}
          />
        ) : null}

        {/* Items */}
        {this.state.show_setup_items ? (
          <Menugrid
            is_setup={true}
            menu_id={this.state.menu_id}
            category_id={this.state.category_id}
            setupMenuHandler={this.setupMenuHandler}
          />
        ) : null}

        <div className="col-md-12">
          <button
            className="btn btn-primary mt-4 d-block float-right"
            onClick={() => this.props.handleClose()}
            type="button"
          >
            Close
          </button>
          {/* <button className="btn btn-primary mt-4 d-block float-right" onClick={() => this.props.handleActivePage(2)} type="button">Skip</button> */}
        </div>
      </Fragment>
    );
  }
}

export default MenuMaster;
