import React, { Component, Fragment } from "react";
import * as SecurityServiceAPI from "../../services/user/SecurityServiceAPI";
import Scrollbar from "react-perfect-scrollbar";
import { toast } from "react-toastify";

class TermsAndConditions extends Component {
  state = {
    tnc_id: "",
    tnc_details: "",
    tnc_acceptance: false,
  };

  componentDidMount = () => {
    SecurityServiceAPI.fetchTermsAndCondition().then((response) => {
      this.setState({
        tnc_id: response.data.data.tnc_id,
        tnc_details: response.data.data,
      });
      // this.props.history.push("/menu");
    });
  };

  acceptTermsAndConditions = () => {
    const username = localStorage.getItem("username");
    const prop_id = localStorage.getItem("property_id");

    // Submit only when previous 3 status are true
    const { state, toggleTermsTab, handleClose } = this.props;

    if (
      state.property_setup_flag &&
      state.menu_setup_flag &&
      state.merchant_setup_flag
    ) {
      let payload = {
        tnc_status: "true",
        tnc_id: this.state.tnc_id,
        username: username,
        property_id: prop_id,
      };

      SecurityServiceAPI.updateTermsAndConditionStatus(payload).then(
        (response) => {
          if (response.data.status) {
            toast.success("Terms & Conditions Accepted! Thank You!");
            toggleTermsTab(true);
            handleClose(true);
          }
        }
      );
    } else {
      // show message or alert
      toast.error("Please complete previous steps");
    }
  };

  render() {
    return (
      <Fragment>
        <div className="ms-panel">
          <div className="ms-panel-body">
            {this.state.tnc_details ? (
              <Scrollbar
                className="ms-quickbar-container ms-todo-list-container ms-scrollable"
                style={{ height: "200px" }}
              >
                <div dangerouslySetInnerHTML={this.state.tnc_details}></div>
              </Scrollbar>
            ) : null}
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <button
                className="btn btn-primary float-right"
                type="button"
                onClick={this.acceptTermsAndConditions}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TermsAndConditions;
