import { createSlice } from "@reduxjs/toolkit";

export const propertySlice = createSlice({
  name: "property",
  initialState: {
    property: {},
    propertyId: null,
    isFranchise: false,
    propertyDetails: {},
  },
  reducers: {
    setProperty: (state, action) => {
      state.property = action.payload;
    },
    setPropertyId: (state, action) => {
      state.propertyId = action.payload;
    },
    setPropertyDetails: (state, action) => {
      state.propertyDetails = action.payload;
    },
    setFranchise: (state, action) => {
      state.isFranchise = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProperty, setPropertyId, setPropertyDetails, setFranchise } = propertySlice.actions;

export default propertySlice.reducer;
