import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCurrencySymbol } from "../../../config";

export default function Accordian({ data }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography
          variant="body1"
          sx={{ width: "70%", flexShrink: 1, fontWeight: 600 }}
        >
          Payment Mode
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          {data?.payment_mode?.toUpperCase()}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Mode</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {data?.split_details?.map((item, index) => (
              <tr key={index}>
                <td>{item?.mode}</td>
                <td>
                  {" "}
                  {getCurrencySymbol(
                    localStorage.getItem("currency_code")
                  )}{" "}
                  {item?.amount}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </AccordionDetails>
    </Accordion>
  );
}
