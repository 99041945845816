/*global google*/
import React, { Component } from "react";
import { Fragment } from "react";
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker,
} from "react-google-maps";
import deliveryagent from "./images/delivery-agent.png";
import cutlery from './images/cutlery.svg';
import deliverypoint from './images/delivery-point.png';

// List of Icons
const icons = [deliveryagent, cutlery, deliverypoint];

class Map extends Component {

  constructor(props) {
    super(props);
    this.state = {
      directions: {},
    };
  }

  componentDidMount = () => {
    if (this.props && this.props.mapData && this.props.mapData.data) {
      const keyData = this.props.mapData;
      this.mapDirection(
        keyData.data[0],
        keyData.data[1],
        keyData.data[2]
      );
    }
  }

  mapDirection = (origin, waypoint, destination) => {
    const directionsService = new window.google.maps.DirectionsService();
    if (origin && waypoint && destination) {
      directionsService.route(
        {
          origin: new google.maps.LatLng(origin.latitude, origin.longitude),
          destination: new google.maps.LatLng(destination.latitude, destination.longitude),
          waypoints: [
            {
              location: new google.maps.LatLng(waypoint.latitude, waypoint.longitude),
              stopover: false,
            },
          ],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            this.setState({
              directions: result,
            });
          } else {
            console.error(`Error Fetching Directions ${result}`);
          }
        }
      );
    } else {
      console.log('Data Not Available!');
    }
  }

  render() {
    // Directions with waypoint and markers
    const GoogleMapExample = withGoogleMap(({ keyData }) => (
      <Fragment>
        {keyData.agent_assigned && (
          <GoogleMap defaultZoom={12}>
            {keyData &&
              keyData.data.map((item, i) => (
                <Marker
                  className="new-marker"
                  key={i}
                  icon={{
                    url: icons[i],
                    scaledSize: new google.maps.Size(35, 35),
                  }}
                  position={{
                    lat: item.latitude,
                    lng: item.longitude,
                  }}
                ></Marker>
              ))}

            {
              this.state.directions ?

                <DirectionsRenderer
                  options={{
                    polylineOptions: {
                      strokeColor: "#007bff",
                      strokeOpacity: 1,
                      strokeWeight: 4,
                    },
                    suppressMarkers: true,
                  }}
                  directions={this.state.directions ? this.state.directions : null}
                />
                :
                null
            }
          </GoogleMap>
        )}
      </Fragment>
    ));

    // Markers only
    const MapWithAMarker = withGoogleMap(({ keyData }) => (
      <Fragment>
        {!keyData.agent_assigned && (
          <GoogleMap
            defaultZoom={8}
            defaultCenter={{
              lat: keyData.data ? keyData.data[0].latitude : 18.27,
              lng: keyData.data ? keyData.data[0].longitude : 74.079,
            }}
          >
            {this.props.mapData &&
              this.props.mapData?.data?.map((item, i) => (
                <Marker
                  className="new-marker"
                  key={i}
                  icon={{
                    url: icons[0],
                    scaledSize: new google.maps.Size(35, 35),
                  }}
                  position={{
                    lat: item.latitude,
                    lng: item.longitude,
                  }}
                ></Marker>
              ))}
          </GoogleMap>
        )}
      </Fragment>
    ));
    return (
      <div>
        {this.props.mapData && (
          <Fragment>
            {this.props.mapData.agent_assigned && (
              <GoogleMapExample
                keyData={this.props.mapData && this.props.mapData}
                containerElement={
                  <div style={{ height: `350px`, width: "450px" }} />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            )}
          </Fragment>
        )}
        {this.props.mapData && (
          <Fragment>
            {!this.props.mapData.agent_assigned && (
              <MapWithAMarker
                keyData={this.props.mapData && this.props.mapData}
                containerElement={<div style={{ height: `350px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

export default Map;


