import axios from 'axios';
import * as APIUrl from '../APIUrl';

export const addShift = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.ADD_SHIFT,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}


export const fetchAllShifts = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_ALL_SHIFT + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}
export const deleteShift = (payload) => {
    try {
        return axios({
            method: "put",
            url: APIUrl.DELETE_SHIFT + `${payload}`,
        });
    } catch (error) {
        throw new Error(error);
    }
};

export const updateShift = (payload) => {

    try {
        return axios({
            method: 'put',
            url: APIUrl.UPDATE_SHIFT,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}