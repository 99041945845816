import React, { Component, Fragment } from 'react'
import * as SettingServiceAPI from '../../services/setting/SettingServiceAPI'
import * as SecurityServiceAPI from '../../services/user/SecurityServiceAPI'
import { toast } from 'react-toastify'
import { FlapperSpinner } from "react-spinners-kit";
import Breadcrumb from './Breadcrumb';
import MultipleImageCropper from '../../shared/Cropper/MultipleImageCropper';

export default class index extends Component {
    state = {
        hasfeaturedCoverImage: false,
        files: [],
        property_details: '',
        username: '',
        featured_image_or_video: [],
        loading: false
    }
    fileArray = [];
    fileObj = [];

    componentDidMount = () => {
        const username = localStorage.getItem('username')
        this.fetchBusinessPlace(username);
        this.setState({ username: username })
    }

    fetchBusinessPlace = (username) => {
        SecurityServiceAPI.fetchBusinessPlace(username).then(response => {
            if (response.data.status) {
                const property_details = response.data.data.property_details[0];
                if (property_details) {
                    if (property_details.featured_image_or_video) {

                        this.setState({ featured_image_or_video: property_details.featured_image_or_video, property_details: property_details })
                    }
                    this.setState({
                        property_details: property_details,
                        hasfeaturedCoverImage: false
                    })
                }
            }
        })
    }

    

    uploadPromotionsImages = () => {
        this.setState({ loading: true })
        let formData = new FormData();

        formData.append('property_id', this.state.property_details.property_id)

        for (let i = 0; i < this.state.files.length; i++) {
            formData.append("promotions", this.state.files[i]);
        }

        // it should be promotions
        // this is not the correct url form promotios
        SettingServiceAPI.uploadFeaturedImages(formData).then(response => {
            if (response.data.status) {
                this.fetchBusinessPlace(this.state.username);
                this.state({
                    files: []
                })
                this.setState({ loading: false })
            } else {
                this.setState({ loading: false })
            }
        })
    }
    
    getCroppedImages = (formData, key, imageArray, file) => {
        console.log(key, file, imageArray, formData);

        if (key === "promotions") {
            this.setState({
                files: file
            }, () => {
                this.uploadPromotionsImages();
            });
            // this.formData.append("property_id", this.state.property_details.property_id);
           
        } else {
            console.log('No cropper key returned!');
        }
    }

    
    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-10">
                        <Breadcrumb />
                    </div>

                    {/* Cropper */}
                    <MultipleImageCropper data={{
                        key: "promotions",
                        isMultiple: true,
                        getCroppedImages: this.getCroppedImages.bind(this)
                    }} 
                    height={300}
                    width={300}
                    />
                </div>
            </Fragment>
        )
    }
}


