import React, { Component } from "react";
import { toast } from "react-toastify";
import * as EposNowServiceAPI from "../../services/integrations/EposNowServiceAPI";
import * as DelevirictServiceAPI from "../../services/integrations/DelevirictServiceAPI";
import { Fragment } from "react";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Loader from "../../shared/Loader";
import Breadcrumb from "./Breadcrumb";
import { Stack } from "@mui/material";
import eposNow from "../../assets/img/eposNow.png";
import delevirict from "../../assets/img/delevirict.png";
import { setWebOrderUserInfo } from "../../redux/misc/miscSlice";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import CreateIcon from "@mui/icons-material/Create";
import { InputLabel } from "@mui/material";
import * as Yup from "yup";
import Nash from "../NashOrdering/Index.js";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const validationSchema = Yup.object().shape({
  epos_Id: Yup.string().required("EPOS id is required"),
  epos_Secret: Yup.string().required("EPOS secret is required"),
});

const validationDelivrectSchema = Yup.object().shape({
  deliverect_Id: Yup.string().required("Delivrect id is required"),
  deliverect_Secret: Yup.string().required("Delivrect secret is required"),
});

class index extends Component {
  state = {
    isLoading: false,
    terms: {},
    eposPlan: {},
    deliverictPlan: {},
    eposInfo: {},
    deliverectInfo: {},
    formData: {
      epos_Id: "",
      epos_Secret: "",
      is_epos_now_enalbled: true,
    },
    delivrectformData: {
      deliverect_Id: "",
      deliverect_Secret: "",
      is_deliverect_enabled: true,
    },
    errors: {},
    showEdit: false,
    isLoading: true,
    showDetails: false,
    showDeleverictDetails: false,
    showDeleverictDetailsEdit: false,
  };

  componentDidMount = () => {
    const dump = this.props.propertyId;
    this.fetchTermsConditions(dump);
    this.fetchEposPlan();
    this.fetchDeliverictPlan();
    this.unlisten = this.props.history.listen((location, action) => {
      if (
        location.pathname !== "epos-now" &&
        location.pathname !== "epos-activate"
      ) {
        this.props.setWebOrderUserInfo(null);
      }
    });
  };

  componentWillUnmount() {
    // Clean up the listener when the component unmounts
    this.unlisten();
  }

  fetchEposPlan = async () => {
    const dump = this.props.propertyId;
    try {
      const response = await EposNowServiceAPI.fetchEposPlan(dump);
      if (response.data.status) {
        this.fetchEposInfo();
        if (response.data?.data) {
          this.setState(
            {
              eposPlan: response.data.data,
            },
            () => {
              if (response.data.data.subscription_status === "EXPIRED") {
              }
            }
          );
        }
      } else {
        const err = response.data?.message || "Something went wrong.";
        //   toast.warn(err);
        this.setState({ eposPlan: {}, isLoading: false });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ eposPlan: {}, isLoading: false });
      //    toast.error(err);
      throw error;
    }
  };
  fetchDeliverictPlan = async () => {
    const dump = this.props.propertyId;
    try {
      const response = await DelevirictServiceAPI.fetchDelevirictPlan(dump);
      if (response.data.status) {
        this.fetchEposInfo();
        if (response.data?.data) {
          this.setState(
            {
              deliverictPlan: response.data.data,
            },
            () => {
              if (response.data.data.subscription_status === "EXPIRED") {
              }
            }
          );
        }
      } else {
        const err = response.data?.message || "Something went wrong.";
        //   toast.warn(err);
        this.setState({ deliverictPlan: {}, isLoading: false });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ deliverictPlan: {}, isLoading: false });
      //    toast.error(err);
      throw error;
    }
  };
  fetchEposInfo = async () => {
    const dump = this.props.propertyId;
    try {
      const response = await EposNowServiceAPI.fetchEposInfo(dump);
      if (response.data.status) {
        if (response.data?.data) {
          this.setState({
            eposInfo: response.data.data?.epos_now_info,
            formData: {
              ...this.state.formData,
              epos_Id: response.data.data?.epos_now_info?.client_id,
              epos_Secret: response.data.data?.epos_now_info?.client_secret,
              is_epos_now_enalbled: response.data.data?.is_eposnow_enabled,
              //     is_epos_now_enalbled: response.data.data?.is_epos_now_enabled
            },
            showEdit: response.data.data?.is_eposnow_enabled,
            deliverectInfo: response.data.data?.deliverect_info,
            delivrectformData: {
              ...this.state.delivrectformData,
              deliverect_Id: response.data.data?.deliverect_info?.client_id,
              deliverect_Secret:
                response.data.data?.deliverect_info?.client_secret,
              is_deliverect_enabled: response.data.data?.is_deliverect_enabled,
              //     is_epos_now_enalbled: response.data.data?.is_epos_now_enabled
            },
            showDeleverictEdit: response.data.data?.is_deliverect_enabled,
            isLoading: false,
          });
        }
      } else {
        const err = response.data?.message || "Something went wrong.";
        //   toast.warn(err);
        this.setState({ eposInfo: {}, isLoading: false });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ eposInfo: {}, isLoading: false });
      //    toast.error(err);
      throw error;
    }
  };

  cancelEposPlan = async () => {
    const dump = this.state.eposPlan.integration_Id;
    try {
      const response = await EposNowServiceAPI.cancelEposPlan(dump);

      if (response.data.status) {
        toast.success("Epos Now Plan is cancelled.");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        const err = response.data?.message || "Something went wrong.";
        toast.warn(err);
        this.setState({ eposPlan: {} });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ eposNow: {} });
      toast.error(err);
      throw error;
    }
  };

  cancelDelevrictPlan = async () => {
    const dump = this.state.deliverictPlan.integration_Id;
    try {
      const response = await DelevirictServiceAPI.cancelEposPlan(dump);

      if (response.data.status) {
        toast.success("Delevirict Plan is cancelled.");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        const err = response.data?.message || "Something went wrong.";
        toast.warn(err);
        this.setState({ eposPlan: {} });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ eposNow: {} });
      toast.error(err);
      throw error;
    }
  };

  fetchTermsConditions = (payload) => {
    this.setState({ isLoading: true });
    if (payload) {
      EposNowServiceAPI.fetchEposNowTerms(payload)
        .then((response) => {
          if (response.data.status) {
            this.setState({ terms: response.data.data /* isLoading: false */ });
          } else {
            this.setState({ terms: {} /* isLoading: false */ });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, terms: {} });
        });
    } else {
      this.setState({ isLoading: false, terms: {} });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    validationSchema
      .validate(this.state.formData, { abortEarly: false })
      .then(() => {
        let data = {
          ...this.state.payload,
          ...this.state.formData,
          property_id: this.props.propertyId,
        };
        this.saveEpos(data);
      })
      .catch((errors) => {
        const errorDetails = {};
        errors.inner.forEach((error) => {
          errorDetails[error?.path] = error?.message;
        });
        this.setState({ errors: errorDetails });
      });
  };

  handleDelivrectSubmit = (event) => {
    event.preventDefault();
    validationDelivrectSchema
      .validate(this.state.delivrectformData, { abortEarly: false })
      .then(() => {
        let data = {
          ...this.state.payload,
          ...this.state.delivrectformData,
          property_id: this.props.propertyId,
        };
        console.log(data);
        this.saveDelivrect(data);
      })
      .catch((errors) => {
        const errorDetails = {};
        errors.inner.forEach((error) => {
          errorDetails[error?.path] = error?.message;
        });
        this.setState({ errors: errorDetails });
      });
  };

  saveEpos = async (data) => {
    try {
      const payload = {
        epos_now_info: {
          client_id: data.epos_Id,
          client_secret: data.epos_Secret,
        },
        is_epos_now_enalbled: data.is_epos_now_enalbled,
        property_id: data.property_id,
      };
      const response = await EposNowServiceAPI.saveEpos(payload);

      if (response.data.status) {
        toast.success("Epos info saved.");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        const err = response.data.message || "Couldn't save epos info.";
        toast.warn(err);
      }
    } catch (error) {
      const err = "Something went wrong.";
      toast.warn(err);
      throw error;
    }
  };

  saveDelivrect = async (data) => {
    try {
      const payload = {
        deliverect_info: {
          client_id: data.deliverect_Id,
          client_secret: data.deliverect_Secret,
        },
        is_deliverect_enabled: data.is_deliverect_enabled,
        property_id: data.property_id,
      };
      const response = await DelevirictServiceAPI.saveDelivrect(payload);

      if (response.data.status) {
        toast.success("Delivrect info saved.");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        const err = response.data.message || "Couldn't save delivrect info.";
        toast.warn(err);
      }
    } catch (error) {
      const err = "Something went wrong.";
      toast.warn(err);
      throw error;
    }
  };

  handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    const newValue = name === "is_epos_now_enalbled" ? checked : value;

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: newValue,
      },
    }));
  };

  handleDelivrectInputChange = (event) => {
    const { name, value, checked } = event.target;
    const newValue = name === "is_deliverect_enabled" ? checked : value;

    this.setState((prevState) => ({
      delivrectformData: {
        ...prevState.delivrectformData,
        [name]: newValue,
      },
    }));
  };

  handleClick = () => {
    this.setState((prevState) => ({
      showDetails: !prevState.showDetails,
      showEdit: true,
    }));
  };
  handleDeleverictClick = () => {
    this.setState((prevState) => ({
      showDeleverictDetails: !prevState.showDeleverictDetails,
      showDeleverictDetailsEdit: true,
    }));
  };
  handleEditDetails = () => {
    this.setState({
      showEdit: false,
    });
  };

  handleDeliverectEditDetails = () => {
    this.setState({
      showDeleverictDetailsEdit: false,
    });
  };

  render() {
    const {
      eposPlan,
      errors,
      formData,
      eposInfo,
      showEdit,
      showDetails,
      deliverictPlan,
      deliverectInfo,
      delivrectformData,
      showDeleverictDetails,
      showDeleverictDetailsEdit,
    } = this.state;
    return (
      <Fragment>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="">
            <div className="">
              <div className="">
                <br />
                <div className="col-md-12">
                  <Breadcrumb />
                </div>

                <br />
                {this.state.isLoading ? (
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginBottom: "40px",
                    }}
                  >
                    {" "}
                    <Loader size={50} />{" "}
                  </div>
                ) : (
                  <Grid container spacing={2}>
                    {true && (
                      <Grid item lg={12}>
                        <Paper
                          elevation={3}
                          sx={{ mb: 0, mt: 0, pl: 4, pr: 4, ml: 5, mr: 5 }}
                        >
                          <Grid container alignItems="center">
                            {/* Image */}
                            <Grid item lg={1}>
                              <img
                                src={eposNow}
                                style={{
                                  width: "100px",
                                  margin: "8px",
                                  boxSizing: "border-box",
                                }}
                              />
                            </Grid>

                            {/* Vertical Line */}
                            <Grid item>
                              <div
                                style={{
                                  borderLeft: "1px solid black",
                                  height: "115px",
                                  margin: "0 20px",
                                }}
                              ></div>
                            </Grid>

                            {/* Text and Buttons */}
                            <Grid item lg={5}>
                              <Grid container>
                                {/* Text */}
                                <Grid item style={{ marginBottom: "8px" }}>
                                  <Typography variant="h6">
                                    <b>EPOS NOW Integration</b>{" "}
                                    {eposPlan?.subscription_status ===
                                      "CANCELLED" && (
                                      <span
                                        style={{
                                          marginLeft: "10px",
                                          backgroundColor: "lightgrey",
                                          padding: "5px",
                                          paddingLeft: "10px",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Valid till{" "}
                                        {eposPlan?.subscription_end_date?.substring(
                                          0,
                                          10
                                        )}
                                      </span>
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container>   
                                <Grid item>
                                  <Typography>
                                  Integrate seamlessly with eposnow
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* Buttons */}
                            {(eposPlan.subscription_status == "ACTIVE" ||
                              eposPlan.subscription_status == "CANCELLED") && (
                              <Grid item lg={1}>
                                {
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={showDetails}
                                        onChange={this.handleClick}
                                        name="show_Details"
                                        color="primary"
                                      />
                                    }
                                    labelPlacement="start"
                                    label="Show Details"
                                  />
                                }
                              </Grid>
                            )}
                            <Grid item lg={4}>
                              <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="right"
                              >
                                {eposPlan.subscription_status !== "ACTIVE" ? (
                                  <Grid item lg={10}>
                                    {eposPlan?.subscription_status !==
                                    "CANCELLED" ? (
                                      <Link
                                        to={{
                                          pathname: "/epos-activate",
                                          state: { terms: this.state.terms },
                                        }}
                                        style={{
                                          textDecoration: "none",
                                          float: "right",
                                        }}
                                      >
                                        <Button
                                          size="large"
                                          variant="contained"
                                          style={{
                                            width: "150px",
                                            height: "50px",
                                            borderRadius: "30px",
                                          }}
                                        >
                                          Activate
                                        </Button>
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                ) : (
                                  <>
                                    <Grid
                                      container
                                      justifyContent="flex-end"
                                      alignItems="right"
                                    >
                                      <Grid item lg={5}>
                                        <Button
                                          size="large"
                                          variant="contained"
                                          style={{
                                            width: "150px",
                                            height: "50px",
                                            float: "right",
                                            borderRadius: "30px",
                                            backgroundColor: "#DAFFB9",
                                            color: "#699E38",
                                            "&:hover": {
                                              backgroundColor: "#DAFFB9",
                                              color: "#699E38",
                                            },
                                          }}
                                        >
                                          Activated
                                        </Button>
                                      </Grid>
                                      <Grid item lg={5}>
                                        <Button
                                          size="large"
                                          variant="outlined"
                                          onClick={this.cancelEposPlan}
                                          style={{
                                            width: "150px",
                                            height: "50px",
                                            borderRadius: "30px",
                                            float: "right",
                                            borderColor: "#C90A0A",
                                            color: "#C90A0A",
                                            "&:hover": {
                                              borderColor: "#C90A0A",
                                              color: "#C90A0A",
                                            },
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </>
                                )}
                                <Grid
                                  container
                                  justifyContent="flex-end"
                                  alignItems="right"
                                >
                                  {eposPlan?.subscription_status ===
                                  "CANCELLED" ? (
                                    <Button
                                      size="large"
                                      variant="contained"
                                      className="white-text"
                                      sx={{
                                        mt: { xs: 1, md: 0 },
                                        width: "150px",
                                        height: "50px",
                                      }}
                                      disabled={true}
                                    >
                                      Cancelled
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* </Paper> */}

                          {(eposPlan.subscription_status == "ACTIVE" ||
                            eposPlan.subscription_status == "CANCELLED") && (
                            <Box
                              // elevation={3}
                              sx={{ mb: 0, mt: 0, pl: 4, pr: 4, ml: 5, mr: 5 }}
                            >
                              <br /> <br />
                              {showDetails && (
                                <Grid container spacing={2} alignItems="center">
                                  {/* <Grid item lg={3}>
                                    {!showEdit ? (
                                      <div>
                                        <FormControl fullWidth>
                                          <InputLabel>EPOS NOW ID</InputLabel>
                                          <Input
                                            name="epos_Id"
                                            value={formData.epos_Id}
                                            onChange={this.handleInputChange}
                                          />
                                        </FormControl>
                                        <Typography
                                          variant="body2"
                                          color="error"
                                        >
                                          {errors.epos_Id}
                                        </Typography>
                                      </div>
                                    ) : (
                                      <div>
                                        EPOS NOW ID : &nbsp;{" "}
                                        <b>{eposInfo?.client_id}</b>
                                      </div>
                                    )}
                                  </Grid> */}

                                  <Grid item xs={6}>
                                    {!showEdit ? (
                                      <Stack direction="row">
                                        <div>
                                          <FormControl fullWidth>
                                            <InputLabel>EPOS NOW ID</InputLabel>
                                            <Input
                                              name="epos_Id"
                                              value={formData.epos_Id}
                                              onChange={this.handleInputChange}
                                            />
                                          </FormControl>
                                          <Typography
                                            variant="body2"
                                            color="error"
                                          >
                                            {errors.epos_Id}
                                          </Typography>
                                        </div>
                                        <div style={{ marginLeft: "30px" }}>
                                          <FormControl fullWidth>
                                            <InputLabel>
                                              EPOS NOW Secret
                                            </InputLabel>
                                            <Input
                                              name="epos_Secret"
                                              value={formData.epos_Secret}
                                              onChange={this.handleInputChange}
                                            />
                                          </FormControl>
                                          <Typography
                                            variant="body2"
                                            color="error"
                                          >
                                            {errors.epos_Secret}
                                          </Typography>{" "}
                                        </div>
                                      </Stack>
                                    ) : (
                                      <Stack direction="row">
                                        <Typography>
                                          EPOS NOW ID : &nbsp;{" "}
                                          <b>{eposInfo?.client_id}</b>
                                        </Typography>
                                        <Typography sx={{ ml: 3 }}>
                                          EPOS NOW SECRET : &nbsp;{" "}
                                          <b>{eposInfo?.client_secret}</b>
                                        </Typography>
                                      </Stack>
                                    )}
                                  </Grid>

                                  <Grid item xs={3}>
                                    {!showEdit && (
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={
                                              formData.is_epos_now_enalbled ===
                                              true
                                            }
                                            onChange={this.handleInputChange}
                                            name="is_epos_now_enalbled"
                                            color="primary"
                                            inputProps={{
                                              "aria-label":
                                                "is_epos_now_enalbled checkbox",
                                            }}
                                          />
                                        }
                                        labelPlacement="start"
                                        label="Is Epos Now Enabled"
                                      />
                                    )}
                                  </Grid>
                                  {showEdit && (
                                    <Grid item xs={3}>
                                      <Button
                                        onClick={this.handleEditDetails}
                                        startIcon={<CreateIcon />}
                                      >
                                        Edit Details
                                      </Button>
                                    </Grid>
                                  )}

                                  {!showEdit && (
                                    <Grid item xs={12}>
                                      <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleSubmit}
                                        sx={{ p: 2 }}
                                      >
                                        Save Details
                                      </Button>
                                    </Grid>
                                  )}
                                </Grid>
                              )}
                              <br /> <br />
                            </Box>
                          )}
                        </Paper>
                      </Grid>
                    )}

                    {true && (
                      <Grid item lg={12}>
                        <Paper
                          elevation={3}
                          sx={{
                            mb: 0,
                            mt: 0,
                            pl: 4,
                            pr: 4,
                            ml: 5,
                            mr: 5,
                          }}
                        >
                          <Grid container alignItems="center">
                            {/* Image */}
                            <Grid item lg={1}>
                              <img
                                src={delevirict}
                                style={{
                                  width: "100px",
                                  margin: "8px",
                                  boxSizing: "border-box",
                                }}
                              />
                            </Grid>

                            {/* Vertical Line */}
                            <Grid item>
                              <div
                                style={{
                                  borderLeft: "1px solid black",
                                  height: "115px",
                                  margin: "0 20px",
                                }}
                              ></div>
                            </Grid>

                            {/* Text and Buttons */}
                            <Grid item lg={5}>
                              <Grid container>
                                {/* Text */}
                                <Grid item style={{ marginBottom: "8px" }}>
                                  <Typography variant="h6">
                                    <b>Deliverect Integration</b>{" "}
                                    {deliverictPlan?.subscription_status ===
                                      "CANCELLED" && (
                                      <span
                                        style={{
                                          marginLeft: "10px",
                                          backgroundColor: "lightgrey",
                                          padding: "5px",
                                          paddingLeft: "10px",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Valid till{" "}
                                        {deliverictPlan?.subscription_end_date?.substring(
                                          0,
                                          10
                                        )}
                                      </span>
                                    )}
                                  </Typography>
                                </Grid>
                                 </Grid>
                              <Grid container sx={{mb : 1}}>
                                <Grid item>
                                  <Typography>
                                  By activating this integration with Deliverect, your online delivery orders will be seamlessly received by your POS system. Deliverect connects various food delivery platforms to your POS, streamlining order management.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                           
                          {/*   {(deliverictPlan.subscription_status == "ACTIVE" ||
                              deliverictPlan.subscription_status ==
                                "CANCELLED") && (
                              <Grid item lg={1}>
                                {
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={showDeleverictDetails}
                                        onChange={this.handleDeleverictClick}
                                        name="show_Details"
                                        color="primary"
                                      />
                                    }
                                    labelPlacement="start"
                                    label="Show Details"
                                  />
                                }
                              </Grid>
                            )} */}
                            <Grid item lg={4} sx={{mb : 2}}>
                              <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="right"
                              >
                                {deliverictPlan.subscription_status !==
                                "ACTIVE" ? (
                                  <Grid item lg={10}>
                                    {deliverictPlan?.subscription_status !==
                                    "CANCELLED" ? (
                                      <Link
                                        to={{
                                          pathname:
                                            "/delevirict-terms-and-conditions",
                                          state: { terms: this.state.terms },
                                        }}
                                        style={{
                                          textDecoration: "none",
                                          float: "right",
                                        }}
                                      >
                                        <Button
                                          size="large"
                                          variant="contained"
                                          style={{
                                            width: "150px",
                                            height: "50px",
                                            borderRadius: "30px",
                                          }}
                                        >
                                          Activate
                                        </Button>
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                ) : (
                                  <>
                                    <Grid
                                      container
                                      justifyContent="flex-end"
                                      alignItems="right"
                                    >
                                      <Grid item lg={5}>
                                        <Button
                                          size="large"
                                          variant="contained"
                                          style={{
                                            width: "150px",
                                            height: "50px",
                                            float: "right",
                                            borderRadius: "30px",
                                            backgroundColor: "#DAFFB9",
                                            color: "#699E38",
                                            "&:hover": {
                                              backgroundColor: "#DAFFB9",
                                              color: "#699E38",
                                            },
                                          }}
                                        >
                                          Activated
                                        </Button>
                                      </Grid>
                                      <Grid item lg={5}>
                                        <Button
                                          size="large"
                                          variant="outlined"
                                          onClick={this.cancelDelevrictPlan}
                                          style={{
                                            width: "150px",
                                            height: "50px",
                                            borderRadius: "30px",
                                            float: "right",
                                            borderColor: "#C90A0A",
                                            color: "#C90A0A",
                                            "&:hover": {
                                              borderColor: "#C90A0A",
                                              color: "#C90A0A",
                                            },
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </>
                                )}
                                <Grid
                                  container
                                  justifyContent="flex-end"
                                  alignItems="right"
                                >
                                  {deliverictPlan?.subscription_status ===
                                  "CANCELLED" ? (
                                    <Button
                                      size="large"
                                      variant="contained"
                                      className="white-text"
                                      sx={{
                                        mt: { xs: 1, md: 0 },
                                        width: "150px",
                                        height: "50px",
                                      }}
                                      disabled={true}
                                    >
                                      Cancelled
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>

                       {/*  {(deliverictPlan.subscription_status == "ACTIVE" ||
                          deliverictPlan.subscription_status ==
                            "CANCELLED") && (
                          <Paper
                            elevation={3}
                            sx={{ mb: 0, mt: 0, pl: 4, pr: 4, ml: 5, mr: 5 }}
                          >
                            <br /> <br />
                            {showDeleverictDetails && (
                              <Grid container spacing={2} alignItems="center">
                                <Grid item lg={3}>
                                  {!showDeleverictDetailsEdit ? (
                                    <div>
                                      <FormControl fullWidth>
                                        <InputLabel>
                                          DELIVRECT NOW ID
                                        </InputLabel>
                                        <Input
                                          name="deliverect_Id"
                                          value={
                                            delivrectformData.deliverect_Id
                                          }
                                          onChange={
                                            this.handleDelivrectInputChange
                                          }
                                        />
                                      </FormControl>
                                      <Typography variant="body2" color="error">
                                        {errors.deliverect_Id}
                                      </Typography>
                                    </div>
                                  ) : (
                                    <div>
                                      DELIVRECT ID : &nbsp;{" "}
                                      <b>{deliverectInfo?.client_id}</b>
                                    </div>
                                  )}
                                </Grid>

                                <Grid item lg={3}>
                                  {!showDeleverictDetailsEdit ? (
                                    <div>
                                      <FormControl fullWidth>
                                        <InputLabel>
                                          DELIVRECT SECRET
                                        </InputLabel>
                                        <Input
                                          name="deliverect_Secret"
                                          value={
                                            delivrectformData.deliverect_Secret
                                          }
                                          onChange={
                                            this.handleDelivrectInputChange
                                          }
                                        />
                                      </FormControl>
                                      <Typography variant="body2" color="error">
                                        {errors.deliverect_Secret}
                                      </Typography>{" "}
                                    </div>
                                  ) : (
                                    <div>
                                      DELIVRECT NOW SECRET : &nbsp;{" "}
                                      <b>{deliverectInfo?.client_secret}</b>
                                    </div>
                                  )}
                                </Grid>

                                <Grid item lg={3}>
                                  {!showDeleverictDetailsEdit && (
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={
                                            delivrectformData.is_deliverect_enabled ===
                                            true
                                          }
                                          onChange={
                                            this.handleDelivrectInputChange
                                          }
                                          name="is_deliverect_enabled"
                                          color="primary"
                                          inputProps={{
                                            "aria-label":
                                              "is_deliverect_enabled checkbox",
                                          }}
                                        />
                                      }
                                      labelPlacement="start"
                                      label="Is DELIVRECT  Enabled"
                                    />
                                  )}
                                </Grid>
                                {showDeleverictDetailsEdit && (
                                  <Grid item lg={3}>
                                    <Button
                                      onClick={this.handleDeliverectEditDetails}
                                      startIcon={<CreateIcon />}
                                    >
                                      Edit Details
                                    </Button>
                                  </Grid>
                                )}

                                {!showDeleverictDetailsEdit && (
                                  <Grid item lg={12}>
                                    <Button
                                      fullWidth
                                      variant="contained"
                                      color="primary"
                                      onClick={this.handleDelivrectSubmit}
                                      sx={{ p: 2 }}
                                    >
                                      Save Details
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            )}
                            <br /> <br />
                          </Paper>
                        )} */}
                      </Grid>
                    )}
                  </Grid>
                )}
              </div>{" "}
            </div>{" "}
            <Nash />
          </div>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

const mapDispatchToProps = {
  setWebOrderUserInfo,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(index));
