import React, { Component } from "react";
import { toast } from "react-toastify";
import * as PaymentServiceAPI from "../../services/payment/PaymentServiceAPI";
import Breadcrumb from "./Breadcrumb";
import { Fragment } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
export default class index extends Component {


  state = {
    isLoading: false,
    split_payments: [],
    propertyId: "",
    value: ""
  };

  componentDidMount = () => {
    const dump = {
      property_id: localStorage.getItem("property_id"),
      username: localStorage.getItem("username")
    }
    this.fetchAccountDetails(dump);

    console.log(this.state)
  };


  handleChange = (event) => {
    this.setState({ propertyId: event.target.value });
  };

  handleValueChange = (event) => {
    this.setState({ value: event.target.value });
  };


  venderPercentage = (value, name, index) => {
    let newTimings = [...this.state.split_payments];
    console.log(value)
    console.log(index)


    if (name === "value") {
      newTimings[index].value = parseInt(value) ? parseInt(value) : 0;

    }


    console.log(newTimings)

    this.setState({
      split_payments: newTimings
    })

    console.log(this.state)
  }




  fetchAccountDetails = (payload) => {
    if (payload) {
      PaymentServiceAPI.fetchSplitPayment(payload)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data.data)
            this.setState({ split_payments: response.data.data, isLoading: false });
          }

        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ isLoading: false });
    }


  };



  updateSplitPayments = () => {


    let payload = {
      "property_id": localStorage.getItem("property_id"),
      "split_payments": this.state.split_payments
    };


    if (payload) {
      PaymentServiceAPI.updateSplitPayment(payload)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data.data)
            this.setState({ isLoading: false });
            toast.success("The value has been updated.");
          } else {
            toast.error("Something went wrong. ");
          }

        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ isLoading: false });
    }


  };





  render() {

    return (
      <Fragment>

        <Breadcrumb />


        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel">

              <div className="ms-panel-body"></div>



              <Grid
                container
                spacing={2}
                direction="row"
                alignItems="center"

              >



              </Grid>

              <div className="ms-panel-header">
                <h6>Split Payments</h6>
              </div>


              <Grid
                container
                spacing={2}
                direction="column"
                alignItems="left"

              >
                <Grid item xs={4} md={4}>
                  <TableContainer sx={{ margin: "40px" }}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell >Property Name</TableCell>
                          <TableCell align="center">Percentage</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody style={{ width: "100%" }}  >
                        {this.state?.split_payments.map((row, i) => (
                          <TableRow key={row?.i}>
                            <TableCell component="th" scope="row"  >
                              <input
                                type="text" name="property_id"
                                value={row?.property_trading_name}
                                className="form-control no_caret"
                              />
                            </TableCell>
                            <TableCell align="center" >
                              <input
                                type="text"
                                name="value"
                                value={row?.value}
                                onChange={(e) =>
                                  this.venderPercentage(
                                    e.target.value,
                                    "value",
                                    i
                                  )
                                }
                                className="form-control no_caret"
                              /></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={4} md={4}>
                  <FormControl sx={{ float: "right", marginBottom: "30px" }}>
                    <Button
                      className="mt-0"
                      color="primary"
                      variant="contained"

                      onClick={this.updateSplitPayments}
                    >
                      Update
                    </Button>
                  </FormControl>

                </Grid>



                <Grid
                  container
                  spacing={2}
                  direction="column"
                  alignItems="center"

                ></Grid>


              </Grid>



            </div> </div> </div>
      </Fragment>
    );
  }
}
