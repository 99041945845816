import React, { Fragment, PureComponent } from "react";
import { Modal } from "react-bootstrap";
import Button from '@mui/material/Button';
import * as DataURLtoFile from "./dataToUrl";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonGroup from '@mui/material/ButtonGroup';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import $ from "jquery";
class MultipleImageCropper extends PureComponent {
  firstSource = null;
  handleClose = () => this.setState({ imagesList: [], src: null, show: false });
  handleShow = () => this.setState({ show: true });
  handleCloseInnerModal = () => this.setState({ showInnerModal: false });
  handleShowInnerModal = () => this.setState({ showInnerModal: true });



  state = {
    imagesList: [],
    currentImg: 0,
    src: null,
    crop: {
      unit: "px",
      width: 300,
      height: 300,
      aspect: 1,
      x: 0,
      y: 0,
    },
    show: false,
    showInnerModal: false,
    username: "",
    selectedFile: [],
    selectTarget: null
  };

  constructor(props) {
    super(props);

    this.selectorRef = React.createRef();

  }

  isFileImage(file) {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

    return file && acceptedImageTypes.includes(file['type'])
  }

  onSelectFile = (e) => {
    e.persist();

    if (this.state.imagesList !== null) {
      this.state.imagesList = [];
    }

    if (e.target.files && e.target.files.length > 0) {
      let cropObject = this.state.crop;
      /* Check for the image size */

      /*  for (let i = 0; i <= e.target.files.length - 1; i++) {
         console.log(e.target.files)
         const fsize = e.target.files.item(i).size;
         const file = Math.round((fsize / 1024));
         // The size of the file.
         if (file >= 4096) {
           window.alert(
             "File too Big, please select a file less than 4mb");
         }
       } */



      Array.from(e.target.files).forEach((file, idx) => {
        if (!this.isFileImage(file)) {
          window.alert(
            `Error : Only images are accepted. `
          );
          $(".fileControl").val('');
          return false;
        }
      });

      let promises = [];

      Array.from(e.target.files).forEach((file, idx) => {
        promises.push(this.loadImage(file));
      });


      //  console.log(e.target);
      if (this.props.data.isMultiple == true) {
        this.setState({
          selectedFile: Array.from(e.target.files),
        });
      } else {
        this.setState({
          selectTarget: e.target
        });
      }




      Promise.all(promises).then((values) => {
        console.log(values);
        const imageArray = values.filter(function (e) {
          return e;
        });


        console.log(values)


        // check size of array if size is different show error
        /*  if (values.length > imageArray.length) {
           window.alert(
             `Error : Some Images were rejected. Please select ${this.state.crop.width}x${this.state.crop.height} or larger images`
           );
         } */

        // check if array has items
        if (imageArray.length && imageArray.length > 0) {
          this.setState(
            {
              src: imageArray[0], // assign first item as source
              imagesList: imageArray,
              currentImg: 0,
            },
            () => {
              if (
                this.state.imagesList[0] &&
                this.state.imagesList[0].cropData
              ) {
                this.cropper.setCropBoxData(this.state.imagesList[0].cropData);
              }
            }
          );
        }
      });
    }
  };

  async loadImage(file) {
    return new Promise((resolve) => {
      let image = new Image();

      const { width = 300, height = 300 } = this.props;

      image.onload = () => {
        if (image.naturalHeight < height || image.naturalWidth < width) {
          console.log("Image Rejected...", image.src);
          $(".fileControl").val('');
          window.alert(
            `Error : Some Images were rejected. Please select ${this.state.crop.width}x${this.state.crop.height} or larger images`
          );
          resolve(null);
        }
        else {
          let url = this.addSelectedFile(file, image);
          resolve(url);
        }
      };

      console.log(file)
      const fsize = file.size;
      const files = Math.round((fsize / 1024));
      // The size of the file.
      if (files >= 5120) {
        resolve(null);
        $(".fileControl").val('');
        window.alert(
          "File too Big, please select a file less than 5 MB. ");
      }

      image.src = URL.createObjectURL(file);
    });

    // return image.onload = () => {

    //     if (image.naturalHeight < cropObject.height || image.naturalWidth < cropObject.width) {
    //         console.log('Image Rejected...', image.src);
    //         //  through toast here to tell the user that some images may have been rejected!
    //     } else {
    //         console.log('Adding file...',  image.src);
    //         this.addSelectedFile(file, image);
    //     }
    // }
  }

  doDefaultCropp = (file, imageRef) => {
    let imagesList = this.state.imagesList;

    const { width = 300, height = 300 } = this.props;

    let temp_obj = {};
    let crop = {
      unit: "px",
      width: width,
      height: height,
      x: 0,
      y: 0,
    };
    temp_obj["crop"] = this.state.crop;
    temp_obj["src"] = URL.createObjectURL(file);

    const max_container_width = width > 300 ? width : 300;

    if (imageRef.naturalWidth > max_container_width) {
      let aspectratio = imageRef.naturalHeight / imageRef.naturalWidth;

      // calculate approx new height for the image
      let newHeight = aspectratio * max_container_width;
      let maxWidth = max_container_width; // default max width for container

      // check if newHeight is according to required height
      if (newHeight < height) {
        // this.state.crop.height is minimum height required
        newHeight = height;
        maxWidth = Math.ceil(height / aspectratio); // set max width according to minimum height requiured
      }

      // temp_obj["max_width"] = maxWidth;

      imageRef.width = maxWidth;
      imageRef.height = newHeight;
    } else {
      // temp_obj["max_width"] = 'fit-content';
    }

    let croppedImageUrl = this.getCroppedImg(imageRef, crop, "newFile.jpeg");

    temp_obj["croppedImageUrl"] = croppedImageUrl;
    temp_obj["cropData"] = {
      height: crop.height,
      left: crop.x,
      top: crop.y,
      width: crop.width,
    };

    return temp_obj;
  };

  addSelectedFile = (file, imageRef, _this) => {
    return this.doDefaultCropp(file, imageRef);
  };

  UNSAFE_componentWillMount = () => {
    const { width, height } = this.props;
    this.setState({
      username: localStorage.getItem("username")
        ? localStorage.getItem("username")
        : "",
      crop: {
        ...this.state.crop,
        width,
        aspect: width / height,
      },
    });
    // this.setState({ crop: { ...this.state.crop, height: this.props.height, width: this.props.width} });
  };

  componentWillUnmount = () => { };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    // New lines to be added
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    let base64Image = canvas.toDataURL("image/jpeg");

    //this.setState({ base64Array: base64Image });
    return base64Image;
  }

  downloadImage() {
    let imageArray = [];
    this.state.imagesList.map((image) => {
      imageArray.push(image.croppedImageUrl);
    });

    // console.log(imageArray);

    let file = [];

    imageArray.forEach(async (image, i) => {
      await file.push(DataURLtoFile.dataURLtoFile(image, `image-${i}.png`));
    });

    let formData = new FormData();

    file.forEach((item) => {
      formData.append("promotions", item);
      formData.append("image_or_video", item);
      formData.append("profile_image", item);
      formData.append("cover_image", item);
      formData.append("menu_image", item);
    });



    //  console.log([imageArray]);


    // Call parent component function & return formData
    this.props.data.getCroppedImages(
      formData,
      this.props.data.key,
      imageArray,
      file
    );

    this.setState({ show: false, imagesList: [], currentImg: 0, src: null });
  }

  imageClick(idx) {
    this.setState(
      (state) => {
        const src = state.imagesList[idx];
        const crop = { ...state.imagesList[idx].crop };
        const currentImg = idx;

        return {
          src,
          crop,
          currentImg,
        };
      },
      () => {
        if (this.state.imagesList[idx] && this.state.imagesList[idx].cropData) {
          this.cropper.setCropBoxData(this.state.imagesList[idx].cropData);
        }
      }
    );
  }

  removeImage(name) {
    this.setState((state) => {
      let imagesList = [];
      let src = state.src;

      //   console.log(this.props);

      //   console.log(this.state.selectedFile);

      //   console.log(name);
      //   this.state.selectedFile= null;
      // this.selectorRef.value='';
      // this.state.selectedFile[0].name="";
      //   this.state.selectedFile.filees[1].pop();
      //  this.state.selectedFile.value = null;


      // this.state.selectedFile.splice(1, 1);


      //  this.state.selectedFile = null;

      state.imagesList.forEach((item, idx) => {
        if (name !== idx) {
          item.name = idx;
          imagesList.push(item);
        }

        if ((name === idx)) {
          this.state.selectedFile.splice(name, 1)
        }

        if (state.currentImg === name) {
          src = null;
        }
      });



      // console.log(this.selectorRef);

      if (this.props.data.isMultiple == true) {

        let list = new DataTransfer();

        // let file = new File(["content"], "filename.jpg");


        this.state.selectedFile.forEach(function (number) {
          list.items.add(number);
        })
        this.selectorRef.current.files = list.files;
      } else {
        console.log(this.state.selectTarget)
        this.state.selectTarget.value = null;
        this.state.selectTarget = null;

      }



      return {
        imagesList,
        src,
      };
    });
  }

  setAspect_169 = () => {
    this.cropper.setAspectRatio("16/9");
  }
  setAspect_11 = () => {
    this.cropper.setAspectRatio("1");
  }
  setAspect_23 = () => {
    this.cropper.setAspectRatio("2/3");
  }

  cropImage() {
    if (typeof this.cropper.getCroppedCanvas() === "undefined") {
      return;
    }

    this.setState((state) => {
      const { width = 300, height = 300 } = this.props;

      let imagesList = [];
      state.imagesList.forEach((item, idx) => {
        if (state.currentImg === idx) {
          item.croppedImageUrl = this.cropper
            .getCroppedCanvas({ width, height })
            .toDataURL();
          item.cropData = this.cropper.getCropBoxData();
        }
        imagesList.push(item);
      });
      return {
        imagesList,
      };
    });
  }

  onCropComplete = () => {
    if (typeof this.cropper.getCroppedCanvas() === "undefined") {
      return;
    }

    const { width = 300, height = 300 } = this.props;

    this.setState((state) => {
      let imagesList = [];
      state.imagesList.forEach((item, idx) => {
        if (state.currentImg === idx) {
          if (this.cropper.getCroppedCanvas() !== null) {
            item.croppedImageUrl = this.cropper
              .getCroppedCanvas({ width: 1000, height: 1000 })
              .toDataURL();
            item.cropData = this.cropper.getCropBoxData();
          }
        }
        imagesList.push(item);
      });
      return {
        imagesList,
      };
    });
  };

  render() {
    const { src, imagesList } = this.state;

    const { width = 300, height = 300 } = this.props;
    //col-md-2 col-xl-6 mb-2 
    return (
      <Fragment>
        <div className="App">

          {this.props.data.isMultiple ? (
            <Button color="primary" variant="contained" sx={{ mt: 2, mb: 2 }} onClick={this.handleShow}>
              {this.props.data.label
                ? this.props.data.label
                : "Upload Images"}
            </Button>
          ) : (
            <React.Fragment>
              <br /> <br />
              <Button
                color="primary"
                variant="contained"
                onClick={this.handleShow}
              >
                {this.props.data.label
                  ? this.props.data.label
                  : "Upload Image"}
              </Button>
            </React.Fragment>
          )}
        </div>
        <Dialog
          open={this.state.show}
          onClose={this.handleClose}
        // backdrop="static"
        // keyboard={false}
        // size="lg"
        >
          {" "}
          <DialogTitle closeButton>
            <div className="inputFile">
              {this.props.data.isMultiple ? (
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg, image/heif"
                  multiple
                  class="fileControl"
                  ref={this.selectorRef}
                  onChange={this.onSelectFile}
                />
              ) : (
                <input
                  type="file" class="fileControl"
                  ref={this.selectorRef}
                  accept="image/png, image/gif, image/jpeg, image/heif"
                  onChange={this.onSelectFile}
                />
              )}
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="main-modal">
              {src && (
                <React.Fragment>

                  <ButtonGroup variant="outlined" aria-label="outlined button group" className="mt-1">
                    <div className="d-flex flex-wrap align-items-center">
                      <Button
                        color="primary"
                        size="sm"
                        className="mt-2"
                        onClick={() => this.setAspect_169()}
                      >
                        16:9
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        size="sm"
                        className="mt-2"
                        onClick={() => this.setAspect_11()}
                      >
                        1:1
                      </Button>
                      &nbsp;

                      <Button
                        color="primary"
                        size="sm"
                        className="mt-2"
                        onClick={() => this.cropper.move(-10, 0)}
                      >
                        <span class="ms-text-primary fa fa-arrow-left"></span>
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        size="sm"
                        className="mt-2"
                        onClick={() => this.cropper.move(10, 0)}
                      >
                        <span class="ms-text-primary fa fa-arrow-right"></span>
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        size="sm"
                        className="mt-2"
                        onClick={() => this.cropper.move(0, -10)}
                      >
                        <span class="ms-text-primary fa fa-arrow-up"></span>
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        size="sm"
                        className="mt-2"
                        onClick={() => this.cropper.move(0, 10)}
                      >
                        <span class="ms-text-primary fa fa-arrow-down"></span>
                      </Button>
                      &nbsp;

                      {/*   <Button
                      color="primary"
                      size="sm"
                      className="mt-0"
                      onClick={() => this.cropper.zoom(0)}
                    >
                     <span class="ms-text-primary fa fa-arrows-alt-h"></span>
                    </Button>

                    <Button
                      color="primary"
                      size="sm"
                      className="mt-0"
                      onClick={() => this.cropper.zoom(0.5)}
                    >
                     <span class="ms-text-primary fa fa-arrows-alt-v"></span>
                    </Button>  */}


                      <Button
                        color="primary"
                        size="sm"
                        className="mt-2"
                        onClick={() => this.cropper.zoom(-0.3)}
                      >
                        <span color="primary" class="ms-text-primary fa fa-minus"></span>
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        size="sm"
                        className="mt-2"
                        onClick={() => this.cropper.zoom(0.3)}
                      >
                        <span color="primary" class="ms-text-primary fa fa-plus "></span>
                      </Button>
                      &nbsp;

                      <Button
                        color="primary"
                        size="sm"
                        className="mt-2"
                        onClick={() => this.cropper.reset()}
                      >
                        <span color="primary" class="ms-text-primary fa fa-sync-alt"></span>
                      </Button>
                      &nbsp; &nbsp; &nbsp;
                      <Button
                        color="primary"
                        variant="contained"
                        className="mt-2"
                        onClick={() => this.downloadImage()}
                      >
                        Save
                      </Button>
                    </div>
                  </ButtonGroup>

                </React.Fragment>
              )}

              {/* {src && (
                        <div>
                          <Button
                            onClick={() => this.cropImage()}
                            variant="warning"
                          >
                            Update Cropped Image
                          </Button>
                        </div>
                      )} */}

              <div className="imageCrop">
                {src && (
                  <div style={{ width: "100%" }}>
                    <Cropper
                      src={src?.src}
                      boxData={src?.cropData}
                      style={{ height: "400px", width: "100%" }}
                      initialAspectRatio={1}
                      // preview=".img-preview"
                      viewMode={1}
                      center={true}
                      minCropBoxHeight={50}
                      minCropBoxWidth={50}
                      autoCropArea={1}
                      background={true}
                      responsive={true}
                      checkOrientation={false}
                      ref={(cropper) => {
                        this.cropper = cropper;
                      }}
                      guides={true}
                      dragMode="move"
                      cropBoxMovable={true}
                      crop={this.onCropComplete}
                    />
                  </div>
                )}
              </div>

              <div className="imagePreview">
                {imagesList.map((item, idx) => {
                  return (
                    <div className="img-container" key={idx}>
                      <img
                        alt="Crop"
                        key={idx}
                        onClick={() => this.imageClick(idx)}
                        src={item.src}
                      />

                      <img
                        className="close"
                        src="https://cdn.icon-icons.com/icons2/1674/PNG/512/close_111152.png"
                        onClick={() => this.removeImage(idx)}
                      />
                    </div>
                  );
                })}
              </div>

              {/* {src && <h5 className="my-1">Cropped Images</h5>}
                    <div className="imagePreview">
                     
                      {imagesList.map((item, idx) => {
                        return (
                          <div className="img-container" key={idx}>
                            <img
                              alt="Crop"
                              key={idx}
                              onClick={() => this.imageClick(idx)}
                              src={item.croppedImageUrl}
                            />
                          </div>
                        );
                      })}
                    </div> */}
              {/* <div className="saveImage">
                      {src && (
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => this.downloadImage()}
                        >
                          Save Changes
                        </Button>
                      )}
                    </div> */}
            </div>
            <DialogActions>
              <Button
                className="mt-0"
                color="primary"
                variant="contained"
                onClick={this.handleClose}
              >
                Close
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

      </Fragment>
    );
  }
}

export default MultipleImageCropper;
