import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";
import Typography from "@mui/material/Typography";
import * as PaymentLinksServiceAPI from "../../../services/paymentLinks/PaymentLinksServiceAPI";
import {
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Divider from "@mui/material/Divider";
import dayjs from "dayjs";
import { withStyles } from "@mui/material/styles";
import * as PaymentServiceAPI from "../../../services/payment/PaymentServiceAPI";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { withRouter } from "react-router-dom";
import pay from "../../../container/pay";
import { values } from "../../../assets/vendors/iconic-fonts/font-awesome/js/v4-shims";
import { RowingRounded } from "@mui/icons-material";
import Loader from "../../../shared/Loader";
import { connect } from "react-redux";
import {getCurrencySymbol} from "../../../config";


class Detailcontent extends Component {
  state = {
    transactionDetails: [],
    payout_summary: "",
    row: "",
    total: 0,
    payout_id: "",
    origin: "",
    type: "",
    currency: "",
    payByLinkTransactions: "",
    today_date: new Date().toLocaleDateString(),
    openPreAuth: false,
    openPreAuthCancel: false,
    status: "",
    amount: "",
    lab: "",
    updated_value: "",
    transaction_id: "",
    data: "",
    isLoading: false,
    bool: false,
    cardNumber:"",
  };

  componentDidMount = () => {
    const state = this.props.history.location.state;
    this.setState({
      row: this.props?.history?.location?.state?.row,
      status: this.props?.history?.location?.state?.row?.kbsd_status,
      is_pre_auth: this.props?.history?.location?.state?.row?.is_pre_auth,
      updated_value: this.props?.history?.location?.state?.row?.updated_value,
    });

    const payload = { transaction_id: state?.row?.transaction_id };
    this.fetchPaymentLinkTransactions(
      this.props?.history?.location?.state?.row.payment_link_id
    );
    //  this.fetchPayoutDetails(payload)
  };

  fetchTransaction = (row) => {
    if (row) {
      const payload = {
        row: row,
        status: this.props?.history?.location?.state?.row?.kbsd_status,
      };
      this.props.history.push({
        pathname: "/pay-by-link-transaction-details",
        state: payload,
      });
    }
  };

  fetchPayoutDetails = (payload) => {
    PaymentServiceAPI.fetchTransactionDetails(payload).then((response) => {
      if (response.data.status) {
        this.setState({
          transactionDetails: response.data.data,
        });
      } else {
        toast.error(response.data.message);
      }
    });
  };

  fetchPaymentLinkTransactions = async (payment_link_id) => {
    try {
      this.setState({ isLoading: true });

      const response =
        await PaymentLinksServiceAPI.fetchPaymentLinkTransactions(
          payment_link_id
        );

      if (response && response.data && response.data.status) {
        this.setState({ paymentLinks: response.data.data[0], isLoading: false,cardNumber:response.data.data[0].card_number });
        this.setState({ payByLinkTransactions: response.data.data });
        // this.setState({ payByLinkTransactions: response.data.data });
        // this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
        // toast.success("Discount added. ")
      } else {
        toast.warn("There are no transaction associated with the pay by link.");
            this.setState({ paymentLinks: [], isLoading: false });
        //   this.setState({ paymentLinks: [] });
      }
    } catch (error) {
      toast.error("Something has gone wrong.");
      //  this.setState({ paymentLinks: [], isLoading: false });
      throw error;
    }
  };

  /*     fetchPayoutReport = () => {
    
            const payload = {
                payout_id: this.state.row?.transaction_id 
    
            }
    
    
            PaymentServiceAPI.fetchPayoutReports(payload).then((response) => {
                const file = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                this.downloadFile(fileURL);
                //   window.open(fileURL);
            }).catch(error => {
            });;
    
    
    
        };
     */
  downloadFile(filePath) {
    var link = document.createElement("a");
    link.href = filePath;
    link.download = "invoice";
    link.click();
  }

  showOrderDetails = (value) => {
    const payload = {
      property_id: this.state.property_id,
      payment_id: value,
    };
    PaymentServiceAPI.fetchOrderDetailsByPayoutId(payload).then((response) => {
      if (response.data.status) {
        const order = response.data.data;
        this.props.history.push({
          pathname: "/orderdetails",
          state: {
            order: order,
          },
        });
      }
    });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  handlePreauthOpen = (text, row) => {
    this.setState({
      openPreAuth: true,
      amount: "",
      lab: text,
      transaction_id: row.transaction_id,
      data: row,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCancelTrasactionClose = () => {
    this.setState({ openPreAuthCancel: false });
  };

  handleClosePreAuth = () => {
    this.setState({ openPreAuth: false, amount: "" });
  };

  handlePreAuthCancel = (row) => {
    this.setState({
      openPreAuthCancel: true,
      transaction_id: row.transaction_id,
    });
  };

  columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          <div>{row.name}</div>
        </div>
      ),
    },
    {
      name: "Card Number",
      selector: "card_number",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> **********{row.card_number}</div>,
    },
    {
      name: "Date",
      selector: "transaction_date",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          <div>{row.transaction_date}</div>
        </div>
      ),
    },
    {
      name: "Payment Method",
      selector: "payment_method",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          {" "}
          {(row?.payment_method == "mc" && "Mastercard") ||
            (row?.payment_method == "visa" && "Visa") ||
            (row?.payment_method == "amex" && "American Express")}
        </div>
      ),
    },
    {
      name: "Captured Amount",
      selector: "captured_value",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {getCurrencySymbol(localStorage.getItem('currency_code')) + row.captured_value?.toFixed(2)}</div>,
    },
    {
      name: "Transaction Date",
      selector: "transaction_status",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {row.transaction_date}</div>,
    },

    {
      name: "Transaction Status",
      selector: "transaction_status",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {row.transaction_status}</div>,
    },

    // {
    //   name: "Update Payment",
    //   selector: "update_payment",
    //   justifyContent: "center",
    //   cell: (row) =>
    //     row.transaction_status !== "COMPLETED" &&
    //     row.transaction_status !== "REFUNDED" &&
    //     row.transaction_status !== "FAILED" &&
    //     row.transaction_status !== "REFUND FAILED" &&
    //     row.transaction_status !== "CANCELLED" &&
    //     row.transaction_status !== "UPDATE AMOUNT INITIATED" &&
    //     row.transaction_status !== "CAPTURE INITIATED" &&
    //     row.transaction_status !== "CANCEL INITIATED" &&
    //     row.is_pre_auth == true && (
    //       <div>
    //         <Button
    //           variant="outlined"
    //           onClick={() => {
    //             this.handlePreauthOpen("Update", row);
    //           }}
    //         >
    //           Update
    //         </Button>
    //       </div>
    //     ),
    // },
    // {
    //   name: "Capture Payment",
    //   selector: "payment",
    //   justifyContent: "center",
    //   cell: (row) =>
    //     row.transaction_status !== "COMPLETED" &&
    //     row.transaction_status !== "FAILED" &&
    //     row.transaction_status !== "REFUNDED" &&
    //     row.transaction_status !== "REFUND FAILED" &&
    //     row.transaction_status !== "CANCELLED" &&
    //     row.transaction_status !== "UPDATE AMOUNT INITIATED" &&
    //     row.transaction_status !== "CAPTURE INITIATED" &&
    //     row.transaction_status !== "REFUND INITIATED" &&
    //     row.transaction_status !== "CANCEL INITIATED" &&
    //     row.is_pre_auth == true && (
    //       <div>
    //         <Button
    //           variant="outlined"
    //           onClick={() => {
    //             this.handlePreauthOpen("Capture", row);
    //           }}
    //         >
    //           Capture
    //         </Button>
    //       </div>
    //     ),
    // },
    // {
    //   name: "Cancel Payment",
    //   selector: "payment",
    //   justifyContent: "center",
    //   cell: (row) =>
    //   row.transaction_status !== "COMPLETED" &&
    //   row.transaction_status !== "FAILED" &&
    //   row.transaction_status !== "REFUNDED" &&
    //   row.transaction_status !== "REFUND FAILED" &&
    //   row.transaction_status !== "CANCELLED" &&
    //   row.transaction_status !== "UPDATE AMOUNT INITIATED" &&
    //   row.transaction_status !== "CAPTURE INITIATED" &&
    //   row.transaction_status !== "REFUND INITIATED" &&
    //   row.transaction_status !== "CANCEL INITIATED" &&
    //     row.is_pre_auth == true && (
    //       <div>
    //         <Button
    //           variant="outlined"
    //           onClick={() => this.handlePreAuthCancel(row)}
    //         >
    //           Cancel
    //         </Button>
    //       </div>
    //     ),
    // },
    {
      name: "Action",
      selector: "action",
      justifyContent: "center",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            onClick={() => row && this.fetchTransaction(row)}
          >
            <VisibilityIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  customStyles = {
    rows: {
      style: {
        //          minHeight: '70px', // override the row height
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "65px",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "auto",
      },
    },
    cells: {
      style: {
        paddingLeft: "2px", // override the cell padding for data cells
        paddingRight: "2px",
        pointer: "cursor",
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };
  cancelTransaction = () => {
    this.setState({
      /*  isLoading: true, */ openPreAuthCancel: false,
    });

    const payload = {
      transaction_id: this.state?.transaction_id,
    };
    PaymentLinksServiceAPI.cancelTransaction(payload)
      .then((response) => {
        if (response.data.status) {
          toast.success("Transaction cancelled.");
          this.setState({ openPreAuthCancel: false, isLoading: false });
          setTimeout(() => {
            window.history.back();
          }, 2000);
        } else {
          this.setState({
            openPreAuthCancel: false,
            isLoading: false,
          });
          toast.warn("Couldn't cancel transaction.");
        }
      })
      .catch((err) => {
        toast.warning("Error : ", err);
        toast.success("Something has gone wrong.");
        this.setState({
          isLoading: false,
          openPreAuthCancel: false,
        });
      });
  };

  captureOrUpdate = () => {
    if (
      this.state.lab === "Capture" &&
      this.state.amount > this.state.row.value
    ) {
      toast.warn(
        `Capture amount should be less than or equal to ${this.state.row.value}`
      );
    } else {
      if (this.state.amount) {
        const payload = {
          transaction_id: this.state?.transaction_id,
          amount: this.state.amount,
        };

        if (this.state.lab === "Capture") {
          this.setState({ isLoading: true, bool: true });

          PaymentLinksServiceAPI.capturePayByLinkPreAuth(payload)
            .then((response) => {
              if (response.data.status) {
                this.setState({
                  openPreAuth: false,
                  isLoading: false,
                  bool: false,
                });
                toast.success("Amount Captured.");
                setTimeout(() => {
                  window.history.back();
                }, 2000);
              } else {
                this.setState({
                  data: [],
                  open: false,
                  isLoading: false,
                  bool: false,
                });
                toast.warn("Couldn't capture amount.");
              }
            })
            .catch((err) => {
              toast.warning("Error : ", err);
              toast.success("Something has gone wrong. ");
              this.setState({
                isLoading: false,
                open: false,
                bool: false,
              });
            });
        } else if (this.state.lab === "Update") {
          PaymentLinksServiceAPI.updatePayByLinkPreAuth(payload)
            .then((response) => {
              if (response.data.status) {
                toast.success("Amount Updated.");
                this.setState({ open: false, isLoading: false });
                setTimeout(() => {
                  window.history.back();
                }, 2000);
              } else {
                this.setState({
                  data: [],
                  open: false,
                  isLoading: false,
                });
                toast.warn("Couldn't update amount.");
              }
            })
            .catch((err) => {
              toast.warning("Error : ", err);
              toast.success("Something has gone wrong. ");
              this.setState({
                isLoading: false,
                open: false,
              });
            });
        }
      } else {
        toast.warn("Amount is required.");
      }
    }
  };
  render() {
    const {lab, row, bool } = this.state;
    return (
      <>
        <div className="ms-content-wrapper">
          {this.state.isLoading ? (
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "40px",
              }}
            >
              {" "}
              <Loader size={50} />{" "}
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-md-12">
                  <Breadcrumb />
                  <div className="ms-panel">
                    <div className="ms-panel-header header-mini">
                      <div className="d-flex justify-content-between">
                        {/*    <div>
                                        <h4 className='mt-3 d-inline'>£ {this.state.row?.value.toFixed(2) || Number(0).toFixed(2)}   </h4>   {this.state.row?.transaction_status && <Chip style={{ color: "white" }} className="d-inline" label={this.state.row?.transaction_status} />}

                                    </div> */}
                        {/*    <div className="invoice-buttons text-right"> */}{" "}
                        {/* <Link to="#" className="btn btn-primary mr-2">Print</Link> */}
                        {/* <Button variant="contained" onClick={() => this.fetchPayoutReport()} >Download </Button> */}
                        {/* 
                                    </div> */}
                      </div>
                    </div>
                    <br /> <br />
                    <Divider variant="middle" />
                    <div className="ms-panel-body">
                      <React.Fragment>
                        <CssBaseline />

                        <Dialog
                          open={this.state.openPreAuthCancel}
                          onClose={this.handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          PaperProps={{
                            style: {
                              minWidth: "350px", // Set your desired width
                            },
                          }}
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Cancel transaction ?"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Do you really want to proceed?
                              <br />
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={this.handleCancelTrasactionClose}>
                              No
                            </Button>
                            <Button onClick={() => this.cancelTransaction()}>
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>

                        <Dialog
                          open={this.state.openPreAuth}
                          onClose={this.handleClosePreAuth}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          PaperProps={{
                            style: {
                              minWidth: "350px",
                            },
                          }}
                        >
                          <DialogTitle id="alert-dialog-title">
                            {this.state.lab} Amount
                          </DialogTitle>
                          <DialogContent>
                            <FormControl fullWidth>
                              <br />
                              <TextField
                                autoFocus
                                margin="dense"
                                id="updated_amount"
                                label="Amount"
                                type="number"
                                value={this.state.amount || ""}
                                inputProps={{
                                  min: 1,
                                  max: row?.value,
                                }}
                                onChange={(e) => {
                                  if (
                                    e.target.value &&
                                    /^(\d*\.?\d{0,6}|\.\d{1,6})$/.test(
                                      e.target.value
                                    )
                                  ) {
                                    this.setState({ amount: e.target.value });
                                  } else {
                                    this.setState({ amount: "" });
                                  }
                                }}
                                error={
                                  this.state.amount &&
                                  this.state.amount > this.state.row.value
                                }
                                helperText={`${lab} Amount should be less or equal to ${row?.value?.toFixed(2)}`}
                                fullWidth
                                variant="outlined"
                              />
                            </FormControl>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={this.handleClosePreAuth}
                              color="primary"
                            >
                              No
                            </Button>
                            <Button
                              disabled={bool}
                              onClick={this.captureOrUpdate}
                              color="primary"
                              variant="contained"
                            >
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                        <Grid
                          container
                          style={{
                            backgroundColor: "rgb(0 0 0 / 8%)",
                            height: "15vh",
                            paddingLeft: "10%",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between", // Align items horizontally
                            }}
                          >
                            <Typography variant="h4">Link Details</Typography>
                            <Button
                              style={{ marginRight: "40px" }}
                              variant="outlined"
                              onClick={this.handleBack}
                            >
                              Back
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              <p>
                                Merchant ref:{" "}
                                {
                                  this.props?.history?.location?.state?.row
                                    .merchant_reference
                                }
                              </p>
                            </Typography>
                          </Grid>
                          {/* You can add more Grid items for additional content */}
                        </Grid>

                        <br />
                        <br />
                        <Grid container spacing={2}>
                          {/* <Grid item xs={12} md={3}>

                                            <Typography variant="h4" style={{ paddingLeft: '20%', marginTop: "20px" }}>Summary</Typography>
                                        </Grid> */}
                          <Grid item xs={12} md={6}>
                            <Typography
                              variant="h4"
                              style={{ marginTop: "20px" }}
                            >
                              Summary
                            </Typography>

                            <div className="ms-invoice-table table-responsive">
                              <TableContainer component={Paper}>
                                <Table size="medium" aria-label="a dense table">
                                  <TableBody>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        Payment Link
                                      </TableCell>
                                      <TableCell>
                                        {" "}
                                        {this.props?.history?.location?.state
                                          ?.row.kbsd_status === "ACTIVE" ? (
                                          <a
                                            href={
                                              this.props?.history?.location
                                                ?.state?.row.url
                                            }
                                            target="_blank"
                                          >
                                            {
                                              this.props?.history?.location
                                                ?.state?.row.url
                                            }
                                          </a>
                                        ) : (
                                          this.props?.history?.location?.state
                                            ?.row.url
                                        )}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        Status
                                      </TableCell>
                                      <TableCell>
                                        {
                                          this.props?.history?.location?.state
                                            ?.row?.kbsd_status
                                        }
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        Description
                                      </TableCell>
                                      <TableCell>
                                        {
                                          this.props?.history?.location?.state
                                            ?.row?.description
                                        }
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        Link Type
                                      </TableCell>
                                      <TableCell>
                                        {
                                          this.props?.history?.location?.state
                                            ?.row?.link_type
                                        }
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        Merchant Reference
                                      </TableCell>
                                      <TableCell>
                                        {
                                          this.props?.history?.location?.state
                                            ?.row?.merchant_reference
                                        }
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        Payment Link Id
                                      </TableCell>
                                      <TableCell>
                                        {
                                          this.props?.history?.location?.state
                                            ?.row?.payment_link_id
                                        }
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        Link Validity
                                      </TableCell>
                                      <TableCell>
                                        {
                                          this.props?.history?.location?.state
                                            ?.row?.link_validity
                                        }
                                      </TableCell>
                                    </TableRow>
                                    {/* <TableRow>
                                      <TableCell component="th" scope="row">
                                        Card Number
                                      </TableCell>
                                      <TableCell>
                                       
                                       ********{this.state?.cardNumber}
                                      </TableCell>
                                    </TableRow> */}
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        Amount
                                      </TableCell>
                                      <TableCell>
                                        {" "}
                                        {" "}
                                        {getCurrencySymbol(localStorage.getItem('currency_code')) + this.props?.history?.location?.state?.row?.value?.toFixed(
                                          2
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </Grid>
                          {/* <Grid item xs={12} md={6}>
                      {status !== "COMPLETED" &&
                        status !== "REFUNDED" &&
                        status !== "REFUND FAILED" &&
                        status !== "CANCELLED" &&
                        status !== "UPDATE AMOUNT INITIATED" &&
                        status !== "CAPTURE INITIATED" &&
                        status !== "CANCEL INITIATED" &&
                        is_pre_auth == true && (
                          <React.Fragment>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              &nbsp; &nbsp;
                              <ButtonGroup
                                variant="outlined"
                                aria-label="outlined button group"
                              >
                                <Button
                                  style={{ float: "right" }}
                                  color="primary"
                                  onClick={() => {
                                    this.handlePreauthOpen("Update");
                                  }}
                                >
                                  UPDATE
                                </Button>

                                <Button
                                  style={{ float: "right" }}
                                  color="primary"
                                  onClick={() => {
                                    this.handlePreauthOpen("Capture");
                                  }}
                                >
                                  Capture
                                </Button>

                                <Button
                                  style={{ float: "right" }}
                                  color="primary"
                                  onClick={() => {
                                    this.handlePreAuthCancel();
                                  }}
                                >
                                  Cancel
                                </Button>
                              </ButtonGroup>
                            </div>
                          </React.Fragment>
                        )}
                    </Grid> */}
                        </Grid>
                      </React.Fragment>

                         <React.Fragment>



                                    {/* <Grid container spacing={1} >
                                        <Grid item xs={12} sm={12}>
                                            <Grid
                                                container
                                                spacing={4}
                                                direction="row"
                                                alignItems="center"
                                            >
                                                <Grid item xs={12} md={4} lg={2}>
                                                    <div>Transaction date</div>
                                                    <div>  {this.state.row?.transaction_date}</div>
                                                </Grid>
                                                <Divider orientation="vertical" flexItem />
                                                <Grid item xs={12} md={4} lg={2} sx={{ ml: 2 }}>
                                                    <div>Payment method</div>
                                                    <div> {this.state.row?.card_number ? "*******" + this.state.row?.card_number : ""}</div>
                                                </Grid>
                                            </Grid>


                                        </Grid>

                                    </Grid> */}

                                </React.Fragment>


                                {/* <div className="row align-items-center">

                                    <div className="col-md-6 ">
                                        <div className=" table-heading">
                                            <h4>Transaction details</h4>
                                        </div>
                                        <Divider variant="middle" />

                                        <br></br>
                                        <div className="ms-invoice-table table-responsive">


                                            <TableContainer component={Paper}>
                                                <Table size="small" aria-label="a dense table" >

                                                    <TableBody >




                                                        <TableRow >
                                                            <TableCell component="th" scope="row">Card number</TableCell>
                                                            <TableCell> { "*******" + this.state.paymentLinks?.card_number } </TableCell>

                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell component="th" scope="row">Card Expiry</TableCell>
                                                            <TableCell >{this.state.paymentLinks?.card_expiry}</TableCell>

                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell component="th" scope="row">Payment Mode</TableCell>
                                                            <TableCell >{this.state.paymentLinks?.payment_mode}</TableCell>

                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell component="th" scope="row">Payment method</TableCell>
                                                            <TableCell> {this.state.paymentLinks?.payment_method == "mc" && "Mastercard"}
                                                                {this.state.paymentLinks?.payment_method == "visa" && "Visa"}
                                                                {this.state.paymentLinks?.payment_method == "amex" && "American Express"}
                                                            </TableCell>

                                                        </TableRow>



                                                    </TableBody>


                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>

                                <Grid item xs={12} md={12} style={{ paddingTop: 5, marginTop: 10 }} >
                                    <br></br>
                                    <br></br>
                                    <Typography variant="h5" gutterBottom>
                                        Metadata
                                    </Typography>
                                    <Divider variant="middle" />
                                </Grid>


                                <div className="col-md-6 ">

                                    <div className="ms-invoice-table table-responsive">


                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="a dense table" >

                                                <TableBody >

                                                    <TableRow >
                                                        <TableCell component="th" scope="row">Name</TableCell>
                                                        <TableCell> {this.state.paymentLinks?.name}</TableCell>

                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell component="th" scope="row">Email</TableCell>
                                                        <TableCell> {this.state.paymentLinks?.email}</TableCell>

                                                    </TableRow>

                                                    <TableRow >
                                                        <TableCell component="th" scope="row">Phone No</TableCell>
                                                        <TableCell> {this.state.paymentLinks?.phone_number}</TableCell>

                                                    </TableRow>





                                                    <TableRow >
                                                        <TableCell component="th" scope="row"  >Transaction Id</TableCell>
                                                        <TableCell  >{this.state.paymentLinks?.transaction_id}</TableCell>

                                                    </TableRow>

                                                    <TableRow >
                                                        <TableCell component="th" scope="row"  >PSP Refrence</TableCell>
                                                        <TableCell  >{this.state.paymentLinks?.psp_reference}</TableCell>

                                                    </TableRow>


                                                    <TableRow >
                                                        <TableCell component="th" scope="row">Reference</TableCell>
                                                        <TableCell  >{this.state.paymentLinks?.reference}</TableCell>

                                                    </TableRow>


                                                </TableBody>


                                            </Table>
                                        </TableContainer>
                                    </div>

                                </div> */}

                      <Grid item xs={12} md={3} lg={12}>
                        <br />
                        <br /> <br />
                        <br />
                        <DataTable
                          columns={this.columns}
                          customStyles={this.customStyles}
                          pagination
                          paginationRowsPerPageOptions={[
                            10, 25, 50, 100, 500, 1000,
                          ]}
                          data={this.state.payByLinkTransactions}
                        />
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default Detailcontent;
