import React, { Component } from 'react';
import Pagination from '../../../shared/Pagination/Pagination';
import DataTable from 'react-data-table-component';
import * as DashboardServiceAPI from "../../../services/dashboard/DashboardServiceAPI";
import Loader from "../../../shared/Loader";
import Typography from '@mui/material/Typography';
import { connect } from "react-redux";
import dayjs from 'dayjs';
import Divider from '@mui/material/Divider';
import {getCurrencySymbol} from "../../../config";
class Recentorder extends Component {

    state = {

        page: 0,
        order_details: [],
        current_page: 0,
        number_of_elements: 10,
        page_size: 10,
        total_elements: 0,
        total_pages: 0,
        property_id: null,
        isLoading: true,
        start: "",
        edn: ""

    };

    constructor(props) {
        super(props)
    };


    componentDidMount = () => {

        this.setState({ start: dayjs(this.props.state.start).format("DD-MM-YYYY"), end: dayjs(this.props.state.end).format("DD-MM-YYYY") });


        /*     (async () => {
                const rawResponse = await fetch('http://18.133.81.78:8086/business/report/fetch-transactions-summary', {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(payload)
                });
                const content = await rawResponse.json();
              
                console.log(content);
              })();
     */


    };


    render() {

        const { start, end } = this.props.state;
       
        const customStyles = {
            rows: {
                style: {
                    //              minHeight: '70px', // override the row height
                }
            },
            headCells: {
                style: {
                    //                   minHeight: '65px',

                    backgroundColor: '#5773ff',
                    fontSize: '14px',
                    color: 'white',
                    justifyContent: "center",
                    '&:hover div': {
                        backgroundColor: 'transparent',
                        color: 'white !important'
                    },
                    'div': {
                        color: 'white !important'
                    },
                    'span': {
                        color: 'white !important'
                    },

                },
            },
            cells: {
                style: {

                    pointer: 'cursor',

                },
            },
        };

        const customHeader = ({ column }) => {
            return (
                <div style={{ textAlign: column.center ? "center" : "" }}>
                    {column.name}
                </div>
            );
        };

        const order_list_columns = [

            {
                name: 'Item Name',
                selector: 'item_name',
                sortable: true,
                cell: (row) => (
                    <div style={{ cursor: "pointer" }} >
                        <div onClick={() => this.props.fetchOrderDetailsByOrderId(row)}>
                            {row.item_name}
                        </div>
                    </div>
                ),
                headerCell: customHeader

            },
            {
                name: 'Item Price',
                selector: 'price',
                sortable: true,
                cell: (row) => (
                    <div className="" /* onClick={() => this.fetchOrderDetailsByOrderId(row)} */>
                     {getCurrencySymbol(localStorage.getItem('currency_code')) + row.price.toFixed(2)}
                    </div>
                ),
                style: {
                    textAlign: "center", justifyContent: 'center'
                },
            },
            {
                name: 'Sold Quantity',
                selector: 'sold_out_quantity',
                sortable: true,
                style: {
                    textAlign: "center", justifyContent: 'center'
                },

            },
            {
                name: 'Available Quantity',
                selector: 'available_quantity',
                width: "200px",
                sortable: true,
                style: {
                    textAlign: "center", justifyContent: 'center'
                },

            },
        ];

        return (

            <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12"  style={{ overflow: "hidden" }} >
                <div className="table-responsive"  style={{ overflow: "hidden" }}>
                    {this.props.state.isLoading ? (
                        <Loader size={30} />
                    ) : (
                        <div>
                            <Typography variant="h5" gutterBottom>
                                Top Items Sold     
                                <span style={{ fontSize: "20px " }}>
                                    {this.props.state?.filter == "4" && ` ( ${dayjs(this.props.state.start).format("DD-MM-YYYY")}  to  ${dayjs(this.props.state.end).format("DD-MM-YYYY")}  )`}
                                    {this.props.state?.filter == "6" && ` ( ${dayjs(this.props.state.start).format("DD-MM-YYYY")}   to   ${dayjs(this.props.state.end).format("DD-MM-YYYY")}  )`}
                                    {this.props.state?.filter == "5" && ` ( ${dayjs(this.props.state.start).format("DD-MM-YYYY")}  to  ${dayjs(this.props.state.end).format("DD-MM-YYYY")}  )`}
                                    {this.props.state?.filter == "3" && ` ( ${dayjs(this.props.state.start).format("DD-MM-YYYY")}   to  ${dayjs(this.props.state.end).format("DD-MM-YYYY")}  )`}
                                    {this.props.state?.filter == "2" && ` ( ${dayjs(this.props.state.start).format("DD-MM-YYYY")}  )`}
                                    {this.props.state?.filter == "1" && ` ( ${dayjs(this.props.state.start).format("DD-MM-YYYY")}  )`}
                                </span>

                            </Typography>

                            <Divider variant="middle" />

                            <DataTable
                                // title="Today's Order"
                                columns={order_list_columns}
                                pagination
                                paginationRowsPerPageOptions={[10]}
                                data={this.props.state.order_details}
                                customStyles={customStyles}
                            />
                            <Divider orientation="vertical" flexItem />
                        </div>

                    )
                    }
                    {/*     <div className="row" style={{ float: 'right', margin: '25px' }}>
                        <Pagination currentPage={this.props.state.current_page} total_pages={this.props.state.total_pages} handleChange={this.props.changeCurrentPage} />
                    </div>
                    <div style={{ float: 'left', margin: '15px' }}>Total Records : {this.props.state.total_elements}</div> */}
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, null)(Recentorder);