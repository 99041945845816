import React, { Component } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as message from "../../utils/Message";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import * as SecurityServiceAPI from "../../services/user/SecurityServiceAPI";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { Form, Formik } from "formik";
import * as schema from "../../utils/Schema";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { connect } from "react-redux";

class CreateNewPassword extends Component {
  state = {
    loading: false,
  };

  handleForcePassChange = (values) => {
    this.setState({ loading: true });
    const payload = {
      email: this.props.email,
      password: values.old_password,
      new_password: values.new_password,
    };
    SecurityServiceAPI.needPasswordChangeUpdate(payload)
      .then((response) => {
        if (response.data.status) {
          toast.success(message.PASSWORD_CHANGE_SUCCESS);
          this.setState({ loading: false });
          toast.success("Please login with new credentials");
          this.props.history.push("/");
        } else {
          this.setState({ loading: false });
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.error("Something went wrong");
      });
  };

  togglePasswordHide = () => {
    this.setState({ toggle_password: !this.state.toggle_password });
  };

  render() {
    const { loading, toggle_password } = this.state;

    return (
      <div className="ms-body ms-primary-theme ms-logged-out">
        <main className="body-content">
          <div className="ms-content-wrapper ms-auth">
            <div className="ms-auth-container">
              <div className="ms-auth-col">
                <div className="ms-auth-bg" />
              </div>
              <div className="ms-auth-col">
                <div className="ms-auth-form">
                  <Formik
                    initialValues={{
                      old_password: "",
                      new_password: "",
                      confirm_password: "",
                    }}
                    validationSchema={schema.createNewPasswordSchema}
                    onSubmit={(values) => {
                      this.handleForcePassChange(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      isValid,
                      dirty,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Box
                          sx={{
                            my: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h1" variant="h5">
                            Create New Password
                          </Typography>
                        </Box>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="old_password"
                          label="Temporary password"
                          type="text"
                          id="old_password"
                          size="small"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.old_password}
                          error={
                            touched.old_password && errors.old_password
                              ? true
                              : false
                          }
                          helperText={
                            errors.old_password && touched.old_password
                              ? errors.old_password
                              : null
                          }
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="new_password"
                          label="New password"
                          type={toggle_password ? "text" : "password"}
                          id="new_password"
                          size="small"
                          autoComplete="current-new_password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.new_password}
                          error={
                            touched.new_password && errors.new_password
                              ? true
                              : false
                          }
                          helperText={
                            errors.new_password && touched.new_password
                              ? errors.new_password
                              : null
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {toggle_password ? (
                                  <Visibility
                                    onClick={this.togglePasswordHide}
                                  />
                                ) : (
                                  <VisibilityOff
                                    onClick={this.togglePasswordHide}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="confirm_password"
                          label="Confirm password"
                          type={toggle_password ? "text" : "password"}
                          id="confirm_password"
                          size="small"
                          autoComplete="current-confirm_password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirm_password}
                          error={
                            touched.confirm_password && errors.confirm_password
                              ? true
                              : false
                          }
                          helperText={
                            errors.confirm_password && touched.confirm_password
                              ? errors.confirm_password
                              : null
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {toggle_password ? (
                                  <Visibility
                                    onClick={this.togglePasswordHide}
                                  />
                                ) : (
                                  <VisibilityOff
                                    onClick={this.togglePasswordHide}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />

                        {loading ? (
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid>
                              <CircularProgress />
                            </Grid>
                          </Grid>
                        ) : (
                          <div>
                            <Button
                              type="submit"
                              fullWidth
                              color="primary"
                              variant="contained"
                              sx={{ mt: 2, mb: 2 }}
                              disabled={!(isValid && dirty)}
                            >
                              Change Password
                            </Button>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  username: state.user.username,
  email: state.user.email,
});

export default connect(mapStateToProps, null)(CreateNewPassword);
