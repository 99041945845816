import React, { Component, Fragment } from "react";
import * as OrderServiceAPI from "../../../services/order/OrderServiceAPI";
import * as PaymentServiceAPI from "../../../services/payment/PaymentServiceAPI";
import DataTableComponet from "react-data-table-component";
import Pagination from "../../../shared/Pagination/Pagination";
import Breadcrumb from "./Breadcrumb";
import "../../../index.css";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Loader from "../../../shared/Loader";
import $ from "jquery";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DownloadingIcon from "@mui/icons-material/Downloading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import ReactGA from "react-ga";
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import {getCurrencySymbol} from "../../../config";


class Content extends Component {
  table_columns = [
    {
      name: "TRANSACTION DATE",
      selector: "transaction_date",
      sortable: true,
      width: "250px",
    },

    {
      name: "ORDER #",
      selector: "order_number",
      sortable: true,
      cell: (row) => (
        <div
          style={{
            fontWeight: "bold",
          }} /* onClick={() => this.fetchOrderDetailsByOrderId(row)} */
        >
          {row.order_number}
        </div>
      ),
    },
    /*  */
    /*   {
              name: "ORDER STATUS",
              selector: "status",
              sortable: true,
              cell: (row) => (
                  <div>
                      <div onClick={() => this.fetchOrderDetailsByOrderId(row)} >
                          {row.status}
                      </div>
                  </div>
              ),
          }, */
    /*      {
                 name: "TRANSACTION ID",
                 selector: "transaction_id",
                 sortable: true,
                 cell: (row) => (
                     <div>
                         <div  onClick={() => this.fetchOrderDetailsByOrderId(row)} >
                             {row.transaction_id}
                         </div>
                     </div>
                 ),
             }, */
    {
      name: "PSP REFRENCE",
      selector: "psp_reference",
      sortable: false,
      cell: (row) => (
        <div>
          <div /* onClick={() => this.fetchOrderDetailsByOrderId(row)} */>
            {row.psp_reference}
          </div>
        </div>
      ),
    },

    /*  {
             name: "SCHEDULE PICKUP TIME",
             selector: "handover_time",
             sortable: true,
             cell: (row) => (
                 <div>
                     <div  onClick={() => this.fetchOrderDetailsByOrderId(row)} >
                         {row.handover_time}
                     </div>
                 </div>
             ),
         }, */

    {
      name: "GRAND TOTAL",
      selector: "grand_total",
      sortable: true,
      cell: (row) => (
        <div
          className="" /* onClick={() => this.fetchOrderDetailsByOrderId(row)} */
        >
           {getCurrencySymbol(localStorage.getItem('currency_code'))} {row.grand_total ? row.grand_total?.toFixed(2) : Number(0.0)}
        </div>
      ),
    },
    {
      name: "STATUS",
      selector: "status",
      width: "250px",
      cell: (row) => (
        <div className="type-margin">
          <div /* onClick={() => this.fetchOrderDetailsByOrderId(row)} */>
            {row.status === "COMPLETED" ? (
              <p
                style={{ width: "200px" }}
                className="badge badge-take-away badge-size"
              >
                {row.status}
              </p>
            ) : (
              ""
            )}
            {row.status === "AUTHORISED" ? (
              <p
                style={{ width: "200px" }}
                className="badge badge-pre-auth badge-size"
              >
                {row.status}
              </p>
            ) : (
              ""
            )}
               {row.status === "PENDING" ? (
              <p
                style={{ width: "200px" }}
                className="badge badge-pending badge-size"
              >
                {row.status}
              </p>
            ) : (
              ""
            )}
            {row.status === "INITIATED" ? (
              <p
                style={{ width: "200px", height: "25px", paddingTop: "6px" }}
                className="badge badge-buffet badge-size"
              >
                {row.status}
              </p>
            ) : (
              ""
            )}
            {row.status === "CANCELLED" ? (
              <p
                style={{ width: "200px", height: "25px", paddingTop: "6px" }}
                className="badge  badge-warning badge-size"
              >
                {row.status}
              </p>
            ) : (
              ""
            )}
            {row.status === "PARTIAL REFUND" ? (
              <p
                style={{ width: "200px", height: "25px", paddingTop: "6px" }}
                className="badge badge-info badge-size"
              >
                {row.status}
              </p>
            ) : (
              ""
            )}
            {row.status === "REFUND FAILED" ? (
              <p
                style={{ width: "200px", height: "25px", paddingTop: "6px" }}
                className="badge badge-danger badge-size"
              >
                {row.status}
              </p>
            ) : (
              ""
            )}
            {row.status === "FAILED" ? (
              <p
                style={{ width: "200px", height: "25px", paddingTop: "6px" }}
                className="badge badge-danger badge-size"
              >
                {row.status}
              </p>
            ) : (
              ""
            )}
            {row.status === "REFUND INTITIATED" ? (
              <p
                style={{ width: "200px", height: "25px", paddingTop: "6px" }}
                className="badge bg-warning badge-size"
              >
                {row.status}
              </p>
            ) : (
              ""
            )}
            {row.status === "REFUNDED" ? (
              <p
                style={{ width: "200px", height: "25px", paddingTop: "6px" }}
                className="badge bg-secondary badge-size"
              >
                {row.status}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      ),
    },
    {
      name: "ACTION",
      selector: "grand_total",
      cell: (row) => (
        <div
          className="" /* onClick={() => this.fetchOrderDetailsByOrderId(row)} */
        >
          {/* <IconButton
            color="primary"
            aria-label="view"
            onClick={() => this.fetchTransactionById(row)}
          >
            <VisibilityIcon />
          </IconButton> */}
          <Button variant="outlined" startIcon={<VisibilityIcon color="primary"/>} onClick={() => this.fetchTransactionById(row)} sx={{alignItems:"flex-start"}}>View</Button>
        </div>
      ),
    },
  ];

  customStyles = {
    rows: {
      style: {
        //       minHeight: '20px', // override the row height
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "55px",
        justifyContent: "center",

        textAlign: "center",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        //      paddingLeft: '16px', // override the cell padding for data cells
        //      paddingRight: 'auto',
      },
    },
    cells: {
      style: {
        paddingLeft: "2px", // override the cell padding for data cells
        paddingRight: "2px",
        pointer: "cursor",
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  state = {
    page: 0,
    data: [],
    current_page: 0,
    current_orders_page: 0,
    number_of_elements: 15,
    page_size: 10,
    total_elements: 0,
    total_pages: 0,
    total_order_pages: 0,
    property_id: "",
    search_text: "",
    changeBool: null,
    from_date: null,
    to_date: null,
    date_options_dd: null,
    isLoading: true,
    count: 0,
    hideFilter: false,
    columnName: "transaction_date",
    sortOrder: "DESC",
  };

  constructor(props) {
    super(props);
    // this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  fetchTransactionById = (row) => {
    const payload = {
      row: row,
    };
    this.props.history.push({
      pathname: "/transactiontdetails",
      state: payload,
    });
  };

  handleExportCSV = () => {
    if (this.state.zReport) {
      const fileExtension = ".csv";
      var link = document.createElement("a");
      link.href = this.state.zReport;
      //  link.download = "zReport";
      link.download = `transactions${fileExtension}`;
      link.click();
    } else {
      toast.warn("No report found.");
    }
  };

  downloadExcel = () => {
    this.setState({ isLoadingExcel: true });

    let payload = {
      from_date: dayjs(this.state.from_date).format("DD-MM-YYYY"),
      to_date: dayjs(this.state.to_date).format("DD-MM-YYYY"),
      property_id: this.props.propertyId,
      search_text: this.state.search_text || "",
    };

    PaymentServiceAPI.downloadTransactions(payload)
      .then((response) => {
        console.log(response);
        if (response) {
          console.log(response);
          const mimeType = "text/csv";
          const file = new Blob([response.data], { type: mimeType });
          console.log(response.request.getAllResponseHeaders());
          const fileURL = URL.createObjectURL(file); //Open the URL on new Window
          // window.open(fileURL);
          //    this.downloadFile(fileURL);

          this.setState({ isLoadingExcel: false, zReport: fileURL }, () => {
            this.handleExportCSV();
          });
        } else {
          toast.warn("Report not generated.");
          this.setState({
            isLoadingExcel: false,
            trendingItemsSalesReport: [],
            zReport: "",
          });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false })
        this.setState({ isLoadingExcel: false, zReport: "" });
        toast.error(error);
        console.log(error);
      });
  };

  componentDidMount = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    let prop_id = this.props.propertyId;
    if (prop_id) {
      this.interval = setInterval(() => window.location.reload(), 600000);
      let today = dayjs(new Date());
      let lastYear = dayjs(new Date()).subtract(1, "week");
      this.setState(
        { from_date: lastYear, to_date: today, property_id: prop_id },
        this.filterAllOrders
      );
    } else {
      this.setState({ isLoading: false });
    }

    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#reports").addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#reports").addClass("active");
    }

    // this.fetchAllOrders(payload);
  };

  filterAllOrders = () => {
    console.log(this.state);
    if (
      this.state.from_date != null &&
      this.state.to_date != null &&
      this.state.from_date != "" &&
      this.state.to_date != "" &&
      this.state.property_id
    ) {
      console.log("Filter");
      this.setState({ isLoading: true });
      const payload = {
        page: this.state.page,
        size: this.state.number_of_elements,
        from_date: dayjs(this.state.from_date).format("DD-MM-YYYY"),
        to_date: dayjs(this.state.to_date).format("DD-MM-YYYY"),

        property_id: this.state.property_id,
      };

      PaymentServiceAPI.filterAllTrancactionsByDate(payload)
        .then((response) => {
          //   console.log(response)
          if (response.data.status) {
            //       console.log(response)
            this.setState({
              data: response.data.data.transactionDetails,
              total_elements: response.data.data.total_elements,
              total_pages: response.data.data.total_pages,
              current_page: response.data.data.current_page + 1,
              page_size: response.data.data.page_size,
              isLoading: false,
              count: response.data.data.transactionDetails.length,
            });
            this.setState({ isLoading: false });
          } else {
            this.setState({
              data: [],
              total_elements: 0,
              total_pages: 0,
              current_page: 0,
              page_size: 0,
              isLoading: false,
              count: 0,
            });
            this.setState({ isLoading: false });
          }
        })
        .catch((err) => {
          toast.warning("Error : ", err);
          console.log("Error: ", err);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      //toast.warning("Please select appropriate date values!");
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  searchOrder = (event) => {
    event.preventDefault();
    const payload = {
      page: 0,
      size: this.state.number_of_elements,
      search_text: event.target.value,
    };
    this.fetchAllOrders(payload);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ number_of_elements: parseInt(event.target.value, 10) });
  };

  handlePagination = (e) => {
    console.log(e?.target?.value);
    this.setState(
      { number_of_elements: parseInt(e.target.value) },
      this.filterAllOrders
    );
  };

  fetchAllOrders = (payload) => {
    PaymentServiceAPI.filterAllTrancactionsByDate(payload).then((response) => {
      if (response.data.status) {
        if (response.data.data.transactionDetails) {
          this.setState({
            data: response.data.data.transactionDetails,
            total_elements: response.data.data.total_elements,
            total_order_pages: response.data.data.total_pages,
            current_orders_page: response.data.data.current_page + 1,
            page_size: response.data.data.page_size,
            isLoading: false,
            count: response.data.data.transactionDetails.length,
          });
        }
      } else {
        this.setState({
          isLoading: false,
          count: 0,
        });
      }
    });
  };

  filterOrders = (event) => {
    event.preventDefault();

    const payload = {
      search_text: event.target.value,
      page: 0,
      size: 15,
      property_id: this.state.property_id,
    };

    PaymentServiceAPI.filterAllTrancactionsByDate(payload).then((response) => {
      console.log("response : ", response);
      if (response.data.data && response.data.status) {
        this.setState({
          data: response.data.data.transactionDetails,
          total_elements: response.data.data.total_elements,
          total_pages: response.data.data.total_pages,
          current_page: response.data.data.current_page,
          page_size: response.data.data.page_size,
          count: response.data.data.transactionDetails.length,
        });
      } else {
        console.log("some error occured...");
      }
    });
  };

  filterOrdersSearch = (event) => {
    event.preventDefault();

    const payload = {
      search_text: event.target.value,
      page: 0,
      size: 15,
      property_id: this.state.property_id,
    };

    PaymentServiceAPI.filterAllTrancactionsBySearch(payload).then(
      (response) => {
        console.log("response : ", response);
        if (response.data.data && response.data.status) {
          this.setState({
            data: response.data.data.transactionDetails,
            total_elements: response.data.data.total_elements,
            total_pages: response.data.data.total_pages,
            current_page: response.data.data.current_page,
            page_size: response.data.data.page_size,
            count: response.data.data.transactionDetails.length,
          });
        } else {
          console.log("some error occured...");
        }
      }
    );
  };

  /*     fetchOrderDetailsByOrderId = (order) => {
            this.props.history.push({
                pathname: "/orderdetails",
                state: {
                    order: order,
                },
            });
        };
     */
  ordersHandleChange = (event, value) => {
    event.preventDefault();
    const page = value - 1;
    this.setState({ current_page: value });
    const payload = {
      page: page,
      size: this.state.number_of_elements,
      property_id: this.state.property_id,
      from_date: this.state.from_date
        ? dayjs(this.state.from_date).format("DD-MM-YYYY")
        : "",
      to_date: this.state.to_date
        ? dayjs(this.state.to_date).format("DD-MM-YYYY")
        : "",
      sort_value: this.state.columnName,
      sort: this.state.sortOrder?.toUpperCase(),
    };

    if (this.state.search_text) {
      console.log("Filter Serch");
      payload = {
        page: page,
        size: 15,
        property_id: this.state.property_id,
        from_date: this.state.from_date
          ? dayjs(this.state.from_date).format("DD-MM-YYYY")
          : "",
        to_date: this.state.to_date
          ? dayjs(this.state.to_date).format("DD-MM-YYYY")
          : "",
        search_text: this.state.search_text ? this.state.search_text : "",
      };

      this.filterOrdersSearch(payload);
    } else {
      console.log("Filter");
      this.fetchAllOrders(payload);
    }
  };

  /*     onChangeHandler(event) {
            const ctrl_name = event.target?.name;
            console.log(event)
            if (ctrl_name === "from_date") {
                this.setState({ changeBool: 1, from_date: event.target.value }, this.fnCallback);
            } else if (ctrl_name === "to_date") {
                this.setState({ changeBool: 2, to_date: event.target.value }, this.fnCallback);
            } else if (ctrl_name === "date_options_dd") {
                let dd_val = event.target.value;
                if (dd_val === "1") {
                    this.setState({ changeBool: 3 }, this.fnCallback);
                } else if (dd_val === "2") {
                    this.setState({ changeBool: 4 }, this.fnCallback);
                } else if (dd_val === "3") {
                    this.setState({ changeBool: 5 }, this.fnCallback);
                } else if (dd_val === "4") {
                    this.setState({ changeBool: 6 }, this.fnCallback);
                }
            }
        } */

  handleToDate = (e) => {
    //       const ctrl_name = e.target?.name;
    console.log(e);
    console.log(dayjs(e).format("DD-MM-YYYY"));
    let toDat = dayjs(e);
    this.setState({ to_date: toDat }, 
      //this.fnCallback
    );
  };

  handleFromDate = (e) => {
    //    const ctrl_name = e.target?.name;
    console.log(e);
    console.log(dayjs(e));
    let toDat = dayjs(e);
    this.setState({ from_date: toDat }, 
      //this.fnCallback
    );
  };

  reformatDate = (dateStr) => {
    let dArr = dateStr.split("-"); // ex input "2010-01-18"
    return dArr[2] + "-" + dArr[1] + "-" + dArr[0]; //ex out: "18/01/10"
  };

  fnCallback = (e) => {
    console.log(this.state);
    console.log(e?.target?.value);
    this.setState({ hideFilter: false });
    if (!e?.target?.value) {
      this.filterAllOrders();
    } else if (e?.target?.value == 1) {
      // Today
      var today = dayjs(new Date());
      this.setState({ from_date: today, to_date: today }, 
       // this.filterAllOrders
      );
    } else if (e?.target?.value == 2) {
      // Yesterday
      // var today = dayjs(new Date()).subtract(1, 'day');
      var yesterday = dayjs(new Date()).subtract(1, "day");
      console.log(yesterday);
      this.setState(
        { from_date: yesterday, to_date: yesterday },
        //this.filterAllOrders
      );
      console.log(this.state);
    } else if (e?.target?.value == 3) {
      // Last 7 Days
      var today = dayjs(new Date()).subtract(1, "week").startOf("week");
      var last7 = dayjs(new Date()).subtract(1, "week").endOf("week");
      this.setState({ from_date: today, to_date: last7 }, 
        //this.filterAllOrders
      );
    } else if (e?.target?.value == 4) {
      // Last 30 Days
      var today = dayjs(new Date()).subtract(1, "month").startOf("month");
      var last30 = dayjs(new Date()).subtract(1, "month").endOf("month");
      this.setState(
        { from_date: today, to_date: last30 },
       // this.filterAllOrders
      );
    } else if (e?.target?.value == 6) {
      // Last 30 Days
      var today = dayjs(new Date());
      var last30 = dayjs(new Date()).subtract(1, "year");
      this.setState(
        { from_date: last30, to_date: today },
        //this.filterAllOrders
      );
    } else if (e?.target?.value == 7) {
      // Last 30 Day
      this.setState({ hideFilter: true });
    } else if (e?.target?.value == 5) {
      // Last 30 Days]
      console.log("Thismonth");
      var today = dayjs(new Date());
      var firstDayOfMonth = dayjs(new Date()).startOf("month");
      this.setState(
        { from_date: firstDayOfMonth, to_date: today },
        //this.filterAllOrders
      );
    }
  };

  sortByColumn = (column, sortDirection) => {
    console.log(`Column Name: ${column.selector}`);
    console.log(`Sort Order: ${sortDirection}`);
    this.setState({
      columnName: column.selector,
      sortOrder: sortDirection,
      current_page: 0,
    });
    const payload = {
      page: 0,
      size: this.state.number_of_elements,
      sort: sortDirection?.toUpperCase(),
      sort_value: column?.selector,
      property_id: this.state.property_id,
      from_date: dayjs(this.state.from_date).format("DD-MM-YYYY"),
      to_date: dayjs(this.state.to_date).format("DD-MM-YYYY"),
    };
    this.fetchAllOrders(payload);
  };

  render() {
    return (
      <Fragment>
        <Breadcrumb />
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="ms-panel">
                <div className="ms-panel-body">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                      <div className="mt-4">
                        <FormControl fullWidth>
                          <Select
                            style={{ height: "54px", width: "100%" }}
                            name="date_options_dd"
                            onChange={this.fnCallback}
                            defaultValue="0"
                          >
                            <MenuItem value="0">- - Filter By - -</MenuItem>
                            <MenuItem value="1">Today</MenuItem>
                            <MenuItem value="2">Yesterday</MenuItem>
                            <MenuItem value="3">Last Week</MenuItem>
                            <MenuItem value="4">Last Month</MenuItem>
                            <MenuItem value="5">This month</MenuItem>
                            <MenuItem value="6">All time</MenuItem>
                            {/* <MenuItem value="7">Custom</MenuItem> */}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <div className="mt-4">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <MobileDatePicker
                              label="From"
                              value={this.state.from_date || new Date()}
                              inputFormat="DD-MM-YYYY"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <CalendarMonthIcon />
                                  </InputAdornment>
                                ),
                              }}
                              maxDate={new Date()}
                              onChange={this.handleFromDate}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <div className="mt-4">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <MobileDatePicker
                              label="To"
                              value={this.state.to_date || new Date()}
                              inputFormat="DD-MM-YYYY"
                              onChange={this.handleToDate}
                              maxDate={new Date()}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <CalendarMonthIcon />
                                  </InputAdornment>
                                ),
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <div className="mt-4">
                        <TextField
                          label="Search Transactions"
                          onChange={this.filterOrdersSearch}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div className="mt-4">No of Rows: {this.state.count}</div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Button type="submit" sx={{ mt: 2 }} variant="contained" onClick={this.filterAllOrders} color="primary">
                        GENERATE
                      </Button>
                    </Grid>            
                  </Grid>

                  <Grid
                      item
                      xs={12}
                      md={2}
                      style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                    >
                      <Button
                        className="mt-4"
                        variant="contained"
                        style={{ float: "right" }}
                        color="primary"
                        onClick={this.downloadExcel}
                        disabled={this.state.isLoadingExcel} // Disable the button when loading is true
                      >
                        {this.state.isLoadingExcel
                          ? "Downloading..."
                          : "Download CSV"}
                      </Button>
                    </Grid>
                  <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12">
                      {/* {
                                             (this.state.data.length > 0) ? 
                                                console.log('render data : ', this.state.data)
                                                :
                                                null
                                        } */}

                      {this.state.isLoading ? (
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginBottom: "40px",
                          }}
                        >
                          {" "}
                          <Loader size={50} />{" "}
                        </div>
                      ) : (
                        <React.Fragment>
                          <DataTableComponet
                            searchOrder={this.searchOrder}
                            state={this.state.data}
                            data={this.state.data}
                            columns={this.table_columns}
                            customStyles={this.customStyles}
                            is_expandable_row={false}
                            onSort={this.sortByColumn}
                            handleChangeRowsPerPage={
                              this.handleChangeRowsPerPage
                            }
                            handleChange={this.changeCurrentPage}
                            dense
                          />

                          <div
                            className="row"
                            style={{ float: "right", margin: "25px" }}
                          >
                            {/*  <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={this.state.number_of_elements}
                                                            onChange={this.handlePagination}
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                            label="Age"

                                                        >
                                                            <MenuItem value={10}>Ten</MenuItem>
                                                            <MenuItem value={20}>Twenty</MenuItem>
                                                            <MenuItem value={30}>Thirty</MenuItem>
                                                        </Select>
                                                    </FormControl> */}

                            {this.state.total_pages > 0 && (
                              <React.Fragment>
                                <span
                                  style={{
                                    position: "relative",
                                    right: "10px",
                                    top: "5px",
                                  }}
                                >
                                  {" "}
                                  Rows Per Page
                                </span>
                                <Grid container spacing={1}>
                                  {/* First Grid Item */}
                                  <Grid item xs={12} lg={4}>
                                    <FormControl>
                                      <NativeSelect
                                        defaultValue={
                                          this.state.number_of_elements
                                        }
                                        onChange={this.handlePagination}
                                        inputProps={{
                                          name: "Pagination",
                                          id: "uncontrolled-native",
                                        }}
                                      >
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={500}>500</option>
                                        <option value={1000}>1000</option>
                                      </NativeSelect>
                                    </FormControl>
                                  </Grid>
                                  <Pagination
                                    currentPage={this.state.current_page}
                                    total_pages={this.state.total_pages}
                                    state={this.state.data}
                                    sibCount={0}
                                    handleChange={this.ordersHandleChange}
                                  />
                                </Grid>
                              </React.Fragment>
                            )}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default connect(mapStateToProps, {})(Content);
