import React, { Component } from "react";
import { toast } from "react-toastify";
import * as PaymentServiceAPI from "../../../services/payment/PaymentServiceAPI";
import Breadcrumb from "./Breadcrumb";
import { Fragment } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Grid, Typography, Link, Paper, Stack, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { withStyles } from '@mui/styles';
import { CheckCircleOutline } from '@mui/icons-material';
import * as APIUrl from '../../../services/APIUrl';
import { withRouter } from 'react-router-dom';
import noGrants from '../../../assets/img/Approved.png';
import adyen from '../../../assets/img/adyen.png';
import $ from "jquery";
const styles = (theme) => ({
  root: {
    //  height: '100vh',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {

    //  maxWidth: 800,
    padding: theme.spacing(2),
    //  textAlign: 'left',
  },
});


class index extends Component {


  state = {
    isLoading: false,
    checked: ['wifi'],
    activeGrant: null
  };

  componentDidMount = () => {
    function setActiveMenuItem() {

      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }

    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }
   

    const activeGrant = this.props?.history?.location?.state?.activeGrant;
    this.setState({activeGrant: activeGrant});

  };





  fetchAccountDetails = async (payload) => {

    let url = process.env.REACT_APP_API_BASE_URL + APIUrl.FETCH_TERMS_CONDITIONS + `${payload}`;


    try {
      const response = await fetch(url); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log(result)
      if (result?.status) {
        console.log(result.data)
        this.setState({ terms: result?.data });
      }


    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };






  render() {
    const { classes, } = this.props;
    const { terms } = this.state;
    return (
      <Fragment>
        <div className={classes.root}>
         
            <Grid container className={classes.contentContainer}>
              {/* <Typography variant="h6" gutterBottom>
                <b> Terms and Conditions for Capital</b>
              </Typography> */}

              <Stack
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center', // Center items horizontally
                  justifyContent: 'center', // Center items vertically
                  pt: 4
                }}
                spacing={2}
              >
                {/*    <Alert severity="warning">No grants found.</Alert> */}
                <Typography variant="h5">
                  <b> You have successfully received funding. </b>
                </Typography>
                <p style={{ color: "black", fontSize: "16px" }}> Your current balance will be updated on the overview. </p>
                <img src={noGrants} style={{ width: '27vw' }} />

                <Button variant="contained" onClick={() => {
              this.props.history.push({
                pathname: "/grant-details",
                state: {
                  activeGrant: this.state.activeGrant
                }
              });
            }}>
              Back to Overview
            </Button>
              </Stack>

           

            </Grid>
            <Typography variant="body2" style={{ position: "fixed", bottom: "10px", right: "10px", display: "flex", alignItems: "center", fontFamily: "Roboto", fontSize: "16px", fontWeight: "bold", color: "#333" }}>
              Powered by <img style={{ width: "100px", marginLeft: "5px" }} src={adyen} alt="Adyen Logo" />
            </Typography>
      
        </div>
      </Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(index));