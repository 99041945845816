import React, { Component, Fragment } from "react";
import * as ReportServiceAPI from "../../../services/reports/ReportServiceAPI";
import Breadcrumb from "./Breadcrumb";
import "../../../index.css";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Loader from "../../../shared/Loader";
import $ from "jquery";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DownloadingIcon from "@mui/icons-material/Downloading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import { Column } from "@ant-design/plots";
import Typography from "@mui/material/Typography";
import { List, ListItem, ListItemText, Button } from "@mui/material";
import { ItemList } from "./ItemList/ItemList.js";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Papa from "papaparse";
import { Grid, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { connect } from "react-redux";
import Alert from "@mui/material/Alert";
import ReactGA from "react-ga";
import { tableCellClasses } from "@mui/material/TableCell";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

class Content extends Component {
  state = {
    page: 0,
    order_details: [],
    current_page: 0,
    current_orders_page: 0,
    number_of_elements: 15,
    page_size: 10,
    total_elements: 0,
    total_pages: 0,
    total_order_pages: 0,
    property_id: "",
    search_text: "",
    changeBool: null,
    from_date: null,
    to_date: null,
    date_options_dd: null,
    isLoading: false,
    isPdfLoading: false,
    count: 0,
    hideFilter: false,
    start: dayjs(new Date()).startOf("week"),
    end: dayjs(new Date()),
    data: null,
    topSellingItems: [],
    trendingItemsSalesReport: [],
    unSoldItems: [],
    currentPage: 1,
    itemsPerPage: 10,
    tab: 0,
    selectTimePeriod: "DAILY",
    selectReport: "Item",
    max: new Date(),
    min: dayjs(new Date()).subtract(3, "year"),
    zReport: "",
    filterBy: 0,
    propertyId: "",
    filter: 0,
  };

  constructor(props) {
    super(props);
    // this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setState({ propertyId: this.props.propertyId });

    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#reports").addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#reports").addClass("active");
    }
  };

  handleExportCSV = () => {
    if (this.state.zReport) {
      var link = document.createElement("a");
      link.href = this.state.zReport;
      link.download = "SummaryReport";
      link.click();
    } else {
      toast.warn("No report found.");
    }
  };

  fnCallback = (e) => {
    console.log(this.state);
    console.log(e?.target?.value);
    this.setState({ hideFilter: false, filter: e?.target?.value });
    if (!e?.target?.value) {
      this.filterAllOrders();
    } else if (e?.target?.value == 1) {
      // Today
      let today = dayjs(new Date());

      this.setState({ start: today, end: today }, () => {});
    } else if (e?.target?.value == 2) {
      // Yesterday
      // var today = dayjs(new Date()).subtract(1, 'day');
      let yesterday = dayjs(new Date()).subtract(1, "day");

      console.log(yesterday);
      this.setState({ start: yesterday, end: yesterday }, () => {});
    } else if (e?.target?.value == 3) {
      // Last 7 Days
      let today = dayjs(new Date()).subtract(1, "week").startOf("week");
      let last7 = dayjs(new Date()).subtract(1, "week").endOf("week");
      this.setState({ start: today, end: last7 }, () => {});
    } else if (e?.target?.value == 4) {
      // Last 30 Days
      let today = dayjs(new Date()).startOf("week");
      let last30 = dayjs(new Date());
      this.setState({ start: today, end: last30 }, () => {});
    } else if (e?.target?.value == 9) {
      // Last 30 Days
      this.setState({ filterBy: 9, end: this.state.start }, () => {});
    } else if (e?.target?.value == 5) {
      // Last 30 Days
      let today = dayjs(new Date());
      let last30 = dayjs(new Date()).subtract(1, "year").startOf("year");
      if (this.state.selectTimePeriod == "HOURLY") {
        this.setState(
          { start: dayjs(new Date()).startOf("week"), end: dayjs(new Date()) },
          () => {}
        );
      } else {
        this.setState({ start: last30, end: today }, () => {
          this.getTrendingSalesReport();
        });
      }
    } else if (e?.target?.value == 6) {
      // Last 30 Days
      let today = dayjs(new Date());
      let firstDayOfMonth = dayjs(new Date()).startOf("month");

      if (this.state.selectTimePeriod == "HOURLY") {
        this.setState(
          { start: dayjs(new Date()).startOf("week"), end: dayjs(new Date()) },
          () => {}
        );
      } else {
        this.setState({ start: firstDayOfMonth, end: today }, () => {});
      }
    } else if (e?.target?.value == 7) {
      // Last 30 Day
      this.setState({ hideFilter: true });
    }
  };

  fetchSummaryReport = () => {
    this.setState({ isLoading: true });

    let payload = {
      start_date: this.state.start.format("DD-MM-YYYY"),
      end_date: this.state.end.format("DD-MM-YYYY"),
      property_id: this.props.propertyId,
    };

    ReportServiceAPI.fetchSummaryReport(payload)
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          //     console.log(response.data.data)
          this.setState({ isLoading: false, data: response.data.data });
        } else {
          toast.warn("No data retrieved.");
          this.setState({ isLoading: false, data: null });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        this.setState({ isLoading: false, data: null });
        console.log(error);
        toast.error(error);
      });
  };

  downloadSummaryReport = () => {
    this.setState({ isPdfLoading: true });

    let payload = {
      start_date: this.state.start.format("DD-MM-YYYY"),
      end_date: this.state.end.format("DD-MM-YYYY"),
      property_id: this.props.propertyId,
    };

    ReportServiceAPI.downloadSummaryReport(payload)
      .then((response) => {
        console.log(response);
        if (response) {
          console.log(response);
          const file = new Blob([response.data], { type: "application/pdf" });
          console.log(response.request.getAllResponseHeaders());
          const fileURL = URL.createObjectURL(file); //Open the URL on new Window
          // window.open(fileURL);
          //    this.downloadFile(fileURL);

          this.setState({ isPdfLoading: false, zReport: fileURL }, () => {
            this.handleExportCSV();
          });
        } else {
          toast.warn("Report not generated.");
          this.setState({
            isPdfLoading: false,
            trendingItemsSalesReport: [],
            zReport: "",
          });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false })
        this.setState({ isPdfLoading: false, zReport: "" });
        toast.error(error);
        console.log(error);
      });
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tab: newValue });
  };

  handleToDate = (e) => {
    //       const ctrl_name = e.target?.name;
    console.log(e);
    console.log(dayjs(e).format("DD-MM-YYYY"));
    let toDat = dayjs(e);
    this.setState(
      { end: toDat /* start: dayjs(toDat).startOf('week') */ },
      () => {}
    );
  };

  handleFromDate = (e) => {
    //    const ctrl_name = e.target?.name;
    console.log(e);
    console.log(dayjs(e));
    let today = dayjs(e);
    const endOfWeek = dayjs(today).add(7, "day");
    const result = endOfWeek.isAfter(new Date())
      ? dayjs(new Date())
      : endOfWeek;

    if (this.state.filter == 9) {
      this.setState({ /* end: today, */ start: today, filter: 9 }, () => {});
    } else {
      this.setState({ end: result, start: today, filter: 0 }, () => {});
    }
  };

  render() {
    const tableStyle = {
      backgroundColor: "#5773ff",
      color: "white",
    };

    const titleStyle = {
      borderBottom: "5px solid #5773ff", // Adjust the line style as needed
      paddingBottom: "8px", // Add some padding to create space between the line and text
    };

    const titleStyleHeader = {
      borderBottom: "5px solid #5773ff", // Adjust the line style as needed
      paddingBottom: "8px", // Add some padding to create space between the line and text
    };

    const { tab, data, propertyId } = this.state;

    return (
      <Fragment>
        <Breadcrumb />
        {propertyId ? (
          <React.Fragment>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <AppBar position="static" color="default">
                    <Tabs
                      value={tab}
                      onChange={this.handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      TabIndicatorProps={{ style: { background: "#5773ff" } }}
                    >
                      <Tab label="Summary Report" />
                      {/* <Tab label="Unsold Items" /> */}
                    </Tabs>
                    <TabPanel value={tab} index={0} className="tabPanel">
                      <div className="ms-panel">
                        <div className="ms-panel-body">
                          <div className="row">
                            <div className="col-xl-12 col-md-12 col-sm-12">
                              <div className="row m-2">
                                <div className="col-md-2">
                                  <div className="mt-4">
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        Filter By
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label" /* style={{ height: 40 }} */
                                        id="demo-simple-select"
                                        value={this.state.filter}
                                        label="Filter By"
                                        onChange={this.fnCallback}
                                      >
                                        <MenuItem value={0}>
                                          {" "}
                                          Filter By
                                        </MenuItem>
                                        <MenuItem value={1}>Today</MenuItem>
                                        <MenuItem value={2}>Yesterday</MenuItem>
                                        <MenuItem value={4}>This Week</MenuItem>
                                        <MenuItem value={3}>Last Week</MenuItem>
                                        {/*   <MenuItem value={9}>Any Single Day</MenuItem> */}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>

                                {/*     <div className="col-md-2">
                                                            <div className="mt-4">

                                                                <FormControl fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">Select Report</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={this.state.selectReport}
                                                                        label="Select Report"
                                                                        onChange={this.selectSalesByTypeItemReport}
                                                                    >
                                                                        <MenuItem value="Item">By Item</MenuItem>
                                                                        <MenuItem value={"Date"}>By Date</MenuItem>



                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
 */}

                                {/* {this.state.hideFilter && <React.Fragment> */}

                                <div className="col-md-2">
                                  <div className="mt-4">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Stack spacing={3}>
                                        <MobileDatePicker
                                          label="From"
                                          value={this.state.start || new Date()}
                                          inputFormat="DD-MM-YYYY"
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="Date">
                                                <CalendarMonthIcon />
                                              </InputAdornment>
                                            ),
                                          }}
                                          maxDate={this.state.max}
                                          // minDate={this.state.min}
                                          onChange={this.handleFromDate}
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                        />
                                      </Stack>
                                    </LocalizationProvider>
                                  </div>
                                </div>

                                <div className="col-md-2">
                                  <div className="mt-4">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Stack spacing={3}>
                                        <MobileDatePicker
                                          label="To"
                                          value={this.state.end || new Date()}
                                          inputFormat="DD-MM-YYYY"
                                          onChange={this.handleToDate}
                                          maxDate={this.state.max}
                                          minDate={this.state.start}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="start">
                                                <CalendarMonthIcon />
                                              </InputAdornment>
                                            ),
                                          }}
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                        />
                                      </Stack>
                                    </LocalizationProvider>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="mt-3">
                                    <Button
                                      type="submit"
                                      sx={{ mt: 2 }}
                                      variant="contained"
                                      onClick={this.fetchSummaryReport}
                                      color="primary"
                                    >
                                      GENERATE
                                    </Button>
                                  </div>
                                </div>

                                {/*            </React.Fragment>
                                                        } */}
                              </div>

                              <div
                                className=""
                                style={{ marginTop: "100px !important" }}
                              >
                                {this.state.isLoading ? (
                                  <div
                                    style={{
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      marginBottom: "40px",
                                    }}
                                  >
                                    {" "}
                                    <Loader size={50} />{" "}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    {/* 
                                                                <div className="col-md-6">
                                                                    <h4>Total Item Sales</h4>
                                                                    <Line {...totalItemsSalesReportConfig} />

                                                                </div>

                                                                <div className="col-md-6">
                                                                    <h4>Top Selling Items</h4>
                                                                    <Column {...config} />

                                                                </div>

                                                                <div className="col-md-6" style={{ marginTop: "50px" }}>
                                                                    <h4>Trending </h4>

                                                                    <Line {...trendingReportConfig} />

                                                                </div> */}
                                    {/* 
                                                                <div className="col-md-6">
                                                                    <Column {...config} />

                                                                </div> */}

                                    <br></br>
                                    {this.state.data && (
                                      <React.Fragment>
                                        {this.state.isPdfLoading ? (
                                          <div
                                            style={{
                                              marginLeft: "auto",
                                              marginRight: "auto",
                                              marginBottom: "40px",
                                            }}
                                          >
                                            {" "}
                                            <Loader size={50} />{" "}
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <Button
                                              variant="contained"
                                              style={{ float: "right" }}
                                              color="primary"
                                              onClick={
                                                this.downloadSummaryReport
                                              }
                                            >
                                              Download Report
                                            </Button>
                                          </div>
                                        )}
                                        <br></br>

                                        <Paper
                                          elevation={3}
                                          style={{
                                            padding: "20px",
                                            margin: "0px",
                                          }}
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item xs={12} lg={6}>
                                              <Typography
                                                variant="body1"
                                                style={titleStyle}
                                              >
                                                Summary Report
                                              </Typography>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={12}
                                              lg={6}
                                              style={{ textAlign: "right" }}
                                            >
                                              <Typography
                                                variant="body1"
                                                style={{
                                                  float: "right",
                                                  ...titleStyle,
                                                }}
                                              >
                                                {" "}
                                                {data?.generatedDate}
                                              </Typography>
                                            </Grid>

                                            <Grid
                                              xs={12}
                                              item
                                              lg={12}
                                              style={{ textAlign: "left" }}
                                            >
                                              <Typography
                                                variant="h5"
                                                style={titleStyleHeader}
                                              >
                                                {" "}
                                                {data?.businessName}
                                              </Typography>
                                              {/* <Typography variant="h5"> {data?.businessAdress}</Typography> */}
                                            </Grid>

                                            <Grid item lg={4}></Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Divider />
                                            </Grid>
                                            <Typography
                                              variant="h6"
                                              sx={{
                                                textAlign: "left",
                                                padding: "20px",
                                              }}
                                            >
                                              Category Report
                                            </Typography>
                                            <TableContainer component={Paper}>
                                              <Table
                                                aria-label="simple table"
                                                size="small"
                                                dense
                                              >
                                                <TableHead
                                                  className="white-text"
                                                  style={tableStyle}
                                                >
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      Category
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      Quantity
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      Sales (ex) {/* &#163; */}{" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      Sales (inc) {/* &#163; */}{" "}
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {(data?.categories || []).map(
                                                    (item, index) => (
                                                      <TableRow>
                                                        <TableCell align="center">
                                                          {item?.category}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          {item?.quantity}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          {item?.sales}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          {item?.salesTax}
                                                        </TableCell>
                                                      </TableRow>
                                                    )
                                                  )}
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      <b>
                                                        Total Category Sales
                                                      </b>
                                                    </TableCell>
                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      <b>
                                                        {" "}
                                                        {
                                                          data?.totalCategoryCount
                                                        }
                                                      </b>
                                                    </TableCell>
                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      <b>
                                                        {
                                                          data?.totalCategorySales
                                                        }
                                                      </b>
                                                    </TableCell>
                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      <b>
                                                        {" "}
                                                        {
                                                          data?.totalCategorySalesInc
                                                        }
                                                      </b>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                            <Typography
                                              variant="h6"
                                              sx={{
                                                textAlign: "left",
                                                padding: "20px",
                                              }}
                                            >
                                              Menu Report
                                            </Typography>
                                            <TableContainer component={Paper}>
                                              <Table
                                                aria-label="simple table"
                                                size="small"
                                                dense
                                              >
                                                <TableHead
                                                  className="white-text"
                                                  style={tableStyle}
                                                >
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      Menu
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      Quantity
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      Sales (ex) {/* &#163; */}{" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      Sales (inc) {/* &#163; */}{" "}
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {(data?.menus || []).map(
                                                    (item, index) => (
                                                      <TableRow>
                                                        <TableCell align="center">
                                                          {item?.menu}
                                                        </TableCell>
                                                        <TableCell
                                                          align="center"
                                                          style={{
                                                            minWidth: "150px",
                                                          }}
                                                        >
                                                          {item?.quantity}
                                                        </TableCell>
                                                        <TableCell
                                                          align="center"
                                                          style={{
                                                            minWidth: "150px",
                                                          }}
                                                        >
                                                          {item?.sales}
                                                        </TableCell>
                                                        <TableCell
                                                          align="center"
                                                          style={{
                                                            minWidth: "150px",
                                                          }}
                                                        >
                                                          {item?.salesTax}
                                                        </TableCell>
                                                      </TableRow>
                                                    )
                                                  )}
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      <b> Total Menu Sales</b>
                                                    </TableCell>
                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      <b>
                                                        {" "}
                                                        {data?.totalMenuCount}
                                                      </b>
                                                    </TableCell>
                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      <b>
                                                        {" "}
                                                        {data?.totalMenuSales}
                                                      </b>
                                                    </TableCell>
                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      <b>
                                                        {" "}
                                                        {
                                                          data?.totalMenuSalesInc
                                                        }
                                                      </b>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>

                                            <TableContainer component={Paper}>
                                              <Table
                                                aria-label="simple table"
                                                size="small"
                                                dense
                                              >
                                                <TableHead
                                                  className="white-text"
                                                  style={tableStyle}
                                                >
                                                  <TableRow>
                                                    <TableCell
                                                      align="center"
                                                      colSpan={4}
                                                    >
                                                      Additional Charges
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {(data?.surPlus || []).map(
                                                    (item, index) => (
                                                      <TableRow>
                                                        <TableCell align="center">
                                                          {item?.name}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          {item?.count}
                                                        </TableCell>
                                                        <TableCell
                                                          align="center"
                                                          style={{
                                                            minWidth: "150px",
                                                          }}
                                                        >
                                                          {item?.amount}
                                                        </TableCell>
                                                      </TableRow>
                                                    )
                                                  )}
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      Total Tip Amount
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>

                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      {data?.totalTipAmount}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      Total Service Charges
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>

                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      {data?.totalServiceTax}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      Total Delivery Charges
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>

                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      {
                                                        data?.totalDeliveryCharge
                                                      }
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      <b>Total Charges</b>
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>

                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      <b>
                                                        {data?.totalSurPlus}
                                                      </b>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>

                                            <TableContainer component={Paper}>
                                              <Table
                                                aria-label="simple table"
                                                size="small"
                                                dense
                                              >
                                                <TableHead
                                                  className="white-text"
                                                  style={tableStyle}
                                                >
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      Discount
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      Count
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center">
                                                      Total Amount
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {(data?.discounts || []).map(
                                                    (item, index) => (
                                                      <TableRow>
                                                        <TableCell align="center">
                                                          {item?.name}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          {item?.count}
                                                        </TableCell>
                                                        <TableCell align="center"></TableCell>
                                                        <TableCell
                                                          align="center"
                                                          style={{
                                                            minWidth: "150px",
                                                          }}
                                                        >
                                                          {item?.amount}
                                                        </TableCell>
                                                      </TableRow>
                                                    )
                                                  )}
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      <b> Total discount</b>
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      <b>
                                                        {" "}
                                                        {data?.totalDiscount}
                                                      </b>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                            <TableContainer component={Paper}>
                                              <Table
                                                aria-label="simple table"
                                                size="small"
                                                dense
                                              >
                                                <TableHead
                                                  className="white-text"
                                                  style={tableStyle}
                                                >
                                                  <TableRow
                                                    style={{ height: "40px" }}
                                                  >
                                                    <TableCell
                                                      align="center"
                                                      colSpan={4}
                                                    ></TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  <TableRow>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center">
                                                      Sub Total
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>

                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      {data?.netSale}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center">
                                                      Total discount
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>

                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      - {data?.totalDiscount}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center">
                                                      Tax
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>

                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      {data?.tax}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center">
                                                      Gross Sales
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>

                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      {data?.grossSale}
                                                    </TableCell>
                                                  </TableRow>

                                                  <TableRow>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center">
                                                      Refund
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>

                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      -{" "}
                                                      {data?.totalRefundAmount}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center">
                                                      <b>Total</b>
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>

                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      <b>
                                                        {" "}
                                                        {data?.netGrossSale}
                                                      </b>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>

                                            <TableContainer component={Paper}>
                                              <Table
                                                className="white-text"
                                                aria-label="simple table"
                                                size="small"
                                                dense
                                              >
                                                <TableHead style={tableStyle}>
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      Payment Type
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Amount{" "}
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      CASH
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {data?.cashPayment}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      CARD
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {data?.cardPayment}
                                                    </TableCell>
                                                  </TableRow>
                                                  {data?.dogDollarPayment && (
                                                    <TableRow>
                                                      <TableCell align="center">
                                                        Dog Dollar
                                                      </TableCell>
                                                      <TableCell align="center"></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "150px",
                                                        }}
                                                        align="center"
                                                      >
                                                        {data?.dogDollarPayment}
                                                      </TableCell>
                                                    </TableRow>
                                                  )}
                                                  {data?.invoicePayment && (
                                                    <TableRow>
                                                      <TableCell align="center">
                                                        Invoice
                                                      </TableCell>
                                                      <TableCell align="center"></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "150px",
                                                        }}
                                                        align="center"
                                                      >
                                                        {data?.invoicePayment}
                                                      </TableCell>
                                                    </TableRow>
                                                  )}
                                                  {data?.totalDeliverooPayment && (
                                                    <TableRow>
                                                      <TableCell align="center">
                                                        Deliveroo
                                                      </TableCell>
                                                      <TableCell align="center"></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "150px",
                                                        }}
                                                        align="center"
                                                      >
                                                        {
                                                          data?.totalDeliverooPayment
                                                        }
                                                      </TableCell>
                                                    </TableRow>
                                                  )}
                                                  {data?.totalJustEatsPayment && (
                                                    <TableRow>
                                                      <TableCell align="center">
                                                        Just Eats
                                                      </TableCell>
                                                      <TableCell align="center"></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "150px",
                                                        }}
                                                        align="center"
                                                      >
                                                        {
                                                          data?.totalJustEatsPayment
                                                        }
                                                      </TableCell>
                                                    </TableRow>
                                                  )}
                                                  {data?.totalUberEatsPayment && (
                                                    <TableRow>
                                                      <TableCell align="center">
                                                        Uber Eats
                                                      </TableCell>
                                                      <TableCell align="center"></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "150px",
                                                        }}
                                                        align="center"
                                                      >
                                                        {
                                                          data?.totalUberEatsPayment
                                                        }
                                                      </TableCell>
                                                    </TableRow>
                                                  )}
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      Refund
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      -{" "}
                                                      {data?.totalRefundAmount}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      <b> Total</b>
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      <b>
                                                        {" "}
                                                        {data?.totalPaymentSale}
                                                      </b>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Grid>
                                        </Paper>
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                      <div className="ms-panel">
                        <div className="ms-panel-body">
                          <div className="col-md-12">
                            <Typography variant="h5" gutterBottom style={{}}>
                              Inventory of Unsold Items
                            </Typography>
                            <ItemList items={this.state.unSoldItems} />
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </AppBar>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Alert severity="warning">
            To access this feature please complete your account setup.
          </Alert>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default connect(mapStateToProps, {})(Content);
