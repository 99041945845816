import React, { Component } from "react";
import { toast } from "react-toastify";
import * as DiscountServiceAPI from "../../services/discount/DiscountServiceAPI";
import Breadcrumb from "./Breadcrumb";
import SimpleModal from "../../shared/Modal/SimpleModal";
import { Fragment } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Card, CardContent, FormControl } from "@mui/material";
import * as schema from "../../utils/Schema";
import { Form, Formik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import AlarmIcon from "@mui/icons-material/Alarm";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import EditIcon from "@mui/icons-material/Edit";
import isEqual from "lodash/isEqual";
import InputAdornment from "@mui/material/InputAdornment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  TableSortLabel,
} from "@mui/material";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { QRCode } from "antd";
import DataTable from "react-data-table-component";
import SearchIcon from "@mui/icons-material/Search";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InfoIcon from "@mui/icons-material/Info";
import { connect } from "react-redux";
import Loader from "../../shared/Loader";
import Typography from "@mui/material/Typography";
import $ from "jquery";
import {getCurrencySymbol} from "../../config";


/* const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px', // Adjust the padding as needed
        },
      },
    },
  },
}); */
const styles = {
  table: {
    "& .MuiTableCell-root": {
      padding: "4px 8px", // Adjust the padding as needed
    },
  },
};
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const discountTypeOptions = [
  { value: "FIXED", label: "Fixed" },
  { value: "PERCENTAGE", label: "Percentage" },
];

const discountVoucherTypeOptions = [
  { value: "NORMAL DISCOUNT", label: "Normal" },
  /*   { value: "THRESHOLD DISCOUNT", label: "Threshold" },
    { value: "USAGE LIMITED DISCOUNT", label: "Usage Limited" }, */
];

class index extends Component {
  state = {
    isLoading: false,
    initialValues: {},
    isLoading: false,
    discounts: [],
    discount: null,
    selectedDiscount: "",
    selectedDiscountVoucher: "NORMAL DISCOUNT",
    page: 0,
    rowsPerPage: 10,
    sortColumn: "discount_code",
    sortDirection: "asc",
    open: false,
    dId: "",
    discount_code: "",
    discountList: [],
    showForm: false,
    showFormDialog: false,
    is_editable: true,
  };

  columns = [
    {
      name: "Discount Name",
      selector: "discount_name",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          <div>{row.discount_name}</div>
        </div>
      ),
    },

    {
      name: "Discount Code",
      selector: "discount_code",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          <div>{row.discount_code}</div>
        </div>
      ),
    },

    {
      name: "Discount Type",
      selector: "discount_type",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          <div>{row.discount_type}</div>
        </div>
      ),
    },

    {
      name: "Discount Value",
      selector: "discount_value",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          {" "}
          {row.discount_type != "PERCENTAGE"
            ?   getCurrencySymbol(localStorage.getItem('currency_code')) +  row.discount_value?.toFixed(2)
            : ""}{" "}
          {row.discount_type == "PERCENTAGE" ? row.discount_value + " %" : ""}
        </div>
      ),
    },

    {
      name: "Minimum Order Amount",
      selector: "minimum_order_amount",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div>  {row.minimum_order_amount?.toFixed(2)}</div>,
    },

    {
      name: "Pin",
      selector: "discount_pin",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {row.discount_pin}</div>,
    },

    /* 
    {
      name: "Voucher type",
      selector: "discount_voucher_type",
      sortable: true,
      justifyContent: 'center',
      cell: row => <div >   {row.discount_voucher_type}</div>
    }, */

    {
      name: "Start date",
      selector: "start_date",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {row.discount_start_date}</div>,
    },

    {
      name: "End date",
      selector: "end_date",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {row.discount_end_date}</div>,
    },

    {
      name: "Action",
      selector: "action",
      justifyContent: "center",
      width:"180px",
      cell: (row) => (
        // <div>
        //   <IconButton
        //     color="primary"
        //     onClick={() => this.editDiscount(row.discount_id)}
        //     aria-label="Edit"
        //   >
        //     <EditIcon />
        //   </IconButton>
        //   <IconButton
        //     color="primary"
        //     onClick={() => this.handleClickOpen(row.discount_id)}
        //     aria-label="delete"
        //     size="large"
        //   >
        //     <DeleteIcon fontSize="inherit" />
        //   </IconButton>
        // </div>
        <Stack direction="column" spacing={1} sx={{padding:"4px"}}>
        
        <Button variant="outlined" startIcon={ <EditIcon color="primary"/>} onClick={() => this.editDiscount(row.discount_id)}>Edit</Button>
      
        <Button variant="outlined" startIcon={ <DeleteIcon color="primary"/>} onClick={() => this.handleClickOpen(row.discount_id)}>Delete</Button>

      </Stack>
      ),
    },
  ];

  customStyles = {
    rows: {
      style: {
        //          minHeight: '70px', // override the row height
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "65px",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "auto",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        justifyContent: "center",
        "&:hover div": {
          backgroundColor: "transparent",
          color: "white !important",
        },
        div: {
          color: "white !important",
        },
        span: {
          color: "white !important",
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "2px", // override the cell padding for data cells
        paddingRight: "2px",
        pointer: "cursor",
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  componentDidMount = () => {
    this.setInitalValues();
    this.fetchAllDiscounts();
    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }

    let intervalId = setInterval(async () => {
      try {
        await this.fetchAllDiscounts();
      } catch (error) {
        console.error("An error occurred:", error);
        clearInterval(intervalId); // Stop the interval timer
      }
    }, 300000);
  };

  downloadQRCode = (id) => {
    const canvas = document.getElementById(id)?.querySelector("canvas");
    if (canvas) {
      // Get the current size of the QR code
      const qrCodeSize = canvas.width;

      // Create a new canvas with a larger size to accommodate the border
      const borderedCanvas = document.createElement("canvas");
      const borderedCtx = borderedCanvas.getContext("2d");

      // Set the size of the new canvas including the border
      borderedCanvas.width = qrCodeSize + 20; // 20 is the width of the white border
      borderedCanvas.height = qrCodeSize + 20; // 20 is the height of the white border

      // Draw a white border
      borderedCtx.fillStyle = "#ffffff"; // Set the color of the white border
      borderedCtx.fillRect(0, 0, borderedCanvas.width, borderedCanvas.height);

      // Draw the original QR code on top of the white border
      borderedCtx.drawImage(canvas, 10, 10, qrCodeSize, qrCodeSize); // 10 is the border width

      // Create a download link
      const url = borderedCanvas.toDataURL();
      const a = document.createElement("a");
      a.download = id + ".jpg";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  handleClickOpen = (disCountId) => {
    this.setState({ open: true, dId: disCountId, showFormDialog: true });
  };

  handleCancel = () => {
    this.setState(
      {
        discount: null,
        selectedDiscount: "",
        selectedDiscountVoucher: "",
        showForm: false,
      },
      () => {
        this.setInitalValues();
      }
    );
  };

  handleClose = () => {
    this.setState({ open: false, dId: "" });
  };

  handleDescription = () => {
  };

  setInitalValues = () => {
    const { discount } = this.state;

    let initialValues = {};

    initialValues = {
      discount_code: discount?.discount_code || "",
      discount_name: discount?.discount_name || "",
      discount_start_date: discount?.discount_start_date
        ?dayjs(discount.discount_start_date, "DD MMMM YYYY hh:mm A")
        : dayjs(new Date()),
      discount_end_date: discount?.discount_end_date
        ? dayjs(discount.discount_end_date, "DD MMMM YYYY hh:mm A")
        : dayjs(new Date()),
      discount_type: discount?.discount_type || "",
      discount_voucher_type: "NORMAL DISCOUNT",
      discount_value: discount?.discount_value || "",
      minimum_order_amount: discount?.minimum_order_amount || "",
      discount_pin: discount?.discount_pin || "",
    };

    this.setState({ initialValues });
  };

  setInitalDicountCode = (values) => {
    const { discount } = this.state;

    let initialValues = {};

    initialValues = {
      discount_code: this.state.discount_code || discount?.discount_code || "",
      discount_name: values?.discount_name || discount?.discount_name || "",
      discount_start_date: discount?.discount_start_date
        ? dayjs(discount.discount_start_date, "DD-MM-YYYY")
        : dayjs(new Date()),
      discount_end_date: discount?.discount_end_date
        ? dayjs(discount.discount_end_date, "DD-MM-YYYY")
        : dayjs(new Date()),
      discount_type: values?.discount_type || discount?.discount_type || "",
      discount_voucher_type: "NORMAL DISCOUNT",
      discount_value: values?.discount_value || discount?.discount_value || "",
      minimum_order_amount:
        values?.minimum_order_amount || discount?.minimum_order_amount || "",
      discount_pin: values?.discount_pin || discount?.discount_pin || "",
    };

    this.setState({ initialValues });
  };

  fetchAllDiscounts = async () => {
    try {
      this.setState({ isLoading: true });
      let propertId = this.props.propertyId;
      let props = { property_id: propertId, type: "BUSINESS" };
      const response = await DiscountServiceAPI.fetchAllEposDiscounts(props);

      if (response.data.status) {
        this.setState({
          discounts: response.data.data,
          discountList: response.data.data,
          isLoading: false,
        });
        // this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
        // toast.success("Discount added. ")
      } else {
        this.setState({ discounts: [], isLoading: false });
      }
    } catch (error) {
      this.setState({ discounts: [], isLoading: false });
      throw error;
    }
  };

  addDiscount = (values, resetForm) => {
    values["discount_start_date"] = dayjs(values["discount_start_date"]).format(
      "DD-MM-YYYY"
    );
    values["discount_end_date"] = dayjs(values["discount_end_date"]).format(
      "DD-MM-YYYY"
    );
    values["discount_code"] = values["discount_code"]?.toUpperCase();

    DiscountServiceAPI.addDiscount(values)
      .then((response) => {
        if (response.data.status) {
          //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
          toast.success("Epos / Mpos discount  added. ");
          resetForm({ values: "" });
          //      this.setState({ discount: null, selectedDiscount: null, selectedDiscountVoucher: null, discount_code: "" });
          this.setState(
            {
              discount: null,
              selectedDiscount: null,
              selectedDiscountVoucher: null,
              discount_code: "",
              showForm: false,
              isLoading: false,
            },
            () => {
              this.setInitalValues();
            }
          );
          this.fetchAllDiscounts();
        } else {
          let dis = { ...this.state.discount, ...values };
          this.setState(
            {
              discount: dis,
              isLoading: false,
            },
            () => {
              this.setInitalValues();
            }
          );
          this.setState({ discount: null });
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not add epos discount.. ");
      });
  };

  updateDiscount = (values, resetForm) => {
    values["discount_start_date"] = dayjs(values["discount_start_date"]).format(
      "DD-MM-YYYY"
    );
    values["discount_end_date"] = dayjs(values["discount_end_date"]).format(
      "DD-MM-YYYY"
    );
    values["discount_code"] = values["discount_code"]?.toUpperCase();

    DiscountServiceAPI.updateDiscount(values)
      .then((response) => {
        if (response.data.status) {
          //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
          toast.success("Epos / Mpos discount  updated. ");
          resetForm({ values: "" });
          this.setState(
            {
              discount: null,
              selectedDiscount: null,
              selectedDiscountVoucher: null,
              showForm: false,
              isLoading: false,
            },
            () => {
              this.setInitalValues();
            }
          );
          this.fetchAllDiscounts();
        } else {
          this.setState(
            {
              discount: null,
              selectedDiscount: null,
              selectedDiscountVoucher: null,
              discount_code: "",
              showForm: true,
              isLoading: false,
            },
            () => {
              this.setInitalValues();
            }
          );
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not update discount.. ");
      });
  };

  editDiscount = (discountId) => {
    /* 
        this.setState({ discount, selectedDiscount: discount.discount_type, selectedDiscountVoucher: discount?.discount_voucher_type || "NORMAL DISCOUNT" }, () => {
          this.setInitalValues();
        }); 
     */
    DiscountServiceAPI.fetchDiscount(discountId)
      .then((response) => {
        if (response.data.status) {
          const discount = response.data.data;
          //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
          //    if (discount?.is_editable) {
          this.setState(
            {
              discount,
              is_editable: discount?.is_editable,
              selectedDiscount: discount.discount_type,
              selectedDiscountVoucher:
                discount?.discount_voucher_type || "NORMAL DISCOUNT",
              discount_code: "",
              showForm: true,
            },
            () => {
              this.setInitalValues();
              toast.success("Epos / Mpos discount details retrieved.");
            }
          );
          //   } else {
          //  toast.warn("This discount code that is already in use can't be modified.");
          //  }
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not add discount.. ");
      });
  };

  fetchDiscountCode = (setFieldValue) => {
    DiscountServiceAPI.addDiscountCode()
      .then((response) => {
        if (response.data.status) {
          this.setState(
            { discount_code: response.data.data.discount_code },
            () => {
              setFieldValue("discount_code", response.data.data?.discount_code);
            }
          );
          toast.success("Epos / Mpos discount code generated. ");
        } else {
          toast.warn("Couldn'fetch epos discount code.  ");
          this.setState({ isLoading: false, open: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.error("Something went wrong. ");
      });
  };

  handleDiscountSubmit = (values, resetForm) => {
    this.setState({ isLoading: true });

    if (!this.state.discount) {
      this.addDiscount(values, resetForm);
    } else {
      values["discount_id"] = this.state.discount.discount_id;
      this.updateDiscount(values, resetForm);
    }
  };

  deleteDiscount = (discountId) => {
    DiscountServiceAPI.deleteDiscount(discountId)
      .then((response) => {
        if (response.data.status) {
          toast.success("Epos / Mpos discount  deleted. ");
          this.setState({ open: false });
          this.fetchAllDiscounts();
        } else {
          toast.warn("Couldn't delete. ");
          this.setState({ isLoading: false, open: false, showForm: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, open: false });
        toast.error("Something went wrong. ");
      });
  };

  filterOrders = (event) => {
    event.preventDefault();
    let filteredData = event.target.value
      ? this.state.discounts.filter((item) =>
          item.discount_code
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        )
      : this.state.discountList;

    if (filteredData?.length == 0) {
      filteredData = event.target.value
        ? this.state.discounts.filter((item) =>
            item.discount_name
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
          )
        : this.state.discountList;
    }

    this.setState({ discounts: filteredData || this.state.discountList });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleViewInstructions = () => {
    window.scroll({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  handleSort = (column) => {
    const { discounts, sortDirection, sortColumn } = this.state;
    let direction = "asc";

    if (column === sortColumn) {
      direction = sortDirection === "asc" ? "desc" : "asc";
    }

    const sortedDiscounts = [...discounts].sort((a, b) => {
      if (direction === "asc") {
        return a[column] < b[column] ? -1 : 1;
      } else {
        return a[column] > b[column] ? -1 : 1;
      }
    });

    this.setState({
      discounts: sortedDiscounts,
      sortColumn: column,
      sortDirection: direction,
    });
  };

  showForm = () => {
    if (!this.state.showForm) {
      this.setState({
        showForm: true,
      });
    } else {
      this.setState(
        {
          showForm: false,
          discount: null,
          is_editable: true,
        },
        () => {
          this.setInitalValues();
        }
      );
    }
  };

  render() {
    const { submitting, initialValues, showForm, showFormDialog } = this.state;
    const { cl } = this.props;
    return (
      <Fragment>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <Breadcrumb />
            </div>
            <div className="col-md-12">
              <div className="ms-panel">
                {showFormDialog && (
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Delete Epos / Mpos Discount?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Do you really want to proceed?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose}>No</Button>
                      <Button
                        onClick={() => this.deleteDiscount(this.state.dId)}
                      >
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
                {!showForm && (
                  <div className="ms-panel-header">
                    <React.Fragment>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6} lg={6}>
                          <h5>Epos / Mpos Discounts</h5>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.showForm}
                          >
                            {this.state.discount ? "Update" : "Add"} Epos / Mpos
                            Discount
                          </Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  </div>
                )}
                <div className="ms-panel-body"></div>
                {showForm && (
                  <React.Fragment>
                    <div style={{}}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ marginLeft: "20px", height: "10px" }}
                      >
                        <div style={{}}>
                          {/* {this.state.discount ? "Update" : "Create"}  */}
                          Epos / Mpos Discount
                          <Button
                            variant="contained"
                            style={{
                              float: "right",
                              marginRight: "20px",
                              position: "relative",
                              top: "-8px",
                            }}
                            color="primary"
                            onClick={this.showForm}
                          >
                            Back
                          </Button>
                        </div>{" "}
                        <Divider />
                      </Typography>
                    </div>
                    <br /> <br />
                    <Grid
                      container
                      sx={{ display: { xs: "block", md: "flex" } }}
                    >
                      <Grid container xs={12} md={8} lg={8}>
                        <Formik
                          enableReinitialize="true"
                          initialValues={initialValues}
                          validationSchema={schema.eposDiscountSchema}
                          onSubmit={(values, { resetForm }) => {
                            values["property_id"] = this.props.propertyId;
                            values["type"] = "BUSINESS";
                            this.handleDiscountSubmit(values, resetForm);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              <Box sx={{ margin: "30px", marginTop: "0px" }}>
                                <Grid container spacing={3} direction="row">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                      mb: 2,
                                      ml: "10px",
                                      width: "220px",
                                      height: "40px",
                                      display: { xs: "block", md: "none" },
                                    }}
                                    onClick={this.handleViewInstructions}
                                  >
                                    View Instructions
                                  </Button>{" "}
                                  <Grid item xs={12} md={12} lg={12}>
                                    <Typography>
                                      {" "}
                                      Complete the following details to{" "}
                                      {this.state?.discount
                                        ? "update the "
                                        : "create a "}
                                      discount.
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                      {/* <div
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        alignItems: "center",
                                      }}
                                    > */}
                                      <TextField
                                        error={
                                          touched.discount_code &&
                                          errors.discount_code
                                            ? true
                                            : false
                                        }
                                        id="discount_code"
                                        label="Discount Code"
                                        type="text"
                                        name="discount_code"
                                        variant="standard"
                                        inputProps={{ maxLength: 8 }} 
                                        disabled={!this.state?.is_editable}
                                        value={values.discount_code || ""}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "discount_code",
                                            e.target.value.toUpperCase().trim()
                                          );
                                        }}
                                        onBlur={(e) => {
                                          setFieldValue(
                                            "discount_code",
                                            e.target.value.toUpperCase().trim()
                                          );
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <Tooltip
                                                disableFocusListener
                                                title={
                                                  <React.Fragment>
                                                    <i>
                                                      * Enter or generate a
                                                      unique discount code
                                                      applied during checkout to
                                                      an order.{" "}
                                                    </i>{" "}
                                                    <br />
                                                  </React.Fragment>
                                                }
                                              >
                                                {this.state?.is_editable && (
                                                  <IconButton
                                                    color="primary"
                                                    onClick={() =>
                                                      this.fetchDiscountCode(
                                                        setFieldValue
                                                      )
                                                    }
                                                    aria-label="Fetch"
                                                  >
                                                    <PlaylistAddIcon />
                                                  </IconButton>
                                                )}
                                                {/* <InfoIcon
                                              onClick={this.handleDescription}
                                            /> */}
                                              </Tooltip>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                      {/* {this.state?.is_editable && (
                                        <IconButton
                                          color="primary"
                                          onClick={() =>
                                            this.fetchDiscountCode(
                                              setFieldValue
                                            )
                                          }
                                          aria-label="Fetch"
                                        >
                                          <PlaylistAddIcon />
                                        </IconButton>
                                      )} */}
                                      {/* </div> */}
                                      {/*    <FormHelperText>
                              * Enter or generate a unique discount code applied during checkout to an order.
                            </FormHelperText> */}
                                      <FormHelperText error>
                                        {touched.discount_code &&
                                        errors.discount_code
                                          ? errors.discount_code
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                      <TextField
                                        error={
                                          touched.discount_name &&
                                          errors.discount_name
                                            ? true
                                            : false
                                        }
                                        name="discount_name"
                                        label="Discount Name"
                                        autoComplete="discount_name"
                                        type="text"
                                        disabled={!this.state?.is_editable}
                                        variant="standard"
                                        pattern="\s*\S+.*"
                                        value={values.discount_name || ""}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "discount_name",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={(e) => {
                                          setFieldValue(
                                            "discount_name",
                                            e.target.value.trim()
                                          );
                                        }}
                                        aria-describedby="component-error-text"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <Tooltip
                                                disableFocusListener
                                                title={
                                                  <React.Fragment>
                                                    <i>
                                                      * Enter descriptive name
                                                      for the discount.{" "}
                                                    </i>{" "}
                                                    <br />
                                                  </React.Fragment>
                                                }
                                              >
                                                {/* <InfoIcon
                                                onClick={this.handleDescription}
                                              /> */}
                                              </Tooltip>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />

                                      <FormHelperText error>
                                        {touched.discount_name &&
                                        errors.discount_name
                                          ? errors.discount_name
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <div className="select-container">
                                      <FormControl fullWidth>
                                        <InputLabel id="discount_type">
                                          Discount Type
                                        </InputLabel>
                                        <Select
                                          labelId="discount_type"
                                          id="discount_type"
                                          disabled={!this.state?.is_editable}
                                          name="discount_type"
                                          variant="standard"
                                          input={
                                            <OutlinedInput
                                              id="discount_type"
                                              label="Discount Type"
                                            />
                                          }
                                          value={
                                            this.state.selectedDiscount || ""
                                          }
                                          label="Discount Type"
                                          onChange={(e) => {
                                            this.setState({
                                              selectedDiscount: e.target.value,
                                            });
                                            setFieldValue(
                                              "discount_type",
                                              e.target.value
                                            );
                                          }}
                                          onBlur={(e) => {
                                            this.setState({
                                              selectedDiscount: e.target.value,
                                            });
                                            setFieldValue(
                                              "discount_type",
                                              e.target.value
                                            );
                                          }}
                                        >
                                          {discountTypeOptions.map((option) => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        <Tooltip
                                          title={
                                            <React.Fragment>
                                              <i>
                                                * Choose between discount type :{" "}
                                              </i>{" "}
                                              <br />
                                              <i>
                                                * Deduct a fixed amount or
                                                percentage from the order total.{" "}
                                              </i>
                                            </React.Fragment>
                                          }
                                        >
                                          {/* <InfoIcon
                                          className="help-icon"
                                          sx={{ right: "22px" }}
                                        /> */}
                                        </Tooltip>
                                        {/*  <FormHelperText>
                              *  Choose between discount type :  <br />
                              Deduct a fixed amount or percentage from the order total.
                            </FormHelperText> */}
                                        <FormHelperText error>
                                          {touched.discount_type &&
                                          errors.discount_type
                                            ? errors.discount_type
                                            : null}
                                        </FormHelperText>
                                      </FormControl>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                      <TextField
                                        error={
                                          touched.discount_value &&
                                          errors.discount_value
                                            ? true
                                            : false
                                        }
                                        name="discount_value"
                                        label="Discount Value"
                                        type="number"
                                        disabled={!this.state?.is_editable}
                                        variant="standard"
                                        value={values.discount_value || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="start">
                                              &nbsp;&nbsp;
                                              <Tooltip
                                                disableFocusListener
                                                title={
                                                  <React.Fragment>
                                                    <i>
                                                      * Define discount amount
                                                      or percentage.{" "}
                                                    </i>
                                                  </React.Fragment>
                                                }
                                              ></Tooltip>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />

                                      <FormHelperText error>
                                        {touched.discount_value &&
                                        errors.discount_value
                                          ? errors.discount_value
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                      <TextField
                                        error={
                                          touched.minimum_order_amount &&
                                          errors.minimum_order_amount
                                            ? true
                                            : false
                                        }
                                        name="minimum_order_amount"
                                        label="Minimum Order Amount"
                                        onInput={(event) => {
                                          if (!isNaN(event.target.value)) {
                                            event.target.value = !isNaN(
                                              event.target.value
                                            )
                                              ? event.target.value
                                              : "";
                                          } else {
                                            event.target.value = "";
                                          }
                                        }}
                                        type="number"
                                        variant="standard"
                                        value={
                                          values.minimum_order_amount || ""
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!this.state?.is_editable}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="start">
                                              &nbsp;&nbsp;
                                              <Tooltip
                                                disableFocusListener
                                                title={
                                                  <React.Fragment>
                                                    <i>
                                                      * Set minimum order amount
                                                      for discount purchase
                                                      criteria.{" "}
                                                    </i>
                                                  </React.Fragment>
                                                }
                                              ></Tooltip>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />

                                      <FormHelperText error>
                                        {touched.minimum_order_amount &&
                                        errors.minimum_order_amount
                                          ? errors.minimum_order_amount
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                      <TextField
                                        error={
                                          touched.discount_pin &&
                                          errors.discount_pin
                                            ? true
                                            : false
                                        }
                                        name="discount_pin"
                                        label="Discount Pin"
                                        onInput={(event) => {
                                          if (!isNaN(event.target.value)) {
                                            event.target.value = !isNaN(
                                              event.target.value
                                            )
                                              ? event.target.value
                                              : "";
                                          } else {
                                            event.target.value = "";
                                          }
                                        }}
                                        type="text"
                                        variant="standard"
                                        value={values.discount_pin || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!this.state?.is_editable}
                                        InputProps={{
                                          inputProps: {
                                            minLength: 4,
                                            maxLength: 4,
                                          },
                                          endAdornment: (
                                            <InputAdornment position="start">
                                              &nbsp;&nbsp;
                                              <Tooltip
                                                disableFocusListener
                                                title={
                                                  <React.Fragment>
                                                    <i>
                                                      * Set 4 digit discount
                                                      pin.{" "}
                                                    </i>
                                                  </React.Fragment>
                                                }
                                              ></Tooltip>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />

                                      <FormHelperText error>
                                        {touched.discount_pin &&
                                        errors.discount_pin
                                          ? errors.discount_pin
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <Stack spacing={3}>
                                          <MobileDatePicker
                                            error={
                                              touched.discount_start_date &&
                                              errors.discount_start_date
                                                ? true
                                                : false
                                            }
                                            name="discount_start_date"
                                            label="Discount Start Date"
                                            variant="standard"
                                            disabled={!this.state?.is_editable}
                                            value={
                                              values.discount_start_date ||
                                              dayjs(new Date())
                                            }
                                            minDate={dayjs()}
                                            inputFormat="DD-MM-YYYY"
                                            onChange={(day) => {
                                              const formattedDay =
                                                dayjs(day).format("DD-MM-YYYY");
                                              setFieldValue(
                                                "discount_start_date",
                                                dayjs(day)
                                              );
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="start">
                                                  <CalendarMonthIcon />
                                                  &nbsp;&nbsp;
                                                  <Tooltip
                                                    disableFocusListener
                                                    title={
                                                      <React.Fragment>
                                                        <i>
                                                          * Discount valid from
                                                          specified start date.{" "}
                                                        </i>
                                                      </React.Fragment>
                                                    }
                                                  ></Tooltip>
                                                </InputAdornment>
                                              ),
                                            }}
                                            renderInput={(params) => (
                                              <TextField {...params} />
                                            )}
                                          />
                                        </Stack>
                                      </LocalizationProvider>

                                      <FormHelperText error>
                                        {touched.discount_start_date &&
                                        errors.discount_start_date
                                          ? errors.discount_start_date
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <Stack spacing={3}>
                                          <MobileDatePicker
                                            error={
                                              touched.discount_end_date &&
                                              errors.discount_end_date
                                                ? true
                                                : false
                                            }
                                            name="discount_end_date"
                                            label="Discount End Date"
                                            variant="standard"
                                            value={
                                              values.discount_end_date ||
                                              dayjs(new Date())
                                            }
                                            inputFormat="DD-MM-YYYY"
                                            minDate={dayjs()}
                                            onChange={(day) => {
                                              const formattedDay =
                                                dayjs(day).format("DD-MM-YYYY");
                                              setFieldValue(
                                                "discount_end_date",
                                                dayjs(day)
                                              );
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="start">
                                                  <CalendarMonthIcon />
                                                  &nbsp;&nbsp;
                                                  <Tooltip
                                                    disableFocusListener
                                                    title={
                                                      <React.Fragment>
                                                        <i>
                                                          * Discount valid until
                                                          specified end date. :{" "}
                                                        </i>
                                                      </React.Fragment>
                                                    }
                                                  ></Tooltip>
                                                </InputAdornment>
                                              ),
                                            }}
                                            renderInput={(params) => (
                                              <TextField {...params} />
                                            )}
                                          />
                                        </Stack>
                                      </LocalizationProvider>

                                      <FormHelperText error>
                                        {touched.discount_end_date &&
                                        errors.discount_end_date
                                          ? errors.discount_end_date
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    container
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    direction="row"
                                  >
                                    <Grid item xs={12} md={6} lg={6}>
                                      <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        block
                                        sx={{
                                          mt: 2,
                                          mb: 2,
                                          ml: 2,
                                          width: "300px",
                                          height: "40px",
                                        }}
                                      >
                                        {this.state?.discount
                                          ? "Update"
                                          : "Submit"}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                      {this.state?.discount && (
                                        <Button
                                          type="button"
                                          color="primary"
                                          variant="contained"
                                          block
                                          onClick={() => this.handleCancel()}
                                          sx={{
                                            mt: 2,
                                            mb: 2,
                                            ml: 2,
                                            width: "300px",
                                            height: "40px",
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Form>
                          )}
                        </Formik>
                      </Grid>
                      <Grid container xs={12} md={4} lg={4}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Box sx={{ padding: "20px", paddingTop: "0px" }}>
                            <Paper
                              elevation={3}
                              sx={{
                                width: "auto",
                              }}
                            >
                              <Card>
                                <Typography
                                  variant="h5"
                                  gutterBottom
                                  style={{
                                    background: "#5773ff",
                                    textAlign: "center",
                                    color: "white",
                                    margin: "0px",
                                    padding: "6px 0px",
                                  }}
                                >
                                  Instructions
                                </Typography>
                                <CardContent>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Typography color="textPrimary" paragraph>
                                      <b>
                                      This section is designated for the creation of discounts. Once created, these discounts can be applied across your range of products.
                                      </b>
                                    </Typography>
                                  </Box>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    sx={{ my: 1 }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b> Discount Code and Name.</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Enter or generate a unique discount code
                                        and name applied during checkout to an
                                        order.
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b> Discount Type .</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Choose between discount type
                                        <br />
                                        Deduct a fixed amount or percentage from
                                        the order total.
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b>Discount Pin.</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Set 4 digit discount pin.
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b>
                                          {" "}
                                          Discount Value and Minimum Order
                                          Amount.
                                        </b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Define discount amount or percentage and
                                        Set minimum order amount.
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b> Discount Start and End Date.</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Discount valid until specified end date.
                                        :{" "}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Paper>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}

                {!showForm && (
                  <div className="ms-invoice-table table-responsive mt-2">
                    <DataTable
                      columns={this.columns}
                      customStyles={this.customStyles}
                      pagination
                      paginationRowsPerPageOptions={[
                        10, 25, 50, 100, 500, 1000,
                      ]}
                      data={this.state.discounts}
                    />
                  </div>
                )}
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default connect(mapStateToProps, {})(index);
