import React, { Component } from "react";
import { toast } from "react-toastify";
import * as shiftServiceApi from "../../services/shift/shiftServiceApi";
import Breadcrumb from "./Breadcrumb";
import { Fragment } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ButtonBase, Card, CardContent, FormControl, Paper, Stack } from "@mui/material";
import * as schema from "../../utils/Schema";
import { Form, Formik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import Loader from "../../shared/Loader";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import TimePicker from "../../components/commonComps/TimePicker";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

class index extends Component {
  state = {
    isLoading: false,
    initialValues: {},
    isLoading: false,
    shifts: [],
    shift: null,
    discount: null,
    businessTypes: [],
    costType: "",
    selectedBusinessType: "",
    selectedDiscountVoucher: "",
    page: 0,
    rowsPerPage: 10,
    sortColumn: "discount_code",
    sortDirection: "asc",
    open: false,
    dId: "",
    discount_code: "",
    discountList: [],
    showForm: false,
    showFormDialog: false,
    is_editable: true,
    shiftId: "",
  };

  columns = [
    {
      name: "Shift Name",
      selector: "shift_name",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {row.shift_name}</div>,
    },
    {
      name: "Start Time",
      selector: "start_time",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          {row.start_time ? (
            <div>{dayjs(row.start_time, "HH:mm").format("HH:mm")}</div>
          ) : (
            ""
          )}
        </div>
      ),
    },

    {
      name: "End Time",
      selector: "end_time",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          {row.end_time ? (
            <div>{dayjs(row.end_time, "HH:mm").format("HH:mm")}</div>
          ) : (
            ""
          )}
        </div>
      ),
    },

    {
      name: "Action",
      selector: "action",
      justifyContent: "center",
      cell: (row) => (
        // <div>
        //   <IconButton
        //     color="primary"
        //     onClick={() => this.editShift(row)}
        //     aria-label="Edit"
        //   >
        //     <EditIcon />
        //   </IconButton>
        //   <IconButton
        //     color="primary"
        //     onClick={() => this.handleClickOpen(row.shift_id)}
        //     aria-label="delete"
        //     size="large"
        //   >
        //     <DeleteIcon fontSize="inherit" />
        //   </IconButton>
        // </div>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" startIcon={<EditIcon color="primary" />} onClick={() => this.editShift(row)}>Edit</Button>
          <Button variant="outlined" startIcon={<DeleteIcon color="primary" />} onClick={() => this.handleClickOpen(row.shift_id)}>Delete</Button>

        </Stack>
      ),
    },
  ];

  customStyles = {
    rows: {
      style: {
        //          minHeight: '70px', // override the row height
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "65px",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "auto",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        justifyContent: "center",
        "&:hover div": {
          backgroundColor: "transparent",
          color: "white !important",
        },
        div: {
          color: "white !important",
        },
        span: {
          color: "white !important",
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "2px", // override the cell padding for data cells
        paddingRight: "2px",
        pointer: "cursor",
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  componentDidMount = () => {
    this.setInitalValues();
    this.fetchAllShifts();
  };

  handleClickOpen = (shiftId) => {
    this.setState({ open: true, shiftId: shiftId, showFormDialog: true });
  };

  handleCancel = () => {
    this.setState(
      {
        shift: null,
        showForm: false,
      },
      () => {
        this.setInitalValues();
      }
    );
  };

  handleClose = () => {
    this.setState({ open: false, dId: "" });
  };

  handleDescription = () => {
    console.log("handle desc.");
  };

  setInitalValues = () => {
    const { shift } = this.state;

    let initialValues = {};

    initialValues = {
      shift_name: shift?.shift_name || "",
      start_time: shift?.start_time.slice(0, 5) || "",
      end_time: shift?.end_time.slice(0, 5) || "",
    };

    this.setState({ initialValues });
  };

  fetchAllShifts = async () => {
    try {
      this.setState({ isLoading: true });
      let propertId = this.props.propertyId;
      const response = await shiftServiceApi.fetchAllShifts(propertId);

      if (response && response.data.status) {
        this.setState({
          shifts: response?.data?.data,
          isLoading: false,
        });
        // toast.success("shifts fetched")
      } else {
        this.setState({ shifts: [], isLoading: false });
      }
    } catch (error) {
      this.setState({ shifts: [], isLoading: false });
      throw error;
    }
  };

  addShift = (values, resetForm) => {
    shiftServiceApi
      .addShift(values)
      .then((response) => {
        if (response.data.status) {
          toast.success("Shift added.");
          resetForm({ values: "" });
          this.setState(
            { shift: null, isLoading: false, showForm: false },
            () => {
              this.setInitalValues();
            }
          );
          this.fetchAllShifts();
        } else {
          this.setState(
            { shift: null, showForm: false, isLoading: false },
            () => {
              this.setInitalValues();
            }
          );
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not add Shift.. ");
      });
  };

  updateShift = (values, resetForm) => {
    shiftServiceApi
      .updateShift(values)
      .then((response) => {
        if (response.data.status) {
          toast.success("Shift updated successfully. ");
          resetForm({ values: "" });
          this.setState(
            { shift: null, showForm: false, isLoading: false },
            () => {
              this.setInitalValues();
            }
          );
          this.fetchAllShifts();
        } else {
          this.setState({ showForm: false, isLoading: false });
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not update shift.. ");
      });
  };
  editShift = (shift) => {
    this.setState({ shift, showForm: true }, () => {
      this.setInitalValues();
    });
  };
  handleShiftSubmit = (values, resetForm) => {
    if (!this.state.shift) {
      this.setState({ isLoading: true });
      this.addShift(values, resetForm);
    } else {
      values["shift_id"] = this.state.shift.shift_id;
      this.updateShift(values, resetForm);
    }
  };

  deleteShift = (shiftId) => {
    if (shiftId) {
      shiftServiceApi
        .deleteShift(shiftId)
        .then((response) => {
          if (response.data.status) {
            toast.success("Shift deleted.");
            this.setState({ open: false, isLoading: false });
            this.fetchAllShifts();
          } else {
            toast.warn("Couldn't delete. ");
            this.setState({ isLoading: false, open: false, showForm: false });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, open: false });
          toast.error("Something went wrong. ");
        });
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleViewInstructions = () => {
    window.scroll({
      top:  document.documentElement.scrollHeight, 
      behavior: "smooth", 
    });
  };
  showForm = () => {
    if (!this.state.showForm) {
      this.setState({
        showForm: true,
      });
    } else {
      this.setState(
        {
          showForm: false,
          shift: null,
          is_editable: true,
        },
        () => {
          this.setInitalValues();
        }
      );
    }
  };

  render() {
    const {
      submitting,
      initialValues,
      showForm,
      showFormDialog,
      businessTypes,
    } = this.state;

    return (
      <>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <Breadcrumb />
            </div>
            <div className="col-md-12">
              <div className="ms-panel">
                <div className="ms-panel-header">
                  {showFormDialog && (
                    <Dialog
                      open={this.state.open}
                      onClose={this.handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Delete Shift?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Do you really want to proceed?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.handleClose}>No</Button>
                        <Button
                          onClick={() => this.deleteShift(this.state.shiftId)}
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                  {!showForm && (
                    <React.Fragment>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6} lg={6}>
                          <h5>Shift Details</h5>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.showForm}
                          >
                            {this.state.discount ? "Update" : "Add"} Shift
                          </Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </div>
                <div className="ms-panel-body"></div>
                {showForm && (
                  <React.Fragment>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ margin: "10px" }}
                    >
                      <div style={{}}>
                        Shift
                        <Button
                          variant="contained"
                          style={{ float: "right" }}
                          color="primary"
                          onClick={this.showForm}
                        >
                          Back
                        </Button>
                      </div>
                    </Typography>
                    <p style={{ margin: "10px" }}>
                      Complete the following details.
                    </p>
                    <Divider sx={{ mb: 4 }} />
                    <Grid
                      container
                      sx={{display:{xs:"block",md:"flex"}}}
                      alignItems="center"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          mb: 2,
                          ml: "20px",
                          width: "220px",
                          height: "40px",
                          display: { xs: "block", md: "none" },
                        }}
                        onClick={this.handleViewInstructions}
                      >
                        View Instructions
                      </Button>{" "}
                      <Grid item xs={12} md={8} lg={8}>
                        <Formik
                          enableReinitialize
                          initialValues={initialValues}
                          validationSchema={schema.shiftFormSchema}
                          onSubmit={(values, { resetForm }) => {
                            values["property_id"] = this.props.propertyId;
                            values["start_time"] = values["start_time"] + ":00";
                            values["end_time"] = values["end_time"] + ":00";
                            this.handleShiftSubmit(values, resetForm);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              <Box sx={{ margin: "30px" }}>
                                <Grid container spacing={3} direction="row">
                                  <Grid item xs={12} md={10} lg={10}>
                                    <FormControl fullWidth>
                                      <TextField
                                        error={
                                          touched.shift_name &&
                                          errors.shift_name
                                            ? true
                                            : false
                                        }
                                        name="shift_name"
                                        label="Shift Name"
                                        autoComplete="shift_name"
                                        type="text"
                                        variant="standard"
                                        value={values.shift_name || ""}
                                        onChange={handleChange}
                                        aria-describedby="component-error-text"
                                      />

                                      <FormHelperText error>
                                        {touched.shift_name && errors.shift_name
                                          ? errors.shift_name
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} md={5} lg={5}>
                                    <FormControl fullWidth>
                                      <TimePicker
                                        id="startTime"
                                        name="startTime"
                                        allowClear={false}
                                        changeOnBlur
                                        label="Start time"
                                        format="HH:mm"
                                        value={
                                          values.start_time
                                            ? dayjs(values.start_time, "HH:mm")
                                            : ""
                                        }
                                        onCalendarChange={(time, timeString) => {
                                          setFieldValue(
                                            "start_time",
                                            timeString
                                          );
                                        }}
                                        onChange={(time, timeString) => {
                                          setFieldValue(
                                            "start_time",
                                            timeString
                                          );
                                        }}
                                        defaultValue={""}
                                      />
                                      <FormHelperText error>
                                        {touched.start_time && errors.start_time
                                          ? errors.start_time
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} md={5} lg={5}>
                                    <FormControl fullWidth>
                                      <TimePicker
                                        id="endTime"
                                        name="endTime"
                                        allowClear={false}
                                        changeOnBlur
                                        label="End time"
                                        format="HH:mm"
                                        value={
                                          values.end_time
                                            ? dayjs(values.end_time, "HH:mm")
                                            : ""
                                        }
                                        onCalendarChange={(time, timeString) => {
                                          setFieldValue("end_time", timeString);
                                        }}
                                        onChange={(time, timeString) => {
                                          setFieldValue("end_time", timeString);
                                        }}
                                        defaultValue={""}
                                      />
                                      <FormHelperText error>
                                        {touched.end_time && errors.end_time
                                          ? errors.end_time
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={5} lg={5}>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      variant="contained"
                                      block
                                      sx={{
                                        mt: 2,
                                        mb: 2,
                                        width: "300px",
                                        height: "40px",
                                      }}
                                    >
                                      {this.state?.shift ? "Update" : "Submit"}
                                    </Button>
                                    </Grid>
                                    {this.state?.shift && (
                                      <Grid item xs={12} md={5} lg={5}>
                                      <Button
                                        type="button"
                                        color="primary"
                                        variant="contained"
                                        block
                                        onClick={() => this.handleCancel()}
                                        sx={{
                                          mt: 2,
                                          mb: 2,
                                          width: "300px",
                                          height: "40px",
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                  </Grid>
                                    )}
                                </Grid>
                              </Box>
                            </Form>
                          )}
                        </Formik>{" "}
                      </Grid>
                      <Grid container xs={12} md={4} lg={4}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Box sx={{ margin: "20px" }}>
                            <Paper
                              elevation={3}
                              sx={{
                                width: "auto",
                                marginBottom: "20px",
                              }}
                            >
                              <Card>
                                <Typography
                                  variant="h5"
                                  gutterBottom
                                  style={{
                                    background: "#5773ff",
                                    textAlign: "center",
                                    color: "white",
                                    margin: "0px",
                                    padding: "6px 0px",
                                  }}
                                >
                                  Instructions
                                </Typography>
                                <CardContent>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Typography color="textPrimary" paragraph>
                                      <b>
                                        You can create a shift in this section.
                                      </b>
                                    </Typography>
                                  </Box>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    sx={{ my: 1 }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b>Shift Name.</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Name of the Shift{" "}
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b>Start and End Time.</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        The start and End time of shift
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Paper>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}

                {!showForm && (
                  <div className="ms-invoice-table table-responsive mt-2">
                    <DataTable
                      columns={this.columns}
                      customStyles={this.customStyles}
                      pagination
                      paginationRowsPerPageOptions={[
                        10, 25, 50, 100, 500, 1000,
                      ]}
                      data={this.state.shifts}
                    />
                  </div>
                )}
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default connect(mapStateToProps, {})(index);
