import React, { Component } from "react";
import { toast } from "react-toastify";
import * as CashServiceAPI from "../../services/cash/CashServiceAPI";
import * as WebOrderServiceAPI from "../../services/webOrder/WebOrderServiceAPI";
import { Fragment } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "../../shared/Loader";
import Breadcrumb from "./Breadcrumb";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";


class index extends Component {
  state = {
    isLoading: false,
    payment_method_type: [],
    checked: ["wifi"],
    selectedCard: false,
    grants: [],
    dense: false,
    isChecked: false,
    plan: {},
    terms: {},
    url: {},
    nash:{},
  };

  componentDidMount = () => {
    const dump = this.props.propertyId;
    this.fetchWebURL();
    this.fetchGrants(dump);
    const nashState = this.props?.history?.location?.state?.nash;
    this.setState({nash:nashState})
  };

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked });
  };

  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };

  fetchWebURL = async () => {
    const dump = this.props.propertyId;
    try {
      const response = await WebOrderServiceAPI.fetchWebURL(dump);

      if (response.data.status) {
        if (response.data?.data) {
          this.setState(
            {
              url: response.data.data,
              isModalOpen: response.data.data?.is_enable,
            },
            () => {}
          );
        }
      } else {
        const err = response.data?.message || "Something went wrong.";
        this.setState({ url: {}, isModalOpen: false });
        toast.warn(err);
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ url: {}, isModalOpen: false });
      toast.warn(err);
      throw error;
    }
  };

  fetchGrants = (payload) => {
    this.setState({ isLoading: true });
    if (payload) {
      WebOrderServiceAPI.fetchNashOrderTerms(payload)
        .then((response) => {
          if (response.data.status) {
            this.setState({ terms: response.data.data, isLoading: false });
          } else {
            this.setState({ terms: {}, isLoading: false });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, terms: {} });
        });
    } else {
      this.setState({ isLoading: false, terms: {} });
    }
  };

  handleCheckboxChange = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  handleSignRequest = () => {
    this.setState({ isLoading: true });
    this.props.history.push({
      pathname: "/nash-order",
      state: {
        terms: this.state.terms,
      },
    });
    };


  render() {
    const {
      selectedCard,
      grants,
      dense,
      isChecked,
      plan,
      isLoading,
      terms,
      url,
    } = this.state;
    return (
      <Fragment>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="">
            <div className="">
              <div className="">
                <br />
                <div className="col-md-12">
                  <Breadcrumb />
                </div>

                <br />
                <Grid
                  container
                  justifyContent="center" 
                  alignItems= "center" 
                >
                  <Grid item lg={9}>
                    <Paper
                      elevation={3}
                      sx={{ p: 2, borderRadius: 2, mb: 2, padding: 5 }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h5">
                          <b>Third Party Delivery Fulfillment</b>{" "}
                          {url?.is_enable && (
                            <CheckCircleIcon style={{ color: "#5773ff" }} />
                          )}{" "}
                        </Typography>
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{ background: "#e7e7fa", padding: "5px" }}
                          >
                            {terms?.amount}
                          </Typography>
                      </div>
                      <hr />
                        <Grid container spacing={1}>
                          <React.Fragment>
                            <Grid item xs={12} md={12}>
                              <br />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography variant="h6" gutterBottom>
                                  <b> Terms and Conditions</b>
                                </Typography>
                              </div>

                              <Typography
                                variant="body1"
                                style={{ padding: "5px" }}
                              >
                                <div
                                  style={{
                                    background: "#f5f5f5",
                                    padding: "15px",
                                    maxHeight: "300px", // Corrected property name
                                    overflow: "auto",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: terms?.terms_and_conditions,
                                  }}
                                />
                              </Typography>
                            </Grid>
                          </React.Fragment>{" "}
                        </Grid>
                        <React.Fragment>
                          <br /> <br /> <br />
                          <Grid
                            container
                            spacing={3}
                            justifyContent="space-between"
                          >
                            <Grid item lg={6}>
                              <Button
                                fullWidth
                                style={{
                                  borderRadius: "10px",
                                  padding: "10px 0px",
                                }}
                                variant="outlined"
                                onClick={() => {
                                  this.props.history.goBack();
                                }}
                              >
                                Back
                              </Button>
                            </Grid>
                            <Grid item lg={6}>
                              <Button
                                fullWidth
                                style={{
                                  borderRadius: "10px",
                                  padding: "10px 0px",
                                }}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  this.handleSignRequest();
                                }}
                              >
                                Agree & Continue to Pay
                              </Button>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                     
                      <br /> <br /> <br />
                    </Paper>
                  </Grid>
                
                </Grid>
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default withRouter(connect(mapStateToProps, {})(index));
