import { createSlice } from "@reduxjs/toolkit";

export const menuSlice = createSlice({
  name: "menu",
  initialState: {
    selectedMenu: null,
    selectedCategory: null,
    menuNames: null,
    items: {},
    categoryNames: null,
    currentItemExtras: [],
    currentItemOptions: [],
    categoriesForExtras: [],
    categoriesForOptions: [],
    combos:[],
    comboItems:[],
  },
  reducers: {
    setSelectedMenu: (state, action) => {
      state.selectedMenu = action.payload;
    },
    setMenuNames: (state, action) => {
      state.menuNames = action.payload;
    },
    setCategoryNames: (state, action) => {
      state.categoryNames = action.payload;
    },
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setCombos: (state, action) => {
      state.combos = action.payload;
    },
    setComboItems: (state, action) => {
      state.comboItems = action.payload;
    },
    setCurrentItemExtras: (state, action) => {
      state.currentItemExtras = action.payload;
    },
    setCurrentItemOptions: (state, action) => {
      state.currentItemOptions = action.payload;
    },
    setCategoriesForExtras: (state, action) => {
      state.categoriesForExtras = action.payload;
    },
    setCategoriesForOptions: (state, action) => {
      state.categoriesForOptions = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setMenuNames,
  setItems,
  setCategoryNames,
  setSelectedMenu,
  setCurrentItemExtras,
  setCurrentItemOptions,
  setCategoriesForExtras,
  setCategoriesForOptions,
  setSelectedCategory,
  setCombos,
  setComboItems
} = menuSlice.actions;

export default menuSlice.reducer;
