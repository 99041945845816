import axios from "axios";
import * as APIUrl from "../APIUrl";

export const allOrders = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_ORDER + `${payload.page}&size=${payload.size}`,
    });
  } catch (error) {
    console.log("Error in All Order ::", error);
    throw new Error(error);
  }
};

export const filterOrders = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.FILTER_ORDER +
        `${payload.page}&search_text=${payload.search_text}&size=${payload.size}&property_id=${payload.property_id}&order_type=${payload.order_type}`,
    });
  } catch (error) {
    console.log("Error in All Order ::", error);
    throw new Error(error);
  }
};

export const searchOrder = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.SEARCH_ORDER +
        `${payload.page}&property_id=${payload.property_id}&search_text=${payload.search_text}&size=${payload.size}&order_type=${payload.order_type}`,
    });
  } catch (error) {
    console.log("Error in search order ::", error);
    throw new Error(error);
  }
};

export const updateOrderStatus = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.UPDATE_ORDER_STATUS +
        `${payload.order_id}&order-status=${payload.order_status}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updatePartialRefund = (payload) => {
  try {
    return axios({
      method: "put",
      url:
        APIUrl.UPDATE_PARTIAL_ORDER_STATUS +
        `${payload.order_id}&refund_amount=${payload.refund_amount}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const downloadExcel = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.DOWNLOAD_EXCEL +
        `${payload.to_date}&start_date=${payload.from_date}&search_text=${payload.search_text}&property_id=${payload.property_id}`,
      responseType: "blob",
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const refundSlip = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.REFUND_ORDER_SLIP + `${payload.order_id}`,
      responseType: "blob",
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updateOrderItemStatus = (payload) => {
  try {
    return axios({
      method: "put",
      url:
        APIUrl.UPDATE_ORDER_ITEM_STATUS +
        `${payload.order_id}&item-id=${payload.item_id}&order-item-status=${payload.order_item_status}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updateOwnDeliveryStatus = (payload) => {
  try {
    return axios({
      method: "put",
      url:
        APIUrl.UPDATE_OWN_DELIVERY_STATUS +
        `${payload.order_id}&own-delivery-status=${payload.own_delivery_status}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchTodaysOrder = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.FETCH_TODAYS_ORDER +
        `${payload.page}&property_id=${payload.property_id}&size=${payload.size}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchOrder = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.FETCH_ORDER +
        `${payload.page}&property_id=${payload.property_id}&size=${payload.size}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchOrderDetailsByOrderId = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.FETCH_ORDER_DETAILS_BY_ORDER_ID +
        `${payload.order_id}&page=${payload.page}&size=${payload.size}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const pushOrderNotificationToUser = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.PUSH_ORDER_NOTIFICATION_TO_USER +
        `?message=${payload.message}&order_id=${payload.order_id}&username=${payload.username}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchOrderMessages = () => {
  try {
    return axios({
      method: "",
      url: APIUrl.FETCH_ORDER_MESSAGES,
    });
  } catch (error) {
    throw new Error(error);
  }
};

// All Orders Filter By Date
export const filterAllOrdersByDate = (payload) => {
  let filter_url = "";

  if (
    payload.from_date === undefined &&
    payload.search_text === "" &&
    payload.sortValue === undefined &&
    payload.sort === undefined
  ) {
    // Pagination
    filter_url =
      APIUrl.FILTER_ORDER_BY_DATE +
      `${payload.page}&size=${payload.size}&property_id=${payload.property_id}`;
  } else if (
    payload.from_date === undefined &&
    payload.search_text !== undefined &&
    payload.sortValue === undefined &&
    payload.sort === undefined
  ) {
    // Filter
    filter_url =
      APIUrl.FILTER_ORDER_BY_DATE +
      `${payload.page}&size=${payload.size}&search_text=${payload.search_text}&property_id=${payload.property_id}`;
  } else if (
    payload.from_date != undefined &&
    payload.search_text === undefined &&
    payload.sortValue === undefined &&
    payload.sort === undefined
  ) {
    // Initial Load
    filter_url =
      APIUrl.FILTER_ORDER_BY_DATE +
      `${payload.page}&end_date=${payload.to_date}&size=${payload.size}&start_date=${payload.from_date}&property_id=${payload.property_id}`;
  } else if (payload.sort_value !== undefined && payload.sort !== undefined) {
    // Initial Load
    filter_url =
      APIUrl.FILTER_ORDER_BY_DATE +
      `${payload.page}&size=${payload.size}&end_date=${payload.to_date}&start_date=${payload.from_date}&property_id=${payload.property_id}&sort_value=${payload.sort_value}&sort=${payload.sort}`;
  } else if (
    payload.from_date === undefined &&
    payload.card_number !== undefined &&
    payload.sortValue === undefined &&
    payload.sort === undefined
  ) {
    filter_url =
      APIUrl.FILTER_ORDER_BY_DATE +
      `${payload.page}&size=${payload.size}&card_number=${payload.card_number}&property_id=${payload.property_id}`; // Change here: Replace search_text with card_number
  } else {
    filter_url =
      APIUrl.FILTER_ORDER_BY_DATE +
      `${payload.page}&size=${payload.size}&property_id=${payload.property_id}`;
  }

  try {
    return axios({
      method: "get",
      // url: APIUrl.FILTER_ORDER_BY_DATE + `${payload.page}&end_date=${payload.to_date}&size=${payload.size}&start_date=${payload.from_date}`
      url: filter_url,
    });
  } catch (error) {
    console.log("Error in All Order ::", error);
    throw new Error(error);
  }
};

// export const assignDeliveryAgent = (payload) => {
//     try {
//         return axios({
//             method: 'get',
//             url: APIUrl.ASSIGN_DELIVERY_AGENTS + `${payload.delivery_agent_username}&order_id=${payload.order_id}`
//         })
//     } catch (error) {
//         throw new Error(error)
//     }
// }

export const fetchDisputedOrders = (payload) => {

  try {
      return axios({
          method: 'get',
          url: APIUrl.FETCH_DISPUTED_ORDERS + `${payload}`,
      })
  } catch (error) {
      throw new Error(error)
  }
}
export const acceptDisputedOrder = (payload) => {

  try {
      return axios({
          method: 'post',
          url: APIUrl.ACCEPT_DISPUTED_ORDER + `${payload}`,
      })
  } catch (error) {
      throw new Error(error)
  }
}
export const defendDisputedOrder = (payload) => {

  try {
      return axios({
          method: 'post',
          url: APIUrl.DEFEND_DISPUTED_ORDER + `${payload}`,
      })
  } catch (error) {
      throw new Error(error)
  }
}
export const defendReasons = (payload) => {

  try {
      return axios({
          method: 'post',
          url: APIUrl.GET_DEFEND_REASONS + `${payload}`,
      })
  } catch (error) {
      throw new Error(error)
  }
}
export const defendDocs = (payload) => {

  try {
      return axios({
          method: 'post',
          url: APIUrl.SUPPLY_DEFEND_DOCS,
          data:payload,

      })
  } catch (error) {
      throw new Error(error)
  }
}