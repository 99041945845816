import React, { Component, Fragment } from 'react'
import AppTiming from './AppTiming'
import Breadcrumb from './Breadcrumb'
import { toast } from 'react-toastify'
import * as SettingServiceAPI from '../../services/setting/SettingServiceAPI'
import "./timing.css"
import dayjs from 'dayjs';
import { connect } from "react-redux";
import Loader from "../../shared/Loader";

class index extends Component {

    state = {
        delivery_timings: [],
        dine_in_timings: [],
        property_timing: [],
        take_away_timing: [],
        property: '',
        isLoading: false
    }

    componentDidMount = () => {
        const property = this.props.propertyId;
        this.setState({ property: property })
        if (property) {
            SettingServiceAPI.fetchBusinessTiming(property).then(response => {
                if (response.data.status) {
                    this.setState({
                        delivery_timings: response.data.data.delivery_timings,
                        dine_in_timings: response.data.data.dine_in_timings,
                        property_timing: response.data.data.property_timings,
                        take_away_timing: response.data.data.take_away_timings,
                    })
                }
            })
        }

    }

    updateDeliveryTime = (value) => {
        this.setState({ isLoading: true });
        const payload = {
            property_id: this.state.property,
            property_timing_details: value,
        }

        if (value.length == 0) {
            toast.success("Nothing to update. ");
            this.setState({ isLoading: false });
            return false;
        }


        console.log(value)
        for (let i = 0; i < 7; i++) {
            if (!(value[i].open_time && value[i].close_time) && this.state.property_timing[i].status == "OPEN") {
                console.log("Please enter delivery timing for all the days ")
                toast.error("Please enter delivery timing for all the days.")
                this.setState({ isLoading: false });
                return false;
            }

            if (this.state.property_timing[i].status == "CLOSED") {
                value[i].open_time = "";
                value[i].close_time = "";
            }


        }

        SettingServiceAPI.updateDeliveryTiming(payload).then(response => {
            if (response.data.status) {
                this.setState({ isLoading: false });
                toast.success('Delivery timing updated successfully!')
            } else {
                this.setState({ isLoading: false });
            }
        })
    }

    updateTakeAwayTime = (value) => {
        this.setState({ isLoading: true });
        const payload = {
            property_id: this.state.property,
            property_timing_details: value,
        }

        if (value.length == 0) {
            toast.success("Nothing to update. ");
            this.setState({ isLoading: false });
            return false;
        }

        console.log(value)
        for (let i = 0; i < 7; i++) {
            if (!(value[i].open_time && value[i].close_time) && this.state.property_timing[i].status == "OPEN") {
                console.log("Please enter takeaway timing for all the days.  ")
                toast.error("Please enter takeaway timing for all the days.")
                this.setState({ isLoading: false });
                return false;
            }

            if (this.state.property_timing[i].status == "CLOSED") {
                value[i].open_time = "";
                value[i].close_time = "";
            }
        }

        SettingServiceAPI.updateTakeAwayTiming(payload).then(response => {
            if (response.data.status) {
                this.setState({ isLoading: false });
                toast.success('Take-away timing updated successfully!')

            } else {
                this.setState({ isLoading: false });
            }
        })
    }

    updatePropertyTime = (value) => {
        this.setState({ isLoading: true });


        if (value.length == 0) {
            toast.success("Nothing to update. ");
            this.setState({ isLoading: false });
            return false;
        }

        console.log(value)
        for (let i = 0; i < 7; i++) {

            if (!(value[i].open_time && value[i].close_time) && value[i].status == "OPEN") {
                console.log("Please enter property timing for all days. ")
                toast.error("Please enter property timing for all days.")
                this.setState({ isLoading: false });
                return false;
            }

            if (value[i].status == "CLOSED") {
                value[i].open_time = "";
                value[i].close_time = "";
            }
            if (value[i].open_time && value[i].close_time) {
                value[i].status = "OPEN"
            }
        }


        const payload = {
            property_id: this.state.property,
            property_timing_details: value,
        }

        SettingServiceAPI.updatePropertyTiming(payload).then(response => {
            if (response.data.status) {
                this.setState({ isLoading: false });
                toast.success("Property timing updated successfully.")
            } else {
                this.setState({ isLoading: false });
                toast.error(response.data.message)
            }
        }).catch(err => {
            toast.error('Error : ', err);
            console.log('Error: ', err)
            this.setState({
                isLoading: false
            });
        });
    }

    updateDineInTime = (value) => {
        this.setState({ isLoading: true });
        const payload = {
            property_id: this.state.property,
            property_timing_details: value,
        }

        if (value.length == 0) {
            toast.success("Nothing to update. ");
            this.setState({ isLoading: false });
            return false;
        }


        console.log(value)
        for (let i = 0; i < 7; i++) {
            if (!(value[i].open_time && value[i].close_time) && this.state.property_timing[i].status == "OPEN") {
                console.log("Please enter dine-in timeing for all days. ")
                toast.error("Please enter dine-in timeing for all days.")
                this.setState({ isLoading: false });
                return false;
            }

            if (this.state.property_timing[i].status == "CLOSED") {
                value[i].open_time = "";
                value[i].close_time = "";
            }
        }


        SettingServiceAPI.updateDineInTiming(payload).then(response => {
            if (response.data.status) {
                this.setState({ isLoading: false });
                toast.success("Dine-in timing Updated Successfully")
            } else {
                this.setState({ isLoading: false });
            }
        })
    }

    onPropertyTimingChange = (value, name, index) => {
        let newTimings = [...this.state.property_timing];
        if (name === "close_time") newTimings[index].close_time = dayjs(value, "HH:mm").format("HH:mm");
        if (name === "open_time") newTimings[index].open_time =  dayjs(value, "HH:mm").format("HH:mm");
        if (name === "status") newTimings[index].status = value
        this.setState({
            property_timing: newTimings
        })
    }

    onPropertyTimingChangeAll = (values) => {
        let newTimings = [...this.state.property_timing];
         console.log(newTimings)
        for (let i = 0; i < 7; i++) {
            newTimings[i].status = values['status'];
            if (values['status'] === 'OPEN') {
                newTimings[i].open_time = dayjs(values['open_time'], "HH:mm").format("HH:mm");
                newTimings[i].close_time = dayjs(values['close_time'], "HH:mm").format("HH:mm");
            }
        }

        this.setState({
            property_timing: newTimings
        })
    }

    onDeliveryTimingChange = (value, name, index) => {
        let newTimings = [...this.state.delivery_timings]
        //      if (name === "close_time") newTimings[index].close_time = value
        //       if (name === "open_time") newTimings[index].open_time = value

        console.log(value)
        console.log(this.state.property_timing[index].open_time)
        console.log(this.state.property_timing[index].close_time)
        const op = dayjs(`2000-01-01 ${this.state.property_timing[index].open_time}`);
        const ct = dayjs(`2000-01-01 ${this.state.property_timing[index].close_time}`);
        const tt = dayjs(`2000-01-01 ${dayjs(value, "HH:mm").format("HH:mm")}`);
        if (name === "open_time") {
            if (tt.isSame(op) || tt.isBefore(ct) ) {
              newTimings[index].open_time = dayjs(value, "HH:mm").format("HH:mm");
            }
          }
        
          if (name === "close_time") { 
            if (tt.isSame(ct) || tt.isBefore(ct)) {
              newTimings[index].close_time = dayjs(value, "HH:mm").format("HH:mm");
            }
          }


        this.setState({
            delivery_timings: newTimings
        })
    }

    onDeliveryTimingChangeAll = (values) => {
        let newTimings = [...this.state.delivery_timings];

        for (let i = 0; i < 7; i++) {

            //    newTimings[i].open_time = values['open_time'];
            //    newTimings[i].close_time = values['close_time'];

            const op = dayjs(`2000-01-01 ${this.state.property_timing[i].open_time}`);
            const ct = dayjs(`2000-01-01 ${this.state.property_timing[i].close_time}`);
            const cot = dayjs(`2000-01-01 ${dayjs(values['open_time'], "HH:mm").format("HH:mm")}`);
            const cct = dayjs(`2000-01-01 ${dayjs(values['close_time'], "HH:mm").format("HH:mm")}`);




         /*    if (dayjs(cot).isBefore(dayjs(op))) {
                console.log("Before");
            } else if (dayjs(cot).isAfter(dayjs(op)) && dayjs(cot).isBefore(dayjs(ct))) {
                console.log("After");
                newTimings[i].open_time = dayjs(values['open_time'], "HH:mm").format("HH:mm");
                console.log("Same");
                newTimings[i].open_time = dayjs(values['open_time'], "HH:mm").format("HH:mm");
            }
 */
            if ( cot.isSame(op) || cot.isBefore(ct) ) {
                newTimings[i].open_time = dayjs(values['open_time'], "HH:mm").format("HH:mm");
            }
            
            if ( cct.isBefore(ct) || cct.isSame(ct)) {
                newTimings[i].close_time = dayjs(values['close_time'], "HH:mm").format("HH:mm");
            }

        }

        this.setState({
            delivery_timings: newTimings
        })
    }

    onTakeAwayTimingChange = (value, name, index) => {
        let newTimings = [...this.state.take_away_timing]
        //   if (name === "close_time") newTimings[index].close_time = value
        //   if (name === "open_time") newTimings[index].open_time = value

        console.log(value)
        console.log(this.state.property_timing[index].open_time)
        console.log(this.state.property_timing[index].close_time)
        const op = dayjs(`2000-01-01 ${this.state.property_timing[index].open_time}`);
        const ct = dayjs(`2000-01-01 ${this.state.property_timing[index].close_time}`);
        const tt = dayjs(`2000-01-01 ${dayjs(value, "HH:mm").format("HH:mm")}`);

        if (name === "open_time") {
            if (tt.isSame(op) || tt.isBefore(ct) ) {
              newTimings[index].open_time = dayjs(value, "HH:mm").format("HH:mm");
            }
          }
        
          if (name === "close_time") { 
            if (tt.isSame(ct) || tt.isBefore(ct)) {
              newTimings[index].close_time = dayjs(value, "HH:mm").format("HH:mm");
            }
          }

        this.setState({
            take_away_timing: newTimings
        })
    }

    onTakeAwayTimingChangeAll = (values) => {
        let newTimings = [...this.state.take_away_timing];

        for (let i = 0; i < 7; i++) {

            //      newTimings[i].open_time = values['open_time'];
            //        newTimings[i].close_time = values['close_time'];


            const op = dayjs(`2000-01-01 ${this.state.property_timing[i].open_time}`);
            const ct = dayjs(`2000-01-01 ${this.state.property_timing[i].close_time}`);
            const cot = dayjs(`2000-01-01 ${dayjs(values['open_time'], "HH:mm").format("HH:mm")}`);
            const cct = dayjs(`2000-01-01 ${dayjs(values['close_time'], "HH:mm").format("HH:mm")}`);

            console.log(dayjs(values['open_time'], "HH:mm").format("HH:mm"))
            console.log(dayjs(values['close_time'], "HH:mm").format("HH:mm"))
            console.log(dayjs(this.state.property_timing[i].open_time, "HH:mm").format("HH:mm"))


            if ( cot.isSame(op) || cot.isBefore(ct) ) {
                newTimings[i].open_time = dayjs(values['open_time'], "HH:mm").format("HH:mm");
            }
            
            if ( cct.isBefore(ct) || cct.isSame(ct)) {
                newTimings[i].close_time = dayjs(values['close_time'], "HH:mm").format("HH:mm");
            }



        }

        this.setState({
            take_away_timing: newTimings
        })
    }

    onDineInTimingChange = (value, name, index) => {
        let newTimings = [...this.state.dine_in_timings]
        //     if (name === "close_time") newTimings[index].close_time = value
        //     if (name === "open_time") newTimings[index].open_time = value

        console.log(value)
        console.log(this.state.property_timing[index].open_time)
        console.log(this.state.property_timing[index].close_time)
        const op = dayjs(`2000-01-01 ${this.state.property_timing[index].open_time}`);
        const ct = dayjs(`2000-01-01 ${this.state.property_timing[index].close_time}`);
        const tt = dayjs(`2000-01-01 ${dayjs(value, "HH:mm").format("HH:mm")}`);
        if (name === "open_time") {
            if (tt.isSame(op) || tt.isBefore(ct) ) {
              newTimings[index].open_time = dayjs(value, "HH:mm").format("HH:mm");
            }
          }
        
          if (name === "close_time") { 
            if (tt.isSame(ct) || tt.isBefore(ct)) {
              newTimings[index].close_time = dayjs(value, "HH:mm").format("HH:mm");
            }
          }

        this.setState({
            dine_in_timings: newTimings
        })
    }

    onDineInTimingChangeAll = (values) => {
        let newTimings = [...this.state.dine_in_timings];

        for (let i = 0; i < 7; i++) {

            //    newTimings[i].open_time = values['open_time'];
            //    newTimings[i].close_time = values['close_time'];

            const op = dayjs(`2000-01-01 ${this.state.property_timing[i].open_time}`);
            const ct = dayjs(`2000-01-01 ${this.state.property_timing[i].close_time}`);
            const cot = dayjs(`2000-01-01 ${dayjs(values['open_time'], "HH:mm").format("HH:mm")}`);
            const cct = dayjs(`2000-01-01 ${dayjs(values['close_time'], "HH:mm").format("HH:mm")}`);




            if ( cot.isSame(op) || cot.isBefore(ct) ) {
                newTimings[i].open_time = dayjs(values['open_time'], "HH:mm").format("HH:mm");
            }
            
            if ( cct.isBefore(ct) || cct.isSame(ct)) {
                newTimings[i].close_time = dayjs(values['close_time'], "HH:mm").format("HH:mm");
            }



        }

        this.setState({
            dine_in_timings: newTimings
        })
    }

    render() {
        return (
            <Fragment>
                <div className="ms-content-wrapper Dashboard general_setting_page">
                    <div className="col-md-12">
                        <Breadcrumb />
                    </div>

                    {this.state.isLoading ? <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "40px" }}>   <Loader size={50} /> </div>
                        : <AppTiming state={this.state}
                            updateDeliveryTime={this.updateDeliveryTime}
                            updateDineInTiming={this.updateDineInTime}
                            updatePropertyTime={this.updatePropertyTime}
                            updateTakeAwayTime={this.updateTakeAwayTime}

                            onPropertyChange={this.onPropertyTimingChange}
                            onDineInChange={this.onDineInTimingChange}
                            onDeliveryTimingChange={this.onDeliveryTimingChange}
                            onTakeAwayTimingChange={this.onTakeAwayTimingChange}

                            onPropertyTimingChangeAll={this.onPropertyTimingChangeAll}
                            onDineInTimingChangeAll={this.onDineInTimingChangeAll}
                            onDeliveryTimingChangeAll={this.onDeliveryTimingChangeAll}
                            onTakeAwayTimingChangeAll={this.onTakeAwayTimingChangeAll}
                        />
                    }
                </div>

            </Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    user: state.user,
    propertyId: state.user.propertyId
  });
  
  

  
  export default connect(mapStateToProps, null)(index);