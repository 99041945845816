import React, { Fragment, useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import { toast } from 'react-toastify'
import dayjs from "dayjs";
import TimePicker from "../../components/commonComps/TimePicker";
import { FormControl } from "@mui/material";
export default function AppTiming(props) {
    const {
        delivery_timings,
        dine_in_timings,
        property_timing,
        take_away_timing,
    } = props.state;

    console.log(props.state)

    const [property_timing_daily_status, setPropertyTimingDailyStatus] =
        useState("OPEN");
    const [property_timing_daily_open_time, setPropertyTimingDailyOpenTime] =
        useState(dayjs("00:00",  "HH:mm"));
    const [property_timing_daily_close_time, setPropertyTimingDailyCloseTime] =
        useState(dayjs("23:00",  "HH:mm"));

    const applyDailyPropertyTiming = () => {
        const values = {
            status: property_timing_daily_status,
            open_time: property_timing_daily_open_time,
            close_time: property_timing_daily_close_time,
        };

        if (property_timing_daily_status == "OPEN" && (property_timing_daily_open_time == "" || property_timing_daily_close_time == "")) {
            toast.error("Can't leave open and closed timing as empty when status is open.")
            return false;
        }

        props.onPropertyTimingChangeAll(values);
    };


    const [delivery_timing_daily_open_time, setDeliveryTimingDailyOpenTime] =
        useState(dayjs("00:00",  "HH:mm"));
    const [delivery_timing_daily_close_time, setDeliveryTimingDailyCloseTime] =
        useState(dayjs("23:00",  "HH:mm"));

    const applyDailyDeliveryTiming = () => {
        const values = {
            open_time: delivery_timing_daily_open_time,
            close_time: delivery_timing_daily_close_time,
        };

        if (property_timing_daily_status == "OPEN" && (delivery_timing_daily_open_time == "" || delivery_timing_daily_close_time == "")) {
            toast.error("Can't leave open and closed timing as empty when property status is open.")
            return false;
        }

        props.onDeliveryTimingChangeAll(values);
    };

    const [dine_in_timing_daily_open_time, setDineInTimingDailyOpenTime] =
        useState(dayjs("00:00",  "HH:mm"));
    const [dine_in_timing_daily_close_time, setDineInTimingDailyCloseTime] =
        useState(dayjs("23:00",  "HH:mm"));

    const applyDailyDineInTiming = () => {
        const values = {
            open_time: dine_in_timing_daily_open_time,
            close_time: dine_in_timing_daily_close_time,
        };

        if (property_timing_daily_status == "OPEN" && (dine_in_timing_daily_open_time == "" || dine_in_timing_daily_close_time == "")) {
            toast.error("Can't leave open and closed timing as empty when property status is open.")
            return false;
        }

        props.onDineInTimingChangeAll(values);
    };

    const [take_away_timing_daily_open_time, setTakeAwayTimingDailyOpenTime] =
        useState(dayjs("00:00",  "HH:mm"));
    const [take_away_timing_daily_close_time, setTakeAwayTimingDailyCloseTime] =
        useState(dayjs("23:00",  "HH:mm"));

    const applyDailyTakeAwayTiming = () => {
        const values = {
            open_time: take_away_timing_daily_open_time,
            close_time: take_away_timing_daily_close_time,
        };

        if (property_timing_daily_status == "OPEN" && (take_away_timing_daily_open_time == "" || take_away_timing_daily_close_time == "")) {
            toast.error("Can't leave open and closed timing as empty when property status is open.")
            return false;
        }

        props.onTakeAwayTimingChangeAll(values);
    };

    return (
        <Fragment>
            <div className="ms-panel ms-panel-fh">
                <div className="ms-panel-body">
                    <Accordion
                        defaultActiveKey="0"
                        className="has-gap ms-accordion-chevron"
                    >
                        <Card>
                            <Accordion.Collapse className="collapseparent" eventKey="10">
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label>Day</label>
                                        </div>
                                        <div className="col-md-3">
                                            <label>Open/Close</label>
                                        </div>
                                        <div className="col-md-3">
                                            <label>Open Time</label>
                                        </div>
                                        <div className="col-md-3">
                                            <label>Close Time</label>
                                        </div>
                                    </div>

                                    <div className="row" key={"all"}>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="form-control">Daily</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="input-group">
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    value={property_timing_daily_status}
                                                    onChange={(e) =>
                                                        setPropertyTimingDailyStatus(e.target.value)
                                                    }
                                                >
                                                    <option value="">Select</option>
                                                    <option key="1" value="OPEN">
                                                        OPEN
                                                    </option>
                                                    <option key="2" value="CLOSED">
                                                        CLOSED
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <div className="input-group">
                                                   {/*  <input
                                                        type="time"
                                                        disabled={
                                                            property_timing_daily_status == "CLOSED"
                                                        }
                                                        value={property_timing_daily_open_time}
                                                        onChange={(e) =>
                                                            setPropertyTimingDailyOpenTime(e.target.value)
                                                        }
                                                        className="form-control"
                                                    /> */}
                                                    <FormControl fullWidth>
                                                        <TimePicker
                                                            allowClear={false}
                                                            id="property_timing_daily_open_time"
                                                            name="property_timing_daily_open_time"
                                                            changeOnBlur
                                                            size="small"
                                                            style={{ height: '34px' }}
                                                            label=""
                                                            ampm={false} // Set ampm to false to disable AM/PM selection
                                                            format="HH:mm"
                                                            value={dayjs(property_timing_daily_open_time, "HH:mm")}
                                                            onCalendarChange={(time, timeString) => {
                                                                setPropertyTimingDailyOpenTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                            onChange={(time, timeString) => {
                                                                setPropertyTimingDailyOpenTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                        />
                                                       
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    {/* <input
                                                        type="time"
                                                        className="form-control"
                                                        disabled={
                                                            property_timing_daily_status == "CLOSED"
                                                        }
                                                        value={property_timing_daily_close_time}
                                                        onChange={(e) =>
                                                            setPropertyTimingDailyCloseTime(e.target.value)
                                                        }
                                                    /> */}
                                                      <FormControl fullWidth>
                                                        <TimePicker
                                                            allowClear={false}
                                                            id="property_timing_daily_close_time"
                                                            name="property_timing_daily_close_time"
                                                            changeOnBlur
                                                            label=""
                                                            style={{ height: '34px' }}
                                                            ampm={false} // Set ampm to false to disable AM/PM selection
                                                            format="HH:mm"
                                                            value={dayjs(property_timing_daily_close_time, "HH:mm")}
                                                            onCalendarChange={(time, timeString) => {
                                                                setPropertyTimingDailyCloseTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                            onChange={(time, timeString) => {
                                                                setPropertyTimingDailyCloseTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                        />
                                                       
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <button
                                                onClick={applyDailyPropertyTiming}
                                                className="btn btn-primary d-block float-right mb-3"
                                            >
                                                Apply To All
                                            </button>
                                        </div>
                                    </div>

                                    {property_timing.map((property_timing, i) => (
                                        <div className="row" key={i}>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            value={property_timing.day}
                                                            className="form-control no_caret"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="input-group">
                                                    <select
                                                        className="form-control"
                                                        name="status"
                                                        value={property_timing.status}
                                                        onChange={(e) =>
                                                            props.onPropertyChange(
                                                                e.target.value,
                                                                "status",
                                                                i
                                                            )
                                                        }
                                                    >
                                                        <option value="">Select</option>
                                                        <option key="1" value="OPEN">
                                                            OPEN
                                                        </option>
                                                        <option key="2" value="CLOSED">
                                                            CLOSED
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                      {/*   <input
                                                            type="time"
                                                            disabled={property_timing.status == "CLOSED"}
                                                            value={property_timing.open_time}
                                                            onChange={(e) =>
                                                                props.onPropertyChange(
                                                                    e.target.value,
                                                                    "open_time",
                                                                    i
                                                                )
                                                            }
                                                            className="form-control"
                                                        /> */}
                                                        <FormControl fullWidth>
                                                            <TimePicker
                                                                allowClear={false}
                                                                changeOnBlur
                                                                id={"pOpeningTime"+i}
                                                                name={"pOpeningTime"+i}
                                                                label=""
                                                                ampm={false}
                                                                format="HH:mm"
                                                                style={{ height: '34px' }}
                                                                disabled={property_timing.status === "CLOSED"}
                                                                value={property_timing.open_time ? dayjs(property_timing.open_time, "HH:mm") : null}
                                                                onCalendarChange={(time, timeString) => {
                                                                      // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                      if (timeString) {
                                                                        props.onPropertyChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "open_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onPropertyChange(null, "open_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                                onChange={(time, timeString) => {
                                                                    // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                    if (timeString) {
                                                                        props.onPropertyChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "open_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onPropertyChange(null, "open_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                            />

                                                    </FormControl>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                      {/*   <input
                                                            type="time"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                props.onPropertyChange(
                                                                    e.target.value,
                                                                    "close_time",
                                                                    i
                                                                )
                                                            }
                                                            disabled={property_timing.status == "CLOSED"}
                                                            value={property_timing.close_time}
                                                        /> */}
                                                        <FormControl fullWidth>
                                                            <TimePicker
                                                                allowClear={false}
                                                                id={"pClosingTime" + i}
                                                                name={"pClosingTime" + i}
                                                                changeOnBlur
                                                                label=""
                                                                style={{ height: '34px' }}
                                                                ampm={false}
                                                                format="HH:mm"
                                                                disabled={property_timing.status === "CLOSED"}
                                                                value={property_timing.close_time ? dayjs(property_timing.close_time, "HH:mm") : null}
                                                                onCalendarChange={(time, timeString) => {
                                                                    // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                    if (timeString) {
                                                                        props.onPropertyChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "close_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onPropertyChange(null, "close_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                                onChange={(time, timeString) => {
                                                                    // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                    if (timeString) {
                                                                        props.onPropertyChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "close_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onPropertyChange(null, "close_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                            />

                                                        </FormControl>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <div className="row">
                                        <div className="col-md-12">
                                            <button
                                                onClick={() =>
                                                    props.updatePropertyTime(property_timing)
                                                }
                                                disabled={property_timing.length == 0}
                                                className="btn btn-primary d-block float-right"
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <Accordion.Toggle as={Card.Header} eventKey="10">
                                <span className="has-icon">
                                    {" "}
                                    <i
                                        style={{ marginTop: "5px" }}
                                        class="fas fa-building"
                                    />{" "}
                                    Property Timing{" "}
                                </span>
                            </Accordion.Toggle>
                        </Card>

                        <Card>
                            <Accordion.Collapse className="collapseparent" eventKey="3">
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Day</label>
                                        </div>
                                        <div className="col-md-4">
                                            <label>Open Time</label>
                                        </div>
                                        <div className="col-md-4">
                                            <label>Close Time</label>
                                        </div>
                                    </div>

                                    <div className="row" key={"all"}>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="form-control">Daily</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="input-group">
                                                   {/*  <input
                                                        type="time"
                                                        value={take_away_timing_daily_open_time}
                                                        onChange={(e) =>
                                                            setTakeAwayTimingDailyOpenTime(e.target.value)
                                                        }
                                                        className="form-control"
                                                    /> */}
                                                    <FormControl fullWidth>
                                                        <TimePicker
                                                            allowClear={false}
                                                            id={"take_away_timing_daily_open_time"}
                                                            name={"take_away_timing_daily_open_time"}
                                                            changeOnBlur
                                                            label=""
                                                            style={{ height: '34px' }}
                                                            ampm={false} // Set ampm to false to disable AM/PM selection
                                                            format="HH:mm"
                                                            value={dayjs(take_away_timing_daily_open_time, "HH:mm")}
                                                            onChange={(time, timeString) => {
                                                                setTakeAwayTimingDailyOpenTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                            onCalendarChange={(time, timeString) => {
                                                                setTakeAwayTimingDailyOpenTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="input-group">
                                                  {/*   <input
                                                        type="time"
                                                        className="form-control"
                                                        value={take_away_timing_daily_close_time}
                                                        onChange={(e) =>
                                                            setTakeAwayTimingDailyCloseTime(e.target.value)
                                                        }
                                                    /> */}
                                                     <FormControl fullWidth>
                                                        <TimePicker
                                                            allowClear={false}
                                                            id={"take_away_timing_daily_close_time"}
                                                            name={"take_away_timing_daily_close_time"}
                                                            changeOnBlur
                                                            label=""
                                                            style={{ height: '34px' }}
                                                            ampm={false} // Set ampm to false to disable AM/PM selection
                                                            format="HH:mm"
                                                            value={dayjs(take_away_timing_daily_close_time, "HH:mm")}
                                                            onChange={(time, timeString) => {
                                                                setTakeAwayTimingDailyCloseTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                            onCalendarChange={(time, timeString) => {
                                                                setTakeAwayTimingDailyCloseTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <button
                                                onClick={applyDailyTakeAwayTiming}
                                                className="btn btn-primary d-block float-right mb-3"
                                            >
                                                Apply To All
                                            </button>
                                        </div>
                                    </div>

                                    {take_away_timing.map((tt, i) => (
                                        <div className="row" key={i}>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    {/* <label>Day</label> */}
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            value={tt.day}
                                                            className="form-control no_caret"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    {/* <label>Open Time</label> */}
                                                    <div className="input-group">
                                                        {/* <input
                                                            type="time"
                                                            onChange={(e) =>
                                                                props.onTakeAwayTimingChange(
                                                                    e.target.value,
                                                                    "open_time",
                                                                    i
                                                                )
                                                            }
                                                            value={tt.open_time}
                                                            className="form-control"
                                                        /> */}
                                                        <FormControl fullWidth>
                                                            <TimePicker
                                                                allowClear={false}
                                                                id={"take_away_timing_daily_open_time" +i}
                                                                name={"take_away_timing_daily_open_time" +i}
                                                                changeOnBlur
                                                                label=""
                                                                ampm={false}
                                                                style={{ height: '34px' }}
                                                                format="HH:mm"
                                                                disabled={property_timing[i].open_time == "" ? true : false}
                                                                value={tt.open_time ? dayjs(tt.open_time, "HH:mm") : null}
                                                                onChange={(time, timeString) => {
                                                                    // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                    if (timeString) {
                                                                        props.onTakeAwayTimingChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "open_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onTakeAwayTimingChange(null, "open_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                                onCalendarChange={(time, timeString) => {
                                                                    // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                    if (timeString) {
                                                                        props.onTakeAwayTimingChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "open_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onTakeAwayTimingChange(null, "open_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                            />
                                                            </FormControl>
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    {/* <label>Close Time</label> */}
                                                    <div className="input-group">
                                                       {/*  <input
                                                            type="time"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                props.onTakeAwayTimingChange(
                                                                    e.target.value,
                                                                    "close_time",
                                                                    i
                                                                )
                                                            }
                                                            value={tt.close_time}
                                                        /> */}
                                                         <FormControl fullWidth>
                                                            <TimePicker
                                                                allowClear={false}
                                                                id={"take_away_timing_daily_close_time" + i}
                                                                name={"take_away_timing_daily_close_time" + i}
                                                                changeOnBlur
                                                                label=""
                                                                style={{ height: '34px' }}
                                                                ampm={false}
                                                                disabled={property_timing[i].close_time == "" ? true : false}
                                                                format="HH:mm"
                                                                value={tt.close_time ? dayjs(tt.close_time, "HH:mm") : null}
                                                                onChange={(time, timeString) => {
                                                                    // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                    if (timeString) {
                                                                        props.onTakeAwayTimingChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "close_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onTakeAwayTimingChange(null, "open_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                                onCalendarChange={(time, timeString) => {
                                                                    // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                    if (timeString) {
                                                                        props.onTakeAwayTimingChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "close_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onTakeAwayTimingChange(null, "close_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                            />
                                                            </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button
                                                className="btn btn-primary d-block float-right"
                                                onClick={() =>
                                                    props.updateTakeAwayTime(take_away_timing)
                                                }
                                                disabled={take_away_timing.length == 0}
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <Accordion.Toggle as={Card.Header} eventKey="3">
                                <span className="has-icon">
                                    {" "}
                                    <i className="flaticon-conversation" /> Take Away Timing{" "}
                                </span>
                            </Accordion.Toggle>
                        </Card>

                        <Card>
                            <Accordion.Collapse className="collapseparent" eventKey="1">
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Day</label>
                                        </div>
                                        <div className="col-md-4">
                                            <label>Open Time</label>
                                        </div>
                                        <div className="col-md-4">
                                            <label>Close Time</label>
                                        </div>
                                    </div>

                                    <div className="row" key={"all"}>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="form-control">Daily</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="input-group">
                                                   {/*  <input
                                                        type="time"
                                                        value={delivery_timing_daily_open_time}
                                                        onChange={(e) =>
                                                            setDeliveryTimingDailyOpenTime(e.target.value)
                                                        }
                                                        className="form-control"
                                                    /> */}
                                                     <FormControl fullWidth>
                                                        <TimePicker
                                                            allowClear={false}
                                                            id={"delivery_timing_daily_open_time"}
                                                            name={"delivery_timing_daily_open_time"}
                                                            changeOnBlur
                                                            label=""
                                                            style={{ height: '34px' }}
                                                            ampm={false} // Set ampm to false to disable AM/PM selection
                                                            format="HH:mm"
                                                            value={dayjs(delivery_timing_daily_open_time, "HH:mm")}
                                                            onChange={(time, timeString) => {
                                                                setDeliveryTimingDailyOpenTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                            onCalendarChange={(time, timeString) => {
                                                                setDeliveryTimingDailyOpenTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="input-group">
                                                  {/*   <input
                                                        type="time"
                                                        className="form-control"
                                                        value={delivery_timing_daily_close_time}
                                                        onChange={(e) =>
                                                            setDeliveryTimingDailyCloseTime(e.target.value)
                                                        }
                                                    /> */}
                                                          <FormControl fullWidth>
                                                        <TimePicker
                                                            allowClear={false}
                                                            id={"delivery_timing_daily_close_time"}
                                                            name={"delivery_timing_daily_close_time"}
                                                            changeOnBlur
                                                            label=""
                                                            style={{ height: '34px' }}
                                                            ampm={false} // Set ampm to false to disable AM/PM selection
                                                            format="HH:mm"
                                                            value={dayjs(delivery_timing_daily_close_time, "HH:mm")}
                                                            onChange={(time, timeString) => {
                                                                setDeliveryTimingDailyCloseTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                            onCalendarChange={(time, timeString) => {
                                                                setDeliveryTimingDailyCloseTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <button
                                                onClick={applyDailyDeliveryTiming}
                                                className="btn btn-primary d-block float-right mb-3"
                                            >
                                                Apply To All
                                            </button>
                                        </div>
                                    </div>

                                    {delivery_timings.map((dt, i) => (
                                        <div className="row" key={i}>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            value={dt.day}
                                                            className="form-control no_caret"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        {/* <input
                                                            type="time"
                                                            onChange={(e) =>
                                                                props.onDeliveryTimingChange(
                                                                    e.target.value,
                                                                    "open_time",
                                                                    i
                                                                )
                                                            }
                                                            value={dt.open_time}
                                                            className="form-control"
                                                        /> */}

                                                        <FormControl fullWidth>
                                                            <TimePicker
                                                                allowClear={false}
                                                                id={"delivery_timing_daily_open_time"+i}
                                                                name={"delivery_timing_daily_open_time"+i}
                                                                changeOnBlur
                                                                label=""
                                                                ampm={false}
                                                                style={{ height: '34px' }}
                                                                disabled={property_timing[i].open_time == "" ? true : false}
                                                                format="HH:mm"
                                                                value={dt.open_time ? dayjs(dt.open_time, "HH:mm") : null}
                                                                onChange={(time, timeString) => {
                                                                    // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                    if (timeString) {
                                                                        props.onDeliveryTimingChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "open_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onDeliveryTimingChange(null, "open_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                                onCalendarChange={(time, timeString) => {
                                                                    // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                    if (timeString) {
                                                                        props.onDeliveryTimingChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "open_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onDeliveryTimingChange(null, "open_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                       {/*  <input
                                                            type="time"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                props.onDeliveryTimingChange(
                                                                    e.target.value,
                                                                    "close_time",
                                                                    i
                                                                )
                                                            }
                                                            value={dt.close_time}
                                                        /> */}
                                                        <FormControl fullWidth>
                                                            <TimePicker
                                                                allowClear={false}
                                                                id={"delivery_timing_daily_close_time" + i}
                                                                name={"delivery_timing_daily_close_time" + i}
                                                                changeOnBlur
                                                                label=""
                                                                ampm={false}
                                                                format="HH:mm"
                                                                style={{ height: '34px' }}
                                                                disabled={property_timing[i].close_time == "" ? true : false}
                                                                value={dt.close_time ? dayjs(dt.close_time, "HH:mm") : null}
                                                                onChange={(time, timeString) => {
                                                                    // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                    if (timeString) {
                                                                        props.onDeliveryTimingChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "close_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onDeliveryTimingChange(null, "close_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                                onCalendarChange={(time, timeString) => {
                                                                    // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                    if (timeString) {
                                                                        props.onDeliveryTimingChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "close_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onDeliveryTimingChange(null, "close_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                                
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button
                                                onClick={() =>
                                                    props.updateDeliveryTime(delivery_timings)
                                                }
                                                disabled={delivery_timings.length == 0}
                                                className="btn btn-primary d-block float-right"
                                                type="button"
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                <span className="has-icon">
                                    {" "}
                                    <i className="flaticon-email" /> Delivery Timing{" "}
                                </span>
                            </Accordion.Toggle>
                        </Card>

                        <Card>
                            <Accordion.Collapse className="collapseparent" eventKey="2">
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Day</label>
                                        </div>
                                        <div className="col-md-4">
                                            <label>Open Time</label>
                                        </div>
                                        <div className="col-md-4">
                                            <label>Close Time</label>
                                        </div>
                                    </div>

                                    <div className="row" key={"all"}>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="form-control">Daily</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    {/* <input
                                                        type="time"
                                                        value={dine_in_timing_daily_open_time}
                                                        onChange={(e) =>
                                                            setDineInTimingDailyOpenTime(e.target.value)
                                                        }
                                                        className="form-control"
                                                    /> */}
                                                    <FormControl fullWidth>
                                                        <TimePicker
                                                            allowClear={false}
                                                            id={"dine_in_timing_daily_open_time"}
                                                            name={"dine_in_timing_daily_open_time"}
                                                            changeOnBlur
                                                            label=""
                                                            style={{ height: '34px' }}
                                                            ampm={false} // Set ampm to false to disable AM/PM selection
                                                            format="HH:mm"
                                                            value={dayjs(dine_in_timing_daily_open_time, "HH:mm")}
                                                            onChange={(time, timeString) => {
                                                                setDineInTimingDailyOpenTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                            onCalendarChange={(time, timeString) => {
                                                                setDineInTimingDailyOpenTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="input-group">
                                                   {/*  <input
                                                        type="time"
                                                        className="form-control"
                                                        value={dine_in_timing_daily_close_time}
                                                        onChange={(e) =>
                                                            setDineInTimingDailyCloseTime(e.target.value)
                                                        }
                                                    /> */}
                                                    <FormControl fullWidth>
                                                        <TimePicker
                                                            allowClear={false}
                                                            id={"dine_in_timing_daily_close_time"}
                                                            name={"dine_in_timing_daily_close_time"}
                                                            changeOnBlur
                                                            label=""
                                                            style={{ height: '34px' }}
                                                            ampm={false} // Set ampm to false to disable AM/PM selection
                                                            format="HH:mm"
                                                            value={dayjs(dine_in_timing_daily_close_time, "HH:mm")}
                                                            onChange={(time, timeString) => {
                                                                setDineInTimingDailyCloseTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                            onCalendarChange={(time, timeString) => {
                                                                setDineInTimingDailyCloseTime(dayjs(timeString, "HH:mm"));
                                                            }}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <button
                                                onClick={applyDailyDineInTiming}
                                                className="btn btn-primary d-block float-right mb-3"
                                            >
                                                Apply To All
                                            </button>
                                        </div>
                                    </div>

                                    {dine_in_timings.map((dit, i) => (
                                        <div className="row" key={i}>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            value={dit.day}
                                                            className="form-control no_caret"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                      {/*   <input
                                                            type="time"
                                                            onChange={(e) =>
                                                                props.onDineInChange(
                                                                    e.target.value,
                                                                    "open_time",
                                                                    i
                                                                )
                                                            }
                                                            value={dit.open_time}
                                                            className="form-control"
                                                        /> */}
                                                         <FormControl fullWidth>
                                                            <TimePicker
                                                                allowClear={false}
                                                                id={"dine_in_timing_daily_open_time"+i}
                                                                name={"dine_in_timing_daily_open_time"+i}
                                                                changeOnBlur
                                                                label=""
                                                                ampm={false}
                                                                format="HH:mm"
                                                                disabled={property_timing[i].open_time == "" ? true : false}
                                                                style={{ height: '34px' }}
                                                                value={dit.open_time ? dayjs(dit.open_time, "HH:mm") : null}
                                                                onChange={(time, timeString) => {
                                                                    // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                    if (timeString) {
                                                                        props.onDineInChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "open_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onDineInChange(null, "open_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                                onCalendarChange={(time, timeString) => {
                                                                    // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                    if (timeString) {
                                                                        props.onDineInChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "open_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onDineInChange(null, "open_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                       {/*  <input
                                                            type="time"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                props.onDineInChange(
                                                                    e.target.value,
                                                                    "close_time",
                                                                    i
                                                                )
                                                            }
                                                            value={dit.close_time}
                                                        /> */}
                                                         <FormControl fullWidth>
                                                            <TimePicker
                                                                allowClear={false}
                                                                changeOnBlur
                                                                id={"dine_in_timing_daily_close_time"+i}
                                                                name={"dine_in_timing_daily_close_time"+i}
                                                                label=""
                                                                ampm={false}
                                                                style={{ height: '34px' }}
                                                                format="HH:mm"
                                                                disabled={property_timing[i].close_time == "" ? true : false}
                                                                value={dit.close_time ? dayjs(dit.close_time, "HH:mm") : null}
                                                                onChange={(time, timeString) => {
                                                                    // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                    if (timeString) {
                                                                        props.onDineInChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "close_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onDineInChange(null, "close_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                                onCalendarChange={(time, timeString) => {
                                                                    // Check if timeString is defined to avoid errors when the clear button is clicked
                                                                    if (timeString) {
                                                                        props.onDineInChange(
                                                                            dayjs(timeString, "HH:mm"), // Use moment() to parse the time string
                                                                            "close_time",
                                                                            i
                                                                        );
                                                                    } else {
                                                                        props.onDineInChange(null, "close_time", i); // Handle clearing the time
                                                                    }
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <div className="row">
                                        <div className="col-md-12">
                                            <button
                                                onClick={() =>
                                                    props.updateDineInTiming(dine_in_timings)
                                                }
                                                className="btn btn-primary d-block float-right"
                                                disabled={dine_in_timings.length == 0}
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                                <span className="has-icon">
                                    {" "}
                                    <i
                                        style={{ marginTop: "5px" }}
                                        class="fab fa-first-order"
                                    />{" "}
                                    Dine In Timing{" "}
                                </span>
                            </Accordion.Toggle>
                        </Card>
                    </Accordion>
                </div>
            </div>
        </Fragment>
    );
}
