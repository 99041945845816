import React, { Component } from 'react';
import { getItems, deleteItem } from '../../../services/inventory/inventoryService';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from "react-redux";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DataTable from 'react-data-table-component';
class ItemList extends Component {
  state = {
    items: []
  };

  componentDidMount() {
    this.fetchItems();
  }

  fetchItems = () => {
    getItems(this.props.propertyId).then(response => {
      this.setState({ items: response.data ?  response.data.data : [] });
    });
  };

  handleDelete = (id) => {
    deleteItem(id).then(() => {
      this.fetchItems();
    });
  };

  render() {
    const { items } = this.state;
    const { onEdit } = this.props;

    const columns = [
      {
        name: 'Stock Name',
        cell: row => (
          <div style={{ fontWeight: 'bold' }}>
            {row.stock_name}
          </div>
        ),
        sortable: true,
      },
      {
        name: 'Qty.',
        cell: row => (
          <div style={{ fontWeight: 'bold' }}>
            {row.quantity}
          </div>
        ),
        sortable: true,
      },
      {
        name: 'Min. Stock',
        cell: row => (
          <div style={{ fontWeight: 'bold' }}>
            { row.min_quantity}
          </div>
        ),
        sortable: true,
      },
      {
        name: 'Max. Stock',
        cell: row => (
          <div style={{ fontWeight: 'bold' }}>
            { row.max_quantity}
          </div>
        ),
        sortable: true,
      },
      {
        name: 'Actions',
        width: "150px",
        cell: row => (
          <>
           <Button  fullWidth startIcon={<RemoveRedEyeIcon color='primary'/>} onClick={() => onEdit(row)}>View</Button>
          </>
        ),
      },
    ];

    const customStyles = {
      headCells: {
        style: {
          color: 'white', // Change the header text color to white
          fontWeight: 'bold', // Make the header text bold
        },
      },
    };

    return (
      <DataTable
      columns={columns}
      data={items}
      customStyles={customStyles} // Apply custom styles
      pagination
      highlightOnHover
      pointerOnHover
    />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
  username: state.user.username,
  settings: state.user.settings,
});


const mapDispatchToProps = {};


export default  connect(mapStateToProps, mapDispatchToProps)(ItemList);
