import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import * as PaymentServiceAPI from '../../services/payment/PaymentServiceAPI'
import Pagination from "../../shared/Pagination/Pagination";
import Loader from "../../shared/Loader";
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { Button, IconButton } from "@mui/material";
import DownloadingIcon from '@mui/icons-material/Downloading';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from "react-toastify";
import { connect } from "react-redux";
import NativeSelect from '@mui/material/NativeSelect';
import ReactGA from 'react-ga';
import { Grid } from '@mui/material';
import $ from 'jquery';
import { withRouter} from 'react-router-dom';
import {getCurrencySymbol} from "../../config";


class Content extends Component {

    state = {

        property_id: "",
        page: 0,
        data: [],
        current_page: 0,
        current_data_page: 0,
        number_of_elements: 15,
        page_size: 15,
        total_elements: 0,
        total_pages: 0,
        total_data_pages: 0,
        isLoading: true,
        count: 0,

    }



    componentDidMount = () => {
        function setActiveMenuItem() {

            // get elements that are active and remove their active class
            var activeItem = $(".active");
            activeItem.removeClass("active");
      
            $(this).removeAttr("href");
            var listitems = $(this).parent("li");
            var element = listitems.children();
            localStorage.setItem("activeNavItem", element.attr("id"));
            if (element.hasClass("active")) {
                element.removeClass("active");
                element.find("li").removeClass("active");
                listitems.find(".collapse").slideUp();
            } else {
                element.addClass("active");
                listitems.children(".collapse").slideDown();
                listitems.siblings("li").children(".collapse").slideUp();
                element.siblings("li").removeClass("active");
                element.siblings("li").find("li").removeClass("active");
                listitems.siblings("li").find(".collapse").slideUp();
            }
      
        }
        setActiveMenuItem();
        if (!localStorage.getItem("activeNavItem")) {
            let pathArray = window.location.pathname.split("/");
            let currentPage = pathArray[1];
            $("#" + currentPage).addClass("active");
        } else {
            let pathArray = window.location.pathname.split("/");
            let currentPage = pathArray[1];
            $("#" + currentPage).addClass("active");
        }
        ReactGA.pageview(window.location.pathname + window.location.search);
        let prop_id = this.props.propertyId;
        console.log(prop_id)
        if (prop_id) {
            this.setState({ property_id: prop_id }, this.filterAllPayouts());
        } else {
            this.setState({ isLoading: false });
        }

    }

    fetchPayoutDetailsOnRowClick = (row) => {
        const payload = {
            property_id: this.props.propertyId,
            row: row
        }
        this.props.history.push({
            pathname: '/payoutdetails',
            state: payload
        })
    }

    handlePagination = (e) => {
        console.log(e?.target?.value)
        this.setState({ number_of_elements: parseInt(e.target.value) }, () => {
            this.filterAllPayouts();
        });

    };


    payoutHandleChange = (event, value) => {
        event.preventDefault();
        const page = value - 1;
        this.setState({ current_page: value });
        const payload = {
            page: page,
            size: this.state.number_of_elements,
            property_id: this.props.propertyId
        };
        this.fetchAllPayouts(payload);
    };


    filterAllPayouts = (payload) => {
        const property = this.props.propertyId;
        this.setState({ property: property })
        if (property) {
            const payload = {
                property_id: property,
                page: this.state.page,
                size: this.state.number_of_elements,
            }

            PaymentServiceAPI.fetchPayouts(payload).then(response => {
                if (response.data.status) {
                    //          console.log(response.data.data.payout_details);
                    let sortedPayouts = response.data.data.payout_details.sort(function (a, b) {
                        var c = new Date(b.created);
                        var d = new Date(a.created);
                        return c - d;
                    });
                    this.setState({
                        data: sortedPayouts,
                        total_elements: response.data.data.total_elements,
                        total_pages: response.data.data.total_pages,
                        current_page: response.data.data.current_page + 1,
                        page_size: response.data.data.page_size,
                        isLoading: false,
                        count: sortedPayouts.length
                    });

                } else {
                    console.log(response.data.message);
                    // toast.error('Please Activate Payment Service')
                    this.setState({
                        data: [],
                        current_page: 0,
                        total_pages: 0,
                        total_elements: 0,
                        isLoading: false,
                        count: 0
                    })
                }
            }).catch(err => {
                toast.warning('Error : ', err);
                console.log('Error: ', err)
                this.setState({
                    isLoading: false
                });
            });
        }
    };

    fetchAllPayouts = (payload) => {
        PaymentServiceAPI.fetchPayouts(payload).then((response) => {
            if (response.data.status) {
                console.log(response.data.data.data)
                let sortedPayouts = response.data.data.payout_details.sort(function (a, b) {
                    var c = new Date(b.created);
                    var d = new Date(a.created);
                    return c - d;
                });
                this.setState({
                    data: sortedPayouts,
                    total_elements: response.data.data.total_elements,
                    total_pages: response.data.data.total_pages,
                    current_page: response.data.data.current_page + 1,
                    page_size: response.data.data.page_size,
                    isLoading: false,
                    count: sortedPayouts.length
                });

            } else {
                this.setState({
                    data: [],
                    total_elements: 0,
                    total_pages: 0,
                    current_page: 0,
                    page_size: 0,
                    isLoading: false,
                    count: 0
                });
            }
        });
    };

    fetchPayoutById = (row) => {
        const payload = {
            row: row
        }
        this.props.history.push({
            pathname: '/payoutdetails',
            state: payload
        })
    };


    fetchPayoutReport = (row) => {

        const payload = {
            payout_id: row.payout_id

        }


        PaymentServiceAPI.fetchPayoutReports(payload).then((response) => {
            const file = new Blob([response.data], { type: 'application/pdf' });
            console.log(response)
            const fileURL = URL.createObjectURL(file);//Open the URL on new Window
            window.open(fileURL);
            this.downloadFile(fileURL);
            //   window.open(fileURL);
        }).catch(error => {
            console.log(error);
        });;



    };

    downloadFile(filePath) {
        var link = document.createElement('a');
        link.href = filePath;
        link.download = "invoice";
        link.click();
    }

    render() {


        const customStyles = {
            rows: {
                style: {
                    minHeight: "40px", // override the row height
                }
            },
            headCells: {
                style: {
                    //                  minHeight: '65px',
                    justifyContent: 'center',
                    textAlign: 'center',
                    backgroundColor: '#5773ff',
                    fontSize: '14px',
                    color: 'white',
                    paddingRight: "0px"

                    //    paddingLeft: '32px', // override the cell padding for data cells
                    //    paddingRight: '0px',

                },
            },
            cells: {
                style: {
                    //      paddingLeft: '16px', // override the cell padding for data cells
                    //     paddingRight: '0px',
                    pointer: 'cursor',
                    justifyContent: 'center',
                    textAlign: 'center',
                    paddingRight: "0px"
                },
            },
        };

        const columns = [


            {
                name: 'CREATED',
                selector: row => dayjs(row.created).format('DD-MM-YYYY'),
                sortable: false,
            },


            /*       {
                      name: 'GROSS AMOUNT',
                      selector: "total",
                      sortable: true,
                      cell: (row) => (
                          <div className=""  onClick={() => this.fetchOrderDetailsByOrderId(row)} >
                              £ {Math.abs(row.total)}
                          </div>
                      ),
                  },
      
                  {
                      name: 'FEE',
                      selector: "fee",
                      sortable: true,
                      cell: (row) => (
                          <div className=""  onClick={() => this.fetchOrderDetailsByOrderId(row)} >
                              £ {Math.abs(row.fee)}
                          </div>
                      ),
                  }, */
            {
                name: 'TOTAL',
                selector: "amount",
                sortable: false,
                cell: (row) => (
                    <div className="" /* onClick={() => this.fetchOrderDetailsByOrderId(row)} */>
                        <b> {getCurrencySymbol(localStorage.getItem('currency_code')) + Math.abs(row.total_amount)} payout by Kayana </b>
                    </div>
                ),
            },




            {
                name: 'BANK ACCOUNT',
                selector: row => row.bank_account_last_4_digit,
            },


            {
                name: 'PAYOUT ID',
                selector: row => row.payout_id,
            },

            {
                name: "STATUS",
                selector: "status",
                width: "150px",
                selector: row => row.status == "booked" ? <div className="type-margin">
                    <div > <p style={{}} className="badge badge-take-away badge-size">PAID</p> </div> </div> : "",
            },
            /*  {
                 name: 'Transfer Id',
                 selector: row => row.transferId,
             }, */


            {
                name: "ACTIONS",
                cell: (row) => (
                    <div>

                        {/*   <i
                                className="fas far fa-eye text-secondary text-success"
                                onClick={() => this.fetchPayoutById(row)}
                                style={{
                                    marginLeft: "25px",
                                    cursor: "pointer",
                                    fontSize: "15px",
                                }}
                            /> */}

                        <Stack direction="row" spacing={1} >
                            {/* <IconButton color="primary" aria-label="view" onClick={() => this.fetchPayoutById(row)}>
                                <VisibilityIcon />
                            </IconButton>

                            &nbsp; */}
                            {/* <IconButton color="primary" aria-label="download" onClick={() => this.fetchPayoutReport(row)}>
                                <DownloadingIcon color="primary" />
                            </IconButton> */}
                            <Button variant='outlined' startIcon={<DownloadingIcon color="primary" />} onClick={() => this.fetchPayoutReport(row)} sx={{alignItems:"flex-start"}}>Download</Button>
                        </Stack>

                        {/* <i className="fas fa-pencil-alt text-secondary" /> */}
                        {/* <i className="far fa-trash-alt ms-text-danger" onClick={() => this.rejectTableRequest(row)} style={{ marginRight: '15px', cursor: 'pointer', fontSize: '15px' }} /> */}

                    </div>
                ),
            },

        ];

        return (
            <div className="ms-content-wrapper">

                {/* <Breadcrumb /> */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="ms-panel">
                            {/* <div className="ms-panel-header">
                                <h6>Payouts</h6>
                            </div> */}
                            <div className="ms-panel-body">

                                {/* <div className="">


                                    <div className="col-md-3" style={{ display: "flex", alignItems: "center" }}>
                                        <div className="">
                                            No of Rows :  {this.state.count}
                                        </div>
                                    </div>
                                </div> */}

                                <div className="table-responsive">

                                    {/* <MDBDataTable className="thead-primary" striped onClick={this.state.fetchPayoutDetailsOnRowClick} hover data={data} /> */}


                                    {this.state.isLoading ? <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "40px" }}>   <Loader size={50} /> </div> :

                                        <React.Fragment>
                                            <DataTable
                                                columns={columns}
                                                data={this.state.data}
                                                state={this.state.data}
                                                customStyles={customStyles}
                                                handleChange={this.changeCurrentPage}
                                            />



                                            <div className="row" style={{ float: "right", margin: "25px" }}>
                                                {this.state.total_pages > 0 &&
                                                    <React.Fragment> 
                                                        <span style={{ position: "relative", right: "10px", top: "5px" }}  > Rows Per Page</span>
                                                        <Grid container spacing={1}>
                                                                {/* First Grid Item */}
                                                                <Grid item xs={12} lg={4}>
                                                        <FormControl >
                                                            <NativeSelect
                                                                defaultValue={this.state.number_of_elements}
                                                                onChange={this.handlePagination}
                                                                inputProps={{
                                                                    name: 'Pagination',
                                                                    id: 'uncontrolled-native',
                                                                }}
                                                            >
                                                                <option value={10}>10</option>
                                                                <option value={15}>15</option>
                                                                <option value={20}>20</option>
                                                                <option value={50}>50</option>
                                                                <option value={100}>100</option>
                                                                <option value={500}>500</option>
                                                                <option value={1000}>1000</option>
                                                            </NativeSelect>
                                                        </FormControl>
                                                        </Grid>
                                                        <Pagination
                                                            currentPage={this.state.current_page}
                                                            total_pages={this.state.total_pages}
                                                            state={this.state.data}
                                                            sibCount={0}
                                                            handleChange={this.payoutHandleChange}
                                                        />  
                                                         </Grid>
                                                        </React.Fragment>}

                                            </div>

                                        </React.Fragment>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    propertyId: state.user.propertyId
});

export default withRouter(connect(mapStateToProps, {})(Content));
