import React, { Component, Fragment } from "react";
import * as OrderServiceAPI from "../../../services/order/OrderServiceAPI";
import * as DashboardServiceAPI from '../../../services/dashboard/DashboardServiceAPI'
import * as ReportServiceAPI from '../../../services/reports/ReportServiceAPI'
import DataTableComponet from 'react-data-table-component';
import Pagination from "../../../shared/Pagination/Pagination";
import Breadcrumb from "./Breadcrumb";
import '../../../index.css';
import { toast } from "react-toastify";
import DataTable from 'react-data-table-component';
import { IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Loader from "../../../shared/Loader";
import $ from "jquery";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DownloadingIcon from '@mui/icons-material/Downloading';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import ReactDOM from 'react-dom';
import { Line } from '@ant-design/plots';
import { Column } from '@ant-design/plots';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemText, Button, Paper } from '@mui/material';
import { ItemList } from './ItemList/ItemList.js'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Box from '@mui/material/Box';
import Papa from 'papaparse';
import { withStyles } from '@mui/material/styles';
import { connect } from "react-redux";
import ReactGA from 'react-ga';
import {getCurrencySymbol} from "../../../config";   
const styles = theme => ({
    notice: {
        marginTop: theme.spacing(4),
        backgroundColor: '#f5f5f5',
        padding: theme.spacing(2),
        borderRadius: '4px',
    },
});
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={1}>{children}</Box>}
        </div>
    );
}


class Content extends Component {

    customStyles = {
        rows: {
            style: {
                //              minHeight: '70px', // override the row height
            }
        },
        headCells: {
            style: {
                //                   minHeight: '65px',

                backgroundColor: '#5773ff',
                fontSize: '14px',
                color: 'white',
                justifyContent: "center",
                '&:hover div': {
                    backgroundColor: 'transparent',
                    color: 'white !important'
                },
                'div': {
                    color: 'white !important'
                },
                'span': {
                    color: 'white !important'
                },

            },
        },
        cells: {
            style: {

                pointer: 'cursor',

            },
        },
    };

    customHeader = ({ column }) => {
        return (
            <div style={{ textAlign: column.center ? "center" : "" }}>
                {column.name}
            </div>
        );
    };

    order_list_columns = [

        {
            name: 'Item Name',
            selector: 'Item Name',
            sortable: true,
            cell: (row) => (
                <div style={{ cursor: "pointer" }} >
                    <div>
                        {row['Item Name']}
                    </div>
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },

        },
        {
            name: 'Item Description',
            selector: 'Item Description',
            sortable: true,
            cell: (row) => (
                <div style={{ cursor: "pointer" }} >
                    <div>
                        {row['Item Description']}
                    </div>
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },

        },
        {
            name: 'No of items',
            selector: 'Item Count',
            sortable: true,
            cell: (row) => (
                <div style={{ cursor: "pointer" }} >
                    <div>
                        {row['Item Count']}
                    </div>
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },

        },
        {
            name: 'Total Sales',
            selector: 'Total Sales',
            sortable: true,
            cell: (row) => (
                <div className="" /* onClick={() => this.fetchOrderDetailsByOrderId(row)} */>
                 {row['Total Sales']}
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },
        }


    ];

    order_list_column = [

        {
            name: 'Date',
            selector: 'Date',
            sortable: true,
            cell: (row) => (
                <div style={{ cursor: "pointer" }} >
                    <div>
                        {row['Date']}
                    </div>
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },

        },


        {
            name: 'Sales',
            selector: 'Sales',
            sortable: true,
            cell: (row) => (
                <div className="" /* onClick={() => this.fetchOrderDetailsByOrderId(row)} */>
                 {getCurrencySymbol(localStorage.getItem('currency_code')) + row['Sales']}
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },
        }


    ];
    state = {
        page: 0,
        order_details: [],
        current_page: 0,
        current_orders_page: 0,
        number_of_elements: 15,
        page_size: 10,
        total_elements: 0,
        total_pages: 0,
        total_order_pages: 0,
        property_id: "",
        search_text: "",
        changeBool: null,
        from_date: null,
        to_date: null,
        date_options_dd: null,
        isLoading: false,
        count: 0,
        hideFilter: false,
        start: dayjs(new Date()).startOf('month'),
        end: dayjs(new Date()),
        data: [],
        topSellingItems: [],
        trendingItemsSalesReport: [],
        unSoldItems: [],
        currentPage: 1,
        itemsPerPage: 10,
        tab: 0,
        selectTimePeriod: "DAILY",
        selectReport: "Item"

    };

    constructor(props) {
        super(props);
        // this.onChangeHandler = this.onChangeHandler.bind(this);
    }


    componentDidMount = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    
        function setActiveMenuItem() {

            // get elements that are active and remove their active class
            var activeItem = $(".active");
            activeItem.removeClass("active");

            $(this).removeAttr("href");
            var listitems = $(this).parent("li");
            var element = listitems.children();
            localStorage.setItem("activeNavItem", element.attr("id"));
            if (element.hasClass("active")) {
                element.removeClass("active");
                element.find("li").removeClass("active");
                listitems.find(".collapse").slideUp();
            } else {
                element.addClass("active");
                listitems.children(".collapse").slideDown();
                listitems.siblings("li").children(".collapse").slideUp();
                element.siblings("li").removeClass("active");
                element.siblings("li").find("li").removeClass("active");
                listitems.siblings("li").find(".collapse").slideUp();
            }

        }
        setActiveMenuItem();
        if (!localStorage.getItem("activeNavItem")) {
            let pathArray = window.location.pathname.split("/");
            let currentPage = pathArray[1];
            $("#reports").addClass("active");
            $("#" + currentPage).addClass("active");
            $("#reports").addClass("active");
        } else {
            let pathArray = window.location.pathname.split("/");
            let currentPage = pathArray[1];
            $("#reports").addClass("active");
            $("#" + currentPage).addClass("active");
            $("#reports").addClass("active");
        }
        //    this.totalSalesReport();

        //  this.getColumnGraph();
        //  this.getUnSoldItems();

        /*    this.setState({ selectTimePeriod: "DAILY" }, () => {
               this.getTrendingSalesReport();
   
           }); */

    }

    handleExportCSV = () => {
        // Convert data to CSV format

        // Create a CSV file from the CSV data
        const csvString = Papa.unparse(this.state.data);

        // Create a data URL for the CSV file
        const dataUrl = `data:text/csv;charset=utf-8,${encodeURIComponent(csvString)}`;

        // Create a temporary anchor element to trigger the download
        const downloadLink = document.createElement('a');
        downloadLink.href = dataUrl;
        downloadLink.download = 'Sales.csv';
        downloadLink.click();
    };




    fetchFrequentOrders = () => {

        this.setState({ isLoading: true });

        const from = dayjs(this.state.start).format('DD-MM-YYYY')
        const to = dayjs(this.state.end).format('DD-MM-YYYY')
        console.log(from)
        const payload = {
            from: from,
            to: to,
            property_id: this.props.propertyId
        };




        DashboardServiceAPI.fetchFrequentOrders(payload)
            .then((response) => {
                console.log(response.data.status);
                if (response.data.status) {
                    console.log(response.data.data);
                    let frequentOrders = response.data.data.sort((a, b) => b.sold_out_quantity - a.sold_out_quantity);
                    this.setState({ order_details: frequentOrders });
                    this.setState({ isLoading: false });
                } else {
                    //     toast.error(response.data.message);
                    this.setState({ isLoading: false, order_details: [] });
                }
            })
            .catch((error) => {
                //  this.setState({ loading: false, show: false });
                this.setState({ isLoading: false });
                console.log(error);
            });

    }


    fnCallback = (e) => {
        console.log(this.state)
        console.log(e?.target?.value)
        this.setState({ hideFilter: false });
        if (!e?.target?.value) {
            this.filterAllOrders();
        } else if (e?.target?.value == 1) { // Today
            var today = dayjs(new Date());
            this.setState({ start: today, end: today }, () => {
            });
        } else if (e?.target?.value == 2) { // Yesterday
            // var today = dayjs(new Date()).subtract(1, 'day');
            var yesterday = dayjs(new Date()).subtract(1, 'day');
            console.log(yesterday)
            this.setState({ start: yesterday, end: yesterday }, () => {
            });
            console.log(this.state)
        } else if (e?.target?.value == 3) { // Last 7 Days
            var today = dayjs(new Date()).subtract(1, 'week').startOf('week');
            var last7 = dayjs(new Date()).subtract(1, 'week').endOf('week');
            this.setState({ start: today, end: last7 }, () => {
            });
        } else if (e?.target?.value == 4) { // Last 30 Days
            var today = dayjs(new Date()).subtract(1, 'month').startOf('month');
            var last30 = dayjs(new Date()).subtract(1, 'month').endOf('month');
            this.setState({ start: today, end: last30 }, () => {
            });
        } else if (e?.target?.value == 5) { // Last 30 Days
            var today = dayjs(new Date());
            var last30 = dayjs(new Date()).subtract(1, 'year');
            this.setState({ start: last30, end: today }, () => {
            });
        } else if (e?.target?.value == 6) { // Last 30 Days
            var today = dayjs(new Date());
            var firstDayOfMonth = dayjs(new Date()).startOf('month');
            this.setState({ start: firstDayOfMonth, end: today }, () => {
            });
        } else if (e?.target?.value == 7) { // Last 30 Day
            this.setState({ hideFilter: true });
        }

    }


    selectTrenidngReportTimePeriod = (e) => {
        console.log(this.state)
        console.log(this.state.start.isSame(this.state.end))
        this.setState({ selectTimePeriod: e.target.value }, () => {
            this.getTrendingSalesReport();

        });


    }


    selectSalesByTypeItemReport = (e) => {
        this.setState({ selectReport: e.target.value }, () => {
            if (e.target.value == "Item") {
                this.getLineGraph();
            } else if (e.target.value == "Date") {
                this.totalSalesReport();
            }
        });


    }



    getReport = () => {
        if (this.state.selectReport == "Item") {
            this.getLineGraph();
        } else if (this.state.selectReport == "Date") {
            this.totalSalesReport();
        }
    }

    getLineGraph = () => {
        this.setState({ isLoading: true });
        let payload = {
            start_date: this.state.start.format("DD-MM-YYYY"),
            end_date: this.state.end.format("DD-MM-YYYY"),
            property_id: this.props.propertyId
        }
        const output_data = [];
        ReportServiceAPI.fetchItemReport(payload)
            .then((response) => {
                console.log(response.data.status);
                if (response.data.status) {
                    console.log(response.data.data);
                    this.setState({ data: response.data.data, isLoading: false });

                } else {
                    //     toast.error(response.data.message);
                    this.setState({ isLoading: false, data: [] });
                }
            })
            .catch((error) => {
                //  this.setState({ loading: false, show: false });
                this.setState({ isLoading: false });
                console.log(error);
            });
    }

    totalSalesReport = () => {
        this.setState({ isLoading: true });
        let payload = {
            start_date: this.state.start.format("DD-MM-YYYY"),
            end_date: this.state.end.format("DD-MM-YYYY"),
            property_id: this.props.propertyId
        }
        const output_data = [];
        ReportServiceAPI.fetchTotalSalesItemReport(payload)
            .then((response) => {
                console.log(response.data.status);
                if (response.data.status) {
                    console.log(response.data?.data);
                    const output_data = [];


                    for (const date in response?.data?.data) {
                        const scales = response?.data?.data[date];
                        const month_year = date;


                        const data_point = {
                            "Date": month_year,
                            "Sales": scales
                        };


                        output_data.push(data_point);
                    }

                    console.log(output_data)

                    this.setState({ data: output_data, isLoading: false });



                } else {
                    //     toast.error(response.data.message);
                    this.setState({ isLoading: false, data: [] });
                }
            })
            .catch((error) => {
                //  this.setState({ loading: false, show: false });
                this.setState({ isLoading: false, data: []  });
                console.log(error);
            });
    }


    getColumnGraph = () => {

        let payload = {
            start_date: this.state.start.format("DD-MM-YYYY"),
            end_date: this.state.end.format("DD-MM-YYYY"),
            property_id: this.props.propertyId
        }

        ReportServiceAPI.topSellingItemReport(payload)
            .then((response) => {
                console.log(response.data.status);
                if (response.data.status) {
                    console.log(response.data.data);
                    const result = [];

                    for (let key in response.data.data) {
                        let item = {
                            "Item Name": key,
                            "Items Sold": response.data.data[key]
                        };
                        result.push(item);
                    }

                    console.log(result)

                    const topItems = result.sort((a, b) => b["Items Sold"] - a["Items Sold"]);

                    console.log(topItems)

                    this.setState({ topSellingItems: topItems, isLoading: false })


                } else {
                    //     toast.error(response.data.message);
                    this.setState({ isLoading: false, order_details: [] });
                }
            })
            .catch((error) => {
                //  this.setState({ loading: false, show: false });
                this.setState({ isLoading: false });
                console.log(error);
            });
    }

    getTrendingSalesReport = () => {


        let payload = {
            start_date: this.state.start.format("DD-MM-YYYY"),
            end_date: this.state.end.format("DD-MM-YYYY"),
            property_id: this.props.propertyId,
            time_interval: this.state.selectTimePeriod
        }

        ReportServiceAPI.trendingItemSalesReport(payload)
            .then((response) => {
                console.log(response.data.status);
                if (response.data.status) {
                    console.log(response.data.data)

                    this.setState({ trendingItemsSalesReport: response.data.data })

                } else {
                    //     toast.error(response.data.message);
                    this.setState({ isLoading: false, trendingItemsSalesReport: [] });
                }
            })
            .catch((error) => {
                //  this.setState({ loading: false, show: false });
                this.setState({ isLoading: false });
                console.log(error);
            });
    }



    getUnSoldItems = () => {

        let payload = {
            start_date: this.state.start.format("DD-MM-YYYY"),
            end_date: this.state.end.format("DD-MM-YYYY"),
            property_id: this.props.propertyId
        }

        ReportServiceAPI.fetchUnSoldItems(payload)
            .then((response) => {
                console.log(response.data.status);
                if (response.data.status) {
                    console.log(response.data.data);


                    this.setState({ unSoldItems: response.data.data, isLoading: false })


                } else {
                    //     toast.error(response.data.message);
                    this.setState({ isLoading: false, unSoldItems: [] });
                }
            })
            .catch((error) => {
                //  this.setState({ loading: false, show: false });
                this.setState({ isLoading: false });
                console.log(error);
            });
    }


    handleTabChange = (event, newValue) => {
        this.setState({ tab: newValue });
    };

    handleToDate = (e) => {
        //       const ctrl_name = e.target?.name;
        console.log(e);
        console.log(dayjs(e).format('DD-MM-YYYY'));
        let toDat = dayjs(e);
        this.setState({ end: toDat }, () => {
        });
    }

    handleFromDate = (e) => {
        //    const ctrl_name = e.target?.name;
        console.log(e);
        console.log(dayjs(e));
        let toDat = dayjs(e);
        this.setState({ start: toDat }, () => {

        });
    }
    fetchSalesReport = (row) => {
        let payload = {
            start_date: this.state.start.format("DD-MM-YYYY"),
            end_date: this.state.end.format("DD-MM-YYYY"),
            property_id: this.props.propertyId
        }
    
        ReportServiceAPI.fetchdarItems(payload)
            .then((response) => {
                const csvData = response.data;
    
                // Create a Blob containing the CSV data
                const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    
                // Create a download link
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', 'report.csv');
    
                // Append the link to the body
                document.body.appendChild(link);
    
                // Trigger a click on the link to initiate the download
                link.click();
    
                // Remove the link from the body
                document.body.removeChild(link);
            })
            .catch(error => {
                console.log(error);
            });
    };
    
    // No changes needed in the downloadFile function
    downloadFile(filePath) {
        var link = document.createElement('a');
        link.href = filePath;
        link.download = "invoice";
        link.click();
    }
    


    render() {


        const { tab } = this.state;

        const totalItemsSalesReportConfig = {
            data: this.state.data,
            padding: 'auto',
            xField: 'Date',
            yField: 'Sales',
            
            yAxis: {
                label: {
                    // 数值格式化为千分位
                    formatter: (v) => getCurrencySymbol(localStorage.getItem('currency_code'))+`${v}`
                }
            },
            tooltip: {
                formatter: (datum) => {
                    return { name: "Sales", value:  + getCurrencySymbol(localStorage.getItem('currency_code')) + datum.Sales };
                },
            }
        };

        const config = {
            data: this.state.topSellingItems,
            xField: 'Item Name',
            yField: 'Items Sold',
            xAxis: {
                label: {
                    autoRotate: false,
                },
            },
            slider: {
                start: 0.0,
                end: 1,
            },
        };


        let trendingReportConfig = {
            data: this.state.trendingItemsSalesReport,
            padding: 'auto',
            xField: 'Date',
            yField: 'Count',
            xAxis: {
                // type: 'timeCat',
                tickCount: 5,
            },
        };

        if (this.state.selectTimePeriod == "DAILY") {
            trendingReportConfig = {
                data: this.state.trendingItemsSalesReport,
                padding: 'auto',
                xField: 'Date',
                yField: 'Count',
                xAxis: {
                    // type: 'timeCat',
                    tickCount: 5,
                },
            };

        } else if (this.state.selectTimePeriod == "YEARLY") {
            trendingReportConfig = {
                data: this.state.trendingItemsSalesReport,
                padding: 'auto',
                xField: 'Year',
                yField: 'Count',
                xAxis: {
                    // type: 'timeCat',
                    tickCount: 5,
                },
            };

        } else if (this.state.selectTimePeriod == "HOURLY") {
            trendingReportConfig = {
                data: this.state.trendingItemsSalesReport,
                padding: 'auto',
                xField: 'Hour',
                yField: 'Count',
                seriesField: 'Date',
                yAxis: {
                    label: {
                        // 数值格式化为千分位
                        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                    },
                },
                color: ['#1979C9', '#D62A0D', '#FAA219'],
            };

        } else if (this.state.selectTimePeriod == "MONTHLY") {
            trendingReportConfig = {
                data: this.state.trendingItemsSalesReport,
                padding: 'auto',
                xField: 'Year',
                yField: 'Count',
                seriesField: 'Month',
                yAxis: {
                    label: {
                        // 数值格式化为千分位
                        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                    },
                },
                color: ['#1979C9', '#D62A0D', '#FAA219'],
            };

        }

        const { classes } = this.props;
        return (
            <Fragment>
                <Breadcrumb />
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={tab}
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    TabIndicatorProps={{ style: { background: "#5773ff" } }}
                                >
                                    <Tab label="Sales Report" />
                                    {/* <Tab label="Unsold Items" /> */}
                                </Tabs>
                                <TabPanel value={tab} index={0} className="tabPanel">
                                    <div className="ms-panel">

                                        <div className="ms-panel-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-md-12 col-sm-12">

                                                    <div className="row m-2">

                                                        <div className="col-md-2">
                                                            <div className="mt-4">
                                                                {/*     <label htmlFor="validationCustom05">Filter By</label> */}
                                                                <select style={{ height: "54px" }} className="form-control" name="date_options_dd" id="validationCustom05" onChange={this.fnCallback} >
                                                                    <option defaultValue="">- - Filter By - -</option>
                                                                    <option value="1">Today</option>
                                                                    <option value="2">Yesterday</option>
                                                                    <option value="3">Last Week</option>
                                                                    <option value="4">Last Month</option>
                                                                    <option value="6">This Month</option>
                                                                    <option value="5">All time</option>
                                                                    {/* <option value="7">Custom</option> */}
                                                                </select>

                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="mt-4">

                                                                <FormControl fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">Select Report</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={this.state.selectReport}
                                                                        label="Select Report"
                                                                        onChange={this.selectSalesByTypeItemReport}
                                                                    >
                                                                        <MenuItem value="Item">By Item</MenuItem>
                                                                        <MenuItem value={"Date"}>By Date</MenuItem>



                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>





                                                        {/*     {this.state.hideFilter && */} <React.Fragment>

                                                            <div className="col-md-2">
                                                                <div className="mt-4">

                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <Stack spacing={3}>
                                                                            <MobileDatePicker
                                                                                label="From"
                                                                                value={this.state.start || new Date()}
                                                                                inputFormat="DD-MM-YYYY"
                                                                                InputProps={{
                                                                                    endAdornment: <InputAdornment position="start"><CalendarMonthIcon /></InputAdornment>,
                                                                                }}
                                                                                maxDate={new Date()}
                                                                                onChange={this.handleFromDate}
                                                                                renderInput={(params) => <TextField {...params} />}
                                                                            />
                                                                        </Stack>
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-2">
                                                                <div className="mt-4">

                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <Stack spacing={3}>
                                                                            <MobileDatePicker
                                                                                label="To"
                                                                                value={this.state.end || new Date()}
                                                                                inputFormat="DD-MM-YYYY"
                                                                                onChange={this.handleToDate}
                                                                                maxDate={new Date()}
                                                                                InputProps={{
                                                                                    endAdornment: <InputAdornment position="start"><CalendarMonthIcon /></InputAdornment>,
                                                                                }}
                                                                                renderInput={(params) => <TextField {...params} />}
                                                                            />
                                                                        </Stack>
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </div>


                                                        </React.Fragment>
                                                        {/* } */}

                                                        <div className="col-md-3">
                                                            <div className="mt-3" >
                                                                <Button type="submit" sx={{ mt: 2 }} variant="contained" onClick={this.getReport} color="primary">
                                                                    GENERATE
                                                                </Button>
                                                            </div>
                                                        </div>

                                                    </div>





                                                    <div className="" style={{ marginTop: "100px !important" }}  >


                                                        {this.state.isLoading ? <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "40px" }}>   <Loader size={50} /> </div> :

                                                            <React.Fragment >
                                                                {/*
                                                                <div className="col-md-6">
                                                                    <h4>Total Item Sales</h4>


                                                                </div>
                                                               
                                                                <div className="col-md-6">
                                                                    <h4>Top Selling Items</h4>
                                                                    <Column {...config} />

                                                                </div>

                                                                <div className="col-md-6" style={{ marginTop: "50px" }}>
                                                                    <h4>Trending </h4>

                                                                    <Line {...trendingReportConfig} />

                                                                </div> */}
                                                                {/* 
                                                                <div className="col-md-6">
                                                                    <Column {...config} />

                                                                </div> */}

                                                                {this.state.selectReport == "Date" && <Column  {...totalItemsSalesReportConfig} />}
                                                                <br></br><br></br>
                                                                <div className="col-md-12" sx={{ marginTop: "100px !important" }}>
                                                                    <Paper elevation={0}>
                                                                        <Typography variant="subtitle1" color="textSecondary">
                                                                            IMPORTANT NOTICE:
                                                                        </Typography>
                                                                        <Typography variant="body1">
                                                                            The sales figures presented below do not reflect any discounts that may have been applied during the checkout process.
                                                                        </Typography>
                                                                    </Paper>
                                                                </div>
                                                                <br></br>
                                                                {this.state.data.length > 0  && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                    <Button variant="contained" style={{ float: "right" }} color="primary"
                                                                        // onClick={this.handleExportCSV}
                                                                        onClick={this.fetchSalesReport}
                                                                    >
                                                                        Export CSV
                                                                    </Button>  </div>
                                                                    }

                                                                <DataTable

                                                                    columns={this.state.selectReport == "Item" ? this.order_list_columns : this.order_list_column}
                                                                    pagination
                                                                    paginationRowsPerPageOptions={[10, 25, 50, 100, 250]}
                                                                    data={this.state.data}
                                                                    customStyles={this.customStyles}
                                                                />






                                                            </React.Fragment>}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </TabPanel>
                                <TabPanel value={tab} index={1}>
                                    <div className="ms-panel">
                                        <div className="ms-panel-body">
                                            <div className="col-md-12">

                                                <Typography variant="h5" gutterBottom style={{}}>
                                                    Inventory of Unsold Items

                                                </Typography>
                                                <ItemList items={this.state.unSoldItems} />
                                            </div>

                                        </div>
                                    </div>
                                </TabPanel>

                            </AppBar>



                        </div>
                    </div>
                </div>


            </Fragment >
        );
    }
}
const mapStateToProps = (state) => ({
    user: state.user,
    propertyId: state.user.propertyId
  });
  
  export default connect(mapStateToProps, {}) (Content);