import React, { Component } from "react";
import { toast } from "react-toastify";
import * as DiscountServiceAPI from "../../services/discount/DiscountServiceAPI";
import Breadcrumb from "./Breadcrumb";
import SimpleModal from "../../shared/Modal/SimpleModal";
import { Fragment } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Card, CardContent, FormControl, Typography } from "@mui/material";
import * as schema from "../../utils/Schema";
import { Form, Formik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import AlarmIcon from "@mui/icons-material/Alarm";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import EditIcon from "@mui/icons-material/Edit";
import isEqual from "lodash/isEqual";
import InputAdornment from "@mui/material/InputAdornment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  TableSortLabel,
} from "@mui/material";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
//import Checkbox from '@mui/material/Checkbox';
import DataTable from "react-data-table-component";
import moment from "moment";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import {
  fetchMenuNames,
  fetchMenu,
} from "../../services/product/menu/MenuServiceAPI";
import "@arco-design/web-react/dist/css/arco.css";
import { TreeSelect, Checkbox } from "@arco-design/web-react";
import {
  fetchCategoryNames,
  fetchCategory,
} from "../../services/product/category/CategoryServiceAPI";
import {
  addItem,
  updateItem,
  fetchItem,
  addExtrasOptions,
  deleteExtrasOptions,
  updateExtrasOptions,
  fetchAddonByAddonId,
  fetchUpsellItems,
} from "../../services/product/item/ItemServiceAPI";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
} from "@mui/material";
import { connect } from "react-redux";
import Loader from "../../shared/Loader";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { DatePicker, Space, Alert } from "antd";
import { DesktopDateTimePicker, nlNL } from "@mui/x-date-pickers";
import $ from "jquery";
import {getCurrencySymbol} from "../../config";

const { RangePicker } = DatePicker;
const discountTypeOptions = [
  /* { value: "FIXED", label: "Fixed" }, */
  { value: "PERCENTAGE", label: "Percentage" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const treeDat = [
  {
    title: "Trunk 0-0",
    value: "Trunk 0-0",
    key: "0-0",
    children: [
      {
        title: "Leaf 0-0-1",
        value: "Leaf 0-0-1",
        key: "0-0-1",
      },
      {
        title: "Branch 0-0-2",
        value: "Branch 0-0-2",
        key: "0-0-2",
        children: [
          {
            title: "Leaf 0-0-2-1",
            value: "Leaf 0-0-2-1",
            key: "0-0-2-1",
          },
        ],
      },
    ],
  },
  {
    title: "Trunk 0-1",
    value: "Trunk 0-1",
    key: "0-1",
    children: [
      {
        title: "Branch 0-1-1",
        value: "Branch 0-1-1",
        key: "0-1-1",
        checkable: false,
        children: [
          {
            title: "Leaf 0-1-1-1",
            value: "Leaf 0-1-1-1",
            key: "0-1-1-1",
          },
          {
            title: "Leaf 0-1-1-2",
            value: "Leaf 0-1-1-2",
            key: "0-1-1-2",
            disabled: true,
          },
        ],
      },
      {
        title: "Leaf 0-1-2",
        value: "Leaf 0-1-2",
        key: "0-1-2",
      },
    ],
  },
];
const { SHOW_PARENT } = TreeSelect;

const hashmap = {};
const ExpandableComponent = ({ data }) => (
  /*   <List
      sx={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader" >
      {data.item_list.map((item, index) => (
        <ListItem key={index} sx={{ display: 'flex', flexDirection: 'row', width: '30%', flexWrap: 'wrap' }}>
          <ListItemIcon>
            <Avatar>{index + 1}</Avatar>
          </ListItemIcon>
          <ListItemText primary={hashmap[item]} />
        </ListItem>
      ))}
    </List> */

  <Grid container spacing={2}>
    {data.item_list.map((item, index) => (
      <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
        <ListItem>
          <ListItemIcon>
            <Avatar>{index + 1}</Avatar>
          </ListItemIcon>
          <ListItemText primary={hashmap[item]} />
        </ListItem>
      </Grid>
    ))}
  </Grid>
);

class index extends Component {
  state = {
    isLoading: false,
    initialValues: {},
    isLoading: false,
    discounts: [],
    discount: null,
    selectedDiscount: null,
    selectedDiscountVoucher: "",
    page: 0,
    rowsPerPage: 10,
    sortDirection: "asc",
    open: false,
    dId: "",
    menuNames: /* [{ "menu_id": "1", "menu_identifier": "One" }] || */ [],
    selectedMenu: "",
    itemNames: /* [{ "menu_id": "1", "menu_identifier": "One" }] || */ [],
    selectedItem: [],
    selectedItems: [],
    categoryNames: [],
    itemIdentifiers: [],
    itemIds: [],
    itemMap: {},
    selectedCategory: "",
    item_list: [],
    treeCheckStrictly: false,
    value: [],
    treeData: [],
    error: "",
    startDate: null,
    endDate: null,
    defaultRange: [dayjs(), dayjs()],
    selectedStartDate: null,
    showForm: false,
    showFormDialog: false,
  };

  columns = [
    {
      name: "Activity Name",
      selector: "activity_name",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          <div>{row.activity_name}</div>
        </div>
      ),
    },

    {
      name: "Discount Type",
      selector: "discount_type",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          <div>{row.discount_type}</div>
        </div>
      ),
    },

    {
      name: "Discount Value",
      selector: "discount_value",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          {" "}
          {row.discount_type != "PERCENTAGE"
            ? getCurrencySymbol(localStorage.getItem('currency_code')) + row.discount_value?.toFixed(2)
            : ""}{" "}
          {row.discount_type == "PERCENTAGE" ? row.discount_value + " %" : ""}
        </div>
      ),
    },

    {
      name: "Activity Start Date",
      selector: "activity_start_date",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {row.activity_start_date}</div>,
    },

    {
      name: "Activity End date",
      selector: "activity_end_date",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {row.activity_end_date}</div>,
    },

    {
      name: "Action",
      selector: "action",
      justifyContent: "center",
      width:"280px",
      cell: (row) => (
        // <div>
        //   <IconButton
        //     color="primary"
        //     onClick={() => this.editDiscount(row.activity_id)}
        //     aria-label="Edit"
        //   >
        //     <EditIcon />
        //   </IconButton>
        //   <IconButton
        //     color="primary"
        //     onClick={() => this.handleClickOpen(row.activity_id)}
        //     aria-label="delete"
        //     size="large"
        //   >
        //     <DeleteIcon fontSize="inherit" />
        //   </IconButton>
        // </div>
       <Stack direction="row" spacing={2}>
       <Button variant="outlined" startIcon={<EditIcon color="primary" />} onClick={() => this.editDiscount(row.activity_id)}>Edit</Button>
       <Button variant="outlined" startIcon={<DeleteIcon color="primary" />} onClick={() => this.handleClickOpen(row.activity_id)}>Delete</Button>

     </Stack>
      ),
    },
  ];

  customStyles = {
    rows: {
      style: {
        //          minHeight: '70px', // override the row height
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "65px",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "auto",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        justifyContent: "center",
        "&:hover div": {
          backgroundColor: "transparent",
          color: "white !important",
        },
        div: {
          color: "white !important",
        },
        span: {
          color: "white !important",
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "2px", // override the cell padding for data cells
        paddingRight: "2px",
        pointer: "cursor",
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  componentDidMount = () => {
    this.fetchItems(this.props.propertyId);
    //   this.fetchMenuNames();
    this.fetchAllDiscounts();
    this.setInitalValues();
    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }
  };

  handleClickOpen = (disCountId) => {
    this.setState({ open: true, dId: disCountId, showFormDialog: true });
  };

  handleCancel = () => {
    this.setState(
      {
        discount: null,
        selectedDiscount: "",
        value: [],
        item_list: [],
        showForm: false,
      },
      () => {
        this.setInitalValues();
      }
    );
  };

  handleMenuChange = (e, setFieldValue) => {
    setFieldValue("menu_id", e.target.value);
    this.setState(
      {
        selectedMenu: e.target.value,
      },
      () => {
        this.fetchCategoryNames(e.target.value);
      }
    );
  };

  handleCategoryChange = (e, setFieldValue) => {
    this.setState({ selectedCategory: e.target.value });
    setFieldValue("category_id", e.target.value);
    this.fetchItems(e.target.value);

    // this.props.setSelectedMenu(event.target.value);
  };

  handleItemChange = async (e, setFieldValue) => {
    setFieldValue("item_list", e.target.value);

    const { value } = e.target;
    this.setState(
      (prevState) => ({
        selectedItem: typeof value === "string" ? value.split(",") : value,
        selectedItems: [...prevState.selectedItems, value[0]],
      }),
   
    );

  };

  handleClose = () => {
    this.setState({ open: false, dId: "" });
  };

  setInitalValues = () => {
    const { discount } = this.state;

    let initialValues = {};
    initialValues = {
      activity_name: discount?.activity_name || "",
      // activity_start_date: discount?.activity_start_date ? dayjs(discount.activity_start_date, 'DD-MM-YYYY HH:mm:ss') : dayjs(new Date()),
      // activity_end_date: discount?.activity_end_date ? dayjs(discount.activity_end_date, 'DD-MM-YYYY HH:mm:ss') : dayjs(new Date()),
      discount_type: "PERCENTAGE",
      discount_value: discount?.discount_value || "",
      item_list: discount?.item_list || [],
    };

    const defaultRange = [
      dayjs(discount?.activity_start_date || dayjs(), "DD-MM-YYYY HH:mm:ss"),
      dayjs(discount?.activity_end_date || dayjs(), "DD-MM-YYYY HH:mm:ss"),
    ];
    this.setState({
      initialValues,
      defaultRange: defaultRange,
      startDate: dayjs(discount?.activity_start_date || dayjs(), "DD-MM-YYYY HH:mm:ss"),
      endDate: dayjs(discount?.activity_end_date || dayjs(), "DD-MM-YYYY HH:mm:ss"),
    });
  };

  setInitalDicountCode = (values) => {
    const { discount } = this.state;
    let initialValues = {};

    if (this.state.selectedDiscountVoucher == "NORMAL DISCOUNT") {
      initialValues = {
        activity_name: values?.activity_name || discount?.activity_name || "",
        discount_start_date: discount?.discount_start_date
          ? dayjs(discount.discount_start_date, "DD-MM-YYYY")
          : dayjs(new Date()),
        discount_end_date: discount?.discount_end_date
          ? dayjs(discount.discount_end_date, "DD-MM-YYYY")
          : dayjs(new Date()),
        ddiscount_type: "PERCENTAGE",
        discount_value:
          values?.discount_value || discount?.discount_value || "",
      };
    }

    this.setState({ initialValues });
  };

  fetchAllDiscounts = () => {
    this.setState({ isLoading: true });
    let propertId = this.props.propertyId;
    DiscountServiceAPI.fetchAllHappyHourDiscounts(propertId)
      .then((response) => {
        if (response.data.status) {
          this.setState({ discounts: response.data.data, isLoading: false });
          //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
          //  toast.success("Discount added. ")
        } else {
          this.setState({ discounts: [], isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not fetch discounts.. ");
      });
  };

  addDiscount = (values, resetForm) => {
    values["activity_name"] = values["activity_name"]?.toUpperCase();
    values["item_list"] = this.state.value;

    DiscountServiceAPI.addHappyHourDiscount(values)
      .then((response) => {
        if (response.data.status) {
          this.setState({ isLoading: false });
          toast.success("Happy hour activity  added. ");
          resetForm({ values: "" });
          //      this.setState({ discount: null, selectedDiscount: null, selectedDiscountVoucher: null, discount_code: "" });
          this.setState(
            {
              discount: null,
              selectedDiscount: "",
              value: [],
              showForm: false,
              isLoading: false,
            },
            () => {
              this.setInitalValues();
            }
          );
          this.fetchAllDiscounts();
        } else {
          // this.setState({ isLoading: false });
          let dis = { ...this.state.discount, ...values };
          this.setState(
            { discount: dis, isLoading: false },
            () => {
              this.setInitalValues();
            }
          );
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not add discount.. ");
      });
  };

  updateDiscount = (values, resetForm) => {
    values["activity_name"] = values["activity_name"]?.toUpperCase();

    values["item_list"] = this.state.value;


    DiscountServiceAPI.updateHappyHourDiscount(values)
      .then((response) => {
        if (response.data.status) {
          this.setState({ isLoading: false });
          toast.success("Happy hour activity  updated. ");
          resetForm({ values: "" });
          this.setState(
            {
              discount: null,
              selectedDiscount: "",
              value: [],
              showForm: false,
              isLoading: false,
            },
            () => {
              this.setInitalValues();
            }
          );
          this.fetchAllDiscounts();
        } else {
          toast.warn(response.data.message);
          this.setState({ isLoading: false });

          // resetForm({ values: "" });
          // this.setInitalValues();
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not update discount.. ");
      });
  };

  editDiscount = (discountId) => {
    /* 
        this.setState({ discount, selectedDiscount: discount.discount_type, selectedDiscountVoucher: discount?.discount_voucher_type || "NORMAL DISCOUNT" }, () => {
          this.setInitalValues();
        }); 
     */
    DiscountServiceAPI.fetchHappyHourDiscount(discountId)
      .then((response) => {
        if (response.data.status) {
          const discount = response.data.data;
          //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });

          this.setState(
            {
              discount,
              value: discount?.item_list || [],
              selectedDiscount: discount.discount_type,
              showForm: true,
            },
            () => {
              this.setInitalValues();
              toast.success("Happy hour activity details retrieved.");
            }
          );
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not add discount.. ");
      });
  };

  fetchMenuNames = async (menu) => {
    try {
      let propertId = this.props.propertyId;
      let menus = await fetchMenu(propertId);
      if (menus.data.status) {
        let sorted_menus = menus.data.data.menu_response_bean_list.sort(
          (a, b) => a.item_sort_order - b.item_sort_order
        );
        this.setState(
          {
            isLoading: false,
            menuNames:
              sorted_menus /* selectedMenu: sorted_menus[0].menu_id  */,
          },
          () => {
            /* this.fetchCategoryNames(sorted_menus[0].menu_id) */
          }
        );
      } else {
        this.props.setMenuNames(null);
        this.setState({ isLoading: false, menuNames: null });
      }
    } catch (e) {
      console.log("error getting menu names", e);
    }
  };

  fetchCategoryNames = async (selectedMenu) => {
    try {
      let categories = await fetchCategory(selectedMenu);

      if (
        categories.data.status == false ||
        categories.data.message == "No details found."
      ) {
        // setLoading(false);
        this.setState({ categoryNames: [] });
        this.setState({ selectedCategory: "" });
        return;
      } else {
        this.setState({
          categoryNames: categories.data.data.category_response_bean_list,
        });
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  fetchItems = async (value) => {
    try {
      let items = await DiscountServiceAPI.fetchAllHappyHourDiscountsItems(
        value
      );


      if (
        items.data.status == false ||
        items.data.message == "No details found."
      ) {
        this.setState({
          itemNames: [],
        });
        return;
      } else {
        const transformedData = this.transformObject(items.data.data.menu_list);
        transformedData.sort((a, b) => a.menu_sort_order - b.menu_sort_order);
        this.setState({ treeData: transformedData });
        

        items.data.data.menu_list.forEach((menu) => {
          menu.category_list.forEach((category) => {
            category.item_list.forEach((item) => {
              hashmap[item.item_id] = item.item_identifier;
            });
          });
        });
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  transformObject(obj) {
    let result = obj
      .filter((menu) => menu.category_list && menu.category_list.length > 0) // Filter menus without category_list or empty category_list
      .map((menu) => ({
        title: menu.menu_identifier,
        value: menu.menu_id,
        key: menu.menu_id,
        menu_sort_order: menu.menu_sort_order,
        children: menu.category_list
          .filter(
            (category) => category.item_list && category.item_list.length > 0
          ) // Filter categories without item_list or empty item_list
          .map((category) => ({
            title: category.category_identifier,
            value: category.category_id,
            key: category.category_id,
            children: category.item_list.map((item) => ({
              title: item.item_identifier,
              value: item.item_id,
              key: item.item_id,
            })),
          }))
          .filter((category) => category.children.length > 0), // Filter categories without any items
      }))
      .filter((menu) => menu.children.length > 0); // Filter menus without any categories or categories without any items

      result.sort((a, b) => a.title.localeCompare(b.title));

      return result;
  }

  disabledDate = (current) => {
    // Get the current date and time
    const currentDate = dayjs();

    // If the selected date is before the current date, disable it
    return current && current < currentDate.startOf("day");
  };
  disabledRangeTime = (current, type) => {
    const currentTime = this.state.selectedStartDate || dayjs();
    const isStartDateSameAsCurrent =
      current && current.isSame(currentTime, "day");
    const isEndDateSameAsStart = current && current.isSame(currentTime, "day");

    // Disable times before the current time on the selected date
    if (type === "start" && isStartDateSameAsCurrent) {
      return {
        disabledHours: () => [...Array(currentTime.hour()).keys()],
        disabledMinutes: (selectedHour) => {
          if (selectedHour === currentTime.hour()) {
            return [...Array(currentTime.minute()).keys()];
          }
          return [];
        },
        disabledSeconds: (selectedHour, selectedMinute) => {
          if (
            selectedHour === currentTime.hour() &&
            selectedMinute === currentTime.minute()
          ) {
            return [...Array(currentTime.second()).keys()];
          }
          return [];
        },
      };
    } else if (type === "end" && isEndDateSameAsStart) {
      // Disable times before the selected start time
      return {
        disabledHours: () => [...Array(currentTime.hour()).keys()],
        disabledMinutes: (selectedHour) => {
          if (selectedHour === currentTime.hour()) {
            return [...Array(currentTime.minute()).keys()];
          }
          return [];
        },
        disabledSeconds: (selectedHour, selectedMinute) => {
          if (
            selectedHour === currentTime.hour() &&
            selectedMinute === currentTime.minute()
          ) {
            return [...Array(currentTime.second()).keys()];
          }
          return [];
        },
      };
    }

    return {};
  };

  handleDiscountSubmit = (values, resetForm) => {
    this.setState({ isLoading: true });
    if (!this.state.discount) {
      this.addDiscount(values, resetForm);
    } else {
      values["activity_id"] = this.state.discount.activity_id;
      this.updateDiscount(values, resetForm);
    }
  };
  handleViewInstructions = () => {
    window.scroll({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  deleteDiscount = (discountId) => {
    let del = {
      property_id: this.props.propertyId,
      activity_id: discountId,
    };
    DiscountServiceAPI.deleteHappyHourDiscount(del)
      .then((response) => {
        if (response.data.status) {
          toast.success("Happy hour activity  deleted. ");
          this.setState({ open: false });
          this.fetchAllDiscounts();
          this.setState(
            { discount: null, selectedDiscount: "", value: [] },
            () => {
              this.setInitalValues();
            }
          );
        } else {
          toast.warn("Couldn't delete. ");
          this.setState(
            { discount: null, selectedDiscount: "", value: [] },
            () => {
              this.setInitalValues();
            }
          );
          this.setState({ isLoading: false, open: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, open: false });
        toast.error("Something went wrong. ");
      });
  };

  handleRangeChange = (value) => {
    console.log(value);
    // Handle the selected range here
    if (value && value.length === 2) {
      const [startDate, endDate] = value.map((date) =>
        dayjs(date, "DD-MM-YYYY HH:mm:ss")
      );

      this.setState({
        error: "",
        startDate: startDate,
        endDate: endDate,
        selectedStartDate: startDate,
        defaultRange: [startDate, endDate],
      });
    }
  };

  showForm = () => {
    if (!this.state.showForm) {
      this.setState({
        showForm: true,
      });
    } else {
      this.setState(
        {
          showForm: false,
          discount: null,
          is_editable: true,
        },
        () => {
          this.setInitalValues();
        }
      );
    }
  };

  render() {
    const {
      submitting,
      initialValues,
      menuNames,
      selectedMenu,
      categoryNames,
      selectedCategory,
      itemNames,
      selectedItem,
      itemIdentifiers,
      selectedItems,
      itemMap,
      treeCheckStrictly,
      value,
      treeData,
      error,
      defaultRange,
      selectedStartDate,
      showForm,
      showFormDialog,
    } = this.state;

    return (
      <Fragment>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <Breadcrumb />
            </div>
            <div className="col-md-12">
              <div className="ms-panel">
                {showFormDialog && (
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Delete Happy Hour Discount?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Do you really want to proceed?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose}>No</Button>
                      <Button
                        onClick={() => this.deleteDiscount(this.state.dId)}
                      >
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
                {!showForm && (
                  <div className="ms-panel-header">
                    <React.Fragment>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6} lg={6}>
                          <h5>Happy Hour Discount</h5>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.showForm}
                          >
                            {this.state.discount ? "Update" : "Add"} Happy Hour
                            Discount
                          </Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  </div>
                )}
                <div className="ms-panel-body"></div>
                {showForm && (
                  <React.Fragment>
                    <div style={{}}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ marginLeft: "20px", height: "10px" }}
                      >
                        <div style={{}}>
                          {/* {this.state.discount ? "Update" : "Create"} */}
                          Happy Hour Discount
                          <Button
                            variant="contained"
                            style={{
                              float: "right",
                              marginRight: "20px",
                              position: "relative",
                              top: "-8px",
                            }}
                            color="primary"
                            onClick={this.showForm}
                          >
                            Back
                          </Button>
                        </div>{" "}
                        <Divider />
                      </Typography>
                    </div>
                    <br /> <br />
                    <Grid
                      container
                      sx={{ display: { xs: "block", md: "flex" } }}
                    >
                      <Grid container xs={12} md={8} lg={8}>
                        <Formik
                          enableReinitialize="true"
                          initialValues={initialValues}
                          validationSchema={schema.discountHappyHourSchema}
                          onSubmit={(values, { resetForm }) => {
                            values["property_id"] = this.props.propertyId;
                            if (!this.state.endDate || !this.state.startDate) {
                              this.setState({
                                error:
                                  "Start activity datetime and End activity datetime is required.",
                                startDate: null,
                                endDate: null,
                              });
                              return false;
                            } else {
                              // Assuming values, startDate, and endDate are already defined

                              // Format start and end dates
                              const formattedStartDate = dayjs(this.state.startDate).format("DD-MM-YYYY HH:mm:ss");
                              const formattedEndDate = dayjs(this.state.endDate).format("DD-MM-YYYY HH:mm:ss");

                              // Check if start datetime and end datetime are equal
                              if (formattedStartDate === formattedEndDate) {
                                // If equal, set error state and return
                                this.setState({ error: "Start datetime and end datetime cannot be equal." });
                                return; // Stop further processing
                              } else {
                                // If not equal, clear any existing error
                                this.setState({ error: "" });

                                // Proceed with setting values
                                values["activity_start_date"] = formattedStartDate;
                                values["activity_end_date"] = formattedEndDate;
                              }

                            }
                            this.handleDiscountSubmit(values, resetForm);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              <Box sx={{ margin: "30px", marginTop: "0px" }}>
                                <Grid container spacing={3} direction="row">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                      mb: 2,
                                      ml: "10px",
                                      width: "220px",
                                      height: "40px",
                                      display: { xs: "block", md: "none" },
                                    }}
                                    onClick={this.handleViewInstructions}
                                  >
                                    View Instructions
                                  </Button>{" "}
                                  <Grid item xs={12} md={12} lg={12}>
                                    <Typography>
                                      {" "}
                                      Complete the following details to{" "}
                                      {this.state?.discount
                                        ? "update the "
                                        : "create a "}
                                      discount.
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                      <TextField
                                        error={
                                          touched.activity_name &&
                                          errors.activity_name
                                            ? true
                                            : false
                                        }
                                        name="activity_name"
                                        label="Activity Name"
                                        autoComplete="activity_name"
                                        type="text"
                                        variant="standard"
                                        pattern="\s*\S+.*"
                                        value={values.activity_name || ""}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "activity_name",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={(e) => {
                                          setFieldValue(
                                            "activity_name",
                                            e.target.value.trim()
                                          );
                                        }}
                                        aria-describedby="component-error-text"
                                      />
                                      <FormHelperText error>
                                        {touched.activity_name &&
                                        errors.activity_name
                                          ? errors.activity_name
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                      <TextField
                                        error={
                                          touched.discount_value &&
                                          errors.discount_value
                                            ? true
                                            : false
                                        }
                                        name="discount_value"
                                        label="Discount Value (Percentage)"
                                        type="number"
                                        variant="standard"
                                        value={values.discount_value || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <FormHelperText error>
                                        {touched.discount_value &&
                                        errors.discount_value
                                          ? errors.discount_value
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  {/*   <Grid item xs={6} md={3} lg={3}>

                          <FormControl fullWidth>
                            <InputLabel id="menu_id">Menu</InputLabel>
                            <Select
                              error={
                                touched.menu_id &&
                                  errors.menu_id
                                  ? true
                                  : false
                              }
                              labelId="menu_id"
                              id="menu_id"
                              name="menu_id"
                              value={selectedMenu}
                              defaultValue={selectedMenu}
                              input={<OutlinedInput id="menu_id" label="Menu" />}
                              className="formControl"
                              //     disabled={!props.menuNames}
                              MenuProps={{
                                disableScrollLock: true
                              }}

                              onChange={(e, val) =>
                                this.handleMenuChange(e, setFieldValue)
                              }
                            >
                              {menuNames &&
                                menuNames.map((item) => {
                                  return (
                                    <MenuItem
                                      key={item.menu_id}
                                      value={item.menu_id}
                                    >
                                      <span>{item.menu_identifier}</span>
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText error>
                              {touched.menu_id && errors.menu_id
                                ? errors.menu_id
                                : null}
                            </FormHelperText>
                          </FormControl>


                        </Grid>

                        <Grid item xs={6} md={3} lg={3}  >
                          <FormControl fullWidth>

                            <InputLabel id="demo-dialog-select-label">Category</InputLabel>

                            <Select
                              labelId="select-category"
                              id="category_id"
                              error={
                                touched.category_id &&
                                  errors.category_id
                                  ? true
                                  : false
                              }
                              name="category_id"
                              value={selectedCategory}
                              onChange={(e, val) =>
                                this.handleCategoryChange(e, setFieldValue)
                              }
                              input={<OutlinedInput id="category_id" label="Category" />}
                              defaultValue={selectedCategory}
                              className="formControl" >
                              {categoryNames &&
                                categoryNames.map((item) => {
                                  return (
                                    <MenuItem
                                      fullWidth
                                      key={item.category_id}
                                      value={item.category_id}
                                    >
                                      {item.category_identifier}
                                    </MenuItem>
                                  );
                                })}

                            </Select>
                            <FormHelperText error>
                              {touched.category_id && errors.category_id
                                ? errors.category_id
                                : null}
                            </FormHelperText>
                          </FormControl>


                        </Grid> */}
                                  {/*     <Grid item xs={6} md={3} lg={3}  >
                            <FormControl fullWidth  >
                              <InputLabel id="discount_type"
                              >Discount Type</InputLabel>
                              <Select
                                labelId="discount_type"
                                id="discount_type"
                                name="discount_type"
                                variant="standard"
                                input={<OutlinedInput id="discount_type" label="Discount Type" />}
                                value={this.state.selectedDiscount || ''}
                                label="Discount Type"
                                onChange={(e) => {
                                  this.setState({ selectedDiscount: e.target.value });
                                  setFieldValue("discount_type", e.target.value);
                                }}
                                onBlur={(e) => {
                                  this.setState({ selectedDiscount: e.target.value });
                                  setFieldValue("discount_type", e.target.value);
                                }}
                              >

                                {discountTypeOptions.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText error>
                                {touched.discount_type && errors.discount_type
                                  ? errors.discount_type
                                  : null}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
 */}
                                  {/*  <Grid item xs={6} md={3} lg={3} >
                            <FormControl fullWidth >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                  <DesktopDateTimePicker
                                    error={
                                      touched.activity_start_date &&
                                        errors.activity_start_date
                                        ? true
                                        : false
                                    }
                                    name="discount_start_date"
                                    label="Activity Start Date"
                                    variant="standard"
                                    ampm={false}
                                    value={values.activity_start_date || dayjs(new Date())}
                                    views={['year', 'day', 'hours', 'minutes', 'seconds']}
                                    minDate={dayjs()}
                                    inputFormat="DD-MM-YYYY HH:mm:ss"
                                    showToolbar={false}
                                    components={{
                                      OpenPickerIcon: () => null, // Remove date picker icon
                                      TimePicker: () => null, // Use DigitalClock for time 
                                    }}
                                    onChange={(day) => {
                                      const formattedDay = dayjs(day).format('DD-MM-YYYY HH:mm:ss');

                                      if (dayjs(day).isValid()) {
                                        setFieldValue("activity_start_date", dayjs(day));
                                      } else {
                                        console.error("Invalid date/time");
                                      //  setFieldValue("activity_start_date", '');
                                        // Handle the error, show a message, or perform any other action
                                      }

                                    }}
                                    InputProps={{
                                      endAdornment: <InputAdornment position="start"><CalendarMonthIcon /></InputAdornment>,
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </Stack>
                              </LocalizationProvider>
                              <FormHelperText error>
                                {touched.activity_start_date && errors.activity_start_date
                                  ? errors.activity_start_date
                                  : null}
                              </FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item xs={6} md={3} lg={3} >
                            <FormControl fullWidth >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                  <MobileDateTimePicker
                                    error={
                                      touched.activity_end_date &&
                                        errors.activity_end_date
                                        ? true
                                        : false
                                    }
                                    name="activity_end_date"
                                    label="Activity End Date"
                                    variant="standard"
                                    views={['year', 'day', 'hours', 'minutes', 'seconds']}
                                    ampm={false}
                                    value={values.activity_end_date || dayjs(new Date())}
                                    inputFormat="DD-MM-YYYY HH:mm:ss"
                                    minDate={dayjs()}
                                    onChange={(day) => {
                                      const formattedDay = dayjs(day).format('DD-MM-YYYY');
                                      setFieldValue("activity_end_date", dayjs(day));

                                    }}
                                    InputProps={{
                                      endAdornment: <InputAdornment position="start"><CalendarMonthIcon /></InputAdornment>,
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </Stack>
                              </LocalizationProvider>
                              <FormHelperText error>
                                {touched.activity_end_date && errors.activity_end_date
                                  ? errors.activity_end_date
                                  : null}
                              </FormHelperText>
                            </FormControl>

                          </Grid> */}
                                  <Grid item xs={12} md={6} lg={6}>
                                    <Space style={{}}>
                                      {/*  <RangePicker showTime size={"large"}  disabledDate={this.disabledDate} disabledTime={this.disabledRangeTime}
                              format="DD-MM-YYYY HH:mm:ss" value={defaultRange} onChange={this.handleRangeChange} style={{ height: '50px' }} /> */}
                                      <RangePicker
                                        showTime
                                        size={"mini"}
                                        placement={"bottomLeft"}
                                        disabledDate={this.disabledDate}
                                        disabledTime={this.disabledRangeTime}
                                        format="DD-MM-YYYY HH:mm:ss"
                                        value={defaultRange}
                                        onChange={this.handleRangeChange}
                                        onCalendarChange={(value, dateStrings) => {
                                          this.setState({
                                            selectedStartDate: dateStrings[0],
                                          });
                                          console.log(value);
                                          // Handle the selected range here
                                          if (value && value.length === 2) {
                                            const [startDate, endDate] = value.map((date) =>
                                              dayjs(date, "DD-MM-YYYY HH:mm:ss")
                                            );
                                      
                                            this.setState({
                                              error: "",
                                              startDate: startDate,
                                              endDate: endDate,
                                              selectedStartDate: startDate,
                                              defaultRange: [startDate, endDate],
                                            });
                                          }
                                        }}
                                        changeOnBlur={true}
                                        showOk={false}
                                        showNow={false}
                                        style={{ height: "50px" }}
                                      />
                                      
                                    </Space>
                                    <FormHelperText error>
                                        {error}
                                      </FormHelperText>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <div
                                      style={{
                                        lineHeight: "62px",
                                        width: "100%",
                                        overflow: "auto",
                                      }}
                                    >
                                      <TreeSelect
                                        error={
                                          touched.item_list && errors.item_list
                                            ? true
                                            : false
                                        }
                                        showSearch={true}
                                        notFoundContent={"No Data"}
                                        allowClear
                                        treeCheckable
                                        treeData={treeData}
                                        value={value}
                                        size={"large"}
                                        filterTreeNode={(input, treeNode) => {
                                          // You can implement your custom search logic here
                                          // Return true to show the treeNode in the filtered results
                                          // Return false to hide the treeNode from the filtered results
                                          // For example, you can match the input against the node's title
                                          return treeNode?.props?.title
                                            ?.toLowerCase()
                                            .includes(input?.toLowerCase());
                                        }}
                                        //  treeCheckedStrategy={TreeSelect.SHOW_PARENT}
                                        placeholder="Please select items"
                                        treeCheckStrictly={treeCheckStrictly}
                                        onChange={(value) => {
                                          this.setState({ value });
                                          setFieldValue("item_list", value);
                                        }}
                                      />
                                    </div>
                                    <FormHelperText error>
                                      {touched.item_list && errors.item_list
                                        ? errors.item_list
                                        : null}
                                    </FormHelperText>
                                  </Grid>
                                </Grid>

                                <Grid
                                  container
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  direction="row"
                                >
                                  <Grid item xs={12} md={6} lg={6}>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      variant="contained"
                                      block
                                      sx={{
                                        mt: 2,
                                        mb: 2,
                                        width: "300px",
                                        height: "40px",
                                      }}
                                    >
                                      {this.state?.discount
                                        ? "Update"
                                        : "Submit"}
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    {this.state?.discount && (
                                      <Button
                                        type="button"
                                        color="primary"
                                        variant="contained"
                                        block
                                        onClick={() => this.handleCancel()}
                                        sx={{
                                          mt: 2,
                                          mb: 2,
                                          width: "300px",
                                          height: "40px",
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                              </Box>
                            </Form>
                          )}
                        </Formik>
                      </Grid>
                      <Grid container xs={12} md={4} lg={4}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Box sx={{ padding: "20px", paddingTop: "0px" }}>
                            <Paper
                              elevation={3}
                              sx={{
                                width: "auto",
                              }}
                            >
                              <Card>
                                <Typography
                                  variant="h5"
                                  gutterBottom
                                  style={{
                                    background: "#5773ff",
                                    textAlign: "center",
                                    color: "white",
                                    margin: "0px",
                                    padding: "6px 0px",
                                  }}
                                >
                                  Instructions
                                </Typography>
                                <CardContent>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Typography color="textPrimary" paragraph>
                                      <b>
                                      This section is designated for the creation of discounts. Once created, these discounts can be applied across your range of products.
                                      </b>
                                    </Typography>
                                  </Box>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    sx={{ my: 1 }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b> Activity Name</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Enter the discount name for the
                                        activity.
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b> Discount value(percentage) .</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Set a fixed percentage from the order
                                        total.
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b>Select Items</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Select Items from the dropdown to apply
                                        discount on those items.
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b> Discount Start and End Date.</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Discount valid until specified end date.
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Paper>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                {!showForm && (
                  <div className="ms-invoice-table table-responsive mt-5">
                    <DataTable
                      columns={this.columns}
                      customStyles={this.customStyles}
                      pagination
                      paginationRowsPerPageOptions={[
                        10, 25, 50, 100, 500, 1000,
                      ]}
                      data={this.state.discounts}
                      expandableRows
                      expandableRowsComponent={<ExpandableComponent />}
                    />
                  </div>
                )}
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default connect(mapStateToProps, {})(index);
