import React from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import * as schema from "../../../utils/Schema";
import MultipleImageCropper from "../../../shared/Cropper/MultipleImageCropper";
import {
  addMenu,
  updateMenu,
} from "../../../services/product/menu/MenuServiceAPI";
import Loader from "../../../shared/Loader";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TimePicker } from 'antd';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import Divider from '@mui/material/Divider';
import { InputLabel } from '@mui/material';
import coming from '../../../assets/img/default.jpg';
import {
  setSelectedMenu,
} from "../../../redux/menu/menuSlice";
const timings =[
  {
      "day": "MONDAY",
      "open_time": "00:00:00",
      "close_time": "23:59:59"
  },
  {
      "day": "TUESDAY",
      "open_time": "00:00:00",
      "close_time": "23:59:59"
  },
  {
      "day": "WEDNESDAY",
      "open_time": "00:00:00",
      "close_time": "23:59:59"
  },
  {
      "day": "THURSDAY",
      "open_time": "00:00:00",
      "close_time": "23:59:59"
  },
  {
      "day": "FRIDAY",
      "open_time": "00:00:00",
      "close_time": "23:59:59"
  },
  {
      "day": "SATURDAY",
      "open_time": "00:00:00",
      "close_time": "23:59:59"
  },
  {
      "day": "SUNDAY",
      "open_time": "00:00:00",
      "close_time": "23:59:59"
  }
];
class AddEditMenu extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    const TIME_FORMAT = "HH:mm:ss";
    this.state = {
      file: null,
      imagesPreviewUrl: null,
      submitting: false,
      menuToEdit: this.props.menuToEdit ? this.props.menuToEdit : null,
      initialValues: {},
      menuIdentifier: this.props.menuToEdit ? this.props.menuToEdit.menu_identifier.length : 0,
      menuDesc: this.props.menuToEdit ? this.props.menuToEdit.menu_description.length : 0,
      menu_timings: this.props.menuToEdit ? this.props.menuToEdit.menu_timings : timings,
      newTimings: [],
      start_time: this.props.menuToEdit?.start_time
        ? dayjs(this.props.menuToEdit.start_time, TIME_FORMAT).isValid()
          ? dayjs(this.props.menuToEdit.start_time, TIME_FORMAT).startOf("minute")
          : dayjs("00:00:00", TIME_FORMAT).startOf("minute")
        : dayjs("00:00:00", TIME_FORMAT).startOf("minute"),
      end_time: this.props.menuToEdit?.end_time
        ? dayjs(this.props.menuToEdit.end_time, TIME_FORMAT).isValid()
          ? dayjs(this.props.menuToEdit.end_time, TIME_FORMAT)
          : dayjs("23:59:59", TIME_FORMAT)
        : dayjs("23:59:59", TIME_FORMAT),
    };
  }

  componentDidMount() {
    this.setInitalValues();
  }

  setInitalValues = async() => {
    let addInitialValues = {
      is_buffet: this.state.menuToEdit
        ? this.state.menuToEdit.is_buffet
        : false,
      // is_time_based: this.state.menuToEdit
      //   ? this.state.menuToEdit.is_time_based
      //   : false,
      menu_description: this.state.menuToEdit
        ? this.state.menuToEdit.menu_description
        : "",
      menu_identifier: this.state.menuToEdit
        ? this.state.menuToEdit.menu_identifier
        : "",
      menu_image: this.state.menuToEdit ? this.state.menuToEdit.menu_image : "",
      menu_sort_order: this.state.menuToEdit
        ? this.state.menuToEdit.menu_sort_order
        : "",
      total_cost: this.state.menuToEdit ? this.state.menuToEdit.total_cost : 0,
      //    start_time: this.state.start_time,
      //    end_time: this.state.end_time,
      menu_timings: this.state.menuToEdit?.menu_timings || timings,
      mobile_availability: this.state.menuToEdit
        ? this.state?.menuToEdit?.menu_availability?.mobile_availability
        : true,
      kiosk_availability: this.state.menuToEdit
        ? this.state.menuToEdit?.menu_availability?.kiosk_availability
        : true,
      epos_availability: this.state.menuToEdit
        ? this.state.menuToEdit?.menu_availability?.epos_availability
        : true,
      web_availability: this.state.menuToEdit
        ? this.state.menuToEdit?.menu_availability?.web_availability
        : true,
        business_app_availability: this.state.menuToEdit
        ? this.state.menuToEdit?.menu_availability?.business_app_availability
        : true,
    };
    this.setState({
      initialValues: addInitialValues,
      imagesPreviewUrl: this.state.menuToEdit
        ? this.state.menuToEdit.menu_image
        : null,
    });
    if (!this.state.menuToEdit) {
      try {
        const response = await fetch(coming);

        if (!response.ok) {
          throw new Error('Failed to fetch the image.');
        }

        const blob = await response.blob();
        const reader = new FileReader();
        const file = new File([blob], 'coming.jpg', { type: blob.type });

        reader.onloadend = () => {
          // Convert the image to a data URL
          const base64Data = reader.result;
          console.log(reader)
          this.setState({
            imagesPreviewUrl: base64Data,
            file: file
          });
        };

        reader.onerror = (error) => {
          console.error('Error occurred while reading the image:', error);
        };

        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error occurred while fetching the image:', error);
      }
    }
  };

  handleMenu = (values, resetForm) => {
    if (this.state.menuToEdit) {
      this.updateMenu(values);
    } else {
      this.submitMenu(values, resetForm);
    }
  };


  submitMenu = (values, resetForm) => {
    console.log(values,"onsubmit")
    this.setState({ submitting: true });
    let formData = new FormData();
    formData.append("is_buffet", values.is_buffet);
  //  formData.append("is_time_based", values.is_time_based);
    formData.append("menu_description", values.menu_description);
    formData.append("menu_identifier", values.menu_identifier);
    formData.append("menu_sort_order", values.menu_sort_order);
    formData.append("property_id", this.props.propertyId);
    formData.append("total_cost", values.total_cost);
    if (this.state.file) {
      formData.append("menu_image", this.state.file);
    }
    /*   if (values.is_time_based) {
        formData.append("start_time", dayjs(values.start_time).format('HH:mm:ss'));
        formData.append("end_time", dayjs(values.end_time).format('HH:mm:ss'));
      } */

      let menu_availability = {
        "kiosk_availability": values.kiosk_availability,
        "epos_availability": values.epos_availability,
        "mobile_availability": values.mobile_availability,
        "web_availability": values.web_availability,
        "business_app_availability":values.business_app_availability

      }
  

      

    formData.append("menu_availability", JSON.stringify(menu_availability));

    formData.append("menu_timings", JSON.stringify(this.state.menu_timings));


    

    addMenu(formData)
      .then((response) => {
        if (response.data.status) {
          this.setState(
            {
              submitting: false,
            },
            () => {
              this.props.setSelectedMenu(null)
              this.props.fetchMenuNames(values.menu_identifier);
              //  this.props.setMenuAfterAdding(values.menu_identifier);
            }
          );
          resetForm({ values: '' })
          this.setState({ imagesPreviewUrl: '' });
          toast.success("Menu Added");
          this.props.handleQuickAdd("Close");
        } else {
          this.setState({
            submitting: false,
          });
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        });
        toast.error("Something went wrong");
      });
  };

  updateMenu = (values) => {
    this.setState({ submitting: true });
    let formData = new FormData();
    formData.append("is_buffet", values.is_buffet);
//    formData.append("is_time_based", values.is_time_based);
    formData.append("menu_description", values.menu_description);
    formData.append("menu_identifier", values.menu_identifier);
    formData.append("menu_sort_order", values.menu_sort_order);
    formData.append("property_id", this.props.propertyId);
    formData.append("total_cost", values.total_cost);
    formData.append("menu_id", this.state.menuToEdit.menu_id);

    if (this.state.file) {
      formData.append("menu_image", this.state.file);
    }
    /*   if (values.is_time_based) {
        formData.append("start_time", dayjs(values.start_time).format('HH:mm:ss'));
        formData.append("end_time", dayjs(values.end_time).format('HH:mm:ss'));
      } */

    let menu_availability = {
      "kiosk_availability": values.kiosk_availability,
      "epos_availability": values.epos_availability,
      "mobile_availability": values.mobile_availability,
      "web_availability": values.web_availability,
      "business_app_availability":values.business_app_availability
    }


    delete values.kiosk_availability;
    delete values.epos_availability;
    delete values.mobile_availability;
    delete values.web_availability;
    delete values.business_app_availability;

    formData.append("menu_availability", JSON.stringify(menu_availability));

    formData.append("menu_timings", JSON.stringify(this.state.menu_timings));



    updateMenu(formData)
      .then((response) => {
        if (response.data.status) {
          this.setState(
            {
              submitting: false,
            },
            () => {
              this.props.fetchMenuNames();
            }
          );
          toast.success("Menu Updated");
          this.props.handleQuickAdd("Close");
        } else {
          this.setState({
            submitting: false,
          });
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        });
        toast.error("Something went wrong");
      });
  };
  // Invoked from Multi Cropper Child Component, returns cropped form data
  getCroppedImages = (croppedImagesFormData, key, imageArray, file) => {
    if (key === "menu") {
      this.setState(
        {
          file: file[0],
          imagesPreviewUrl: imageArray[0],
        },
        () => {
          console.log("this.stae", file);
        }
      );
    } else {
      console.log("No cropper key returned!");
    }
  };

  onMenuTimingChange = (value, name, index) => {
    let newTimings = [...this.state.menu_timings]
    if (name === "close_time") newTimings[index].close_time = value
    if (name === "open_time") newTimings[index].open_time = value
    this.setState({
      menu_timings: newTimings
    })

  }

  render() {
    const { submitting, initialValues } = this.state;
    return (
      <>
        <Grid container spacing={3} style={{ padding: 10 }}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" className={"title"}>
              {this.props.title}
              {this.props.showClose && (
                <span style={{ float: "right" }}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => this.props.handleQuickAdd("Close")}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Formik
              enableReinitialize="true"
              initialValues={initialValues}
              validationSchema={schema.menuSchema}
              onSubmit={(values, { resetForm }) => {
                this.handleMenu(values, resetForm);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    alignItems="center"
                  >


                    <Grid item xs={6} md={6}>
                      <FormControl fullWidth>

                        <TextField
                          error={
                            touched.menu_identifier && errors.menu_identifier
                              ? true
                              : false
                          }
                          margin="normal"
                          type="text"
                          id="menu_identifier"
                          label="Name"
                          name="menu_identifier"
                          autoComplete="menu_identifier"
                          autoFocus
                          size="small"
                          variant="standard"
                          fullWidth
                          onBlur={handleBlur}
                          value={values.menu_identifier}
                          onChange={(e) => {
                            handleChange(e);
                            console.log(300 - e.target.value.length);
                            this.setState({
                              menuIdentifier: e.target.value.length,
                            });
                            console.log(this.state.menuIdentifier);
                          }}
                          aria-describedby="component-error-text"
                          helperText={
                            touched.menu_identifier && errors.menu_identifier
                              ? errors.menu_identifier
                              : null
                          }
                        />

                        &nbsp;
                      </FormControl>
                      <span style={{ color: "red" }} >{40 - this.state.menuIdentifier}</span> / 40
                      {/* <TextField
                      error={
                        touched.total_cost && errors.total_cost ? true : false
                      }
                      margin="normal"
                      required
                      type="number"
                      id="total_cost"
                      label="Price"
                      name="total_cost"
                      autoComplete="total_cost"
                      size="small"
                      variant="standard"
                      onBlur={handleBlur}
                      value={values.total_cost}
                      onChange={handleChange}
                      aria-describedby="component-error-text"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: "any",
                        },
                      }}
                      helperText={
                        touched.total_cost && errors.total_cost
                          ? errors.total_cost
                          : null
                      }
                    /> 
                    &nbsp;*/}

                    </Grid>


                    <Grid item xs={6} md={6}>
                      <FormControl fullWidth>

                        <TextField
                          error={
                            touched.menu_sort_order && errors.menu_sort_order
                              ? true
                              : false
                          }
                          margin="normal"
                          type="number"
                          id="menu_sort_order"
                          label="Sort order"
                          name="menu_sort_order"
                          autoComplete="menu_sort_order"
                          size="small"
                          variant="standard"
                          style={{ position: "relative", bottom: "11px" }}
                          onBlur={handleBlur}
                          value={values.menu_sort_order}
                          onChange={handleChange}
                          onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                          }}
                          aria-describedby="component-error-text"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              step: "1",
                            },
                          }}
                          helperText={
                            touched.menu_sort_order && errors.menu_sort_order
                              ? errors.menu_sort_order
                              : null
                          }
                        />
                        &nbsp;
                      </FormControl>
                    </Grid>


                  </Grid>

                  {/*      <Grid
                    container
                    spacing={2}
                    direction="row"
                    alignItems="center"
                  >

                    <Grid item xs={4} md={4}>
                  
                    </Grid>

                  </Grid>
 */}
                  {/*      {values.is_time_based && <React.Fragment>
                    <Grid item xs={12} md={12}>
                      <Typography variant="h6" gutterBottom>
                        Set time-based availability for your menu
                      </Typography>
                      <Divider sx={{ marginBottom: "20px" }} />
                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >

                      <Grid item xs={4} md={4}>
                        <FormControl> */}

                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <TimePicker
                              error={
                                touched.start_time && errors.start_time
                                  ? true
                                  : false
                              }
                              label="Start"
                              name="start_time"
                              ampm={false}
                              inputFormat="HH:mm:ss"
                              views={['hours', 'minutes', 'seconds']}
                              value={values.start_time}
                              //        onChange={(newValue) => this.handleStartTime(newValue)}
                              onChange={(time) => {
                                console.log(dayjs(time, 'HH:mm:ss').format('HH:mm:ss'));
                                setFieldValue("start_time", dayjs(time, 'HH:mm:ss'));

                              }}
                              //   onChange={value => setFieldValue("time", value)}

                              helperText={
                                touched.start_time && errors.start_time
                                  ? errors.start_time
                                  : null
                              }
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider> */}
                  {/*       <InputLabel sx={{ position: "relative", left: "-13px", top: "-11px" }} htmlFor="start-time-picker">Start Time</InputLabel>

                          <TimePicker name="start_time"
                            value={values.start_time} size="large"
                            format="HH:mm:ss"
                            allowClear={false}
                            onChange={(time, timeString) => {
                              if (timeString) { // check if time string is not empty
                                const isValidTime = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/.test(timeString); // validate time format
                                if (isValidTime) {
                                  setFieldValue("start_time", time);
                                } else {
                                  console.error("Invalid time format: ", timeString);
                                  setFieldValue("start_time", null); // handle the error by setting a default value or displaying an error message
                                }
                              } else {
                                setFieldValue("start_time", null);
                              }
                            }}
                            onBlur={(event) => {
                              const timeString = event.target.value;
                              if (timeString) {
                                const isValidTime = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/.test(timeString); // validate time format
                                if (isValidTime) {
                                  const time = dayjs(timeString, 'HH:mm:ss');
                                  setFieldValue("start_time", time);
                                } else {
                                  console.error("Invalid time format: ", timeString);
                                  setFieldValue("start_time", null); // handle the error by setting a default value or displaying an error message
                                }
                              } else {
                                setFieldValue("start_time", null);
                              }
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={4} md={4}>
                        <FormControl>

                          <InputLabel sx={{ position: "relative", left: "-13px", top: "-11px" }} htmlFor="start-time-picker">End Time</InputLabel> */}
                  {/*   <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DesktopTimePicker
                              error={
                                touched.end_time && errors.end_time
                                  ? true
                                  : false
                              }
                              label="End"
                              name="end_time"
                              ampm={false}
                              inputFormat="HH:mm:ss"

                              value={values.end_time}
                              onChange={(time) => {
                                console.log(dayjs(time, 'HH:mm:ss').format('HH:mm:ss'));
                                setFieldValue("end_time", dayjs(time, 'HH:mm:ss'));
                              }}
                              helperText={
                                touched.end_time && errors.end_time
                                  ? errors.end_time
                                  : null
                              }
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider> */}

                  {/*         <TimePicker
                            name="end_time"
                            value={values.end_time}
                            format="HH:mm:ss"
                            allowClear={false}
                            onChange={(time, timeString) => {
                              if (timeString) { // check if time string is not empty
                                const isValidTime = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/.test(timeString); // validate time format
                                if (isValidTime) {
                                  setFieldValue("end_time", time);
                                } else {
                                  console.error("Invalid time format: ", timeString);
                                  setFieldValue("end_time", null); // handle the error by setting a default value or displaying an error message
                                }
                              } else {
                                setFieldValue("end_time", null);
                              }
                            }}
                            onBlur={(event) => {
                              const timeString = event.target.value;
                              if (timeString) {
                                const isValidTime = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/.test(timeString); // validate time format
                                if (isValidTime) {
                                  const time = dayjs(timeString, 'HH:mm:ss');
                                  setFieldValue("end_time", time);
                                } else {
                                  console.error("Invalid time format: ", timeString);
                                  setFieldValue("end_time", null); // handle the error by setting a default value or displaying an error message
                                }
                              } else {
                                setFieldValue("end_time", null);
                              }
                            }}
                          />








                        </FormControl>
                      </Grid>

                    </Grid> </React.Fragment>} */}



              
                  <Grid item xs={12} md={12}>
                    <TextField
                      error={
                        touched.menu_description && errors.menu_description
                          ? true
                          : false
                      }
                      margin="normal"
                      type="text"
                      fullWidth
                      multiline
                      rows={5}
                      id="menu_description"
                      label="Description"
                      name="menu_description"
                      autoComplete="menu_description"
                      size="small"
                      variant="standard"
                      onBlur={handleBlur}
                      value={values.menu_description}
                      onChange={(e) => {
                        handleChange(e);
                        console.log(300 - e.target.value.length);
                        this.setState({
                          menuDesc: e.target.value.length,
                        });
                        console.log(this.state.menuDesc);
                      }}
                      aria-describedby="component-error-text"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                      helperText={
                        touched.menu_description && errors.menu_description
                          ? errors.menu_description
                          : null
                      }
                    />
                    <span style={{ color: "red" }} >{300 - this.state.menuDesc}</span> / 300
                  </Grid>
                  

                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    {/* <Grid item xs={6} md={6} sx={{display:"none"}}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.is_buffet == true}
                            onChange={handleChange}
                            name="is_buffet"
                            color="primary"
                            inputProps={{
                              "aria-label": "is_buffet checkbox",
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Is buffet"
                      />
                    </Grid> */}
                  </Grid>

                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                  >
                {/*     <Grid item xs={6} md={6} lg={3} >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.is_time_based == true}
                            onChange={handleChange}
                            name="is_time_based"
                            color="primary"
                            inputProps={{
                              "aria-label": "is_time_based checkbox",
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Enable Time "
                      />
                    </Grid> */}

<Grid item xs={12} md={4} lg={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.mobile_availability}
                            onChange={handleChange}
                            name="mobile_availability"
                            color="primary"
                            inputProps={{
                              "aria-label": "mobile_availability checkbox",
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Enable Mobile "
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.kiosk_availability}
                            onChange={handleChange}
                            name="kiosk_availability"
                            color="primary"
                            inputProps={{
                              "aria-label": "kiosk_availability checkbox",
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Enable Kiosk"
                      />
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.epos_availability}
                            onChange={handleChange}
                            name="epos_availability"
                            color="primary"
                            inputProps={{
                              "aria-label": "epos_availability checkbox",
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Enable Epos"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.web_availability}
                            onChange={handleChange}
                            name="web_availability"
                            color="primary"
                            inputProps={{
                              "aria-label": "web_availability checkbox",
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Enable Web"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.business_app_availability}
                            onChange={handleChange}
                            name="business_app_availability"
                            color="primary"
                            inputProps={{
                              "aria-label": "business_app_availability checkbox",
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Enable Business App"
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography variant="h6" gutterBottom>
                      Set time-based availability for your menu
                    </Typography>
                    <Divider sx={{ marginBottom: "20px" }} />
                  </Grid>

                  { this.state.menu_timings && this.state.menu_timings.map((tt, i) => (
                    <div className="row" key={i}>
                      <div className="col-md-3">
                        <div className="form-group">
                          {/* <label>Day</label> */}
                          <div className="input-group">
                            <input
                              type="text"
                              value={tt.day}
                              className="form-control no_caret"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          {/* <label>Open Time</label> */}
                          <div className="input-group">
                            {/*   <input
                              type="time"
                              onChange={(e) =>
                                this.onMenuTimingChange(
                                  e.target.value,
                                  "open_time",
                                  i
                                )
                              }
                              value={tt.open_time}
                              className="form-control"
                            /> */}

                            <TimePicker
                              name="start_time"
                              id="start_time"
                              value={dayjs(tt.open_time, 'HH:mm:ss')}
                              format="HH:mm:ss"
                              allowClear={false}
                              changeOnBlur={true} showOk={false}  showNow={false}
                              onCalendarChange={(time, timeString) => {
                                if (timeString) { // check if time string is not empty
                                  const isValidTime = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/.test(timeString); // validate time format
                                  if (isValidTime) {
                                    this.onMenuTimingChange(
                                      timeString,
                                      "open_time",
                                      i
                                    )
                                  } else {
                                    console.error("Invalid time format: ", timeString);
                                    //   setFieldValue("end_time", null); // handle the error by setting a default value or displaying an error message
                                  }
                                } else {
                                  //  setFieldValue("end_time", null);
                                }
                              }
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          {/* <label>Close Time</label> */}
                          <div className="input-group">
                            {/*  <input
                              type="time"
                              className="form-control"
                              onChange={(e) =>
                                this.onMenuTimingChange(
                                  e.target.value,
                                  "close_time",
                                  i
                                )
                              }
                              value={tt.close_time}
                            /> */}
                            <TimePicker
                              name="end_time"
                              id="end_time"
                              value={dayjs(tt.close_time, 'HH:mm:ss')}
                              format="HH:mm:ss"
                              allowClear={false}
                              changeOnBlur={true} showOk={false}  showNow={false}
                              onCalendarChange={(time, timeString) => {
                                if (timeString) { // check if time string is not empty
                                  const isValidTime =  /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/.test(timeString); // validate time format
                                  if (isValidTime) {
                                    this.onMenuTimingChange(
                                      timeString,
                                      "close_time",
                                      i
                                    )
                                  } else {
                                    console.error("Invalid time format: ", timeString);
                                    //   setFieldValue("end_time", null); // handle the error by setting a default value or displaying an error message
                                  }
                                } else {
                                  //  setFieldValue("end_time", null);
                                }
                              }
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}



                  <Grid >
                    <MultipleImageCropper
                      data={{
                        key: "menu",
                        isMultiple: false,
                        getCroppedImages: this.getCroppedImages,
                      }}
                      height={300}
                      width={300}
                    />
                    <br></br>   <br></br>
                    <img className="cover"
                      src={
                        this.state.imagesPreviewUrl
                          ? this.state.imagesPreviewUrl
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12} style={{ paddingTop: 5 }}>
                    {Array.isArray(errors) ||
                      (Object.values(errors).toString() != "" && (
                        <Alert severity="warning">
                          {Object.values(errors).toString()}
                        </Alert>
                      ))}
                    {submitting ? (
                      <Loader size={30} />
                    ) : (
                      <Button
                        type="submit"
                        block
                        color="primary"
                        sx={{ mt: 2, mb: 2 }}
                      >
                        {this.props.title == "Add Menu"
                          ? "Add Menu"
                          : "Update Menu"}
                      </Button>
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  propertyId: state.user.propertyId,
});

const mapDispatchToProps = {
  setSelectedMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditMenu);
