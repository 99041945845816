import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Grid, FormControl, FormControlLabel, MenuItem, Button, FormHelperText,
  Select, InputLabel, Typography, Divider, Checkbox, TextField
} from "@mui/material";
import Geocode from "react-geocode";
import ReactSelect from "react-select";
import "react-phone-input-2/lib/style.css";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countryList from "react-select-country-list";
import { connect } from 'react-redux';
import { useStepperContext, StepperContext } from "../../contexts/UseContextProvider";
import * as SecurityServiceAPI from "../../../../../../services/user/SecurityServiceAPI";
import { setLegalEntityId } from "../../../../../../redux/user/userSlice";
import { toast } from "react-toastify";
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';

const validationOrganisationSchema = Yup.object().shape({
  entityType: Yup.string().required('Company Type is required'),
  organisationType: Yup.string().required('Organisation Structure is required'),
  organisationRegistrationNumber: Yup.string().required('Registration Number is required'),
  legalName: Yup.string().required('Legal Name of the Company is required'),
  organisationPhone: Yup.string().required('Contact Number is required'),
  organisationEmail: Yup.string().required('Email is required').email('Invalid email address'),
  organisationStreet: Yup.string().required('Company Street is required'),
  organisationCity: Yup.string().required('City is required'),
  organisationPostalCode: Yup.string().required('Postal Code is required'),
  organisationCountry: Yup.string().required('Country is required'),
});

const validationIndividualSchema = Yup.object().shape({
  individualFirstName: Yup.string().required('First Name is required'),
  individualLastName: Yup.string().required('Last Name is required'),
  individualEmail: Yup.string().email('Invalid email address').required('Email is required'),
  individualResidentialAddress: Yup.string().required('Street is required'),
  individualCity: Yup.string().required('City is required'),
  individualPostalCode: Yup.string().required('Postal Code is required'),
  individualCountry: Yup.string().required('Country is required'),
  individualDateOfBirth: Yup.string().required('Date of Birth is required'),
});

const customStyles = {
  control: (base) => ({
    ...base,
    height: 56,
    minHeight: 56,
    zIndex: "0 !important",
  }),
};

class Details extends React.Component {
  static contextType = StepperContext;
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
      formErrors: {},
      isSubmitting: false,
      loading: false,
      compType: "",
      checked: true,
      initialValues: {},
      entityType: '',
      country: {},
      individualCountry: {},
      organisationPhone: '',
      organisation: {},
      individual: {},
      val: null,
      checked: true,
      orgStruct: "organization"
    };
  }

  componentDidMount() {
    this.props.fetchProperty();
    console.log(this.props);

    this.fetchPaymentAccount();
    const { userData, setUserData, actStep, setActStep } = this.context;
    console.log(userData)
  /*   setTimeout(() => {
      this.setInitalValues();
    }, 1000); */
    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey("AIzaSyAbquQkju85Ho0VUlMI_TgWP6hUQlzZ7UI");

    // set response language. Defaults to english.
    Geocode.setLanguage("en");

    // set response region. Its optional.
    // A Geocoding request with region=es (Spain) will return the Spanish city.
    Geocode.setRegion("EU");

    // set location_type filter . Its optional.
    // google geocoder returns more that one address for given lat/lng.
    // In some case we need one address as response for which google itself provides a location_type filter.
    // So we can easily parse the result for fetching address components
    // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
    // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
    Geocode.setLocationType("ROOFTOP");

    // Enable or disable logs. Its optional.
    Geocode.enableDebug();
  }

  setInitalValues = async (orgInfo) => {
    const { orgStruct, individualCountry } = this.state;
    let addInitialValues = {};
    if (orgInfo?.entityType == "organization" || orgStruct == "organization") {
      addInitialValues = {
        entityType: orgInfo?.entityType || "organization",
        organisationType: orgInfo?.organisationType || "",
        organisationRegistrationNumber: orgInfo?.organisationRegistrationNumber || "",
        legalName: orgInfo?.legalName || this.props.initialValues.property_details.property_name || this.props.property.property_name || "",
        organisationPhone: orgInfo?.organisationPhone || this.props.property.contact_number || "",
        organisationEmail: orgInfo?.organisationEmail || "",
        organisationStreet: orgInfo?.organisationStreet || this.props.initialValues.property_details.property_address || "",
        organisationCity: orgInfo?.organisationCity || this.props.initialValues.property_details.city || "",
        organisationCountry: orgInfo?.organisationCountry || "GB",
        organisationPostalCode: orgInfo?.organisationPostalCode || this.props.initialValues.property_details.postal_code || "",
      };
    } else if (orgInfo?.entityType == "individual" || orgStruct == "individual") {
      addInitialValues = {
        entityType: orgInfo?.entityType || "individual",
        individualFirstName: orgInfo?.individualFirstName || "",
        individualLastName: orgInfo?.individualLastName || "",
        individualEmail: orgInfo?.individualEmail || this.props.initialValues.property_details.email || this.props.property.email || "",
        individualResidentialAddress: orgInfo?.individualResidentialAddress || this.props.initialValues.property_details.property_address || "",
        individualCity: orgInfo?.individualCity || this.props.initialValues.property_details.city || this.props.property.city || "",
        individualPostalCode: orgInfo?.individualPostalCode || this.props.initialValues.property_details.postal_code || "",
        individualCountry: orgInfo?.individualCountry || individualCountry.country_code || this.props.property.country_code || "GB",
        individualDateOfBirth: orgInfo?.individualDateOfBirth || dayjs().format("YYYY-MM-DD")
      };
    }
    const country = {country_code : this.props.property.country_code, organisationCountry: this.props.property.country};
    const iCountry = {country_code : this.props.property.country_code, individualCountry: this.props.property.country};
    console.log(country);
    this.setState({
      initialValues: addInitialValues,
      country: country,
      individualCountry: iCountry,  
    });
    console.log(this.props.property)
  };
  setInitalValEmpty = async (orgInfo) => {
    let addInitialValues = {
      entityType: orgInfo?.entityType || "organization",
      organisationType: orgInfo?.organisationType || "",
      organisationRegistrationNumber: orgInfo?.organisationRegistrationNumber || "",
      legalName: orgInfo?.legalName || "",
      organisationPhone: orgInfo?.organisationPhone || "",
      organisationEmail: orgInfo?.organisationEmail || "",
      organisationStreet: orgInfo?.organisationStreet || "",
      organisationCity: orgInfo?.organisationCity || "",
      organisationCountry: orgInfo?.organisationCountry || "GB",
      organisationPostalCode: orgInfo?.organisationPostalCode || "",
    };
    this.setState({
      initialValues: addInitialValues
    });
  };

  fetchPaymentAccount = () => {
    const payload = this.props.propertyId || localStorage.getItem("property_id");
    const { userData, setUserData, actStep, setActStep } = this.context;
    if (localStorage.getItem("property_id") !== null) {
      SecurityServiceAPI.fetchLegalEntity(payload)
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status) {
            //     this.setState({ loading: false, show: false });
            //    toast.success("Message Sent Successfully");
            console.log(response.data.data.organization);

            let org = response.data.data?.organization;
            let individual = response.data.data?.individual;
            if (org) {
              this.setState({ orgStruct: "organization" });
              const orgInfo = {
                entityType: "organization",
                legalName: org?.legalName,
                doingBusinessAs: org?.doingBusinessAs,
                organisationCity: org?.registeredAddress?.city,
                organisationCountry: org?.registeredAddress?.country && countryList().getLabel(
                  org?.registeredAddress?.country
                ),
                organisationPostalCode: org?.registeredAddress?.postalCode,
                organisationEmail: org?.email,
                organisationPhone: org?.phone?.number,
                organisationStreet: org?.registeredAddress?.street,
                organisationType: org?.type,
                organisationRegistrationNumber: org?.registrationNumber,
              };

              this.setInitalValues(orgInfo);

              console.log(orgInfo);

              setUserData({ ...userData, ...orgInfo });
            } else if (individual) {
              this.setState({ orgStruct: "individual" });
              const individualInfo = {
                entityType: "individual",
                individualFirstName: individual?.name?.firstName,
                individualLastName: individual?.name?.lastName,
                individualDateOfBirth: individual?.birthData?.dateOfBirth,
                individualCity: individual?.residentialAddress?.city,
                individualCountry: countryList().getLabel(individual?.residentialAddress?.country),
                individualPostalCode: individual?.residentialAddress?.postalCode,
                individualEmail: individual?.email,
                individualResidentialAddress: individual?.residentialAddress?.street,
              };
              this.setInitalValues(individualInfo);
              setUserData({ ...userData, ...individualInfo });
              console.log(individualInfo);
            }



            //   this.setState({ summary: response[0].data.data });
          } else {
            setTimeout(() => {
              this.handleCopyAddress(true);
            }, 1000);
          }
        })
        .catch((error) => {
          //  this.setState({ loading: false, show: false });
          console.log(error);
          // props.setCompType("individual");
        });
    }
  };

  getCountryCode = (countryName) => {
    const countries = countryList().getData();
    const selectedCountry = countries.find(country => country.label === countryName);

    // Check if the country is found
    if (selectedCountry) {
      return selectedCountry.value; // Return the country code
    } else {
      return null; // Country not found
    }
  }

  handleoPhoneChangeHandler = (value) => {
    this.setState({ organisationPhone: value });
  };

  handleSub = (info) => {
    console.log(info)
    let data;
    const isUSState = this.props.property.country_code === 'US';
    if (info.entityType === "organization") {
      data = {
        property_id: this.props.propertyId || localStorage.getItem("property_id"),
        payload: {
          organization: {
            legalName: info.legalName,
            doingBusinessAs: info.property_trading_name
              ? info.property_trading_name
              : this.props.initialValues.property_details.property_trading_name,
            type: info.organisationType,
            registeredAddress: {
              street: info.organisationStreet,
              city: info.organisationCity,
              ...(isUSState && { stateOrProvince: this.props.property.state }),
              postalCode: info.organisationPostalCode?.toUpperCase(),
              country: info.country
                ? countryList().getValue(info.country)
                : this.props.initialValues.property_details.country_code,
            },
            phone: {
              number: info.organisationPhone.startsWith("+") ? info.organisationPhone : "+" + info.organisationPhone,
              type: "mobile"
            },
            email: info.organisationEmail,
            description: info.legalName,
            registrationNumber: info.organisationRegistrationNumber,
          },
          reference: info.legalName,
          type: info.entityType,
        },
      };
    } else if (info.entityType === "individual") {
      data = {
        property_id: this.props.propertyId || localStorage.getItem("property_id"),
        type: info.entityType,
        payload: {
          type: info.entityType,
          individual: {
            residentialAddress: {
              street: info.individualResidentialAddress,
              city: info.individualCity,
              postalCode: info.individualPostalCode?.toUpperCase(),
              ...(isUSState && { stateOrProvince: this.props.property.state }),
              country: info.individualCountry
                ? info.individualCountry
                : this.props.property.country_code,
            },
            name: {
              firstName: info.individualFirstName,
              lastName: info.individualLastName,
            },
            birthData: {
              dateOfBirth: info.individualDateOfBirth,
            },
            email: info.individualEmail,
          },
        },
      };
    }

    console.log(data)
    this.setState({ val: data }, () => {
      if (info.entityType === "organization") {
        this.handleLegelEntityForOrganisation(data);
      } else if (info.entityType === "individual") {
        this.handleLegelEntityForIndividual(data);
      }
    });

  };

  handleLegelEntityForOrganisation = (data) => {
    //  this.setState({ loading: true });
    const { userData, setUserData, actStep, setActStep } = this.context;
    this.setState({ isLooading: true })


    var legalEntity;


    if (!this.props.initialValues.adyen?.adyen_info?.legal_entity_id) {
      SecurityServiceAPI.createLegalEntity(data)
        .then((response) => {
          if (response.data.status) {
            localStorage.setItem("legalEntityId", JSON.stringify(response.data.data));
            legalEntity = { legalEntityId: response.data.data };
            setUserData({ ...userData, ...legalEntity });
            this.props.setLegalEntityId(response.data.data);
          } else {
            this.setState({ isLooading: false })
            toast.warn(response.data.message);
          }
        })
        .then(() => {
          if (legalEntity) {
            this.createBusinessLine();
          }
        })
        .catch((error) => {
          this.setState({ loading: false })
          toast.error("Oops! Something went wrong");
          //  this.setState({ loading: false });
        });
    } else {

      //  createBusinessLine();
      let payload = Object.assign({}, data);
      payload.type = "ORGANIZATION";
      delete payload.payload.type;
      SecurityServiceAPI.updateLegalEntity(payload)
        .then((response) => {
          if (response.data.status) {
          } else {
            toast.warn(response.data.message);
            this.setState({ loading: false })
          }
        })
        .then(() => {
          if (this.props.initialValues.adyen?.adyen_info?.legal_entity_id) {
            this.updateBusinessLine();
          }
        })
        .catch((error) => {
          toast.error("Oops! Something went wrong");
          this.setState({ loading: false });
        });
    }
  };

  createBusinessLine = () => {
    //  this.setState({ loading: true });
    const { userData, setUserData, actStep, setActStep } = this.context;
    let legalEntityId = JSON.parse(localStorage.getItem("legalEntityId")) ? JSON.parse(localStorage.getItem("legalEntityId"))
      : this.props.initialValues.adyen?.adyen_info?.legal_entity_id;

    let payload = {
      property_id: this.props.propertyId || localStorage.getItem("property_id"),
      payload: {
        capability: "receivePayments",
        industryCode: localStorage.getItem("industryCode") || this.props.industryCode || localStorage.getItem("business_code"),
        legalEntityId: legalEntityId ? legalEntityId : userData.legalEntityId,
        salesChannels: ["pos"],
        webData: [
          {
            webAddress: "https://www.kayana.co.uk/",
          },
        ],
      },
    };

    SecurityServiceAPI.createBusinessLine(payload)
      .then((response) => {
        if (response.data.status) {
          toast.success("Payment account created sucessfully!");
          this.props.handleClick("next");
          this.setState({ loading: false })
          this.props.handleStep();
          this.props.fetchProperty();
        } else {
          toast.warn(response.data.message);
          this.setState({ loading: false })
        }
      })
      .catch((error) => {
        this.setState({ isLooading: false });
        toast.error("Oops! Something went wrong");
      });
  };

  updateBusinessLine = () => {
    const { userData, setUserData, actStep, setActStep } = this.context;

    if (this.props.initialValues.adyen?.adyen_info?.legal_entity_id) {
      let legalEntityId =
        this.props.initialValues.adyen?.adyen_info?.legal_entity_id;
      let payload = {
        property_id: this.props.propertyId || localStorage.getItem("property_id"),
        payload: {
          capability: "receivePayments",
          industryCode:
            localStorage.getItem("industryCode") || this.props.industryCode,
          legalEntityId: legalEntityId ? legalEntityId : userData.legalEntityId,
          salesChannels: ["pos"],
          webData: [
            {
              webAddress: "https://www.kayana.co.uk/",
            },
          ],
        },
      };
      SecurityServiceAPI.updateBusinessLine(payload)
        .then((response) => {
          if (response.data.status) {
            toast.success("Payment account updated sucessfully!");
            this.setState({ isLooading: false })
            this.props.handleClick("next");
            this.props.handleStep();
          } else {
            toast.warn(response.data.message);
            this.setState({ looading: false })
          }
        })
        .catch((error) => {
          this.setState({ Loading: false })
          toast.error("Oops! Something went wrong");
        });
    } else {
      this.setState({ Loading: false })
      this.props.handleClick("next");
    }
  };

  handleLegelEntityForIndividual = (data) => {
    const { userData, setUserData, actStep, setActStep } = this.context;
    this.setState({ isLooading: true })
    const { val } = this.state;
    console.log(val)
    let payload = {
      property_id: this.props.propertyId || localStorage.getItem("property_id"),
      type: "SOLE PROPRIETOR",
      payload: {
        soleProprietorship: {
          countryOfGoverningLaw: val.individualCountry
            ? val.individualCountry
            : this.props.initialValues.property_details.country_code,
          name: this.props.property.property_name,
          doingBusinessAs: this.props.property.property_trading_name,
          registeredAddress: { ...val.payload?.individual?.residentialAddress },
        },
        type: "soleProprietorship",
      },
    };

    if (!this.props.initialValues.adyen?.adyen_info?.legal_entity_id) {
      const allPromises = [
        SecurityServiceAPI.createLegalEntity(data), // Populate Dropdown values for Business Types // Step #1
      ];

      Promise.all(allPromises)
        .then((responses) => {
          if (responses[0].data.status) {
            localStorage.setItem(
              "legalEntityId",
              JSON.stringify(responses[0].data.data)
            );
            let legalEntity = { legalEntityId: responses[0].data.data };
            setUserData({ ...userData, ...legalEntity });
            return SecurityServiceAPI.createLegalEntity(payload);
          } else {
            this.setState({ Loading: false })
          }
        })
        .then((resp1) => {
          if (resp1.data.status) {
            let legalEntityId =
              this.props.initialValues.adyen?.adyen_info
                ?.sole_proprieter_legal_entity_id;
            let payload = {
              property_id: this.props.propertyId || localStorage.getItem("property_id"),
              type: "INDIVIDUAL",
              payload: {
                entityAssociations: [
                  {
                    legalEntityId: resp1.data.data,
                    type: "soleProprietorship",
                  },
                ],
              },
            };
            return SecurityServiceAPI.updateLegalEntity(payload);
          } else {
            this.setState({ Loading: false })
          }
        })
        .then((resp2) => {
          if (resp2.data.status) {
            this.createBusinessLine();
          }
        })
        .catch((err) => {
        });
    } else {

      let payload = Object.assign({}, data);
      delete payload.payload.type;

      const allPromises = [
        SecurityServiceAPI.updateLegalEntity(payload), // Populate Dropdown values for Business Types // Step #1
      ];

      let payloads = {
        property_id: this.props.propertyId || localStorage.getItem("property_id"),
        type: "SOLE PROPRIETOR",
        payload: {
          soleProprietorship: {
            countryOfGoverningLaw: val.individualCountry
              ? val.individualCountry
              : this.props.initialValues.property_details.country_code,
            name: this.props.property.property_name,
            doingBusinessAs: this.props.property.property_trading_name,
            registeredAddress: { ...val.payload?.individual?.residentialAddress },
          },
        },
      };

      Promise.all(allPromises)
        .then((responses) => {
          return SecurityServiceAPI.updateLegalEntity(payloads);
        })
        .then((resp) => {
          if (!this.props.initialValues.adyen?.adyen_info?.business_line_id) {
            this.createBusinessLine();
          } else {
            this.updateBusinessLine();
          }
        })
        .then(() => {
          this.setState({ loading: true });
          this.props.handleClick("next");
        })
        .catch((err) => {
          this.setState({ Loading: true })
        });
    }
  };


  getFullAddress = async (address, setFieldValue) => {
    try {
      const response = await Geocode.fromAddress(address);
      console.log(response);

      const addressComponents = response.results[0].address_components;
      let city = "";
      let state = "";
      let country = "";
      let postalCode = "";
      let countryCode = "";
      for (let i = 0; i < addressComponents.length; i++) {
        const component = addressComponents[i];
        const types = component.types;
        if (types.includes("locality")) {
          city = component.long_name;
          if (city) {
            setFieldValue("organisationCity", city);
          }
        } else if (types.includes("administrative_area_level_2")) {
          state = component.long_name;
        } else if (types.includes("country")) {
          country = component.long_name;
          countryCode = component.short_name;
          if (countryCode) {
            setFieldValue("organisationCountry", country);
          }
        } else if (types.includes("postal_code")) {
          postalCode = component.long_name;
          if (postalCode) {
            setFieldValue("organisationPostalCode", postalCode);
          }
        } else if (types.includes("postal_town")) {
          city = component.long_name;
          if (city) {
            setFieldValue("organisationCity", city);
          }
        }
      }
      console.log(city, state, country, postalCode, countryCode);
    } catch (error) {
      console.error(error);
    }
  }

  handleCopyAddress = (checked) => {
    const { initialValues } = this.state;
    console.log("checked" + checked);
    const { userData, setUserData, actStep, setActStep } = this.context;
    console.log(localStorage.getItem("email"));
    if (checked === true) {
      console.log("Inside");

      if (userData.contact_number) {
        var phone = initialValues?.organisationPhone || this.props.property.contact_number;
      }

      const total = {
        entityType: initialValues?.entityType || "organization",
        legalName: initialValues?.legalName || this.props.initialValues.property_details.property_name,
        doingBusinessAs: initialValues?.organisationPhone || this.props.initialValues.property_details.property_trading_name,
        organisationCity: initialValues?.organisationCity || this.props.initialValues.property_details.city,
        organisationCountry: initialValues?.organisationCountry || this.props.initialValues.country,
        organisationPostalCode: initialValues?.organisationPostalCode || this.props.initialValues.property_details.postal_code,
        organisationPhone:  initialValues?.organisationPhone || this.props.property.contact_number,
        organisationStreet: initialValues?.organisationStreet || this.props.initialValues.property_details.property_address,
      };

      console.log(total);
      this.setInitalValues(total);
      setUserData({ ...userData, ...total });
    } else {
      const total = {
        legalName: "",
        doingBusinessAs: "",
        organisationCity: "",
        organisationCountry: "",
        organisationPostalCode: "",
        organisationEmail: "",
        organisationPhone: "",
        organisationStreet: "",
      };

      this.setInitalValEmpty(total);

      setUserData({ ...userData, ...total });
    }
  };

  handleIndividualCopyAddress = (checked) => {
    const { initialValues} = this.state;
    console.log("checked" + checked);
    const { userData, setUserData, actStep, setActStep } = this.context;
    console.log(localStorage.getItem("email"));
    if (checked === true) {
      console.log("Inside");
      const total = {
        entityType: initialValues?.entityType || "individual",
        individualCity: initialValues?.organisationCity || this.props.initialValues.property_details.city,
        individualCountry: initialValues?.organisationCountry || this.props.initialValues.country,
        individualPostalCode: initialValues?.organisationPostalCode || this.props.initialValues.property_details.postal_code,
        individualStreet: initialValues?.organisationStreet || this.props.initialValues.property_details.property_address,
      };

      console.log(total);
      this.setInitalValues(total);
      setUserData({ ...userData, ...total });
    } else {
      let dial_code = this.props.initialValues.property_details.dial_code;
      const total = {
        individualCity: "",
        individualCountry: "",
        individualPostalCode: "",
        individualStreet: "",
      };

      this.setInitalValEmpty(total);

      setUserData({ ...userData, ...total });
    }
  };


  handleCheckboxChange = (event) => {
    console.log(event.target.checked);
    this.setState({ checked: event.target.checked });
    this.handleCopyAddress(event.target.checked);
  };

  handleIndividualChange = (event) => {
    console.log(event.target.checked);
    this.setState({ checked: event.target.checked });
    this.handleIndividualCopyAddress(event.target.checked);
  };

  render() {
    const { checked, initialValues, entityType, country, orgStruct, individualCountry } = this.state;
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={orgStruct == "organization" ? validationOrganisationSchema : validationIndividualSchema}
        onSubmit={values => {
          this.handleSub(values);
          console.log(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setField,
          isSubmitting }) => (
          <Form>
            <Grid item xs={12} md={12} style={{ paddingTop: 5, marginTop: 10 }}>
              <Typography variant="h5" gutterBottom>
                Create Payment Account
              </Typography>
              <Divider variant="middle" />
            </Grid>
            <br />
            <Grid container spacing={2} >

              <>
                <Grid item xs={12} md={4}>

                  <FormControl fullWidth error={touched.entityType && errors.entityType ? true : false}>
                    <InputLabel id="companyType">
                      Company Type
                    </InputLabel>
                    <Select
                      labelId="companyType"
                      id="companyType"
                      name="entityType"
                      fullWidth
                      value={values.entityType || ""}
                      onChange={(event) => {
                        handleChange(event);
                        this.setState({ orgStruct: event.target.value }, () => {
                          this.setInitalValues();
                        });
                      }}
                      label="Organisation Structure">
                      <MenuItem value={"organization"}>Organisation </MenuItem>
                      <MenuItem value={"individual"}>Individual </MenuItem>
                    </Select>
                    <FormHelperText>
                      {touched.entityType && errors.entityType ? errors.entityType : null}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {values.entityType == "organization" ? (
                  <React.Fragment>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth error={touched.organisationType && errors.organisationType ? true : false}>
                        <InputLabel id="organisationType-label">Organisation Structure</InputLabel>
                        <Select
                          labelId="organisationType-label"
                          id="organisationType"
                          name="organisationType"
                          value={values.organisationType || ""}
                          onChange={handleChange}
                          label="Organisation Structure"
                        >
                          <MenuItem value={"privateCompany"}>Private Ltd. </MenuItem>
                          <MenuItem value={"governmentalOrganization"}>Govt Organisation</MenuItem>
                          <MenuItem value={"partnershipIncorporated"}>Partnership Incorporated</MenuItem>
                          <MenuItem value={"nonProfit"}>Non Profit</MenuItem>
                          <MenuItem value={"associationIncorporated"}>Association Incorporated</MenuItem>
                        </Select>
                        <FormHelperText>
                          {touched.organisationType && errors.organisationType ? errors.organisationType : null}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          error={touched.organisationRegistrationNumber && errors.organisationRegistrationNumber ? true : false}
                          id="organisationRegistrationNumber"
                          name="organisationRegistrationNumber"
                          fullWidth
                          label="Registration Number"
                          type="text"
                          inputProps={{ maxLength: 12 }}
                          value={values.organisationRegistrationNumber || ""}
                          onChange={handleChange}
                          helperText={
                            touched.organisationRegistrationNumber &&
                              errors.organisationRegistrationNumber
                              ? errors.organisationRegistrationNumber
                              : null
                          }
                        />

                      </FormControl>
                    </Grid>


                    <Grid item xs={12} md={12}>
                      <FormControlLabel
                        label="Same as Kayana Setup"
                        control={<Checkbox checked={checked} onChange={this.handleCheckboxChange} />}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          error={touched.legalName && errors.legalName ? true : false}
                          id="legalName"
                          name="legalName"
                          fullWidth
                          label="Legal Name of the Company"
                          type="text"
                          value={values.legalName || ""}
                          onChange={handleChange}
                          helperText={
                            touched.legalName && errors.legalName ? errors.legalName : null
                          }
                        />

                      </FormControl>

                    </Grid>

                    <Grid item xs={12} md={4}>
                      <ReactPhoneInput
                        inputExtrathis={{
                          name: "organisationPhone",
                          required: true,
                          autoFocus: false,
                        }}
                        inputStyle={{
                          width: "100%",
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                          height: "56px",
                        }}
                        required
                        // country={"gb"}
                        name="organisationPhone"
                        specialLabel="Contact Number"
                        searchPlaceholder="Search Country Code"
                        value={values.organisationPhone}
                        enableSearchField
                        // placeholder="0207*******"
                        onChange={(value, country, event, formattedValue, setField) => {
                          console.log(value, country, formattedValue);
                          setFieldValue('organisationPhone', value);
                          this.handleoPhoneChangeHandler(value)
                        }
                        }
                      />

                      <FormHelperText>
                        {errors.organisationPhone && touched.organisationPhone && (
                          <span style={{ color: 'red' }}>Phone no is required</span>
                        )}
                      </FormHelperText>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          error={touched.organisationEmail && errors.organisationEmail ? true : false}
                          id="organisationEmail"
                          name="organisationEmail"
                          fullWidth
                          label="Email"
                          type="text"
                          value={values.organisationEmail || ""}
                          onChange={handleChange}
                          helperText={
                            touched.organisationEmail && errors.organisationEmail ? errors.organisationEmail : null
                          }
                        />
                      </FormControl>

                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          error={touched.organisationStreet && errors.organisationStreet ? true : false}
                          id="organisationStreet"
                          name="organisationStreet"
                          fullWidth
                          label="Company Street"
                          type="text"
                          value={values.organisationStreet || ""}
                          onChange={handleChange}
                          onBlur={(e) => {
                            this.getFullAddress(e.target.value, setFieldValue);
                          }}
                          helperText={
                            touched.organisationStreet && errors.organisationEmail ? errors.organisationStreet : null
                          }
                        />
                      </FormControl>

                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          error={touched.organisationCity && errors.organisationCity ? true : false}
                          id="organisationCity"
                          name="organisationCity"
                          fullWidth
                          label="City"
                          type="text"
                          value={values.organisationCity || ""}
                          onChange={handleChange}
                          helperText={
                            touched.organisationCity && errors.organisationCity ? errors.organisationCity : null
                          }
                        />
                      </FormControl>

                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          id="organisationPostalCode"
                          error={touched.organisationPostalCode && errors.organisationPostalCode ? true : false}
                          name="organisationPostalCode"
                          fullWidth
                          label="Postal Code"
                          type="text"
                          value={values.organisationPostalCode || ""}
                          onChange={handleChange}
                          helperText={
                            touched.organisationPostalCode && errors.organisationPostalCode ? errors.organisationPostalCode : null
                          }
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <ReactSelect
                        id="organisationCountry"
                        name="organisationCountry"
                        error={touched.organisationCountry && errors.organisationCountry ? true : false}
                        styles={customStyles}
                        className="lab"
                        classNamePrefix="react-select"
                        options={this.props.initialValues.country_options}
                        value={{
                          label: country.organisationCountry || "United Kingdom",
                          value: country.country_code || "GB",
                        }}
                        onChange={(selectedOption) => {
                          setFieldValue('organisationCountry', selectedOption.value);
                          let con = {
                            organisationCountry: selectedOption.label,
                            country_code: selectedOption.value,
                            country: selectedOption.label,
                          };
                          this.setState({ country: con });
                        }}
                        helperText={
                          touched.organisationCountry && errors.organisationCountry ? errors.organisationCountry : null
                        }
                      />
                      <FormHelperText>
                        {errors.organisationCountry && touched.organisationCountry && (
                          <span style={{ color: 'red' }}>Country is required</span>
                        )}
                      </FormHelperText>

                    </Grid>
                  </React.Fragment>
                )
                  : (
                    <React.Fragment>


                      <Grid item xs={12} md={4} >
                        <FormControl fullWidth>
                          <TextField
                            id="individualFirstName"
                            name="individualFirstName"
                            error={touched.individualFirstName && errors.individualFirstName ? true : false}
                            fullWidth
                            label="First Name"
                            type="text"
                            value={values.individualFirstName || ""}
                            onChange={handleChange}
                            helperText={
                              touched.individualFirstName && errors.individualFirstName ? errors.individualFirstName : null
                            }
                          />
                        </FormControl>

                      </Grid>


                      <Grid item xs={12} md={4} >
                        <FormControl fullWidth>
                          <TextField
                            error={touched.individualLastName && errors.individualLastName ? true : false}
                            id="individualLastName"
                            name="individualLastName"
                            fullWidth
                            label="Last Name"
                            type="text"
                            value={values.individualLastName || ""}
                            onChange={handleChange}
                            helperText={
                              touched.individualLastName && errors.individualLastName ? errors.individualLastName : null
                            }
                          />
                        </FormControl>

                      </Grid>

                      <Grid item xs={12} md={4} >
                        <FormControl fullWidth>
                          <TextField
                            error={touched.individualEmail && errors.individualEmail ? true : false}
                            id="individualEmail"
                            name="individualEmail"
                            fullWidth
                            label="Email"
                            type="text"
                            value={values.individualEmail || ""}
                            onChange={handleChange}
                            helperText={
                              touched.individualEmail && errors.individualEmail ? errors.individualEmail : null
                            }
                          />
                        </FormControl>
                      </Grid>


                      <Grid item xs={12} md={4} >

                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <MobileDatePicker
                              label="Date of Birth"
                              inputFormat="YYYY-MM-DD"
                              name="individualDateOfBirth"
                              value={dayjs(values.individualDateOfBirth)}
                              onChange={(newValue) => {
                                if (newValue) {
                                  setFieldValue('individualDateOfBirth', dayjs(newValue).format("YYYY-MM-DD"));
                                }
                              }}
                              defaultValue={null}
                              renderInput={(params) => <TextField {...params} />}
                              InputProps={{
                                endAdornment: <InputAdornment position="start"><CalendarMonthIcon /></InputAdornment>,
                              }}
                              helperText={
                                touched.individualDateOfBirth && errors.individualDateOfBirth ? errors.individualDateOfBirth : null
                              }
                            />            
                          </Stack>
                        </LocalizationProvider>
                          <FormHelperText>
                            {errors.individualDateOfBirth && touched.individualDateOfBirth && (
                              <span style={{ color: 'red' }}>Date of birth is required</span>
                            )}
                          </FormHelperText>
                       </FormControl>



                      </Grid>





                      <Grid item xs={12} md={12} style={{ paddingTop: 5, marginTop: 10 }} >
                        <Typography variant="h5" gutterBottom>
                          Personnel Address
                        </Typography>

                      </Grid>




                      <Grid item xs={12} md={12} >



                        <FormControlLabel
                          label="Same as Kayana Setup"
                          control={<Checkbox checked={checked} onChange={this.handleIndividualChange} />}
                        />

                      </Grid>




                      <Grid item xs={12} md={4} >
                        <FormControl fullWidth>
                          <TextField
                            error={touched.individualResidentialAddress && errors.individualResidentialAddress ? true : false}
                            id="individualResidentialAddress"
                            name="individualResidentialAddress"
                            fullWidth
                            label="Street"
                            type="text"
                            value={values.individualResidentialAddress || ""}
                            onChange={handleChange}
                            onBlur={(e) => {
                              this.getFullAddress();
                            }}
                            helperText={
                              touched.individualResidentialAddress && errors.individualResidentialAddress ? errors.individualResidentialAddress : null
                            }
                          />
                        </FormControl>

                      </Grid>




                      <Grid item xs={12} md={4} >
                        <FormControl fullWidth>
                          <TextField
                            error={touched.individualCity && errors.individualCity ? true : false}
                            id="individualCity"
                            name="individualCity"
                            fullWidth
                            label="City"
                            type="text"
                            value={values.individualCity || ""}
                            onChange={handleChange}
                            helperText={
                              touched.individualCity && errors.individualCity ? errors.individualCity : null
                            }
                          />
                        </FormControl>

                      </Grid>








                      <Grid item xs={12} md={4} >
                        <FormControl fullWidth>
                          <TextField
                            error={touched.individualPostalCode && errors.individualPostalCode ? true : false}
                            id="individualPostalCode"
                            name="individualPostalCode"
                            fullWidth
                            label="Postal Code"
                            type="text"
                            value={values.individualPostalCode || ""}
                            onChange={handleChange}
                            helperText={
                              touched.individualPostalCode && errors.individualPostalCode ? errors.individualPostalCode : null
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={4} >

                        <ReactSelect
                          id="individualCountry"
                          name="individualCountry"
                          styles={customStyles}
                          options={this.props.initialValues.country_options}
                          onChange={(selectedOption) => {
                            setFieldValue('individualCountry', selectedOption.value);
                            let con = {
                              individualCountry: selectedOption.label,
                              country_code: selectedOption.value,
                              country: selectedOption.label,
                            };
                            this.setState({ individualCountry: con });
                          }}
                          value={{
                            label: individualCountry.individualCountry || "United Kingdom",
                            value: individualCountry.country_code || "GB",
                          }}

                        />
                        <FormHelperText>
                        {errors.individualCountry && touched.individualCountry && (
                          <span style={{ color: 'red' }}>Country is required</span>
                        )}
                      </FormHelperText>
                      </Grid>
                    </React.Fragment>
                  )
                }
              </>

            </Grid>
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, mb: 2 }}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {
    property: state.property.property,
    propertyId: state.user.propertyId,
  };
};
const mapDispatchToProps = {
  setLegalEntityId
};
export default connect(mapStateToProps, mapDispatchToProps)(Details);
