import React, { Component, Fragment } from "react";
import * as OrderServiceAPI from "../../../services/order/OrderServiceAPI";
import * as DashboardServiceAPI from '../../../services/dashboard/DashboardServiceAPI'
import * as ReportServiceAPI from '../../../services/reports/ReportServiceAPI'
import DataTableComponet from 'react-data-table-component';
import Pagination from "../../../shared/Pagination/Pagination";
import Breadcrumb from "./Breadcrumb";
import '../../../index.css';
import { toast } from "react-toastify";
import DataTable from 'react-data-table-component';
import { IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Loader from "../../../shared/Loader";
import $ from "jquery";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DownloadingIcon from '@mui/icons-material/Downloading';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import ReactDOM from 'react-dom';
import { Line } from '@ant-design/plots';
import { Column } from '@ant-design/plots';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemText, Button } from '@mui/material';
import { ItemList } from './ItemList/ItemList.js'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Box from '@mui/material/Box';
import Papa from 'papaparse';
import { connect } from "react-redux";
import ReactGA from 'react-ga';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={1}>{children}</Box>}
        </div>
    );
}



class Content extends Component {

    customStyles = {
        rows: {
            style: {
                //              minHeight: '70px', // override the row height
            }
        },
        headCells: {
            style: {
                //                   minHeight: '65px',

                backgroundColor: '#5773ff',
                fontSize: '14px',
                color: 'white',
                justifyContent: "center",
                '&:hover div': {
                    backgroundColor: 'transparent',
                    color: 'white !important'
                },
                'div': {
                    color: 'white !important'
                },
                'span': {
                    color: 'white !important'
                },

            },
        },
        cells: {
            style: {

                pointer: 'cursor',

            },
        },
    };

    customHeader = ({ column }) => {
        return (
            <div style={{ textAlign: column.center ? "center" : "" }}>
                {column.name}
            </div>
        );
    };

    DAILY = [

        {
            name: 'Date',
            selector: 'Date',
            sortable: true,
            cell: (row) => (
                <div style={{ cursor: "pointer" }} >
                    <div>
                        {row['Date']}
                    </div>
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },

        },
        {
            name: 'No of items',
            selector: 'Count',
            sortable: true,
            cell: (row) => (
                <div style={{ cursor: "pointer" }} >
                    <div>
                        {row['Count']}
                    </div>
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },

        },


    ];

    HOURLY = [

        {
            name: 'Date',
            selector: 'Date',
            sortable: true,
            cell: (row) => (
                <div style={{ cursor: "pointer" }} >
                    <div>
                        {row['Date']}
                    </div>
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },

        },


        {
            name: 'Hour',
            selector: 'Hour',
            sortable: true,
            cell: (row) => (
                <div className="" /* onClick={() => this.fetchOrderDetailsByOrderId(row)} */>
                    {row['Hour']}
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },
        },

        {
            name: 'No of items',
            selector: 'Count',
            sortable: true,
            cell: (row) => (
                <div style={{ cursor: "pointer" }} >
                    <div>
                        {row['Count']}
                    </div>
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },

        },


    ];

    MONTHLY = [

        {
            name: 'Year',
            selector: 'Year',
            sortable: true,
            cell: (row) => (
                <div style={{ cursor: "pointer" }} >
                    <div>
                        {row['Year']}
                    </div>
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },

        },
        {
            name: 'Month',
            selector: 'Month',
            sortable: true,
            cell: (row) => (
                <div style={{ cursor: "pointer" }} >
                    <div>
                        {row['Month']}
                    </div>
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },

        },
        {
            name: 'No of items',
            selector: 'Count',
            sortable: true,
            cell: (row) => (
                <div style={{ cursor: "pointer" }} >
                    <div>
                        {row['Count']}
                    </div>
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },

        },


    ];

    YEARLY = [

        {
            name: 'Year',
            selector: 'year',
            sortable: true,
            cell: (row) => (
                <div style={{ cursor: "pointer" }} >
                    <div>
                        {row['Year']}
                    </div>
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },

        },
        {
            name: 'No of items',
            selector: 'Count',
            sortable: true,
            cell: (row) => (
                <div style={{ cursor: "pointer" }} >
                    <div>
                        {row['Count']}
                    </div>
                </div>
            ),
            style: {
                textAlign: "center", justifyContent: 'center'
            },

        },


    ];


    state = {
        page: 0,
        order_details: [],
        current_page: 0,
        current_orders_page: 0,
        number_of_elements: 15,
        page_size: 10,
        total_elements: 0,
        total_pages: 0,
        total_order_pages: 0,
        property_id: "",
        search_text: "",
        changeBool: null,
        from_date: null,
        to_date: null,
        date_options_dd: null,
        isLoading: false,
        count: 0,
        hideFilter: false,
        start: dayjs(new Date()).startOf('month'),
        end: dayjs(new Date()),
        data: [],
        topSellingItems: [],
        trendingItemsSalesReport: [],
        unSoldItems: [],
        currentPage: 1,
        itemsPerPage: 10,
        tab: 0,
        selectTimePeriod: "DAILY",
        selectReport: "Item",
        max: new Date(),
        min: dayjs(new Date()).subtract(3, 'year'),
        filterToday: false,
        filterYesterday: false,
        filterLastWeek:  false,
        filterLastMonth: false,
        filterLastYear: false

    };

    constructor(props) {
        super(props);
        // this.onChangeHandler = this.onChangeHandler.bind(this);
    }


    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);


        function setActiveMenuItem() {

            // get elements that are active and remove their active class
            var activeItem = $(".active");
            activeItem.removeClass("active");

            $(this).removeAttr("href");
            var listitems = $(this).parent("li");
            var element = listitems.children();
            localStorage.setItem("activeNavItem", element.attr("id"));
            if (element.hasClass("active")) {
                element.removeClass("active");
                element.find("li").removeClass("active");
                listitems.find(".collapse").slideUp();
            } else {
                element.addClass("active");
                listitems.children(".collapse").slideDown();
                listitems.siblings("li").children(".collapse").slideUp();
                element.siblings("li").removeClass("active");
                element.siblings("li").find("li").removeClass("active");
                listitems.siblings("li").find(".collapse").slideUp();
            }

        }
        setActiveMenuItem();
        if (!localStorage.getItem("activeNavItem")) {
            let pathArray = window.location.pathname.split("/");
            let currentPage = pathArray[1];
            $("#" + currentPage).addClass("active");
            $("#reports").addClass("active");
        } else {
            let pathArray = window.location.pathname.split("/");
            let currentPage = pathArray[1];
            $("#" + currentPage).addClass("active");
            $("#reports").addClass("active");
        }


    }

    handleExportCSV = () => {
        // Convert data to CSV format

        // Create a CSV file from the CSV data
        const csvString = Papa.unparse(this.state.trendingItemsSalesReport);

        // Create a data URL for the CSV file
        const dataUrl = `data:text/csv;charset=utf-8,${encodeURIComponent(csvString)}`;

        // Create a temporary anchor element to trigger the download
        const downloadLink = document.createElement('a');
        downloadLink.href = dataUrl;
        downloadLink.download = 'trending.csv';
        downloadLink.click();
    };





    fnCallback = (e) => {
        console.log(this.state)
        console.log(e?.target?.value)
        this.setState({ hideFilter: false });
        if (!e?.target?.value) {
            this.filterAllOrders();
        } else if (e?.target?.value == 1) { // Today
            let today = dayjs(new Date());
            this.setState({ start: today, end: today, filterToday : true, filterLastMonth: false, filterLastWeek : false,  filterYesterday : false, filterLastYear: false,  selectTimePeriod : "HOURLY" }, () => {
            });

        } else if (e?.target?.value == 2) { // Yesterday
            // var today = dayjs(new Date()).subtract(1, 'day');
            let yesterday = dayjs(new Date()).subtract(1, 'day');
            console.log(yesterday)
            this.setState({ start: yesterday, end: yesterday, filterToday : true, filterYesterday : true, filterLastMonth: false, filterLastWeek : false, filterLastYear: false, selectTimePeriod : "DAILY" }, () => {
            });

        } else if (e?.target?.value == 3) { // Last 7 Days
            let today = dayjs(new Date()).subtract(1, 'week').startOf('week');
            let last7 = dayjs(new Date()).subtract(1, 'week').endOf('week');
            /*   if (this.state.selectTimePeriod == "HOURLY") {
                  this.setState({ start: dayjs(new Date()).startOf('week'), end: dayjs(new Date()) }, () => {
                      this.getTrendingSalesReport();
                  });
              } else { */
            this.setState({ start: today, end: last7,  filterLastWeek : true, filterToday : true, filterYesterday : true, filterLastMonth: false, filterLastYear: false,  selectTimePeriod : "DAILY" }, () => {
            });
            //   }
        } else if (e?.target?.value == 4) { // Last 30 Days
            let today = dayjs(new Date()).subtract(1, 'month').startOf('month');
            let last30 = dayjs(new Date()).subtract(1, 'month').endOf('month');
            /*     if (this.state.selectTimePeriod == "HOURLY") {
                    this.setState({ start: dayjs(new Date()).startOf('week'), end: dayjs(new Date()) }, () => {
                        this.getTrendingSalesReport();
                    });
                } else { */
            this.setState({ start: today, end: last30,  filterLastMonth: true, filterLastWeek : true, filterToday : true, filterYesterday : true,  filterLastYear: false, selectTimePeriod : "DAILY"  }, () => {
            });
            //      }
        } else if (e?.target?.value == 5) { // Last Year
            let today = dayjs(new Date());
            let last30 = dayjs(new Date()).subtract(1, 'year').startOf('year');
            /*      if (this.state.selectTimePeriod == "HOURLY") {
                     this.setState({ start: dayjs(new Date()).startOf('week'), end: dayjs(new Date()) }, () => {
                         this.getTrendingSalesReport();
                     });
                 } else { */
            this.setState({ start: last30, end: today,  filterLastYear: true, filterLastMonth: true, filterLastWeek : true, filterToday : true, filterYesterday : true, selectTimePeriod : "DAILY"   }, () => {
            });
            //    }
        } else if (e?.target?.value == 6) { // Last 30 Days
            let today = dayjs(new Date());
            let firstDayOfMonth = dayjs(new Date()).startOf('month');

            /*    if (this.state.selectTimePeriod == "HOURLY") {
                   this.setState({ start: dayjs(new Date()).startOf('week'), end: dayjs(new Date()) }, () => {
                       this.getTrendingSalesReport();
                   });
               } else { */
            this.setState({ start: firstDayOfMonth, end: today,  filterLastMonth: false, filterLastWeek : true, filterToday : true, filterYesterday : true, filterLastYear: false, selectTimePeriod : "DAILY"  }, () => {
            });
            //       }
        } else if (e?.target?.value == 7) { // Last 30 Day
            this.setState({ hideFilter: true });
        }

    }


    selectTrenidngReportTimePeriod = (e) => {
        console.log(this.state)
        console.log(this.state.start.isSame(this.state.end))
        /*  if (e.target.value == "HOURLY") {
             this.setState({ selectTimePeriod: e.target.value, start: dayjs(new Date()).startOf('week'), end: dayjs(new Date()) }, () => {
                 this.getTrendingSalesReport();
 
             });
 
         } else { */
        this.setState({ selectTimePeriod: e.target.value,  trendingItemsSalesReport: [] }, () => {
        });
        //     }


    }



    getTrendingSalesReport = () => {

        this.setState({ isLoading: true });

        let payload = {
            start_date: this.state.start.format("DD-MM-YYYY"),
            end_date: this.state.end.format("DD-MM-YYYY"),
            property_id: this.props.propertyId,
            time_interval: this.state.selectTimePeriod
        }

        ReportServiceAPI.trendingItemSalesReport(payload)
            .then((response) => {
                console.log(response.data.status);
                if (response.data.status) {
                    console.log(response.data.data);

                    let trending = response.data.data;

                    if (this.state.selectTimePeriod == "HOURLY") {
                        trending.sort((a, b) => {
                            let [hourA, minuteA, periodA] = a.Hour.split(/[ :]/);
                            let [hourB, minuteB, periodB] = b.Hour.split(/[ :]/);

                            if (periodA === periodB) {
                                if (parseInt(hourA) === 12) {
                                    hourA = "00";
                                }
                                if (parseInt(hourB) === 12) {
                                    hourB = "00";
                                }
                                let timeA = parseInt(hourA) * 60 + parseInt(minuteA);
                                let timeB = parseInt(hourB) * 60 + parseInt(minuteB);
                                return timeA - timeB;
                            } else if (periodA === "AM") {
                                return -1;
                            } else {
                                return 1;
                            }
                        });
                    }


                    console.log(trending)


                    this.setState({ trendingItemsSalesReport: trending, isLoading: false })

                } else {
                    //     toast.error(response.data.message);
                    this.setState({ isLoading: false, trendingItemsSalesReport: [] });
                }
            })
            .catch((error) => {
                //  this.setState({ loading: false, show: false });
                this.setState({ isLoading: false });
                console.log(error);
            });
    }


    getTimeValue = (hourStr) => {
        const [hour, minute, period] = hourStr.split(/[:\s]/);
        const hourValue = parseInt(hour, 10) % 12;
        const minuteValue = parseInt(minute, 10);
        const periodValue = period.toUpperCase() === 'AM' ? 0 : 1;
        return hourValue * 60 + minuteValue + periodValue * 12 * 60;
    }


    getUnSoldItems = () => {

        let payload = {
            start_date: this.state.start.format("DD-MM-YYYY"),
            end_date: this.state.end.format("DD-MM-YYYY"),
            property_id: this.props.propertyId,
        }

        ReportServiceAPI.fetchUnSoldItems(payload)
            .then((response) => {
                console.log(response.data.status);
                if (response.data.status) {
                    console.log(response.data.data);


                    this.setState({ unSoldItems: response.data.data, isLoading: false })


                } else {
                    //     toast.error(response.data.message);
                    this.setState({ isLoading: false, unSoldItems: [] });
                }
            })
            .catch((error) => {
                //  this.setState({ loading: false, show: false });
                this.setState({ isLoading: false });
                console.log(error);
            });
    }


    handleTabChange = (event, newValue) => {
        this.setState({ tab: newValue });
    };

    handleToDate = (e) => {
        //       const ctrl_name = e.target?.name;
        console.log(e);
        console.log(dayjs(e).format('DD-MM-YYYY'));
        let toDat = dayjs(e);
        /*    this.setState({ end: toDat }, () => {
               this.getTrendingSalesReport();
           });
    */
        /*  if (this.state.selectTimePeriod == "HOURLY") {
             this.setState({ start:  dayjs(toDat).startOf('week'), end: dayjs(toDat).endOf('week') }, () => {
                 this.getTrendingSalesReport();
 
             });
 
         } else { */
        this.setState({ end: toDat }, () => {
        });
        //     }
    }

    handleFromDate = (e) => {
        //    const ctrl_name = e.target?.name;
        console.log(e);
        console.log(dayjs(e));
        let toDat = dayjs(e);
        /*   this.setState({ start: toDat }, () => {
  
              this.getTrendingSalesReport();
          }); */

        /*     if (this.state.selectTimePeriod == "HOURLY") {
                this.setState({ start:  dayjs(toDat).startOf('week'), end: dayjs(toDat).endOf('week') }, () => {
                    this.getTrendingSalesReport();
    
                });
    
            } else { */
        this.setState({ start: toDat }, () => {
        });
        //    }
    }


    render() {

        let counter = 1; // Initialize the counter

        // Function to generate a unique identifier
        function generateUniqueIdentifier() {
            let counter = 1; // Initialize the counter

            return () => {
                const uniqueIdentifier = `#${counter}`; // Customize the format of the unique identifier as needed
                counter++; // Increment the counter for the next identifier
                return uniqueIdentifier;
            };
        }
        const { tab } = this.state;
        let trendingHourlyReportConfig = {
            data: this.state.trendingItemsSalesReport,
            isStack: true,
            xField: 'Hour',
            yField: 'Count',
            seriesField: 'Date',
            label: {
                position: 'middle',
                layout: [
                    {
                        type: 'interval-adjust-position',
                    },
                    {
                        type: 'interval-hide-overlap',
                    },
                    {
                        type: 'adjust-color',
                    },
                ],
            },
            legend: false/* {
                layout: 'horizontal',
                position: 'top',
                itemValue: {
                    formatter: (text, item) => {
                   
                        const uniqueDates = Array.from(
                            new Set(this.state.trendingItemsSalesReport.map((d) => d.Date))
                        );
                        const index = uniqueDates.indexOf(item.name);             
                            return ""    
                    },

                },
            }, */,
            xAxis: {
                title: {
                    visible: true,
                    text: 'Hour of the day',
                },
            },
            yAxis: {
                title: {
                    visible: true,
                    text: 'Count',
                },
            },
        };

        let trendingReportConfig;

        if (this.state.selectTimePeriod == "DAILY") {
            trendingReportConfig = {
                title: {
                    visible: true,
                    text: 'Daily Chart',
                },
                data: this.state.trendingItemsSalesReport,
                padding: 'auto',
                xField: 'Date',
                yField: 'Count',

                xAxis: {
                    title: {
                        visible: true,
                        text: 'Date',
                    },
                },
                yAxis: {
                    title: {
                        visible: true,
                        text: 'Count',
                    },
                },
            };

        } else if (this.state.selectTimePeriod == "YEARLY") {
            trendingReportConfig = {
                title: {
                    visible: true,
                    text: 'Yearly Chart',
                },
                data: this.state.trendingItemsSalesReport,
                padding: 'auto',
                xField: 'Year',
                yField: 'Count',
                xAxis: {
                    // type: 'timeCat',
                    tickCount: 5,
                },
                xAxis: {
                    title: {
                        visible: true,
                        text: 'Year',
                    },
                },
                yAxis: {
                    title: {
                        visible: true,
                        text: 'Count',
                    },
                },
            };

        } else if (this.state.selectTimePeriod == "MONTHLY") {
            trendingReportConfig = {
                title: {
                    visible: true,
                    text: 'Montly Chart',
                },
                data: this.state.trendingItemsSalesReport,
                padding: 'auto',
                xField: 'Month',
                yField: 'Count',
                xAxis: {
                    title: {
                        visible: true,
                        text: 'Month',
                    },
                },
                yAxis: {
                    title: {
                        visible: true,
                        text: 'Count',
                    },
                },
            };

        }


        return (
            <Fragment>
                <Breadcrumb />
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={tab}
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    TabIndicatorProps={{ style: { background: "#5773ff" } }}
                                >
                                    <Tab label="X Report" />
                                    {/* <Tab label="Unsold Items" /> */}
                                </Tabs>
                                <TabPanel value={tab} index={0} className="tabPanel">
                                    <div className="ms-panel">

                                        <div className="ms-panel-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-md-12 col-sm-12">

                                                    <div className="row m-2">

                                                        <div className="col-md-2">
                                                            <div className="mt-4">
                                                                {/*     <label htmlFor="validationCustom05">Filter By</label> */}
                                                                <select style={{ height: "54px" }} className="form-control" name="date_options_dd" id="validationCustom05" onChange={this.fnCallback} >
                                                                    <option defaultValue="">- - Filter By - -</option>
                                                                    <option value="1">Today</option>
                                                                    <option value="2">Yesterday</option>
                                                                    <option value="3">Last Week</option>
                                                                    <option value="4">Last Month</option>
                                                                    <option value="6">This Month</option>
                                                                    <option value="5">All time</option>
                                                                    {/* <option value="7">Custom</option> */}
                                                                </select>

                                                            </div>
                                                        </div>

                                                        {/*     <div className="col-md-2">
                                                            <div className="mt-4">

                                                                <FormControl fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">Select Report</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={this.state.selectReport}
                                                                        label="Select Report"
                                                                        onChange={this.selectSalesByTypeItemReport}
                                                                    >
                                                                        <MenuItem value="Item">By Item</MenuItem>
                                                                        <MenuItem value={"Date"}>By Date</MenuItem>



                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
 */}


                                                        {/* {this.state.hideFilter && <React.Fragment> */}

                                                        <div className="col-md-2">
                                                            <div className="mt-4">

                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <Stack spacing={3}>
                                                                        <MobileDatePicker
                                                                            label="From"
                                                                            value={this.state.start || new Date()}
                                                                            inputFormat="DD-MM-YYYY"
                                                                            InputProps={{
                                                                                endAdornment: <InputAdornment position="start"><CalendarMonthIcon /></InputAdornment>,
                                                                            }}
                                                                            maxDate={this.state.max}
                                                                            // minDate={this.state.min}
                                                                            onChange={this.handleFromDate}
                                                                            renderInput={(params) => <TextField {...params} />}
                                                                        />
                                                                    </Stack>
                                                                </LocalizationProvider>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="mt-4">

                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <Stack spacing={3}>
                                                                        <MobileDatePicker
                                                                            label="To"
                                                                            value={this.state.end || new Date()}
                                                                            inputFormat="DD-MM-YYYY"
                                                                            onChange={this.handleToDate}
                                                                            maxDate={this.state.max}
                                                                            // minDate={this.state.min}
                                                                            InputProps={{
                                                                                endAdornment: <InputAdornment position="start"><CalendarMonthIcon /></InputAdornment>,
                                                                            }}
                                                                            renderInput={(params) => <TextField {...params} />}
                                                                        />
                                                                    </Stack>
                                                                </LocalizationProvider>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="mt-4">
                                                                {/* <label htmlFor="validationCustom05">Filter By</label> */}
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={this.state.selectTimePeriod}
                                                                        label="Filter By"
                                                                        onChange={this.selectTrenidngReportTimePeriod}
                                                                    >
                                                                        <MenuItem value={"HOURLY"}>HOURLY</MenuItem>
                                                                        <MenuItem style={{ display: (this.state.filterToday && this.state.filterYesterday) || this.state.filterLastWeek  ? "block" : "none"}} value={"DAILY"}>DAILY</MenuItem>
                                                                        <MenuItem style={{ display: this.state.filterLastMonth ? "block" : "none" }} value={"MONTHLY"}>MONTHLY</MenuItem>
                                                                        <MenuItem style={{ display: this.state.filterLastYear ? "block" : "none" }} value={"YEARLY"}>YEARLY</MenuItem>


                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="mt-3" >
                                                                <Button type="submit" sx={{ mt: 2 }} variant="contained" onClick={ this.getTrendingSalesReport} color="primary">
                                                                    GENERATE
                                                                </Button>
                                                            </div>
                                                        </div>

                                                        {/*            </React.Fragment>
                                                        } */}



                                                    </div>



                                                    <div className="" style={{ marginTop: "100px !important" }}  >

                                                        {this.state.isLoading ? <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "40px" }}>   <Loader size={50} /> </div> :

                                                            <React.Fragment >
                                                                {/* 
                                                                <div className="col-md-6">
                                                                    <h4>Total Item Sales</h4>
                                                                    <Line {...totalItemsSalesReportConfig} />

                                                                </div>

                                                                <div className="col-md-6">
                                                                    <h4>Top Selling Items</h4>
                                                                    <Column {...config} />

                                                                </div>

                                                                <div className="col-md-6" style={{ marginTop: "50px" }}>
                                                                    <h4>Trending </h4>

                                                                    <Line {...trendingReportConfig} />

                                                                </div> */}
                                                                {/* 
                                                                <div className="col-md-6">
                                                                    <Column {...config} />

                                                                </div> */}

                                                                {this.state.selectTimePeriod != "HOURLY" && <Column {...trendingReportConfig} />}
                                                                {this.state.selectTimePeriod == "HOURLY" && <Column {...trendingHourlyReportConfig} />}

                                                                <br></br>


                                                                {this.state.trendingItemsSalesReport?.length > 0 && < div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                    <Button variant="contained" style={{ float: "right" }} color="primary" onClick={this.handleExportCSV}>
                                                                        Export CSV
                                                                    </Button>  </div>
                                                                }

                                                                <DataTable

                                                                    columns={this.state.selectTimePeriod == "DAILY" && this.DAILY || this.state.selectTimePeriod == "HOURLY" && this.HOURLY
                                                                        || this.state.selectTimePeriod == "MONTHLY" && this.MONTHLY || this.state.selectTimePeriod == "YEARLY" && this.YEARLY
                                                                    }
                                                                    pagination
                                                                    paginationRowsPerPageOptions={[10, 25, 50, 100, 250]}
                                                                    data={this.state.trendingItemsSalesReport}
                                                                    customStyles={this.customStyles}
                                                                />






                                                            </React.Fragment>}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </TabPanel>
                                <TabPanel value={tab} index={1}>
                                    <div className="ms-panel">
                                        <div className="ms-panel-body">
                                            <div className="col-md-12">

                                                <Typography variant="h5" gutterBottom style={{}}>
                                                    Inventory of Unsold Items

                                                </Typography>
                                                <ItemList items={this.state.unSoldItems} />
                                            </div>

                                        </div>
                                    </div>
                                </TabPanel>

                            </AppBar>



                        </div>
                    </div>
                </div>


            </Fragment >
        );
    }
}
const mapStateToProps = (state) => ({
    user: state.user,
    propertyId: state.user.propertyId
  });
  
  export default connect(mapStateToProps, {}) (Content);