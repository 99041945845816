import React, { Fragment } from 'react';
import Scrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import MultipleImageCropper from '../../shared/Cropper/MultipleImageCropper';
import Avatar from '@mui/material/Avatar';

const Profile = (props) => {
    return (
        <Fragment>
            <div className="row">

                {/* General Details */}
                <div className="col-xl-6 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-header">
                            <h6>GENERAL DETAILS</h6>
                        </div>
                        <div className="ms-panel-body">
                            <div className="col-xl-12 col-md-12">
                                <form className="needs-validation clearfix" onSubmit={props.updateProfileData} noValidate>
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <label htmlFor="validationCustom01">Name</label>
                                            <div className="input-group">
                                                <input type="text"
                                                    className="form-control"
                                                    id="validationCustom01"
                                                    name="name"
                                                    value={props.state.name}
                                                    onChange={props.onChangeHandler}
                                                    required />
                                            </div>
                                        </div>

                                        <div className="col-md-6 ">
                                            <label htmlFor="validationCustom05">Contact Number</label>
                                            <div className="input-group">
                                                <input type="text"
                                                    className="form-control"
                                                    id="validationCustom01"
                                                    name="phone"
                                                    value={props.state.phone}
                                                    onChange={props.onChangeHandler}
                                                    required />
                                            </div>
                                        </div>
                                    </div>
                                    <button disabled= { (props.state.name  == "" || props.state.phone == "") ? true : false  } className="btn btn-primary d-block" type="submit">Update Business Profile</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Email */}
                {/*    <div className="col-xl-6 col-md-12 ">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-header">
                            <h6>Email</h6>
                        </div>
                        <div className="ms-panel-body">
                            <div className="col-xl-12 col-md-12">
                                <p>Do you want to update email?</p>
                                <a className="btn btn-primary" onClick={props.emailModalHandler}>Update Email</a>
                            </div>
                        </div>

                    </div>
                </div> */}

                {/* Profile Image */}
                <div className="col-xl-6 col-md-12 ">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-header">
                            <h6>Profile Image</h6>
                        </div>
                        <div className="ms-panel-body">
                            <div className="col-xl-12 col-md-12">
                                <div className="row" style={{ display: "flex", alignItems: "center" }}>
                                    <div className="col-xl-6 col-md-6 text-left">
                                        {/* <button className="btn btn-primary"
                                            onClick={props.handleImageCropperModalChange}>Upload Profile Image</button> */}

                                        {/* Cover Cropper */}
                                        <MultipleImageCropper data={{
                                            key: 'profile_image',
                                            label: 'Upload Profile Image',
                                            isMultiple: true,
                                            getCroppedImages: props.profilePictureHandler
                                        }}
                                            height={300}
                                            width={300}
                                        />
                                    </div>
                                    {
                                        props.state.profileImagePreviewUrl ?
                                            <div>
                                                <Avatar alt="Remy Sharp" sx={{  width: 100, height: 100 }}   src={props.state.profileImagePreviewUrl} />
                                            </div>
                                            :
                                            props.state.profile_image_url ? <div>
                                              <Avatar alt="Remy Sharp" sx={{  width: 100, height: 100 }} src={props.state.profile_image_url} />  
                                            </div> : <div style={{ display: "flex", alignItems: "center" }}><Avatar sx={{  width: 100, height: 100 }} alt="Remy Sharp" src="" />  </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* Security */}
                <div className="col-xl-6 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-header">
                            <h6>Security</h6>
                        </div>
                        <div className="ms-panel-body">
                            <div className="col-xl-12 col-md-12">
                                <p>Do you want to change your password? </p>
                                <a className="btn btn-primary" onClick={props.passwordModalHandler}>Change Your Password</a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Devices */}
                <div className="col-xl-6 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-header">
                            <h6>Devices</h6>
                        </div>
                        <div className="ms-panel-body">
                            <div className="col-xl-12 col-md-12">
                                <ul className="ms-followers ms-list ms-scrollable">
                                    <Scrollbar className="ms-scrollable ms-dropdown-list">
                                        {props.state.login_sessions.map((session, i) => (
                                            <li key={i} className="ms-list-item media">
                                                <div className="media-body mt-1"><h5>{session.os_detail}</h5>
                                                    <h6>{session.browser_detail}</h6>
                                                    <p className="fs-12">{session.date_and_time}</p>
                                                    <p className="fs-12">{session.location}</p>
                                                </div>
                                                {/* <button type="button" className="btn btn-success" name="button">Logout</button> */}
                                            </li>
                                        ))}
                                    </Scrollbar>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default Profile