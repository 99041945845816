import React, { Component, Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/animate.min.css";
import "../../assets/css/style.css";
import "../../assets/vendors/iconic-fonts/cryptocoins/cryptocoins-colors.css";
import "../../assets/vendors/iconic-fonts/cryptocoins/cryptocoins.css";
import "../../assets/vendors/iconic-fonts/flat-icons/flaticon.css";
import "../../assets/vendors/iconic-fonts/font-awesome/css/all.min.css";
import { setCurrentUser } from "../../redux/user/user.action";
import Routes from "../_Routes/index";
import { logout } from "../../redux/user/userSlice";
import { withRouter } from "react-router";
import { APIBaseURL } from "../../services/APIUrl";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import IdleTimer from "./IdleTimer";
import * as APIUrl from '../../services/APIUrl'
import axios from 'axios';
import ReactGA from 'react-ga';
ReactGA.initialize('G-R6JHW8GW6T');
const theme = createTheme({
  palette: {
    primary: {
      light: '#5773ff',
      main: '#5773ff',
      dark: '#5773ff',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    action: {
      light: '#333',
      main: '#333',
      dark: '#333',
      contrastText: '#333',
    },
  },
});
var timer;




// Higher-order component to check server status
function withServerStatusCheck(WrappedComponent) {
  return function ServerStatusCheck(props) {
    const [isServerDown, setIsServerDown] = useState(false);



  /*   useEffect(() => {
      const axiosInstance = axios.create();

      axiosInstance.interceptors.response.use(
        (response) => {
          // If the response is successful, return it as-is
          return response;
        },
        (error) => {
          if (!error.response) {
            // Network error or server down
            setIsServerDown(true);
          }
          return Promise.reject(error);
        }
      );
      const checkServerStatus = async () => {
        axiosInstance.options(APIUrl.SIGNIN)
        .then((response) => {
          setIsServerDown(false);
        })
        .catch((error) => {
          // Handle error (including server down or network error) here
        });
      };
  
      // Initial check when the component mounts
      checkServerStatus();
  
      // Periodically check server status every 5 seconds
      const intervalId = setInterval(checkServerStatus, 5000);
  
      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }, []); */

    return (
      <div>
      
          <WrappedComponent {...props }  isServerDown = {isServerDown}/>
    
      </div>
    );
  };
}

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_token_expired: true,
      isLoading: true,
      isTimeout: false
    };

  }





  logout = () => {
    localStorage.clear();
    localStorage.removeItem("activeNavItem");
    this.props.logout();
  };



  componentDidMount() {
    // this.setupBeforeUnloadListener();
    //  if (window.location.pathname !== "/login" || window.location.pathname !== "/") {
    //   const start = Date.now();
    console.log(localStorage.getItem("_expiredTime"));

    let time = APIBaseURL === "http://devbusiness.kayana.co.uk/" ? 1800 : 3600

    console.log(new Date().toUTCString())
    console.log(time);


    timer = new IdleTimer({
      timeout: time, //expire after 10 seconds
      onTimeout: () => {

        console.log("timout happened")
        console.log(localStorage.getItem("_expiredTime"));


        this.setState({ isTimeout: true }, () => {
          //    console.log("timout happened Inside")
        })

        if (this.state.isTimeout) {
          console.log(new Date().toUTCString())
          //       const millis = Date.now() - start;

          //       console.log(`seconds elapsed = ${Math.floor(millis / 1000)}`);

          this.logout();

          if (window.location.pathname !== "/login") {
            if (window.location.pathname !== "/") {
              window.location.pathname = "/";
            }
          }

          if (window.location.pathname !== "/") {
            if (window.location.pathname !== "/login") {
              window.location.pathname = "/";
            }
          }

        }

      },
      onExpired: () => {
        // do something if expired on load
        console.log("Expired")
        this.setState({ isTimeout: true })
        if (this.state.isTimeout) {

          this.logout();

          console.log(new Date().toUTCString())
          if (window.location.pathname !== "/login") {
            if (window.location.pathname !== "/") {
              window.location.pathname = "/";
            }
          }

          if (window.location.pathname !== "/") {
            if (window.location.pathname !== "/login") {
               window.location.pathname = "/";
            }
          }

        }
      }
    });
    //  }
  }

  componentWillUnmount() {
    timer.cleanUp();
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
        {/* { this.props.isServerDown && <div class="alert alert-danger" role="alert"><i class="flaticon-alert-1"></i>  Our servers are temporarily unavailable. Please check back later.</div> } */}
          <Routes state={this.state} isServerDown={this.props.isServerDown} />
          <ToastContainer position="top-center" />
        </Fragment>
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  logout,
});

export default withServerStatusCheck(connect(
  null,
  mapDispatchToProps
)(withRouter((props) => <App {...props} />)));
