import axios from 'axios';
import * as APIUrl from '../APIUrl';


export const getItems = (payload) => {
    try {
        return axios({
            method: 'get',  
            url: APIUrl.FETCH_ALL_BATCHES   + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
};

export const getItem = (id) => {
    try {
        return axios({
            method: 'get',  
            url: APIUrl.FETCH_ALL_INVENTORY_STOCK_ITEMS  + `${id}`,
        })
    } catch (error) {
        throw new Error(error)
    }
};

export const createBatch = (batch) => {
    try {
        return axios({
            method: 'post',  
            url: APIUrl.ADD_BATCH + `${batch.stock_id}&quantity=${batch.quantity}&description=${batch.description}&has_flag=${batch.has_flag}&reason=${batch.reason}`,
        })
    } catch (error) {
        throw new Error(error)
    }
};

export const updateBatch = (batch) => {
    try {
        return axios({
            method: 'put',  
            url: APIUrl.UPDATE_BATCH,
            data: batch
        })
    } catch (error) {
        throw new Error(error)
    }
};

export const deleteBatch = (id) => {
    try {
        return axios({
            method: 'put',  
            url: APIUrl.DELETE_BATCH+ `${id}`,
        })
    } catch (error) {
        throw new Error(error)
    }
};


export const getReasons= (payload) => {
    try {
        return axios({
            method: 'get',  
            url: APIUrl.FETCH_ALL_REASONS   + `${payload.property_id}&type=${payload.type}`,
        })
    } catch (error) {
        throw new Error(error)
    }
};


export const createReason = (payload) => {
    try {
        return axios({
            method: 'post',  
            url: APIUrl.ADD_RESAON + `${payload.property_id}&reason=${payload.reason}&type=${payload.type}`,
        })
    } catch (error) {
        throw new Error(error)
    }
};

export const updateReason = (payload) => {
    try {
        return axios({
            method: 'put',  
            url: APIUrl.UPDATE_RESAON + `reason=${payload.reason}&reason_id=${payload.reason_id}`,
        })
    } catch (error) {
        throw new Error(error)
    }
};

export const deleteReason = (id) => {
    try {
        return axios({
            method: 'put',  
            url: APIUrl.DELETE_REASON + `${id}`,
        })
    } catch (error) {
        throw new Error(error)
    }
};



