import React, { Component } from "react";
import { toast } from "react-toastify";
import * as CashServiceAPI from "../../../services/cash/CashServiceAPI";
import * as WebOrderServiceAPI from "../../../services/webOrder/WebOrderServiceAPI";
import { Fragment } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Switch from "@mui/material/Switch";
import WifiIcon from "@mui/icons-material/Wifi";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { connect } from "react-redux";
import FolderIcon from "@mui/icons-material/Folder";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Loader from "../../../shared/Loader";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CheckCircleOutline } from "@mui/icons-material";
import Breadcrumb from "./Breadcrumb";
import { Stack } from "@mui/material";
import { Divider } from "antd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Modal } from "antd";
import { Result } from "antd";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

class index extends Component {
  state = {
    isLoading: false,
    payment_method_type: [],
    checked: ["wifi"],
    selectedCard: false,
    grants: [],
    dense: false,
    isChecked: false,
    plan: {},
    terms: {},
    url: {},
    nash: {},
    isModalOpen: false,
    isNashModalOpen: false,
    webPlan: {},
  };

  componentDidMount = () => {
    const dump = this.props.propertyId;
    this.fetchWebURL();
    this.fetchGrants(dump);
    this.fetchWebPlan();
  };

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked });
  };


  handleCancelClick = () => {
    this.setState({ isModalOpen: true });
  };
  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };


  fetchWebURL = async () => {
    const dump = this.props.propertyId;
    try {
      const response = await WebOrderServiceAPI.fetchWebURL(dump);

      if (response.data.status) {
        if (response.data?.data) {
          this.setState(
            {
              url: response.data.data,
            },
            () => {}
          );
        }
      } else {
        const err = response.data?.message || "Something went wrong.";
        this.setState({ url: {}, isModalOpen: false });
        toast.warn(err);
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ url: {}, isModalOpen: false });
      toast.warn(err);
      throw error;
    }
  };

  fetchWebPlan = async () => {
    const dump = this.props.propertyId;
    try {
      const response = await WebOrderServiceAPI.fetchWebPlan(dump);
      if (response.data.status) {
        if (response.data?.data) {
          this.setState(
            {
              webPlan: response.data.data,
            },
            () => {
              if (response.data.data.subscription_status === "EXPIRED") {
                this.setState({ url: {} });
              }
            }
          );
        }
      } else {
        const err = response.data?.message || "Something went wrong.";
        this.setState({ webPlan: {} });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ webPlan: {}, isModalOpen: false });
      toast.warn(err);
      throw error;
    }
  };

  cancelWebPlan = async () => {
    const dump = this.state.webPlan.web_id;
    try {
      const response = await WebOrderServiceAPI.cancelWebPlan(dump);

      if (response.data.status) {
        this.fetchWebPlan();
        this.fetchNashPlan();
        this.setState({ isModalOpen: false });
      } else {
        const err = response.data?.message || "Something went wrong.";
        this.setState({ webPlan: {}, isModalOpen: false });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ webPlan: {}, isModalOpen: false });
      toast.warn(err);
      throw error;
    }
  };


  fetchGrants = (payload) => {
    this.setState({ isLoading: true });
    if (payload) {
      WebOrderServiceAPI.fetchWebOrderTerms(payload)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data.data.adyen_info);
            this.setState({ terms: response.data.data, isLoading: false });
          } else {
            this.setState({ terms: {}, isLoading: false });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, terms: {} });
        });
    } else {
      this.setState({ isLoading: false, terms: {} });
    }
  };

  handleCheckboxChange = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  handleSignRequest = () => {
    this.setState({ isLoading: true });
    this.props.history.push({
      pathname: "/web-order",
      state: {
        terms: this.state.terms,
      },
    });
    /*    const payload = {
 property_id: this.props.propertyId,
     grant_offer_id: this.state.plan.id
   }

   CashServiceAPI.requestGrant(payload)
     .then((response) => {
       if (response.data.status) {
         console.log(response.data.data)
         this.setState({ isLoading: false });
         toast.success("Grant requested intiated.");
         this.props.history.push({
           pathname: "/grant-details",
         });
       } else {
         this.setState({ isLoading: false });
         toast.success("Grant requested failed.")
       }

     })
     .catch((error) => {
       this.setState({ isLoading: false });
     }); */
  };

  handleCopy = async () => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(this.state.url.web_url);
        toast.success("Text copied to clipboard.");
      } else {
        // Fallback for browsers that do not support the Clipboard API
        const textarea = document.createElement("textarea");
        textarea.value = this.state.url.web_url;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        toast.success("Text copied to clipboard.");
      }
    } catch (err) {
      console.error("Unable to copy text to clipboard", err);
    }
  };

  render() {
    const {
      selectedCard,
      grants,
      dense,
      isChecked,
      plan,
      isLoading,
      terms,
      url,
      nash,
      isModalOpen,
      isNashModalOpen,
      webPlan,
    } = this.state;
    return (
      <Fragment>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="">
            <div className="">
              <div className="">
                <br />
                <div className="col-md-12">
                  <Breadcrumb />
                </div>

                <br />
                <Grid
                  container
                  justifyContent={
                    webPlan?.subscription_status == "ACTIVE" ||
                    webPlan?.subscription_status == "CANCELLED"
                      ? "left"
                      : "center"
                  }
                  alignItems={
                    webPlan?.subscription_status == "ACTIVE" ||
                    webPlan?.subscription_status == "CANCELLED"
                      ? "left"
                      : " center"
                  }
                >
                  <Grid item lg={9}>
                    <Paper
                      elevation={3}
                      sx={{ p: 2, borderRadius: 2, mb: 2, padding: 5 }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h5">
                          <b>Web Ordering Service</b>{" "}
                          {webPlan?.subscription_status === "ACTIVE" ||
                          webPlan?.subscription_status === "CANCELLED" ? (
                            <CheckCircleIcon style={{ color: "#5773ff" }} />
                          ) : (
                            ""
                          )}{" "}
                          {webPlan?.subscription_status === "CANCELLED" ? (
                            <Typography
                              variant="body2"
                              sx={{
                                marginLeft: "10px",
                                backgroundColor: "lightgrey",
                                padding: "5px",
                                width: "55%",
                                paddingLeft: "10px",
                              }}
                            >
                              Valid till{" "}
                              {webPlan?.subscription_end_date?.substring(0, 10)}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Typography>
                        {!(
                          webPlan?.subscription_status === "ACTIVE" ||
                          webPlan?.subscription_status === "CANCELLED"
                        ) ? (
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{ background: "#e7e7fa", padding: "5px" }}
                          >
                            {terms?.amount}
                          </Typography>
                        ) : (
                          ""
                        )}
                        {webPlan?.subscription_status === "ACTIVE" ? (
                          <Button
                            size="large"
                            variant="contained"
                            className="white-text"
                            sx={{
                              mt: { xs: 1, md: 0 },
                              width: "150px",
                              height: "50px",
                            }}
                            onClick={this.handleCancelClick}
                          >
                            Cancel Plan
                          </Button>
                        ) : (
                          ""
                        )}
                        {webPlan?.subscription_status === "CANCELLED" ? (
                          <Button
                            size="large"
                            variant="contained"
                            className="white-text"
                            sx={{
                              mt: { xs: 1, md: 0 },
                              width: "150px",
                              height: "50px",
                            }}
                            disabled={true}
                          >
                            Cancelled
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                      {url?.is_enable ? (
                        <Grid container>
                          <Grid item lg={12}>
                            <Modal
                              title=""
                              open={isModalOpen}
                              onCancel={this.handleCancel}
                            >
                              <Result
                                style={{
                                  color: "#5773ff",
                                  marginBottom: "0px",
                                  marginTop: "5px",
                                }}
                                title={"Cancel Subscription!"}
                                subTitle={
                                  <Typography style={{ fontSize: "20px" }}>
                                    Are you sure you want to cancel your Web
                                    Ordering subscription, your subscription is
                                    valid till{" "}
                                    {webPlan?.subscription_end_date?.substring(
                                      0,
                                      10
                                    )}
                                  </Typography>
                                }
                              />
                              <div
                                style={{
                                  marginTop: "0px",
                                  justifyContent: "space-around",
                                  display: "flex",
                                }}
                              >
                                <Button
                                  size="large"
                                  variant="contained"
                                  className="white-text"
                                  sx={{ height: "40px", width: "150px" }}
                                  onClick={this.handleCancel}
                                >
                                  No
                                </Button>
                                <Button
                                  size="large"
                                  variant="contained"
                                  className="white-text"
                                  sx={{
                                    height: "40px",
                                    width: "150px",
                                    marginLeft: "10px",
                                  }}
                                  onClick={this.cancelWebPlan}
                                >
                                  Yes
                                </Button>
                              </div>
                            </Modal>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                      <hr />
                      {!(
                        webPlan?.subscription_status === "ACTIVE" ||
                        webPlan?.subscription_status === "CANCELLED"
                      ) ? (
                        <Grid container spacing={1}>
                          <React.Fragment>
                            <Grid item xs={12} md={12}>
                              <br />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography variant="h6" gutterBottom>
                                  <b> Terms and Conditions</b>
                                </Typography>
                              </div>

                              <Typography
                                variant="body1"
                                style={{ padding: "5px" }}
                              >
                                <div
                                  style={{
                                    background: "#f5f5f5",
                                    padding: "15px",
                                    maxHeight: "300px", // Corrected property name
                                    overflow: "auto",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: terms?.terms_and_conditions,
                                  }}
                                />
                              </Typography>
                            </Grid>
                          </React.Fragment>{" "}
                        </Grid>
                      ) : (
                        ""
                      )}
                      {!(
                        webPlan?.subscription_status === "ACTIVE" ||
                        webPlan?.subscription_status === "CANCELLED"
                      ) ? (
                        <React.Fragment>
                          <br /> <br /> <br />
                          <Grid
                            container
                            spacing={3}
                            justifyContent="space-between"
                          >
                            <Grid item lg={6}>
                              <Button
                                fullWidth
                                style={{
                                  borderRadius: "10px",
                                  padding: "10px 0px",
                                }}
                                variant="outlined"
                                onClick={() => {
                                  this.props.history.goBack();
                                }}
                              >
                                Back
                              </Button>
                            </Grid>
                            <Grid item lg={6}>
                              <Button
                                fullWidth
                                style={{
                                  borderRadius: "10px",
                                  padding: "10px 0px",
                                }}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  this.handleSignRequest();
                                }}
                              >
                                Agree & Continue to Pay
                              </Button>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      {url?.is_enable &&
                        webPlan.subscription_status != "EXPIRED" && (
                          <Grid item xs={12} md={10}>
                            <Typography variant="h6" gutterBottom>
                              These services are accesible from your
                              application. Here is your customised URL for
                              receiving orders directly in your application.
                            </Typography>
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: { xs: "column", md: "row" }, // Row for md and above, column for xs
                                alignItems: { xs: "stretch", md: "center" },
                                justifyContent: "flex-start", // Align items to the start of the container
                              }}
                            >
                              <TextField
                                variant="outlined"
                                value={url?.web_url}
                                InputProps={{
                                  readOnly: true,
                                }}
                                size="normal"
                                sx={{
                                  width: { xs: "100%", md: "45ch" }, // Full width for xs, fixed width for md
                                  mb: { xs: 1, md: 0 }, // Add margin-bottom for mobile devices
                                  mr: { xs: 0, md: 1 }, // Add margin-right for mobile devices
                                }}
                              />
                              <Button
                                size="small"
                                variant="contained"
                                className="white-text"
                                sx={{ mt: { xs: 1, md: 0 } }} // Add margin-top for mobile devices
                              >
                                <IconButton onClick={this.handleCopy}>
                                  <ContentCopyIcon />
                                  Copy
                                </IconButton>
                              </Button>
                            </Stack>
                          </Grid>
                        )}
                      <br /> <br /> <br />
                    </Paper>
                  </Grid>

                
                </Grid>
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default withRouter(connect(mapStateToProps, {})(index));
