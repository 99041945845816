import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

// import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from "@mui/material/colors";
import $ from "jquery";
import Breadcrumb from "./Breadcrumb";
import { Jumbotron, Container } from "react-bootstrap";
import ApiIcon from "@mui/icons-material/Api";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import table from "../../assets/img/qr/tab.jpg";
import bed from "../../assets/img/qr/rom.jpg";
import property from "../../assets/img/qr/prp.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, Stack } from "@mui/material";
import * as PlanServiceAPI from "../../services/plans/PlanServiceAPI";
import Loader from "../../shared/Loader";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Box,
  Grid,
  Divider,
  useTheme,
  withStyles,
} from "@mui/material";
import { Modal } from "antd";
import { Result } from "antd";
const { Meta } = Card;
class Content extends Component {
  state = {
    isLoading: false,
    QR: null,
    isQrModalOpen: false,
  };

  componentDidMount = () => {
    this.fetchPlansStatus();
    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }
  };

  handleQrCancelClick = () => {
    this.setState({ isQrModalOpen: true });
  };
  handleCancel = () => {
    this.setState({ isQrModalOpen: false });
  };
  cancelQrPlan = async () => {
    const dump = this.state.QR.subscription_id;
    try {
      const response = await PlanServiceAPI.cancelQrPlan(dump);

      if (response.data.status) {
        this.fetchPlansStatus();
        this.setState({ isQrModalOpen: false });
      } else {
        const err = response.data?.message || "Something went wrong.";
        this.setState({ QR: {}, isQrModalOpen: false });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ QR: {}, isQrModalOpen: false });
      throw error;
    }
  };
  fetchPlansStatus = async () => {
    try {
      this.setState({ isLoading: true });
      let propertId = this.props.propertyId;
      const response = await PlanServiceAPI.fetchPlansStatus(propertId);

      if (response.data.status) {
        this.setState({ QR: response.data.data });
        if (response.data.data?.subscription_status == "ACTIVE") {
          this.props.history.push({
            pathname: "/manage-qr-flow",
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.props.history.push({
          pathname: "/pricing",
        });
        this.setState({ isLoading: false });
      }
    } catch (error) {
      this.setState({ isActive: false, isLoading: false });
      throw error;
    }
  };

  render() {
    const { QR, isQrModalOpen } = this.state;

    return (
      <Fragment>
        <div className="ms-content-wrapper Dashboard">
          <div className="col-md-12">
            <Breadcrumb />
          </div>
          <div className="">
            <div className="">
              <div
                className="row"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* {" "}
                {QR?.subscription_status === "CANCELLED" ? (
                  <Button variant="outlined">
                    Valid till {QR?.subscription_end_date.substring(0, 10)}
                  </Button>
                ) : (
                  ""
                )} */}
              </div>
              {this.state.isLoading ? (
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "40px",
                  }}
                >
                  {" "}
                  <Loader size={50} />{" "}
                </div>
              ) : (
                <div
                  className="col-md-10"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignItems: "flex-start",
                    marginTop: "0px",
                  }}
                >
                  <Grid container>
                    <Card
                      sx={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <CardContent style={{ paddingBottom: "0px" }}>
                        <Grid container spacing={4} alignItems="center">
                          <Grid item lg={5} md={5} xs={12}>
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="h6"
                                color="textPrimary"
                                paragraph
                              >
                                <b>Active Plan : </b>
                              </Typography>
                              <Typography
                                color="textPrimary"
                                className="vl"
                                style={{
                                  background: "rgb(206 207 255)",
                                  color: "#7e80eb",
                                  borderRadius: "7px",
                                  padding: "6px 12px",
                                  marginLeft: "5px",
                                }}
                                paragraph
                              >
                                <b>
                                  {" "}
                                  {QR?.plan_name}
                                  {QR?.subscription_status === "EXPIRED"
                                    ? "(" + QR?.subscription_status + ")"
                                    : ""}{" "}
                                  {QR?.subscription_status === "CANCELLED"
                                    ? "(" +
                                      "Valid till " +
                                      QR?.subscription_end_date.substring(
                                        0,
                                        10
                                      ) +
                                      ")"
                                    : ""}
                                </b>
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item lg={4} md={4} xs={12}>
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="h6"
                                color="textPrimary"
                                paragraph
                              >
                                <b> QR Codes Available : </b>
                              </Typography>
                              <Typography
                                color="textPrimary"
                                style={{
                                  background: "rgb(202 237 222)",
                                  color: "#2f956a",
                                  borderRadius: "7px",
                                  padding: "6px 12px",
                                  marginLeft: "10px",
                                }}
                                paragraph
                              >
                                <b
                                  style={{
                                    fontSize:
                                      QR?.subscription_status === "EXPIRED"
                                        ? "26px"
                                        : "inherit",
                                    fontWeight: "600",
                                  }}
                                >
                                  {QR?.subscription_status === "EXPIRED"
                                    ? "--"
                                    : QR?.qr_remaining || 0}
                                </b>
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="h6"
                                color="textPrimary"
                                paragraph
                              >
                                <b> QR Codes Used : </b>
                              </Typography>
                              <Typography
                                color="textPrimary"
                                style={{
                                  background: "rgb(235 174 180)",
                                  color: "#a8424c",
                                  borderRadius: "7px",
                                  padding: "6px 12px",
                                  marginLeft: "10px",
                                }}
                                paragraph
                              >
                                <b
                                  style={{
                                    fontSize:
                                      QR?.subscription_status === "EXPIRED"
                                        ? "26px"
                                        : "inherit",
                                    fontWeight: "600",
                                  }}
                                >
                                  {QR?.subscription_status === "EXPIRED"
                                    ? "--"
                                    : Number(
                                        QR?.qr_available - QR?.qr_remaining || 0
                                      )}
                                </b>
                              </Typography>
                              <Divider
                                orientation="vertical"
                                style={{
                                  border: "1px solid #000",
                                  marginBottom: "16px",
                                  height: "50px",
                                  visibility: "hidden",
                                }}
                                flexItem
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>

                    <Grid
                      container
                      spacing={4}
                      sx={{
                        backgroundColor: "lightgrey",
                        height: "auto",
                        marginTop: "40px",
                        alignItems: "center",
                        padding: "10px",
                        marginBottom: "20px",
                        marginLeft: "10px",
                      }}
                    >
                      <Grid item xs={12} md={2} lg={2}>
                        <Typography variant="h6" color="textPrimary">
                          Pricing :
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        {QR?.qr_fee.local_card_fee ? (
                          <Typography variant="body1" color="textPrimary">
                            Local Card : {QR?.qr_fee.local_card_fee}% + 10p
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        {QR?.qr_fee.international_commercial_card_fee ? (
                          <Typography variant="body1" color="textPrimary">
                            International Card:{" "}
                            {QR?.qr_fee.international_commercial_card_fee}% +
                            10p
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>

                      <Grid item xs={12} md={3} lg={3}>
                        {QR?.qr_fee.other_card_fee ? (
                          <Typography variant="body1" color="textPrimary">
                            Other Cards: {QR?.qr_fee.other_card_fee}% + 10p
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                       

                    <Grid container spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Link to="/table">
                          <Card
                            sx={{
                              height: 300,
                              display: "border-box",
                              padding: "10px",
                            }}
                          >
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                sx={{ borderRadius: "10px", height: "234px" }}
                                image={table}
                                alt="green iguana"
                              />
                              <CardContent>
                                <Typography
                                  style={{ textAlign: "center" }}
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                >
                                  <b> QR for table service. </b>
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={12} md={4} lg={4}>
                        <Link to="/room">
                          <Card
                            sx={{
                              height: 300,
                              display: "border-box",
                              padding: "10px",
                            }}
                          >
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                sx={{ borderRadius: "10px", height: "234px" }}
                                image={bed}
                                alt="green iguana"
                              />
                              <CardContent>
                                <Typography
                                  style={{ textAlign: "center" }}
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                >
                                  <b> QR for room service. </b>
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={12} md={4} lg={4}>
                        <Link to="/generate-qr-code">
                          <Card
                            sx={{
                              height: 300,
                              display: "border-box",
                              padding: "10px",
                            }}
                          >
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                sx={{ borderRadius: "10px", height: "234px" }}
                                image={property}
                                alt="green iguana"
                              />
                              <CardContent>
                                <Typography
                                  style={{ textAlign: "center" }}
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                >
                                  <b> Other QR service. </b>
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      lg={12}
                    >
                      <Grid item xs={12} alignSelf="flex-end">
                        <br /> <br />
                        <br />
                      </Grid>
                    </Grid>

                    <Stack
                      direction={{ xs: "column", sm: "column", md: "row" }}
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {QR?.subscription_status === "ACTIVE" ||
                      QR?.subscription_status === "UPGRADED" ? (
                        <Typography
                          variant="h5"
                          color="textSecondary"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <InfoIcon style={{ marginRight: "8px" }} />
                          Upgrade your plan now for an enhanced experience with
                          additional QR codes and exclusive features.
                        </Typography>
                      ) : (
                        ""
                      )}
                      {QR?.subscription_status === "CANCELLED" ? (
                        <Typography
                          variant="h5"
                          color="textSecondary"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <InfoIcon style={{ marginRight: "8px" }} />
                          Your current plan has been cancelled. It will expire
                          on {QR?.subscription_end_date.substring(0, 10)}.
                        </Typography>
                      ) : (
                        ""
                      )}
                      {QR?.subscription_status === "EXPIRED" ? (
                        <Typography
                          variant="h5"
                          color="textSecondary"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <InfoIcon style={{ marginRight: "8px" }} />
                          Your current plan has expired. Renew plan to get back
                          on track.
                        </Typography>
                      ) : (
                        ""
                      )}
                      {QR?.subscription_status === "ACTIVE" &&
                      QR?.plan_type !== "FREE" ? (
                        <Button
                          variant="contained"
                          size="large"
                          sx={{
                            width: "250px",
                            height: "50px",
                            padding: "10px",
                            textTransform: "none",
                            fontSize: "16px",
                            alignItems: "center",
                          }}
                          onClick={this.handleQrCancelClick}
                        >
                          <b>Cancel Plan</b>
                        </Button>
                      ) : (
                        ""
                      )}

                      <Button
                        variant="contained"
                        size="large"
                        sx={{
                          width: "250px",
                          height: "50px",
                          padding: "10px",
                          textTransform: "none",
                          fontSize: "16px",
                          alignItems: "center",
                          marginLeft:
                            QR?.subscription_status !== "ACTIVE"
                              ? { xs: "130px !important" }
                              : "",
                        }}
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/pricing",
                          });
                        }}
                      >
                        <b>
                          {QR?.subscription_status === "ACTIVE"
                            ? "Upgrade"
                            : "Buy "}{" "}
                          Plan{" "}
                        </b>
                      </Button>
                    </Stack>
                  </Grid>{" "}
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                      <Modal
                        title=""
                        open={isQrModalOpen}
                        onCancel={this.handleCancel}
                      >
                        <Result
                          style={{
                            color: "#5773ff",
                            marginBottom: "0px",
                            marginTop: "5px",
                          }}
                          title={"Cancel Subscription!"}
                          subTitle={
                            <Typography style={{ fontSize: "22px" }}>
                              Are you sure you want to cancel your QR
                              subscription, your subscription is valid till{" "}
                              {QR?.subscription_end_date.substring(0, 10)}.
                            </Typography>
                          }
                        />
                        <div
                          style={{
                            marginTop: "0px",
                            justifyContent: "space-around",
                            display: "flex",
                          }}
                        >
                          <Button
                            size="large"
                            variant="contained"
                            className="white-text"
                            sx={{ height: "40px", width: "150px" }}
                            onClick={this.handleCancel}
                          >
                            No
                          </Button>
                          <Button
                            size="large"
                            variant="contained"
                            className="white-text"
                            sx={{
                              height: "40px",
                              width: "150px",
                              marginLeft: "10px",
                            }}
                            onClick={this.cancelQrPlan}
                          >
                            Yes
                          </Button>
                        </div>
                      </Modal>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});
export default withRouter(connect(mapStateToProps, {})(Content));
