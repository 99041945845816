import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import * as PaymentServiceAPI from "../../services/payment/PaymentServiceAPI";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import $ from "jquery";
import Breadcrumb from "./Breadcrumb";
import { Jumbotron, Container } from "react-bootstrap";
import ApiIcon from "@mui/icons-material/Api";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { settings } from "../../config";
import { connect } from "react-redux";
import Loader from "../../shared/Loader";
import integr from "../../assets/icon/integrate.svg";
import settin from "../../assets/icon/settings.svg";
class Content extends Component {
  state = {
    status: "",
  };

  componentDidMount = () => {
    this.fetchAccountInformation();
    this.fetchAccountSetting();
    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }
  };

  fetchAccountInformation() {
    this.setState({ isLoading: true });
    const dump = {
      property_id: this.props.propertyId,
      username: this.props.user.username
        ? this.props.user.username
        : localStorage.getItem("username"),
    };

    PaymentServiceAPI.fetchAccountStatus(dump)
      .then((response) => {
        if (response.data.status) {
          this.setState({
            status: response.data?.data?.account_status,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false, status: "" });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        console.log(error);
        this.setState({ isLoading: false, status: "" });
      });
  }
  fetchAccountSetting() {
    const dump = {
      property_id: this.props.propertyId,
    };

    PaymentServiceAPI.fetchAccountSetting(dump)
      .then((response) => {
        if (response.data && response.data.status) {
          this.setState({
            is_suite: response.data?.data?.is_suite,
          });
          this.props.setSettings(response.data.data);
        } else {
          this.props.setSettings({});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    let tabs = [
      {
        title: "General",
        link: "/general",
        description: "View and update your store details",
        icon_name: "ac_unit",
      },
      {
        title: "Timing",
        link: "/app-timing",
        description: "Update your business timings here.",
        icon_name: "access_time",
      },
      {
        title: "Payments",
        link: "/payment",
        description: "Payment methods that are active. ",
        icon_name: "account_balance_wallet",
      },
      /*     {
               /*  {
                    title: 'Taxes',
                    link: '/tax',
                    description: 'Manage how your store charges tax',
                    icon_name: 'text_snippet'
                }, */
      {
        title: "Notification",
        link: "/notification-setting",
        description: "Manage Notification sent to you & your customers",
        icon_name: "announcement",
      },
      {
        title: "Web Ordering",
        link: "/web-order-accept",
        description: "Receive orders from the web.",
        icon_name: "add_to_queue",
      },
      {
        title: "Shift",
        link: "/shift",
        description: "Add Shift.",
        icon_name: "clock",
      },
      {
        title: "Suite",
        link: "/suite",
        description: "Add suite.",
        icon_name: "hotel",
      },
      {
        title: "Book Suite",
        link: "/booked-suite",
        description: "Book suite.",
        icon_name: "book",
      },
      {
        title: "Delivery Charges",
        link: "/delivery-charges",
        description: "Add Delivery Charges.",
        icon_name: "bull",
      },

      {
        title: "Receipt Note",
        link: "/receipt",
        description: "Add Receipt Note.",
        icon_name: "branding_watermark",
      },
      {
        title: "Discounts",
        link: "/all-discounts",
        description: "Manage Platform Discounts.",
        icon_name: "discount",
      },
      /*       {
                title: 'Happy Hour Discount',
                link: '/happy-hour-discount',
                description: 'Manage Happy Hour Discount.',
                icon_name: 'autorenew'
            }, 
            {
                title: 'Epos Discount',
                link: '/epos-discounts',
                description: 'Manage Epos Discount.',
                icon_name: 'monitor'
            },  */
      /* cl */
      {
        title: "Onboarding",
        link: "/setup",
        description: "ADYEN Onboarding Setup",
        icon_name: "add_box",
      },
      {
        title: "Billing",
        link: "/billing",
        description: "View Invoices",
        icon_name: "article",
      },
      {
        title: "Manage Users",
        link: "/manage-user",
        description: "Add, Update users.",
        icon_name: "account_box",
      },
      {
        title: "Integrations",
        link: "/epos-now",
        description: "View integrated services.",
        icon_name: "integrate",
      },
/*       {
        title: "Third Party Delivery",
        link: "/nashHome",
        description: "Delivery Services.",
        icon_name: "local_shipping",
      }, */
      {
        title: "Additional Settings",
        link: "/tip",
        description: "Manage additional settings.",
        icon_name: "settings",
      },
/*       {
        title: "Inventory",
        link: "/inventory-accept",
        description: "Manage inventory of stock & batch.",
        icon_name: "inventory",
      }, */
      
      /* 
            {
                title: 'Manage Room',
                link: '/room',
                description: 'Add, Update, Delete Room.',
                icon_name: 'building'
            },
            { 
                title: 'Manage Table',
                link: '/table',
                description: 'Add, Update, Delete Table.',
                icon_name: 'circle'
            }, */
      /*  {
                title: 'Manage QR.',
                link: '/manage-qr',
                description: 'Manage Table, Room etc.',
                icon_name: 'center_focus_weak'
            }, */

      /*  {
                 title: 'Theme',
                 link: '/theme',
                 description: 'Change Kiosk theme.',
                 icon_name: 'grain'
             }, */
      /*   {
                  title: 'Split Payment',
                  link: '/splitpayment',
                  description: 'Update payments for vendors. ',
                  icon_name: 'account_balance'
              }, */
    ];
    const { status, isLoading,is_suite } = this.state;

    if (status !== "ACCOUNT COMPLETED") {
      tabs = tabs.filter(
        (tab) =>
          tab.title !== "Web Ordering" &&
          tab.title !== "Shift" &&
          tab.title !== "Integrations" &&
          tab.title !== "Manage Users" &&
          tab.title !== "Billing" &&
          tab.title !== "Delivery Charges" &&
          tab.title !== "Third Party Delivery" &&
          tab.title !== "Additional Settings"
      );
    }

    if (!is_suite) {
        tabs = tabs.filter(
          (tab) =>
            tab.title !== "Book Suite" &&
            tab.title !== "Suite"
        );
      }
    return isLoading ? (
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "40px",
        }}
      >
        {" "}
        <Loader size={50} />{" "}
      </div>
    ) : (
      <Fragment>
        <div className="ms-content-wrapper Dashboard">
          <div className="col-md-12">
            <Breadcrumb />
          </div>
          <div className="ms-panel">
            <div className="ms-panel-body">
              <div
                style={{ width: "100%", textAlign: "left", fontSize: "bold" }}
              >
                <div>
                  {" "}
                  <b> V {settings.APP_VERSION} </b>
                </div>
              </div>
              <div className="row">
                {tabs.map((tab, i) => (
                  <div key={i} className="col-xl-6 col-md-6">
                    <Link to={tab.link}>
                      {" "}
                      <div className="ms-panel-body media ms-panel-hoverable has-border ms-widget ms-has-new-msg ms-notification-widget">
                        {tab.icon_name == "building" ? (
                          <i class="fa fa-building"></i>
                        ) : tab.icon_name == "circle" ? (
                          <i class="fa fa-circle"></i>
                        ) : tab.icon_name == "clock" ? (
                          <i class="fa fa-clock"></i>
                        ) : tab.icon_name == "book" ? (
                          <i class="fa fa-h-square" aria-hidden="true"></i>
                        ) : tab.icon_name == "bull" ? (
                          <i className="fa-solid fa  fa-bullseye"></i>
                        ) : tab.icon_name == "discount" ? (
                          <i class="material-icons fs-16">discount</i>
                        ) : tab.icon_name == "integrate" ? (
                          <img
                            src={integr}
                            className="material-icons fs-16"
                            alt="Bolt icon"
                            style={{
                              width: "34px",
                              height: "34px",
                              marginRight: "20px",
                              backgroundColor: "#f0f0fa",
                              borderRadius: "50%",
                              padding: "5px", // optional, adjust as needed
                            }}
                          />
                        ) : tab.icon_name == "settings" ? (
                          <img
                            src={settin}
                            className="material-icons fs-16"
                            alt="Bolt icon"
                            style={{
                              width: "34px",
                              height: "34px",
                              marginRight: "20px",
                              backgroundColor: "#f0f0fa",
                              borderRadius: "50%",
                              padding: "5px", // optional, adjust as needed
                            }}
                          />
                        ) : (
                          <i className="material-icons">{tab.icon_name}</i>
                        )}
                        <div className="media-body">
                          <h6>{tab.title}</h6>
                          <span>{tab.description}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
