import axios from 'axios';
import * as APIUrl from '../APIUrl';

export const addTable = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.ADD_TABLE,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchRoom = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl. EDIT_ROOM,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}


export const fetchAllTable = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_VACANT_TABLE_DETAILS + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}
export const fetchAllHappyHourDiscounts = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_ALL_HAPPY_HOUR_DISCOUNTS + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const addHappyHourDiscount = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.ADD_HAPPY_HOUR_DISCOUNT,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const deleteTable = (payload) => {
    try {
        return axios({
            method: "delete",
            url: APIUrl.DELETE_TABLE + `${payload.table_id}`,
        });
    } catch (error) {
        throw new Error(error);
    }
};

export const updateHappyHourDiscount = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.UPDATE_HAPPY_HOUR_DISCOUNT,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchHappyHourDiscount = (payload) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.FIND_HAPPY_HOUR_DISCOUNT + `${payload}`,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchAllHappyHourDiscountsItems = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.RETRIEVE_HAPPY_HOUR_DISCOUNT_ITEMS + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}


export const deleteDiscount = (payload) => {
    try {
        return axios({
            method: "delete",
            url: APIUrl.DELETE_DISCOUNT + payload,
        });
    } catch (error) {
        throw new Error(error);
    }
};

export const addDiscountCode = (payload) => {
    try {
        return axios({
            method: "get",
            url: APIUrl.ADD_DISCOUNT_CODE ,
        });
    } catch (error) {
        throw new Error(error);
    }
};
export const updateTable = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.UPDATE_TABLE,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const updatePersonalDetailsWithDocument = (payload) => {
    try {
        return axios({
            method: 'post',
            url: APIUrl.UPDATE_PERSONAL_DETAILS,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const updateBankDetails = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.UPDATE_BANK_DETAILS,
            data: payload
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const updateTermsAndCondition = (property_id) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.UPDATE_TERMS_AND_CONDITION + property_id,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const updateReceipt = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.UPDATE_RECEIPT + `${payload.property_id}&receipt_note=${payload.receipt_note}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const updateTaxDetails = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.UPDATE_TAX_DETAILS,
            data: payload
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchAccountStatus = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_ACCOUNT_DETAILS + `${payload.property_id}&username=${payload.username}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchSplitPayment = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_SPLIT_PAYMENTS + `${payload.property_id}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const updateSplitPayment = (payload) => {
    try {
        return axios({
            method: 'post',
            url: APIUrl.UPDATE_SPLIT_PAYMENTS,
            data: payload
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchMerchantCategoryCodes = () => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_MCC_MERCHANT_CATEGORY_CODE,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchAccountId = (property_id) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_ACCOUNT_ID + property_id,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchTermsAndCondition = (country_code) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_STRIPE_TERMS_AND_CONDITION + country_code,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchPayouts = (payload) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_PAYOUT + payload.property_id + `&page=${payload.page}&size=${payload.size}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}


export const fetchTransactions = (payload) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_TRANSACTIONS + payload.property_id + `&page=${payload.page}&size=${payload.size}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const filterAllTrancactionsByDate = (payload) => {
    let filter_url = '';

    if (((payload.from_date) === undefined) && ((payload.search_text) === '')) { // Pagination
        filter_url = APIUrl.FILTER_TRANSACTIONS_BY_DATE + `${payload.page}&size=${payload.size}&property_id=${payload.property_id}`;
    } else if ((((payload.from_date) === undefined) && ((payload.search_text) !== undefined))) { // Filter
        filter_url = APIUrl.FILTER_TRANSACTIONS_BY_DATE + `${payload.page}&size=${payload.size}&search_text=${payload.search_text}&property_id=${payload.property_id}`;
    } else if (((payload.from_date) != undefined) && ((payload.search_text) === undefined)) { // Initial Load
        filter_url = APIUrl.FILTER_TRANSACTIONS_BY_DATE + `${payload.page}&end_date=${payload.to_date}&size=${payload.size}&start_date=${payload.from_date}&property_id=${payload.property_id}`;
    } else {
        filter_url = APIUrl.FILTER_TRANSACTIONS_BY_DATE + `${payload.page}&size=${payload.size}&property_id=${payload.property_id}`;
    }

    try {
        return axios({
            method: 'get',
            // url: APIUrl.FILTER_ORDER_BY_DATE + `${payload.page}&end_date=${payload.to_date}&size=${payload.size}&start_date=${payload.from_date}`
            url: filter_url
        })
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const filterAllTrancactionsBySearch = (payload) => {
    let filter_url = '';

    if (((payload.from_date) === undefined) && ((payload.search_text) === '')) { // Pagination
        filter_url = APIUrl.FILTER_TRANSACTIONS_BY_SEARCH + `${payload.page}&size=${payload.size}&property_id=${payload.property_id}`;
    } else if ((((payload.from_date) === undefined) && ((payload.search_text) !== undefined))) { // Filter
        filter_url = APIUrl.FILTER_TRANSACTIONS_BY_SEARCH + `${payload.page}&size=${payload.size}&search_text=${payload.search_text}&property_id=${payload.property_id}`;
    } else if (((payload.from_date) != undefined) && ((payload.search_text) === undefined)) { // Initial Load
        filter_url = APIUrl.FILTER_TRANSACTIONS_BY_SEARCH + `${payload.page}&end_date=${payload.to_date}&size=${payload.size}&start_date=${payload.from_date}&property_id=${payload.property_id}`;
    } else {
        filter_url = APIUrl.FILTER_TRANSACTIONS_BY_SEARCH + `${payload.page}&size=${payload.size}&property_id=${payload.property_id}`;
    }

    try {
        return axios({
            method: 'get',
            // url: APIUrl.FILTER_ORDER_BY_DATE + `${payload.page}&end_date=${payload.to_date}&size=${payload.size}&start_date=${payload.from_date}`
            url: filter_url
        })
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}




export const fetchPayoutReports = (payload) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_PAYOUT_REPORT + payload.payout_id,
            responseType: 'blob',

        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchPayoutDetails = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_PAYOUT_DETAILS + `${payload.payout_id}`
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchTransactionDetails = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_TRANSACTIONS_DETAILS + `${payload.transaction_id}`
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchOrderDetailsByPayoutId = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_ORDER_DETAILS_BY_PAYOUT_ID + `${payload.payment_id}&property_id=${payload.property_id}`,
        })
    } catch (error) {

    }
}



