import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import { toast } from 'react-toastify'
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import * as PaymentServiceAPI from '../../../services/payment/PaymentServiceAPI'
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Papa from 'papaparse';
import Loader from "../../../shared/Loader";
import { connect } from "react-redux";
import {getCurrencySymbol} from "../../../config";
class Detailcontent extends Component {

    state = {
        payout_transactions: [],
        payoutTransactions:[],
        payout_summary: '',
        row: '',
        total: 0,
        payout_id: '',
        origin: '',
        type: '',
        currency: '',
        today_date: new Date().toLocaleDateString(),

    }

    componentDidMount = () => {
        const state = this.props.history.location.state
        this.setState({ row: this.props?.history?.location?.state?.row })

        const payload = { payout_id: state.row.payout_id }
        this.fetchPayoutDetails(payload)
    }

    fetchPayoutDetails = (payload) => {
        this.setState({
            isLoading: true
        })
        PaymentServiceAPI.fetchPayoutDetails(payload).then(response => {
            if (response.data.status) {
                const sortedTransactions = response.data?.data?.payout_transactions?.sort((a, b) => new Date(a.created) - new Date(b.created));
                this.setState({
                    payout_transactions:  response.data.data, payoutTransactions:sortedTransactions,  isLoading: false
                })
            } else {
                this.setState({
                    payout_transactions: [], isLoading: false
                })
                toast.warn(response.data.message)
            }
        }).catch(err => {
            this.setState({
                payout_transactions: [], isLoading: false
            })
            toast.error("Something went wrong.");
        })
    }

    fetchPayoutReport = () => {

        const payload = {
            payout_id: this.state.row.payout_id

        }


        PaymentServiceAPI.fetchPayoutReports(payload).then((response) => {
            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            this.downloadFile(fileURL);
        }).catch(error => {
            console.log(error);
        });;



    };

    handleExportCSV = () => {
       
        const csvString = Papa.unparse(this.state?.payout_transactions?.payout_transactions);

        const dataUrl = `data:text/csv;charset=utf-8,${encodeURIComponent(csvString)}`;

        const downloadLink = document.createElement('a');
        downloadLink.href = dataUrl;
        downloadLink.download = 'transactions.csv';
        downloadLink.click();
    };

    downloadFile(filePath) {
        var link = document.createElement('a');
        link.href = filePath;
        link.download = "invoice";
        link.click();
    }

    showOrderDetails = (value) => {

        const payload = {
            property_id: this.state.property_id,
            payment_id: value
        }
        PaymentServiceAPI.fetchOrderDetailsByPayoutId(payload).then(response => {
            if (response.data.status) {
                const order = response.data.data;
                this.props.history.push({
                    pathname: '/orderdetails',
                    state: {
                        order: order
                    }
                })
            }
        })
    }

    customValiditySort = (rowA, rowB) => {
        const dateA = new Date(rowA.created);
        const dateB = new Date(rowB.created);
        return dateA - dateB; // Compare Date objects
    };

    render() {

        const columns = [


            {
                name: "DATE",
                selector: "created",
                sortable: true,
                justifyContent: 'center',
                sortFunction: this.customValiditySort,
                cell: row => <div ><div>{dayjs(row.created).format('DD-MM-YYYY')}</div></div>
            },

            {
                name: "ORDER NO",
                selector: "description",
                sortable: true,
                justifyContent: 'center',
                cell: row => <div ><div>{row.description}</div></div>
            },

            {
                name: "PAYOUT ID",
                selector: "payout_id",
                sortable: true,
                justifyContent: 'center',
                cell: row => <div><div>{row.id}</div></div>
            },

            {
                name: "GROSS AMOUNT",
                selector: "total",
                sortable: true,
                justifyContent: 'center',
                cell: row => <div >  {getCurrencySymbol(localStorage.getItem('currency_code')) + row.gross ? row.gross?.toFixed(2) : getCurrencySymbol(localStorage.getItem('currency_code')) + Number(0.00).toFixed(2)}</div>
            },


            {
                name: "FEE",
                selector: "fee",
                sortable: true,
                justifyContent: 'center',
                cell: row => <div >    {getCurrencySymbol(localStorage.getItem('currency_code')) + row.fee ? row.fee?.toFixed(2) : getCurrencySymbol(localStorage.getItem('currency_code')) + Number(0.00).toFixed(2)}</div>
            },

            {
                name: "REFUND",
                selector: "refund",
                sortable: true,
                justifyContent: 'center',
                cell: row => <div >  {getCurrencySymbol(localStorage.getItem('currency_code')) + row.refund ? row.refund?.toFixed(2) : Number(0.00).toFixed(2)}</div>
            },
            {
                name: "INCOMING SPLIT",
                selector: "inComingSales",
                sortable: true,
                justifyContent: 'center',
                cell: row => <div > {getCurrencySymbol(localStorage.getItem('currency_code')) + row.inComingSales ? row.inComingSales?.toFixed(2) : Number(0.00).toFixed(2)}</div>
            },
            {
                name: "OUTGOING SPLIT",
                selector: "outGoingSales",
                sortable: true,
                justifyContent: 'center',
                cell: row => <div >  {getCurrencySymbol(localStorage.getItem('currency_code')) + row.outGoingSales? row.outGoingSales?.toFixed(2) : Number(0.00).toFixed(2)}</div>
            },

            {
                name: "Tip",
                selector: "tip",
                sortable: true,
                justifyContent: 'center',
                cell: row => <div >  {getCurrencySymbol(localStorage.getItem('currency_code')) + row.tip ? row.tip?.toFixed(2) : Number(0.00).toFixed(2)}</div>
            },


            {
                name: "TOTAL",
                selector: "amount",
                sortable: true,
                justifyContent: 'center',
                cell: row => <div >  {getCurrencySymbol(localStorage.getItem('currency_code')) + row.total ? row.total?.toFixed(2) : Number(0.00).toFixed(2)}</div>
            },










        ]

        const customStyles = {
            rows: {
                style: {
                    justifyContent: 'center',
                    textAlign: 'center',
                }
            },
            headCells: {
                style: {
                    minHeight: '65px',
                    justifyContent: 'center',
                    textAlign: 'center',
                    backgroundColor: '#5773ff',
                    fontSize: '14px',
                    color: 'white',
                    paddingLeft: '16px', 
                    paddingRight: 'auto',

                },
            },
            cells: {
                style: {
                    paddingLeft: '2px', 
                    paddingRight: '2px',
                    pointer: 'cursor',
                    justifyContent: 'center',
                    textAlign: 'center',
                },
            },
        };

        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb />
                        {this.state.isLoading ? <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "40px" }}>   <Loader size={50} /> </div> :
                            <div className="ms-panel">
                                <div className="ms-panel-header header-mini">
                                    <div className="d-flex justify-content-between">

                                        <div>
                                            <h4 className='mt-3 d-inline'> {getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions?.total?.toFixed(2) || Number(0).toFixed(2) || Number(0).toFixed(2) }   </h4> <Chip style={{ color: "white" }} className="d-inline" label={this.state.row.status == "booked" ? "PAID" : ""} />
                                            <div className="col-md-12" sx={{ marginTop: "100px !important" }}>

                                                <br />
                                                <h6>
                                                    Note: Payout includes transactions from Sunday 22:00:00 GMT till next week same time.
                                                </h6>

                                            </div>
                                        </div>
                                        { !this.state.row?.isTipPayout &&
                                        <div className="invoice-buttons text-right">
                                            <Button variant="contained" onClick={() => this.fetchPayoutReport()} >Download </Button>
                                        </div> }
                                    </div>
                                </div>

                                <div className="ms-panel-body">
                                    <div className="row align-items-center">

                                        <div className="col-md-6 ">
                                            <div className=" table-heading">
                                                <h4>Overview</h4>
                                            </div>
                                            <Divider variant="middle" />

                                            <br></br>
                                            <div className="ms-invoice-table table-responsive">


                                                <TableContainer component={Paper}>
                                                    <Table size="small" aria-label="a dense table" >

                                                        <TableBody >

                                                            <TableRow >
                                                                <TableCell component="th" scope="row">Bank Account</TableCell>
                                                                <TableCell> {this.state.row.bank_account_last_4_digit}</TableCell>

                                                            </TableRow>
                                                            <TableRow >
                                                                <TableCell component="th" scope="row"  >Date Paid</TableCell>
                                                                <TableCell>{dayjs(this.state.row.created).format('DD-MM-YYYY')}</TableCell>

                                                            </TableRow>

                                                            <TableRow >
                                                                <TableCell component="th" scope="row"  >Status</TableCell>
                                                                <TableCell  >{this.state.row.status == "booked" ? "PAID" : ""
                                                                }</TableCell>

                                                            </TableRow>

                                                            <TableRow >
                                                                <TableCell component="th" scope="row">Payout Id</TableCell>
                                                                <TableCell  >{this.state.row.payout_id}</TableCell>

                                                            </TableRow>
                                                            <TableRow >
                                                                <TableCell component="th" scope="row">Transfer Id</TableCell>
                                                                <TableCell  >{this.state.row.transferId}</TableCell>

                                                            </TableRow>
                                                            <TableRow >
                                                                <TableCell component="th" scope="row">Currency</TableCell>
                                                                <TableCell>{this.state.row.currency}</TableCell>

                                                            </TableRow>


                                                        </TableBody>


                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="row align-items-center">


                                        <div className="col-md-6 ">
                                            <div className=" table-heading">
                                                <h4>Summary</h4>
                                            </div>
                                            <Divider variant="middle" />

                                            <br></br>
                                            <div className="ms-invoice-table table-responsive">


                                                <TableContainer component={Paper}>
                                                    <Table size="small" aria-label="a dense table" >


                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell></TableCell>
                                                                <TableCell >Count</TableCell>
                                                                <TableCell >Gross</TableCell>
                                                                <TableCell >Total</TableCell>
                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody >
                                                           {!this.state.row?.isTipPayout && <TableRow >
                                                                <TableCell component="th" scope="row"  >Incoming Split</TableCell>
                                                                <TableCell> {this.state.payout_transactions.incoming_count}</TableCell>
                                                                <TableCell  style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions?.incomingSales?.toFixed(2)}</TableCell>
                                                                <TableCell  style={{ minWidth: '150px' }} >{getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions?.incomingSales?.toFixed(2)}</TableCell>
                                                            </TableRow>}
                                                            { !this.state.row?.isTipPayout &&  <TableRow >  
                                                                <TableCell component="th" scope="row"  > Business Sales</TableCell>
                                                                <TableCell> {this.state.payout_transactions.transaction_count}</TableCell>
                                                                <TableCell  style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code')) } { (Number(this.state.payout_transactions.amount) - Number(this.state.payout_transactions.incomingSales)).toFixed(2) || Number(0).toFixed(2)}</TableCell>
                                                                <TableCell  style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code')) } { (Number(this.state.payout_transactions.amount) - Number(this.state.payout_transactions.incomingSales)).toFixed(2) || Number(0).toFixed(2)}</TableCell>
                                                            </TableRow> }
                                                            {!this.state.row?.isTipPayout &&<TableRow >
                                                                <TableCell component="th" scope="row"  > Incoming Payment</TableCell>
                                                                <TableCell> {this.state.payout_transactions.incoming_payment_count}</TableCell>
                                                                <TableCell style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code'))} {(Number(this.state.payout_transactions.incomingPayment)) || Number(0).toFixed(2)}</TableCell>
                                                                <TableCell style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code'))} {(Number(this.state.payout_transactions.incomingPayment)).toFixed(2) || Number(0).toFixed(2)}</TableCell>
                                                            </TableRow> }
                                                            
                                                           { this.state.row?.isTipPayout &&  <TableRow >
                                                                <TableCell component="th" scope="row"  >Tip</TableCell>
                                                                <TableCell>{this.state.payout_transactions.tipSalesCount}</TableCell>
                                                                <TableCell style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code')) } {this.state.payout_transactions.tipSales?.toFixed(2) || Number(0).toFixed(2)}</TableCell>
                                                                <TableCell  style={{ minWidth: '150px' }} >{getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions.tipSales?.toFixed(2) || Number(0).toFixed(2)}</TableCell>

                                                            </TableRow> }
                                                          {!this.state.row?.isTipPayout &&  <TableRow >
                                                                <TableCell component="th" scope="row"  >Capital Received</TableCell>
                                                                <TableCell> {this.state.payout_transactions?.grants_received_count }</TableCell>
                                                                <TableCell  style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code')) } {this.state.payout_transactions.grants_received?.toFixed(2) || Number(0).toFixed(2)}</TableCell>
                                                                <TableCell  style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code')) } {this.state.payout_transactions.grants_received?.toFixed(2) || Number(0).toFixed(2)}</TableCell>
                                                            </TableRow>}
                                                          

                                                            {!this.state.row?.isTipPayout &&  <TableRow >
                                                                <TableCell component="th" scope="row"><b>Net Charges</b></TableCell>
                                                                <TableCell> {/* {this.state.payout_transactions.transaction_count} */}</TableCell>
                                                                <TableCell  style={{ minWidth: '150px' }} > <b>{getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions.total_amount || Number(0).toFixed(2)} </b></TableCell>
                                                                <TableCell style={{ minWidth: '150px' }} > <b>   {getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions.total_amount || Number(0).toFixed(2) } </b></TableCell>

                                                            </TableRow>}
                                                            <TableRow >
                                                                <TableCell component="th" scope="row"  >Refunds</TableCell>
                                                                <TableCell>{this.state.payout_transactions.refund_count}</TableCell>
                                                                <TableCell style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code')) } {this.state.payout_transactions.refund?.toFixed(2) || Number(0).toFixed(2)}</TableCell>
                                                                <TableCell  style={{ minWidth: '150px' }} >{getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions.refund?.toFixed(2) || Number(0).toFixed(2)}</TableCell>

                                                            </TableRow>
                                                            {!this.state.row?.isTipPayout &&  <TableRow >
                                                                <TableCell component="th" scope="row"  >Tip</TableCell>
                                                                <TableCell>{this.state.payout_transactions.tipSalesCount}</TableCell>
                                                                <TableCell style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code')) } {this.state.payout_transactions.tipSales?.toFixed(2) || Number(0).toFixed(2)}</TableCell>
                                                                <TableCell  style={{ minWidth: '150px' }} >{getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions.tipSales?.toFixed(2) || Number(0).toFixed(2)}</TableCell>

                                                            </TableRow> }


                                                            {!this.state.row?.isTipPayout &&  <TableRow >
                                                                <TableCell component="th" scope="row"  >Application Fees</TableCell>
                                                                <TableCell> {this.state.payout_transactions.transaction_count}</TableCell>
                                                                <TableCell  style={{ minWidth: '150px' }} >{getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions.fee?.toFixed(2) || Number(0).toFixed(2)}</TableCell>
                                                                <TableCell  style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions.fee?.toFixed(2) || Number(0).toFixed(2)}</TableCell>
                                                            </TableRow>}

                                                            {!this.state.row?.isTipPayout &&  <TableRow >
                                                                <TableCell component="th" scope="row"  >Outgoing Split</TableCell>
                                                                <TableCell> {this.state.payout_transactions.outgoing_count}</TableCell>
                                                                <TableCell  style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions.outgoingSales?.toFixed(2) || Number(0).toFixed(2)}</TableCell>
                                                              {/*   <TableCell  style={{ minWidth: '150px' }} >£ 0.00</TableCell> */}
                                                                <TableCell  style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions.outgoingSales?.toFixed(2) || Number(0).toFixed(2) }</TableCell>
                                                            </TableRow>}
                                                            {!this.state.row?.isTipPayout &&  <TableRow >
                                                                <TableCell component="th" scope="row"  >Deduction at Source</TableCell>
                                                                <TableCell> {this.state.payout_transactions.deduction_count}</TableCell>
                                                                <TableCell  style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code')) } {this.state.payout_transactions.deductionAtSource?.toFixed(2) || Number(0).toFixed(2) }</TableCell>
                                                              {/*   <TableCell  style={{ minWidth: '150px' }} >£ 0.00</TableCell> */}
                                                                <TableCell  style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions.deductionAtSource?.toFixed(2)  || Number(0).toFixed(2) }</TableCell>
                                                            </TableRow>}
                                                            {!this.state.row?.isTipPayout &&  <TableRow >
                                                                <TableCell component="th" scope="row"  >Capital Repayment</TableCell>
                                                                <TableCell> { this.state.payout_transactions.grants_repayment == 0 ? 0 : 1}</TableCell>
                                                                <TableCell  style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions.grants_repayment?.toFixed(2) || Number(0).toFixed(2)}</TableCell>
                                                             {/*    <TableCell  style={{ minWidth: '150px' }} >£ 0.00</TableCell> */}
                                                                <TableCell  style={{ minWidth: '150px' }} > {getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions.grants_repayment?.toFixed(2) || Number(0).toFixed(2)}</TableCell>
                                                            </TableRow>}

                                                            <TableRow >
                                                                <TableCell component="th" scope="row"><b>Payouts </b></TableCell>
                                                                <TableCell  ></TableCell>
                                                                <TableCell  ></TableCell>
                                                              {/*   <TableCell  ></TableCell> */}
                                                                <TableCell  style={{ minWidth: '150px' }} ><b>  {getCurrencySymbol(localStorage.getItem('currency_code')) } { this.state.payout_transactions?.total?.toFixed(2) || Number(0).toFixed(2) || Number(0).toFixed(2) } </b></TableCell>
                                                            </TableRow>



                                                        </TableBody>


                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        </div>
                                    </div>

                                    <Grid item xs={12} md={12} style={{ paddingTop: 5, marginTop: 10 }} >
                                        <br></br>
                                        <br></br>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button variant="contained" style={{ float: "right" }} color="primary" onClick={this.handleExportCSV}>
                                                Export CSV
                                            </Button>  </div>
                                        <Typography variant="h5" gutterBottom>
                                            Transactions
                                        </Typography>
                                        <Divider variant="middle" />
                                    </Grid>

                                    <div className="ms-invoice-table table-responsive mt-5">
                                        <DataTable
                                            columns={columns}
                                            customStyles={customStyles}
                                            pagination
                                            paginationRowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                                            data={this.state.payoutTransactions}
                                        />
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    settings: state.user.settings,
  });
  

export default connect(mapStateToProps, {})(Detailcontent);