import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import $ from "jquery";
import Breadcrumb from "./Breadcrumb";
import { Jumbotron, Container } from "react-bootstrap";
import ApiIcon from "@mui/icons-material/Api";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { connect } from "react-redux";
import Loader from "../../../shared/Loader";
class Content extends Component {
  state = {
    status: "",
  };

  componentDidMount = () => {
    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }
  };

  render() {
    let tabs = [
      {
        title: "Kiosk Discounts",
        link: "/discount/Kiosk",
        description: "Manage Kiosk Discount.",
        icon_name: "ad_units",
      },
      {
        title: "Web Discounts",
        link: "/discount/Web",
        description: "Manage Web Discount.",
        icon_name: "language",
      },
      {
        title: "Mobile Discounts",
        link: "/discount/Mobile",
        description: "Manage Mobile Discount.",
        icon_name: "smartphone",
      },
      {
        title: "QR Discounts",
        link: "/discount/QR",
        description: "Manage QR Discount.",
        icon_name: "qr_code",
      },
      {
        title: "Happy Hour Discount",
        link: "/happy-hour-discount",
        description: "Manage Happy Hour Discount.",
        icon_name: "local_bar",
      },
      {
        title: "Epos Discounts",
        link: "/discount/Epos",
        description: "Manage Epos Discount.",
        icon_name: "point_of_sale",
      },
      {
        title: "Mpos Discounts",
        link: "/discount/Mpos",
        description: "Manage Mpos Discount.",
        icon_name: "tablet_mac",
      },
    ];

    const { status, isLoading } = this.state;

    return isLoading ? (
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "40px",
        }}
      >
        {" "}
        <Loader size={50} />{" "}
      </div>
    ) : (
      <Fragment>
        <div className="ms-content-wrapper Dashboard">
          <div className="col-md-12">
            <Breadcrumb />
          </div>
          <div className="ms-panel">
            <div className="ms-panel-body">
              <div className="row">
                {tabs.map((tab, i) => (
                  <div key={i} className="col-xl-6 col-md-6">
                    <Link to={tab.link}>
                      {" "}
                      <div className="ms-panel-body media ms-panel-hoverable has-border ms-widget ms-has-new-msg ms-notification-widget">
                        {tab.icon_name == "building" ? (
                          <i className="fa fa-building"></i>
                        ) : tab.icon_name == "circle" ? (
                          <i className="fa fa-circle"></i>
                        ) : tab.icon_name == "clock" ? (
                          <i className="fa fa-clock"></i>
                        ) : tab.icon_name == "bull" ? (
                          <i className="fa-solid fa  fa-bullseye"></i>
                        ) : (
                          <i className="material-icons">{tab.icon_name}</i>
                        )}
                        <div className="media-body">
                          <h6>{tab.title}</h6>
                          <span>{tab.description}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
