import React, { useState, useEffect } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {getCurrencySymbol} from "../../../../config";


export default function Tab(props) {

    const [data, setData] = useState([]);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        // setData(props.data);
        console.log(props)
        if (props?.addons?.addons)
            Object.values(props?.addons?.addons).filter(function (data) {
                return data.addon_type === props.type;
            }).map((row) => console.log(row));

    }, []);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (

        <div>
            {/*     < pre>{JSON.stringify(props.data, null, 2)}</pre> */}




            <Button style={{ padding: "10px", color: "white", width: "100px", background: "#5773ff" }} color="primary" size="small" onClick={handleClickOpen} >
                View  +
            </Button>



            {/*       {Object.keys(formErrors).length === 0 && isSubmitting && (
                <span className="success-msg">Signed in successfully</span>
            )} */}
            <Dialog open={open} onClose={handleClose}>

                <DialogContent>



                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell >Identifier</TableCell>
                                    <TableCell align="right">Quantity</TableCell>
                                    <TableCell align="right">Sub Total   {/* &#163; */} </TableCell>
                                </TableRow>
                            </TableHead>



                            <TableBody style={{ width: "100%" }}  >
                                {props?.addons?.addons && Object.values(props?.addons?.addons).filter(function (data) {
                                    return data.addon_type === props.type;
                                }).map((row) => (
                                    <TableRow key={row.item_id}>
                                        <TableCell component="th" scope="row"  >{row.addon_identifier}</TableCell>
                                        <TableCell align="center" >{row.addon_quantity}</TableCell>
                                        <TableCell align="center"> {getCurrencySymbol(localStorage.getItem('currency_code')) + row.sub_total.toFixed(2)}  </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>


                        </Table>
                    </TableContainer>
                </DialogContent>



            </Dialog>
        </div>
    )
};
