import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import './style.css'
import TextField from "@mui/material/TextField"

import InputAdornment from "@mui/material/InputAdornment";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Password = (props) => {
    const passwordSchema = Yup.object().shape({
        old_password: Yup.string()
            .required('Password is required'),
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .notOneOf([Yup.ref('old_password')], 'New password cannot be the same as old password')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords Must Match')
            .required('Password is required'),
    });

    return (
        <Formik
            initialValues={{
                password: '',
                old_password: '',
                confirmPassword: '',
            }}
            validationSchema={passwordSchema}
            onSubmit={values => {
                // same shape as initial values
                console.log(values)
                props.needPasswordChangeUpdate(values);
            }}
        >
            {({ errors, status, touched, handleChange }) => (

                <Form >
                    <div className="row">
                        <div className="col-md-12">


                            <TextField
                                error={
                                    errors.old_password && touched.old_password
                                        ? true
                                        : false
                                }
                                fullWidth
                                name="old_password" label="Old Password" type={props.state.oldPasswordShow ? 'text' : 'password'}
                                onChange={handleChange}
                                helperText={
                                    errors.old_password && touched.old_password
                                        ? errors.old_password
                                        : null
                                }
                            />
                            {/* {props.state.oldPasswordShow ? <i onClick={() => props.handleClickShowPassword('oldPasswordShow')} className="far fa-eye" id="togglePassword"></i> :
                                <i onClick={() => props.handleClickShowPassword('oldPasswordShow')} className="far fa-eye-slash" id="togglePassword"></i>} */}

                            <br></br>  <br></br>
                        </div>




                        <div className="col-md-12 ">


                            <TextField
                                error={
                                    errors.password && touched.password
                                        ? true
                                        : false
                                }
                                name="password" label="Password" type={props.state.passwordShow ? 'text' : 'password'}
                                fullWidth
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {props.state.passwordShow ? (
                                                <Visibility
                                                    onClick={props.togglePasswordHide}
                                                />
                                            ) : (
                                                <VisibilityOff
                                                    onClick={props.togglePasswordHide}
                                                />
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={
                                    errors.password && touched.password
                                        ? errors.password
                                        : null
                                }
                            />
                            {/* {props.state.passwordShow ? <i onClick={() => props.handleClickShowPassword('passwordShow')} className="far fa-eye" id="togglePassword"></i> :
                                <i onClick={() => props.handleClickShowPassword('passwordShow')} className="far fa-eye-slash" id="togglePassword"></i>} */}

                            <br></br>  <br></br>
                        </div>


                        <div className="col-md-12">

                            <TextField
                                error={
                                    errors.confirmPassword && touched.confirmPassword
                                        ? true
                                        : false
                                }
                                fullWidth
                                name="confirmPassword" label="Confirm Password" type={props.state.passwordShow ? 'text' : 'password'}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {props.state.passwordShow ? (
                                                <Visibility
                                                    onClick={props.togglePasswordHide}
                                                />
                                            ) : (
                                                <VisibilityOff
                                                    onClick={props.togglePasswordHide}
                                                />
                                            )}
                                        </InputAdornment>
                                    ),
                                }}

                                helperText={
                                    errors.confirmPassword && touched.password
                                        ? errors.confirmPassword
                                        : null
                                }
                            />
                            {/* {props.state.confirmPasswordShow ? <i onClick={() => props.handleClickShowPassword('confirmPasswordShow')} className="far fa-eye" id="togglePassword"></i> :
                                <i onClick={() => props.handleClickShowPassword('confirmPasswordShow')} className="far fa-eye-slash" id="togglePassword"></i>} */}
                            <br></br>  <br></br>
                        </div>



                        <button className="btn btn-primary mt-4 d-block w-100" type="submit">{props.button_text}</button>
                    </div>
                </Form>
            )}
        </Formik>
    )


}
export default Password;