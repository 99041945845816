import React, { Component, Fragment } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
// import * as customerimg from "../../assets/img/annonymous-user-images.jpg";
import * as OrderServiceAPI from "../../../services/order/OrderServiceAPI";
import CommonModal from "../../../shared/Modal/CommonModal";
import Pagination from "../../../shared/Pagination/Pagination";
import Breadcrumb from "./Breadcrumb";
import CustomStepper from "./Stepper";
import ItemStepper from "./ItemStepper";
import { Accordion, Card } from "react-bootstrap";
import { withScriptjs } from "react-google-maps";
import Map from "../GoogleMaps/GMapDirections";
import Tab from "./components/Tab";
import happy from "../../../assets/img/feedback/happy.png";
import angry from "../../../assets/img/feedback/angry.png";
import normal from "../../../assets/img/feedback/normal.png";
import soon from "../../../assets/img/icons8-coming-soon-96.png";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Loader from "../../../shared/Loader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { withStyles, createStyles } from "@mui/styles";
import { Stepper, Step, StepLabel, Box } from "@mui/material";
import { ButtonGroup } from "@mui/material";
import { format } from "date-fns";
import { getCurrencySymbol } from "../../../config";
import * as PaymentLinksServiceAPI from "../../../services/paymentLinks/PaymentLinksServiceAPI";
import { da } from "date-fns/locale";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Stack } from "@mui/material";
import Accordian from "./Accordian";
// API key of the google map
const MapLoader = withScriptjs(Map);

const styles = (theme) =>
  createStyles({
    timeline: {
      transform: "rotate(-90deg)",
    },
    timelineContentContainer: {
      textAlign: "left",
    },
    timelineContent: {
      display: "inline-block",
      transform: "rotate(-90deg)",
      textAlign: "center",
      minWidth: 50,
    },
    timelineIcon: {
      transform: "rotate(-90deg)",
    },
  });
class Detailcontent extends Component {
  state = {
    data: "",
    order_Details: [],
    profileImagePreviewUrl: "",
    profile_image_url: "",
    order_messages: [],
    order_status: "",
    user_username: "",
    show: false,
    loading: false,
    order_id: "",
    item_status: "",

    current_page: 0,
    number_of_elements: 10,
    page_size: 10,
    total_elements: 0,
    total_pages: 0,

    selectDeliveryAgent: false,
    deliveryAgentsDetails: [],
    allocate_delivery_agent_detail: "",
    selecttag: true,
    loadMap: false,

    // Map data is an Object not Array
    map_data: {}, // map_data: [],

    delivery_agent_assigned: false,
    order_type: false,
    order: null,
    is_own_delivery: false,
    errMessage: "",
    isLoader: false,
    open: false,
    refundType: "full",
    refundAmount: "",
    amount: "",
    deliveryStatusHistory: [],
  };

  componentDidMount = () => {
    this.interval = setInterval(() => window.location.reload(), 300000);
    const order = this.props?.history?.location?.state?.order;
    const payload = {
      order_id: order?.order_id,
      page: 0,
      size: 5,
    };

    this.setState(
      {
        username: localStorage.getItem("username"),
        order_id: order?.order_id,
        order: order,
      },
      this.fetchOrderDetailsByOrderId(payload)
    );
  };

  componentWillUnmount() {
    if (
      window.location.pathname !== "/orders-delivery/delivery" &&
      window.location.pathname !== "/orderdetails"
    ) {
      localStorage.setItem("current_page", 0);
      localStorage.setItem("number_of_elements", 15);
    }
  }

  fetchOrderDetailsByOrderId = (payload) => {
    this.setState({ isLoading: true });
    OrderServiceAPI.fetchOrderDetailsByOrderId(payload)
      .then((response) => {
        if (response.data.status) {
          if (response && response.data && response.data.data) {
            this.setState({
              data: response.data.data,
              total_elements: response.data.data.order_Details?.length,
              item_status: response.data.data?.order_status,
              isLoading: false,
              deliveryStatusHistory:
                response.data?.data?.delivery_details?.deliveryStatusHistory,
            });
          } else {
            this.setState({});
          }
        } else {
          toast.error(response.data.message);
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        toast.error("Oops !! Something went wrong");
        this.setState({ isLoading: false });
      });
  };

  handleDeliveryAgentOnChange = (event) => {
    this.state.deliveryAgentsDetails.map((dgd) => {
      if (dgd.name === event.target.value) {
        this.setState({ allocate_delivery_agent_detail: dgd });
      }
    });
    this.setState({
      delivery_agent: event.target.value,
      selectDeliveryAgent: true,
    });
  };

  pushOrderNotificationToUser = () => {
    this.setState({ loading: true });

    const payload = {
      message: this.state.order_status,
      username: this.state.user_username,
      order_id: this.state.order_id,
    };

    if (payload.message === "" || payload.message === null) {
      this.state.errMessage = "Can't leave message field blank";
      return false;
    }

    OrderServiceAPI.pushOrderNotificationToUser(payload)
      .then((response) => {
        if (response.data.status) {
          this.setState({ loading: false, show: false });
          toast.success("Message Sent Successfully");
        } else {
          toast.error(response.data.message);
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false, show: false });
      });
  };

  onChangeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  changeCurrentPage = (event, value) => {
    event.preventDefault();
    const page = value - 1;
    this.setState({ currentPage: value });
    const payload = {
      page: page,
      size: this.state.number_of_elements,
      property_id: this.state.property_id,
    };
    this.fetchAllOrders(payload);
  };

  handleModal = () => {
    this.setState({ show: !this.state.show });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ number_of_elements: parseInt(event.target.value, 10) });
  };

  getItemSteps() {
    return ["NEW", "PREPARING ITEM", "ITEM READY"];
  }

  toggleHandler = (current) => {
    this.setState(
      {
        [current]: !this.state[current],
      },
      this.updateOwnDelivery
    );
  };

  updateOwnDelivery = () => {
    const payload = {
      order_id: this.state.order_id,
      own_delivery_status: this.state.is_own_delivery,
    };

    OrderServiceAPI.updateOwnDeliveryStatus(payload)
      .then((response) => {
        if (response.data.status) {
          toast.success("Status Updated Successfully");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false, show: false });
      });
  };

  updateOrderStatus = () => {
    if (this.state.refundType == "full") {
      this.setState({
        /*  isLoading: true, */ open: false,
      });

      let payload = {
        order_id: this.state.order.order_id,
        order_status: "ORDER REJECTED",
      };

      OrderServiceAPI.updateOrderStatus(payload)
        .then((response) => {
          if (response.data.status) {
            toast.success("Order refund Initiated.");
            this.setState({ open: false, isLoading: false });
            setTimeout(() => {
              window.location.reload();
            }, 3500);
          } else {
            this.setState({
              data: [],
              open: false,
              isLoading: false,
            });
            toast.success("Couldn't refund order.");
          }
        })
        .catch((err) => {
          toast.warning("Error : ", err);
          toast.success("Something has gone wrong. ");
          this.setState({
            isLoading: false,
            open: false,
          });
        });
    } else {
      this.setState({
        /*  isLoading: true, */ open: false,
      });

      if (this.state.refundAmount && this.state.refundAmount > 0) {
        let payload = {
          order_id: this.state.order.order_id,
          refund_amount: this.state.refundAmount,
        };

        OrderServiceAPI.updatePartialRefund(payload)
          .then((response) => {
            if (response.data.status) {
              toast.success("Partial order refund Initiated.");
              this.setState({ open: false, isLoading: false });
              setTimeout(() => {
                window.location.reload();
              }, 3500);
            } else {
              this.setState({
                data: [],
                open: false,
                isLoading: false,
              });
              toast.success("Couldn't partially refund order.");
            }
          })
          .catch((err) => {
            toast.warning("Error : ", err);
            toast.success("Something has gone wrong. ");
            this.setState({
              isLoading: false,
              open: false,
            });
          });
      } else {
        toast.warn("Refund amount is required and can't be equal 0.");
      }
    }
  };

  refundSlip = () => {
    const payload = {
      order_id: this.state.order.order_id,
    };

    OrderServiceAPI.refundSlip(payload)
      .then((response) => {
        if (response) {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file); //Open the URL on new Window
          // window.open(fileURL);
          this.downloadFile(fileURL);
          //   this.setState({ isLoading: false, zReport: fileURL })
        } else {
          //     toast.error(response.data.message);
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  downloadFile = (fileURL) => {
    var link = document.createElement("a");
    link.href = fileURL;
    link.download = "refundSlip";
    link.click();
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handlePreauthOpen = (text) => {
    this.setState({ openPreAuth: true, amount: "", lab: text });
  };
  handleCancelTrasactionClose = () => {
    this.setState({ openPreAuthCancel: false });
  };

  handlePreAuthCancel = () => {
    this.setState({
      openPreAuthCancel: true,
    });
  };

  handleClosePreAuth = () => {
    this.setState({ openPreAuth: false, amount: "" });
  };
  captureOrUpdate = () => {
    if (this.state.amount > this.state.data?.order_sub_total) {
      toast.warn(
        `Capture amount should be less than or equal to ${this.state.data?.order_sub_total}`
      );
    } else {
      if (this.state.amount) {
        this.setState({ isLoading: true });
        const payload = {
          order_id: this.state?.order_id,
          amount: this.state.amount,
        };

        if (this.state.lab === "Capture") {
          PaymentLinksServiceAPI.capturePayByLinkPreAuth(payload)
            .then((response) => {
              if (response.data.status) {
                this.setState({
                  openPreAuth: false,
                  isLoading: false,
                  bool: false,
                });
                toast.success("Amount Captured.");
                setTimeout(() => {
                  window.history.back();
                }, 2000);
              } else {
                this.setState({
                  data: [],
                  open: false,
                  isLoading: false,
                  openPreAuth: false,
                });
                toast.warn("Couldn't capture amount.");
              }
            })
            .catch((err) => {
              toast.warning("Error : ", err);
              toast.success("Something has gone wrong. ");
              this.setState({
                isLoading: false,
                open: false,
                openPreAuth: false,
              });
            });
        }
      } else {
        toast.warn("Amount is required.");
        this.setState({ openPreAuth: false });
      }
    }
  };
  cancelTransaction = () => {
    this.setState({
      /*  isLoading: true, */ openPreAuthCancel: false,
    });

    const payload = {
      order_id: this.state?.data?.order_id,
    };

    PaymentLinksServiceAPI.cancelTransaction(payload)
      .then((response) => {
        if (response.data.status) {
          toast.success("Transaction cancelled.");
          this.setState({ openPreAuthCancel: false, isLoading: false });
          setTimeout(() => {
            window.history.back();
          }, 2000);
        } else {
          this.setState({
            openPreAuthCancel: false,
            isLoading: false,
          });
          toast.warn("Couldn't cancel transaction.");
        }
      })
      .catch((err) => {
        toast.warning("Error : ", err);
        toast.success("Something has gone wrong.");
        this.setState({
          isLoading: false,
          openPreAuthCancel: false,
        });
      });
  };

  calculateNetAmount = () => {
    let subTotal = this.state.data?.order_sub_total;
    let tax = this.state.data?.tax_details?.tax_on_order;
    let applicationFee = this.state.data?.tax_details?.application_fee_amount;
    let merchantFee = this.state.data?.tax_details?.merchant_fee_amount;
    let totalSplitPayment = 0;
    let discount = this.state.data?.discounted_amount;
    let refund = this.state.data?.partial_refund_amount;
    let Grandtotal = this.calculateGrandTotal();
    //    let additionalCharges = 0;

    if (this.state.data.payment_mode !== "cash") {
      if (this.state.data?.split_property_fee_map) {
        totalSplitPayment = Object.values(
          this.state.data.split_property_fee_map
        ).reduce((acc, value) => acc + value, 0);
      }
    }

    /*  if (this.state.data?.tax_details?.additional_charges) {
             this.state.data.tax_details.additional_charges.forEach((item) => {
                 // Convert the additional_charge_value to a float using parseFloat()
                 const chargeValue = parseFloat(item?.additional_charge_value);
 
                 // Check if the parsed value is a valid number
                 if (!isNaN(chargeValue)) {
                     additionalCharges += chargeValue;
                 }
             });
         } */

    // Convert all variables to numbers and handle NaN values
    subTotal = Number(subTotal) || 0;
    applicationFee = Number(applicationFee) || 0;
    merchantFee = Number(merchantFee) || 0;
    totalSplitPayment = Number(totalSplitPayment) || 0;
    discount = Number(discount) || 0;
    refund = Number(refund) || 0;
    tax = Number(tax) || 0;
    let total = 0;

    if (this.state.data.payment_mode === "card") {
      total = Grandtotal - merchantFee - totalSplitPayment - refund;
    } else {
      total = Grandtotal - totalSplitPayment - refund;
    }

    return total.toFixed(2); // Convert the calculated total to a string with 2 decimal places
  };

  calculateGrandTotal = () => {
    let subTotal = this.state.data?.order_sub_total;
    let discount = this.state.data?.discounted_amount;
    let serviceTax = this.state.data?.tax_details?.service_tax_on_order;
    let tax = this.state.data?.tax_details?.tax_on_order;
    let applicationFee = this.state.data?.tax_details?.application_fee_amount;
    let tip = this.state.data?.tax_details?.tip_amount || 0;
    let merchantFee = this.state.data?.tax_details?.merchant_fee_amount;
    let additionalCharges = 0;

    if (this.state.data?.tax_details?.additional_charges) {
      this.state.data.tax_details.additional_charges.forEach((item) => {
        // Convert the additional_charge_value to a float using parseFloat()
        const chargeValue = parseFloat(item?.additional_charge_value);

        // Check if the parsed value is a valid number
        if (!isNaN(chargeValue)) {
          additionalCharges += chargeValue;
        }
      });
    }
    let orderVatCharges = 0;
    if (this.state.data?.tax_details?.order_vat_details) {
      this.state.data.tax_details.order_vat_details.forEach((item) => {
        // Convert the additional_charge_value to a float using parseFloat()
        const chargeValue = parseFloat(item?.tax_amount);

        // Check if the parsed value is a valid number
        if (!isNaN(chargeValue)) {
          orderVatCharges += chargeValue;
        }
      });
    }

    // Convert all variables to numbers and handle NaN values
    subTotal = Number(subTotal) || 0;
    discount = Number(discount) || 0;
    serviceTax = Number(serviceTax) || 0;
    applicationFee = Number(applicationFee) || 0;
    merchantFee = Number(merchantFee) || 0;
    tax = Number(tax) || 0;

    let total = 0;

    total =
      subTotal -
      discount +
      applicationFee +
      additionalCharges +
      orderVatCharges +
      tip +
      serviceTax;

    return total.toFixed(2); // Convert the calculated total to a string with 2 decimal places
  };
  render() {
    const { classes } = this.props;
    const { deliveryStatusHistory } = this.state;
    const steps = [
      {
        icon: <FastfoodIcon />,
        label: "Order accepted date",
        content: this.state?.data?.order_accepted_date,
      },
      {
        icon: <HourglassBottomIcon />,
        label: "Duration",
        content: this.state?.data?.order_time_duration,
      },
      {
        icon: <HandshakeIcon />,
        label: "Handover",
        content: this.state?.data?.handover_time,
      },
    ];

    const customStyles = {
      rows: {
        style: {
          minHeight: "50px", // override the row height
        },
      },
      headCells: {
        style: {
          minHeight: "65px",
          textAlign: "center",
          backgroundColor: "#5773ff",
          fontSize: "14px",
          color: "white",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
          pointer: "cursor",
        },
      },
    };

    const data = [
      {
        name: "ITEM NAME",
        selector: "item_name",
        sortable: true,
        cell: (row) => (
          <div style={{ fontWeight: "bold" }}>{row.item_name}</div>
        ),
      },
      {
        name: "QUANTITY",
        selector: "quantity",
        sortable: true,
        className: "lft-padding",
        cell: (row) => <div className="lft-padding-30">{row.quantity}</div>,
      },
      {
        name: "SUB TOTAL",
        selector: "sub_total",
        sortable: true,
        cell: (row) => (
          <div className="lft-padding-35">
            {" "}
            {getCurrencySymbol(localStorage.getItem("currency_code")) +
              row.sub_total.toFixed(2)}
          </div>
        ),
      },
      /*  {
                 name: "STATUS",
                 selector: "status",
                 sortable: true,
                 cell: (row) => <div className="lft-padding-25">{row.status}</div>,
             }, */
      {
        name: "OPTIONS",
        width: "auto",

        cell: (row) => (
          <div className="lft-padding-25">
            {/* <Accordion defaultActiveKey="1"> */}
            {/*    <Card>
                                <Accordion.Collapse className="collapseparent" eventKey="0">
                                    <Card.Body>
                                        <div className="table-responsive">
                                            <table className="table table-bordered thead-primary">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Identifier</th>
                                                        <th scope="col">Quantity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.addons
                                                        ? row.addons
                                                            .filter(function (data) {
                                                                return data.addon_type === "OPTIONS";
                                                            })
                                                            .map((item, i) => (
                                                                <tr key={i}>
                                                                    <th scope="row">{i + 1}</th>
                                                                    <td>{item.addon_identifier}</td>
                                                                    <td>{item.addon_quantity}</td>
                                                                </tr>
                                                            ))
                                                        : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                                <Accordion.Toggle
                                    as={Card.Header}
                                    className="wid-100"
                                    eventKey="0"
                                >
                                    <span> View </span>
                                   
                                </Accordion.Toggle>
                            </Card> */}
            <Tab addons={row} type="OPTIONS" />
            {/* </Accordion> */}
          </div>
        ),
      },
      {
        name: "EXTRAS",
        width: "auto",

        cell: (row) => (
          <div className="lft-padding-25">
            {/*          <Accordion defaultActiveKey="1">
                            <Card>
                                <Accordion.Collapse className="collapseparent" eventKey="0">
                                    <Card.Body>
                                        <div className="table-responsive">
                                            <table className="table table-bordered thead-primary">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Identifier</th>
                                                        <th scope="col">Quantity</th>
                                                        <th scope="col">Sub Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.addons
                                                        ? row.addons
                                                            .filter(function (data) {
                                                                return data.addon_type === "EXTRAS";
                                                            })
                                                            .map((item, i) => (
                                                                <tr key={i}>
                                                                    <th scope="row">{i + 1}</th>
                                                                    <td>{item.addon_identifier}</td>
                                                                    <td>{item.addon_quantity}</td>
                                                                    <td>{item.sub_total}</td>
                                                                </tr>
                                                            ))
                                                        : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                                <Accordion.Toggle
                                    as={Card.Header}
                                    className="wid-100"
                                    eventKey="0"
                                >
                                    <span> View </span>
                                  
                                </Accordion.Toggle>
                            </Card>
                        </Accordion> */}
            <Tab addons={row} type="EXTRAS" />
          </div>
        ),
      },
    ];

    const suborders_data = [
      {
        name: "ITEM NAME",
        selector: "item_name",
        sortable: true,
        cell: (row) => (
          <div style={{ fontWeight: "bold" }}>{row.item_name}</div>
        ),
      },
      {
        name: "STATUS",
        selector: "status",
        sortable: true,
        className: "lft-padding",
        cell: (row) => <div className="lft-padding-30">{row.status}</div>,
      },
      {
        name: "QUANTITY",
        selector: "quantity",
        sortable: true,
        className: "lft-padding",
        cell: (row) => <div className="lft-padding-30">{row.status}</div>,
      },
      {
        name: "SUB TOTAL",
        selector: "sub_total",
        sortable: true,
        className: "lft-padding",
        cell: (row) => <div className="lft-padding-30">{row.status}</div>,
      },
      {
        name: "OPTIONS",
        sortable: true,
        width: "auto",
        cell: (row) => (
          <div>
            <Accordion defaultActiveKey="1">
              <Card>
                <Accordion.Collapse className="collapseparent" eventKey="0">
                  <Card.Body>
                    <div className="table-responsive">
                      <table className="table table-bordered thead-primary">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Identifier</th>
                            <th scope="col">Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {row.addons
                            ? row.addons
                                .filter(function (data) {
                                  return data.addon_type === "OPTIONS";
                                })
                                .map((item, i) => (
                                  <tr key={i}>
                                    <th scope="row">{i + 1}</th>
                                    <td>{item.addon_identifier}</td>
                                    <td>{item.addon_quantity}</td>
                                  </tr>
                                ))
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle
                  as={Card.Header}
                  className="wid-100"
                  eventKey="0"
                >
                  <span> View </span>
                </Accordion.Toggle>
              </Card>
            </Accordion>
          </div>
        ),
      },
      {
        name: "EXTRAS",
        sortable: true,
        width: "auto",
        cell: (row) => (
          <div>
            <Accordion defaultActiveKey="1">
              <Card>
                <Accordion.Collapse className="collapseparent" eventKey="0">
                  <Card.Body>
                    <div className="table-responsive">
                      <table className="table table-bordered thead-primary">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Identifier</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Sub Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {row.addons
                            ? row.addons
                                .filter(function (data) {
                                  return data.addon_type === "EXTRAS";
                                })
                                .map((item, i) => (
                                  <tr key={i}>
                                    <th scope="row">{i + 1}</th>
                                    <td>{item.addon_identifier}</td>
                                    <td>{item.addon_quantity}</td>
                                    <td>{item.sub_total}</td>
                                  </tr>
                                ))
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle
                  as={Card.Header}
                  className="wid-100"
                  eventKey="0"
                >
                  <span> View </span>
                </Accordion.Toggle>
              </Card>
            </Accordion>
          </div>
        ),
      },
    ];

    const ExpandableComponent = ({ data }) => (
      <React.Fragment>
        {" "}
        <Tab data={data} />{" "}
      </React.Fragment>
    );
    const deliveryStatuses = {
      created: "Created",
      not_assigned_driver: "Not Assigned Driver",
      assigned_driver: "Assigned Driver",
      pickup_enroute: "Pickup Enroute",
      pickup_arrived: "Pickup Arrived",
      pickup_complete: "Pickup Complete",
      dropoff_enroute: "Dropoff Enroute",
      dropoff_arrived: "Dropoff Arrived",
      dropoff_complete: "Dropoff Complete",
    };
    const cancelledDeliveryStatuses = {
      created: "Created",
      canceled_by_customer: "Cancelled By Customer",
    };
    const rejectedDeliveryStatuses = {
      created: "Created",
      order_rejected: "Cancelled By Store",
    };
    const isElementCheckedRejected = (statusList, index) => {
      if (!statusList) return false;

      let lastStatus = statusList[statusList.length - 1];
      let lastStatusIndex = Object.keys(rejectedDeliveryStatuses).findIndex(
        (el) => el === lastStatus.status
      );

      if (lastStatusIndex >= index) {
        return true;
      }
      return false;
    };
    const isElementCheckedCancelled = (statusList, index) => {
      if (!statusList) return false;

      let lastStatus = statusList[statusList.length - 1];
      let lastStatusIndex = Object.keys(cancelledDeliveryStatuses).findIndex(
        (el) => el === lastStatus.status
      );

      if (lastStatusIndex >= index) {
        return true;
      }
      return false;
    };

    const isElementChecked = (statusList, index) => {
      if (!statusList) return false;

      let lastStatus = statusList[statusList.length - 1];
      let lastStatusIndex = Object.keys(deliveryStatuses).findIndex(
        (el) => el === lastStatus.status
      );

      if (lastStatusIndex >= index) {
        return true;
      }
      return false;
    };
    const getDateField = (key) => {
      for (let i = 0; i < deliveryStatusHistory.length; i++) {
        if (deliveryStatusHistory[i].status === key) {
          const createdAt = new Date(deliveryStatusHistory[i].createdAt);
          const formattedDate = createdAt.toLocaleDateString();
          const formattedTime = createdAt.toLocaleTimeString();
          return `${formattedDate} ${formattedTime}`;
        }
      }
      return null;
    };
    return (
      <React.Fragment>
        <Breadcrumb />
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="ms-panel">
            <div className="ms-panel-body">
              <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Refund full or partial order?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Do you really want to proceed?
                    <br /> <br /> <br />
                  </DialogContentText>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Refund Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.refundType}
                      label="Refund Type"
                      onChange={(e) => {
                        this.setState({ refundType: e.target.value });
                      }}
                    >
                      <MenuItem value={"partial"}>Partial Refund.</MenuItem>
                      <MenuItem value={"full"}>Full Refund.</MenuItem>
                    </Select>
                  </FormControl>
                  <br /> <br />
                  {this.state.refundType == "partial" && (
                    <FormControl fullWidth>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="amount"
                        label="Refund"
                        type="number"
                        value={this.state.refundAmount}
                        onChange={(e) => {
                          let grandTotal = this.calculateGrandTotal();
                          if (Number(e.target.value) <= Number(grandTotal)) {
                            this.setState({ refundAmount: e.target.value });
                          } else {
                            this.setState({ refundAmount: grandTotal });
                            toast.warn(
                              "Amount should be less than grand total. "
                            );
                          }
                        }}
                        fullWidth
                        inputProps={{ min: 0, max: this.calculateGrandTotal() }}
                        variant="standard"
                      />
                    </FormControl>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose}>No</Button>
                  <Button onClick={() => this.updateOrderStatus()}>Yes</Button>
                </DialogActions>
              </Dialog>
              {/* )} */}

              {/* Google Map & Table */}
              <div className="row mr-1 ml-1 123">
                {/* Google Maps */}
                {/*   <div className="col-xl-6 col-md-12">
                                {!this.state.loadMap ? null : (
                                    <MapLoader
                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAbquQkju85Ho0VUlMI_TgWP6hUQlzZ7UI"
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        mapData={this.state.map_data}
                                    />
                                )}
                            </div> */}
                <div className="col-xl-12 col-md-12">
                  <Dialog
                    open={this.state.openPreAuthCancel}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        minWidth: "350px", // Set your desired width
                      },
                    }}
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Cancel transaction ?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Do you really want to proceed?
                        <br />
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleCancelTrasactionClose}>
                        No
                      </Button>
                      <Button onClick={() => this.cancelTransaction()}>
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    open={this.state.openPreAuth}
                    onClose={this.handleClosePreAuth}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        minWidth: "350px",
                      },
                    }}
                  >
                    <DialogTitle id="alert-dialog-title">
                      Capture Amount
                    </DialogTitle>
                    <DialogContent>
                      <FormControl fullWidth>
                        <br />
                        <TextField
                          autoFocus
                          margin="dense"
                          id="updated_amount"
                          label="Amount"
                          type="number"
                          value={this.state.amount || ""}
                          inputProps={{
                            min: 1,
                            max: this.state.data.order_sub_total,
                          }}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              /^(\d*\.?\d{0,6}|\.\d{1,6})$/.test(e.target.value)
                            ) {
                              this.setState({ amount: e.target.value });
                            } else {
                              this.setState({ amount: "" });
                            }
                          }}
                          error={
                            this.state.amount &&
                            this.state.amount > this.state.data.order_sub_total
                          }
                          helperText={`Capture Amount should be less or equal to ${this.state.data?.order_sub_total?.toFixed(
                            2
                          )} `}
                          fullWidth
                          variant="outlined"
                        />
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClosePreAuth} color="primary">
                        No
                      </Button>
                      <Button
                        onClick={this.captureOrUpdate}
                        color="primary"
                        variant="contained"
                      >
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {(this.state.data?.order_payment_status === "PAID" ||
                    this.state.data?.order_payment_status ===
                      "REFUND FAILED") &&
                    this.state.data?.payment_mode == "card" && (
                      <React.Fragment>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{ float: "right", marginBottom: "10px" }}
                            color="primary"
                            onClick={this.handleClickOpen}
                          >
                            REFUND
                          </Button>
                        </div>
                      </React.Fragment>
                    )}
                  {(this.state.data?.order_payment_status ===
                    "PAYMENT AUTHORISED" ||
                    this.state.data?.order_payment_status ===
                      "CAPTURE FAILED") &&
                    this.state.data?.order_type === "PAY BY LINK" && (
                      <React.Fragment>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          &nbsp; &nbsp;
                          <ButtonGroup
                            variant="outlined"
                            aria-label="outlined button group"
                          >
                            <Button
                              style={{ float: "right" }}
                              color="primary"
                              onClick={() => {
                                this.handlePreauthOpen("Capture");
                              }}
                            >
                              Capture
                            </Button>

                            <Button
                              style={{ float: "right" }}
                              color="primary"
                              onClick={() => {
                                this.handlePreAuthCancel();
                              }}
                            >
                              Cancel
                            </Button>
                          </ButtonGroup>
                        </div>
                      </React.Fragment>
                    )}

                  {this.state.data?.payment_mode === "card" &&
                    (this.state.data?.order_payment_status ===
                      "PAYMENT REFUNDED" ||
                      this.state.data?.order_payment_status ===
                        "PAYMENT PARTIAL REFUNDED") && (
                      <React.Fragment>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{ float: "right", marginBottom: "10px" }}
                            color="primary"
                            onClick={this.refundSlip}
                          >
                            Download Slip
                          </Button>
                        </div>
                      </React.Fragment>
                    )}
                </div>
                <br /> <br />
                {/* Order Table */}
                <div className="col-xl-6 col-md-6">
                  <div className="ms-invoice-table table-responsive">
                    <div className="table-wrapper">
                      <table className="table table-hover table-bordered">
                        <tbody>
                          <tr>
                            <th>MULTI VENDOR NAME </th>
                            <td>
                              {this.state.data?.parent_property_trading_name
                                ? this.state.data?.parent_property_trading_name
                                : ""}
                            </td>
                          </tr>
                          {!(
                            this.state.data.order_type === "PAY BY LINK" ||
                            this.state.data.order_type === "TERMINAL"
                          ) ? (
                            <tr>
                              <th>NAME </th>
                              <td>
                                {this.state.data.customer_name
                                  ? this.state.data.customer_name
                                  : ""}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {this.state.data.order_type === "PAY BY LINK" &&
                          this.state.data.is_pay_by_link_pre_auth ? (
                            <tr>
                              <th>AUTHORISED AMOUNT</th>
                              <td>
                                {this.state.data?.authorised_amount?.toFixed(2)}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                          <tr>
                            <th>ORDER #</th>
                            <td>
                              {this.state.data.order_number
                                ? this.state.data.order_number
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <th>ORDER SOURCE</th>
                            <td>
                              {this.state.data.order_source
                                ? this.state.data.order_source
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <th>DATE & TIME</th>
                            <td>
                              {this.state.data.order_date_time
                                ? this.state.data.order_date_time
                                : ""}
                            </td>
                          </tr>
                          {this.state.data?.delivery_address && (
                            <tr>
                              <th>DELIVERY ADDRESS</th>
                              <td>{this.state.data?.delivery_address}</td>
                            </tr>
                          )}
                          <tr>
                            <th>CONTACT NUMBER</th>
                            <td>{this.state.data?.contact_number}</td>
                          </tr>
                          {this.state.data?.delivery_charge_detail
                            ?.delivery_charge > 0 && (
                            <tr>
                              <th>DELIVERY CHARGE</th>
                              <td>
                                {getCurrencySymbol(
                                  localStorage.getItem("currency_code")
                                )}{" "}
                                {this.state.data?.delivery_charge_detail?.delivery_charge.toFixed(
                                  2
                                )}
                              </td>
                            </tr>
                          )}
                          {!(
                            this.state.data.order_type === "PAY BY LINK" ||
                            this.state.data.order_type === "TERMINAL"
                          ) ? (
                            <tr>
                              <th>TOTAL ITEMS</th>
                              <td>{this.state.total_elements}</td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {!(
                            this.state.data.order_type === "PAY BY LINK" ||
                            this.state.data.order_type === "TERMINAL"
                          ) ? (
                            <tr>
                              <th>STATUS</th>
                              <td>
                                {this.state.item_status
                                  ? this.state.item_status
                                  : ""}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {this.state.data?.payment_mode === "split" ? (
                            
                              <th colSpan="2" style={{padding:"0px"}}>
                              <Accordian data={this.state.data}/>
                              </th>
                            
                          ) : (
                            <tr>
                              <th>PAYMENT MODE</th>
                              <td>
                                {this.state.data?.payment_mode?.toUpperCase()}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th>PAYMENT STATUS</th>
                            <td>{this.state.data?.order_payment_status}</td>
                          </tr>
                          {!(
                            this.state.data.order_type === "PAY BY LINK" ||
                            this.state.data.order_type === "TERMINAL"
                          ) ? (
                            <tr>
                              <th>TIME SLOT</th>
                              <td>{this.state.data?.order_slot_time}</td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {this.state.data.order_type === "PAY BY LINK" ? (
                            <tr>
                              <th>NOTES</th>
                              <td>{this.state.data?.notes}</td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {this.state.data.order_type === "PAY BY LINK" ? (
                            <tr>
                              <th>DESCRIPTION</th>
                              <td>
                                {this.state.data?.pay_by_link_description}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {((this.state.data.is_pay_by_link_pre_auth &&
                            this.state.data.order_type === "PAY BY LINK") ||
                            (this.state.data.is_terminal_pre_auth &&
                              this.state.data.order_type === "TERMINAL")) &&
                          (this.state.data?.order_payment_status === "PAID" ||
                            this.state.data?.order_payment_status ===
                              "PAYMENT PARTIAL REFUNDED" ||
                            this.state.order_payment_status ===
                              "PAYMENT REFUNDED") ? (
                            <tr>
                              <th>CAPTURED AMOUNT</th>
                              <td>
                                {this.state.data?.order_sub_total?.toFixed(2)}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {!(
                            this.state.data.order_type === "PAY BY LINK" ||
                            this.state.data.order_type === "TERMINAL"
                          ) ? (
                            <tr>
                              <th>RATING</th>
                              <td sx={{ width: 100 }}>
                                {this.state?.order?.order_rating == 1 && (
                                  <img src={angry} />
                                )}
                                {this.state?.order?.order_rating == 3 && (
                                  <img src={normal} />
                                )}
                                {this.state?.order?.order_rating == 5 && (
                                  <img src={happy} />
                                )}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {/*    <tr>
                                                    <th>OWN DELIVERY</th>
                                                    <td>
                                                        <div className="form-group">
                                                            <label className="ms-switch">
                                                                <input type="checkbox" name="is_own_delivery" onChange={() => this.toggleHandler('is_own_delivery')} checked={this.state.is_own_delivery} />
                                                                <span className="ms-switch-slider round" />
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr> */}

                          {/* Google Map */}
                          {!this.state.loadMap ? null : (
                            <Fragment>
                              {this.state.selecttag ? null : (
                                <tr>
                                  <th>DELIVERY AGENT</th>
                                  <td>{this.state.delivery_agent}</td>
                                </tr>
                              )}
                              {this.state.map_data.agent_assigned ? (
                                <Fragment>
                                  <tr>
                                    <th>COLLECTION ADDRESS</th>
                                    <td>
                                      {this.state.data.business_place_address}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>DELIVERY ADDRESS</th>
                                    <td className="break-space">
                                      {this.state.data.delivery_address}
                                    </td>
                                  </tr>
                                </Fragment>
                              ) : null}
                            </Fragment>
                          )}
                        </tbody>
                        <tbody
                          data-bind-show="showRetriedPayouts"
                          className="hide"
                        ></tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6">
                  <table className="table">
                    <tbody>
                      <tr>
                        {" "}
                        <th>SUB TOTAL: </th>
                        <td>
                          {" "}
                          {getCurrencySymbol(
                            localStorage.getItem("currency_code")
                          )}{" "}
                          {this.state.data?.order_item_sub_total?.toFixed(2)}
                        </td>
                      </tr>
                      {this.state.data?.vat_list &&(<tr>
                        {" "}
                        <th>ITEM VAT </th>
                        <td>
                          {getCurrencySymbol(
                            localStorage.getItem("currency_code")
                          )}{" "}
                          {this.state.data?.tax_details?.tax_on_order.toFixed(2)}
                        </td>
                      </tr>)}
                      <tr>
                        {" "}
                        <th>DISCOUNT </th>
                        <td>
                          {getCurrencySymbol(
                            localStorage.getItem("currency_code")
                          )}{" "}
                          {this.state.data?.discounted_amount?.toFixed(2) ||
                            Number(0).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        {" "}
                        <th>NEW TOTAL </th>
                        <td>
                          {getCurrencySymbol(
                            localStorage.getItem("currency_code")
                          )}{" "}
                          {(
                            parseFloat(this.state.data?.order_sub_total || 0) -
                            parseFloat(this.state.data?.discounted_amount || 0)
                          ).toFixed(2)}
                        </td>
                      </tr>

                      <tr>
                        {" "}
                        <th>TIP AMOUNT </th>
                        <td>
                          {getCurrencySymbol(
                            localStorage.getItem("currency_code")
                          )}{" "}
                          {this.state.data?.tax_details?.tip_amount?.toFixed(
                            2
                          ) || Number(0).toFixed(2)}
                        </td>
                      </tr>
                      {this.state.data?.tax_details?.service_tax_on_order > 0  && (
                        <tr>
                          {" "}
                          <th>SERVICE CHARGE: </th>
                          <td>
                            {getCurrencySymbol(
                              localStorage.getItem("currency_code")
                            )}{" "}
                            {this.state.data?.tax_details?.service_tax_on_order?.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                      )}
                      {/* <tr>
                        {" "}
                        <th>TAX: </th>
                        <td>
                          {getCurrencySymbol(
                            localStorage.getItem("currency_code")
                          )}{" "}
                          {this.state.data?.tax_details?.tax_on_order?.toFixed(
                            2
                          ) || Number(0).toFixed(2)}
                        </td>
                      </tr> */}
                      {this.state.data.payment_mode != "cash" && (
                        <tr>
                          {" "}
                          <th>APPLICATION FEE: </th>
                          <td>
                            {getCurrencySymbol(
                              localStorage.getItem("currency_code")
                            )}{" "}
                            {this.state.data?.tax_details?.application_fee_amount?.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                      )}

                      {this.state.data?.tax_details?.additional_charges
                        ?.length > 0 && (
                        <React.Fragment>
                          {this.state.data?.tax_details?.additional_charges && (
                            <tr className="text-center">
                              <th coslpas="1">Additional Charges </th>
                            </tr>
                          )}
                          {this.state.data?.tax_details?.additional_charges &&
                            this.state.data?.tax_details?.additional_charges.map(
                              (item) => (
                                <tr>
                                  <td>{item?.additional_charge_name}</td>
                                  <td>
                                    {getCurrencySymbol(
                                      localStorage.getItem("currency_code")
                                    )}
                                    {item?.additional_charge_value}
                                  </td>
                                </tr>
                              )
                            )}
                        </React.Fragment>
                      )}
                       {this.state.data?.tax_details?.order_vat_details
                        ?.length > 0 && (
                        <React.Fragment>
                          {this.state.data?.tax_details?.order_vat_details && (
                            <tr className="text-center">
                              <th coslpas="1">Order VAT details </th>
                            </tr>
                          )}
                          {this.state.data?.tax_details?.order_vat_details &&
                            this.state.data?.tax_details?.order_vat_details.map(
                              (item) => (
                                <tr>
                                  <td>{item?.description}{" "}({item?.tax_percentage}%)</td>
                                  <td>
                                    {getCurrencySymbol(
                                      localStorage.getItem("currency_code")
                                    )}
                                    {item?.tax_amount.toFixed(2)}
                                  </td>
                                </tr>
                              )
                            )}
                        </React.Fragment>
                      )}
                      <tr>
                        {" "}
                        <th>GRAND TOTAL: </th>
                        <td>
                          {" "}
                          {getCurrencySymbol(
                            localStorage.getItem("currency_code")
                          )}{" "}
                          {this.calculateGrandTotal()}
                        </td>
                      </tr>
                      {this.state.data.payment_mode != "cash" && (
                        <React.Fragment>
                          {this.state.data?.split_property_fee_map && (
                            <tr className="text-center">
                              {" "}
                              <th coslpas="2">Split Payment </th>
                            </tr>
                          )}
                          {this.state.data?.split_property_fee_map &&
                            Object.entries(
                              this.state.data.split_property_fee_map
                            ).map(([key, value]) => (
                              <tr key={key}>
                                <td>{key}</td>
                                <td>
                                  {" "}
                                  {getCurrencySymbol(
                                    localStorage.getItem("currency_code")
                                  )}{" "}
                                  {value?.toFixed(2)}
                                </td>
                              </tr>
                            ))}
                        </React.Fragment>
                      )}

                      {this.state.data.payment_mode === "card" && (
                        <tr>
                          {" "}
                          <th>MERCHANT FEE: </th>
                          <td>
                            {getCurrencySymbol(
                              localStorage.getItem("currency_code")
                            )}{" "}
                            {this.state.data?.tax_details?.merchant_fee_amount?.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                      )}

                      <tr>
                        {" "}
                        <th>REFUND </th>
                        <td>
                          {this.state.data?.order_payment_status !==
                            "PAYMENT REFUNDED" &&
                          this.state.data?.order_payment_status !==
                            "PAYMENT REFUND INITIATED"
                            ? getCurrencySymbol(
                                localStorage.getItem("currency_code")
                              ) +
                              (this.state.data?.partial_refund_amount?.toFixed(
                                2
                              ) || Number(0).toFixed(2))
                            : getCurrencySymbol(
                                localStorage.getItem("currency_code")
                              ) +
                                (
                                  Number(this.calculateGrandTotal()) -
                                  Number(
                                    this.state.data?.discounted_amount ||
                                      Number(0)
                                  )
                                )?.toFixed(2) || Number(0).toFixed(2)}{" "}
                        </td>
                      </tr>

                      <tr>
                        <th>NET AMOUNT</th>
                        <td>
                          {getCurrencySymbol(
                            localStorage.getItem("currency_code")
                          )}{" "}
                          {this.state.data?.order_payment_status !==
                            "PAYMENT REFUNDED" &&
                          this.state.data?.order_payment_status !==
                            "PAYMENT REFUND INITIATED"
                            ? this.calculateNetAmount()
                            : Number(0).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Custom Stepper */}
              {/*                         <div className="ms-invoice-table table-responsive mt-4">
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body">

                                        <CustomStepper
                                            order={this.props.history.location.state.order}
                                            order_status={this.state.order_status}
                                            fetchOrderDetailsByOrderId={
                                                this.fetchOrderDetailsByOrderId
                                            }
                                        />

                                    </div>
                                </div>
                            </div>
                        </div> */}

              {this.state.selectDeliveryAgent ? (
                <div className="row" style={{ margin: "0 2px" }}>
                  <div className="col-md-12">
                    <div className="ms-panel ms-panel-fh">
                      <div className="ms-panel-body">
                        <Accordion
                          defaultActiveKey="0"
                          className="has-gap ms-accordion-chevron"
                        >
                          <Card>
                            <Accordion.Collapse
                              className="collapseparent"
                              eventKey="0"
                            >
                              <Card.Body>
                                <div className="table-wrapper">
                                  <table className="table table-hover table-bordered">
                                    <tbody>
                                      <tr>
                                        <th>Name </th>
                                        <td>
                                          {
                                            this.state
                                              .allocate_delivery_agent_detail
                                              .name
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Email</th>
                                        <td>
                                          {
                                            this.state
                                              .allocate_delivery_agent_detail
                                              .email
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Phone Number</th>
                                        <td>
                                          {
                                            this.state
                                              .allocate_delivery_agent_detail
                                              .dial_code
                                          }
                                          {
                                            this.state
                                              .allocate_delivery_agent_detail
                                              .phone_number
                                          }
                                        </td>
                                      </tr>
                                      {/* <tr>
                                      <th></th>
                                      <td>
                                        <button
                                          onClick={() =>
                                            this.assignDeliveryAgent()
                                          }
                                          className="btn btn-primary d-block float-right"
                                        >
                                          Assign
                                        </button>
                                      </td>
                                    </tr> */}
                                    </tbody>
                                  </table>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                              <span className="has-icon">
                                {" "}
                                <i
                                  style={{ marginTop: "5px" }}
                                  class="fas fa-building"
                                />{" "}
                                Assign Delivery Agent{" "}
                              </span>
                            </Accordion.Toggle>
                          </Card>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {!(
                this.state.data.order_type === "PAY BY LINK" ||
                this.state.data.order_type === "TERMINAL"
              ) ? (
                <>
                  <div className="col-md-10">
                    <br></br>
                    <br></br>
                    <Typography variant="h6" gutterBottom>
                      <div style={{ margin: "10px" }}>Timeline</div>
                    </Typography>
                    <Divider style={{ margin: "20px" }} />
                    {/*      <Timeline align="left" position="alternate" >
                                    <TimelineItem  >
                                        <TimelineOppositeContent
                                            sx={{ m: 'auto 0' }}
                                            align="right"
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            {this.state?.data?.order_accepted_date}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FastfoodIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ marginTop: "10px" }} >
                                            <Typography variant="h6" component="span">
                                                Order accepted date.
                                            </Typography>

                                        </TimelineContent>
                                    </TimelineItem>
                                    <TimelineItem  >
                                        <TimelineOppositeContent
                                            sx={{ m: 'auto 0' }}
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            {this.state?.data?.order_time_duration}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot color="primary">
                                                < HourglassBottomIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ marginTop: "10px" }} >
                                            <Typography variant="h6" component="span">
                                                Duration
                                            </Typography>

                                        </TimelineContent>
                                    </TimelineItem>
                                    <TimelineItem >
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot color="primary" variant="outlined">
                                                <HandshakeIcon />
                                            </TimelineDot>
                                            <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }} >
                                            <Typography variant="h6" component="span">
                                                Handover
                                            </Typography>
                                            <Typography>{this.state?.data?.handover_date}</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
 */}
                    <Stepper
                      alternativeLabel
                      activeStep={0}
                      orientation="horizontal"
                    >
                      {steps.map((step, index) => (
                        <Step key={index}>
                          <StepLabel icon={step.icon}>
                            <Typography variant="h6">{step.label}</Typography>
                            <Typography variant="body2">
                              {step.content}
                            </Typography>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                  {this.state.data?.order_type === "DELIVERY" &&
                  deliveryStatusHistory ? (
                    <div className="col-md-12">
                      <br></br>
                      <br></br>
                      <Typography variant="h6" gutterBottom>
                        <div style={{ margin: "10px" }}>
                          Delivery Status Details
                        </div>
                      </Typography>
                      <Divider />
                      <div style={{ overflowX: "auto", margin: "20px" }}>
                        {!(
                          this.state.item_status === "ORDER REJECTED" ||
                          this.state.item_status === "CANCELLED"
                        ) && (
                          <Stepper
                            activeStep={
                              deliveryStatusHistory.filter((status, index) =>
                                isElementChecked(deliveryStatusHistory, index)
                              ).length
                            }
                            alternativeLabel
                          >
                            {Object.entries(deliveryStatuses).map(
                              ([key, value], index) => (
                                <Step key={index}>
                                  <StepLabel>
                                    {value}
                                    <br />
                                    {getDateField(key)}
                                  </StepLabel>
                                </Step>
                              )
                            )}
                          </Stepper>
                        )}
                        {this.state.item_status === "CANCELLED" && (
                          <Stepper
                            activeStep={
                              deliveryStatusHistory.filter((status, index) =>
                                isElementCheckedCancelled(
                                  deliveryStatusHistory,
                                  index
                                )
                              ).length
                            }
                            alternativeLabel
                          >
                            {Object.entries(cancelledDeliveryStatuses).map(
                              ([key, value], index) => (
                                <Step key={index}>
                                  <StepLabel>
                                    {value}
                                    <br />
                                    {getDateField(key)}
                                  </StepLabel>
                                </Step>
                              )
                            )}
                          </Stepper>
                        )}
                        {this.state.item_status === "ORDER REJECTED" && (
                          <Stepper
                            activeStep={
                              deliveryStatusHistory.filter((status, index) =>
                                isElementCheckedRejected(
                                  deliveryStatusHistory,
                                  index
                                )
                              ).length
                            }
                            alternativeLabel
                          >
                            {Object.entries(rejectedDeliveryStatuses).map(
                              ([key, value], index) => (
                                <Step key={index}>
                                  <StepLabel>
                                    {value}
                                    <br />
                                    {getDateField(key)}
                                  </StepLabel>
                                </Step>
                              )
                            )}
                          </Stepper>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.data.order_Details ? (
                    <div className="ms-invoice-table table-responsive mt-2">
                      <div className="col-xl-12 col-md-12">
                        <div className="ms-panel">
                          <div className="ms-panel-header">
                            <div className="d-flex justify-content-between">
                              <div className="align-self-center align-left">
                                <h6>
                                  Order No #{this.state.data.order_number}
                                </h6>
                              </div>
                              {/* <button type="button" className="btn btn-primary">View All</button> */}
                            </div>
                          </div>
                          <div className="ms-panel-body">
                            <div className="table-responsive">
                              <Fragment>
                                <DataTable
                                  customStyles={customStyles}
                                  columns={data}
                                  data={this.state.data.order_Details}
                                  /*  expandableRows
                                                             expandableRowsComponent={<ExpandableComponent data={this.state.data.order_Details} />} */
                                />
                                <div
                                  className="row"
                                  style={{ float: "right", margin: "25px" }}
                                >
                                  {this.state.total_pages > 0 && (
                                    <Pagination
                                      currentPage={this.state.current_page}
                                      total_pages={this.state.total_pages}
                                      number_of_elements={
                                        this.state.number_of_elements
                                      }
                                      handleChangeRowsPerPage={
                                        this.handleChangeRowsPerPage
                                      }
                                      handleChange={this.changeCurrentPage}
                                    />
                                  )}
                                </div>
                                <div style={{ float: "left", margin: "15px" }}>
                                  Total Records : {this.state.total_elements}
                                </div>
                              </Fragment>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {this.state.data.sub_orders ? (
                    <div className="ms-invoice-table table-responsive mt-2">
                      <div className="col-xl-12 col-md-12">
                        <div className="ms-panel">
                          <div className="ms-panel-header">
                            <div className="d-flex justify-content-between">
                              <div className="align-self-center align-left">
                                <h6>
                                  Sub Order No #
                                  {this.state.data.sub_orders[0].order_number}
                                </h6>
                              </div>
                              {/* <button type="button" className="btn btn-primary">View All</button> */}
                            </div>
                          </div>
                          <div className="ms-panel-body">
                            <div className="table-responsive">
                              <Fragment>
                                <DataTable
                                  customStyles={customStyles}
                                  columns={suborders_data}
                                  data={
                                    this.state.data.sub_orders[0].order_Details
                                  }
                                  expandableRows
                                  expandableRowsComponent={
                                    <ExpandableComponent />
                                  }
                                />
                                <div style={{ float: "left" }}>
                                  Total Records :{" "}
                                  {
                                    this.state.data.sub_orders[0].order_Details
                                      .length
                                  }
                                </div>
                              </Fragment>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                ""
              )}

              {/* Send Message */}
              <div className="row">
                <div className="col-md-12">
                  <button
                    className="btn btn-primary d-block float-right mr-3"
                    onClick={this.handleModal}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <CommonModal
          state={this.state}
          onHide={this.handleModal}
          Heading="NOTIFY USER"
          show={this.state.show}
          handleClose={this.handleModal}
        >
          <div>
            <div className="col-md-12 ">
              <div class="form-group row">
                <label for="exampleMessage">Message</label>

                <textarea
                  className="form-control col-md-12 row"
                  name="order_status"
                  value={this.state.order_status}
                  onChange={this.onChangeHandler}
                />
              </div>

              {this.state.errMessage && (
                <span className="alert alert-danger alert-outline">
                  {this.state.errMessage}
                </span>
              )}
            </div>

            <div className="col-md-12">
              <button
                className="btn btn-primary d-block float-right"
                onClick={this.pushOrderNotificationToUser}
              >
                Send Message
              </button>
            </div>
          </div>
        </CommonModal>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Detailcontent);
