import axios from "axios";
import * as APIUrl from "../../APIUrl";

export const fetchCategoryDetails = () => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_CATEGORY_DETAILS,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchItemFilters = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_ITEM_FILTERS + payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const addItemFilter = (payload) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.ADD_ITEM_FILTER,
      data: payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updateItemFilter = (payload) => {
  try {
    return axios({
      method: "put",
      url: APIUrl.UPDATE_ITEM_FILTER,
      data: payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};
