import axios from 'axios';
import * as APIUrl from '../APIUrl';

export const collectUserRequirement = (payload) => {

    try {
        return axios({
            method: 'post',  
            url: APIUrl.COLLECT_USER_INFO,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}


export const checkIfUserInfoIsCollected = (payload) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.CHECK_USER_INFO + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}





