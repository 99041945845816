import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import "./MenuModal.css";
import { FlapperSpinner } from "react-spinners-kit";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Schema from "../../../utils/Schema";
import MultipleImageCropper from "../../../shared/Cropper/MultipleImageCropper";

const MenuModal = (props) => {
  return (
    <Fragment>
      <Modal
        className="modal-min"
        show={props.state.show}
        onHide={props.handleModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">
          <h1> {props.title} </h1>
          <button type="button" className="close" onClick={props.handleModal}>
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <Modal.Body>
          {props.state.loading ? (
            <FlapperSpinner
              size={90}
              color="#233cad"
              loading={props.state.loading}
            />
          ) : (
            <Formik
              initialValues={{
                name: props.state.identifier,
                description: props.state.description,
                sort_order: props.state.sort_order,
                total_cost: props.state.total_cost,
                is_buffet: props.state.is_buffet,
              }}
              validateOnMount
              validationSchema={Schema.menuSchema}
              onSubmit={(values, errors, status, touched) => {
                // same shape as initial values
                props.onClickHandler(values);
              }}
            >
              {({ values, errors, status, touched }) => (
                <Form>
                  <div className="form-row">
                    <div className="col-md-12 ">
                      <div className="ms-card-img">
                        {props.state.imagesPreviewUrl ? (
                          <img src={props.state.imagesPreviewUrl} />
                        ) : null}

                        {/* <button className="btn btn-primary uploadImage menu_product_image"> Upload an image
                                                    <input className="upload-image-input"
                                                        accept=".jpeg,.jpg,.png,.svg"
                                                        type="file"
                                                        id="choose-profile"
                                                        name="file"
                                                        onChange={props.handleImageChange}
                                                    />
                                                </button> */}

                        {/* Cropper 1 */}
                        <MultipleImageCropper
                          data={{
                            key: "menu",
                            isMultiple: false,
                            getCroppedImages: props.handleImageChange,
                          }}
                          height={300}
                          width={300}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mt-4">
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <Field
                          name="name"
                          type="text"
                          placeholder="Name"
                          className="form-control"
                        />
                        {/* <ErrorMessage name="name" className="invalid-feedback" /> */}
                        {touched.name ? (
                          <div className="error-message">{errors.name}</div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="validationCustom02">Description</label>
                        <Field
                          name="description"
                          type="text"
                          placeholder="Description"
                          className="form-control"
                        />
                        {/* <ErrorMessage name="description" className="invalid-feedback" /> */}
                        {touched.description ? (
                          <div className="error-message">
                            {errors.description}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="validationCustom02">Sort Order</label>
                      <div className="input-group">
                        <Field
                          name="sort_order"
                          type="number"
                          min="0"
                          max="999999"
                          className="form-control"
                        />
                        {/* <ErrorMessage name="sort_order" className="invalid-feedback" /> */}
                        {touched.sort_order ? (
                          <div className="error-message">
                            {errors.sort_order}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="ms-panel-header new">
                        <p className="medium">Buffet</p>
                        <div className="d-block">
                          <label
                            className="ms-switch float-right"
                            style={{ bottom: "20px" }}
                          >
                            <input
                              type="checkbox"
                              name="is_buffet"
                              // disabled={props.state.update_menu}
                              onChange={() => props.toggleHandler("is_buffet")}
                              checked={props.state.is_buffet}
                            />
                            <span className="ms-switch-slider round" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {props.state.is_buffet ? (
                    <div className="col-md-12">
                      <label htmlFor="validationCustom02">Total Cost</label>
                      <div className="input-group">
                        <Field
                          name="total_cost"
                          type="number"
                          placeholder="0"
                          min="0"
                          max="999999"
                          className="form-control"
                        />
                        {/* <ErrorMessage name="total_cost" className="invalid-feedback" /> */}
                        {touched.total_cost ? (
                          <div className="error-message">
                            {errors.total_cost}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : null}

                  <div>
                    <button
                      className="btn btn-secondary d-block float-right"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default MenuModal;
