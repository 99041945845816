import axios from 'axios';
import * as APIUrl from '../APIUrl'

export const uploadFeaturedImages =(payload)=>{
    try{
        return axios({
            method: 'post',
            url: APIUrl.UPLOAD_FEATURED_IMAGE,
            data:payload,
        })
    }catch(error){
        throw new Error(error)
    }
}

export const fetchBusinessTiming =(property_timing)=>{
    try{
        return axios({
            method: 'get',
            url: APIUrl.FETCH_BUSINESS_TIMING+property_timing,
        })
    }catch(error){
        throw new Error(error)
    }
}

export const updatePropertyTiming=(payload)=>{
    console.log(payload)
    try{
        return axios({
            method: 'put',
            url:APIUrl.UPDATE_PROPERTY_TIMING,
            data:payload
        })
    }catch(error){
        throw new Error(error)
    }
}

export const updateDeliveryTiming=(payload)=>{
    console.log(payload)
    try{
        return axios({
            method: 'put',
            url:APIUrl.UPDATE_DELIVERY_TIMING,
            data:payload
        })
    }catch(error){
        throw new Error(error)
    }
}

export const updateTakeAwayTiming=(payload)=>{
    console.log(payload)
    try{
        return axios({
            method: 'put',
            url:APIUrl.UPDATE_TAKEAWAY_TIMING,
            data:payload
        })
    }catch(error){
        throw new Error(error)
    }
}

export const updateDineInTiming=(payload)=>{
    console.log(payload)

    try{
        return axios({
            method: 'put',
            url:APIUrl.UPDATE_DINE_IN_TIMING,
            data:payload
        })
    }catch(error){
        throw new Error(error)
    }
}

export const saveTip =(payload)=>{
    try{
        return axios({
            method: 'post',
            url: APIUrl.SAVE_TIP,
            data:payload,
        })
    }catch(error){
        throw new Error(error)
    }
}

export const saveScreenSaver =(payload)=>{
    try{
        return axios({
            method: 'post',
            url: APIUrl.SAVE_SCREENSAVER,
            data:payload,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }catch(error){
        throw new Error(error)
    }
}

export const deleteScreenSaver =(payload)=>{
    try{
        return axios({
            method: 'delete',
            url: APIUrl.DELTE_SCREENSAVER + `${payload.property_id}&image_or_video_url=${payload.image_or_video_url}`,
        })
    }catch(error){
        throw new Error(error)
    }
}


export const saveVat =(payload,username)=>{
    try{
        return axios({
            method: 'post',
            url: APIUrl.SAVE_VAT,
            data:payload,
            username:username,
        })
    }catch(error){
        throw new Error(error)
    }
}

export const fetchScreenSaverPlan = (payload) => {

    try {
        return axios({
            method: 'get',  
            url: APIUrl.FETCH_SCREENSAVER_PLAN + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchScreenSaverTerms = (payload) => {

    try {
        return axios({
            method: 'get',  
            url: APIUrl.FETCH_SCREENSAVER_TERMS+ `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const selectScreenSaverPlan = (payload) => {
    try {
        return axios({
            method: "post",
            url: APIUrl. INTIATE_SCREENSAVER_PAYMENT ,
            data: payload,
        });
    } catch (error) {
        throw new Error(error);
    }
};

export const applyDiscount = (payload) => {
    try {
        return axios({
            method: "post",
            url: APIUrl.APPLY_SCREENSAVER_DISCOUNT + `discount_code=${payload.discount_code}&integration_id=${payload.integration_id}`,
        });
    } catch (error) {
        throw new Error(error);
    }
};

export const removeDiscount = (payload) => {
    try {
        return axios({
            method: "post",
            url: APIUrl.REMOVE_SCREENSAVER_DISCOUNT+ `integration_id=${payload.integration_id}`,
        });
    } catch (error) {
        throw new Error(error);
    }
};

export const cancelScreenSaverPlan = (payload) => {
    try {
        return axios({
            method: 'post',  
            url: APIUrl.CANCEL_SCREENSAVER_PLAN + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}



