import axios from 'axios';
import * as APIUrl from '../APIUrl';


export const getItems = (payload) => {
    try {
        return axios({
            method: 'get',  
            url: APIUrl.FETCH_ALL_INVENTORY_STOCK_ITEMS  + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
};

export const getUnits = (payload) => {
    try {
        return axios({
            method: 'get',  
            url: APIUrl.FETCH_ALL_UNITS  + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
};

export const getItem = (id) => {
    try {
        return axios({
            method: 'get',  
            url: APIUrl.FETCH_ALL_INVENTORY_STOCK_ITEMS  + `${id}`,
        })
    } catch (error) {
        throw new Error(error)
    }
};

export const createItem = (item) => {
    try {
        return axios({
            method: 'post',  
            url: APIUrl.ADD_STOCK,
            data: item
        })
    } catch (error) {
        throw new Error(error)
    }
};

export const updateItem = (item) => {
    try {
        return axios({
            method: 'put',  
            url: APIUrl.UPDATE_STOCK,
            data: item
        })
    } catch (error) {
        throw new Error(error)
    }
};

export const deleteItem = (id) => {
    try {
        return axios({
            method: 'put',  
            url: APIUrl.DELETE_STOCK + `${id}`,
        })
    } catch (error) {
        throw new Error(error)
    }
};

export const fetchInventoryTerms = (payload) => {

    try {
        return axios({
            method: 'get',  
            url: APIUrl.FETCH_INVENTORY_TERMS + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const selectInventoryPlan = (payload) => {
    try {
        return axios({
            method: "post",
            url: APIUrl.INTIATE_INVENTORY_PAYMENT ,
            data: payload,
        });
    } catch (error) {
        throw new Error(error);
    }
};


export const fetchInventoryPlan = (payload) => {

    try {
        return axios({
            method: 'get',  
            url: APIUrl.FETCH_INVENOTRY_PLAN + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const cancelInventoryPlan = (payload) => {
    try {
        return axios({
            method: 'post',  
            url: APIUrl.CANCEL_INVENOTRY_PLAN + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const applyDiscount = (payload) => {
    try {
        return axios({
            method: "post",
            url: APIUrl.APPLY_INVENTORY_DISCOUNT + `discount_code=${payload.discount_code}&integration_id=${payload.integration_id}`,
        });
    } catch (error) {
        throw new Error(error);
    }
};
export const removeDiscount = (payload) => {
    try {
        return axios({
            method: "post",
            url: APIUrl.REMOVE_INVENTORY_DISCOUNT+ `integration_id=${payload.integration_id}`,
        });
    } catch (error) {
        throw new Error(error);
    }
};

export const fetchTags = (payload) => {

    try {
        return axios({
            method: 'get',  
            url: APIUrl.FETCH_TAGS +`${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}
export const deleteTag = (payload) => {

    try {
        return axios({
            method: 'put',  
            url: APIUrl.DELETE_TAG +`${payload.property_id}&tag_id=${payload.tag_id}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}
export const addTag = (payload) => {

    try {
        return axios({
            method: 'post',  
            url: APIUrl.ADD_TAG +`${payload.property_id}&tag_name=${payload.tag_name}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}
export const updateTag = (payload) => {

    try {
        return axios({
            method: 'put',  
            url: APIUrl.UPDATE_TAG +`${payload.property_id}&tag_name=${payload.tag_name}&tag_id=${payload.tag_id}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

