import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import OTP from "../../shared/Otp/OTP";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";

const ForgotPassword = (props) => {
  const [toggle_password, setVisible] = useState(false);
  const [errors, setErrors] = useState({});
  return (
    <Fragment>
      <Modal
        className="modal-min"
        show={props.state.show}
        onHide={props.handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="text-center">
          <button type="button" className="close" onClick={props.handleClose}>
            <span aria-hidden="true">×</span>
          </button>
          <i className="flaticon-secure-shield d-block" />
          <h1>Forgot Password?</h1>
          {props.state.showOTPBoxes ? (
            <p>Enter your email to recover your password</p>
          ) : (
            <div>
            <p>Enter OTP sent on your registered mail Id</p>
            {props.state.new_password && props.state.confirm_new_password && props.state.confirm_new_password !== props.state.new_password && (
              <i style={{color: "red"}}>Passwords do not match</i>
            )}
            </div>
          )}
          {props.state.showOTPBoxes ? (
            <div className="ms-form-group has-icon">
              <form onSubmit={props.forgotPasswordHandler}>
                <input
                  type="text"
                  placeholder=""
                  className="form-control"
                  name="email"
                  pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                  value={props.state.email}
                  onChange={props.onChangeHandler}
                />{" "}
                <i className="material-icons">email</i>
                <Button
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                  disabled={props.state.email.trim() ? false : true}
                >
                  Reset Password
                </Button>
              </form>
            </div>
          ) : (
            <div className="ms-form-group has-icon">
              {/* <OTP state={props.state} onChange={props.onChangeHandler} /> */}
              <TextField
                error={false}
                margin="normal"
                sx={{
                  mb: 2,
                }}
                required
                fullWidth
                type="text"
                id="otp"
                label="OTP"
                name="otp"
                autoComplete="otp"
                size="small"
                inputProps={{ min: 6, max: 6 }}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 6)
                }}
                value={props.state.otp}
                onChange={props.onChangeHandler}
                helperText={null}
              />
              <div className="mb-2">
                <label htmlFor="validationCustom09">Password</label>
                <div className="input-group">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="new_password"
                    label="Password"
                    type={toggle_password ? "text" : "password"}
                    id="validationCustom09"
                    size="small"
                    autoComplete="current-password"
                    onChange={(e) => props.onChangeHandler(e)}
                    value={props.state.new_password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {toggle_password ? (
                            <Visibility onClick={() => setVisible(false)} />
                          ) : (
                            <VisibilityOff onClick={() => setVisible(true)} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirm_new_password"
                    label="Confirm password"
                    type={toggle_password ? "text" : "password"}
                    id="validationCustom09"
                    size="small"
                    autoComplete="current-password"
                    onChange={(e) => props.onChangeHandler(e)}
                    value={props.state.confirm_new_password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {toggle_password ? (
                            <Visibility onClick={() => setVisible(false)} />
                          ) : (
                            <VisibilityOff onClick={() => setVisible(true)} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <Button
                type="submit"
                fullWidth
                color="primary"
                onClick={props.confirmForgotPasswordHandler}
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                disabled={
                  props.state.confirm_new_password !== props.state.new_password
                }
              >
                Reset Password
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ForgotPassword;
