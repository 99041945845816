import React from 'react';
import { Container, Typography, } from "@mui/material";
import {  makeStyles } from "@mui/styles";
import Jumbo from '../../../assets/img/jimbo.jpg';



const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    position: 'relative',
    width: '100%',
    height: '400px', // Set the height of your banner
    background: `url(${Jumbo}) center/cover no-repeat`, // Replace with your image URL
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Adjust opacity here (0.5 for 50%)
  },
  content: {
    position: 'absolute',
    top: '60%',
    left: '35%',
    transform: 'translate(-50%, -50%)',
   // textAlign: 'center',
    color: 'white',
    zIndex: 1,
  },
  bgColorH: {
    color: "white",
    fontSize: "4em"
  },
  bgColorW: {
    color: "white",
    fontSize: "2em"
  }
}));

const JumbotronWithImage = () => {
  const classes = useStyles();

  return (
    <div className={classes.bannerContainer}>
      <div className={classes.overlay}></div>
      <div className={classes.content}>
        <h1 className={classes.bgColorH}>Browse Standard KIOSKS</h1>
        <p className={classes. bgColorW} >Flexible and reliable market-ready platforms.</p>
      </div>
    </div>
  );
};

export default JumbotronWithImage;
