import React, { Component, Fragment } from "react";
import Breadcrumb from "./Breadcrumb";
import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";
import Swal from "sweetalert2";
import * as ItemServiceAPI from "../../../services/product/item/ItemServiceAPI";
import { toast } from "react-toastify";
import ItemModal from "./ItemModal";
import ItemDialogue from "./ItemDialog";
import ImageCropper from "../../../shared/Cropper/ImageCropper";
// import * as addItem from '../../../assets/img/costic/add-product-1.jpg';
import default_img from "../../../assets/img/upload_image_2.jpg";
import { FlapperSpinner } from "react-spinners-kit";
import MultipleImageCropper from "../../../shared/Cropper/MultipleImageCropper";

class Gridcontent extends Component {
  state = {
    file: null,
    gridboxes: [],
    item_options: [],
    item_extras: [],
    item_id: "",
    category_id: "",
    menu_id: "",
    delete: false,
    item_to_be_deleted: "",
    is_buffet: false,
    update_item: false,
    show: false,

    item_identifier: "",
    item_description: "",
    quantity: "",
    item_cost: "0",
    item_status: true,
    imagesPreviewUrl: "",
    item_image: "",
    sort_order: "1",
    is_take_away_active: true,
    is_dine_in_active: true,
    is_delivery_active: true,
    is_delivery_to_park_active: true,

    showItemCropper: false,
    itemCropperLoader: false,
    loading: false,

    showOptionsFlag: false,
    showExtrasFlag: false,
    showAddEditOptionFlag: false,
    showAddEditExtrasFlag: false,

    showSubOptionsFlag: false, // View Sub Options Flag
    showSubAddonsFlag: false, // View Sub AddOns Flag
    showAddEditSubOptionsFlag: false, // AddEdit SubOptions Flag
    showAddEditSubExtrasFlag: false, // AddEdit SubExtras Flag

    update_option_flag: false,
    update_extras_flag: false,

    item_sub_extras: null,
    item_sub_options: null,

    edit_options_data: {
      addon_id: null,
      addon_identifier: null,
      addon_description: null,
      addon_sort_order: null,
      total_addons: null,
      addon_status: true,
      is_addon_available: null,
      addon_type: null,
      item_id: null,
      addon_cost: null,
    },

    edit_sub_options_data: {
      // Sub Options Obj
      addon_id: null,
      parent_addon_id: null, // Parent Add-On Id
      addon_identifier: null,
      addon_description: null,
      addon_sort_order: null,
      total_addons: null,
      addon_status: true,
      is_addon_available: null,
      addon_type: null,
      item_id: null,
      addon_cost: null,
    },

    edit_extras_data: {
      addon_id: null,
      addon_identifier: null,
      addon_description: null,
      addon_sort_order: null,
      total_addons: null,
      addon_status: true,
      is_addon_available: null,
      addon_type: null,
      item_id: null,
      addon_cost: null,
    },

    edit_sub_extras_data: {
      // Sub Extras Obj
      addon_id: null,
      parent_addon_id: null, // Parent Add-On Id
      addon_identifier: null,
      addon_description: null,
      addon_sort_order: null,
      total_addons: null,
      addon_status: true,
      is_addon_available: null,
      addon_type: null,
      item_id: null,
      addon_cost: null,
    },
  };

  componentDidMount = (event) => {
    if (this.props.is_setup === true) {
      this.setState({
        menu_id: this.props.menu_id,
        category_id: this.props.category_id,
      });
      this.fetchAllItem(this.props.category_id);
    } else {
      const id = this.props.match.params.category_id;
      this.setState({
        category_id: id,
        menu_id: this.props.match.params.menu_id,
      });
      this.fetchAllItem(id);
    }
  };

  deleteModalShow = (id) => {
    this.setState({
      delete: true,
      item_to_be_deleted: id,
    });
  };

  editModalShow = (item) => {
    this.setState({
      show: true,
      update_item: true,
      item_id: item.item_id,
      item_identifier: item.item_identifier,
      item_description: item.item_description,
      quantity: item.total_items,
      item_cost: item.item_cost,
      item_status: item.item_status,
      imagesPreviewUrl: item.item_image,
      item_image: item.item_image,
      sort_order: item.item_sort_order,
      is_take_away_active: item.is_take_away_active,
      is_dine_in_active: item.is_dine_in_active,
      is_delivery_active: item.is_delivery_active,
      is_buffet: item.is_buffet_item,
      is_delivery_to_park_active: item.is_delivery_to_park_active,
    });
  };

  deleteModalClose = () => {
    this.setState({
      delete: false,
      item_to_be_deleted: "",
    });
  };

  removeItemHandler = (id) => {
    ItemServiceAPI.deleteItem(this.state.item_to_be_deleted)
      .then((response) => {
        if (response.data.status) {
          this.deleteModalClose();
          Swal.fire("Deleted!", "Your Item has been deleted.", "success");
          this.fetchAllItem(this.state.category_id);
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn("Oops! Something went wrong, Please try again later");
      });
  };

  fetchAllItem = (category_id) => {
    ItemServiceAPI.fetchItem(category_id)
      .then((response) => {
        if (response.data.status) {
          if (response.data.data) {
            this.setState({
              is_buffet: response.data.data.is_buffet,
              gridboxes: response.data.data.item_response_bean_list,
            });
          }
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn("Oops! Something went wrong, Please try again later");
      });
  };

  duplicateItem = (item) => {
    ItemServiceAPI.duplicateItem(item.item_id)
      .then((response) => {
        if (response.data.status) {
          toast.success("Item Duplicated Successfully");
          this.fetchAllItem(this.state.category_id);
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn("Oops! Something went wrong, Please try again later");
      });
  };

  handleModal = (e) => {
    this.setState({
      show: !this.state.show,
      update_item: false,
      item_identifier: "",
      item_description: "",
      quantity: "",
      item_cost: "",
      item_status: true,
      imagesPreviewUrl: "",
      item_image: "",
      item_sort_order: "",
      is_take_away_active: true,
      is_dine_in_active: true,
      is_delivery_active: true,
      is_delivery_to_park_active: true,
      is_buffet: false,
    });
  };

  handleOptionsModal = (e) => {
    this.setState({
      showOptionsFlag: !this.state.showOptionsFlag,
    });
  };

  handleExtrasModal = (e) => {
    this.setState({
      showExtrasFlag: !this.state.showExtrasFlag,
    });
  };

  toggleHandler = (current) => {
    this.setState({
      [current]: !this.state[current],
    });
  };

  getCroppedImages = (croppedImagesFormData, key, imageArray, file) => {
    if (key === "item") {
      console.log(file);

      this.setState({
        file: file[0],
        imagesPreviewUrl: imageArray[0],
      });
    } else {
      console.log("No cropper key returned!");
    }
  };

  // handleImageChange = file => {
  //     this.setState({ itemCropperLoader: true })
  //     // const files = Array.from(file);
  //     // files.forEach((file, i) => {
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //         this.setState({
  //             file: file,
  //             imagesPreviewUrl: reader.result,
  //             showItemCropper: false,
  //             itemCropperLoader: false
  //         });
  //     };
  //     reader.readAsDataURL(file);
  //     this.setState({ itemCropperLoader: false })
  //     // this.toggleHandler('showItemCropper');
  // }

  statusItemHandler = (e) => {
    this.setState({
      item_status: !this.state.item_status,
    });
  };

  onChangeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  addItemHandler = (value) => {
    this.setState({ loading: true });

    const payload = {
      // file: this.state.file,
      category_id: this.state.category_id,
      item_identifier: this.state.item_identifier,
      item_description: this.state.item_description,
      quantity: this.state.quantity,
      item_cost: this.state.item_cost,
      item_sort_order: this.state.sort_order,
      item_status: this.state.item_status,
      is_take_away_active: this.state.is_take_away_active,
      is_dine_in_active: this.state.is_dine_in_active,
      is_delivery_active: this.state.is_delivery_active,
      is_delivery_to_park_active: this.state.is_delivery_to_park_active,
    };

    let formData = new FormData();
    formData.append("item_details", JSON.stringify(payload));
    formData.append("item_image", this.state.file);

    ItemServiceAPI.addItem(formData)
      .then((response) => {
        if (response.data.status) {
          this.setState({
            item_identifier: "",
            item_description: "",
            quantity: "",
            item_cost: "",
            item_status: false,
            is_discountable: false,
            file: null,
            imagePreviewUrl: null,
            item_sort_order: "",
            loading: false,
          });
          toast.success("Item Added Successfully");
          this.fetchAllItem(this.state.category_id);
          this.handleModal();
        } else {
          toast.warn(response.data.message);
          this.setState({
            item_identifier: "",
            item_description: "",
            quantity: "",
            item_cost: "",
            item_status: false,
            is_discountable: false,
            imagePreviewUrl: null,
            item_sort_order: "",
            loading: false,
          });
        }
      })
      .catch((error) => {
        toast.warn("Oops! Something went wrong, Please try again later");
        this.setState({ loading: false });
      });
  };

  updateItemHandler = (value) => {
    this.setState({ loading: true });

    let formData = new FormData();
    if (this.state.file === null) {
      const payload = {
        item_id: this.state.item_id,
        item_identifier: this.state.item_identifier,
        item_description: this.state.item_description,
        quantity: this.state.quantity,
        item_cost: this.state.item_cost,
        item_status: this.state.item_status,
        is_discountable: this.state.is_discountable,
        item_sort_order: this.state.sort_order,
        is_take_away_active: this.state.is_take_away_active,
        is_dine_in_active: this.state.is_dine_in_active,
        is_delivery_active: this.state.is_delivery_active,
        is_delivery_to_park_active: this.state.is_delivery_to_park_active,
      };
      formData.append("item_details", JSON.stringify(payload));
    } else {
      const payload = {
        // file: this.state.file,
        item_id: this.state.item_id,
        item_identifier: this.state.item_identifier,
        item_description: this.state.item_description,
        quantity: this.state.quantity,
        item_cost: this.state.item_cost,
        item_status: this.state.item_status,
        is_discountable: this.state.is_discountable,
        item_sort_order: this.state.sort_order,
        is_take_away_active: this.state.is_take_away_active,
        is_dine_in_active: this.state.is_dine_in_active,
        is_delivery_active: this.state.is_delivery_active,
        is_delivery_to_park_active: this.state.is_delivery_to_park_active,
      };
      formData.append("item_details", JSON.stringify(payload));
      formData.append("item_image", this.state.file);
    }

    ItemServiceAPI.updateItem(formData)
      .then((response) => {
        if (response.data.status) {
          toast.success("Item Updated Successfully");
          this.setState({ loading: false });
          this.fetchAllItem(this.state.category_id);
          this.handleModal();
        } else {
          // toast.warn(response.data.message);
          console.log("response.data.message : ", response.data.message);
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        toast.warn("Oops! Something went wrong, Please try again later");
        this.setState({ loading: false });
      });
  };

  showOptions = () => {
    this.setState({ showOptionsFlag: true }, this.getAllOptionsExtras);
  };

  showExtras = () => {
    this.setState({ showExtrasFlag: true }, this.getAllOptionsExtras);
  };

  getAllOptionsExtras = () => {
    ItemServiceAPI.fetchExtrasOptionsByItemId(this.state.item_id)
      .then((response) => {
        if (response.data.status) {
          if (response && response.data && response.data.data) {
            if (response && response.data && response.data.data.length == 0) {
              this.setState({ item_options: null, item_extras: null });
            }

            if (response && response.data && response.data.data.length == 2) {
              this.setState({
                item_options: response.data.data[0]
                  ? response.data.data[0].addon_category
                  : null,
                item_extras: response.data.data[1]
                  ? response.data.data[1].addon_category
                  : null,
              });
            }

            if (response && response.data && response.data.data.length == 1) {
              if (response.data.data[0].addon_type == "EXTRAS") {
                this.setState({
                  item_extras: response.data.data[0]
                    ? response.data.data[0].addon_category
                    : null,
                  item_options: null,
                });
              }

              if (response.data.data[0].addon_type == "OPTIONS") {
                this.setState({
                  item_options: response.data.data[0]
                    ? response.data.data[0].addon_category
                    : null,
                  item_extras: null,
                });
              }
            }
          }
        }
      })
      .catch((error) => {
        toast.warn(
          "Oops! Something went wrong, please try again later!",
          error
        );
      });
    // }, 500);
  };

  showAddEditOptionModal = () => {
    this.setState({ showAddEditOptionFlag: true });
  };

  handleAddOptionModal = () => {
    this.setState({
      showAddEditOptionFlag: !this.state.showAddEditOptionFlag,
      update_option_flag: false,
    });
  };

  handleSubOptionsModal = () => {
    this.setState({
      showSubOptionsFlag: !this.state.showSubOptionsFlag,
      item_sub_options: false,
    });
  };

  handleSubExtrasModal = () => {
    this.setState({ showSubAddonsFlag: !this.state.showSubAddonsFlag });
  };

  handleAddUpdateSubExtrasModal = () => {
    this.setState({
      showAddEditSubExtrasFlag: !this.state.showAddEditSubExtrasFlag,
    });
  };

  showAddEditExtrasModal = () => {
    this.setState({ showAddEditExtrasFlag: true });
  };

  handleAddExtrasModal = () => {
    this.setState({ showAddEditExtrasFlag: !this.state.showAddEditExtrasFlag });
  };

  addNewOption = () => {
    const payload = {
      item_id: "Item_e6c4ce32c83c49348c2bb531f9615393",
      addon_identifier: "test123",
      addon_description: "test desc",
      addon_status: true,
      addon_type: "OPTIONS",
      addon_sort_order: 1,
    };

    ItemServiceAPI.addExtrasOptions(payload)
      .then((response) => {
        if (response.data.status) {
          toast.success("Item Added Successfully");
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn("Oops! Something went wrong, Please try again later");
      });
  };

  handleOptionsChange = (e) => {
    this.setState(
      {
        edit_options_data: {
          ...this.state.edit_options_data,
          [e.target.name]: e.target.value,
        },
      }
      // this.printState
    );
  };

  handleExtrasChange = (e) => {
    this.setState(
      {
        edit_extras_data: {
          ...this.state.edit_extras_data,
          [e.target.name]: e.target.value,
        },
      }
      // this.printState
    );
  };

  addNewExtra = () => {
    console.log("add new extra invoked...");
    // const payload = {
    //     "item_id": "Item_e6c4ce32c83c49348c2bb531f9615393",
    //     "addon_type": "EXTRAS",
    //     "addon_identifier": "Extra 1",
    //     "addon_description": "Test Extra Desc",
    //     "addon_cost": "100",
    //     "quantity": "20",
    //     "addon_status": true,
    //     "addon_sort_order": 1,
    //     "is_addon_available": true
    // }

    // ItemServiceAPI.addExtrasOptions(payload).then(response => {
    //     if (response.data.status) {
    //         toast.success('Item Added Successfully');
    //     } else {
    //         toast.warn(response.data.message);
    //     }
    // }).catch(error => {
    //     toast.warn('Oops! Something went wrong, Please try again later');
    // })
  };

  addOptions = (data) => {
    let payload_data = {};
    if (data === 1) {
      // Add Options
      payload_data = {
        item_id: this.state.item_id,
        addon_identifier: this.state.edit_options_data.addon_identifier,
        addon_description: this.state.edit_options_data.addon_description,
        addon_status: true,
        addon_type: "OPTIONS",
        addon_sort_order: 1,
        is_addon_available: true,
        total_addons: 0,
        addon_cost: 0,
      };
    } else if (data === 2) {
      // Add Sub-Options
      payload_data = {
        parent_addon_id: this.state.parent_addon_id,
        item_id: this.state.item_id,
        addon_identifier: this.state.edit_options_data.addon_identifier,
        addon_description: this.state.edit_options_data.addon_description,
        addon_status: true,
        addon_type: "OPTIONS",
        addon_sort_order: 1,
        is_addon_available: true,
        total_addons: 0,
        addon_cost: 0,
      };
    } else {
      console.log("Error...!");
    }

    ItemServiceAPI.addExtrasOptions(payload_data)
      .then((response) => {
        if (response.data.status) {
          if (data === 1) {
            // Add Options
            toast.success("Options Added Successfully!");
            console.log("response.data.data : ", response.data.data);
            this.setState({
              showAddEditOptionFlag: false,
              item_options: response.data.data[0].addon_category,
            });
          } else {
            // Add Sub-Options
            // this.getAllSubOptionsExtras(this.state.parent_addon_id);
            toast.success("Sub-Options Added Successfully!");
            this.setState({
              showAddEditSubOptionsFlag: false,
              item_sub_options: response.data.data[0].addon_category[0].addons,
            });
          }
        } else {
          console.log("Not Found!");
        }
      })
      .catch((error) => {
        toast.warn("Oops! Something went wrong, Please try again later.");
      });
  };

  addSubOptions = (data) => {
    let payload_data = {};
    payload_data = {
      parent_addon_id: this.state.parent_addon_id,
      item_id: this.state.item_id,
      addon_identifier: this.state.edit_sub_options_data.addon_identifier,
      addon_description: this.state.edit_sub_options_data.addon_description,
      addon_status: true,
      addon_type: "OPTIONS",
      addon_sort_order: 1,
      is_addon_available: true,
      total_addons: 0,
      addon_cost: 0,
    };

    ItemServiceAPI.addExtrasOptions(payload_data)
      .then((response) => {
        if (response.data.status) {
          if (data === 1) {
            // Add Options
            toast.success("Options Added Successfully!");
            console.log("response.data.data : ", response.data.data);
            this.setState({
              showAddEditOptionFlag: false,
              item_options: response.data.data[0].addon_category,
            });
          } else {
            // Add Sub-Options
            // this.getAllSubOptionsExtras(this.state.parent_addon_id);
            toast.success("Sub-Options Added Successfully!");
            this.setState({
              showAddEditSubOptionsFlag: false,
              item_sub_options: response.data.data[0].addon_category[0].addons,
            });
          }
        } else {
          console.log("Not Found!");
        }
      })
      .catch((error) => {
        toast.warn("Oops! Something went wrong, Please try again later.");
      });
  };

  addExtras = (data) => {
    let payload_data = {};

    if (data === 1) {
      // Add Extras
      payload_data = {
        item_id: this.state.item_id,
        addon_type: "EXTRAS",
        addon_identifier: this.state.edit_extras_data.addon_identifier,
        addon_description: this.state.edit_extras_data.addon_description,
        addon_cost: this.state.edit_extras_data.addon_cost,
        quantity: this.state.edit_extras_data.total_addons,
        addon_status: true,
        addon_sort_order: 1,
        is_addon_available: true,
      };
    } else if (data === 2) {
      // Add Sub-Extras
      payload_data = {
        parent_addon_id: this.state.parent_addon_id,
        item_id: this.state.item_id,
        addon_type: "EXTRAS",
        addon_identifier: this.state.edit_extras_data.addon_identifier,
        addon_description: this.state.edit_extras_data.addon_description,
        addon_cost: this.state.edit_extras_data.addon_cost,
        quantity: this.state.edit_extras_data.total_addons,
        addon_status: true,
        addon_sort_order: 1,
        is_addon_available: true,
      };
    } else {
      console.log("Error...!");
    }

    console.log("addExtras payload_data: ......", payload_data);

    ItemServiceAPI.addExtrasOptions(payload_data)
      .then((response) => {
        if (response.data.status) {
          // this.getAllOptionsExtras();
          // this.setState({ show_addeditextras_modal: false });
          if (data === 1) {
            // Add Extras
            toast.success("Extras Added Successfully!");
            console.log("response.data.data : ", response.data.data);
            this.setState({
              showAddEditExtrasFlag: false,
              item_extras: response.data.data[1].addon_category,
            });
          } else {
            // Add Sub-Extras
            // this.getAllSubOptionsExtras(this.state.parent_addon_id);
            this.setState({
              showAddEditSubExtrasFlag: false,
              item_sub_extras: response.data.data[1].addon_category,
            });
            toast.success("Sub-Options Added Successfully!");
          }
        } else {
          console.log("Not Found!");
        }
      })
      .catch((error) => {
        toast.warn("Oops! Something went wrong, Please try again later.");
      });
  };

  addSubExtras = (data) => {
    let payload_data = {};
    payload_data = {
      parent_addon_id: this.state.parent_addon_id,
      item_id: this.state.item_id,
      addon_type: "EXTRAS",
      addon_identifier: this.state.edit_sub_extras_data.addon_identifier,
      addon_description: this.state.edit_sub_extras_data.addon_description,
      addon_cost: this.state.edit_sub_extras_data.addon_cost,
      quantity: this.state.edit_sub_extras_data.total_addons,
      addon_status: true,
      addon_sort_order: 1,
      is_addon_available: true,
      addon_cost: 0,
    };

    ItemServiceAPI.addExtrasOptions(payload_data)
      .then((response) => {
        if (response.data.status) {
          // this.getAllOptionsExtras();
          // this.setState({ show_addeditextras_modal: false });
          if (data === 1) {
            // Add Extras
            toast.success("Extras Added Successfully!");
            console.log("response.data.data : ", response.data.data);
            this.setState({
              showAddEditExtrasFlag: false,
              item_extras: response.data.data[1].addon_category,
            });
          } else {
            // Add Sub-Extras
            // this.getAllSubOptionsExtras(this.state.parent_addon_id);
            this.setState({
              showAddEditSubExtrasFlag: false,
              item_sub_extras: response.data.data[1].addon_category,
            });
            toast.success("Sub-Options Added Successfully!");
          }
        } else {
          console.log("Not Found!");
        }
      })
      .catch((error) => {
        toast.warn("Oops! Something went wrong, Please try again later.");
      });
  };

  updateExtras = () => {
    let payload_data = {
      item_id: this.state.item_id,
      addon_id: this.state.edit_extras_data.addon_id,
      addon_type: "EXTRAS",
      addon_identifier: this.state.edit_extras_data.addon_identifier,
      addon_description: this.state.edit_extras_data.addon_description,
      addon_status: true,
      addon_sort_order: 1,
      is_addon_available: true,
      quantity: this.state.edit_extras_data.total_addons,
      addon_cost: this.state.edit_extras_data.addon_cost,
    };

    ItemServiceAPI.updateExtrasOptions(payload_data)
      .then((response) => {
        if (response.data.status) {
          // this.getAllOptionsExtras();
          toast.success("Extras Updated Successfully!");
          this.setState({
            showAddEditSubExtrasFlag: false,
            item_sub_options: response.data.data[1].addon_category,
          });
        } else {
          console.log("not found");
        }
      })
      .catch((error) => {
        toast.warn("Oops! Something went wrong, Please try again later.");
      });
  };

  handleEditOptions = (recObj) => {
    this.setState(
      {
        edit_options_data: recObj,
        showAddEditOptionFlag: true,
        update_option_flag: true,
      }
      // this.printState
    );
  };

  handleEditExtras = (recObj) => {
    this.setState(
      {
        edit_extras_data: recObj,
        showAddEditExtrasFlag: true,
        update_extras_flag: true,
      }
      // this.printState
    );
  };

  handleSubOptionsChange = (e) => {
    this.setState({
      edit_sub_options_data: {
        ...this.state.edit_sub_options_data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSubExtrasChange = (e) => {
    this.setState({
      edit_sub_extras_data: {
        ...this.state.edit_sub_extras_data,
        [e.target.name]: e.target.value,
      },
    });
  };

  deleteModalShow = (row) => {
    this.setState({
      delete: true,
      item_to_be_deleted: row.addon_id,
    });
  };

  handleDeleteExtrasOptions = () => {
    ItemServiceAPI.deleteExtrasOptions(this.state.item_to_be_deleted)
      .then((response) => {
        if (response.data.status) {
          Swal.fire(
            "Deleted!",
            "Item has been deleted successfully!",
            "success"
          );
          this.setState(
            {
              delete: false,
              item_to_be_deleted: null,
            },
            this.getAllOptionsExtras
          );
        } else {
          console.log("not found");
        }
      })
      .catch((error) => {
        toast.warn(
          "Oops! Something went wrong, Please try again later.",
          error
        );
      });
  };

  updateOptions = () => {
    let payload_data = {
      item_id: this.state.item_id,
      addon_id: this.state.edit_options_data.addon_id,
      addon_identifier: this.state.edit_options_data.addon_identifier,
      addon_description: this.state.edit_options_data.addon_description,
      addon_status: true,
      addon_type: "OPTIONS",
      addon_sort_order: 1,
      is_addon_available: true,
      total_addons: 0,
      addon_cost: 0,
    };

    ItemServiceAPI.updateExtrasOptions(payload_data)
      .then((response) => {
        if (response.data.status) {
          toast.success("Options Updated Successfully!");
          this.setState({
            showAddEditOptionFlag: false,
            item_options: response.data.data[0].addon_category,
          });
        } else {
          console.log("not found");
        }
      })
      .catch((error) => {
        toast.warn("Oops! Something went wrong, Please try again later.");
      });
  };

  updateSubOptions = () => {
    let payload_data = {
      item_id: this.state.item_id,
      addon_id: this.state.edit_sub_options_data.addon_id,
      addon_identifier: this.state.edit_sub_options_data.addon_identifier,
      addon_description: this.state.edit_sub_options_data.addon_description,
      addon_status: true,
      addon_type: "OPTIONS",
      addon_sort_order: 1,
      is_addon_available: true,
      total_addons: 0,
      addon_cost: 0,
    };

    ItemServiceAPI.updateExtrasOptions(payload_data)
      .then((response) => {
        if (response.data.status) {
          toast.success("Options Updated Successfully!");
          this.setState({
            showAddEditSubOptionsFlag: false,
            item_sub_options: response.data.data[0].addon_category[0].addons,
          });
        } else {
          console.log("not found");
        }
      })
      .catch((error) => {
        toast.warn("Oops! Something went wrong, Please try again later.");
      });
  };

  updateSubExtras = () => {
    let payload_data = {
      item_id: this.state.item_id,
      addon_id: this.state.edit_sub_extras_data.addon_id,
      addon_identifier: this.state.edit_sub_extras_data.addon_identifier,
      addon_description: this.state.edit_sub_extras_data.addon_description,
      addon_status: true,
      addon_type: "EXTRAS",
      addon_sort_order: 1,
      is_addon_available: true,
      total_addons: 0,
      addon_cost: 0,
    };

    ItemServiceAPI.updateExtrasOptions(payload_data)
      .then((response) => {
        if (response.data.status) {
          toast.success("Updated Successfully!");
          this.setState({
            showAddEditSubExtrasFlag: false,
            item_sub_extras: response.data.data[1].addon_category[0].addons,
          });
        } else {
          console.log("not found");
        }
      })
      .catch((error) => {
        toast.warn("Oops! Something went wrong, Please try again later.");
      });
  };

  viewSubAddons = (data, addon_type) => {
    // data.addon_id
    if (addon_type == 1) {
      // Options
      this.fetchSubAddons(data).then((response) => {
        if (response.data.data && response.data.data.sub_addons) {
          this.setState({
            item_sub_options: response.data.data.sub_addons,
            showSubOptionsFlag: true,
            showOptionsFlag: false,
            parent_addon_id: data.addon_id,
          });
        } else {
          this.setState({
            item_sub_options: [],
            showSubOptionsFlag: true,
            showOptionsFlag: false,
            parent_addon_id: data.addon_id,
          });
        }
      });
    } else if (addon_type == 2) {
      // Extras
      this.fetchSubAddons(data).then((response) => {
        this.setState({
          item_sub_extras: response.data.data.sub_addons,
          showSubAddonsFlag: true,
          showAddonsFlag: false,
          parent_addon_id: data.addon_id,
        });
      });
    }
  };

  fetchSubAddons = (data) => {
    let myPromise = new Promise(function (myResolve, myReject) {
      ItemServiceAPI.fetchAddonByAddonId({ addon_id: data.addon_id })
        .then((response) => {
          if (response.data.status) {
            return myResolve(response);
          } else {
            console.log("Not Found!");
          }
        })
        .catch((error) => {
          toast.warn("Oops! Something went wrong, Please try again later.");
        });
    });

    return myPromise;
  };

  showAddNewSubOptionModal = () => {
    this.setState({
      showAddEditSubOptionsFlag: true,
      edit_sub_options_data: null,
      update_suboptions_flag: false,
    });
  };

  handleEditSubOptions = (recObj) => {
    this.setState({
      edit_sub_options_data: recObj,
      showAddEditSubOptionsFlag: true,
      update_suboptions_flag: true,
    });
  };

  handleEditSubExtras = (recObj) => {
    this.setState({
      edit_sub_extras_data: recObj,
      showAddEditSubExtrasFlag: true,
      update_subextras_flag: true,
    });
  };

  showAddNewSubExtrasModal = () => {
    this.setState({
      showAddEditSubExtrasFlag: true,
      edit_sub_extras_data: null,
      update_subextras_flag: false,
    });
  };

  handleAddUpdateSubOptionsModal = () => {
    this.setState({
      showAddEditSubOptionsFlag: !this.state.showAddEditSubOptionsFlag,
    });
  };

  printState = () => {
    console.log("Printing State : ", this.state);
  };

  render() {
    const { gridboxes } = this.state;
    return (
      <Fragment>
        {this.state.loading ? (
          <div className="spinner cardBody card-body">
            <FlapperSpinner
              size={75}
              color="#233cad"
              loading={this.state.loading}
            />
          </div>
        ) : (
          <div className="ms-content-wrapper">
            <div className="row">
              {this.props.is_setup ? null : (
                <div className="col-md-10">
                  <Breadcrumb
                    category_id={this.props.match.params.category_id}
                    menu_id={this.props.match.params.menu_id}
                  />
                </div>
              )}

              <div className="col-md-2"></div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  {this.props.is_setup ? (
                    <div className="col-md-3 mb-1">
                      <button
                        type="button"
                        onClick={this.handleModal}
                        className="btn grid-btn mt-0 btn-primary"
                      >
                        + Add Item
                      </button>
                    </div>
                  ) : (
                    <div className="col-md-3 mb-1">
                      <button
                        type="button"
                        onClick={this.handleModal}
                        className="btn grid-btn mt-0 btn-primary"
                      >
                        + Add Item
                      </button>
                    </div>
                  )}
                </div>
                <div className="row">
                  {(gridboxes || []).map((item, i) => (
                    <div
                      key={i}
                      className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
                    >
                      <div className="ms-card">
                        <div className="ms-card-img">
                          <img src={item.item_image} className="item-img" />
                        </div>

                        <div className="ms-card-body ">
                          <div className=" col-md-12">
                            <h5 className="section-title bold">
                              {item.item_identifier.toUpperCase()}
                            </h5>
                            <h6 className="section-title font-italic font-weight-light">
                              "{item.item_description}"
                            </h6>
                          </div>

                          <table className="table ms-profile-information mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">Status</th>
                                <td>
                                  <span
                                    className={
                                      item.item_status == "AVAILABLE"
                                        ? "badge badge-success"
                                        : "badge badge-primary"
                                    }
                                  >
                                    {item.item_status}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Sort Order</th>
                                <td>{item.item_sort_order}</td>
                              </tr>
                              {item.para ? (
                                <tr>
                                  <td>{item.para}</td>
                                </tr>
                              ) : null}
                            </tbody>
                          </table>
                          <div className="new mb-0">
                            <button
                              type="button"
                              onClick={() => this.deleteModalShow(item.item_id)}
                              className="btn grid-btn mt-0 btn-sm btn-danger btn-md btn-block products-btn"
                            >
                              Remove
                            </button>
                            <button
                              type="button"
                              onClick={() => this.editModalShow(item)}
                              className="btn grid-btn mt-0 pt-3 btn-sm btn-primary btn-md btn-block m-2 products-btn"
                            >
                              Edit
                            </button>
                          </div>

                          <SweetAlert
                            show={this.state.delete}
                            title="Delete"
                            html
                            text="Do you want to delete ?"
                            type="error"
                            onConfirm={this.removeItemHandler}
                            onCancel={this.deleteModalClose}
                            showCancelButton={true}
                            showLoaderOnConfirm={true}
                            confirmButtonText="Delete"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {this.state.update_item === true ? (
              <ItemDialogue
                state={this.state}
                is_setup={this.props.is_setup}
                handleModal={this.handleModal}
                handleOptionsModal={this.handleOptionsModal}
                handleAddOptionModal={this.handleAddOptionModal}
                handleExtrasModal={this.handleExtrasModal}
                handleAddExtrasModal={this.handleAddExtrasModal}
                handleImageChange={this.getCroppedImages}
                toggleHandler={this.toggleHandler}
                statusItemHandler={this.statusItemHandler}
                onChangeHandler={this.onChangeHandler}
                addItemHandler={this.addItemHandler}
                updateItemHandler={this.updateItemHandler}
                showOptions={this.showOptions}
                showExtras={this.showExtras}
                addNewOption={this.addNewOption}
                addNewExtra={this.addNewExtra}
                showAddEditOptionModal={this.showAddEditOptionModal}
                showAddEditExtrasModal={this.showAddEditExtrasModal}
                handleOptionsChange={this.handleOptionsChange}
                addOptions={this.addOptions}
                addExtras={this.addExtras}
                updateOptions={this.updateOptions}
                handleExtrasChange={this.handleExtrasChange}
                handleEditOptions={this.handleEditOptions}
                deleteModalShow={this.deleteModalShow}
                handleDeleteExtrasOptions={this.handleDeleteExtrasOptions}
                handleEditExtras={this.handleEditExtras}
                viewSubAddons={this.viewSubAddons}
                showAddNewSubOptionModal={this.showAddNewSubOptionModal}
                updateSubOptions={this.updateSubOptions}
                handleEditSubOptions={this.handleEditSubOptions}
                handleAddUpdateSubOptionsModal={
                  this.handleAddUpdateSubOptionsModal
                }
                handleEditSubExtras={this.handleEditSubExtras}
                showAddNewSubExtrasModal={this.showAddNewSubExtrasModal}
                handleSubOptionsChange={this.handleSubOptionsChange}
                updateExtras={this.updateExtras}
                updateSubExtras={this.updateSubExtras}
                handleSubExtrasChange={this.handleSubExtrasChange}
                addSubOptions={this.addSubOptions}
                addSubExtras={this.addSubExtras}
                handleSubOptionsModal={this.handleSubOptionsModal}
                handleSubExtrasModal={this.handleSubExtrasModal}
                handleAddUpdateSubExtrasModal={
                  this.handleAddUpdateSubExtrasModal
                }
                title="Edit Item"
              />
            ) : (
              <ItemDialogue
                state={this.state}
                is_setup={this.props.is_setup}
                handleModal={this.handleModal}
                handleOptionsModal={this.handleOptionsModal}
                handleAddOptionModal={this.handleAddOptionModal}
                handleExtrasModal={this.handleExtrasModal}
                handleAddExtrasModal={this.handleAddExtrasModal}
                handleImageChange={this.getCroppedImages}
                toggleHandler={this.toggleHandler}
                statusItemHandler={this.statusItemHandler}
                onChangeHandler={this.onChangeHandler}
                addItemHandler={this.addItemHandler}
                updateItemHandler={this.updateItemHandler}
                showOptions={this.showOptions}
                showExtras={this.showExtras}
                addNewOption={this.addNewOption}
                addNewExtra={this.addNewExtra}
                showAddEditOptionModal={this.showAddEditOptionModal}
                showAddEditExtrasModal={this.showAddEditExtrasModal}
                handleOptionsChange={this.handleOptionsChange}
                addOptions={this.addOptions}
                addExtras={this.addExtras}
                updateOptions={this.updateOptions}
                handleExtrasChange={this.handleExtrasChange}
                handleEditOptions={this.handleEditOptions}
                deleteModalShow={this.deleteModalShow}
                handleDeleteExtrasOptions={this.handleDeleteExtrasOptions}
                handleEditExtras={this.handleEditExtras}
                viewSubAddons={this.viewSubAddons}
                showAddNewSubOptionModal={this.showAddNewSubOptionModal}
                updateSubOptions={this.updateSubOptions}
                handleEditSubOptions={this.handleEditSubOptions}
                handleAddUpdateSubOptionsModal={
                  this.handleAddUpdateSubOptionsModal
                }
                handleEditSubExtras={this.handleEditSubExtras}
                showAddNewSubExtrasModal={this.showAddNewSubExtrasModal}
                handleSubOptionsChange={this.handleSubOptionsChange}
                updateExtras={this.updateExtras}
                updateSubExtras={this.updateSubExtras}
                handleSubExtrasChange={this.handleSubExtrasChange}
                addSubOptions={this.addSubOptions}
                addSubExtras={this.addSubExtras}
                handleSubOptionsModal={this.handleSubOptionsModal}
                handleSubExtrasModal={this.handleSubExtrasModal}
                handleAddUpdateSubExtrasModal={
                  this.handleAddUpdateSubExtrasModal
                }
                title="Add Item"
              />
            )}

            {/* <MultipleImageCropper data={{
                                                key: 'item',
                                                isMultiple: false,
                                                getCroppedImages:this.getCroppedImages
                                            }}
                                                height={300}
                                                width={300}
                                            /> */}
          </div>
        )}

        {/* Delete Confirmation Box */}
        <SweetAlert
          show={this.state.delete}
          title="Delete"
          html
          text="Are you sure you want to delete?"
          type="error"
          onConfirm={this.handleDeleteExtrasOptions}
          onCancel={this.deleteModalClose}
          showCancelButton={true}
          showLoaderOnConfirm={true}
          confirmButtonText="Delete"
        />
      </Fragment>
    );
  }
}

export default Gridcontent;
