import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import "../../../assets/css/image.css";
import default_img from "../../../assets/img/upload_image_2.jpg";
import * as MenuServiceAPI from "../../../services/product/menu/MenuServiceAPI";
import MenuModal from "./MenuModal";
import Breadcrumbs from "./Breadcrumb";
import "./style.css";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";
import Swal from "sweetalert2";
import { FlapperSpinner } from "react-spinners-kit";

class Menu extends Component {
  state = {
    menuboxes: [],
    show: false,
    property_id: "",
    menu_id: "",
    total_category_count: "",
    identifier: "",
    description: "",
    imagesPreviewUrl: "",
    file: null,
    update_menu: true,
    sort_order: 1,
    delete: false,
    menu_to_be_removed: "",
    is_buffet: false,
    total_cost: 0,
    is_menu: true,
    show_setup_categories: false,
    show_setup_items: false,
    loading: false,
  };

  handleModal = (e, isAddMenu = false) => {
    if (isAddMenu) {
      this.setState({
        name: "",
        description: "",
        imagesPreviewUrl: "",
        show: !this.state.show,
        update_menu: false,
      });
    } else {
      this.setState({
        name: "",
        description: "",
        imagesPreviewUrl: "",
        show: !this.state.show,
        update_menu: true,
      });
    }
  };

  editMenuHandler = (menu) => {
    this.setState({
      show: true,
      description: menu.menu_description,
      menu_id: menu.menu_id,
      identifier: menu.menu_identifier,
      imagesPreviewUrl: menu.menu_image,
      total_category_count: menu.total_category_count,
      sort_order: menu.menu_sort_order,
      update_menu: true,
      is_buffet: menu.is_buffet,
    });
    if (menu.total_cost) {
      this.setState({ total_cost: menu.total_cost });
    }
  };

  deleteModalShow = (id) => {
    this.setState({
      delete: true,
      menu_to_be_removed: id,
    });
  };

  deleteModalClose = () => {
    this.setState({
      delete: false,
      menu_to_be_removed: "",
    });
  };

  onChangeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  // Invoked from Multi Cropper Child Component, returns cropped form data
  getCroppedImages = (croppedImagesFormData, key, imageArray, file) => {
    if (key === "menu") {
      console.log(file);

      this.setState({
        file: file[0],
        imagesPreviewUrl: imageArray[0],
      });
    } else {
      console.log("No cropper key returned!");
    }
  };

  // handleImageChange = (e) => {
  //     e.preventDefault();
  //     // FileList to Array
  //     const files = Array.from(e.target.files);
  //     files.forEach((file, i) => {
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //             this.setState({
  //                 file: file,
  //                 imagesPreviewUrl: reader.result,
  //             });
  //         };
  //         reader.readAsDataURL(file);
  //     });
  // }

  toggleHandler = (current) => {
    this.setState({
      [current]: !this.state[current],
    });
  };

  componentDidMount = (event) => {
    const property_details = JSON.parse(
      localStorage.getItem("property_details")
    );
    if (property_details) {
      this.setState({ property_id: property_details.property_id });
    }
    this.fetchMenuHandler();
  };

  fetchMenuHandler = (event) => {
    const property = JSON.parse(localStorage.getItem("property_details"));
    if (property) {
      MenuServiceAPI.fetchMenu(property.property_id)
        .then((response) => {
          if (response.data.status) {
            if (
              response.data &&
              response.data.data &&
              response.data.data.menu_response_bean_list
            ) {
              this.setState({
                menuboxes: response.data.data.menu_response_bean_list,
              });
            } else {
              this.setState({ menuboxes: [] });
            }
          } else {
            this.setState({ menuboxes: [] });
            console.log("response.data.message : ", response.data.message);
          }
        })
        .catch((error) => {
          toast.error("Oops! Something went wrong, Please try again later");
        });
    }
  };

  removeMenuHandler = () => {
    MenuServiceAPI.deleteMenu(this.state.menu_to_be_removed)
      .then((response) => {
        if (response.data.status) {
          this.deleteModalClose();
          Swal.fire("Deleted!", "Your menu has been deleted", "success");
          this.fetchMenuHandler();
        } else {
          this.deleteModalClose();
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        this.deleteModalClose();
        toast.error("Oops! Something went wrong, Please try again later");
      });
  };

  updateMenuHandler = (value) => {
    this.setState({ loading: true });
    let formData = new FormData();
    if (this.state.file === null) {
      formData.append("menu_description", value.description);
      formData.append("menu_identifier", value.name);
      formData.append("menu_sort_order", value.sort_order);
      formData.append("menu_id", this.state.menu_id);
      formData.append("total_cost", value.total_cost);
      formData.append("is_buffet", this.state.is_buffet);
    } else {
      // formData = this.state.file; // file is already form data with menu
      formData.append("menu_image", this.state.file);
      formData.append("menu_description", value.description);
      formData.append("menu_identifier", value.name);
      formData.append("menu_sort_order", value.sort_order);
      formData.append("menu_id", this.state.menu_id);
      formData.append("total_cost", value.total_cost);
      formData.append("is_buffet", this.state.is_buffet);
    }

    MenuServiceAPI.updateMenu(formData)
      .then((response) => {
        if (response.data.status) {
          toast.success("Menu Updated Successfully");
          this.setState({
            update_menu: false,
            show: false,
            identifier: "",
            description: "",
            imagesPreviewUrl: null,
            file: null,
            loading: false,
          });
          this.fetchMenuHandler();
        } else {
          toast.error(response.data.message);
          this.setState({
            show: false,
            description: "",
            menu_id: "",
            identifier: "",
            file: null,
            total_category_count: "",
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.handleModal();
        toast.error("Oops! Something went wrong, Please try again later");
        this.setState({
          update_menu: false,
          show: false,
          description: "",
          menu_id: "",
          identifier: "",
          file: null,
          total_category_count: "",
          loading: false,
        });
      });
  };

  addMenuHandler = (value) => {
    let formData = new FormData();
    formData.append("menu_image", this.state.file);
    formData.append("menu_description", value.description);
    formData.append("menu_identifier", value.name);
    formData.append("menu_sort_order", value.sort_order);
    formData.append("property_id", this.state.property_id);
    formData.append("total_cost", value.total_cost);
    formData.append("is_buffet", this.state.is_buffet);
    this.addMenu(formData);
  };

  addMenu = (formData) => {
    this.setState({ loading: true });
    MenuServiceAPI.addMenu(formData)
      .then((response) => {
        if (response.data.status) {
          toast.success("Menu Added Successfully");
          this.setState({
            identifier: "",
            description: "",
            imagesPreviewUrl: null,
            file: null,
            loading: false,
          });
          this.fetchMenuHandler();
          this.handleModal();
        } else {
          this.setState({ loading: false });
          this.handleModal();
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log("assas", error);
        this.setState({ loading: false });
        this.handleModal();
        toast.error("Oops! Something went wrong, Please try again later");
      });
  };

  duplicateMenu = (menu) => {
    let formData = new FormData();
    formData.append("menu_image", menu.menu_image);
    formData.append("menu_description", menu.menu_description);
    formData.append("menu_identifier", menu.menu_identifier);
    formData.append("menu_sort_order", menu.menu_sort_order);
    formData.append("property_id", this.state.property_id);
    MenuServiceAPI.addMenu(formData)
      .then((response) => {
        if (response.data.status) {
          toast.success("Menu Added Successfully");
          this.setState({
            menuboxes: response.data.data,
            identifier: "",
            description: "",
            imagesPreviewUrl: null,
            file: null,
          });
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error("Oops! Something went wrong, Please try again later");
      });
  };

  handleSetupCategories = () => {
    this.setState({ show_setup_categories: true });
  };

  render() {
    return (
      <Fragment>
        {this.state.loading ? (
          <div className="spinner cardBody card-body">
            <FlapperSpinner
              size={75}
              color="#233cad"
              loading={this.state.loading}
            />
          </div>
        ) : (
          <Fragment>
            <div className="ms-content-wrapper box">
              <div className="row product_page">
                {this.props.is_setup ? null : (
                  <div className="col-md-9">
                    <Breadcrumbs show={this.handleModal} />
                  </div>
                )}
                <div>
                  <div className="col-md-12">
                    <div className="row ml-1 mb-1">
                      <button
                        type="button"
                        style={{ float: "right" }}
                        onClick={(e) => this.handleModal(e, true)}
                        className="btn btn-primary"
                      >
                        + Add Menu
                      </button>
                    </div>

                    <div className="row">
                      {this.state.menuboxes && this.state.menuboxes.length > 0
                        ? this.state.menuboxes.map((menu, i) => (
                            <div key={i} className="mr-3">
                              <div className="ms-card">
                                <div className="image-div">
                                  <img src={menu.menu_image} />
                                </div>

                                <div className="ms-card-body">
                                  <div className="col-md-12">
                                    <h5 className="section-title bold">
                                      {menu.menu_identifier.toUpperCase()}
                                    </h5>
                                    <h6 className="section-title font-italic font-weight-light">
                                      "{menu.menu_description}"
                                    </h6>
                                  </div>

                                  <table className="table ms-profile-information mb-0">
                                    <tbody>
                                      <tr>
                                        <th scope="row">Total Categories</th>
                                        <td>{menu.total_category_count}</td>
                                      </tr>

                                      <tr>
                                        <th scope="row">Sort Order</th>
                                        <td>{menu.menu_sort_order}</td>
                                      </tr>

                                      <tr>
                                        <th scope="row">Buffet</th>
                                        <td>
                                          <span
                                            className={
                                              menu.is_buffet
                                                ? "badge badge-success"
                                                : "badge badge-primary"
                                            }
                                          >
                                            {menu.is_buffet ? "YES" : "NO"}
                                          </span>
                                        </td>
                                      </tr>

                                      {menu.is_buffet ? (
                                        <tr>
                                          <th>Total Cost</th>
                                          <td>{menu.total_cost}</td>
                                        </tr>
                                      ) : null}
                                    </tbody>
                                  </table>

                                  <div className="new mb-0">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        this.deleteModalShow(menu.menu_id)
                                      }
                                      className="btn grid-btn mt-0 btn-sm btn-danger btn-md btn-block m-2 products-btn"
                                    >
                                      Remove
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => this.editMenuHandler(menu)}
                                      className="btn grid-btn mt-0 btn-sm btn-primary btn-md btn-block m-2 products-btn"
                                    >
                                      Edit
                                    </button>
                                  </div>

                                  {this.props.is_setup ? (
                                    <Link
                                      className="btn btn-primary btn-md btn-block"
                                      onClick={() =>
                                        this.props.setupMenuHandler(
                                          1,
                                          menu.menu_id
                                        )
                                      }
                                    >
                                      View
                                    </Link>
                                  ) : (
                                    <Link
                                      to={`/menu-list/${menu.menu_id}`}
                                      className="btn btn-primary btn-md btn-block"
                                    >
                                      View
                                    </Link>
                                  )}

                                  <SweetAlert
                                    show={this.state.delete}
                                    title="Delete"
                                    html
                                    text="Do you want to delete ?"
                                    type="error"
                                    onConfirm={this.removeMenuHandler}
                                    onCancel={this.deleteModalClose}
                                    showCancelButton={true}
                                    showLoaderOnConfirm={true}
                                    confirmButtonText="Delete"
                                  />
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
              {this.state.update_menu}
            </div>

            {this.state.update_menu ? (
              <MenuModal
                state={this.state}
                onClickHandler={this.updateMenuHandler}
                handleModal={this.handleModal}
                title="Update Menu"
                toggleHandler={this.toggleHandler}
                handleImageChange={this.getCroppedImages}
              />
            ) : (
              <MenuModal
                state={this.state}
                onClickHandler={this.addMenuHandler}
                handleModal={this.handleModal}
                title="Add Menu"
                toggleHandler={this.toggleHandler}
                handleImageChange={this.getCroppedImages}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default Menu;
