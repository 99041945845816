import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const StyledAntSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 30,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#ffffff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 12,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
    backgroundColor: theme.palette.primary.main,
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: "#ffffff",
    boxSizing: "border-box",
  },
}));

export default function AntSwitch({ offLabel, onLabel, onChange, checked }) {
  return (
    <FormGroup sx={{ alignItems: "center" }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography
          sx={{
            fontWeight: 600,
          }}
          variant="h6"
        >
          {offLabel}
        </Typography>
        <StyledAntSwitch
          defaultChecked
          checked={checked}
          onChange={onChange}
          inputProps={{ "aria-label": "ant design" }}
        />
        <Typography
          sx={{
            fontWeight: 600,
          }}
          variant="h6"
        >
          {onLabel}
        </Typography>
      </Stack>
    </FormGroup>
  );
}
