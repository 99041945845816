import React, { Component } from "react";
import { toast } from "react-toastify";
import * as suiteServiceAPI from "../../services/suite/suiteServiceAPI";
import Breadcrumb from "./Breadcrumb";
import { Fragment } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Paper,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import * as schema from "../../utils/Schema";
import { Form, Formik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import Loader from "../../shared/Loader";
import Typography from "@mui/material/Typography";
import TimePicker from "../../components/commonComps/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InputAdornment from "@mui/material/InputAdornment";
import { Switch } from "antd";
import { Field } from "formik";
import PhoneInputField from "../SignUp/PhoneInputField";
import {getCountrySymbol} from "../../config";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

class index extends Component {
  state = {
    isLoading: false,
    initialValues: {},
    isLoading: false,
    shifts: [],
    unBookedSuites: [],
    shift: null,
    discount: null,
    businessTypes: [],
    costType: "",
    selectedBusinessType: "",
    selectedDiscountVoucher: "",
    page: 0,
    rowsPerPage: 10,
    sortColumn: "discount_code",
    sortDirection: "asc",
    open: false,
    dId: "",
    discount_code: "",
    discountList: [],
    showForm: false,
    showFormDialog: false,
    is_editable: true,
    suiteId: "",
    start: dayjs(new Date()).startOf("month"),
    end: dayjs(new Date()),
    selectedValue: "",
    pickDate: dayjs(new Date()),
    is_offline: false,
    customer_phone_number: "",
    min: dayjs(),
  };

  columns = [
    {
      name: "Order No",
      selector: "order_number",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {row.order_number}</div>,
    },
    /*  {
      name: "Order Id",
      selector: "order_id",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>{row.order_id}</div>
      ),
    }, */
    {
      name: "Suite Name",
      selector: "suite_name",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {row.suite_name}</div>,
    },

    /*   {
      name: "Suite Id",
      selector: "suite_id",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>{row.suite_id}</div>
      ),
    }, */

    {
      name: "Booking Status",
      selector: "booking_status",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div>{row.booking_status}</div>,
    },

    {
      name: "Booking Date",
      selector: "booking_date",
      justifyContent: "center",
      cell: (row) => <div>{row.booking_date}</div>,
    },

    {
      name: "Created Date",
      selector: "created_date",
      justifyContent: "center",
      cell: (row) => <div>{row.created_date}</div>,
    },

    {
      name: "Action",
      selector: "action",
      justifyContent: "center",
      width:"300px",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            onClick={() => this.editSuite(row)}
            aria-label="Edit"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => this.handleClickOpen(row.booking_id)}
            aria-label="delete"
            size="large"
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>

          {/* <IconButton
            color="primary"
            onClick={() => this.handleBookingDownload(row.order_id)}
            aria-label="download"
            size="large"
          >
            <DownloadForOfflineIcon fontSize="inherit" />
          </IconButton> */}
         <Button variant="outlined" startIcon={<DownloadForOfflineIcon color="primary" />} onClick={() => this.handleBookingDownload(row.order_id)}>Download</Button>
        </div>
      ),
    },
  ];
   
  customStyles = {
    rows: {
      style: {
        //          minHeight: '70px', // override the row height
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "65px",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "auto",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        justifyContent: "center",
        "&:hover div": {
          backgroundColor: "transparent",
          color: "white !important",
        },
        div: {
          color: "white !important",
        },
        span: {
          color: "white !important",
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "2px", // override the cell padding for data cells
        paddingRight: "2px",
        pointer: "cursor",
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  componentDidMount = () => {
    this.setInitalValues();
    this.fetchAllSuites();
    this.fetchUnbookedSuites();
  };

  handleClickOpen = (suiteId) => {
    this.setState({ open: true, suiteId: suiteId, showFormDialog: true });
  };

  handleBookingDownload = (orderId) => {

    suiteServiceAPI.getSuiteBookingReport(orderId)
      .then((response) => {
        console.log(response);
        if (response) {
          console.log(response);
          const file = new Blob([response.data], { type: "application/pdf" });
          console.log(response.request.getAllResponseHeaders());
          const fileURL = URL.createObjectURL(file); //Open the URL on new Window
          this.setState({ isPdfLoading: false, zReport: fileURL }, () => {
            this.handleExportCSV();
          });
        } else {
          toast.warn("Report not generated.");
          this.setState({
            isPdfLoading: false,
          });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false })
        this.setState({ isPdfLoading: false, zReport: "" });
        toast.error(error);
        console.log(error);
      });
  };

  handleExportCSV = () => {
    if (this.state.zReport) {
      var link = document.createElement("a");
      link.href = this.state.zReport;
      link.download = "booking";
      link.click();
    } else {
      toast.warn("No report found.");
    }
  };


  handleCancel = () => {
    this.setState(
      {
        shift: null,
        showForm: false,
      },
      () => {
        this.setInitalValues();
      }
    );
  };

  handleClose = () => {
    this.setState({ open: false, dId: "" });
  };

  handleDescription = () => {
    console.log("handle desc.");
  };

  setInitalValues = () => {
    const { shift } = this.state;

    let initialValues = {};

    initialValues = {
      suite_id: shift?.suite_id || "",
      customer_email: shift?.customer_email || "",
      confirmation_email: shift?.confirmation_email || "",
      customer_phone_number: shift?.customer_phone_number ||"",
      customer_username:shift?.customer_username||"",
    };

    this.setState({ initialValues });
  };

  fetchAllSuites = async () => {
    try {
      this.setState({ isLoading: true });
      let propertId = this.props.propertyId;
      let payload = {
        start_date: this.state.start.format("DD-MM-YYYY"),
        end_date: this.state.end.format("DD-MM-YYYY"),
        property_id: propertId,
      };
      const response = await suiteServiceAPI.fetchAllBookedSuite(payload);

      if (response && response.data.status) {
        this.setState({
          shifts: response?.data?.data,
          isLoading: false,
        });
        // toast.success("shifts fetched")
      } else {
        this.setState({ shifts: [], isLoading: false });
      }
    } catch (error) {
      this.setState({ shifts: [], isLoading: false });
      throw error;
    }
  };

  fetchUnbookedSuites = async () => {
    try {
      //  this.setState({ isLoading: true });
      let propertId = this.props.propertyId;
      let payload = {
        date: this.state.pickDate.format("DD-MM-YYYY"),
        property_id: propertId,
      };
      const response = await suiteServiceAPI.fetchAllUnBookedSuite(payload);

      if (response && response.data.status) {
        this.setState({
          unBookedSuites: response?.data?.data,
          isLoading: false,
        });
        this.setInitalValues();
        // toast.success("shifts fetched")
      } else {
        this.setState({ unBookedSuites: [], isLoading: false });
      }
    } catch (error) {
      this.setState({ unBookedSuites: [], isLoading: false });
      throw error;
    }
  };

  initiateSuiteBooking = (values, resetForm) => {
    suiteServiceAPI
      .intitiateBooking(values)
      .then((response) => {
        if (response.data.status) {
          toast.success("Suite Booked.");
          resetForm({ values: "" });
          this.setState(
            { shift: null, isLoading: false, showForm: false },
            () => {
              this.setInitalValues();
            }
          );
          this.fetchAllSuites();
        } else {
          this.setState(
            { /* shift: null, */ showForm: false, isLoading: false },
            () => {
              //this.setInitalValues();
            }
          );
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not intitiate booking. ");
      });
  };

  updateSuite = (values, resetForm) => {
    suiteServiceAPI
      .updateBooking(values)
      .then((response) => {
        if (response.data.status) {
          toast.success("Booking updated successfully. ");
          resetForm({ values: "" });
          this.setState(
            { shift: null, showForm: false, isLoading: false },
            () => {
              this.setInitalValues();
            }
          );
          this.fetchAllSuites();
        } else {
          this.setState({ showForm: false, isLoading: false });
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
        toast.warn("Could not update suite.. ");
      });
  };
  editSuite = (shift) => {
    this.setState(
      {
        shift,
        showForm: true,
        pickDate: dayjs(shift.created_date),
        unBookedSuites: [
          { suite_id: shift.suite_id, suite_name: shift.suite_name },
        ],
        selectedValue: shift.suite_id,
      },
      () => {
        this.setInitalValues();
      }
    );
  };
  handleSuitetSubmit = (values, resetForm) => {
    if (!this.state.shift) {
      this.setState({ isLoading: true });
      delete values.dialCode;
      values["customer_phone_number"] = values.dialCode
        ? values.dialCode + this.state.customer_phone_number
        : this.state.customer_phone_number;
      values["property_id"] = this.props.propertyId;
      values["booking_date"] = this.state.pickDate.format("DD-MM-YYYY");
      this.initiateSuiteBooking(values, resetForm);
    } else {
      delete values.is_offline;
      delete values.dialCode;
      values["customer_phone_number"] = values.dialCode
        ? values.dialCode + this.state.customer_phone_number
        : this.state.customer_phone_number;
      values["booking_id"] = this.state.shift.booking_id;
      values["booking_date"] = this.state.pickDate.format("DD-MM-YYYY");
      this.updateSuite(values, resetForm);
    }
  };

  deleteBooking = (bookingId) => {
    if (bookingId) {
      suiteServiceAPI
        .deleteBooking(bookingId)
        .then((response) => {
          if (response.data.status) {
            toast.success("Booking deleted.");
            this.setState({ open: false, isLoading: false });
            this.fetchAllSuites();
          } else {
            const message = response.data.message || "Couldn't delete.";
            toast.warn(message);
            this.setState({ isLoading: false, open: false, showForm: false });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, open: false });
          toast.error("Something went wrong. ");
        });
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleViewInstructions = () => {
    window.scroll({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  showForm = () => {
    if (!this.state.showForm) {
      this.setState({
        showForm: true,
        pickDate:""
      });
    } else {
      this.setState(
        {
          showForm: false,
          shift: null,
          is_editable: true,
          selectedValue: null,
        },
        () => {
          this.setInitalValues();
        }
      );
    }
  };

  handleToDate = (e) => {
    let toDat = dayjs(e);
    this.setState({ end: toDat }, () => {
      this.fetchAllSuites();
    });
  };

  handleFromDate = (e) => {
    let toDat = dayjs(e);
    this.setState({ start: toDat }, () => {
      this.fetchAllSuites();
    });
  };

  handlePickDate = (e) => {
    let toDat = dayjs(e);
    this.setState({ pickDate: toDat }, () => {
      this.fetchUnbookedSuites();
    });
  };

  handleChange = (event) => {
    this.setState({ selectedValue: event.target.value });
  };

  setPhone = (val) => {
    this.setState({
      customer_phone_number: val.dialCode
        ? val.dialCode + val.phone
        : val.phone,
    });
  };

  render() {
    const {
      submitting,
      initialValues,
      showForm,
      showFormDialog,
      pickDate,
      businessTypes,
      selectedValue,
      unBookedSuites,
      is_offline,
    } = this.state;

    const { classes } = this.props;

    const options = [
      { label: "Option 1", value: "option1" },
      { label: "Option 2", value: "option2" },
      { label: "Option 3", value: "option3" },
    ];

    return (
      <>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <Breadcrumb />
            </div>
            <div className="col-md-12">
              <div className="ms-panel">
                <div className="ms-panel-header">
                  {showFormDialog && (
                    <Dialog
                      open={this.state.open}
                      onClose={this.handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Delete Booking?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Do you really want to proceed?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.handleClose}>No</Button>
                        <Button
                          onClick={() => this.deleteBooking(this.state.suiteId)}
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                  {!showForm && (
                    <React.Fragment>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6} lg={6}>
                          <h5>Suite Details</h5>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.showForm}
                          >
                            Book Suite
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                              <MobileDatePicker
                                label="From"
                                value={this.state.start || new Date()}
                                inputFormat="DD-MM-YYYY"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <CalendarMonthIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                maxDate={this.state.max}
                                // minDate={this.state.min}
                                onChange={this.handleFromDate}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                              <MobileDatePicker
                                label="To"
                                value={this.state.end || new Date()}
                                inputFormat="DD-MM-YYYY"
                                onChange={this.handleToDate}
                                maxDate={this.state.max}
                                // minDate={this.state.min}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <CalendarMonthIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}

                  {showForm && (
                    <React.Fragment>
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ margin: "10px" }}
                      >
                        <div style={{}}>
                          Book Suite
                          <Button
                            variant="contained"
                            style={{ float: "right" }}
                            color="primary"
                            onClick={this.showForm}
                          >
                            Back
                          </Button>
                        </div>
                      </Typography>
                      <p style={{ margin: "10px" }}>
                        Complete the following details.
                      </p>
                    </React.Fragment>
                  )}
                </div>
                <div className="ms-panel-body"></div>
                {showForm && (
                  <React.Fragment>
                    <Grid
                      container
                      sx={{ display: { xs: "block", md: "flex" } }}
                      alignItems="center"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          mb: 2,
                          ml: "20px",
                          width: "220px",
                          height: "40px",
                          display: { xs: "block", md: "none" },
                        }}
                        onClick={this.handleViewInstructions}
                      >
                        View Instructions
                      </Button>{" "}
                      <Grid item xs={12} md={8} lg={8}>
                        <Formik
                          enableReinitialize
                          initialValues={initialValues}
                          validationSchema={schema.bookSuiteFormSchema}
                          onSubmit={(values, { resetForm }) => {
                            values["username"] = this.props.user.username;
                            this.handleSuitetSubmit(values, resetForm);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              <Box sx={{ margin: "30px" }}>
                                <Grid container spacing={3} direction="row">
                                  <Grid item xs={12} md={6} lg={6}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Stack spacing={3}>
                                        <MobileDatePicker
                                          label="Pick a date *"
                                          value={
                                            this.state.pickDate || new Date()
                                          }
                                          inputFormat="DD-MM-YYYY"
                                          minDate={this.state.min}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="start">
                                                <CalendarMonthIcon />
                                              </InputAdornment>
                                            ),
                                          }}
                                          maxDate={this.state.max}
                                          // minDate={this.state.min}
                                          onChange={this.handlePickDate}
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                        />
                                      </Stack>
                                    </LocalizationProvider>
                                  </Grid>

                                  <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                      <InputLabel>Select Suite *</InputLabel>
                                      <Select
                                        value={selectedValue}
                                        onChange={(event) => {
                                          setFieldValue(
                                            "suite_id",
                                            event.target.value
                                          ); 
                                          this.setState({
                                            selectedValue: event.target.value,
                                          }); 
                                        }}
                                        label="Select Suite *"
                                        name="suite"
                                      >
                                        {/* <MenuItem value="">
                                          <em>Select Suite</em>
                                        </MenuItem> */}
                                        {unBookedSuites.map((option) => (
                                          <MenuItem
                                            key={option.suite_id}
                                            value={option.suite_id}
                                          >
                                            {option.suite_name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      <FormHelperText error>
                                        {touched.suite_id && errors.suite_id
                                          ? errors.suite_id
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                      <TextField
                                        error={
                                          touched.customer_username &&
                                          errors.customer_username
                                            ? true
                                            : false
                                        }
                                        name="customer_username"
                                        label="Customer Name *"
                                        autoComplete="customer_username"
                                        type="text"
                                        variant="standard"
                                        value={values.customer_username || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        aria-describedby="customer-username-error-text"
                                      />

                                      <FormHelperText error>
                                        {touched.customer_username &&
                                        errors.customer_username
                                          ? errors.customer_username
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                      <TextField
                                        error={
                                          touched.customer_email &&
                                          errors.customer_email
                                            ? true
                                            : false
                                        }
                                        name="customer_email"
                                        label="Email *"
                                        autoComplete="customer_email"
                                        type="text"
                                        variant="standard"
                                        value={values.customer_email || ""}
                                        onChange={handleChange}
                                        aria-describedby="component-error-text"
                                      />

                                      <FormHelperText error>
                                        {touched.customer_email &&
                                        errors.customer_email
                                          ? errors.customer_email
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <Field
                                      id="customer_phone_number"
                                      name="customer_phone_number"
                                      country={
                                        getCountrySymbol(
                                          localStorage.getItem("currency_code")
                                        )?.toLowerCase() || "gb"
                                      }
                                      value={values.customer_phone_number}
                                      type="text"
                                      maxlength="5"
                                      inputStyle={{ width: "100%",height:"50px" }}
                                      inputClass={
                                        errors.customer_phone_number &&
                                        touched.customer_phone_number
                                          ? "error-phone"
                                          : null
                                      }
                                      component={PhoneInputField}
                                      setPhone={this.setPhone}
                                      handleBlur={handleBlur}
                                      aria-describedby="phone-error-text"
                                    />
                                    <FormHelperText error>
                                      {touched.customer_phone_number &&
                                      errors.customer_phone_number
                                        ? errors.customer_phone_number
                                        : null}
                                    </FormHelperText>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                      <TextField
                                        error={
                                          touched.confirmation_email &&
                                          errors.confirmation_email
                                            ? true
                                            : false
                                        }
                                        name="confirmation_email"
                                        label="Payment confirmation Email *"
                                        autoComplete="confirmation_email"
                                        type="text"
                                        variant="standard"
                                        value={values.confirmation_email || ""}
                                        onChange={handleChange}
                                        aria-describedby="component-error-text"
                                      />

                                      <FormHelperText error>
                                        {touched.confirmation_email &&
                                        errors.confirmation_email
                                          ? errors.confirmation_email
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                
                                

                                  <Grid item xs={12} md={6} lg={6}>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      variant="contained"
                                      block
                                      sx={{
                                        mt: 2,
                                        mb: 2,
                                        width: "300px",
                                        height: "40px",
                                      }}
                                    >
                                      {this.state?.shift ? "Update" : "Submit"}
                                    </Button>
                                  </Grid>
                                  {this.state?.shift && (
                                    <Grid item xs={12} md={5} lg={5}>
                                      <Button
                                        type="button"
                                        color="primary"
                                        variant="contained"
                                        block
                                        onClick={() => this.handleCancel()}
                                        sx={{
                                          mt: 2,
                                          mb: 2,
                                          width: "300px",
                                          height: "40px",
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            </Form>
                          )}
                        </Formik>{" "}
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <Box sx={{ margin: "20px" }}>
                          <Paper
                            elevation={3}
                            sx={{
                              width: "auto",
                              marginBottom: "20px",
                            }}
                          >
                            <Card>
                              <Typography
                                variant="h5"
                                gutterBottom
                                style={{
                                  background: "#5773ff",
                                  textAlign: "center",
                                  color: "white",
                                  margin: "0px",
                                  padding: "6px 0px",
                                }}
                              >
                                Instructions
                              </Typography>
                              <CardContent>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Typography color="textPrimary" paragraph>
                                    <b>You can book a suite in this section.</b>
                                  </Typography>
                                </Box>
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems="center"
                                  sx={{ my: 1 }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    style={{ textAlign: "left" }}
                                  >
                                    <Typography
                                      sx={{
                                        padding: "5px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      <b>Pick a date .</b>
                                    </Typography>
                                    <Typography
                                      sx={{
                                        padding: "5px",
                                      }}
                                    >
                                      Pick a date when you want to book suite.{" "}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    style={{ textAlign: "left" }}
                                  >
                                    <Typography
                                      sx={{
                                        padding: "5px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      <b>Select Suite.</b>
                                    </Typography>
                                    <Typography
                                      sx={{
                                        padding: "5px",
                                      }}
                                    >
                                      Select Suite{" "}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    style={{ textAlign: "left" }}
                                  >
                                    <Typography
                                      sx={{
                                        padding: "5px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      <b>Email Id.</b>
                                    </Typography>
                                    <Typography
                                      sx={{
                                        padding: "5px",
                                      }}
                                    >
                                      Email Id of the customer.
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    style={{ textAlign: "left" }}
                                  >
                                    <Typography
                                      sx={{
                                        padding: "5px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      <b>Payment confirmation email.</b>
                                    </Typography>
                                    <Typography
                                      sx={{
                                        padding: "5px",
                                      }}
                                    >
                                      Email id to which the payment confirmation
                                      will be sent.
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    style={{ textAlign: "left" }}
                                  >
                                    <Typography
                                      sx={{
                                        padding: "5px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      <b>Phone No.</b>
                                    </Typography>
                                    <Typography
                                      sx={{
                                        padding: "5px",
                                      }}
                                    >
                                      Phone no of the customer.
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Paper>
                        </Box>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}

                {!showForm && (
                  <div className="ms-invoice-table table-responsive mt-2">
                    <DataTable
                      columns={this.columns}
                      customStyles={this.customStyles}
                      pagination
                      paginationRowsPerPageOptions={[
                        10, 25, 50, 100, 500, 1000,
                      ]}
                      data={this.state.shifts}
                    />
                  </div>
                )}
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default withStyles(styles)(connect(mapStateToProps, {})(index));
