import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Line as LineChart } from 'react-chartjs-2';

import * as DashboardServiceAPI from "../../../services/dashboard/DashboardServiceAPI";

import * as PaymentServiceAPI from "../../../services/payment/PaymentServiceAPI";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import Loader from "../../../shared/Loader";
import { connect } from "react-redux";
import dayjs from 'dayjs';

//const summary = null;


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));



class Chart extends Component {
    constructor(props) {
        super(props)
        console.log(this.props)
      /*   this.state = {
            summary: {},
            processingTime: 0,
            open: true,
            ayden: {},
        } */
    };

    componentDidMount = () => {

    
    };

    render() {
        return (
            <div className="row">


                {this.props.state.errors ?
                    <div className="col-xl-12 col-md-12">
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="warning">{this.props.state.errors?.replaceAll('"', '').includes("ownership") ? "Ownership details incomplete. " :  this.props.state.errors?.replaceAll('"', '')  }</Alert>
                        </Stack>

                        <br />
                    </div> : ""}

                {this.props.state.isLoading ? (
                    <div style={{ marginLeft: "auto" , marginRight:  "auto" , marginBottom: "40px" }} >   <Loader  size={50}  /> </div>

                ) : (
                    <React.Fragment>

                        <div className="col-xl-3 col-md-6">
                            <HtmlTooltip title={this.props.state.descrption} placement="top" >
                                <Link to="/setup">
                                    <div className="ms-panel ms-panel-hoverable has-border ms-widget ms-has-new-msg ms-notification-widget" style={{ background: this.props.state.color }}>
                                        <div className="ms-panel-body media">
                                            <strong><i className="">  < FlipCameraAndroidIcon /></i>  </strong>
                                            <div className="media-body" ><h6 style={{ color: "#FFFFFF", fontSize: "0.8em", fontWeight: "bold" }} > {this.props.state.statusToShow}</h6><span style={{ color: "#FFFFFF", fontSize: "1em", fontWeight: "bold"  }}> Account Status </span></div>
                                        </div>
                                    </div>
                                </Link>
                            </HtmlTooltip>
                        </div>



                        <div className="col-xl-3 col-md-6">
                            <Link to="/orders-delivery/delivery">
                                <div className="ms-panel ms-panel-hoverable has-border ms-widget ms-has-new-msg ms-notification-widget">
                                    <div className="ms-panel-body media">
                                        <strong><i className="flaticon-stats"></i>  </strong>
                                        <div className="media-body"><h6 style={{ fontSize: "0.9em", fontWeight: "bold" }} > {this.props.state.summary.payment_successfull_orders_count ? this.props.state.summary.payment_successfull_orders_count : 0} </h6><span style={{ fontSize: "1em", fontWeight: "bold" }} >Orders today  </span></div>
                                    </div>
                                </div>
                            </Link>
                        </div>




                        <div className="col-xl-3 col-md-6">
                            <Link to="/orders-delivery/delivery">
                                <div className="ms-panel ms-panel-hoverable has-border ms-widget ms-has-new-msg ms-notification-widget">
                                    <div className="ms-panel-body media">
                                        <i className="material-icons">account_balance_wallet</i>
                                        <div className="media-body"><h6  style={{ fontSize: "0.9em", fontWeight: "bold" }}> &#163; {this.props.state.summary.total_sales_amount ? this.props.state.summary.total_sales_amount :0}  </h6><span  style={{ fontSize: "1em", fontWeight: "bold" }} > Sales today </span></div>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        <div className="col-xl-3 col-md-6">
                            <Link to="/orders-delivery/delivery">
                                <div className="ms-panel ms-panel-hoverable has-border ms-widget ms-has-new-msg ms-notification-widget">
                                    <div className="ms-panel-body media">
                                        <i className="material-icons">access_time</i>
                                        <div className="media-body"><h6  style={{ fontSize: "0.9em", fontWeight: "bold" }} > {this.props.state.processingTime ? this.props.state.processingTime : 0} Minutes</h6><span  style={{ fontSize: "1em", fontWeight: "bold" }} > Order Time </span></div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                    </React.Fragment>)}










            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    user: state.user
  });
  

export default connect(mapStateToProps , null) (Chart)