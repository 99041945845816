import React from 'react';
import { makeStyles } from "@mui/styles";
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    TextField,
} from '@mui/material';
import { Button } from "react-bootstrap";
import Jumbo from '../../../assets/img/apex.jpg';
const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5)',
    },
    media: {
        width: '350px',
        height: '350px',
        backgroundSize: "contain",
        padding: "5px"
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    model: {
        color: "grey",
        fontSize: "14px"
    },
    description: {
        marginBottom: theme.spacing(2),

        position: "relative", // Set the parent element to a relative position
        padding: "10px", // Add some padding to the element

        "&::before": {
            content: "''", // Add content to the pseudo-element
            position: "absolute",
            bottom: "0",
            width: "40%", // Adjust the width as needed
            borderBottom: "5px solid #5773ff",
        }

    },
    quantityField: {
        // marginBottom: theme.spacing(1),
        width: "100px"
    },
    addButton: {
        marginTop: 'auto',
        marginLeft: theme.spacing(5),
        color: "white"
    },
    price: {
        marginTop: '20px;',
        padding: 0,
        verticalAlign: "top",
        color: "#5773ff",
        fontSize: "26px",
        fontWeight: 600
    },
    block_price__currency: {
        padding: 0,
        margin: 0,
        verticalAlign: "top",
        color: "#5773ff",
        fontSize: "26px",
        fontWeight: 600
    }
}));

const ProductCard = ({ product }) => {
    const classes = useStyles();

    return (
      
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={product.imageUrl}
                title={product.name}
              />
              <CardContent className={classes.content}>
                <Typography variant="h6" className={classes.model}>
                  {product.model}
                </Typography>
                <Typography variant="h6" className={classes.description}>
                  {product.name}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {product.description}
                </Typography>
                <Typography className={classes.price} variant="body1" color="textSecondary">
                  £ 1000
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: "20px" }}>
                  <TextField
                    size='small'
                    label="Quantity"
                    variant="outlined"
                    type="number"
                    defaultValue={1}
                    InputProps={{
                      inputProps: {
                        min: 1, // minimum value
                      },
                    }}
                    className={classes.quantityField}
                  />
                  <Button
                    type="submit"
                    color="primary"
                    className={classes.addButton}
                    sx={{ marginLeft: '8px' }} // Add some margin for spacing
                  >
                    Add to Cart
                  </Button>
                </div>
              </CardContent>
            </Card>
         
          

    );
};

export default ProductCard;
