import React, { Fragment, forwardRef } from "react";
import "react-phone-input-2/lib/style.css";
import ConfirmSignupForm from "./ConfirmSignupForm";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as message from "../../utils/Message";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import * as SecurityServiceAPI from "../../services/user/SecurityServiceAPI";
import CommonModal from "../../shared/Modal/CommonModal";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { Form, Formik, Field } from "formik";
import * as schema from "../../utils/Schema";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import countryList from "react-select-country-list";
import { FlapperSpinner } from "react-spinners-kit";
import PhoneInputField from "./PhoneInputField";
import "./phoneInput.css";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from "@mui/material/FormHelperText";

const SignupForm = (props, ref) => {

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  return (
    <Fragment>
      {props.state.confirm_signup ? (
        <ConfirmSignupForm {...props} />
      ) : (
        <div className="ms-body ms-primary-theme ms-logged-out">
          <main className="body-content">
            <div className="ms-content-wrapper ms-auth">
              <Grid container spacing={0} alignItems="center" justifyContent="center">
                <Grid item lg={6}>
                  <div className="ms-auth-sign-up-bg" alignItems="center" justifyContent="center" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <div className="ms-auth-form">
                    <Formik
                      initialValues={{
                        businessName: "",
                        phone: "",
                        email: "",
                        password: "",
                        confirmPassword: "",
                        acceptTerms: false,
                      }}
                      validationSchema={schema.signupSchema}
                      onSubmit={(values) => {
                        props.signupHandler(values);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        isValid,
                        dirty,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Box
                            sx={{
                              my: 2,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography component="h1" variant="h5">
                              Sign up
                            </Typography>
                          </Box>

                          <TextField
                            error={
                              errors.businessName && touched.businessName
                                ? true
                                : false
                            }
                            margin="normal"
                            sx={{
                              mb: 2,
                            }}

                            fullWidth
                            type="text"
                            id="businessName"
                            label="Contact Name "
                            name="businessName"
                            autoComplete="businessName"
                            size="small"
                            onBlur={handleBlur}
                            value={values.name}
                            onChange={handleChange}
                            helperText={
                              errors.businessName && touched.businessName
                                ? errors.businessName
                                : null
                            }
                          />

                          <Field
                            id="phone"
                            label="Phone"
                            name="phone"
                            country="gb"
                            type="text"
                            maxlength="5"
                            inputStyle={{ width: "100%" }}
                            inputClass={
                              errors.phone && touched.phone
                                ? "error-phone"
                                : null
                            }
                            component={PhoneInputField}
                            setPhone={props.setPhone}
                            handleBlur={handleBlur}
                            aria-describedby="phone-error-text"
                          />
                          {errors.phone && touched.phone ? (
                            <div
                              style={{ paddingLeft: "15px" }}
                              className="error-message"
                            >
                              {errors.phone}
                            </div>
                          ) : null}

                          <TextField
                            error={errors.email && touched.email ? true : false}
                            margin="normal"

                            fullWidth
                            type="email"
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            size="small"
                            onBlur={handleBlur}
                            value={values.email}
                            onChange={handleChange}
                            aria-describedby="component-error-text"
                            helperText={
                              errors.email && touched.email
                                ? errors.email
                                : null
                            }
                          />

                          <TextField
                            error={
                              errors.password && touched.password ? true : false
                            }
                            margin="normal"

                            fullWidth
                            name="password"
                            label="Password"
                            type={
                              props.state.toggle_password ? "text" : "password"
                            }
                            id="password"
                            size="small"
                            autoComplete="current-password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            helperText={
                              errors.password && touched.password
                                ? errors.password
                                : null
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {props.state.toggle_password ? (
                                    <Visibility
                                      onClick={props.togglePasswordHide}
                                    />
                                  ) : (
                                    <VisibilityOff
                                      onClick={props.togglePasswordHide}
                                    />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            error={
                              errors.confirmPassword && touched.confirmPassword
                                ? true
                                : false
                            }
                            margin="normal"

                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type={
                              props.state.toggle_password1 ? "text" : "password"
                            }
                            id="confirmPassword"
                            size="small"
                            autoComplete="current-password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            helperText={
                              errors.confirmPassword && touched.confirmPassword
                                ? errors.confirmPassword
                                : null
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {props.state.toggle_password1 ? (
                                    <Visibility
                                      onClick={props.togglePasswordConfirmHide}
                                    />
                                  ) : (
                                    <VisibilityOff
                                      onClick={props.togglePasswordConfirmHide}
                                    />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />

                          <br /> <br />


                          <Typography variant="body2" component="div">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="acceptTerms"
                                  value={values.acceptTerms}
                                  color="primary"
                                  onChange={handleChange}
                                //onClick={handleClickOpen('paper')}
                                />
                              }
                              label={
                                <span>
                                  By ticking this box, you accept the
                                  <Link
                                    component="button"
                                    onClick={handleClickOpen('paper')}
                                    style={{ cursor: 'pointer', textDecoration: 'underline', background: "none", border: "none", padding: 0, color: "#5773ff" }}
                                  >
                                    Terms & Conditions
                                  </Link>
                                </span>
                              }
                            />
                            <FormHelperText error>
                              {touched.acceptTerms && errors.acceptTerms ? errors.acceptTerms : null}
                            </FormHelperText>
                          </Typography>

                          <br />  <br />


                          <Dialog
                            open={open}
                            onClose={handleClose}
                            scroll={scroll}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                          >
                            <DialogTitle id="scroll-dialog-title">Terms and Conditions</DialogTitle>
                            <DialogContent dividers={scroll === 'paper'}>
                              <DialogContentText
                                id="scroll-dialog-description"
                                ref={descriptionElementRef}
                                tabIndex={-1}
                              >
                                {/*        {[...new Array(50)]
                                  .map(
                                    () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                                  )
                                  .join('\n')} */}
                                <Typography variant="body1">
                                  This Customer Agreement (the “Agreement”) sets out the terms and conditions (the “Terms”) by which Kayana World Limited (“we”, “our”, “Kayana” or the “Company”) provides access to our Cloud Software and/or Hardware Services, which you (“you”, “your”, “the User” or “the Customer”) purchase and/or subscribe to—each is a “Party” and collectively the “Parties” to this Agreement.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  Please read these Terms and Conditions carefully before using the Software and/or Hardware provided by Kayana World Limited.
                                </Typography>
                                <Typography variant="h6">1. Terms of Use</Typography>
                                <Typography variant="body2" paragraph>
                                  1.1. By using any company services, you agree to be bound by the Terms herein. If you disagree with these Terms, do not use company services.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  1.2. These terms and conditions apply to all users who receive access to Cloud Software and/or Hardware services provided by Kayana, regardless of whether they pay a subscription fee. Any terms, conditions, or representations (other than those made fraudulently or implied by statute) are excluded.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  1.3. We reserve the right, at any time, to amend, modify, alter, or update the terms and conditions of this agreement. We will endeavour to notify the users of such changes via email or through our app. If you continue to use the Company’s services, your use will be governed by the updated terms.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  1.4. These Terms do not create an agency, partnership, joint venture, or employment, and the User has no authority to bind the Company in any respect whatsoever.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  1.5. Subject to the terms herein, all rights conferred by these Terms are granted to the User in the business as long it is not restricted by our payment processor. Further information can be found here on the list of firms we accept. No part of the Software may be used for any other purposes, including, but not limited to, research, study, competitor analysis or any other activity prohibited by this agreement.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  1.6. By agreeing to the terms and conditions herein, the User warrants that they are not and will not be deemed a ‘consumer’ as defined in The Consumer Contracts (Information, Cancellation and Additional Charges) Regulations 2013.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  These terms were last updated on 7th June 2024.
                                </Typography>


                                <Typography variant="h6">2. Definitions and Interpretations</Typography>
                                <Typography variant="body2" paragraph>
                                  2.1. Kayana World Limited specialises in software development, distinguishing itself from website development consultancies. As a Software as a Service (SaaS) provider, we host applications on our servers, allowing customers to access these services over the internet. This means that while the software resides on our servers, users can access it remotely.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  2.2. Kayana Services shall refer to any Cloud Software services, including, but not limited to, Electronic Point of Sales (ePOS), web ordering, Self-Service apps, mobile apps ordering, website ordering as well as QR Code ordering, and/or any Hardware services provided to the User by the Company (each a “Service” and collectively the “Services”).
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  2.3. "Consumer" or "End User" denotes the User's client who purchases services from the User through our platform.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  2.4. “Business Day” is a day other than a Saturday, Sunday, or public holiday in England when banks in London are open for business. “Business Hours” refers to the hours between 9am and 5pm on a Business Day.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  2.5. “Platform” means the technology (hardware and software) provided by Kayana, a SaaS company, to facilitate ordering & payments. The products and services that we provide through our Platform, regardless of whether fees are charged, are also referred to as “Platform Services”.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  2.6. A “Platform Fee” means a fee paid by the End User to Kayana, under the Platform Fees Model (Section 12), in consideration for each individual transaction processed via the Kayana Platform, in addition to any order and delivery fees.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  2.7. A “Platform Charge” means a fee paid by the User to Kayana, in consideration for each individual transaction processed using web ordering, mobile apps ordering and/or QR Code ordering services under the Platform Charges Model (Section 13). Platform Charges are not Platform Fees.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  2.8. A "License Software Fee" refers to the minimum monthly or annual charge, if applicable, that the User pays Kayana for access to our Cloud Software.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  2.9. "Payment Service Providers" facilitate connections between Users (who may also be referred to as "Merchants" in the context of payment processing) and their consumers, card brand networks, and financial institutions. This ensures a seamless transaction process. For further information on Payment Service Providers and payment processing, please refer to Sections 14 and 15.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  2.10. Users who have integrated Kayana payments powered by Adyen as their Payment Service Provider are referred to as “Sub Merchant Accounts”. The User is required to agree to the terms and conditions of a Adyen Account Agreement to create a Sub Merchant Accounts. Kayana is not the merchant of record.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  2.11. "Personal Data" refers to any information that relates to a natural person who can be identified, known as the "Data Subject". This includes any individual who can be distinguished, directly or indirectly, by unique identifiers like a name, an ID number, location details, an online identifier, or through characteristics specific to their physical, physiological, genetic, mental, economic, cultural, or social identity.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  2.12. "Confidential Information" refers to specific information and materials designated as "confidential" or "proprietary," or that a reasonable person in business would consider being confidential or proprietary. This includes, but is not limited to, information related to a disclosing party’s or its Group’s operations, products, business strategies, trade secrets, methodologies, potential products and services, processes, clientele, business associations, marketing strategies, strategic planning, innovative ideas, research and development data, cost structures, technologies, financial data, and pricing strategies.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  2.13. "Virus" denotes any entity, including software, code, files, or programs, capable of hindering, damaging, or negatively impacting the functionality of computer software, hardware, or networks; telecommunications services, equipment, or networks; or any other service or device. It also encompasses actions that obstruct, deteriorate, or adversely influence access to or
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  2.14. Section headings shall not affect the interpretation of these Terms.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  2.15. Unless the context otherwise requires, words in the singular shall include the plural, and in the plural shall include the singular.
                                </Typography>

                                <Typography variant="h6">3. Account Information</Typography>
                                <Typography variant="body2" paragraph>
                                  3.1. You must be at least 18 years old to purchase and/or subscribe for a licence to access and use our Cloud Software and/or Hardware services. You agree to provide accurate, current and complete information during the subscription process and to update such information to keep it accurate, current and complete. Any significant updates to the User’s personal account information, governed by the Data Protection Act 2018, shall be requested in writing to info@kayana.co.uk with a valid reason for the requested changes. Kayana, in its sole discretion and where deemed necessary, reserves the right to request proof of any changes with relation to personal data.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  3.2. We reserve the right to suspend or terminate your subscription and access to our Cloud Software Services should the information provided during the subscription process, or at any point thereafter, prove to be inaccurate, outdated, or incomplete.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  3.3. Upon termination, the Company will permanently erase (or limit the processing of) the User’s account and all related data. The Company bears no responsibility for any data associated with the User’s account following its termination.
                                </Typography>

                                <Typography variant="h6">4. Data Protection and Security</Typography>
                                <Typography variant="body2" paragraph>
                                  4.1. The Company is committed to employing robust security measures to safeguard the User's personal data and ensure the highest level of confidentiality, protecting the User from unauthorised access to their account and the data stored on the Company Cloud Software. However, it is important to note that no security system is infallible. While we strive to protect your account, account information, and data, we cannot provide an absolute guarantee against the possibility of security breaches. Third-party "attackers" may still find ways to access the Services or their content illegally. By submitting information through the Services, the User acknowledges and accepts the inherent risks of such actions, including the potential for unauthorised access to the Services, their account information, and any other information provided to the Company or via the Software.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  4.2. To clarify roles and responsibilities, the User will typically act as the "Data Controller" for any personal data provided to Kayana for the purpose of delivering our services. The Data Controller is responsible for setting the purposes and means of processing personal data, whereas the Data Processor handles the data on the Data Controller's behalf. In this arrangement, Kayana functions as the "Data Processor," managing personal data for the User under the framework of the Kayana services. Such data management by the User is subject to the Data Protection Act 2018. Kayana will not be liable for any breaches of data protection legislation committed by the User.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  4.3. As a Data Processor, Kayana is limited to handling data presently housed in the Cloud Software. Should data be permanently altered or erased from the Cloud Software or the User’s account, Kayana bears no liability for the loss or retrieval of such data.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  4.4. Data controllers bear the responsibility for implementing appropriate technical and organisational measures to ensure and prove that their data processing aligns with the Data Protection Act 2018. They must adhere to principles of lawfulness, transparency, fairness, data minimisation, purpose limitation, and accuracy. Additionally, fulfilling data subject requests falls squarely on the shoulders of the Data Controller.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  4.5. Kayana has no authority over the data protection notices, policies, and terms and conditions of the user. Therefore, the User will forfeit and keep Kayana and its affiliates indemnified against all losses, costs, and liabilities and all expenses incurred, including reasonable legal or other professional expenses, arising out of or in connection with any claim regarding:
                                  4.5.1. a breach of the Data Protection Act 2018, or
                                  4.5.2. any whatsoever liability emerging in respect of the cookies or
                                  4.5.3. the capture of Personal Data through the User’s website(s), or
                                  4.5.4. the agreement of data subjects for dispersing any Personal Data outside of the European Economic Area by Kayana.
                                  4.5.5. Any liability under this subsection will not be subject to any limitation or exclusions of liability under the Agreement.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  4.6. Kayana is committed to promptly notifying the Data Controller, within fourteen (14) days, should Kayana or any sub-processor detect a breach affecting personal data. At such time, Kayana will furnish the Customer with all necessary information to fulfil any obligations to notify the relevant data protection authority or to inform the affected individuals in accordance with applicable Data Protection laws.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  4.7. Kayana commits to assisting the User in managing any subject access requests received from end-customers promptly and within a reasonable timeframe, at the User's expense. Kayana will implement sufficient technical and organisational measures to enable the Users to fulfil their obligations to individuals seeking access to their personal data held by Kayana. However, the Company's support for subject access requests is confined to data currently stored by the User on the Cloud Software. It is important to note that Kayana is unable to recover any data that has been previously altered or deleted from the Cloud.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  4.8. The User grants Kayana permission to appoint any of its subcontractors as subprocessors without the need for additional approval. Hence, these subcontractors are deemed authorised under this provision. At any point, the User has the right to request a comprehensive list of the current subprocessors.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  4.9. The Company will not be responsible for any losses resulting from unauthorised access to a User's account. The User agrees to protect, compensate, and absolve the Company, including its officers, directors, shareholders, employees, distributors, agents, partners, licensors, information providers, and account providers, from any liabilities arising out of improper, unauthorised, or illegal use of their account.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  4.10. A user's login credentials are strictly personal and must not be shared with others. Organisations are encouraged to create individual logins for each user as needed. It is the responsibility of each user to secure their account login details and passwords. Furthermore, each user must agree not to share their password with any third party and to accept full responsibility for any activities or actions that occur under their account, whether authorised or not.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  4.11. The user is obliged to promptly inform the Company of any unauthorised access to their account or any other security breaches by contacting info@Kayana.co.uk.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  4.12. Right to Erasure or Restriction of Processing as per Section 47 of the Data Protection Act 2018: Users have the right to request the deletion of all their personal data from Kayana's user account records upon termination of this agreement if they choose to do so. Any request for erasure must be submitted in writing to info@Kayana.co.uk. In instances where personal data is required for evidentiary purposes, Kayana will not delete the data but will instead limit its processing. Furthermore, as a Data Controller, users are obligated to honour any erasure requests from their customers concerning data held on the Cloud Software. It is important to note that Kayana, acting as a Data Processor, bears no responsibility for handling erasure requests that users receive from their customers.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  4.13. Besides the conditions outlined in this Agreement, especially Section 4, it is crucial for the User to familiarise themselves with the Company’s Privacy Policy and Cookie Policy. These documents provide detailed insights into how Kayana collects and processes personal data. Utilising our services implies the User’s automatic acceptance of the Company’s Privacy Policy and Cookie Policy.
                                </Typography>

                                <Typography variant="h6" gutterBottom>
                                  5. Kayana Hardware
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.1. Purchase and Compatibility</strong>
                                  <br />
                                  Users may purchase hardware from Kayana with or without a valid Cloud Software license subscription. Compatibility with non-Kayana software is not guaranteed by Kayana. Kayana is not responsible for ensuring compatibility of its hardware with the User's software.
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.2. User Responsibility for Research</strong>
                                  <br />
                                  Users must ensure that the hardware meets their requirements before purchase. Descriptions and video demonstrations are available online at{' '}
                                  <Link href="https://www.kayana.co.uk" target="_blank" rel="noopener noreferrer">
                                    Kayana's Website
                                  </Link>
                                  . Users are encouraged to contact Kayana's Team for additional information.
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.3. License Subscription</strong>
                                  <br />
                                  Hardware purchases via Kayana's website include a one-year "Kayana Software" license subscription to the Cloud Software. Refer to Section 7 for details on Kayana subscriptions.
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.4. Payment and Pricing</strong>
                                  <br />
                                  Full upfront payment is required at the time of order. Prices are listed and charged in GBP (£).
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.5. Foreign Currency Transactions</strong>
                                  <br />
                                  Any charges for transactions in foreign currency, other than GBP (£), shall be payable by the User.
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.6. Assembly and Installation</strong>
                                  <br />
                                  All hardware comes flat packed with instruction manuals/guides for assembly. Kayana does not send engineers for installation or troubleshooting at the User's location.
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.7. Warranty</strong>
                                  <br />
                                  All hardware provided by Kayana includes a one (1) year basic manufacturing warranty from the delivery dispatch date.
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.7.1. Technical Support</strong>
                                  <br />
                                  In case of technical faults, Kayana will troubleshoot remotely or request the faulty hardware to be returned via the Warranty Returns Process in Section 19.
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.7.2. Replacement Policy</strong>
                                  <br />
                                  Kayana reserves the right to repair the faulty equipment multiple times before offering a replacement or refund. A replacement will only be provided if the equipment is non-repairable.
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.7.3. Refurbished Replacements</strong>
                                  <br />
                                  Replacement hardware may be refurbished with the same or better technical specifications as the original hardware.
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.7.4. Warranty Duration</strong>
                                  <br />
                                  The warranty period remains one (1) year from the initial delivery dispatch date, regardless of any replacements.
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.7.5. Exclusions</strong>
                                  <br />
                                  Theft, fire, tampering, and mishandling are not covered under the basic manufacturing warranty.
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.7.6. Third-Party Software</strong>
                                  <br />
                                  Kayana is not liable if hardware becomes unusable due to third-party software. No repair, replacement, or refund will be offered in such cases.
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.8. Warranty Disclaimer</strong>
                                  <br />
                                  Kayana, its subsidiaries, affiliates, and licensors do not warrant:
                                  <ul>
                                    <li>The hardware will meet the User's specific requirements.</li>
                                    <li>The quality of the software, hardware, and any other material purchased will meet all expectations.</li>
                                    <li>The hardware will not develop faults, errors, or inaccuracies.</li>
                                    <li>The hardware will be compatible with newer versions of the Cloud Software or any other software indefinitely.</li>
                                  </ul>
                                  Kayana will inform Users if technical support and updates for specific hardware versions are discontinued.
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.9. Insurance Requirement</strong>
                                  <br />
                                  Users must obtain insurance covering all hardware equipment against fire, theft, accidental damage, and other failures. Kayana is not responsible for losses resulting from hardware failures.
                                </Typography>
                                <Typography paragraph>
                                  <strong>5.10. Portable Appliance Testing (PAT)</strong>
                                  <br />
                                  The User must ensure all hardware is PAT tested regularly by a competent individual to comply with UK laws. Kayana does not perform PAT tests or reminders.
                                </Typography>

                                <Typography variant="h6" gutterBottom>
                                  6. Cloud Software
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.1. Purchase and Compatibility</strong>
                                  <br />
                                  Users can purchase our Cloud Software ("Software") licence subscription with or without hardware provided by Kayana. If the User chooses not to purchase hardware from Kayana, they acknowledge that they must have hardware fully compatible with our Cloud Software. Kayana is not responsible for ensuring compatibility of the User's hardware with our Cloud Software, nor do we provide any managed service or support in this regard.
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.2. Internet Connection</strong>
                                  <br />
                                  To ensure optimal performance and functionality of our Cloud Software, a fast, stable, and reliable internet connection is essential. Kayana recommends using a wired internet connection for improved connectivity. However, please note that this recommendation does not guarantee the performance of the User's internet provider.
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.3. Software Warranty Disclaimer</strong>
                                  <br />
                                  The Software is provided "as is" and "as available," without any warranties, whether explicit or implied. This includes, but is not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or performance. Utilization of the Software is entirely at the User's own risk.
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.4. Technical Support</strong>
                                  <br />
                                  Kayana commits to promptly resolving any technical issues reported by Users regarding the Software.
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.5. Software Performance Disclaimer</strong>
                                  <br />
                                  Kayana, its subsidiaries, affiliates, and licensors do not warrant:
                                  <ul>
                                    <li>That the Software will meet the User’s specific requirements.</li>
                                    <li>The quality of the Software, hardware, or any other material purchased will meet all expectations.</li>
                                    <li>That the Software will always be accurate, reliable, uninterrupted, timely, secure, or error-free.</li>
                                    <li>That the Software is free of viruses or other harmful components.</li>
                                    <li>That all errors in the Software will be corrected.</li>
                                  </ul>
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.6. Default Software Settings and User Responsibility</strong>
                                  <br />
                                  The Software includes default settings for taxation, promotions, and menu configuration, which Users can customize to meet their specific needs. Users are responsible for updating all necessary settings before using the Software.
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.7. Additional Software</strong>
                                  <br />
                                  Users may need to download additional software related to the Cloud Software, subject to agreeing to the respective licence agreement. Usage of such software is governed by the terms outlined in that agreement.
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.8. Data Management</strong>
                                  <br />
                                  Users must upload all menus, promotions, and relevant data to the Cloud Software. While Kayana provides guidelines for this process, it remains the User's responsibility to ensure completeness. Managed plan subscribers can receive initial setup support from their dedicated team.
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.9. Data Retention and Termination</strong>
                                  <br />
                                  Data uploaded by the User to the Cloud Software remains stored throughout the licence subscription period. Users must download any desired data before terminating their subscription, as all data is irreversibly deleted upon termination.
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.10. Data Deletion</strong>
                                  <br />
                                  Data erased from the Cloud Software at any point is irretrievable.
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.11. Backup Policy</strong>
                                  <br />
                                  Kayana performs backups of all customer data at least once every 24 hours. Users are advised to regularly back up their data independently for additional security.
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.12. Offline Functionality</strong>
                                  <br />
                                  The Software supports certain functionalities offline in case of internet outages. Users must reconnect to the internet within 30 days to securely transfer offline data to the Cloud Software. Failure to do so may result in data loss or system functionality issues.
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.13. Internet Connection Requirement</strong>
                                  <br />
                                  To fully utilize the Software's capabilities, Users must maintain a fast, stable, and reliable internet connection.
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.14. Request for New Functionalities</strong>
                                  <br />
                                  Users wishing to add new functionalities to the Software must submit a written request to Kayana. Requests are evaluated for integration feasibility with Kayana's services, though feasibility cannot be guaranteed, especially for functionalities that may infringe upon competitors' copyrights.
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.15. Cloud Server Location</strong>
                                  <br />
                                  Kayana operates a Cloud server provided by AWS. Server locations may change for financial or data security reasons. Kayana reserves the right to relocate its Cloud servers without user consent to maintain operational flexibility and data protection.
                                </Typography>
                                <Typography paragraph>
                                  <strong>6.16. Server Uptime</strong>
                                  <br />
                                  Cloud servers aim for 99% uptime. Planned server maintenance will be notified to Users in advance. In the event of unplanned server disruptions, Kayana is not liable for any consequential losses.
                                </Typography>

                                <Typography variant="h6" gutterBottom>
                                  7. Software Subscription Fees
                                </Typography>
                                <Typography paragraph>
                                  <strong>7.1. Pricing Plans and Subscription Levels</strong>
                                  <br />
                                  Kayana offers a range of pricing plans and subscription levels tailored to meet Users' specific needs. We recommend carefully reviewing all available licence subscription options, including pricing and associated restrictions, before making a purchase. Users should select a subscription tier that best fits their requirements. By using Kayana’s Software, Users agree to the terms and restrictions of their chosen subscription level and this Agreement.
                                </Typography>
                                <Typography paragraph>
                                  <strong>7.2. Hardware and Subscription</strong>
                                  <br />
                                  Any Kayana hardware purchased directly from our website includes a subscription to our Cloud Software under the "Kayana" licence.
                                  <br />
                                  <strong>7.2.1. Freemium Subscription</strong>
                                  <br />
                                  Users who download the Software application from the Google Play Store on non-Kayana devices are automatically enrolled in the “Kayana Freemium” subscription. We advise potential subscribers to explore all subscription tiers and pricing details on our website before downloading the app. Subscribers can upgrade their subscription level through the partner's platform in their online customer account and can also downgrade after the subscription period ends.
                                </Typography>
                                <Typography paragraph>
                                  <strong>7.3. Request a Quotation</strong>
                                  <br />
                                  Alternatively, Users can request a quotation by speaking to one of our experts.
                                </Typography>
                                <Typography paragraph>
                                  <strong>7.4. Payment Terms</strong>
                                  <br />
                                  Users are required to pay the chosen monthly or annual subscription fee for the Software provided by Kayana on the same date of each calendar month or year, unless cancellation occurs according to the Cancellation Policy in Section 22.
                                </Typography>
                                <Typography paragraph>
                                  <strong>7.5. Renewal and Payment Methods</strong>
                                  <br />
                                  Depending on the User’s current licence subscription tier and associated costs, the subscription will be automatically renewed on a monthly or annual basis through Direct Debit or a recurring credit/debit card payment. No alternative payment methods are offered.
                                </Typography>
                                <Typography paragraph>
                                  <strong>7.6. Payment Failure</strong>
                                  <br />
                                  If a User's payment fails and no subsequent payment is made within seven days, the licence subscription will be terminated. Data linked to the subscription will be permanently erased from the Cloud 30 days following the initial payment failure.
                                </Typography>
                                <Typography paragraph>
                                  <strong>7.7. Cloud Data Management</strong>
                                  <br />
                                  Users who exhaust their Cloud data storage have the option to upgrade their licence subscription or download data to a secure device for removal from the Cloud. Once data is deleted from the Cloud, it is irretrievable.
                                </Typography>
                                <Typography paragraph>
                                  <strong>7.8. Subscription Changes</strong>
                                  <br />
                                  Users can upgrade or downgrade their subscription after the initial subscription period.
                                  <br />
                                  <strong>7.8.1. Annual Subscription Changes</strong>
                                  <br />
                                  Annual subscriptions may be upgraded or downgraded with a one-month notice before the renewal date.
                                  <br />
                                  <strong>7.8.2. Downgrade Refund</strong>
                                  <br />
                                  Downgrading an annual subscription will result in a refund for the difference in subscription costs within 28 days of receiving the downgrade request. Changes to subscription features take effect after the one-month notice period expires.
                                  <br />
                                  <strong>7.8.3. Upgrade Process</strong>
                                  <br />
                                  Upgrading an annual subscription involves settling the cost difference between subscription tiers instantly using a credit or debit card. Enhanced functionalities are activated within 24 hours.
                                  <br />
                                  <strong>7.8.4. Monthly Subscription Changes</strong>
                                  <br />
                                  Kayana processes changes to monthly subscriptions starting from the next billing date, provided a minimum of three (3) working days' notice is given. Note that refunds are not available for downgrading monthly subscriptions.
                                  <br />
                                  <strong>7.8.5. Downgrade Restrictions</strong>
                                  <br />
                                  Downgrading is unavailable for users subscribed to Kayana's most basic tier.
                                  <br />
                                  <strong>7.8.6. Payment Adjustments</strong>
                                  <br />
                                  Upon upgrading or downgrading, the monthly or annual payment is modified, with Direct Debit adjusted accordingly from the forthcoming billing date. Changes within three working days of the next billing date take effect from the subsequent billing cycle.
                                </Typography>
                                <Typography paragraph>
                                  <strong>7.9. Fee Modifications</strong>
                                  <br />
                                  Kayana reserves the right to modify charges for Software and/or Hardware usage. After the initial twelve-month period, the subscription fee may increase annually by up to 10%.
                                  <br />
                                  <strong>7.9.1. Fee Increase Notification</strong>
                                  <br />
                                  Users will be informed at least 30 days in advance of any subscription fee increase.
                                  <br />
                                  <strong>7.9.2. Acceptance of Terms</strong>
                                  <br />
                                  Continued Software usage constitutes acceptance of updated payment terms.
                                  <br />
                                  <strong>7.9.3. Subscription Termination</strong>
                                  <br />
                                  Users may terminate their subscription if increased fees exceed their financial means. Refer to Section 23 for termination procedure. Re-subscribing post-cancellation requires a new Quotation and Terms.
                                </Typography>
                                <Typography paragraph>
                                  <strong>7.10. Currency and Billing</strong>
                                  <br />
                                  Prices are quoted and billed in GBP (£) to the user's bank account. Users bear transaction fees for currencies other than GBP (£). Transactions in the USA are charged in USD ($) and in Europe, in Euros.
                                </Typography>
                                <Typography paragraph>
                                  <strong>7.11. Invoice Access</strong>
                                  <br />
                                  Users can access invoices for monthly, annual payments, and individual purchases through their online customer accounts. Invoices cover payouts, merchant fees, and charges related to hardware and software.
                                </Typography>


                                <Typography variant="h6" gutterBottom>
                                  8. Web Ordering Services
                                </Typography>
                                <Typography paragraph>
                                  <strong>8.1. Web Ordering System Integration</strong>
                                  <br />
                                  Kayana offers a web ordering system that can be integrated either through an iFrame with a fixed URL or a plugin, depending on your Content Management System (CMS).
                                </Typography>
                                <Typography paragraph>
                                  <strong>8.2. Integration Requirement</strong>
                                  <br />
                                  If you have an existing website, you must integrate the iFrame into your business site, unless a Kayana plugin is available for use.
                                </Typography>
                                <Typography paragraph>
                                  <strong>8.3. Prebuilt Website Templates</strong>
                                  <br />
                                  If you lack a website, Kayana offers 'Prebuild' free website templates as a goodwill gesture for business use. However, we cannot guarantee a wide selection of templates or that they will meet your specific needs and expectations. Additionally, we do not customize these templates. You are under no obligation to utilize our website templates.
                                </Typography>
                                <Typography paragraph>
                                  <strong>8.4. Responsibility for Existing Websites</strong>
                                  <br />
                                  If you have an existing website, domain, and/or hosting, you are responsible for implementing necessary updates to ensure the seamless integration and functionality of our web ordering system. This includes iFrame integration, modifying domain settings, updating records, and adjusting any hosting settings required. While Kayana will guide you in identifying necessary updates, we do not provide advice or technical support for these updates, nor do we perform them on your behalf. It is your responsibility to ensure all updates are completed successfully.
                                </Typography>
                                <Typography paragraph>
                                  <strong>8.5. Complimentary Domain and Hosting</strong>
                                  <br />
                                  If you do not possess a domain and hosting, Kayana offers a complimentary domain (subject to availability) and free hosting on our servers. You must provide essential business information and ensure it remains up to date.
                                </Typography>
                                <Typography paragraph>
                                  <strong>8.6. Pre-designed Website Templates</strong>
                                  <br />
                                  If you choose to use one of our pre-designed website templates hosted on our servers, upon request, Kayana will grant you direct access to the website hosting control panel. You are responsible for any modifications to the website's content, design, images, and graphics.
                                </Typography>
                                <Typography paragraph>
                                  <strong>8.7. Limitations on Customizations</strong>
                                  <br />
                                  Kayana's web ordering platform offers pre-designed templates and does not accommodate customizations.
                                </Typography>
                                <Typography paragraph>
                                  <strong>8.8. Subscription Terms</strong>
                                  <br />
                                  Section 9 applies to users who have subscribed to our web-based ordering software services.
                                </Typography>
                                <Typography paragraph>
                                  <strong>8.9. Domain and Website Purchase</strong>
                                  <br />
                                  If you choose to leave Kayana, you have the option to purchase your domain and website at a fee to be agreed upon.
                                </Typography>

                                <Typography variant="h6" gutterBottom>
                                  9. Mobile Apps Ordering Services
                                </Typography>
                                <Typography paragraph>
                                  <strong>9.1. Mobile Applications Availability</strong>
                                  <br />
                                  Kayana offers mobile applications exclusively for the Google Play Store and Apple Store, designed specifically for mobile devices. These applications feature pre-built designs, and we do not offer customization options.
                                </Typography>
                                <Typography paragraph>
                                  <strong>9.2. Independent App Publishing</strong>
                                  <br />
                                  Should the User choose to publish app(s) through their personal Google and Apple accounts, they are at liberty to proceed independently. In compliance with Data Protection laws, Kayana is unable to access the control panels of the User’s mobile applications.
                                </Typography>
                                <Typography paragraph>
                                  <strong>9.3. Publishing Through Kayana</strong>
                                  <br />
                                  Should the User not possess their own Google and/or Apple accounts, or prefer not to use them, they have the option to publish their app(s) on the Google Play Store and/or Apple Store through Kayana World Limited's business account. In choosing this option, the User must provide essential information including, but not limited to, a business description, app description, terms and conditions, refund policy, and privacy policy. Moreover, it is the User's responsibility to ensure that this information is kept accurate, up-to-date, and comprehensive.
                                </Typography>
                                <Typography paragraph>
                                  <strong>9.4. App Approval Process</strong>
                                  <br />
                                  Kayana World Limited holds no sway over the app approval process. The Google Play Store and Apple App Store exclusively govern app submissions at their discretion. They reserve the right to modify their policies without prior notification and may decline app submissions for any reason. In the event of a rejection, users are required to furnish the necessary information and evidence as stipulated by the Google Play Store and/or Apple App Store for resubmission of the app(s).
                                </Typography>
                                <Typography paragraph>
                                  <strong>9.5. App Approval Timelines</strong>
                                  <br />
                                  The Google Play Store and Apple App Store typically require up to seven business days to approve applications. However, these timelines are subject to change without notice, and Kayana World Limited has no influence over these approval durations.
                                </Typography>
                                <Typography paragraph>
                                  <strong>9.6. Responsibility for App Content</strong>
                                  <br />
                                  The User is responsible for making any required changes to the content, design, images, and graphics of the mobile application(s).
                                </Typography>
                                <Typography paragraph>
                                  <strong>9.7. Subscription Terms</strong>
                                  <br />
                                  Section 10 applies to users who have subscribed to our Mobile Apps Ordering software services.
                                </Typography>


                                <Typography variant="h6" gutterBottom>
                                  10. QR Code Ordering Services
                                </Typography>
                                <Typography paragraph>
                                  <strong>10.1. Fixed URL</strong>
                                  <br />
                                  Kayana offers a QR Code ordering system that includes a fixed URL, which cannot be altered.
                                </Typography>
                                <Typography paragraph>
                                  <strong>10.2. Pre-designed Templates</strong>
                                  <br />
                                  Our QR Code platform offers pre-designed templates, and we do not accommodate customizations.
                                </Typography>
                                <Typography paragraph>
                                  <strong>10.3. Essential Details</strong>
                                  <br />
                                  The User must supply essential details, including terms and conditions, refund policy, and privacy policy, ensuring that this information remains accurate, up-to-date, and comprehensive. This is crucial for Kayana to successfully create a QR Code for ordering purposes.
                                </Typography>
                                <Typography paragraph>
                                  <strong>10.4. User Responsibility</strong>
                                  <br />
                                  The User is responsible for making any required modifications to the QR Code's content, design, images, and graphics.
                                </Typography>
                                <Typography paragraph>
                                  <strong>10.5. QR Code Delivery</strong>
                                  <br />
                                  Kayana will supply a QR Code in either JPEG or PNG format, delivered via email. Users are responsible for printing their stationery and/or marketing materials to promote the QR Code ordering system. At no point will Kayana provide stationery or marketing materials for advertising the QR Code ordering system.
                                </Typography>
                                <Typography paragraph>
                                  <strong>10.6. Subscription Terms</strong>
                                  <br />
                                  Section 11 applies to users subscribed to our QR Code Ordering software services.
                                </Typography>


                                <div>
                                  <Typography variant="h6" gutterBottom>
                                    11. Kayana Payments Powered by Adyen
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>11.1. Payment Processing Services</strong>
                                    <br />
                                    Kayana has teamed up with Adyen to offer Payment Processing services. It's important to clarify that Kayana acts not as a Payment Service Provider, but as an integrator of Adyen's payment processing solutions. To prevent any confusion, Kayana is not regulated by the FCA as a payment service provider. This section is relevant only to users who have opted for this payment processing service.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>11.2. User Registration with Adyen</strong>
                                    <br />
                                    To process payments through the App, users must undergo registration with Adyen via the Kayana onboarding process. This may require the submission of various documents, such as identity verification, business registration papers, and proof of address. Should Adyen decline the user's application to process payments, regardless of whether the applicant is an individual or a business, the user will consequently be unable to utilise our App. It's important to note that Kayana does not influence Adyen’s decision-making process in any way.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>11.3. Merchant Fee</strong>
                                    <br />
                                    Kayana will levy a Merchant Fee on the User for each payment processed through the Platform, calculated as a predetermined fixed percentage. The specifics of the Merchant Fee percentages and the frequency of fund settlements will be clearly outlined to the User during the sales/registration phase or within the Quotation process.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>11.4. Payment Dashboard</strong>
                                    <br />
                                    Kayana will offer the User a "Payment Dashboard" that features essential transaction details for simplified navigation and clear visibility of processed payments.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>11.5. Transaction Credits</strong>
                                    <br />
                                    Every transaction will be directly credited to the User's Adyen balance account, following the deduction of any Merchant and Platform Fees and as per the payout selected deposited to your bank in due course.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>11.6. Refunds</strong>
                                    <br />
                                    Users have the option to refund a transaction(s) after the payment has been processed. However, even if a payment is refunded, users must still cover the total Merchant Fees associated with the refunded transactions. Under no circumstances are Merchant Fees refundable. A separate refund fee may also be charged.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>11.7. Anti-Money Laundering</strong>
                                    <br />
                                    Users may be subject to Anti-Money Laundering checks at any time, regardless of their chosen payment service provider. It is imperative that users adhere to all relevant Anti-Money Laundering laws and regulations. Failure to comply will be considered a violation of this Agreement and may result in its immediate termination, as outlined in Section 31.4.6.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>11.8. Payment Processing Activation</strong>
                                    <br />
                                    Payment Processing becomes operational only after the user inputs their login details (merchant key) into the POS terminal and/or Kayana control panels.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>11.9. Liability and Support</strong>
                                    <br />
                                    Kayana is not liable for any issues arising with the payment gateway, which include fraudulent transactions and bank chargebacks, among others. The Kayana Technical Support team provides primary technical support and assistance with login problems. Should there be any unresolved issues, they will be escalated to Adyen through the Kayana platform. For concerns directly related to payment processing, such as fraudulent transactions and bank chargebacks, customers are required to complete the chargeback requirements via the admin platform which will be submitted to Adyen directly.
                                  </Typography>

                                  <Typography variant="h6" gutterBottom>
                                    12. Dispute Handling
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>12.1. Dispute Occurrence</strong>
                                    <br />
                                    A dispute (a chargeback, inquiry, or retrieval) occurs when a cardholder questions a payment with their card issuer. An investigation or retrieval is a request for more information about the charge, which may escalate to a chargeback. To avoid an enquiry or retrieval becoming a chargeback, submit any required evidence or fully refund the payment. Refunding the payment marks the enquiry or retrieval as resolved and does not incur a dispute fee.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>12.2. Chargebacks</strong>
                                    <br />
                                    The card issuer creates a formal dispute to process a chargeback, which immediately reverses the payment. The payment amount and a separate £5.00 dispute fee are deducted from the Merchant’s account balance. The dispute fee amount is subject to change at Adyen’s discretion. Kayana is not responsible for communicating any changes to the dispute fee amount to the User.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>12.3. User Responsibility for Disputes</strong>
                                    <br />
                                    The User has full responsibility and/or liability for all payment disputes. The User shall deal with payment disputes through their Payment Dashboard by providing the necessary evidence. In most cases, Adyen does not decide the outcome of the dispute. Adyen conveys your evidence to their financial partners. Adyen’s financial partners then pass your evidence to the card issuers if they deem it sufficient. If you do not submit proof by the deadline, the cardholder wins the dispute and keeps the funds and a charge of £25 will be levied.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>12.4. Costs and Indemnity</strong>
                                    <br />
                                    The user shall cover all costs incurred during the dispute resolution process and keep the Company indemnified against any payment disputes and costs incurred during the dispute settlement process. The User acknowledges and agrees that using any form of Payment Processing is at the User’s own risk.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>12.5. Termination of Partnership with Adyen</strong>
                                    <br />
                                    If Kayana’s partnership with Adyen is terminated, the User shall need to switch to another integrated provider to continue using Kayana Payments for online ordering, contactless table ordering, and/or self-checkout ordering, etc. The Company shall inform the User before any such termination in partnership and provide details of any new third-party payment service provider that will replace Adyen’s functionalities. The User shall have the opportunity to review any new terms and conditions deriving from the new partnership and choose to switch to the new payment service provider or a different third-party payment service provider (please see Section 15), or cancel any Kayana services altogether (please see Section 22). The Company shall endeavour to make the transition period and process as smooth as possible for the User and their customers.
                                  </Typography>


                                  <Typography variant="h6" gutterBottom>
                                    13. Customer Support Services
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>13.1. Enquiries and Operating Hours</strong>
                                    <br />
                                    For any enquiries, requests, or issues, please contact our Customer Support Team for assistance. This team, incorporating Technical Support, operates 16 hours a day, 7 days a week from 7am to 10pm. Should there be an unexpected disruption to telephone or Internet services affecting communication with Customer Support, operational hours may temporarily decrease.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>13.2. Internal Ticketing System</strong>
                                    <br />
                                    The Company employs a comprehensive internal ticketing system across all support channels. This system gathers data exclusively for ticket prioritisation and generating reports aimed at internal management and enhancement efforts. Importantly, any data utilised for these internal reports will be anonymised to ensure privacy. Under no circumstances will data be shared with third parties without explicit consent from the User.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>13.3. Support Channel Availability</strong>
                                    <br />
                                    The availability of customer support channels for the User will vary based on their subscription tier. Should a support channel encounter technical issues and temporarily become unavailable, the User is encouraged to reach out to the Customer Support Team through an alternative channel.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>13.4. Technical Support Entitlement</strong>
                                    <br />
                                    The User is entitled to technical support for any issues encountered with the Company's software or hardware. Support requests can be made via telephone or email at support@kayana.co.uk and will be addressed within 24 hours. For more intricate problems, the issue will be escalated to our second-line support team, who will aim to resolve it within 72 hours from the initial request. Should the Technical Support Team face an unprecedented issue, the Company will commit to regularly update the User on the expected resolution timeline, ensuring minimal disruption to the service's functionality.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>13.5. Main Messaging Service</strong>
                                    <br />
                                    Technical support is available to the User via the main messaging service if no other support channel is operable. Telephone support may be offered.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>13.6. Remote Training on Cloud Services</strong>
                                    <br />
                                    Upon request, we offer remote training on Cloud services, subject to the availability of our engineers and within reasonable timeframes. Should the User require further training sessions, these may incur an additional fee. We will ensure any associated costs and payment details are clearly communicated to the User beforehand.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>13.7. Remote Technical Support</strong>
                                    <br />
                                    The Company does not provide on-site visits to User locations as a component of our technical support offerings. All technical assistance from the Company is exclusively available remotely.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>13.7.1. User Participation</strong>
                                    <br />
                                    Users are expected to help in resolving technical problems under the remote guidance of an engineer.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>13.7.2. Continuation of Troubleshooting</strong>
                                    <br />
                                    If troubleshooting requires more time to resolve, the User will have the option to continue at a more convenient time.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>13.7.3. Remote Access and Screen Sharing</strong>
                                    <br />
                                    If a user needs to grant remote access to their device(s) or engage in screen sharing with a technical support engineer, it is their responsibility to ensure that all sensitive information is securely closed on their device prior to initiating the remote access or screen sharing.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>13.8. Location of Customer Support Team</strong>
                                    <br />
                                    The Company's Customer Support Team is presently located in India, though this may change at the Company's discretion. Kayana will periodically evaluate the team's location as necessary. Should a relocation be deemed appropriate, the Company is not obligated to seek User consent for moving the Customer Support Team to a new location.
                                  </Typography>

                                  <Typography variant="h6" gutterBottom>
                                    14. Third-Party Licensing, Tools, and Integrations
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>14.1. Technologies Used</strong>
                                    <br />
                                    The Software has been crafted using a diverse array of technologies including Android, PHP, Python, Flutter, Angular, HTML, MySQL, JavaScript, eJabber, and CSS, among other tools. Presently, these platforms are available under licence-free terms, eliminating the need for users to obtain separate software licences. However, should any third-party software incorporated into the solution transition to a paid model, users will be required to either subscribe directly to the service or compensate the Company for the requisite licence fees.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>14.2. Google Maps API Integration</strong>
                                    <br />
                                    The Company has seamlessly integrated the Google Maps API to facilitate address searches using postcodes. This feature enhances various processes, including order placements, customer registrations, and loyalty management. However, the Company does not exert control over the accuracy of these records and, consequently, cannot be held responsible or liable for their precision. To add value to our service, we offer Users 1,000 complimentary address lookups each month. Should the need for additional lookups arise, these will incur charges at rates specified in the subscription level or provided quotation, which will be reflected in the subsequent month's billing.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>14.3. Third-Party Tools and Services</strong>
                                    <br />
                                    To enhance software functionality, the company has incorporated a range of third-party tools, including but not limited to Deliveroo, Deliverect, MailChimp, Message Bird, SendGrid, Nash, Twilio, and Xero, among others. Users interested in leveraging any of these integrated third-party services must directly subscribe to them through the respective provider. The company expressly disclaims any responsibility for the pricing, performance, and any disputes arising from the use of these third-party tools. If a user already holds a subscription to any mentioned third-party services, they have the ability to synchronise their existing account with our software, seamlessly integrating their tools.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>14.4. Modification or Withdrawal of Third-Party Tools</strong>
                                    <br />
                                    Third-party tools and integrations may be modified or withdrawn at any time, without prior notice to Kayana or the User. The Company will not be liable for any resulting loss of profits, business, goodwill, use, data, or other tangible and intangible losses incurred due to the discontinuation of any third-party tools and integrations, with or without prior warning.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>14.5. Comprehensive List of Third-Party Tools</strong>
                                    <br />
                                    Users may request that the Company furnish a comprehensive list of all third-party tools and integrations at any given time.
                                  </Typography>

                                  <Typography variant="h6" gutterBottom>
                                    15. Hardware Refunds Policy
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.1. Order Submission and Acceptance</strong>
                                    <br />
                                    By submitting your order details, you are offering to purchase the item(s) you specified in your order form. We reserve the right to refuse your order if necessary. We will notify you if this is the case and return any payment you have made.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.2. Order Cancellation</strong>
                                    <br />
                                    If you decide to cancel your order, you must notify us immediately, preferably by phone, and quote your order number.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.2.1. No Delivery Dispatched</strong>
                                    <br />
                                    If no delivery has been dispatched, no cancellation fee applies, and you will be issued a full refund. If the order has been sent for delivery already, your delivery cancellation request will be refused, and you will need to refer to the process detailed in Section 18.6.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.2.2. Cancellation Acceptance</strong>
                                    <br />
                                    If the cancellation was made in time and once we have accepted your delivery cancellation request, we will refund or re-credit your payment card with the full amount within fourteen (14) days, including the initial delivery charge (where applicable).
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.3. Split Deliveries</strong>
                                    <br />
                                    Should a split delivery become necessary (due to all items not being available simultaneously), our team will notify you about the expected delivery date for the outstanding items. You possess the right to decline a split delivery prior to dispatch, opting instead to await the availability of all items at once, or you may choose to cancel your order and receive a refund.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.4. Wrong Item Delivered</strong>
                                    <br />
                                    In the unlikely event that we deliver a wrong item of hardware, you have the right to either:
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.4.1. Reject the Delivery</strong>
                                    <br />
                                    Reject the delivery of the wrong item, and you shall be fully refunded for the missing item; or
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.4.2. Request Replacement</strong>
                                    <br />
                                    Reject the delivery of the wrong item and request that we replace the incorrect item with the item that you ordered, in which case we shall send you the correct one as soon as possible.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.4.3. Post-Delivery Discovery</strong>
                                    <br />
                                    If you discover that a wrong item has been dispatched after the delivery, you will be required to return the incorrect item to avoid being charged for it.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.5. Damaged Items During Transit</strong>
                                    <br />
                                    Should any items be damaged during transit, it is imperative that the User reports such incidents to Kayana within seven (7) working days following delivery. In instances where the packaging is noticeably damaged upon receipt, the User must immediately notify the delivery driver, who may then document the damage in their system. For any items requiring replacement due to damage, the User is expected to prepare them for collection in their original packaging, complete with all accessories and documentation. Kayana will organise the collection and facilitate the delivery of replacements.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.6. Consumer Rights Act 2015</strong>
                                    <br />
                                    According to the Consumer Rights Act 2015 and the stipulations of Section 1.6, the User does not qualify as a “consumer,” and therefore, consumer statutory rights do not apply to this Agreement. Nevertheless, in a spirit of goodwill, Kayana offers a “5 Days Money Back Guarantee” for hardware purchases.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.6.1. Full Refund</strong>
                                    <br />
                                    If you decide to return your Hardware order, the Company will provide a full refund for any items returned in undamaged condition within seven days of delivery, deducting any charges for delivery and returns. Returned items must be unused, remain in their original packaging, complete with all accessories and documentation, and be in a condition suitable for resale as "as new."
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.6.2. Refusal of Refund</strong>
                                    <br />
                                    The Company reserves the right to refuse a full or partial refund if:
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.6.2.1. Original Packaging</strong>
                                    <br />
                                    Any item is not returned in its original packaging; or
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.6.2.2. Missing Accessories</strong>
                                    <br />
                                    Any item is missing any accessories and/or documentation; or
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.6.2.3. Damaged Condition</strong>
                                    <br />
                                    The goods are in a damaged condition (any damaged equipment will be charged at full cost); or
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.6.2.4. Timely Return</strong>
                                    <br />
                                    The goods are not returned within seven (7) days of delivery.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.6.3. Return Delivery Costs</strong>
                                    <br />
                                    Should you wish to return your goods to us, you will be responsible for the delivery costs associated with the return. Kayana can organise a collection on your behalf, but the associated costs will be deducted from your total refund.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.7. Formal Complaint</strong>
                                    <br />
                                    Should you have a valid formal complaint, you are entitled to request a refund within seven days following the delivery date. This is subject to our review and approval of the complaint.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.8. Refunds, Credit Notes, and Exchanges</strong>
                                    <br />
                                    In the case of a return, refunds, credit notes, or exchanges will be issued exclusively to the original payment card or the individual/business that placed the order. Please note that refunds for payments made via debit/credit card may require up to twenty-eight days to process, even if the order is cancelled immediately after placement. This processing duration is outside our control, and we apologise for any inconvenience this may cause.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.9. Non-Refundable Payments</strong>
                                    <br />
                                    Payments made for onsite installations, product imports, support services, postage, module integrations, as well as repair, and labour expenses, are non-refundable.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>15.10. Non-Refundable Software Sales and Licence Subscriptions</strong>
                                    <br />
                                    Due to significant expenses associated with configuration, personalisation, training, and installation, all costs related to software sales and licence subscriptions fall outside the scope of the standard returns policy. Consequently, these items are strictly non-refundable.
                                  </Typography>


                                  <Typography variant="h6" gutterBottom>
                                    16. Hardware Warranty Returns Process
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.1.</strong> The User is entitled to request a warranty return if they believe the equipment supplied by Kayana does not meet its intended purpose. This request can be made by reaching out to the Customer Support Team within the two-year basic manufacturer’s warranty period.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.2.</strong> The Company will strive to understand the reasons behind the User's belief that the product is 'not fit for purpose' and will make reasonable efforts to resolve any issues through remote troubleshooting.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.3.</strong> Should remote troubleshooting prove unsuccessful, the Company will request that the User send the faulty hardware for repair to a designated address via courier service. It is the User's responsibility to ensure the hardware is appropriately packaged to avoid damage during transportation. Should damage occur due to insufficient packaging, the Company reserves the right to impose a fee on the User. Alternatively we may send an engineer to swap the equipment out depending on which package of support you have selected.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.4.</strong> After repairing the hardware, the Company will return it to the User. If the fault cannot be repaired, the Company will provide the User with a replacement for the faulty hardware. Please note that any replacement may be a refurbished item.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.5.</strong> If the item returned is found to be in a working condition but in a poor and unreasonable state of cleanliness, the User will be billed for cleaning at a fixed rate of £100 per item.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.6.</strong> The Company may offer warranty refunds only after exhausting all efforts to resolve any issues or faults, and if no suitable replacement equipment is available.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.7.</strong> The company is unable to process warranty refunds for hardware under the following circumstances:
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.7.1.</strong> Misunderstanding or improper use of the system by the user or staff.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.7.2.</strong> Incompatibility with third-party items or systems.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.7.3.</strong> The equipment has been configured, personalised, and/or used.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.7.4.</strong> The product exceeds the user's needs.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.7.5.</strong> User’s unstable or insufficient internet connection causing operational issues.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.7.6.</strong> Accidental damage to the item by the user post-delivery.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.7.7.</strong> Misuse of the item by the user, resulting in damage.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.7.8.</strong> User attempting to open or tamper with the hardware.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.7.9.</strong> Environmental issues beyond the company’s control.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.7.10.</strong> The user's business undergoing bankruptcy.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>16.8.</strong> Hardware repairs beyond the two-year standard manufacturer's warranty may incur additional charges. These costs will be mutually agreed upon by the Company and the User before any repair work commences.
                                  </Typography>

                                  {/* Section 17: SMS/MMS Mobile Message Marketing Program */}
                                  <Typography variant="h6" gutterBottom>
                                    17. SMS/MMS Mobile Message Marketing Program
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.1.</strong> Kayana provides a mobile messaging service ("MMM") via Twilio. By choosing to utilise and participate in this service, the User agrees to adhere to the specific Mobile Messaging Terms and Conditions and Privacy Policy.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.2. User Opt-In:</strong> MMM allows Users to receive SMS/MMS mobile messages by affirmatively opting into MMM, such as through online, offline, or application-based enrolment forms. Regardless of the opt-in method the User has utilised to join MMM; the User acknowledges that this Agreement applies to the User’s participation in MMM.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.2.1.</strong> By participating in MMM, the User agrees to receive autodialled or manually dialled marketing mobile messages (as applicable) at the phone number associated with the User’s opt-in, and the User understands that consent is not required to make any purchase from the Company.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.2.2.</strong> While the User consents to receive messages sent using an autodialer, the preceding shall not be interpreted to suggest or imply that any or all of the Company’s mobile messages are sent using an automatic telephone dialling system (“ATDS” or “autodialer”). Message and data rates may apply.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.3. User Opt Out:</strong> The User reserves the right to request to opt out of MMM should the User not wish to continue participating.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.3.1.</strong> To opt out of MMM, the User must reply “STOP”, “END”, “CANCEL”, “UNSUBSCRIBE”, or “QUIT” to any mobile message from the Company. The User may receive an additional mobile message confirming the User’s decision to opt-out.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.3.2.</strong> The User understands and agrees that the preceding options are the only reasonable methods of opting out.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.3.3.</strong> The User understands and agrees that any other method of opting out, including, but not limited to, texting words other than those set forth above or verbally requesting one of the Company’s employees to remove the User from MMM’s mailing list, is not a reasonable means of opting out.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.4. Duty to Notify and Indemnify:</strong> If at any time the User intends to stop using the mobile telephone number that has been used to subscribe to MMM, including cancelling the User’s service plan or selling or transferring the telephone number to another party, the User must complete the User Opt Out process set forth above (Section 20.3) before ending the User’s use of the mobile telephone number.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.4.1.</strong> The User understands and agrees that the User’s agreement to do so is a material part of these terms and conditions.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.4.2.</strong> The User agrees that the User shall indemnify, defend, and hold the Company harmless from any claim or liability resulting from the User’s failure to notify us of a change in the information the User has provided the Company. The User understands and agrees that if the User discontinues the use of the User’s mobile telephone number without notifying Kayana of such change, the User will be responsible for all costs (including any legal fees) and liabilities incurred by the Company or any party that assists in the delivery of the mobile messages, as a result of claims brought by individual(s) who are later assigned that mobile telephone number.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.4.3.</strong> This duty and agreement shall survive any cancellation or termination of the User’s agreement to participate in any of the Company’s Programs.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.5. Program Description:</strong> MMM's scope is not limited; users who opt in can expect to receive messages concerning the marketing and sale of digital and physical products, services, and events.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.6. Cost and Frequency:</strong> Message and data rates may apply. MMM involves recurring mobile messages, and additional mobile messages may be sent periodically based on your interaction with Kayana.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.7. Support Instructions:</strong> For support regarding MMM, text “HELP” to the number you received messages from or email Kayana at info@Kayana.co.uk.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.8. MMS Disclosure:</strong> MMM will send SMS TMs (terminating messages) if your mobile device does not support MMS messaging.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.9. Disclaimer of Warranty:</strong> MMM is offered on an "as-is" basis and may not be available in all areas at all times and may not continue to work in the event of product, software, coverage or other changes made by the User’s wireless carrier/service provider/network operator.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.9.1.</strong> The Company shall not be liable for any delays or failures in the receipt of any mobile messages connected with this Program. Delivery of mobile messages is subject to effective transmission from your wireless service provider/network operator and is outside of the Company’s control.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.9.2.</strong> Kayana, any party that assists the Company in the delivery of the mobile messages, and the respective wireless carriers, including T-Mobile, are not liable for delayed or undelivered mobile messages.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.10. Participant Requirements:</strong> Users must possess a wireless device that supports two-way messaging, utilise a participating carrier, and subscribe to a text messaging plan. Not all mobile phone providers offer the required service for participation in this Programme. Users are advised to verify their device's text-messaging capabilities and follow any specific instructions.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.11. Age Restriction:</strong> You may not use or engage with the marketing Program if you are under eighteen (18) years of age.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.12. Prohibited Content:</strong> The User acknowledges and agrees not to send any prohibited content over MMM. Prohibited content includes, but is not limited to:
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.12.1.</strong> Any fraudulent, libellous, defamatory, scandalous, threatening, harassing, or stalking activity;
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.12.2.</strong> Objectionable content, including profanity, obscenity, lust, violence, bigotry, hatred, and discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age;
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.12.3.</strong> Pirated computer programs, viruses, worms, Trojan horses, or other harmful code;
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.12.4.</strong> Any product, service, or promotion that is unlawful where such product, service, or promotion thereof is received;
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.12.5.</strong> Any content that implicates and/or references personal information that is protected by the Data Protection Act 2018;
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.12.6.</strong> Any other content prohibited by the applicable laws in the jurisdiction from which the message is sent.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.13. Dispute Resolution, Arbitration and Class Action Waiver:</strong> Please read this Section carefully. It affects your rights and will have a substantial impact on how claims between the Parties are resolved:
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.13.1.</strong> If there is a dispute, claim, or controversy between the Parties or any other third-party service provider, including Autopilot, acting on the Company’s behalf to transmit the mobile messages within the scope of MMM, arising out of or relating to federal or state statutory claims, common law claims, this Agreement, or the breach, termination, enforcement, interpretation or validity thereof, including the determination of the scope or applicability of this agreement to arbitrate, such dispute, claim, or controversy will be, to the fullest extent permitted by law, determined by arbitration in 38 West Gorgie Parks, Edinburgh, EH5 1RT, before one sole arbitrator.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.13.2.</strong> The Parties agree to submit the dispute to binding arbitration by the Arbitration Act 1996. Except as otherwise provided herein, the arbitrator shall apply the applicable laws (Section 31) of the jurisdiction in which Kayana’s principal place of business is located without regard to the possible conflict of laws between different jurisdictions in which the Parties may be operating.
                                  </Typography>
                                  <Typography paragraph>
                                    <ul>
                                      <li>Within ten (10) calendar days after the arbitration demand is served upon a party, the Parties must jointly select an arbitrator with at least five (5) years’ experience in that capacity and who has knowledge of and experience with the subject matter of the dispute.</li>
                                      <li>If the Parties disagree with an arbitrator within ten (10) calendar days, a Party may apply for the Law Society president to appoint an arbitrator, who must satisfy the same experience requirement.</li>
                                      <li>In the event of a dispute, the arbitrator shall decide the enforceability and interpretation of this arbitration agreement by the Arbitration Act 1996.</li>
                                      <li>The Parties agree that the Party submitting the dispute for arbitration, may request an emergency action by the other Party, in lieu of seeking emergency injunction from a court, with the same effect as a court injunction. The Parties agree that the appointment of an emergency arbitrator would not be necessary due to the additional costs of doing so.</li>
                                      <li>The decision of the arbitrator shall be final and binding, and no party shall have rights of appeal except for those provided in Section 68 of the Arbitration Act 1996.</li>
                                      <li>Each Party shall bear its share of the fees paid for the arbitrator and the administration of the arbitration; however, the arbitrator shall have the power to order one party to pay all or any portion of such fees as part of a well-reasoned decision. The Parties agree that the arbitrator shall have the authority to award arbitration fees only to the extent expressly authorised by statute or contract.</li>
                                      <li>The arbitrator shall have no authority to award punitive damages and each party hereby waives any right to seek or recover punitive damages with respect to any dispute resolved by arbitration.</li>
                                      <li>The Parties agree to arbitrate solely on an individual basis, and this Agreement does not permit class arbitration or any claims brought as a claimant or class member in any class or representative arbitration proceeding.</li>
                                      <li>Except as may be required by law, neither a party nor the arbitrator may disclose the existence, content, or results of any arbitration without the prior written consent of both Parties, unless to protect or pursue a legal right.</li>
                                      <li>If any term or provision of this Section is invalid, illegal, or unenforceable in the jurisdiction specified in Section 32, such invalidity, illegality, or unenforceability shall not affect any other term or provision of this Section.</li>
                                      <li>If, for any reason, a dispute proceeds in court rather than in arbitration, the Parties hereby waive any right to a jury trial. This arbitration provision shall survive any cancellation or termination of the User’s agreement to participate in any of the Company’s Programs.</li>
                                    </ul>
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.14.</strong> The User warrants and represents to the Company that the User has all necessary rights, power, and authority to agree to these Terms and perform the User’s obligations hereunder, and nothing contained in this Agreement or in the performance of such obligations will place the User in breach of any other contract or obligation.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.15.</strong> The failure of either Party to exercise in any respect any right provided for herein will not be deemed a waiver of any further rights hereunder.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.16.</strong> If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.17.</strong> Any new features, changes, updates or improvements of MMM shall be subject to this Agreement unless explicitly stated otherwise.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>17.18.</strong> Kayana reserves the right to change this Agreement from time to time. Any updates to this Agreement shall be communicated to the User. The User acknowledges the User’s responsibility to review this Agreement from time to time and to be aware of any such changes. By continuing to participate in MMM after any such changes, the User accepts this Agreement as modified.
                                  </Typography>

                                  {/* Section 18: Formal Complaints Procedure */}
                                  <Typography variant="h6" gutterBottom>
                                    18. Formal Complaints Procedure
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>18.1.</strong> Kayana is dedicated to delivering high-quality service to our customers, with a commitment to transparency, accountability, and respect. Should you find any aspect of our service unsatisfactory, we urge you to reach out to us at once by phone or email. We will strive to address and resolve any concerns promptly. For issues that do not pertain to hardware malfunctions, the Company will follow the specified Complaints Procedure to ensure a timely and fair resolution.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>18.1.1. Stage 1 -</strong> Should an informal resolution to your issue prove elusive, we encourage you to reach out to us directly in writing, either through email or postal service, to afford us the opportunity to rectify the situation. Within your communication, please include a detailed account of your grievance, the impact it has had on you, and the resolution you are seeking. Rest assured, we will acknowledge receipt of your complaint within five business days.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>18.1.2. Stage 2 -</strong> If the initial resolution does not meet your expectations, you are encouraged to escalate your complaint by contacting the Chief Executive Officer (CEO) either through email or post, requesting a review of both your complaint and the initial response. Upon receipt, the CEO will acknowledge your request within five business days and aim to provide a comprehensive response within twenty business days. Kayana is committed to resolving issues promptly and efficiently, but it's important to note that some more complex matters may require additional time for a detailed investigation. In such cases, you will be informed about the steps being taken to address your complaint, the expected timeline for a resolution, and the contact details of the person handling your case.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>18.1.3. Final Step -</strong> Should you remain dissatisfied with the response and measures undertaken by the CEO, you are entitled to escalate your complaint by writing to one of the following bodies, depending on your issue's specific nature:
                                  </Typography>
                                  <Typography paragraph>
                                    Information Commissioner’s Office: <a href="https://ico.org.uk/" target="_blank" rel="noopener noreferrer">https://ico.org.uk/</a>
                                  </Typography>
                                  <Typography paragraph>
                                    Health and Safety Executive: <a href="http://www.hse.gov.uk/" target="_blank" rel="noopener noreferrer">http://www.hse.gov.uk/</a>
                                  </Typography>
                                  <Typography paragraph>
                                    Citizens Advice<br />
                                    Telephone: 0808 223 1133<br />
                                    Website: <a href="https://www.citizensadvice.org.uk/consumer/get-more-help/if-you-need-more-help-about-a-consumer-issue/" target="_blank" rel="noopener noreferrer">https://www.citizensadvice.org.uk/consumer/get-more-help/if-you-need-more-help-about-a-consumer-issue/</a>
                                  </Typography>

                                  {/* Section 19: Cancellation of License Subscription */}
                                  <Typography variant="h6" gutterBottom>
                                    19. Cancellation of License Subscription
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>19.1.</strong> Users wishing to cancel their licence subscription must provide Kayana with a thirty-day notice, which can be done by reaching out to the Customer Support Team through email or phone.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>19.2.</strong> If the User's billing cycle occurs within the thirty-day notice period, a full subscription fee will be charged. Upon cancellation of the subscription, a pro-rata refund will be provided for any time remaining after the termination date.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>19.3.</strong> During the 30-day notice period for subscription cancellation, Users are advised to download all data stored in the Cloud Software associated with their account. Once the licence subscription is terminated, all account data will be irrevocably deleted, with no possibility of retrieval.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>19.4.</strong> The User has the option to revoke their cancellation request during the thirty-day notice period, thereby continuing their licence subscription seamlessly.
                                  </Typography>

                                  {/* Section 20: Tax Calculations */}
                                  <Typography variant="h6" gutterBottom>
                                    20. Tax Calculations
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>20.1.</strong> Kayana calculates tax on each line sold in a transaction, a common practice in most software.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>20.2.</strong> Users may notice a discrepancy in the total tax amount when comparing calculations based on individual transactions versus the total sales over a specific period. For instance, selling an item at 25p with a 20% tax rate results in a tax of 4.16p, which, when rounded, amounts to 4p per transaction. Selling this item 100 times individually would accumulate to a tax total of £4.00. However, calculating 20% tax on the collective sales of 100 items yields a tax figure of £4.16.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>20.3.</strong> Discounts applied to tax on a transaction are taken equally from all items in the transaction (pro-rata).
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>20.4.</strong> Kayana is not liable for any incorrect tax calculations. The User’s responsible for taking professional tax advice before submitting returns to tax and other governing authorities.
                                  </Typography>

                                  {/* Section 21: Company Income from Third-Party Referrals */}
                                  <Typography variant="h6" gutterBottom>
                                    21. Company Income from Third-Party Referrals
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>21.1.</strong> The User recognises and agrees that the Company will obtain financial benefits or commissions from third-party providers, including but not limited to leasing, finance, card processing, software tools, delivery partners, and hardware tools, as a result of referring the User to these services.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>21.2.</strong> The Company shall not notify or disclose to the User any such financial incentives/commission figures at any time.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>21.3.</strong> The User recognises and agrees that the Company has the complete authority to receive and retain any financial incentives.
                                  </Typography>

                                  {/* Section 22: Intellectual Property Rights */}
                                  <Typography variant="h6" gutterBottom>
                                    22. Intellectual Property Rights
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>22.1.</strong> The User recognises and consents that iHybrid retains all intellectual property rights related to the software and hardware, encompassing, but not limited to, copyrights and trademarks.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>22.2.</strong> Under this licence agreement, the Company provides the User with a non-exclusive, non-transferable, and time-limited licence to utilise the software, interfaces, content, documentation, and any data, regardless of whether it's stored in read-only memory, on other media, or in any other format.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>22.3.</strong> The User agrees not to contest or engage in any actions that would undermine this ownership.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>22.4.</strong> By using the software, the User grants the Company a royalty-free, irrevocable, and perpetual license to utilize information and/or data derived from the User’s activities on the Software, with the condition that such information or data is anonymised prior to its use. Beyond this specified right, the Company does not assert any intellectual property rights over the information or content entered by the User into the Software.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>22.5.</strong> Users are encouraged to offer feedback, suggestions, or alternative options regarding the Software to the Company. By doing so, users acknowledge that the Company has the freedom to utilise, adapt, and integrate these insights without any obligations. Furthermore, Kayana retains all intellectual property rights for any modifications made based on this feedback.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>22.6.</strong> The Company maintains ownership of the software and reserves all rights not explicitly granted to the User.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>22.7.</strong> The User is not allowed to assign, sub-licence, novate, or transfer these Terms or any of the rights granted to them.
                                  </Typography>
                                  {/* Section 23: Unauthorised, Malicious and/or Illegal Use */}
                                  <Typography variant="h6" gutterBottom>
                                    23. Unauthorised, Malicious and/or Illegal Use
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>23.1.</strong> The Company explicitly requires that the User not undertake any modifications or integrations to the Company Software without obtaining prior approval.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>23.2.</strong> The User must not:
                                    <ul>
                                      <li>23.2.1. Use the Software in any unlawful manner, for any unlawful or fraudulent purpose, or in any manner inconsistent with these Terms; or</li>
                                      <li>23.2.2. Upload any malicious or unlawful content and/or data onto the Software;</li>
                                      <li>23.2.3. Infringe on the intellectual property rights, or those of any third party, about their use of the Software; or</li>
                                      <li>23.2.4. Tamper with or use the Software in a way that could damage, disable, overburden, impair or compromise the Company’s systems or security, or interfere with other users’ systems or security; or</li>
                                      <li>23.2.5. Collect any information or data from the Software and/or Hardware or systems;</li>
                                      <li>23.2.6. Disclose or distribute information relating to another user of the Software to any third party;</li>
                                      <li>23.2.7. Use any other User's information for any marketing purposes unless they have obtained the User's express permission to do so; or</li>
                                      <li>23.2.8. Access or register user logins via any automated methods.</li>
                                    </ul>
                                  </Typography>

                                  {/* Section 24: Limitation of Liability */}
                                  <Typography variant="h6" gutterBottom>
                                    24. Limitation of Liability
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>24.1.</strong> Nothing in these Terms excludes or limits our liability for:
                                    <ul>
                                      <li>24.1.1. Death or personal injury caused by our negligence;</li>
                                      <li>24.1.2. Fraud or fraudulent misrepresentation; and/or</li>
                                      <li>24.1.3. Any matter concerning which it would be unlawful for us to exclude or restrict our liability.</li>
                                    </ul>
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>24.2.</strong> Nothing in these terms and conditions will restrict your statutory rights.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>24.3.</strong> The Company shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, business, goodwill, use, data or other intangible and tangible losses resulting from:
                                    <ul>
                                      <li>24.3.1. The use or the inability to use the Software, hardware (in whole or in part) or any technical malfunctions; or</li>
                                      <li>24.3.2. The unauthorised access to, or alteration of, your communications or data;</li>
                                      <li>24.3.3. The cost of procurement of substitute goods and services;</li>
                                      <li>24.3.4. The conduct or actions of any third party regarding the Software; or</li>
                                      <li>24.3.5. Any other matter relating to the Software.</li>
                                    </ul>
                                  </Typography>
                                  {/* Continued section 24... */}
                                  <Typography paragraph>
                                    <strong>24.4.</strong> The Company accepts no liability for any delays, failures, errors or omissions or loss of transmitted information, viruses or other contamination or destructive properties transmitted to you or your computer system via our Software.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>24.5.</strong> Force majeure: We will not be deemed to be in breach of contract or of these terms and conditions as a result of any delay in our performance or failure to perform our obligations if that delay or failure to perform is due to any cause or circumstance beyond our reasonable control including, but not limited to, all overwhelming and unpreventable events caused directly and exclusively by forces of nature that can be neither anticipated, nor controlled, nor prevented by the exercise of prudence, diligence, and care, including but not limited to: war, riot, civil commotion; compliance with any law or governmental order, rule, regulation or direction and acts of third parties.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>24.6.</strong> If we have contracted to provide orders to more than one customer and are prevented from fully meeting our obligations to you because of an event of Force Majeure, we may decide at our absolute discretion which orders we will fill and to what extent. Nothing in these Terms obliges us to prioritise any order the User places in the event of Force Majeure.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>24.7.</strong> To avoid doubt, nothing in Clause 22.5 shall excuse the Customer from any payment obligations under this agreement.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>24.8.</strong> The products sold by Kayana are provided for commercial use only. Accordingly, we do not accept liability for any indirect loss, consequential loss, loss of data, loss of income or profit, loss or damage to property and/or loss from claims of third parties arising out of the use of the Software and/or Hardware or for any products or services purchased from the Company.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>24.9.</strong> We have taken all reasonable steps to prevent Internet fraud and ensure any data collected from you is stored as securely and safely as possible. However, we cannot be held liable in the unlikely event of a breach in our secure computer servers or those of third parties.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>24.10.</strong> No delay or failure on our part to enforce our rights or remedies under the Agreement shall constitute a waiver on our part of such rights or remedies unless such waiver is confirmed in writing.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>24.11.</strong> The User agrees to defend, indemnify and hold harmless Kayana, its affiliates and its licensees and licensors, and their employees, contractors, agents, officers and directors, from and against any claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to reasonable legal fees), resulting from or arising out of a) your use and access of the Services, b) any unauthorised use of your account or password, or c) any other breach of the terms of this Agreement.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>24.12.</strong> In addition, you will indemnify us and our affiliates against any losses, damages, or claims (and all related costs, including legal fees), penalties, fines, interest, expenses and other liabilities resulting from a third-party claim against us or our affiliate arising from this Agreement and relating to a violation or claimed violation of the third party’s intellectual property rights, where the loss, damages or claim arises from us or an affiliate, using or permitting the use of, or being or having been the registered proprietor of a domain name, a brand name, trademark, logo or other intellectual property which the third-party claims violate its rights, or where it is claimed we or an affiliate have assisted or permitted you to use or to be a registered proprietor of such rights in violation of the third party’s rights.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>24.13.</strong> Other than as set out above, the Company’s maximum aggregate liability arising from any losses for paid licence subscription users under or in connection with these Terms is limited to the total amount of subscription fees paid by the User in the previous twelve (12) months.
                                  </Typography>

                                  {/* Section 25: Representation */}
                                  <Typography variant="h6" gutterBottom>
                                    25. Representation
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>25.1.</strong> The User has the right and power to enter into this Agreement, and entering into it will not breach any agreement the User has with a third party.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>25.2.</strong> By assenting to this Agreement, the User represents and warrants that they have the full right, power and authority to access and use the Software and, to the extent necessary, that they have obtained all necessary corporate or other authorisations or consents to access and use the Services. Thus, if you are an individual (e.g., employee or consultant) acting on behalf of an organisation, you represent and warrant that you have obtained all necessary authorisations or consents (i.e., you must be an authorised representative) to accept these Terms on behalf of such Organization so that, in addition to yourself, such Organization is bound by these Terms.
                                  </Typography>

                                  {/* Section 26: Severance */}
                                  <Typography variant="h6" gutterBottom>
                                    26. Severance
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>26.1.</strong> If any part of these Terms and Conditions is invalid by a court of Law, tribunal or another forum of competent jurisdiction or otherwise rendered unenforceable, that decision shall not invalidate or void the other parts of this Agreement. An amendment of these Terms and Conditions shall be deemed to have been undertaken by any modification or severing parts of the Terms and Conditions as necessary to render them valid, legal and enforceable while preserving their sole purpose. Or, if this is not possible, substituting another valid, legal and enforceable provision that gives equivalent effect to the Parties’ intent.
                                  </Typography>

                                  {/* Section 27: Termination of Agreement */}
                                  <Typography variant="h6" gutterBottom>
                                    27. Termination of Agreement
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>27.1.</strong> The Company may cease providing all parts of the Software at any time for any foreseen or unforeseen reasons, including, but not limited to, cessation of trading or bankruptcy. Nothing in these Terms is to be taken as a guarantee that the Software will always be available, either in its current form or an updated version.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>27.2.</strong> This Agreement may be terminated by either Party subject to a thirty (30) days written notice to the other Party via email.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>27.2.1.</strong> Should the User’s billing cycle fall during the thirty (30) days notice period, a full subscription shall be charged. Once the subscription is terminated, a refund will be issued on a pro-rata basis for any period the subscription covers past the termination date.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>27.2.2.</strong> During the thirty (30) days notice of termination, the User shall download all data stored on the Cloud Software for their account. All account data will be permanently deleted after the licence subscription is cancelled and this Agreement terminated. There is no possible way to retrieve deleted data.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>27.2.3.</strong> The User reserves the right to withdraw their termination request within the thirty (30) days’ notice period and continue the licence subscription without obstructions.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>27.3.</strong> Kayana may decide to terminate this agreement with immediate effect should the User or any of their employees and/or associates display any form of bullying, inappropriate, violent, aggressive, threatening, intimidating, racist and/or otherwise disrespectful behaviour; and/or use any form of offensive, aggressive, threatening, racist and/or otherwise disrespectful language towards any employee or associate of Kayana.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>27.3.1.</strong> In the event of a termination under this section 28.3, Kayana shall provide the User with immediate verbal or written notice and written confirmation via email within twenty-four (24) hours.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>27.3.2.</strong> Once the subscription is terminated, a refund will be issued on a pro-rata basis for any period the subscription covers past the termination date.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>27.4.</strong> Either Party (the “Terminating Party”) may terminate this Agreement immediately if:
                                    <ul>
                                      <li>27.4.1. The other Party files for bankruptcy, receivership, insolvency, reorganisation, dissolution, liquidation, or similar proceedings; or</li>
                                      <li>27.4.2. The other Party had a bankruptcy, receivership, insolvency, reorganisation, dissolution, liquidation, or similar proceedings proceeding instituted against it, and such proceeding is not dismissed within sixty (60) days; or</li>
                                      <li>27.4.3. The other Party makes an assignment for the benefit of its creditors or an offer of settlement, extension or composition to its creditors; or</li>
                                      <li>27.4.4. A trustee, conservator, receiver, or similar fiduciary is appointed for that party or substantially all of the other Party’s assets; or</li>
                                      <li>27.4.5. The other Party commits any fraud or misrepresentation or engages in any act or omission that may damage the reputation, business, or goodwill of the Terminating Party; or</li>
                                      <li>27.4.6. The other Party is found to be involved in any form of child labour, modern slavery, human trafficking, forced and bonded labour and human rights violations, money laundering or terrorist activity; or</li>
                                      <li>27.4.7. The other Party breaches any of the provisions of this Agreement.</li>
                                    </ul>
                                  </Typography>

                                  {/* Section 28: Law and Jurisdiction */}
                                  <Typography variant="h6" gutterBottom>
                                    28. Law and Jurisdiction
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>28.1.</strong> Except as outlined in Section 21, any dispute arising from or related to this Agreement should, wherever possible, be resolved amicably through negotiations between the Parties within thirty (30) days following the receipt of a written notification by either party regarding the existence of a dispute. If a resolution is not achieved within these thirty days, the Parties shall then seek to settle the dispute through mediation before considering litigation. Upon the initiation of this mediation process, the Parties will endeavour to mutually agree on the selection of a mediator, following the receipt of a written request from either party to agree on such an appointment. In the event that mediation is unsuccessful, either wholly or partially, either Party is then at liberty to bring the dispute or claim before a court for a final judgement.
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>28.2.</strong> These terms of use and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed by the laws of England and Wales.
                                  </Typography>

                                  {/* Section 29: Entire Agreement */}
                                  <Typography variant="h6" gutterBottom>
                                    29. Entire Agreement
                                  </Typography>
                                  <Typography paragraph>
                                    <strong>29.1.</strong> This Agreement, together with any supporting documents added in the Appendix, constitutes the entire Agreement between the Company and the User and supersedes any prior written or oral agreement concerning the subject matter hereof.
                                  </Typography>

                                </div>
                              </DialogContentText>
                            </DialogContent>
                            {/* <DialogActions>
                              <Button onClick={handleClose}>Cancel</Button>
                              <Button onClick={() => {
                                setFieldValue('acceptTerms', true);
                                handleClose();
                              }
                              }>Accept</Button>
                            </DialogActions> */}
                          </Dialog>

                          {props.state.loading ? (
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid>
                                <CircularProgress />
                              </Grid>
                            </Grid>
                          ) : (
                            <div>
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={{ mt: 1, mb: 2 }}
                              //disabled={!(isValid && dirty)}
                              >
                                Sign Up
                              </Button>
                            </div>
                          )}
                          <Grid container>
                            <Grid item>
                              <Link to="/" variant="body2">
                                {"Already have an account? Sign In"}
                              </Link>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Grid>
              </Grid>

            </div>
          </main>
        </div>
      )}
    </Fragment>
  );
};

export default forwardRef(SignupForm);
