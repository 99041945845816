import React, { Component } from "react";
import countryList from "react-select-country-list";
import { toast } from "react-toastify";
import * as SecurityServiceAPI from "../../services/user/SecurityServiceAPI";
import "../login/style.css";
import SignupForm from "./SignupForm";
import "./style.css";
import * as message from "../../utils/Message";
import SimpleModal from "../../shared/Modal/SimpleModal";
import { Accordion, Card } from "react-bootstrap";
import { FlapperSpinner } from "react-spinners-kit";

export default class SignUp extends Component {
  state = {
    country_options: "",
    property_country: "",
    currency: "",
    currency_details: [],
    country: "",
    username: "",
    otp: "",
    tnc_id: "",
    tnc_details: "",
    tnc_acceptance: false,
    loading: false,
    showPassword: false,
    confirmShowPassword: false,
    confirm_signup: false,
    business_place_form: false,
    toggle_password: false,
    toggle_password1: false,
    dial_code: null,
    phone_no: null,
  };

  componentDidMount() {
    this.setState({ country_options: countryList().getData() });
    SecurityServiceAPI.fetchAllCurrencies().then((response) => {
      if (response?.data?.status) {
        this.setState({
          currency_details: Object.values(response.data.data.currency_details),
        });
      }
    });

    SecurityServiceAPI.fetchTermsAndCondition().then((response) => {
      if (response?.data?.status) {
        this.setState({
          tnc_id: response.data.data.tnc_id,
          tnc_details: response.data.data,
        });
      }
    });
  }

  handleCountryOnChange = (value) => {
    this.setState({
      country: value,
      property_country: value,
    });
  };

  handleModal = () => {
    this.setState({ show: !this.state.show });
  };

  onChangeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleClickShowPassword = (current) => {
    this.setState({
      [current]: !this.state[current],
    });
  };

  confirmSignupHandler = (event) => {
    event.preventDefault();
    const payload = {
      otp: this.state.otp,
      username: this.state.username,
    };


    if (!this.state.otp) {
      toast.warn("Otp is required. ");
      return false;
    } else if (this.state.otp.toString().length != 6) {
      toast.warn("Otp 6 digits required. ");
      return false;
    }

    if (payload.otp !== "" && this.state.username !== "") {
      SecurityServiceAPI.confirmSignup(payload)
        .then((response) => {
          if (response.data.status) {
            toast.success(message.SIGNUP_SUCCESS);
            this.props.history.push("/");
            this.setState({ otp: "" });
          } else {
            if (response.data?.message == "Attempt limit exceeded, please try after some time.") {
              toast.warn(response.data.message);
              this.props.history.push({pathname: '/'})
            } else {
              toast.warn(response.data.message);
            }

          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.warn(message.OTP_VALIDATION_MESSAGE);
    }
  };

  reversePhoneNumber = (str) => {
    var splitString = str.split("");
    var reverseArray = splitString.reverse();
    var reverse_string = reverseArray.join("");
    return reverse_string;
  };

  splitString = (str) => {
    var reverse_number = str.substring(0, 10);
    var reverse_dialcode = str.substring(10);
    var phone_no = this.reversePhoneNumber(reverse_number);
    var dialcode = this.reversePhoneNumber(reverse_dialcode);
    const form = {
      phone_number: phone_no,
      dial_code: "+" + dialcode,
    };
    return form;
  };

  signupHandler = (payload) => {
    this.setState({ loading: true });

    const signupPayload = {
      name: payload.businessName,
      email: payload.email,
      password: payload.password,
      dial_code: this.state.dial_code,
      phone_number: this.state.phone_no,
    };

    try {
      SecurityServiceAPI.signup(signupPayload)
        .then((response) => {
          console.log(response)
          if (response?.data?.status) {
            // Check if Account has been verified already
            if (response.data?.data?.account_verified === true) {
              toast.warn(response?.data?.message);
              this.setState({loading:false})
            } else {
              toast.success(message.SIGN_UP_FIRST_STEP);
              this.setState({
                username: response?.data?.data?.username,
                confirm_signup: true,
                loading: false,
              });
            }
          } else {
            toast.warn(response?.data?.message);
            console.log(response)
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error.response.data.message)
          toast.error(error.response.data.message);
        });
    } catch (err) {
      this.setState({ loading: false });
      toast.error("Something went wrong, please try again");
    }
  };

  businessPlaceFormHandler = (values) => {
    var getCountry = require("country-currency-map").getCountry;
    const currency = getCountry(this.state.country.label);
    this.setState({ loading: true });

    const businessPlaceForm = {
      property_name: values.property_name,
      property_address:
        values.property_address + " , " + values.second_line_address,
      property_country_code: this.state.country.value,
      currency_code: currency.currency,
      property_country: this.state.country.label,
      property_city: values.city,
      postal_code: values.postal_code,
      username: this.state.username,
    };

    let formData = new FormData();
    formData.append("property_details ", JSON.stringify(businessPlaceForm));

    if (this.state.property_country !== "") {
      SecurityServiceAPI.addBusinessPlace(formData)
        .then((response) => {
          if (response.data.status) {
            this.setState({ business_place_form: false, loading: false });
            toast.success(message.BUSINESS_PLACE_SUCCESS_MESSAGE);
            this.props.history.push("/");
          } else {
            toast.warn(response.data.message);
            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          toast.error(message.CATCH_ERROR_MESSAGE);
          this.setState({
            loading: false,
          });
        });
    } else {
      toast.warn(message.COUNTRY_VALIDATION);
      this.setState({
        loading: false,
      });
    }
  };

  resendOtp = (e) => {

    e.preventDefault();

    SecurityServiceAPI.resendOTP(this.state.username).then((response) => {
      if (response.data.status) {
        toast.success(message.OTP_SUCCESS);
      } else {
        toast.error(message.OTP_FAILURE);
      }
    });
  };

  togglePasswordHide = () => {
    this.setState({ toggle_password: !this.state.toggle_password });
  };
  togglePasswordConfirmHide = () => {
    this.setState({ toggle_password1: !this.state.toggle_password1 });
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  setPhone = (val) => {
    this.setState({ dial_code: val.dialCode, phone_no: val.phone });
  };

  render() {
    return (
      <div className="ms-body ms-primary-theme ms-logged-out">
        <main className="body-content">
          <SignupForm
            resendOtp={this.resendOtp}
            handleModal={this.handleModal}
            handleClickShowPassword={this.handleClickShowPassword}
            togglePasswordHide={this.togglePasswordHide}
            togglePasswordConfirmHide={this.togglePasswordConfirmHide}

            state={this.state}
            setPhone={this.setPhone}
            confirmSignupHandler={this.confirmSignupHandler}
            onChange={this.onChangeHandler}
            signupHandler={this.signupHandler}
          />
        </main>
      </div>
    );
  }
}
