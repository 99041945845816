import React, { Component } from "react";
import { QRCode } from "antd";
import { toast } from "react-toastify";
import Breadcrumb from "./Breadcrumb";
import { Fragment } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import { FormControl } from "@mui/material";
import * as schema from "../../utils/Schema";
import { Form, Formik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
//import Checkbox from '@mui/material/Checkbox';
import DataTable from "react-data-table-component";
import moment from "moment";
import "@arco-design/web-react/dist/css/arco.css";
import * as RoomServiceAPI from "../../services/room/RoomServiceAPI";
import * as QRServiceAPI from "../../services/qr/QRServiceAPI";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { connect } from "react-redux";
import Loader from "../../shared/Loader";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import * as PlanServiceAPI from "../../services/plans/PlanServiceAPI";
import { withRouter } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

class index extends Component {
  state = {
    isLoading: false,
    initialValues: {},
    isLoading: false,
    discounts: [],
    discount: null,
    rooms: [],
    roomList: [],
    room: null,
    selectedDiscount: null,
    selectedDiscountVoucher: "",
    page: 0,
    rowsPerPage: 10,
    sortDirection: "asc",
    open: false,
    dId: "",
    roomId: "",
    menuNames: /* [{ "menu_id": "1", "menu_identifier": "One" }] || */ [],
    selectedMenu: "",
    itemNames: /* [{ "menu_id": "1", "menu_identifier": "One" }] || */ [],
    selectedItem: [],
    selectedItems: [],
    categoryNames: [],
    itemIdentifiers: [],
    itemIds: [],
    itemMap: {},
    selectedCategory: "",
    item_list: [],
    treeCheckStrictly: false,
    value: [],
    treeData: [],
    error: "",
    startDate: null,
    endDate: null,
    start_time: moment(),
    end_time: moment(),
    room_identifier: "",
    submit: false,
    openRoom: false,
    roomRow: null,
    isActive: true,
    openAlert: true,
    openDialog: false,
    confirmLoading: false,
    is_bulk: false,
    showForm: false,
    //defaultRange: [moment(new Date(), 'HH:mm:ss'), moment(new Date(), 'HH:mm:ss')]
  };

  columns = [
    {
      name: "Room",
      selector: "room_identifier",
      sortable: true,
      justifyContent: "center",
      width: "200px",
      cell: (row) => (
        <div>
          <div>{row.room_identifier}</div>
        </div>
      ),
    },

    /*    {
         name: "Start Time",
         selector: "start_time",
         sortable: true,
         justifyContent: 'center',
         cell: row => <div ><div>{row.start_time}</div></div>
       },
   
       {
         name: "End Time",
         selector: "end_time",
         sortable: true,
         justifyContent: 'center',
         cell: row => <div > {row.end_time}</div>
       },
   
   
   
       {
         name: "No of persons",
         selector: "number_of_person_per_room",
         sortable: true,
         justifyContent: 'center',
         cell: row => <div >   {row.number_of_person_per_room}</div>
       },
   
   
       {
         name: "Reserve Time Slot",
         selector: "reserve_time_slot",
         sortable: true,
         justifyContent: 'center',
         cell: row => <div >   {row.reserve_time_slot}</div>
       },
       {
         name: "Reserve Time Slot Unit",
         selector: "reserve_time_slot_unit",
         sortable: true,
         justifyContent: 'center',
         cell: row => <div >   {row.reserve_time_slot_unit}</div>
       },
       {
         name: "Room Status",
         selector: "room_status",
         sortable: true,
         justifyContent: 'center',
         cell: row => <div >   {row.room_status}</div>
       }, */
    {
      name: "QR",
      selector: "room_dynamic_link",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <React.Fragment>
          {/*  <div id={row.room_identifier} style={{ display: 'flex', alignItems: 'center' }}>
          <QRCode size={100} value={row.room_dynamic_link} bgColor="#fff" style={{ marginRight: 16 }} />
        </div> */}
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                this.handleRoomOpen(row);
              }}
            >
              VIEW QR
            </Button>
          </Grid>
        </React.Fragment>
      ),
    },
    {
      name: "Action",
      selector: "action",
      justifyContent: "center",
      width: "150px",
      cell: (row) => (
        <div>
          {/*  <Tooltip
          title="Edit / Update Room."
          placement="top"
        >
          <IconButton color="primary" onClick={() => this.editDiscount(row)} aria-label="Edit">
            <EditIcon />
          </IconButton>
        </Tooltip> */}
          <Tooltip title="Delete Room." placement="top">
            {/* <IconButton
              color="primary"
              onClick={() => this.handleClickOpen(row.room_id)}
              aria-label="delete"
              size="large"
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton> */}
            <Button variant="outlined" startIcon={<DeleteIcon color="primary"/>} onClick={() => this.handleClickOpen(row.room_id)}>Delete</Button>
          </Tooltip>
          {!row.table_dynamic_link && (
            <Tooltip title="Generate QR." placement="top">
              {!row.room_dynamic_link && (
                // <IconButton
                //   color="primary"
                //   onClick={() => this.generateRoomLink(row.room_id)}
                //   aria-label="delete"
                //   size="large"
                // >
                //   <AddBoxIcon fontSize="inherit" />
                // </IconButton>
                <Button variant="outlined" startIcon={<AddBoxIcon color="primary"/>} onClick={() => this.generateRoomLink(row.room_id)}>Add</Button>

              )}
            </Tooltip>
          )}
          {/*  <Button type="primary" onClick={() => {
          this.downloadQRCode(row?.room_identifier)
        }}>
          <Tooltip
            title="Download QR."
            placement="top" >
            < DownloadForOfflineIcon />
          </Tooltip>
        </Button> */}
        </div>
      ),
    },
  ];

  customStyles = {
    rows: {
      style: {
        //          minHeight: '70px', // override the row height
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "65px",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "auto",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        justifyContent: "center",
        "&:hover div": {
          backgroundColor: "transparent",
          color: "white !important",
        },
        div: {
          color: "white !important",
        },
        span: {
          color: "white !important",
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "2px", // override the cell padding for data cells
        paddingRight: "2px",
        pointer: "cursor",
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  componentDidMount = () => {
    console.log("MOUNT");
    this.fetchAllDiscounts();
    this.setInitalValues();
    this.fetchPlansStatus();
  };

  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };
  handleClose = () => {
    this.setState({ openDialog: false });
  };

  downloadQRCode = (name) => {
    console.log(document.getElementById(name));
    const canvas = document.getElementById(name)?.querySelector("canvas");
    console.log(canvas);
    if (canvas) {
      // Get the current size of the QR code
      const qrCodeSize = canvas.width;

      // Create a new canvas with a larger size to accommodate the border
      const borderedCanvas = document.createElement("canvas");
      const borderedCtx = borderedCanvas.getContext("2d");

      // Set the size of the new canvas including the border
      borderedCanvas.width = qrCodeSize + 20; // 20 is the width of the white border
      borderedCanvas.height = qrCodeSize + 20; // 20 is the height of the white border

      // Draw a white border
      borderedCtx.fillStyle = "#ffffff"; // Set the color of the white border
      borderedCtx.fillRect(0, 0, borderedCanvas.width, borderedCanvas.height);

      // Draw the original QR code on top of the white border
      borderedCtx.drawImage(canvas, 10, 10, qrCodeSize, qrCodeSize); // 10 is the border width

      // Create a download link
      const url = borderedCanvas.toDataURL();
      const a = document.createElement("a");
      a.download = name + ".jpg";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  filterOrders = (event) => {
    event.preventDefault();
    const searchValue = event.target.value.toLowerCase();

    // Use the original tableList for resetting the filter
    if (!searchValue) {
      this.setState({ rooms: this.state.roomList });
      return;
    }

    // Filter the data based on the search value
    const filteredData = this.state.roomList.filter((item) =>
      item.room_identifier.toLowerCase().trim().includes(searchValue.trim())
    );

    this.setState({ rooms: filteredData });
  };

  fetchPlansStatus = async () => {
    try {
      let propertId = this.props.propertyId;
      const response = await PlanServiceAPI.fetchPlansStatus(propertId);

      if (response.data.status) {
        this.setState({ qr: response.data.data });
        if (!response.data.data) {
          /*  this.props.history.push({
             pathname: "/manage-qr-flow",
           }); */
        }
      } else {
        this.setState({ qr: response.data.data });
      }
    } catch (error) {
      //  this.setState({ isActive: false });
      throw error;
    }
  };

  handleClickOpen = (roomId) => {
    this.setState({ open: true, roomId: roomId });
  };

  handleCancel = () => {
    this.setState(
      {
        discount: null,
        selectedDiscount: "",
        value: [],
        item_list: [],
      },
      () => {
        this.setInitalValues();
      }
    );
  };

  handleClose = () => {
    this.setState({ open: false, openDialog: false, dId: "" });
  };

  setInitalValues = () => {
    const dateFormat = "DD-MM-YYYY HH:mm:ss";
    const { room } = this.state;
    console.log(room);
    let initialValues = {};

    initialValues = {
      /*   start_time: room?.starTime
          ? moment(room?.starTime, dateFormat)
          : moment(), // Use the current time if start_time is not available
        end_time: room?.endTime
          ? moment(room?.endTime, dateFormat)
          : moment(), // Use the current time if end_time is not available
        reserve_time_slot: room?.reserve_time_slot || '',
        reserve_time_slot_unit: room?.reserve_time_slot_unit || "MINUTES",
        room_count: room?.room_count || 1,
        number_of_person_per_room: room?.number_of_person_per_room || '',
        minimum_spend_on_room_reservation: room?.minimum_spend_on_room_reservation || '', */
      room_names: room?.room_identifier || "",
      room_count: 1,
      is_bulk: false,
    };

    console.log(initialValues);

    this.setState({
      initialValues,
      /* start_time: initialValues.start_time,
      end_time: initialValues.end_time, */
    });
  };

  fetchAllDiscounts = () => {
    this.setState({ isLoading: true });
    let propertId = this.props.propertyId;
    RoomServiceAPI.fetchAllRoom(propertId)
      .then((response) => {
        if (response.data.status) {
          console.log(response.data.data);
          this.setState({
            rooms: response.data.data.room_info,
            roomList: response.data.data.room_info,
            isLoading: false,
          });
          //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
          //  toast.success("Discount added. ")
        } else {
          this.setState({ rooms: [], isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not fetch rooms.. ");
      });
  };

  addRoom = (values, resetForm) => {
    this.setState({ submit: true });
    if (this.state.is_bulk) {
      delete values["room_names"];
      RoomServiceAPI.addRoom(values)
        .then((response) => {
          if (response.data.status) {
            //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
            toast.success("Room added. ");
            resetForm({ values: "" });
            //      this.setState({ discount: null, selectedDiscount: null, selectedDiscountVoucher: null, discount_code: "" });
            this.setState(
              {
                room: null,
                is_bulk: false,
                submit: false,
                showForm: false,
                isLoading: false,
              },
              () => {
                this.setInitalValues();
              }
            );
            this.fetchPlansStatus();
            this.fetchAllDiscounts();
          } else {
            this.setState(
              { room: null, submit: false, showForm: false, isLoading: false },
              () => {
                this.setInitalValues();
              }
            );
            if (response.data?.message == "No active plan found.") {
              this.setState({ openDialog: true, isLoading: false });
            } else {
              toast.warn(response.data?.message);
              this.setState({ isLoading: false });
            }
            // toast.warn(response.data?.message);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, submit: false });
          toast.warn("Could not add room.. ");
        });
    } else {
      delete values["room_count"];
      console.log(values);
      RoomServiceAPI.addRoom(values)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data.data);
            //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
            toast.success("Room added. ");
            resetForm({ values: "" });
            //      this.setState({ discount: null, selectedDiscount: null, selectedDiscountVoucher: null, discount_code: "" });
            this.setState(
              { room: null, submit: false, showForm: false, isLoading: false },
              () => {
                this.setInitalValues();
              }
            );
            this.fetchPlansStatus();
            this.fetchAllDiscounts();
          } else {
            this.setState({ room: null, submit: false }, () => {
              this.setInitalValues();
            });
            if (response.data?.message == "No active plan found.") {
              this.setState({ openDialog: true, isLoading: false });
            } else {
              this.setState({ isLoading: false });
              toast.warn(response.data?.message);
            }
            // toast.warn(response.data?.message);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, submit: false });
          toast.warn("Could not add room.. ");
        });
    }
  };

  updateDiscount = (values, resetForm) => {
    const payload = {
      property_id: this.props.propertyId,
      room_info: [
        {
          /*    "end_time": values['end_time'],
             "minimum_spend_on_room_reservation": values['minimum_spend_on_room_reservation'],
             "number_of_person_per_room": values['number_of_person_per_room'],
             "reserve_time_slot": values['reserve_time_slot'],
             "reserve_time_slot_unit": values['reserve_time_slot_unit'],
             "start_time": values['start_time'],
             "room_id": this.state.room.room_id,
             "room_status": values['room_status'], */
          room_id: this.state.room.room_id,
          room_identifier: this.state.room_identifier,
        },
      ],
    };

    RoomServiceAPI.updateRoom(payload)
      .then((response) => {
        if (response.data.status) {
          console.log(response.data.data);
          //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
          toast.success("Room updated. ");
          resetForm({ values: "" });
          this.setState(
            { room: null, room_identifier: "", isLoading: false },
            () => {
              this.setInitalValues();
            }
          );
          this.fetchAllDiscounts();
        } else {
          toast.warn(response.data.message);
          resetForm({ values: "" });
          this.setInitalValues();
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not update room.. ");
      });
  };

  editDiscount = (row) => {
    this.setState({ room: row }, () => {
      this.setInitalValues();
      toast.success("Room Retrieved.");
    });

    /*     RoomServiceAPI.fetchRoom(row)
          .then((response) => {
            if (response.data.status) {
              console.log(response.data.data);
              this.setState({ room: response.data.data.room_info }, () => {
                this.setInitalValues();
                console.log(this.state);
                toast.success("Happy hour activity details retrieved.");
              });
    
            } else {
              toast.warn(response.data.message);
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            toast.warn("Could not add discount.. ")
          }); */
  };

  generateRoomLink = (roomId) => {
    const payload = {
      room_id: roomId,
      property_id: this.props.propertyId,
    };
    QRServiceAPI.generateRoomDynamicLink(payload)
      .then((response) => {
        if (response.data.status) {
          console.log(response.data.data);
          toast.success("Room  QR link genrated.");
          setTimeout(() => {
            this.fetchAllDiscounts();
          }, 700);
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not generate a room QR link. ");
      });
  };

  disabledDate = (current) => {
    // Get the current date and time
    const currentDate = new Date();
    // Disable if the current date is after the selected date
    return current && current < currentDate;
  };

  disabledRangeTime = () => {
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();
    const currentSecond = new Date().getSeconds();

    // Disable hours that are earlier than the current hour
    const disabledHours = () => {
      const hours = [];
      for (let i = 0; i < currentHour; i++) {
        hours.push(i);
      }
      return hours;
    };

    // Disable minutes that are earlier than the current minute when the current hour is selected
    const disabledMinutes = (selectedHour) => {
      if (selectedHour === currentHour) {
        const minutes = [];
        for (let i = 0; i < currentMinute; i++) {
          minutes.push(i);
        }
        return minutes;
      }
      return [];
    };

    // Disable seconds that are earlier than the current second when the current hour and minute are selected
    const disabledSeconds = (selectedHour, selectedMinute) => {
      if (selectedHour === currentHour && selectedMinute === currentMinute) {
        const seconds = [];
        for (let i = 0; i < currentSecond; i++) {
          seconds.push(i);
        }
        return seconds;
      }
      return [];
    };

    return {
      disabledHours,
      disabledMinutes,
      disabledSeconds,
    };
  };
  handleDiscountSubmit = (values, resetForm) => {
    if (!this.state.room) {
      this.addRoom(values, resetForm);
      if (this.state.is_bulk) {
        toast.success("QR codes are being created. Please wait", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
      this.setState({ isLoading: true });
    } else {
      this.updateDiscount(values, resetForm);
      this.setState({ isLoading: true });
    }
  };

  deleteDiscount = (roomId) => {
    let del = {
      /* property_id: this.props.propertyId, */
      room_id: roomId,
    };
    RoomServiceAPI.deleteRoom(del)
      .then((response) => {
        if (response.data.status) {
          console.log(response.data.data);
          toast.success("Room  deleted. ");
          this.setState({ open: false, showForm: false });
          this.fetchAllDiscounts();
          this.setState({ room: null }, () => {
            this.setInitalValues();
          });
        } else {
          toast.warn("Couldn't delete. ");
          this.setState({ room: null }, () => {
            this.setInitalValues();
          });
          this.setState({ isLoading: false, open: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, open: false });
        toast.error("Something went wrong. ");
      });
  };

  handleRangeChange = (value) => {
    // Handle the selected range here
    console.log(value);
    if (value && value.length === 2) {
      const [startDate, endDate] = value.map((date) =>
        moment(date).format("HH:mm:ss")
      );

      this.setState({
        error: "",
        startDate: moment(startDate),
        endDate: moment(endDate),
        defaultRange: [moment(startDate), moment(endDate)],
      });
      console.log("Start Date/Time:", startDate);
      console.log("End Date/Time:", endDate);
    }
  };

  handleStartTimeChange = (time) => {
    console.log(time);
    this.setState({ start_time: time });
  };

  handleEndTimeChange = (time) => {
    console.log(time);
    this.setState({ end_time: time });
  };

  handleRoomOpen = (row) => {
    this.setState({ openRoom: true, roomRow: row });
  };

  handleRoomClose = () => {
    this.setState({ openRoom: false, roomRow: null });
  };

  showForm = () => {
    if (!this.state.showForm) {
      this.setState({
        showForm: true,
      });
    } else {
      this.setState(
        {
          showForm: false,
          room: null,
          is_editable: true,
        },
        () => {
          this.setInitalValues();
        }
      );
    }
  };

  render() {
    const {
      submitting,
      initialValues,
      error,
      showForm,
      rooms,
      openRoom,
      roomRow,
      isActive,
      qr,
      openDialog,
      confirmLoading,
      is_bulk,
    } = this.state;
    const okButtonStyle = {
      backgroundColor: "#5773ff",
      borderColor: "#5773ff",
      color: "#FFFFFF !important",
    }; // Green color
    const cancelButtonStyle = {
      backgroundColor: "#5773ff",
      borderColor: "#5773ff",
      color: "#FFFFFF !important",
    }; // Red color
    return (
      <Fragment>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <Breadcrumb />
            </div>
            <div className="col-md-12">
              <div className="ms-panel">
                {/*  <h6>    {this.state.room ? "Update" : "Add"} Room</h6> */}
                {!showForm && (
                  <React.Fragment>
                    <div className="ms-panel-header">
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6} lg={6}>
                          <h5>Room</h5>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.showForm}
                          >
                            {this.state.Table ? "Update" : "Add"} Room
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </React.Fragment>
                )}

                <div className="ms-panel-body"></div>
                <div>
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Delete Room?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Do you really want to proceed?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose}>No</Button>
                      <Button
                        onClick={() => this.deleteDiscount(this.state.roomId)}
                      >
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <BootstrapDialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openDialog}
                  >
                    <DialogTitle
                      sx={{ m: 0, p: 2 }}
                      id="customized-dialog-title"
                    >
                      Plan Expired.
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={this.handleClose}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                      <Typography gutterBottom>
                        Your current plan has expired. Please renew your
                        existing plan or upgrade to a new plan.
                      </Typography>
                      {/* <Typography gutterBottom>
                        You may create upto {qr?.qr_available} dynamic codes using your account. if you require more please upgrade.
                      </Typography> */}
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          this.setState({ openDialog: false });
                        }}
                      >
                        No Thanks.
                      </Button>
                      <Button
                        variant="contained"
                        autoFocus
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/pricing",
                          });
                        }}
                      >
                        See Plans and Pricing.
                      </Button>
                    </DialogActions>
                  </BootstrapDialog>
                </div>
                <Dialog
                  open={this.state.openRoom}
                  onClose={this.handleRoomClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"ROOM QR"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <div id={roomRow?.room_identifier}>
                        <QRCode
                          size={350}
                          /* color="#5773ff" */ errorLevel="H"
                          value={roomRow?.room_dynamic_link}
                          bgColor="#fff"
                          style={{ marginRight: 16 }}
                        />
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleRoomClose}>Close</Button>
                    <Button
                      variant="contained"
                      autoFocus
                      color="primary"
                      onClick={() => {
                        this.downloadQRCode(roomRow?.room_identifier);
                      }}
                    >
                      Download
                    </Button>
                  </DialogActions>
                </Dialog>

                {showForm && (
                  <React.Fragment>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ margin: "10px" }}
                    >
                      <div style={{}}>
                        Create Room
                        <Button
                          variant="contained"
                          style={{ float: "right" }}
                          color="primary"
                          onClick={this.showForm}
                        >
                          Back
                        </Button>
                      </div>
                    </Typography>
                    <p style={{ margin: "10px" }}>
                      Complete the following details to create a room.
                    </p>
                    <Divider />
                    <br /> <br />
                    <Formik
                      enableReinitialize="true"
                      initialValues={initialValues}
                      validationSchema={
                        !is_bulk ? schema.roomSchema : schema.roomBulkSchema
                      }
                      onSubmit={(values, { resetForm }) => {
                        //delete values['rooms_names'];
                        //values['rooms_names'] = [values['rooms_names']];
                        /*   if (start_time && end_time) {
                         if (start_time.isAfter(end_time)) {
                           this.setState({ error: "Start time cannot be after end time" })
                           return false;
                         }
                       } */
                        values["property_id"] = this.props.propertyId;
                        //       values['start_time'] = this.state.start_time.format("HH:mm:ss");
                        //       values['end_time'] = this.state.end_time.format("HH:mm:ss");

                        this.handleDiscountSubmit(values, resetForm);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Box sx={{ flexGrow: 1, margin: "15px" }}>
                            <Grid container spacing={3} direction="row">
                              {!is_bulk && (
                                <Grid item xs={6} md={3} lg={3}>
                                  <FormControl fullWidth>
                                    <TextField
                                      error={
                                        touched.room_names && errors.room_names
                                          ? true
                                          : false
                                      }
                                      name="room_names"
                                      label="Room Name"
                                      autoComplete="room_names"
                                      type="text"
                                      variant="standard"
                                      pattern="\s*\S+.*"
                                      value={values.room_names || ""}
                                      onChange={(e) => {
                                        setFieldValue("room_names", [
                                          e.target.value,
                                        ]);
                                        this.setState({
                                          room_identifier: e.target.value,
                                        });
                                      }}
                                      aria-describedby="component-error-text"
                                    />
                                    <FormHelperText error>
                                      {touched.room_names && errors.room_names
                                        ? errors.room_names
                                        : null}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              )}
                              {is_bulk && (
                                <Grid item xs={6} md={3} lg={3}>
                                  <FormControl fullWidth>
                                    <TextField
                                      error={
                                        touched.room_count && errors.room_count
                                      }
                                      name="room_count"
                                      label="Room Count"
                                      type="number"
                                      variant="standard"
                                      value={values.room_count || ""}
                                      onInput={(event) => {
                                        if (!Number.isNaN(event.target.value)) {
                                          event.target.value =
                                            event.target.value;
                                        } else {
                                          event.target.value = "";
                                        }
                                      }}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "room_count",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <FormHelperText error>
                                      {touched.room_count && errors.room_count
                                        ? errors.room_count
                                        : null}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              )}
                              <Grid item xs={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                  <FormControlLabel
                                    name="is_bulk"
                                    value={is_bulk}
                                    onChange={(e) => {
                                      this.setState({
                                        is_bulk: e.target.checked,
                                      });
                                      //   handleChange();
                                      setFieldValue(
                                        "is_bulk",
                                        e.target.checked
                                      );
                                      this.setInitalValues();
                                    }}
                                    control={<Switch />}
                                    label="Is Bulk"
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Grid sx={{ display: "flex" }}>
                              <Button
                                type="submit"
                                // disabled={this.state.submit}
                                disabled={qr.subscription_status === "EXPIRED"}
                                color="primary"
                                variant="contained"
                                sx={{ mt: 2, mb: 2, marginLeft: "20px" }}
                              >
                                {this.state?.room ? "Update" : "Submit"}
                              </Button>
                              {this.state?.discount && (
                                <Button
                                  type="button"
                                  color="primary"
                                  variant="contained"
                                  block
                                  onClick={() => this.handleCancel()}
                                  sx={{ mt: 2, mb: 2, marginLeft: "20px" }}
                                >
                                  Cancel
                                </Button>
                              )}
                              {/* <Typography>
                              {qr.subscription_status === "EXPIRED" ? (
                                "Purchase QR plan"
                              ) : (
                                ""
                              )}
                              </Typography> */}
                            </Grid>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  </React.Fragment>
                )}

                {!showForm && (
                  <React.Fragment>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      <Grid item lg={3} xs={12}></Grid>
                      <Grid item lg={3} xs={12}></Grid>
                      <Grid item lg={2} xs={12}></Grid>

                      <Grid
                        item
                        lg={3}
                        xs={12}
                        style={{ marginTop: "4px", textAlign: "right" }}
                      >
                        <TextField
                          label="Search Room"
                          onChange={this.filterOrders}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <div className="ms-invoice-table table-responsive mt-5">
                      <DataTable
                        columns={this.columns}
                        customStyles={this.customStyles}
                        pagination
                        paginationRowsPerPageOptions={[
                          10, 25, 50, 100, 500, 1000,
                        ]}
                        data={rooms}
                      />
                    </div>{" "}
                  </React.Fragment>
                )}
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default withRouter(connect(mapStateToProps, {})(index));
