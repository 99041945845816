import React from "react";
import "./itemDialog.css";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MultipleImageCropper from "../../../shared/Cropper/MultipleImageCropper";
import DataTable from "react-data-table-component";
import {getCurrencySymbol} from "../../../config";


const itemModalFormSchema = Yup.object().shape({
  item_identifier: Yup.string().required("Invalid Item Name"),
  item_description: Yup.string().required("Invalid Item Description"),
  quantity: Yup.number().required("Invalid Quantity"),
  item_cost: Yup.number().required("Invalid Price"),
  sort_order: Yup.number().required("Invalid Sort Order"),
});

const ItemDialogue = (props) => {
  const customStyles = {
    rows: {
      style: {
        minHeight: "70px", // override the row height
      },
    },
    headCells: {
      style: {
        minHeight: "65px",
        textAlign: "center",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const option_columns = [
    {
      name: "Label",
      selector: "addon_identifier",
      sortable: true,
      cell: (row) => (
        <div>
          <div className="dt-description-100" title={row.addon_identifier}>
            {row.addon_identifier}
          </div>
        </div>
      ),
    },
    {
      name: "Description",
      selector: "addon_description",
      sortable: true,
      cell: (row) => (
        <div>
          <div className="dt-description-300" title={row.addon_description}>
            {row.addon_description}
          </div>
        </div>
      ),
    },
    {
      cell: (row) => (
        <div>
          <div>
            <button
              className="edit-btn"
              onClick={() => props.handleEditOptions(row)}
            >
              <span className="edit-icon"></span>
            </button>
          </div>
        </div>
      ),
      width: "40px",
      minWidth: "40px",
      maxWidth: "40px",
    },
    {
      cell: (row) => (
        <div>
          <div>
            <button
              className="delete-btn"
              onClick={() => props.deleteModalShow(row)}
            >
              <span className="delete-icon"></span>
            </button>
          </div>
        </div>
      ),
      width: "40px",
      minWidth: "40px",
      maxWidth: "40px",
    },
    {
      cell: (row) => (
        <div>
          <div>
            <button onClick={() => props.viewSubAddons(row, 1)}>
              <span className="eye-icon">+</span>
            </button>
          </div>
        </div>
      ),
      width: "40px",
      minWidth: "40px",
      maxWidth: "40px",
    },
  ];

  const extras_columns = [
    {
      name: "Label",
      selector: "addon_identifier",
      sortable: true,
      cell: (row) => (
        <div>
          <div className="dt-description-100" title={row.addon_identifier}>
            {row.addon_identifier}
          </div>
        </div>
      ),
    },
    {
      name: "CostMax",
      selector: "addon_cost",
      sortable: true,
      cell: (row) => (
        <div>
          <div>{getCurrencySymbol(localStorage.getItem('currency_code')) + row.addon_cost}</div>
        </div>
      ),
    },
    {
      name: "Quantity",
      selector: "total_addons",
      sortable: true,
      cell: (row) => (
        <div>
          <div>{row.total_addons}</div>
        </div>
      ),
    },
    {
      name: "Description",
      selector: "addon_description",
      sortable: true,
      cell: (row) => (
        <div>
          <div className="dt-description-100" title={row.addon_description}>
            {row.addon_description}
          </div>
        </div>
      ),
    },
    {
      cell: (row) => (
        <div>
          <div>
            <button
              className="edit-btn"
              onClick={() => props.handleEditExtras(row)}
            >
              <span className="edit-icon"></span>
            </button>
          </div>
        </div>
      ),
      width: "40px",
      minWidth: "40px",
      maxWidth: "40px",
    },
    {
      cell: (row) => (
        <div>
          <div>
            <button
              className="delete-btn"
              onClick={() => props.deleteModalShow(row)}
            >
              <span className="delete-icon"></span>
            </button>
          </div>
        </div>
      ),
      width: "40px",
      minWidth: "40px",
      maxWidth: "40px",
    },
    {
      cell: (row) => (
        <div>
          <div>
            <button onClick={() => props.viewSubAddons(row, 2)}>
              <span className="eye-icon">+</span>
            </button>
          </div>
        </div>
      ),
      width: "40px",
      minWidth: "40px",
      maxWidth: "40px",
    },
  ];

  const modal_sub_option_columns = [
    {
      name: "Label",
      selector: "addon_identifier",
      sortable: true,
      width: 200,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      name: "Description",
      selector: "addon_description",
      sortable: true,
      cell: (row) => (
        <div>
          <div className="dt-description-100" title={row.addon_description}>
            {row.addon_description}
          </div>
        </div>
      ),
    },
    {
      cell: (row) => (
        <div>
          <div>
            <button
              className="edit-btn"
              onClick={() => props.handleEditSubOptions(row)}
            >
              <span className="edit-icon"></span>
            </button>
          </div>
        </div>
      ),
      width: "40px",
      minWidth: "40px",
      maxWidth: "40px",
    },
    {
      cell: (row) => (
        <div>
          <div>
            <button
              className="delete-btn"
              onClick={() => props.deleteModalShow(row)}
            >
              <span className="delete-icon"></span>
            </button>
          </div>
        </div>
      ),
      width: "40px",
      minWidth: "40px",
      maxWidth: "40px",
    },
  ];

  const modal_sub_extras_columns = [
    {
      name: "Label",
      selector: "addon_identifier",
      sortable: true,
    },
    {
      name: "CostMax",
      selector: "addon_cost",
      sortable: true,
      cell: (row) => (
        <div>
          <div>{getCurrencySymbol(localStorage.getItem('currency_code')) + row.addon_cost}</div>
        </div>
      ),
    },
    {
      name: "Quantity",
      selector: "total_addons",
      sortable: true,
      cell: (row) => (
        <div>
          <div>{row.total_addons}</div>
        </div>
      ),
    },
    {
      name: "Description",
      selector: "addon_description",
      sortable: true,
      cell: (row) => (
        <div>
          <div className="dt-description-100" title={row.addon_description}>
            {row.addon_description}
          </div>
        </div>
      ),
    },
    {
      cell: (row) => (
        <div>
          <div>
            <button
              className="edit-btn"
              onClick={() => props.handleEditSubExtras(row)}
            >
              <span class="edit-icon"></span>
            </button>
          </div>
        </div>
      ),
      width: "40px",
      minWidth: "40px",
      maxWidth: "40px",
    },
    {
      cell: (row) => (
        <div>
          <div>
            <button
              className="delete-btn"
              onClick={() => props.deleteModalShow(row)}
            >
              <span class="delete-icon"></span>
            </button>
          </div>
        </div>
      ),
      width: "40px",
      minWidth: "40px",
      maxWidth: "40px",
    },
  ];

  return (
    <>
      {/* Add Item Dialog */}
      <Dialog
        fullScreen
        open={props.state.show}
        onClose={props.handleModal}
        scroll="paper"
      >
        <AppBar className="appBar">
          <Toolbar>
            <Typography variant="h6" className="title">
              {props.title}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: 50, marginBottom: 30 }}>
          <Formik
            initialValues={{
              item_identifier: props.state.item_identifier,
              item_description: props.state.item_description,
              quantity: props.state.quantity,
              item_cost: props.state.item_cost,
              sort_order: props.state.sort_order,
              is_take_away_active: props.state.is_take_away_active,
              is_dine_in_active: props.state.is_dine_in_active,
              is_delivery_active: props.state.is_delivery_active,
              is_delivery_to_park_active:
                props.state.is_delivery_to_park_active,
              item_status: props.state.item_status,
            }}
            enableReinitialize
            validateOnMount
            validationSchema={itemModalFormSchema}
            onSubmit={(values) => {
              // same shape as initial values
              {
                props.state.update_item
                  ? props.updateItemHandler(values)
                  : props.addItemHandler(values);
              }
            }}
          >
            {({ values, errors, status, touched }) => (
              <Form>
                <div className="row">
                  <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                      {/* {props.is_setup ? 
                                    null
                                    :
                                    <div className="ms-panel-header">
                                        <h6>Add Item Form</h6>
                                    </div>
                                } */}

                      <div className="ms-panel-body">
                        <div className="form-row">
                          <div className="col-md-12 mb-3">
                            <label htmlFor="validationCustom18">
                              Item Name
                            </label>
                            <Field
                              name="item_identifier"
                              type="text"
                              placeholder=""
                              onChange={(e) => {
                                props.onChangeHandler(e);
                              }}
                              className="form-control"
                            />
                            {/* <ErrorMessage name="item_identifier" className="invalid-feedback" /> */}
                            {touched.item_identifier ? (
                              <div className="error-message">
                                {errors.item_identifier}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="col-md-12 mb-3">
                            <label htmlFor="validationCustom12">
                              Description
                            </label>
                            <Field
                              name="item_description"
                              type="text"
                              as="textarea"
                              onChange={(e) => {
                                props.onChangeHandler(e);
                              }}
                              rows={5}
                              placeholder=""
                              className="form-control"
                            />
                            {/* <ErrorMessage name="item_description" className="invalid-feedback" /> */}
                            {touched.item_description ? (
                              <div className="error-message">
                                {errors.item_description}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="col-md-4 mb-3">
                            <label htmlFor="validationCustom24">Quantity</label>
                            <Field
                              name="quantity"
                              type="number"
                              placeholder=""
                              onChange={(e) => {
                                props.onChangeHandler(e);
                              }}
                              className="form-control"
                            />
                            {/* <ErrorMessage name="quantity" className="invalid-feedback" /> */}
                            {touched.quantity ? (
                              <div className="error-message">
                                {errors.quantity}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          {props.is_buffet === "true" ? null : (
                            <div className="col-md-4 ">
                              <label htmlFor="validationCustom25">Price</label>
                              <div className="input-group-append">
                                {" "}
                                <span className="input-group-text"> £ </span>
                                <Field
                                  name="item_cost"
                                  type="number"
                                  placeholder=""
                                  onChange={(e) => {
                                    props.onChangeHandler(e);
                                  }}
                                  className="form-control"
                                />
                                {/* <input type="text" disabled={props.isServiceTaxEditable} className="form-control" placeholder="12.5" /> */}
                                {/* <ErrorMessage name="item_cost" className="invalid-feedback" /> */}
                              </div>
                              {touched.item_cost ? (
                                <div className="error-message">
                                  {errors.item_cost}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )}

                          <div className="col-md-4">
                            <label htmlFor="validationCustom25">
                              Sort Order
                            </label>
                            <Field
                              name="sort_order"
                              type="number"
                              placeholder=""
                              onChange={(e) => {
                                props.onChangeHandler(e);
                              }}
                              className="form-control"
                            />
                            {/* <ErrorMessage name="sort_order" className="invalid-feedback" /> */}
                            {touched.sort_order ? (
                              <div className="error-message">
                                {errors.sort_order}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          {/* <div className="col-md-6"></div> */}

                          {/* <div className="col-md-6 mb-3">
                                                        <label htmlFor="validationCustom12">Image</label>
                                                        <div className="custom-file">
                                                            <input className="custom-file-input"
                                                                accept=".jpeg,.jpg,.png,.svg"
                                                                id="choose-profile"
                                                                onClick={() => props.toggleHandler('showItemCropper')}
                                                            />
                                                            <label className="custom-file-label" htmlFor="validatedCustomFile">Crop Images...</label>
                                                            <div className="invalid-feedback">Example invalid custom file feedback</div>
                                                        </div>
                                                    </div> */}

                          <div className="col-md-6">
                            <div className="ms-panel-header new">
                              <p className="medium">Available for Takeaway</p>
                              <div>
                                <label className="ms-switch">
                                  <input
                                    type="checkbox"
                                    checked={props.state.is_take_away_active}
                                    name="is_take_away_active"
                                    onChange={() =>
                                      props.toggleHandler("is_take_away_active")
                                    }
                                  />
                                  <span className="ms-switch-slider round" />
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="ms-panel-header new">
                              <p className="medium">Available for Dine In </p>
                              <div>
                                <label className="ms-switch">
                                  <input
                                    type="checkbox"
                                    checked={props.state.is_dine_in_active}
                                    name="is_dine_in_active"
                                    onChange={() =>
                                      props.toggleHandler("is_dine_in_active")
                                    }
                                  />
                                  <span className="ms-switch-slider round" />
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="ms-panel-header new">
                              <p className="medium">Available for Delivery</p>
                              <div>
                                <label className="ms-switch">
                                  <input
                                    type="checkbox"
                                    checked={props.state.is_delivery_active}
                                    name="is_delivery_active"
                                    onChange={() =>
                                      props.toggleHandler("is_delivery_active")
                                    }
                                  />
                                  <span className="ms-switch-slider round" />
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div
                              className="ms-panel-header new"
                              style={{ paddingBottom: "34px" }}
                            >
                              <p className="medium">Kayana In Park</p>
                              <div>
                                <label className="ms-switch">
                                  <input
                                    type="checkbox"
                                    checked={
                                      props.state.is_delivery_to_park_active
                                    }
                                    name="is_delivery_to_park_active"
                                    onChange={() =>
                                      props.toggleHandler(
                                        "is_delivery_to_park_active"
                                      )
                                    }
                                  />
                                  <span className="ms-switch-slider round" />
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="ms-panel-header new">
                              <p className="medium">Status Available</p>
                              <div>
                                <label className="ms-switch">
                                  <input
                                    type="checkbox"
                                    checked={props.state.item_status}
                                    name="item_status"
                                    onChange={props.statusItemHandler}
                                  />
                                  <span className="ms-switch-slider round" />
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6"></div>

                          <div className="col-md-4 mb-2 mt-2">
                            <button
                              type="button"
                              className="btn btn-primary text-nowrap"
                              onClick={props.showOptions}
                            >
                              <i className="flaticon-hammer mr-2"></i>View
                              Options
                            </button>
                          </div>

                          <div className="col-md-6 mb-2 ml-2 mt-2">
                            <button
                              type="button"
                              className="btn btn-primary text-nowrap"
                              onClick={props.showExtras}
                            >
                              <i className="flaticon-hammer mr-2"></i>View
                              Extras
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      props.is_setup
                        ? "col-xl-12 col-md-12"
                        : "col-xl-12 col-md-12"
                    }
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="ms-panel">
                          {/* <div className="ms-panel-header">
                                                        <h6>Item </h6>
                                                    </div> */}

                          <div className="col-md-12 mt-4">
                            {/* <label htmlFor="validationCustom12">Image</label> */}
                            {/* Cropper 1 */}

                            <MultipleImageCropper
                              data={{
                                key: "item",
                                isMultiple: false,
                                getCroppedImages: props.handleImageChange,
                              }}
                              height={300}
                              width={300}
                            />

                            {/* <div className="custom-file">
                                                            <input className="custom-file-input"
                                                                accept=".jpeg,.jpg,.png,.svg"
                                                                id="choose-profile"
                                                                onClick={() => props.toggleHandler('showItemCropper')}
                                                            />
                                                            <label className="custom-file-label" htmlFor="validatedCustomFile">Crop Images...</label>
                                                            <div className="invalid-feedback">Example invalid custom file feedback</div>
                                                        </div> */}
                          </div>

                          <div className="ms-panel-body">
                            {props.state.imagesPreviewUrl ? (
                              <img
                                className="add-product-image"
                                src={
                                  props.state.imagesPreviewUrl
                                    ? props.state.imagesPreviewUrl
                                    : ""
                                }
                              />
                            ) : null}
                          </div>

                          {/* <div className="ms-panel-header new">
                                                        <p className="medium">Status Available</p>
                                                        <div>
                                                            <label className="ms-switch">
                                                                <input type="checkbox"
                                                                    checked={props.state.item_status}
                                                                    name="item_status"
                                                                    onChange={props.statusItemHandler} />
                                                                <span className="ms-switch-slider round" />
                                                            </label>
                                                        </div>
                                                    </div> */}

                          <div>
                            {/* <Link to={`/menu-grid/${props.category_id}`} className="btn btn-primary d-block" type="reset">Cancel</Link> */}
                            {props.state.update_item ? (
                              <>
                                <button
                                  disabled={
                                    Array.isArray(errors) ||
                                    Object.values(errors).toString() != ""
                                  }
                                  className="btn btn-secondary d-block float-right m-4"
                                  type="submit"
                                >
                                  Update
                                </button>
                              </>
                            ) : (
                              <button
                                disabled={
                                  Array.isArray(errors) ||
                                  Object.values(errors).toString() != ""
                                }
                                className="btn btn-secondary d-block float-right m-4"
                                type="submit"
                              >
                                Save
                              </button>
                            )}
                          </div>
                        </div>
                        {Object.keys(errors).length > 0 && (
                          <div>
                            <span
                              className="float-right"
                              style={{ color: "red" }}
                            >
                              There are errors in your form
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog>

      {/* View Options Dialog */}
      <Dialog
        fullScreen
        open={props.state.showOptionsFlag}
        onClose={props.handleOptionsModal}
        scroll="paper"
      >
        <AppBar className="appBar">
          <Toolbar>
            <Typography variant="h6" className="title">
              Options
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleOptionsModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: 50, marginBottom: 30 }}>
          {props.state.item_options ? (
            <div className="col-xl-12 col-md-12 col-sm-6">
              <button
                className="btn btn-primary mb-2 float-right"
                onClick={() => props.showAddEditOptionModal()}
              >
                + Add Options
              </button>
              <DataTable
                customStyles={customStyles}
                columns={option_columns}
                data={props.state.item_options}
              />
            </div>
          ) : null}
        </div>
      </Dialog>

      {/* View Extras Dialog */}
      <Dialog
        fullScreen
        open={props.state.showExtrasFlag}
        onClose={props.handleExtrasModal}
        scroll="paper"
      >
        <AppBar className="appBar">
          <Toolbar>
            <Typography variant="h6" className="title">
              Extras
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleExtrasModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: 50, marginBottom: 30 }}>
          {props.state.item_extras ? (
            <div className="col-xl-12 col-md-12 col-sm-6">
              <button
                className="btn btn-primary mb-2 float-right"
                onClick={() => props.showAddEditExtrasModal()}
              >
                + Add Extras
              </button>
              <DataTable
                customStyles={customStyles}
                columns={extras_columns}
                data={props.state.item_extras}
              />
            </div>
          ) : null}
        </div>
      </Dialog>

      {/* Add/Update Options Dialog */}
      <Dialog
        fullScreen
        open={props.state.showAddEditOptionFlag}
        onClose={props.handleAddOptionModal}
        scroll="paper"
      >
        <AppBar className="appBar">
          <Toolbar>
            <Typography variant="h6" className="title">
              {props.state.update_option_flag ? "Update Options" : "Add Option"}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleAddOptionModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: 50, marginBottom: 30 }}>
          {props.state.update_option_flag ? (
            <div className="col-xl-12 col-md-12 col-sm-6">
              {/* Update Form */}
              <div className="ms-panel-body text-left">
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleEmail">Update Option Label</label>
                    <input
                      type="text"
                      className="form-control"
                      name="addon_identifier"
                      id="fieldLabel"
                      defaultValue={
                        props.state.edit_options_data
                          ? props.state.edit_options_data.addon_identifier
                          : ""
                      }
                      onChange={(e) => {
                        props.handleOptionsChange(e);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleTextarea">Description</label>
                    <textarea
                      className="form-control"
                      name="addon_description"
                      id="fieldDescription"
                      rows={3}
                      defaultValue={
                        props.state.edit_options_data.addon_description
                          ? props.state.edit_options_data.addon_description
                          : ""
                      }
                      onChange={(e) => {
                        props.handleOptionsChange(e);
                      }}
                    />
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                      style={{ display: "inline-block", float: "left" }}
                      onClick={(e) => props.updateOptions(e)}
                      type="button"
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-danger ml-4"
                      style={{ display: "inline-block", float: "left" }}
                      onClick={(e) => props.handleAddOptionModal()}
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div className="col-xl-12 col-md-12 col-sm-6">
              {/* Add Form */}
              <div className="ms-panel-body text-left">
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleEmail">Add Option Label</label>
                    <input
                      type="text"
                      className="form-control"
                      name="addon_identifier"
                      defaultValue=""
                      id="fieldLabel"
                      onChange={(e) => {
                        props.handleOptionsChange(e);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleTextarea">Description</label>
                    <textarea
                      className="form-control"
                      name="addon_description"
                      defaultValue=""
                      id="fieldDescription"
                      rows={3}
                      onChange={(e) => {
                        props.handleOptionsChange(e);
                      }}
                    />
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={() => props.addOptions(1)}
                      type="button"
                    >
                      Add
                    </button>
                    <button
                      className="btn btn-danger ml-4"
                      onClick={(e) => props.handleAddOptionModal()}
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </Dialog>

      {/* Add/Update Extras Dialog */}
      <Dialog
        fullScreen
        open={props.state.showAddEditExtrasFlag}
        onClose={props.handleAddExtrasModal}
        scroll="paper"
      >
        <AppBar className="appBar">
          <Toolbar>
            <Typography variant="h6" className="title">
              {props.state.update_extras_flag ? "Update Extras" : "Add Extras"}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleAddExtrasModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: 50, marginBottom: 30 }}>
          {props.state.update_extras_flag ? (
            <div className="ms-panel-body text-left">
              <form>
                <div className="form-group">
                  <label htmlFor="exampleLabel">Label</label>
                  <input
                    type="text"
                    className="form-control"
                    name="addon_identifier"
                    id="fieldLabel"
                    defaultValue={
                      props.state.edit_extras_data.addon_identifier
                        ? props.state.edit_extras_data.addon_identifier
                        : ""
                    }
                    onChange={(e) => {
                      props.handleExtrasChange(e);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleCostMax">CostMax</label>
                  <input
                    type="text"
                    className="form-control"
                    name="addon_cost"
                    id="fieldCostMax"
                    defaultValue={
                      props.state.edit_extras_data.addon_cost
                        ? props.state.edit_extras_data.addon_cost
                        : ""
                    }
                    onChange={(e) => {
                      props.handleExtrasChange(e);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleQuantity">Quantity</label>
                  <input
                    type="text"
                    className="form-control"
                    name="total_addons"
                    id="fieldQuantity"
                    defaultValue={
                      props.state.edit_extras_data.total_addons
                        ? props.state.edit_extras_data.total_addons
                        : ""
                    }
                    onChange={(e) => {
                      props.handleExtrasChange(e);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleDescription">Description</label>
                  <textarea
                    className="form-control"
                    name="addon_description"
                    id="fieldDescription"
                    rows={3}
                    defaultValue={
                      props.state.edit_extras_data.addon_description
                        ? props.state.edit_extras_data.addon_description
                        : ""
                    }
                    onChange={(e) => {
                      props.handleExtrasChange(e);
                    }}
                  />
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => props.updateExtras()}
                  type="button"
                >
                  Update
                </button>

                <button
                  className="btn btn-danger ml-4"
                  onClick={(e) => props.handleAddOptionModal()}
                  type="button"
                >
                  Cancel
                </button>
              </form>
            </div>
          ) : (
            <div className="col-xl-12 col-md-12 col-sm-6">
              {/* Add Extras */}
              <div>
                <div className="ms-panel-body text-left">
                  <form>
                    <div className="form-group">
                      <label htmlFor="exampleLabel">Label</label>
                      <input
                        type="text"
                        className="form-control"
                        name="addon_identifier"
                        id="fieldLabel"
                        onChange={(e) => {
                          props.handleExtrasChange(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleCostMax">CostMax</label>
                      <input
                        type="text"
                        className="form-control"
                        name="addon_cost"
                        id="fieldCostMax"
                        onChange={(e) => {
                          props.handleExtrasChange(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleQuantity">Quantity</label>
                      <input
                        type="text"
                        className="form-control"
                        name="total_addons"
                        id="fieldQuantity"
                        onChange={(e) => {
                          props.handleExtrasChange(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleDescription">Description</label>
                      <textarea
                        className="form-control"
                        name="addon_description"
                        id="fieldDescription"
                        rows={3}
                        onChange={(e) => {
                          props.handleExtrasChange(e);
                        }}
                      />
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={() => props.addExtras(1)}
                      type="button"
                    >
                      Save
                    </button>

                    <button
                      className="btn btn-danger ml-4"
                      onClick={(e) => props.handleAddOptionModal()}
                      type="button"
                    >
                      Cancel
                    </button>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </Dialog>

      {/* View All Sub Options Modal */}
      <Dialog
        fullScreen
        open={props.state.showSubOptionsFlag}
        onClose={props.handleSubOptionsModal}
        scroll="paper"
      >
        <AppBar className="appBar">
          <Toolbar>
            <Typography variant="h6" className="title">
              {props.state.item_sub_options
                ? "SubOptions View"
                : "Add SubOptions"}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleSubOptionsModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: 50, marginBottom: 30 }}>
          {props.state.item_sub_options ? (
            <div className="col-xl-12 col-md-12 col-sm-6">
              <button
                className="btn btn-primary float-right"
                onClick={() => props.showAddNewSubOptionModal()}
              >
                + Add New{" "}
              </button>
              <DataTable
                customStyles={customStyles}
                columns={modal_sub_option_columns}
                data={props.state.item_sub_options}
              />
            </div>
          ) : (
            <div className="col-xl-12 col-md-12 col-sm-6">
              <button
                className="btn btn-primary mb-2"
                onClick={() => props.showAddNewSubOptionModal()}
              >
                + Add New{" "}
              </button>
            </div>
          )}
        </div>
      </Dialog>

      {/* View All Sub Extras Add-On Modal */}
      <Dialog
        fullScreen
        open={props.state.showSubAddonsFlag}
        onClose={props.handleSubExtrasModal}
        scroll="paper"
      >
        <AppBar className="appBar">
          <Toolbar>
            <Typography variant="h6" className="title">
              {props.state.item_sub_extras ? "SubExtras View" : "Add SubExtras"}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleSubExtrasModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: 50, marginBottom: 30 }}>
          {props.state.item_sub_extras ? (
            <div className="col-xl-12 col-md-12 col-sm-6">
              <button
                className="btn btn-primary mb-2 float-right"
                onClick={() => props.showAddNewSubExtrasModal()}
              >
                + Add New{" "}
              </button>
              <DataTable
                customStyles={customStyles}
                // title="Requested Table"
                columns={modal_sub_extras_columns}
                data={props.state.item_sub_extras}
              />

              {/* <div className="row" style={{ float: 'right', margin: '25px' }}>
                                        <Pagination currentPage={this.state.current_page_of_request_data_table} total_pages={this.state.total_pages_of_request_data_table} handleChange={this.changeCurrentPageOfRequestDataTable} />
                                    </div>
                                    <div style={{ float: 'left', margin: '15px' }}>Total Records : {this.state.total_elements_of_request_data_table}</div> */}
            </div>
          ) : (
            <div className="col-xl-12 col-md-12 col-sm-6 mb-4 mt-4">
              <button
                className="btn btn-primary mb-2 mt-2"
                onClick={() => props.showAddNewSubExtrasModal()}
              >
                + Add New{" "}
              </button>
            </div>
          )}
        </div>
      </Dialog>

      {/* Sub Options Add / Edit / Update Modal */}
      <Dialog
        fullScreen
        open={props.state.showAddEditSubOptionsFlag}
        onClose={props.handleAddUpdateSubOptionsModal}
        scroll="paper"
      >
        <AppBar className="appBar">
          <Toolbar>
            <Typography variant="h6" className="title">
              {props.state.update_suboptions_flag
                ? "Update SubOptions"
                : "Add SubOptions"}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleAddUpdateSubOptionsModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: 50, marginBottom: 30 }}>
          {props.state.update_suboptions_flag ? (
            <div className="ms-panel">
              <div className="ms-panel-body text-left">
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleEmail">Update Label</label>
                    <input
                      type="text"
                      className="form-control"
                      name="addon_identifier"
                      id="fieldLabel"
                      defaultValue={
                        props.state.edit_sub_options_data
                          ? props.state.edit_sub_options_data.addon_identifier
                          : ""
                      }
                      onChange={(e) => {
                        props.handleSubOptionsChange(e);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleTextarea">Description</label>
                    <textarea
                      className="form-control"
                      name="addon_description"
                      id="fieldDescription"
                      rows={3}
                      defaultValue={
                        props.state.edit_sub_options_data
                          ? props.state.edit_sub_options_data.addon_description
                          : ""
                      }
                      onChange={(e) => {
                        props.handleSubOptionsChange(e);
                      }}
                    />
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={(e) => props.updateSubOptions(e)}
                    type="button"
                  >
                    Update
                  </button>

                  <button
                    className="btn btn-danger ml-4"
                    onClick={(e) => props.handleAddUpdateSubOptionsModal()}
                    type="button"
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          ) : (
            <div className="ms-panel">
              <div className="ms-panel-body text-left">
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleEmail">Add Label</label>
                    <input
                      type="text"
                      className="form-control"
                      name="addon_identifier"
                      defaultValue=""
                      id="fieldLabel"
                      onChange={(e) => {
                        props.handleSubOptionsChange(e);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleTextarea">Description</label>
                    <textarea
                      className="form-control"
                      name="addon_description"
                      defaultValue=""
                      id="fieldDescription"
                      rows={3}
                      onChange={(e) => {
                        props.handleSubOptionsChange(e);
                      }}
                    />
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={() => props.addSubOptions()}
                    type="button"
                  >
                    Add
                  </button>

                  <button
                    className="btn btn-danger ml-4"
                    onClick={(e) => props.handleAddUpdateSubOptionsModal()}
                    type="button"
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </Dialog>

      {/* Sub Extras Add / Edit / Update Modal */}
      <Dialog
        fullScreen
        open={props.state.showAddEditSubExtrasFlag}
        onClose={props.handleAddUpdateSubExtrasModal}
        scroll="paper"
      >
        <AppBar className="appBar">
          <Toolbar>
            <Typography variant="h6" className="title">
              {props.state.update_subextras_flag
                ? "Update SubExtras"
                : "Add SubExtras"}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleAddUpdateSubExtrasModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: 50, marginBottom: 30 }}>
          {props.state.update_subextras_flag ? (
            <div className="ms-panel">
              <div className="ms-panel-body text-left">
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleLabel">Update Label</label>
                    <input
                      type="text"
                      className="form-control"
                      name="addon_identifier"
                      id="fieldLabel"
                      defaultValue={
                        props.state.edit_sub_extras_data.addon_identifier
                          ? props.state.edit_sub_extras_data.addon_identifier
                          : ""
                      }
                      onChange={(e) => {
                        props.handleSubExtrasChange(e);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleCostMax">CostMax</label>
                    <input
                      type="text"
                      className="form-control"
                      name="addon_cost"
                      id="fieldCostMax"
                      defaultValue={
                        props.state.edit_sub_extras_data.addon_cost
                          ? props.state.edit_sub_extras_data.addon_cost
                          : ""
                      }
                      onChange={(e) => {
                        props.handleSubExtrasChange(e);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleQuantity">Quantity</label>
                    <input
                      type="text"
                      className="form-control"
                      name="total_addons"
                      id="fieldQuantity"
                      defaultValue={
                        props.state.edit_sub_extras_data.total_addons
                          ? props.state.edit_sub_extras_data.total_addons
                          : ""
                      }
                      onChange={(e) => {
                        props.handleSubExtrasChange(e);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleDescription">Description</label>
                    <textarea
                      className="form-control"
                      name="addon_description"
                      id="fieldDescription"
                      rows={3}
                      defaultValue={
                        props.state.edit_sub_extras_data.addon_description
                          ? props.state.edit_sub_extras_data.addon_description
                          : ""
                      }
                      onChange={(e) => {
                        props.handleSubExtrasChange(e);
                      }}
                    />
                  </div>
                  <button
                    className="btn btn-primary d-block"
                    onClick={(e) => props.updateSubExtras(e)}
                    type="button"
                  >
                    Update
                  </button>
                </form>
              </div>
            </div>
          ) : (
            <div className="ms-panel">
              <div className="ms-panel-body text-left">
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleLabel">Add Label</label>
                    <input
                      type="text"
                      className="form-control"
                      name="addon_identifier"
                      id="fieldLabel"
                      onChange={(e) => {
                        props.handleSubExtrasChange(e);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleCostMax">CostMax</label>
                    <input
                      type="text"
                      className="form-control"
                      name="addon_cost"
                      id="fieldCostMax"
                      onChange={(e) => {
                        props.handleSubExtrasChange(e);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleQuantity">Quantity</label>
                    <input
                      type="text"
                      className="form-control"
                      name="total_addons"
                      id="fieldQuantity"
                      onChange={(e) => {
                        props.handleSubExtrasChange(e);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleDescription">Description</label>
                    <textarea
                      className="form-control"
                      name="addon_description"
                      id="fieldDescription"
                      rows={3}
                      onChange={(e) => {
                        props.handleSubExtrasChange(e);
                      }}
                    />
                  </div>
                  <button
                    className="btn btn-primary d-block"
                    onClick={() => props.addSubExtras()}
                    type="button"
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default ItemDialogue;
