import React, { Component } from "react";
import { toast } from "react-toastify";
import * as WebOrderServiceAPI from "../../services/webOrder/WebOrderServiceAPI";
import { Fragment } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Loader from "../../shared/Loader";
import nashicon from "../../assets/icon/delivery.svg";

class index extends Component {
  state = {
    isLoading: false,
    isChecked: false,
    nash: {},
    isNashModalOpen: false,
    webPlan: {},
  };

  componentDidMount = () => {
    this.fetchNashPlan();
  };

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked });
  };

  handleCancelNashClick = () => {
    this.setState({ isNashModalOpen: true });
  };
  handleCancelClick = () => {
    this.setState({ isModalOpen: true });
  };
  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };
  handleNashCancel = () => {
    this.setState({ isNashModalOpen: false });
  };

  fetchNashPlan = async () => {
    const dump = this.props.propertyId;
    try {
      const response = await WebOrderServiceAPI.fetchNashPlan(dump);

      if (response.data.status) {
        if (response.data?.data) {
          this.setState({
            nash: response.data.data,
            isLoading: false,
          });
        }
      } else {
        const err = response.data?.message || "Something went wrong.";
        this.setState({ url: {}, isLoading: false });
        // toast.warn(err);
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ url: {}, isModalOpen: false, isLoading: false });
      toast.warn(err);
      throw error;
    }
  };

  cancelNashPlan = async () => {
    const dump = this.state.nash.nash_id;
    try {
      const response = await WebOrderServiceAPI.cancelNashPlan(dump);

      if (response.data.status) {
        this.fetchNashPlan();
        this.setState({ isNashModalOpen: false, isLoading: false });
      } else {
        const err = response.data?.message || "Something went wrong.";
        this.setState({ nash: {}, isNashModalOpen: false, isLoading: false });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ nash: {}, isNashModalOpen: false, isLoading: false });
      toast.warn(err);
      throw error;
    }
  };

  handleCheckboxChange = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  render() {
    const { nash, isNashModalOpen } = this.state;
    return (
      <Fragment>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="">
            <div className="">
              <div className="">
                <br />
         
                <Grid container justifyContent="left" alignItems="left">
               
                  <Grid item xs={12}>
                    <Paper
                      elevation={3}
                      sx={{
                        mb: 0,
                        mt: 0,
                        pl: 4,
                        pr: 4,
                        ml: 5,
                        mr: 5,
                      }}
                    >
                      <Grid container alignItems="center">
                        {/* Image */}
                        <Grid item lg={1}>
                          <img
                            src={nashicon}
                            style={{
                              width: "100px",
                              margin: "8px",
                              boxSizing: "border-box",
                            }}
                          />
                        </Grid>

                        {/* Vertical Line */}
                        <Grid item>
                          <div
                            style={{
                              borderLeft: "1px solid black",
                              height: "115px",
                              margin: "0 20px",
                            }}
                          ></div>
                        </Grid>

                        {/* Text and Buttons */}
                        <Grid item lg={5}>
                          <Grid container>
                            {/* Text */}
                            <Grid item style={{ marginBottom: "8px" }}>
                              <Typography variant="h6">
                                <b>Third Party Delivery Fulfillment</b>{" "}
                                {nash?.subscription_status === "CANCELLED" && (

                                <span
                                  style={{
                                    marginLeft: "10px",
                                    backgroundColor: "lightgrey",
                                    padding: "5px",
                                    paddingLeft: "10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Valid till{" "}
                                  {nash?.subscription_end_date?.substring(0, 10)}
                                </span>
                                 )}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1">
                                Experience seamless delivery through our trusted
                                third-party service.Enjoy efficient and reliable
                                delivery for your customers, hassle-free.{" "}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Buttons */}

                        <Grid item xs={4}>
                          <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="right"
                          >
                            {nash.subscription_status !== "ACTIVE" &&
                              nash.subscription_status !== "CANCELLED" && (
                                <Grid item xs={10}>
                                  <Link
                                    to={{
                                      pathname: "/nash",
                                      state: {
                                        nash: this.state.nash,
                                      },
                                    }}
                                    style={{ textDecoration: "none",float:"right" }}
                                  >
                                    <Button
                                      size="large"
                                      variant="contained"
                                      style={{
                                        width: "150px",
                                        height: "50px",
                                        borderRadius: "30px",
                                      }}
                                    >
                                      Activate
                                    </Button>
                                  </Link>
                                </Grid>
                              )}
                            {nash?.subscription_status === "ACTIVE" && (
                              <>
                                <Grid
                                  container
                                  justifyContent="flex-end"
                                  alignItems="right"
                                >
                                  <Grid item xs={5}>
                                    <Button
                                      size="large"
                                      variant="contained"
                                      style={{
                                        width: "150px",
                                        height: "50px",
                                        float: "right",
                                        borderRadius: "30px",
                                        backgroundColor: "#DAFFB9",
                                        color: "#699E38",
                                        "&:hover": {
                                          backgroundColor: "#DAFFB9",
                                          color: "#699E38",
                                        },
                                      }}
                                    >
                                      Activated
                                    </Button>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Button
                                      size="large"
                                      variant="outlined"
                                      onClick={this.handleCancelNashClick}
                                      style={{
                                        width: "150px",
                                        height: "50px",
                                        borderRadius: "30px",
                                        float: "right",
                                        borderColor: "#C90A0A",
                                        color: "#C90A0A",
                                        "&:hover": {
                                          borderColor: "#C90A0A",
                                          color: "#C90A0A",
                                        },
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                            <Grid
                              container
                              justifyContent="flex-end"
                              alignItems="right"
                            >
                              {nash?.subscription_status === "CANCELLED" && (
                                <Button
                                  size="large"
                                  variant="contained"
                                  className="white-text"
                                  sx={{
                                    mt: { xs: 1, md: 0 },
                                    width: "150px",
                                    height: "50px",
                                  }}
                                  disabled={true}
                                >
                                  Cancelled
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default withRouter(connect(mapStateToProps, {})(index));
