import React, { Component } from 'react';
import { deleteBatch } from '../../../services/inventory/batchService';
import { Box, Typography, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DataTable from 'react-data-table-component';
class BatchItemList extends Component {
  state = {
    batchItems: null
  };

  componentDidMount() {
    this.props.fetchBatchItems(this.props.stockId);
  }

 /*  fetchBatchItems = () => {
    getItems(this.props.stockId).then(response => {
      this.setState({ batchItems: response.data ? response.data.data : [] });
    });
  };  */

  handleDelete = (id) => {
    deleteBatch(id)
      .then(() => {
        this.props.fetchBatchItems(this.props.stockId);
        toast.success('Batch deleted successfully.');
      })
      .catch((error) => {
        console.error('Error deleting batch:', error);
        toast.error('Failed to delete batch.');
      });
  };
  

  

  render() {
    const { onEdit, batchItems } = this.props;

    const columns = [
      {
        name: 'Quantity',
        selector: row => row.quantity,
        sortable: true,
      },
      {
        name: 'Status',
        selector: row => (
          row.add_or_remove === "ADDED" ? 
          <div style={{background: "#DDFFD6", color: "#1E810B", padding: '7px'}}> {row.add_or_remove} </div> : 
          row.add_or_remove === "REMOVED" ? 
          <div style={{background: "#FFD3D1", color: "#DD100C", padding: '7px'}}> {row.add_or_remove} </div> :
          <div style={{background: "#E0E0E0", color: "#000000", padding: '7px'}}> {row.add_or_remove} </div>
        ),
        sortable: true,
      },
      {
        name: 'Date',
        selector: row => row.batch_date,
        sortable: true,
      },
      {
        name: 'Description',
        selector: row => row.description,
        sortable: true,
      },
      {
        name: 'Actions',
        cell: row => (
          <div>
           {/*  <IconButton onClick={() => this.props.onEdit(row)}>
              <EditIcon />
            </IconButton> */}
            <Button  startIcon={<RemoveRedEyeIcon color='primary'/>} onClick={() => this.props.onEdit(row)}>View</Button>
        {/*     <IconButton onClick={() => this.handleDelete(row.batch_id)}>
              <DeleteIcon />
            </IconButton> */}
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];

    const customStyles = {
      headCells: {
        style: {
          color: 'white', // Change the header text color to white
        },
      },
    };

    const CustomTitle = () => (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          //padding: '10px 20px',
          //backgroundColor: '#3f51b5',
          //color: 'white',
          borderRadius: '4px',
          marginBottom: '20px',
        }}
      >
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
          Batch 
        </Typography>
      </Box>
    );

    return (

      <div>
      <DataTable
        columns={columns}
        data={batchItems || []} // Ensure data is an array
        pagination
        customStyles={customStyles} // Apply custom styles
        highlightOnHover
      />

    </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
  username: state.user.username,
  settings: state.user.settings,
});


const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(BatchItemList);
