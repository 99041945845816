import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import * as img1 from "../../../assets/img/kayana/dashboard-images/create_menu.png";
import img2 from "../../../assets/img/kayana/dashboard-images/add_business_info.png";
import img3 from "../../../assets/img/kayana/dashboard-images/add_payment_method.png";
import img4 from "../../../assets/img/kayana/dashboard-images/add_social_media_accounts.png";
import { BrowserRouter } from "react-router-dom";
const trendfood = [


  /* {
    photo: img3,
    title: "Let’s get you started",
    detail: "Show initial setup dialog",
    buttonTitile: "Initial Setup",
    link: "/setup",
    click: "showInitialSetup",
    resposnivecls: "col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-lg-20",
  }, */
  {
    photo: img1,
    title: "Menu",
    detail: "Lets start by changing the menu for your business.",
    buttonTitile: "UPDATE",
    link: "/menu",
    resposnivecls: "col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-lg-20",
  },
  {
    photo: img2,
    title: "Business Details",
    detail: "Update your business information for better reach.",
    buttonTitile: "UPDATE",
    link: "/general",
    resposnivecls: "col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-lg-20",
  },
  // {
  //     photo: img3,
  //     title: "Payment Mode",
  //     detail: "Add appropriate payment mode to recieve payouts",
  //     buttonTitile: 'ADD',
  //     link: '/payment',
  //     resposnivecls: "col-xl-3 col-lg-6 col-md-6 col-sm-6",
  // },
  /*  {
     photo: img4,
     title: "Social Media Accounts",
     detail: "Link social media account to get stats all in one place",
     buttonTitile: "COMING SOON",
     link: "#",
     resposnivecls: "col-xl-4 col-lg-6 col-md-6 col-sm-6",
   }, */

];

class Trendingdetail extends Component {
  // componentDidMount = () => {}

  showInitialSetup = () => {
    this.props.showInitialSetupModal();
  };

  render() {
    return (
      <Fragment>
        {/* <h6>Complete your Business details</h6> */}

        <div className="row">
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-lg-20">
            <div className="ms-card no-margin">
              <div className="ms-card-img">
                <img src={img3} />
              </div>
              <div className="ms-card-body">
                <div className="ms-card-heading-title">
                  <h6  >
                    <strong >{this.props.state.statusToShow ? this.props.state.statusToShow?.charAt(0)?.toUpperCase() + this.props.state.statusToShow.slice(1).toLowerCase()  : "Let’s get you started"}</strong>
                  </h6>
                  <span className="green-text">

                  </span>
                </div>
                <div className="ms-card-heading-title">
                  <span className="font-italic">"{this.props.state.descrption ? this.props.state.descrption : "Setup Account"}"</span>
                </div>
                <Link
                  to="/setup"
                  className="btn btn-primary mt-4 d-block w-100"
                  type="submit"
                >
                  {"UPDATE"}
                </Link>
              </div>
            </div>
          </div>
          {trendfood.map((item, i) => (
            <div key={i} className={item.resposnivecls}>
              <div className="ms-card no-margin">
                <div className="ms-card-img">
                  <img src={item.photo} />
                </div>
                <div className="ms-card-body">
                  <div className="ms-card-heading-title">
                    <h6>
                      <strong>{item.title}</strong>
                    </h6>
                    <span className="green-text">
                      <strong>{item.price}</strong>
                    </span>
                  </div>
                  <div className="ms-card-heading-title">
                    <span className="font-italic">"{item.detail}"</span>
                  </div>
                  {/*   {item.click ? (
                    <button
                      value="hello!"
                      className="btn btn-primary mt-4 d-block w-100"
                      onClick={this.showInitialSetup}
                    >
                      INITIAL SETUP
                    </button>
                  ) : ( */}
                  <Link
                    to={item.link}
                    className="btn btn-primary mt-4 d-block w-100"
                    type="submit"
                  >
                    {item.buttonTitile}
                  </Link>
                  {/*  )} */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}

export default Trendingdetail;
