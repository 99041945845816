import React, { Component, Fragment } from 'react'
import { Route, Redirect } from 'react-router-dom'

export default class index extends Component {
    render() {


        const token = localStorage.getItem("access_token");
        if(this.props.isLoggedIn && token !== null && window.location.pathname !== "/dashboard" ){
            return <Redirect to="/dashboard" />
        }
        
        return (
            <Fragment>
                <Route
                    {...this.props}
                    render={props=><Component {...props}/>}
                />
            </Fragment>
        )
    }
}
