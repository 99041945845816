import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from "@mui/material/colors";
import $ from "jquery";
import Breadcrumb from "./Breadcrumb";
import { Jumbotron, Container } from "react-bootstrap";
import ApiIcon from "@mui/icons-material/Api";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {
  CardActionArea,
  DialogActions,
  DialogContentText,
  DialogTitle,
  InputAdornment,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Input from "@mui/material/Input";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { withRouter } from "react-router-dom";
import Loader from "../../../shared/Loader";
import * as Yup from "yup";
import countryList from "react-select-country-list";
import * as PlanServiceAPI from "../../../services/plans/PlanServiceAPI";
import * as WebOrderServiceAPI from "../../../services/webOrder/WebOrderServiceAPI";
import * as EposNowServiceAPI from "../../../services/integrations/EposNowServiceAPI";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import Paper from "@mui/material/Paper";
import { Modal } from "antd";
import { Result } from "antd";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Box,
  Grid,
  useTheme,
  withStyles,
  Stack,
  InputLabel,
} from "@mui/material";
import DiscountIcon from "@mui/icons-material/LocalOffer";
import DeleteIcon from "@mui/icons-material/Delete";
import { setWebOrderUserInfo } from "../../../redux/misc/miscSlice";
import { getCurrencySymbol } from "../../../config";
const { Meta } = Card;
var checkout = null;
var dropin = null;

const styles = {
  card: {
    maxWidth: 300, // Adjust the maximum width as needed
    margin: "auto",
    marginTop: 20,
  },
  media: {
    height: 140, // Set an appropriate height for your media
    // backgroundImage: 'url(your_image_url)', // Add your image URL
    // backgroundSize: 'cover', // If you have a background image
  },
};

const customStyles = {
  control: (base) => ({
    ...base,
    height: 56,
    minHeight: 56,
  }),
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  country: Yup.string().required("Country is required"),
  house_number: Yup.string().required("House Number is required"),
  street: Yup.string().required("Street is required"),
  city: Yup.string().required("City is required"),
  postal_code: Yup.string().required("Postal Code is required"),
});

class Content extends Component {
  constructor(props) {
    super(props);

    // Create a ref
    this.paymentContainer = React.createRef();
  }

  state = {
    showPaymentFailedDialog: false,
    isLoading: true,
    session: {},
    payload: {},
    showPaymentFailedDialog: false,
    terms: {},
    country: {},
    isModalOpen: false,
    isModal: false,

    url: {},
    formData: {
      first_name: "",
      last_name: "",
      country: "",
      house_number: "",
      street: "",
      city: "",
      postal_code: "",
    },
    errors: {},
    showApplyDiscount: false,
    discountCode: "",
    discountData: {},
    showDiscount: false,
    discountSession: {},
    integration_id: "",
    bool: false,
    thool: false,
    discountFree: false,
  };
  componentDidMount = () => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        country: "GB",
      },
    }));
    function setActiveMenuItem() {
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }
    const terms = this.props?.history?.location?.state?.terms;
    this.setState({ terms: terms, isLoading: false }, () => {});
    const storedUserInfo = this.props.webOrderUser;
    if (storedUserInfo) {
      this.setState({ formData: storedUserInfo });
    }

    this.fetchEposPlan();
    console.log(this.props.propertyId, "props");
    this.selectPlan();
  };

  selectPlan = async () => {
    let data = {
      property_id: this.props.propertyId,
    };
    try {
      const response = await EposNowServiceAPI.selectPlan(data);

      if (response.data.status) {
        if (response.data.data) {
          this.setState(
            {
              session: response.data.data,
              showApplyDiscount: true,
              integration_id: response.data.data.integration_id,
            },
            () => {
              this.createCheckout();
            }
          );
        }
      } else {
        const err = response.data.message || "Something went wrong.";
        toast.warn(err);
      }
    } catch (error) {
      const err = "Something went wrong.";
      toast.warn(err);
      throw error;
    }
  };

  createCheckout = async () => {
    const { session } = this.state;
    try {
      if (dropin) {
        dropin.unmount();
        dropin = null;
      }
      checkout = await AdyenCheckout({
        environment: process?.env?.REACT_APP_ENVIR,
        session: {
          id: session?.session_id,
          sessionData: session?.session_data,
        },
        clientKey: process?.env.REACT_APP_KEY,
        analytics: {
          enabled: true, // Set to false to not send analytics data to Adyen.
        },
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            name: "Credit or debit card",
            billingAddressRequired: true,
          },
        },
        onPaymentCompleted: (response, component) => {
          if (response?.resultCode === "Authorised") {
            toast.success("Epos Now service has been activated.");

            // setTimeout(() => {
            //   this.fetchWebURL();
            // }, 2000);
            this.setState({ isLoading: false, isModalOpen: true });
          } else {
            this.setState({ isLoading: false });
            component.handleError();
          }
        },
        onError: (error, component) => {
          this.setState({ showPaymentFailedDialog: true, isLoading: false });
          component.unmount();
        },
      });

      // The 'ignore' flag is used to avoid double re-rendering caused by React 18 StrictMode
      // More about it here: https://beta.reactjs.org/learn/synchronizing-with-effects#fetching-data
      if (this.paymentContainer.current) {
        dropin = checkout.create("dropin").mount(this.paymentContainer.current);
      }
      this.setState({ isLoading: false });
    } catch (e) {
      toast.warn("Something went Wrong.");
      this.setState({ isLoading: false });
    }
  };
  fetchEposPlan = async () => {
    const dump = this.props.propertyId;
    try {
      const response = await EposNowServiceAPI.fetchEposPlan(dump);
      if (response.data.status) {
        if (response.data?.data) {
          this.setState(
            {
              eposPlan: response.data.data,
            },
            () => {
              if (
                response.data.data.subscription_status === "CANCELLED" ||
                response.data.data.subscription_status === "ACTIVE"
              ) {
              }
              {
                this.props.history.push({ pathname: "/epos-now" });
              }
            }
          );
        }
      } else {
        const err = response.data?.message || "Something went wrong.";
        //   toast.warn(err);
        this.setState({ eposPlan: {}, isLoading: false });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ eposPlan: {}, isLoading: false });
      //    toast.error(err);
      throw error;
    }
  };

  fetchWebURL = async () => {
    const dump = this.props.propertyId;
    try {
      const response = await WebOrderServiceAPI.fetchWebURL(dump);

      if (response.data.status) {
        if (response.data.data) {
          this.setState(
            {
              url: response.data.data,
            },
            () => {
              /*   if (response.data.data?.is_enable) {
              setTimeout(() => {
                this.props.history.push({ pathname: "/web-order-accept" });
              }, 4000)
            } */
            }
          );
        }
      } else {
        const err = response.data.message || "Something went wrong.";
        this.setState({ url: {}, isModalOpen: false });
        toast.warn(err);
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ url: {}, isModalOpen: false });
      toast.warn(err);
      throw error;
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.setWebOrderUserInfo(this.state.formData);
    validationSchema
      .validate(this.state.formData, { abortEarly: false })
      .then(() => {
        let data = {
          ...this.state.payload,
          ...this.state.formData,
          property_id: this.props.propertyId,
        };
        this.selectPlan(data);
      })
      .catch((errors) => {
        // Validation failed, update the state with errors
        const errorDetails = {};
        errors.inner.forEach((error) => {
          errorDetails[error?.path] = error?.message;
        });
        this.setState({ errors: errorDetails });
      });
  };
  handleFullDiscount = () => {
    this.setState({ discountFree: true });
    toast.success("Epos Now service has been activated.");
    this.props.history.push({ pathname: "/epos-now" });
  };

  handleOk = () => {
    this.setState({ isModalOpen: false });
  };
  handleCancel = () => {
    this.setState({ isModalOpen: false, discountFree: false });
    this.props.history.push({ pathname: "/epos-now" });
  };
  handleDone = () => {
    this.setState({ isModal: false, isModalOpen: false });
    this.props.history.push({ pathname: "/epos-now" });
  };
  handleYesClick = () => {
    this.setState({ isModal: false });
    this.props.history.push({ pathname: "/nash" });
  };

  handleCopy = async () => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(this.state.url.web_url);
        toast.success("Text copied to clipboard.");
      } else {
        // Fallback for browsers that do not support the Clipboard API
        const textarea = document.createElement("textarea");
        textarea.value = this.state.url.web_url;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        toast.success("Text copied to clipboard.");
      }
    } catch (err) {
      console.error("Unable to copy text to clipboard", err);
    }
  };
  handleDiscoundChange = (event) => {
    const { value } = event.target;
    this.setState({ discountCode: value.toUpperCase() });
  };
  handleRemoveDiscount = async () => {
    this.setState({ thool: true });

    try {
      const payload = {
        integration_id: this.state.integration_id,
      };
      const response = await EposNowServiceAPI.removeDiscount(payload);
      if (response.data.status) {
        this.setState(
          {
            session: response.data.data,
            discountData: "",
            discountCode: "",
            showDiscount: false,
            bool: false,
          },
          () => {
            this.createCheckout();
          }
        );

        toast.success("Discount removed successfully");
      }
    } catch (error) {
      toast.warn(`Error removing discount Please try again.`);
    }
  };
  handleDiscountSubmit = async () => {
    const { discountCode, session } = this.state;
    this.setState({ isLoading: true });
    if (!discountCode.trim()) {
      toast.warning("Discount code cannot be empty");
      this.setState({ isLoading: false });

      return;
    }

    try {
      const payload = {
        discount_code: discountCode,
        integration_id: session.integration_id,
      };

      const response = await EposNowServiceAPI.applyDiscount(payload);
      this.setState({ bool: true });

      if (response.data.status) {
        this.setState(
          {
            discountData: response.data.data,
            showDiscount: true,
            session: response.data.data,
            bool: true,
            isLoading: false,
          },
          () => {
            if (response.data.data.is_payment_required) {
              this.createCheckout();
            } else {
              this.handleFullDiscount();
            }
          }
        );

        toast.success("Discount applied successfully");
      } else {
        toast.error(response.data.message);
        this.setState({ bool: false, isLoading: false }, () => {
          this.createCheckout();
        });
      }
    } catch (error) {
      toast.warn("Error applying discount. Please try again.");
      this.setState({ bool: false, isLoading: false });
    }
  };

  calculateTotal = () => {
    const { terms, discountData } = this.state;
    const rawAmount =
      parseFloat((terms?.amount || "").replace(/[^\d.-]/g, "")) || 0;
    const rawDiscount =
      parseFloat(
        (discountData?.discounted_amount || "").replace(/[^\d.-]/g, "")
      ) || 0;
    const calculatedAmount = (rawAmount - rawDiscount).toFixed(2);
    return calculatedAmount;
  };

  render() {
    const {
      showPaymentFailedDialog,
      isLoading,
      order,
      formData,
      errors,
      session,
      country,
      terms,
      isModalOpen,
      url,
      discountData,
      bool,
      thool,
      isModal,
      discountFree,
    } = this.state;
    /*     const rawAmount = Number((terms?.amount || "").replace(/[^\d.-]/g, ""));
    const rawDiscount = Number(discountData?.discounted_amount || 0);
    const { classes } = this.props;
    const calculatedAmount = (rawAmount - rawDiscount).toFixed(2); */
    return (
      <Fragment>
        {isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />
          </div>
        ) : (
          <div className="ms-content-wrapper Dashboard">
            <div className="col-md-12">
              <Breadcrumb />
            </div>

            {/* {!url?.is_enable && ( */}
            <Grid container spacing={4}>
              <Grid item md={8}>
                <Paper elevation={3}>
                  {/* {Object.keys(session) == 0 && (
                    <CardContent
                      variant="outlined"
                      sx={{
                        maxHeight: "max-content",
                        maxWidth: "100%",
                        mx: "auto",
                        overflow: "auto",
                        resize: "horizontal",
                        borderRadius: "16px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoOutlinedIcon sx={{ marginRight: "5px" }} />
                        Add personal details
                      </Typography>
                      <Divider inset="none" />
                      <br /> <br />
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth>
                            <InputLabel>First Name</InputLabel>
                            <Input
                              name="first_name"
                              value={formData.first_name}
                              onChange={this.handleInputChange}
                            />
                            <Typography variant="body2" color="error">
                              {errors.first_name}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth>
                            <InputLabel>Last Name</InputLabel>

                            <Input
                              name="last_name"
                              value={formData.last_name}
                              onChange={this.handleInputChange}
                            />
                            <Typography variant="body2" color="error">
                              {errors.last_name}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth>
                            <InputLabel>House number</InputLabel>
                            <Input
                              name="house_number"
                              value={formData.house_number}
                              onChange={this.handleInputChange}
                            />
                            <Typography variant="body2" color="error">
                              {errors.house_number}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth>
                            <InputLabel>Street</InputLabel>
                            <Input
                              name="street"
                              value={formData.street}
                              onChange={this.handleInputChange}
                            />
                            <Typography variant="body2" color="error">
                              {errors.street}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth>
                            <InputLabel>City</InputLabel>
                            <Input
                              name="city"
                              value={formData.city}
                              onChange={this.handleInputChange}
                            />
                            <Typography variant="body2" color="error">
                              {errors.city}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth>
                            <InputLabel>Postal Code</InputLabel>
                            <Input
                              name="postal_code"
                              value={formData.postal_code}
                              onChange={this.handleInputChange}
                            />
                            <Typography variant="body2" color="error">
                              {errors.postal_code}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth>
                            <FormLabel>Country</FormLabel>
                            <Input
                              name="country"
                              placeholder="Enter Country Name"
                              value={formData.country}
                              onChange={this.handleInputChange}
                            />

                          </FormControl>
                          <ReactSelect
                            id="country"
                            name="country"
                            styles={customStyles}
                            className="lab"
                            classNamePrefix="react-select"
                            options={countryList().getData()}
                                   onChange={handleCountryOnChange}
                            value={{
                              value:
                                this.state.country.value ||
                                this.props.countryCode ||
                                "GB",
                              label:
                                this.state.country.label ||
                                this.props.country ||
                                "United Kingdom",
                            }}
                            onChange={(value) => {
                              let cont = {
                                country: value,
                                country: value.label,
                                country_code: value.value,
                              };

                              setFieldValue("country", value.label);

                              this.setState((prevState) => ({
                                formData: {
                                  ...prevState.formData,
                                  country: value.value,
                                },
                              }));

                              this.setState({ country: value });
                            }}
                          />

                          <Typography variant="body2" color="error">
                            {errors.country}
                          </Typography>
                        </Grid>
                      </Grid>
                      <br />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                      >
                        Submit
                      </Button>
                    </CardContent>
                  )} */}
                  <Box className="payment-container">
                    {/* {Object.keys(session).length > 0 && (
                      <Button
                        variant="contained"
                        sx={{ ml: 2, mt: 3, mb: 3 }}
                        onClick={() => {
                          this.paymentContainer.current.innerHtml = "";
                          window.location.reload();
                          setTimeout(() => {
                            this.setState({ session: {} });
                          }, 1000);
                        }}
                      >
                        Back
                      </Button>
                    )} */}
                    <div ref={this.paymentContainer} className="payment"></div>
                  </Box>
                  {showPaymentFailedDialog && (
                    <Dialog
                      onClose={(e, reason) => {
                        if (reason === "backdropClick") {
                          return;
                        }
                        this.setState({ showPaymentFailedDialog: false });
                      }}
                      open={showPaymentFailedDialog}
                    >
                      <DialogContent>
                        <Box textAlign="center">
                          <WarningAmberIcon
                            color="error"
                            sx={{ fontSize: "4rem" }}
                          />
                          <Typography color="error" variant="h5">
                            Something went wrong
                          </Typography>
                          <Button
                            sx={{ my: 2 }}
                            fullWidth
                            onClick={() => {
                              this.createCheckout();
                              this.setState({
                                showPaymentFailedDialog: false,
                              });
                            }}
                          >
                            Retry
                          </Button>
                          <Button
                            fullWidth
                            onClick={() =>
                              this.props.history.push({
                                pathname: "/epos-now",
                              })
                            }
                          >
                            Restart
                          </Button>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  )}
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper elevation={3} sx={{ width: "400px" }}>
                  <Card>
                    <Typography
                      variant="h5"
                      gutterBottom
                      style={{
                        background: "#5773ff",
                        textAlign: "center",
                        color: "white",
                        margin: "0px",
                        padding: "6px 0px",
                      }}
                    >
                      Order Details
                    </Typography>
                    <CardContent>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          color="textPrimary"
                          paragraph
                          sx={{ fontSize: "20px" }}
                        >
                          <b>{order?.plan_name || "EPOS NOW Integration"}</b>
                        </Typography>
                        <Typography
                          color="textPrimary"
                          style={{
                            background: "#5773ff",
                            textAlign: "center",
                            color: "white",
                            borderRadius: "7px",
                            padding: "6px 6px",
                          }}
                          paragraph
                        >
                          {(terms?.amount || "0.00") + " / Annually"}
                        </Typography>
                      </Box>
                      <Divider />
                      <br />
                      {/* <Typography color="textPrimary" paragraph>
                        QR Code Number: 
                      </Typography>
 */}

                      <Grid container spacing={1} alignItems="center">
                        <Grid
                          item
                          xs={12}
                          style={{
                            position: "relative",
                            top: "4px",
                          }}
                        >
                          <Typography
                            color="textPrimary"
                            paragraph
                            style={{
                              fontSize: "18px",
                            }}
                          >
                            {/* <div
                              dangerouslySetInnerHTML={{
                                __html: terms?.terms_and_conditions,
                              }}
                            /> */}
                            By activating this integration, your kiosk orders
                            will be received by your EPOS now. There is an
                            annual charge of {terms?.amount} to activate and
                            maintain this integration.
                          </Typography>
                        </Grid>
                      </Grid>

                      <br />

                      <b>
                        {" "}
                        <Divider />
                      </b>
                      <br />
                      {this.state.showDiscount ? (
                        <Grid
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography
                              color="textPrimary"
                              paragraph
                              sx={{ fontSize: "20px" }}
                            >
                              <b>Discount</b>
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Typography color="textPrimary" paragraph>
                              <b>
                                {" "}
                                {this.state.discountData.discounted_amount}{" "}
                              </b>
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                      <Grid
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography
                            color="textPrimary"
                            paragraph
                            sx={{ fontSize: "20px" }}
                          >
                            <b>Total</b>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography color="textPrimary" paragraph>
                            <b>
                              {" "}
                              {getCurrencySymbol(
                                localStorage.getItem("currency_code")
                              )}{" "}
                            </b>{" "}
                            <b>{this.calculateTotal()}</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Paper>

                {this.state.showApplyDiscount ? (
                  <div style={{ marginTop: "20px", width: "380px" }}>
                    <TextField
                      fullWidth
                      placeholder="Apply Discount Code"
                      variant="outlined"
                      value={this.state.discountCode}
                      onChange={this.handleDiscoundChange}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "3px dashed #5773ff",
                        },
                        "& input": {
                          fontSize: "16px",
                          fontWeight: "bold",
                          "&::placeholder": {
                            fontWeight: "bold",
                          },
                          borderColor: "#5773ff",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ pointerEvents: "none" }}
                          >
                            <DiscountIcon color="primary" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Stack direction="row">
                              {discountData.discounted_amount ? (
                                <DeleteIcon
                                  color="primary"
                                  fontSize="large"
                                  onClick={this.handleRemoveDiscount}
                                  disabled={thool}
                                />
                              ) : (
                                ""
                              )}
                              <Button
                                disabled={bool}
                                variant="contained"
                                sx={{ width: "80px", marginLeft: "10px" }}
                                onClick={this.handleDiscountSubmit}
                              >
                                {discountData.discounted_amount
                                  ? "APPLIED"
                                  : "APPLY"}{" "}
                              </Button>
                            </Stack>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            {/* )} */}

            <Grid container>
              <Grid item md={12}>
                <Modal title="" open={isModalOpen} onCancel={this.handleCancel}>
                  <Result
                    style={{ color: "#5773ff", marginBottom: "0px" }}
                    status="success"
                    title={"Payment Successful!"}
                    subTitle={
                      <Typography style={{ fontSize: "16px" }}>
                        We have activated epos now service on your account.
                      </Typography>
                    }
                  />
                  <div style={{ textAlign: "center", marginTop: "0px" }}>
                    <Button
                      size="large"
                      variant="contained"
                      className="white-text"
                      sx={{ height: "40px", width: "180px" }}
                      onClick={this.handleDone}
                    >
                      Done
                    </Button>
                  </div>
                </Modal>
              </Grid>
            </Grid>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
  webOrderUser: state.misc.webOrderUser,
  country: state.user.country,
  countryCode: state.user.countryCode,
});

const mapDispatchToProps = {
  setWebOrderUserInfo,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Content)
);
