import axios from 'axios';
import * as APIUrl from '../APIUrl'



export const getAllThemes =()=>{
    try{
        return axios({
            method: 'get',
            url: APIUrl.FETCH_ALL_THEMES,
        })
    }catch(error){
        throw new Error(error)
    }
}

export const asignTheme =(payload)=>{
    try{
        return axios({
            method: 'post',
            url: APIUrl.ASSIGN_THEME + `${payload.kiosk_theme_id}&property_id=${payload.property_id}`,
            data:payload,
        })
    }catch(error){
        throw new Error(error)
    }
}

export const fetchTheme =(property_id)=>{
    try{
        return axios({
            method: 'get',
            url: APIUrl.FETCH_THEME +  property_id,
        })
    }catch(error){
        throw new Error(error)
    }
}

