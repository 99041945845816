import React, { Component } from "react";
import { toast } from "react-toastify";
import * as CashServiceAPI from "../../../services/cash/CashServiceAPI";
import { Fragment } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { connect } from "react-redux";
import FolderIcon from '@mui/icons-material/Folder';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import Loader from "../../../shared/Loader";
import { CheckCircleOutline } from '@mui/icons-material';
import Breadcrumb from "./Breadcrumb";
import adyen from '../../../assets/img/adyen.png';
import $ from "jquery";
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

class index extends Component {


  state = {
    isLoading: false,
    payment_method_type: [],
    checked: ['wifi'],
    selectedCard: false,
    grants: [],
    dense: false,
    isChecked: false,
    plan: {},
    activeGrant: {}
  };

  componentDidMount = () => {
    function setActiveMenuItem() {

      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
          element.removeClass("active");
          element.find("li").removeClass("active");
          listitems.find(".collapse").slideUp();
      } else {
          element.addClass("active");
          listitems.children(".collapse").slideDown();
          listitems.siblings("li").children(".collapse").slideUp();
          element.siblings("li").removeClass("active");
          element.siblings("li").find("li").removeClass("active");
          listitems.siblings("li").find(".collapse").slideUp();
      }

  }
  setActiveMenuItem();
  if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
  } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
  }
    const dump = this.props.propertyId
    this.fetchGrants(dump);

    console.log(this.state);
    const plan = this.props?.history?.location?.state?.plan;
    this.setState({ plan: plan })
    console.log(plan)
  };

  handleCardSelect = (index) => {

  };



  handleToggle = (value) => () => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked });
  };


  fetchGrants = (payload) => {
    this.setState({ isLoading: true });
    if (payload) {
      CashServiceAPI.fetchAllGrants(payload)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data.data.adyen_info)
            this.setState({ grants: response.data.data, isLoading: false });
          } else {
            this.setState({ grants: [], isLoading: false });
          }

        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ isLoading: false });
    }


  };



  handleCheckboxChange = () => {
    this.setState({ isChecked: !this.state.isChecked })
  };

  handleSignRequest = () => {
    this.setState({ isLoading: true });
    const payload = {
      property_id: this.props.propertyId,
      grant_offer_id: this.state.plan.id
    }

    CashServiceAPI.requestGrant(payload)
      .then((response) => {
        if (response.data.status) {
          console.log(response.data.data)
          this.setState({ isLoading: false, activeGrant: response.data.data });
       //   toast.success("Grant requested intiated.");
          this.props.history.push({
            pathname: "/approved",
            state: {
              activeGrant: response.data.data
            }
          });
        } else {
          this.setState({ isLoading: false });
          toast.success("Grant requested failed.")
        }

      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });


  };

  render() {

    const { selectedCard, grants, dense, isChecked, plan, isLoading } = this.state;
    return (
      <Fragment>
        {this.state.isLoading ? <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "40px" }}>   <Loader size={50} /> </div> :
          <div className="">
            <div className="">
              <div className="">
                <div className="col-md-12">
                  <Breadcrumb />
                </div>
                <br />
                <div className="" >
                  <Typography variant="h4" >
                    Looking for business financing?
                  </Typography>
                  <h5>
                    You are two clicks away from getting funds.   Accept the terms and conditions and get your cash in minutes.
                  </h5>
                </div>

                <br /><br />
                <Paper elevation={3} sx={{ p: 2, borderRadius: 2, mb: 2 }}>
                  <Grid container spacing={3} >


                    <Grid item xs={12} md={12}>
                      <Typography sx={{ mt: 4, mb: 2 }} variant="h5" component="div">
                        Summary
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <List dense={dense}>
                        <ListItem>
                          <ListItemIcon>
                            <CheckCircleOutline />
                          </ListItemIcon>
                          <ListItemText
                            primary={"Financing amount: " + plan?.principal_amount}

                          />
                        </ListItem>



                      </List>


                    </Grid>

                    <Grid item xs={12} md={6}>
                      <List dense={dense}>
                        <ListItem>
                          <ListItemIcon>
                            <CheckCircleOutline />
                          </ListItemIcon>
                          <ListItemText
                            primary={"Withholding Percentage: " + plan?.repayment_percentage + " %"}

                          />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <List dense={dense}>
                        <ListItem>
                          <ListItemIcon>
                            <CheckCircleOutline />
                          </ListItemIcon>
                          <ListItemText
                            primary={"Threshold Amount: " + plan?.repayment_threshold}
                          />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <List dense={dense}>
                        <ListItem>
                          <ListItemIcon>
                            <CheckCircleOutline />
                          </ListItemIcon>
                          <ListItemText
                            primary={"Fee: " + plan?.fee}

                          />
                        </ListItem>



                      </List>


                    </Grid>
                    <Grid item xs={12} md={6}>
                      <List dense={dense}>
                        <ListItem>
                          <ListItemIcon>
                            <CheckCircleOutline />
                          </ListItemIcon>
                          <ListItemText
                            primary={"Contract type: " + plan?.contract_type}
                          />
                        </ListItem>



                      </List>


                    </Grid>

                    <Grid item xs={12} md={6}>
                      <List dense={dense}>
                        <ListItem>
                          <ListItemIcon>
                            <CheckCircleOutline />
                          </ListItemIcon>
                          <ListItemText
                            primary={"Repayment Amount: " + plan?.total_repayment_amount}


                          />
                        </ListItem>



                      </List>


                    </Grid>

                    <Grid item xs={12} md={6}>
                      <List dense={dense}>
                        <ListItem>
                          <ListItemIcon>
                            <CheckCircleOutline />
                          </ListItemIcon>
                          <ListItemText
                            primary={"Valid Upto: " + plan?.valid_upto}


                          />
                        </ListItem>



                      </List>


                    </Grid>

                    <Grid item xs={12} md={10}>
                      {/*     <Card>
                        <Typography variant="h5" gutterBottom>
                          Terms and Conditions
                        </Typography>
                        <CardContent sx={{ maxHeight: '300px', overflowY: 'auto' }}>

                          <Typography variant="body1">
                            The clauses you should include in your terms and conditions agreement varies depending on your industry and how consumers interact with your website or app.

                            To help you out, we’ve compiled a gigantic list of the most common clauses that appear in terms and conditions agreements:

                            Introduction clause
                            Intellectual property clause
                            Digital Millennium Copyright Act notice and policy
                            User contribution clause
                            Third parties clause
                            User registration clause
                            Acceptable uses clause
                            Prohibited activities and restrictions clause
                            Suspension and termination clause
                            Site management and support clause
                            Pricing and payment terms clause
                            Shipping policy clause
                            Returns and refund policy clause
                            Privacy policy clause
                            Limitation of Liabilities
                            Governing and applicable laws clause
                            Dispute resolutions and indemnification clause
                            Corrections and inaccuracies clause
                            Disclaimers and warranties clause
                            Contact information clause
                            Let’s discuss the contents of each of these clauses in greater detail.
                            Introduction clause
                            Intellectual property clause
                            Digital Millennium Copyright Act notice and policy
                            User contribution clause
                            Third parties clause
                            User registration clause
                            Acceptable uses clause
                            Prohibited activities and restrictions clause
                            Suspension and termination clause
                            Site management and support clause
                            Pricing and payment terms clause
                            Shipping policy clause
                            Returns and refund policy clause
                            Privacy policy clause
                            Limitation of Liabilities
                            Governing and applicable laws clause
                            Dispute resolutions and indemnification clause
                            Corrections and inaccuracies clause
                            Disclaimers and warranties clause
                            Contact information clause
                            Let’s discuss the contents of each of these clauses in greater detail.
                            Introduction clause
                            Intellectual property clause
                            Digital Millennium Copyright Act notice and policy
                            User contribution clause
                            Third parties clause
                            User registration clause
                            Acceptable uses clause
                            Prohibited activities and restrictions clause
                            Suspension and termination clause
                            Site management and support clause
                            Pricing and payment terms clause
                            Shipping policy clause
                            Returns and refund policy clause
                            Privacy policy clause
                            Limitation of Liabilities
                            Governing and applicable laws clause
                            Dispute resolutions and indemnification clause
                            Corrections and inaccuracies clause
                            Disclaimers and warranties clause
                            Contact information clause
                            Let’s discuss the contents of each of these clauses in greater detail.
                          </Typography>
                        </CardContent>
                      </Card> */}
                      <Typography variant="body2" gutterBottom sx={{ pl: 5 }}>
                        <b><Link to="/capital-terms-conditions"> Read Terms & Conditions. </Link>  </b>
                      </Typography>
                    </Grid>
                  </Grid>
                  <br /> <br />
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Checkbox
                        checked={isChecked}
                        onChange={this.handleCheckboxChange}
                        color="primary"
                      />
                    </Grid>
                    <Grid item>

                      <Typography >
                        I have read and I accept the terms and conditions and confirm that I am a legal representative authorised to accept these terms on behalf of the company.
                      </Typography>
                    </Grid>
                  </Grid>
                  <br /> <br /> <br />
                  <Grid container justifyContent="space-between">
                    <Grid item lg={8} >
                      <Button variant="outlined" onClick={() => {
                        this.props.history.goBack();
                      }}>
                        Back
                      </Button>
                    </Grid>
                    <Grid item lg={4}>
                      <Button variant="contained" disabled={!isChecked} color="primary" onClick={() => {
                        this.handleSignRequest();
                      }}>
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                  <br /> <br /> <br />
                  <Typography variant="body2" style={{ position: "fixed", bottom: "10px", right: "10px", display: "flex", alignItems: "center", fontFamily: "Roboto", fontSize: "16px", fontWeight: "bold", color: "#333" }}>
                    Powered by <img style={{ width: "100px", marginLeft: "5px" }} src={adyen} alt="Adyen Logo" />
                  </Typography>
                </Paper>




              </div> </div> </div>
        }
      </Fragment >
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId
});

export default withRouter(connect(mapStateToProps, {})(index));