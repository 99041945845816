import React, { Component, Fragment } from "react";
import * as OrderServiceAPI from "../../../services/order/OrderServiceAPI";
import * as DashboardServiceAPI from "../../../services/dashboard/DashboardServiceAPI";
import * as ReportServiceAPI from "../../../services/reports/ReportServiceAPI";
import DataTableComponet from "react-data-table-component";
import Pagination from "../../../shared/Pagination/Pagination";
import Breadcrumb from "./Breadcrumb";
import "../../../index.css";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Loader from "../../../shared/Loader";
import $ from "jquery";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DownloadingIcon from "@mui/icons-material/Downloading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import { Column } from "@ant-design/plots";
import Typography from "@mui/material/Typography";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Checkbox,
  OutlinedInput,
} from "@mui/material";
import { ItemList } from "./ItemList/ItemList.js";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Papa from "papaparse";
import { Grid, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { connect } from "react-redux";
import Alert from "@mui/material/Alert";
import ReactGA from "react-ga";
import { DatePicker, Space } from "antd";
import * as PaymentServiceAPI from "../../../services/payment/PaymentServiceAPI";

const { RangePicker } = DatePicker;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

class Content extends Component {
  state = {
    page: 0,
    order_details: [],
    current_page: 0,
    current_orders_page: 0,
    number_of_elements: 15,
    page_size: 10,
    total_elements: 0,
    total_pages: 0,
    total_order_pages: 0,
    property_id: "",
    search_text: "",
    changeBool: null,
    from_date: null,
    to_date: null,
    date_options_dd: null,
    isLoading: false,
    isPdfLoading: false,
    count: 0,
    hideFilter: false,
    start: dayjs(new Date()).startOf("week"),
    end: dayjs(new Date()),
    data: null,
    topSellingItems: [],
    trendingItemsSalesReport: [],
    unSoldItems: [],
    currentPage: 1,
    itemsPerPage: 10,
    tab: 0,
    selectTimePeriod: "DAILY",
    selectReport: "Item",
    max: new Date(),
    min: dayjs(new Date()).subtract(3, "year"),
    zReport: "",
    filterBy: 0,
    propertyId: "",
    filter: 0,
    defaultRange: [dayjs(new Date()).startOf("week"), dayjs()],
    tempRange: [dayjs(new Date()).startOf("week"), dayjs()],
    selectedProperties: [],
    franchises: [],
    open: false
  };

  constructor(props) {
    super(props);
    // this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setState({
      propertyId: this.props.propertyId,
      selectedProperties: [this.props.propertyId],
    });
    this.fetchFranchiseInformation();
    // this.getTrendingReport();

    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#reports").addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#reports").addClass("active");
    }
  };
  handleExportCSV = () => {
    if (this.state.zReport) {
      var link = document.createElement("a");
      link.href = this.state.zReport;
      link.download = "zReport";
      link.click();
    } else {
      toast.warn("No report found.");
    }
  };

  fnCallback = (e) => {
    this.setState({ hideFilter: false, filter: e?.target?.value });
    if (!e?.target?.value) {
      this.filterAllOrders();
    } else if (e?.target?.value == 1) {
      // Today
      let today = dayjs(new Date());

      this.setState(
        {
          start: dayjs().startOf("day"),
          end: today,
          defaultRange: [dayjs().startOf("day"), dayjs()],
        },
        () => {
          //    this.getTrendingSalesReport();
          //    this.getTrendingReport();
        }
      );
    } else if (e?.target?.value == 2) {
      // Yesterday
      // var today = dayjs(new Date()).subtract(1, 'day');
      let yesterday = dayjs(new Date()).subtract(1, "day");

      this.setState(
        {
          start: yesterday,
          end: yesterday,
          defaultRange: [
            dayjs(new Date()).subtract(1, "day").startOf("day"),
            dayjs(new Date()).subtract(1, "day").endOf("day"),
          ],
        },
        () => {
          //    this.getTrendingSalesReport();
          //     this.getTrendingReport();
        }
      );
    } else if (e?.target?.value == 3) {
      // Last 7 Days
      let today = dayjs(new Date()).subtract(1, "week").startOf("week");
      let last7 = dayjs(new Date()).subtract(1, "week").endOf("week");
      this.setState(
        { start: today, end: last7, defaultRange: [today, last7] },
        () => {
          //    this.getTrendingSalesReport();
          //     this.getTrendingReport();
        }
      );
    } else if (e?.target?.value == 4) {
      // Last 30 Days
      let today = dayjs(new Date()).startOf("week");
      let last30 = dayjs(new Date());
      this.setState(
        { start: today, end: last30, defaultRange: [today, last30,] },
        () => {
          //    this.getTrendingSalesReport();
          //      this.getTrendingReport();
        }
      );
    } else if (e?.target?.value == 9) {
      // Last 30 Days
      this.setState({ filterBy: 9, end: this.state.start }, () => {
        //      this.getTrendingSalesReport();
        //    this.getTrendingReport();
      });
    } else if (e?.target?.value == 5) {
      // Last 30 Days
      let today = dayjs(new Date());
      let last30 = dayjs(new Date()).subtract(1, "year").startOf("year");
      if (this.state.selectTimePeriod == "HOURLY") {
        this.setState(
          { start: dayjs(new Date()).startOf("week"), end: dayjs(new Date()) },
          () => {
            //       this.getTrendingSalesReport();
          }
        );
      } else {
        this.setState({ start: last30, end: today }, () => {
          //     this.getTrendingSalesReport();
        });
      }
    } else if (e?.target?.value == 6) {
      // Last 30 Days
      let today = dayjs(new Date());
      let firstDayOfMonth = dayjs(new Date()).startOf("month");

      if (this.state.selectTimePeriod == "HOURLY") {
        this.setState(
          { start: dayjs(new Date()).startOf("week"), end: dayjs(new Date()) },
          () => {
            //      this.getTrendingSalesReport();
          }
        );
      } else {
        this.setState({ start: firstDayOfMonth, end: today }, () => {
          //    this.getTrendingSalesReport();
        });
      }
    } else if (e?.target?.value == 7) {
      // Last 30 Day
      this.setState({ hideFilter: true });
    }
  };

  getTrendingReport = () => {
    this.setState({ isLoading: true });
    const property_id = (this.state.selectedProperties && this.state.selectedProperties.length > 0)
      ? this.state.selectedProperties
      : this.props.propertyId;
    let payload = {
      start_date:
        this.state.filter == 1 || this.state.filter == 2
          ? this.state?.start?.format("DD-MM-YYYY") + " 00:00:00"
          : this.state?.start?.format("DD-MM-YYYY HH:mm:ss"),
      end_date:
        (this.state.filter == 2 &&
          this.state.end?.format("DD-MM-YYYY") + " 23:59:59") ||
        (this.state.filter == 1 &&
          this.state.end?.format("DD-MM-YYYY HH:mm:ss")) ||
        this.state.end?.format("DD-MM-YYYY HH:mm:ss"),
      property_id: property_id,
    };

    ReportServiceAPI.getReport(payload)
      .then((response) => {
        if (response.data.status) {
          this.setState({ isLoading: false, data: response.data.data });
        } else {
          toast.warn(response.data.message || "No data retrieved.");
          this.setState({ isLoading: false, data: null });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        this.setState({ isLoading: false, data: null });
        toast.error(error);
      });
  };

  getTrendingSalesReport = () => {
    this.setState({ isPdfLoading: true });
    const property_id = (this.state.selectedProperties && this.state.selectedProperties.length > 0)
      ? this.state.selectedProperties
      : this.props.propertyId;
    let payload = {
      start_date:
        this.state.filter == 1 || this.state.filter == 2
          ? this.state.start.format("DD-MM-YYYY") + " 00:00:00"
          : this.state.start.format("DD-MM-YYYY HH:mm:ss"),
      end_date:
        (this.state.filter == 2 &&
          this.state.end.format("DD-MM-YYYY") + " 23:59:59") ||
        (this.state.filter == 1 &&
          this.state.end.format("DD-MM-YYYY HH:mm:ss")) ||
        this.state.end.format("DD-MM-YYYY HH:mm:ss"),
      property_id: property_id,
    };

    ReportServiceAPI.getZReport(payload)
      .then((response) => {
        if (response) {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file); //Open the URL on new Window
          // window.open(fileURL);
          //    this.downloadFile(fileURL);

          this.setState({ isPdfLoading: false, zReport: fileURL }, () => {
            this.handleExportCSV();
          });
        } else {
          toast.warn("Report not generated.");
          this.setState({
            isPdfLoading: false,
            trendingItemsSalesReport: [],
            zReport: "",
          });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false })
        this.setState({ isPdfLoading: false, zReport: "" });
        toast.error(error);
      });
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tab: newValue });
  };

  handleToDate = (e) => {
    //       const ctrl_name = e.target?.name;
    let toDat = dayjs(e);
    this.setState({ end: toDat, start: dayjs(toDat).startOf("week") }, () => {
      //  this.getTrendingSalesReport();
      this.getTrendingReport();
    });
  };

  handleFromDate = (e) => {
    //    const ctrl_name = e.target?.name;
    let today = dayjs(e);
    const endOfWeek = dayjs(today).add(7, "day");
    const result = endOfWeek.isAfter(new Date())
      ? dayjs(new Date())
      : endOfWeek;

    if (this.state.filter == 9) {
      this.setState({ end: today, start: today, filter: 9 }, () => {
        //  this.getTrendingSalesReport();
        this.getTrendingReport();
      });
    } else {
      this.setState({ end: result, start: today, filter: 0 }, () => {
        //    this.getTrendingSalesReport();
        this.getTrendingReport();
      });
    }
  };

  handleRangeChange = (value) => {
    // Handle the selected range here
    if (value && value.length === 2) {
      const [startDate, endDate] = value.map((date) =>
        dayjs(date, "DD-MM-YYYY HH:mm:ss")
      );

      this.setState(
        {
          start: startDate,
          end: endDate,
          defaultRange: [startDate, endDate],
          filter: 0,
        },
        () => {
          this.getTrendingReport();
        }
      );
    }
  };

  disabledDate = (current) => {
    // Get the current date and time
  };
  disabledRangeTime = (current, type) => {
    const currentTime = dayjs();
    const startDate = this.state.start; // Assuming this is where you store your start date
    const isEndDateSameAsStart = current && current.isSame(startDate, "day");

    // Disable times before the current time on the selected date
    if (type === "start" && current.isSame(currentTime, "day")) {
      return {
        disabledHours: () => [...Array(currentTime.hour()).keys()],
        disabledMinutes: (selectedHour) => {
          if (selectedHour === currentTime.hour()) {
            return [...Array(currentTime.minute()).keys()];
          }
          return [];
        },
        disabledSeconds: (selectedHour, selectedMinute) => {
          if (
            selectedHour === currentTime.hour() &&
            selectedMinute === currentTime.minute()
          ) {
            return [...Array(currentTime.second()).keys()];
          }
          return [];
        },
      };
    } else if (type === "end" && isEndDateSameAsStart) {
      // Disable times before the selected start time
      const startHour = startDate.hour();
      const startMinute = startDate.minute();
      const startSecond = startDate.second();
      return {
        disabledHours: () => [...Array(startHour).keys()],
        disabledMinutes: (selectedHour) => {
          if (selectedHour === startHour) {
            return [...Array(startMinute).keys()];
          }
          return [];
        },
        disabledSeconds: (selectedHour, selectedMinute) => {
          if (selectedHour === startHour && selectedMinute === startMinute) {
            return [...Array(startSecond).keys()];
          }
          return [];
        },
      };
    }

    return {};
  };

  handlePropChange = (event) => {
    const { value } = event.target;
    this.setState({ selectedProperties: value }, () => {
      //this.getTrendingReport();
    });
  };

  handleZreport = () => {
    // Your handler logic here
    this.getTrendingReport();
  };

  fetchFranchiseInformation() {
    const dump = {
      property_id: this.props.propertyId,
    };

    PaymentServiceAPI.fetchFranchiseInfomation(dump)
      .then((response) => {
        if (response.data.status) {
          this.setState({
            franchises: [
              ...response.data.data,
              {
                property_id: this.props.propertyId,
                property_name: this.props.user.user.business_trading_name,
              },
            ],
          });
        } else {
          this.setState({ franchises: [] });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        console.log(error);
        this.setState({ franchises: [] });
      });
  }

  handleOpenChange = (open) => {
    if (!open) {
      // When the picker closes, update the state and trigger the report
      const { tempRange } = this.state;
      if (tempRange?.length === 2) {
        const [startDate, endDate] = tempRange.map((date) =>
          dayjs(date)
        );

        this.setState(
          {
            start: startDate,
            end: endDate,
            defaultRange: [
              startDate,
              endDate,
            ],
            filter: 0,
          },
          () => {
            // this.getTrendingReport();
          }
        );
      } else {
        this.setState({
          start: null,
          end: null,
          defaultRange: [dayjs().startOf('week'), dayjs()],
          filter: 0,
        });
      }
    }
  };

  render() {
    const {
      tab,
      data,
      propertyId,
      defaultRange,
      selectedProperties,
      franchises,
    } = this.state;
    return (
      <Fragment>
        <Breadcrumb />
        {propertyId ? (
          <React.Fragment>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <AppBar position="static" color="default">
                    <Tabs
                      value={tab}
                      onChange={this.handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      TabIndicatorProps={{ style: { background: "#5773ff" } }}
                    >
                      <Tab label="Z Report" />
                      {/* <Tab label="Unsold Items" /> */}
                    </Tabs>
                    <TabPanel value={tab} index={0} className="tabPanel">
                      <div className="ms-panel">
                        <div className="ms-panel-body">
                          <div className="row">
                            <div className="col-xl-12 col-md-12 col-sm-12">
                              <div className="row m-2">
                                <div className="col-md-2">
                                  <div className="mt-4">
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        Filter By
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label" /* style={{ height: 40 }} */
                                        id="demo-simple-select"
                                        value={this.state.filter}
                                        label="Filter By"
                                        onChange={this.fnCallback}
                                      >
                                        <MenuItem value={0}>
                                          {" "}
                                          Filter By
                                        </MenuItem>
                                        <MenuItem value={1}>Today</MenuItem>
                                        <MenuItem value={2}>Yesterday</MenuItem>
                                        <MenuItem value={4}>This Week</MenuItem>
                                        <MenuItem value={3}>Last Week</MenuItem>
                                        {/*  <MenuItem value={9}>Any Single Day</MenuItem> */}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>

                                {/*     <div className="col-md-2">
                                                            <div className="mt-4">

                                                                <FormControl fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">Select Report</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={this.state.selectReport}
                                                                        label="Select Report"
                                                                        onChange={this.selectSalesByTypeItemReport}
                                                                    >
                                                                        <MenuItem value="Item">By Item</MenuItem>
                                                                        <MenuItem value={"Date"}>By Date</MenuItem>



                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
 */}

                                <div className="col-md-4">
                                  <div className="mt-4">
                                    <Space style={{}}>
                                      <RangePicker
                                        showTime
                                        id="zReport"
                                        name="zReport"
                                        size={"mini"}
                                        placement={"bottomLeft"}
                                        allowClear={false}
                                        disabledTime={this.disabledRangeTime}
                                        onOpenChange={this.handleOpenChange}
                                        onCalendarChange={(
                                          value,
                                          dateStrings
                                        ) => {
                                          // Handle the selected range here
                                          if (value && value.length === 2) {
                                            this.setState({
                                              tempRange: value,
                                            });
                                          }
                                        }}
                                        changeOnBlur={true}
                                        showOk={false}
                                        format="DD-MM-YYYY HH:mm:ss"
                                        value={defaultRange}
                                        // onChange={this.handleRangeChange}
                                        style={{ height: "50px" }}
                                      />
                                    </Space>
                                  </div>
                                </div>
                                {this.props.settings.is_master_franchise && (
                                  <div className="col-md-3">

                                    <div className="mt-3">
                                      <FormControl
                                        style={{
                                          margin: "8px",
                                          width: "300px",
                                        }}
                                      >
                                        <InputLabel id="demo-multiple-checkbox-label">
                                          Franchise
                                        </InputLabel>
                                        <Select
                                          labelId="demo-multiple-checkbox-label"
                                          id="demo-multiple-checkbox"
                                          multiple
                                          value={selectedProperties} // Use selectedProperties here
                                          onChange={this.handlePropChange}
                                          input={
                                            <OutlinedInput label="Franchise" />
                                          }
                                          renderValue={(selected) =>
                                            selected
                                              .map((id) => {
                                                const property =
                                                  franchises.find(
                                                    (item) =>
                                                      item.property_id === id
                                                  );
                                                return property
                                                  ? property.property_name
                                                  : "";
                                              })
                                              .join(", ")
                                          }
                                          MenuProps={MenuProps}
                                        >
                                          {/* <MenuItem
                                          key={this.props.propertyId}
                                          value={this.props.propertyId}
                                        >
                                          <Checkbox checked={selectedProperties.indexOf(this.props.propertyId) > -1} />
                                          <ListItemText primary={this.props.user.user.business_trading_name} />
                                        </MenuItem> */}
                                          {franchises.map((item) => (
                                            <MenuItem
                                              key={item.property_id}
                                              value={item.property_id}
                                            >
                                              <Checkbox
                                                checked={
                                                  selectedProperties.indexOf(
                                                    item.property_id
                                                  ) > -1
                                                }
                                              />
                                              <ListItemText
                                                primary={item.property_name}
                                              />
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </div>

                                  </div>
                                )}

                                <div className="col-md-3">
                                  <div className="mt-3" >
                                    <Button type="submit" sx={{ mt: 2 }} variant="contained" onClick={this.handleZreport} color="primary">
                                      GENERATE
                                    </Button>
                                  </div>
                                </div>

                                {/*    <div className="col-md-2">
                                                                    <div className="mt-4">

                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <Stack spacing={3}>
                                                                                <MobileDatePicker
                                                                                    label="Date"
                                                                                    value={this.state.start || new Date()}
                                                                                    inputFormat="DD-MM-YYYY"
                                                                                    InputProps={{
                                                                                        endAdornment: <InputAdornment position="Date"><CalendarMonthIcon /></InputAdornment>,
                                                                                    }}
                                                                                    maxDate={this.state.max}
                                                                                    // minDate={this.state.min}
                                                                                    onChange={this.handleFromDate}
                                                                                    renderInput={(params) => <TextField {...params} />}
                                                                                />
                                                                            </Stack>
                                                                        </LocalizationProvider>
                                                                    </div>
                                                                </div>
 */}
                                {/*         <div className="col-md-2">
                                                            <div className="mt-4">

                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <Stack spacing={3}>
                                                                        <MobileDatePicker
                                                                            label="To"
                                                                            value={this.state.end || new Date()}
                                                                            inputFormat="DD-MM-YYYY"
                                                                            onChange={this.handleToDate}
                                                                            maxDate={this.state.max}
                                                                            // minDate={this.state.min}
                                                                            InputProps={{
                                                                                endAdornment: <InputAdornment position="start"><CalendarMonthIcon /></InputAdornment>,
                                                                            }}
                                                                            renderInput={(params) => <TextField {...params} />}
                                                                        />
                                                                    </Stack>
                                                                </LocalizationProvider>
                                                            </div>
                                                        </div> */}

                                {/*            </React.Fragment>
                                                        } */}
                              </div>

                              <div
                                className=""
                                style={{ marginTop: "100px !important" }}
                              >
                                {this.state.isLoading ? (
                                  <div
                                    style={{
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      marginBottom: "40px",
                                    }}
                                  >
                                    {" "}
                                    <Loader size={50} />{" "}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    {/* 
                                                                <div className="col-md-6">
                                                                    <h4>Total Item Sales</h4>
                                                                    <Line {...totalItemsSalesReportConfig} />

                                                                </div>

                                                                <div className="col-md-6">
                                                                    <h4>Top Selling Items</h4>
                                                                    <Column {...config} />

                                                                </div>

                                                                <div className="col-md-6" style={{ marginTop: "50px" }}>
                                                                    <h4>Trending </h4>

                                                                    <Line {...trendingReportConfig} />

                                                                </div> */}
                                    {/* 
                                                                <div className="col-md-6">
                                                                    <Column {...config} />

                                                                </div> */}

                                    <br></br>
                                    {this.state.data && (
                                      <React.Fragment>
                                        {this.state.isPdfLoading ? (
                                          <div
                                            style={{
                                              marginLeft: "auto",
                                              marginRight: "auto",
                                              marginBottom: "40px",
                                            }}
                                          >
                                            {" "}
                                            <Loader size={50} />{" "}
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <Button
                                              variant="contained"
                                              style={{ float: "right" }}
                                              color="primary"
                                              onClick={
                                                this.getTrendingSalesReport
                                              }
                                            >
                                              Download Report
                                            </Button>
                                          </div>
                                        )}
                                        <br></br>

                                        <Paper
                                          elevation={3}
                                          style={{
                                            padding: "20px",
                                            margin: "0px",
                                          }}
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item xs={12} lg={6}>
                                              <Typography variant="body1">
                                                Z Report
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              lg={6}
                                              style={{ textAlign: "right" }}
                                            >
                                              <Typography variant="body1">
                                                {" "}
                                                {data?.generatedDate}
                                              </Typography>
                                            </Grid>
                                            <Grid item lg={4}></Grid>
                                            <Grid
                                              xs={12}
                                              item
                                              lg={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Typography variant="h5">
                                                {" "}
                                                {data?.businessName}
                                              </Typography>
                                              <Typography variant="h5">
                                                {" "}
                                                {data?.businessAdress}
                                              </Typography>
                                            </Grid>
                                            <Grid item lg={4}></Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Divider />
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Typography variant="h6">
                                                SALES
                                              </Typography>
                                              <Divider />
                                            </Grid>
                                            {/* 
                                                                                        <Grid item xs={4} lg={4} style={{ textAlign: "center" }}>
                                                                                            <div>
                                                                                                <Typography variant="body1">Category</Typography>

                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={4} lg={4} style={{ textAlign: "center" }}>
                                                                                            <div>

                                                                                                <Typography variant="body1">Quantity</Typography>

                                                                                            </div>
                                                                                        </Grid>

                                                                                        <Grid item xs={4} lg={4} style={{ textAlign: "center" }}>
                                                                                            <div>

                                                                                                <Typography variant="body1">Sales</Typography>
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} style={{ textAlign: "center" }}>

                                                                                            <Divider />
                                                                                        </Grid>

                                                                                        <Grid item xs={12} style={{ textAlign: "center" }}>

                                                                                            {data?.items.map((item, index) => (
                                                                                                <Grid container spacing={2} key={index}>
                                                                                                    <Grid xs={4} item lg={4}>
                                                                                                        <Typography variant="body1"> {item.category}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid xs={4} item lg={4}>
                                                                                                        <Typography variant="body1">{item.quantity}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid xs={4} item lg={4}>
                                                                                                        <Typography variant="body1">{item.sales}</Typography>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            ))}
                                                                                        </Grid>

                                                                                        <Grid item xs={12} style={{ textAlign: "center" }}>

                                                                                            <Divider />
                                                                                        </Grid>


                                                                                        <Grid item xs={4} lg={4} style={{ textAlign: "center" }}>
                                                                                            <div>
                                                                                                <Typography variant="body1">Total</Typography>

                                                                                            </div>
                                                                                        </Grid>

                                                                                        <Grid item xs={4} lg={4} style={{ textAlign: "center" }}>
                                                                                            <div>

                                                                                                <Typography variant="body1"></Typography>

                                                                                            </div>
                                                                                        </Grid>



                                                                                        <Grid item xs={4} lg={4} style={{ textAlign: "center" }}>
                                                                                            <div>

                                                                                                <Typography variant="body1">{data?.totalCategorySales}</Typography>
                                                                                            </div>
                                                                                        </Grid> */}
                                            <TableContainer component={Paper}>
                                              <Table
                                                aria-label="simple table"
                                                size="small"
                                                dense
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{ width: "20%" }}
                                                      align="center"
                                                    >
                                                      Item Name
                                                    </TableCell>
                                                    <TableCell
                                                      style={{ width: "20%" }}
                                                      align="center"
                                                    >
                                                      Quantity
                                                    </TableCell>
                                                    <TableCell
                                                      style={{ width: "20%" }}
                                                      align="center"
                                                    >
                                                      Net
                                                    </TableCell>
                                                    <TableCell
                                                      style={{ width: "20%" }}
                                                      align="center"
                                                    >
                                                      Vat
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Gross {/* &#163; */}{" "}
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {data?.items.map(
                                                    (item, index) => (
                                                      <TableRow>
                                                        <TableCell
                                                          style={{ width: "20%" }}
                                                          align="center"
                                                        >
                                                          {item.category}
                                                        </TableCell>
                                                        <TableCell
                                                          style={{ width: "20%" }}
                                                          align="center"
                                                        >
                                                          {item.quantity}
                                                        </TableCell>
                                                         <TableCell
                                                          style={{ width: "20%" }}
                                                          align="center"
                                                        >
                                                          {item.salesExc}
                                                        </TableCell>
                                                        <TableCell
                                                          style={{ width: "20%" }}
                                                          align="center"
                                                        >
                                                          {item.vat}
                                                        </TableCell>
                                                        <TableCell
                                                          style={{
                                                            minWidth: "150px",
                                                          }}
                                                          align="center"
                                                        >
                                                          {" "}
                                                          {item.sales}
                                                        </TableCell>
                                                      </TableRow>
                                                    )
                                                  )}

                                                  <TableRow>
                                                    <TableCell
                                                      style={{ width: "33.3%" }}
                                                      align="center"
                                                    >
                                                      <b> Total Sales</b>
                                                    </TableCell>
                                                    <TableCell align="center">{data?.totalQuantity}</TableCell>  
                                                    <TableCell align="center">{data?.salesExc}</TableCell>
                                                    <TableCell align="center">{data?.totalItemVat}</TableCell>
                                                    <TableCell
                                                      style={{
                                                        minwidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      <b>
                                                        {
                                                          data?.totalCategorySales
                                                        }
                                                      </b>
                                                    </TableCell>
                                                  </TableRow>
                                                  {/* {data?.itemVat && (<TableRow>
                                                    <TableCell align="center">
                                                      <b>Total Item VAT</b>
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "100px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      <b>{data?.itemVat}</b>
                                                    </TableCell>
                                                  </TableRow>)}
                                                  {data?.orderVat && (<TableRow>
                                                    <TableCell align="center">
                                                      <b>Total Order VAT</b>
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "100px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      <b>{data?.orderVat}</b>
                                                    </TableCell>
                                                  </TableRow>)} */}
                                                  {data?.grossTotal && (<TableRow>
                                                    <TableCell align="center">
                                                      <b>Gross Total</b>
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "100px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      <b>{data?.grossTotal}</b>
                                                    </TableCell>
                                                  </TableRow>)}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Typography variant="h6">
                                                SURPLUS CHARGES
                                              </Typography>
                                              <Divider />
                                            </Grid>
                                            <TableContainer component={Paper}>
                                              <Table
                                                aria-label="simple table"
                                                size="small"
                                                dense
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      Name
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      Source
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      Rate {/* &#163; */}{" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      Count {/* &#163; */}{" "}
                                                    </TableCell>
                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      Amount {/* &#163; */}{" "}
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {data?.surPlus.map(
                                                    (item, index) => (
                                                      <TableRow>
                                                        <TableCell align="center">
                                                          {" "}
                                                          {item.name}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          {" "}
                                                          {item.source}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          {" "}
                                                          {item.rate}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          {" "}
                                                          {item.count}
                                                        </TableCell>
                                                        <TableCell
                                                          style={{
                                                            minWidth: "150px",
                                                          }}
                                                          align="center"
                                                        >
                                                          {" "}
                                                          {item.amount}
                                                        </TableCell>
                                                      </TableRow>
                                                    )
                                                  )}
                                                  <TableRow>
                                                    <TableCell
                                                      align="center"
                                                    >
                                                      {" "}
                                                      <b> Total Delivery Charge</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                      <b>
                                                        {" "}
                                                        {data?.totalDeliveryCharge}
                                                      </b>
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell
                                                      align="center"
                                                    >
                                                      {" "}
                                                      <b> Total Tip Amount</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                      <b>
                                                        {" "}
                                                        {data?.totalTipAmount}
                                                      </b>
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      {" "}
                                                      <b>
                                                        Total Service charges
                                                      </b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                      <b>
                                                        {data?.totalServiceTax}
                                                      </b>
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      {" "}
                                                      <b> Total</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {" "}
                                                      <b>
                                                        {" "}
                                                        {data?.totalSurPlus}
                                                      </b>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                            <br /> <br />
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Typography variant="h6">
                                                PAYMENT DETAILS
                                              </Typography>
                                              <Divider />
                                            </Grid>
                                            <TableContainer component={Paper}>
                                              <Table
                                                aria-label="simple table"
                                                size="small"
                                                dense
                                              >
                                                <TableBody>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{ width: "33.3%" }}
                                                      align="center"
                                                    >
                                                      Cash (
                                                      {data?.totalCashCount})
                                                    </TableCell>
                                                    <TableCell
                                                      style={{ width: "33.3%" }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      {data?.cashPayment}{" "}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{ width: "33.3%" }}
                                                      align="center"
                                                    >
                                                      Card (
                                                      {data?.totalCardCount})
                                                    </TableCell>
                                                    <TableCell
                                                      style={{ width: "33.3%" }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      {data?.cardPayment}{" "}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{ width: "33.3%" }}
                                                      align="center"
                                                    >
                                                      Split (
                                                      {data?.totalSplitCount})
                                                    </TableCell>
                                                    <TableCell
                                                      style={{ width: "33.3%" }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {data?.totalSplitAmount}{" "}
                                                    </TableCell>
                                                  </TableRow>
                                                  {data?.totalInvoiceCount && (
                                                    <TableRow>
                                                      <TableCell
                                                        style={{
                                                          width: "33.3%",
                                                        }}
                                                        align="center"
                                                      >
                                                        Invoice (
                                                        {
                                                          data?.totalInvoiceCount
                                                        }
                                                        )
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          width: "33.3%",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "150px",
                                                        }}
                                                        align="center"
                                                      >
                                                        {" "}
                                                        {
                                                          data?.totalInvoicePayment
                                                        }{" "}
                                                      </TableCell>
                                                    </TableRow>
                                                  )}
                                                  {data?.totalDogDollarCount && (
                                                    <TableRow>
                                                      <TableCell
                                                        style={{
                                                          width: "33.3%",
                                                        }}
                                                        align="center"
                                                      >
                                                        Dog Dollar (
                                                        {
                                                          data?.totalDogDollarCount
                                                        }
                                                        )
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          width: "33.3%",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "150px",
                                                        }}
                                                        align="center"
                                                      >
                                                        {" "}
                                                        {
                                                          data?.totalDogDollar
                                                        }{" "}
                                                      </TableCell>
                                                    </TableRow>
                                                  )}

                                                  {data?.totalUberEats && (
                                                    <TableRow>
                                                      <TableCell
                                                        style={{
                                                          width: "33.3%",
                                                        }}
                                                        align="center"
                                                      >
                                                        Uber Eats (
                                                        {
                                                          data?.totalUberEatsCount
                                                        }
                                                        )
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          width: "33.3%",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "150px",
                                                        }}
                                                        align="center"
                                                      >
                                                        {" "}
                                                        {
                                                          data?.totalUberEats
                                                        }{" "}
                                                      </TableCell>
                                                    </TableRow>
                                                  )}

                                                  {data?.totalJustEats && (
                                                    <TableRow>
                                                      <TableCell
                                                        style={{
                                                          width: "33.3%",
                                                        }}
                                                        align="center"
                                                      >
                                                        Just Eats (
                                                        {
                                                          data?.totalJustEatsCount
                                                        }
                                                        )
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          width: "33.3%",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "150px",
                                                        }}
                                                        align="center"
                                                      >
                                                        {" "}
                                                        {
                                                          data?.totalJustEats
                                                        }{" "}
                                                      </TableCell>
                                                    </TableRow>
                                                  )}

                                                  {data?.totalDeliveroo && (
                                                    <TableRow>
                                                      <TableCell
                                                        style={{
                                                          width: "33.3%",
                                                        }}
                                                        align="center"
                                                      >
                                                        Deliveroo (
                                                        {
                                                          data?.totalDeliverooCount
                                                        }
                                                        )
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          width: "33.3%",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "150px",
                                                        }}
                                                        align="center"
                                                      >
                                                        {" "}
                                                        {
                                                          data?.totalDeliveroo
                                                        }{" "}
                                                      </TableCell>
                                                    </TableRow>
                                                  )}

                                                  <TableRow>
                                                    <TableCell
                                                      style={{ width: "33.3%" }}
                                                      align="center"
                                                    >
                                                      <b>
                                                        {" "}
                                                        Total (
                                                        {
                                                          data?.totalCashCardCount
                                                        }
                                                        )
                                                      </b>
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      <b>
                                                        {data?.totalPaymentSale}
                                                      </b>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Typography variant="h6">
                                                PAYMENT SOURCE
                                              </Typography>
                                              <Divider />
                                            </Grid>
                                            <TableContainer component={Paper}>
                                              <Table
                                                aria-label="simple table"
                                                size="small"
                                                dense
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      EPOS
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      KIOSK
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      TERMINAL {/* &#163; */}{" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      MPOS {/* &#163; */}{" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      PAY BY LINK {/* &#163; */}{" "}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      WEB {/* &#163; */}{" "}
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Net Cash : &nbsp;&nbsp;
                                                      &nbsp; {data?.eposCash}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Net Cash : &nbsp;&nbsp;
                                                      &nbsp; {data?.kioskCash}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      Net Cash : &nbsp;&nbsp;
                                                      &nbsp;{" "}
                                                      {data?.terminalCash}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Net Cash : &nbsp;&nbsp;
                                                      &nbsp; {data?.mposCash}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Net Cash : &nbsp;&nbsp;
                                                      &nbsp;{" "}
                                                      {data?.payByLinkCash}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Net Cash : &nbsp;&nbsp;
                                                      &nbsp; {data?.webCash}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Net Card : &nbsp;&nbsp;
                                                      &nbsp; {data?.eposCard}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Net Card : &nbsp;&nbsp;
                                                      &nbsp; {data?.kioskCard}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      Net Card : &nbsp;&nbsp;
                                                      &nbsp;{" "}
                                                      {data?.terminalCard}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Net Card : &nbsp;&nbsp;
                                                      &nbsp; {data?.mposCard}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Net Card : &nbsp;&nbsp;
                                                      &nbsp;{" "}
                                                      {data?.payByLinkCard}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Net Card : &nbsp;&nbsp;
                                                      &nbsp; {data?.webCard}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Split Card: &nbsp;&nbsp;
                                                      &nbsp;{" "}
                                                      {data?.totalSplitCardAmount ||
                                                        Number(0).toFixed(2)}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                    </TableCell>
                                                    {/* <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >  Split Card: &nbsp;&nbsp;
                                                      &nbsp;{" "}
                                                      {data?.totalMposSplitCardAmount ||
                                                        Number(0).toFixed(2)}</TableCell> */}
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    ></TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Split Cash: &nbsp;&nbsp;
                                                      &nbsp;{" "}
                                                      {data?.totalSplitCashAmount ||
                                                        Number(0).toFixed(2)}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                    </TableCell>
                                                    {/* <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >   Split Cash: &nbsp;&nbsp;
                                                      &nbsp;{" "}
                                                      {data?.totalMposSplitCashAmount ||
                                                        Number(0).toFixed(2)}</TableCell> */}
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    ></TableCell>
                                                  </TableRow>
                                                  {data?.totalSplitDogDollar && (<TableRow>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "240px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Split Dog Dollar:
                                                      &nbsp;&nbsp; &nbsp;{" "}
                                                      {data?.totalSplitDogDollar ||
                                                        Number(0).toFixed(2)}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    ></TableCell>
                                                  </TableRow>)}
                                                  {data?.invoicePayment && (<TableRow>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Invoice: &nbsp;&nbsp;
                                                      &nbsp;{" "}
                                                      {data?.invoicePayment ||
                                                        Number(0).toFixed(2)}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "200px",
                                                      }}
                                                      align="center"
                                                    ></TableCell>
                                                  </TableRow>)}

                                                  {data?.dogDollarPayment && (
                                                    <TableRow>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      >
                                                        Dog Dollar: &nbsp;&nbsp;
                                                        &nbsp;{" "}
                                                        {data?.dogDollarPayment ||
                                                          Number(0).toFixed(2)}
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      >
                                                        {" "}
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                    </TableRow>
                                                  )}
                                                  {data?.totalJustEatsPayment && (
                                                    <TableRow>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      >
                                                        Uber Eats: &nbsp;&nbsp;
                                                        &nbsp;{" "}
                                                        {data?.totalJustEatsPayment ||
                                                          Number(0).toFixed(2)}
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      >
                                                        {" "}
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                    </TableRow>
                                                  )}
                                                  {data?.totalUberEatsPayment && (
                                                    <TableRow>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      >
                                                        Uber Eats: &nbsp;&nbsp;
                                                        &nbsp;{" "}
                                                        {data?.totalUberEatsPayment ||
                                                          Number(0).toFixed(2)}
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      >
                                                        {" "}
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                    </TableRow>
                                                  )}
                                                  {data?.totalDeliverooPayment && (
                                                    <TableRow>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      >
                                                        Deliveroo: &nbsp;&nbsp;
                                                        &nbsp;{" "}
                                                        {data?.totalDeliverooPayment ||
                                                          Number(0).toFixed(2)}
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      >
                                                        {" "}
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          minWidth: "200px",
                                                        }}
                                                        align="center"
                                                      ></TableCell>
                                                    </TableRow>
                                                  )}
                                                  <TableRow>

                                                    {data?.totalCashDrop &&

                                                      <TableCell
                                                        style={{
                                                          minWidth: "300px",
                                                        }}
                                                        colSpan={2}
                                                        align="center"
                                                      >
                                                        {" "}
                                                        <b>
                                                          {" "}
                                                          TOTAL CASH DROP:
                                                          &nbsp;&nbsp; &nbsp;{" "}
                                                          {data?.totalCashDrop}
                                                        </b>
                                                      </TableCell>
                                                    }

                                                    {data?.totalCardDrop &&
                                                      <TableCell
                                                        style={{
                                                          minWidth: "300px",
                                                        }}
                                                        align="center"
                                                      >
                                                        <b>
                                                          {" "}
                                                          TOTAL CARD DROP :
                                                          &nbsp;&nbsp; &nbsp;{" "}
                                                          {data?.totalCardDrop}
                                                        </b>
                                                      </TableCell>

                                                    }

                                                    <TableCell
                                                      style={{
                                                        minWidth: "300px",
                                                      }}
                                                      align="center"
                                                      colSpan={2}
                                                    >
                                                      {" "}
                                                      {data?.totalInvoicePayment && (
                                                        <b>
                                                          {" "}
                                                          TOTAL INVOICE DROP :
                                                          &nbsp;&nbsp; &nbsp;{" "}
                                                          {
                                                            data?.totalInvoicePayment
                                                          }
                                                        </b>
                                                      )}
                                                    </TableCell>


                                                  </TableRow>
                                                  <TableRow>
                                                    {data?.totalJustEatsDrop &&
                                                      <TableCell
                                                        style={{
                                                          minWidth: "300px",
                                                        }}
                                                        colSpan={2}
                                                        align="center"
                                                      >
                                                        {" "}
                                                        <b>
                                                          {" "}
                                                          TOTAL JUSTEATS DROP:
                                                          &nbsp;&nbsp; &nbsp;{" "}
                                                          {data?.totalJustEatsDrop}
                                                        </b>
                                                      </TableCell>
                                                    }

                                                    {data?.totalDogDollarDrop && (
                                                      <TableCell
                                                        style={{
                                                          minWidth: "300px",
                                                        }}
                                                        align="center"
                                                      >
                                                        <b>
                                                          {" "}
                                                          TOTAL DOG DOLLAR DROP
                                                          : &nbsp;&nbsp; &nbsp;{" "}
                                                          {data?.totalDogDollarDrop}
                                                        </b>
                                                      </TableCell>
                                                    )}
                                                    {data?.totalUberEatsDrop &&
                                                      <TableCell
                                                        style={{
                                                          minWidth: "300px",
                                                        }}
                                                        align="center"

                                                      >
                                                        {" "}
                                                        <b>
                                                          {" "}
                                                          TOTAL UBEREATS DROP:
                                                          &nbsp;&nbsp; &nbsp;{" "}
                                                          {data?.totalUberEatsDrop}
                                                        </b>
                                                      </TableCell>
                                                    }
                                                  </TableRow>


                                                  <TableRow>
                                                    {data?.totalDeliverooDrop &&
                                                      <TableCell
                                                        style={{
                                                          minWidth: "300px",
                                                        }}
                                                        colSpan={2}
                                                        align="center"
                                                      >
                                                        {" "}
                                                        <b>
                                                          {"  "}
                                                          TOTAL DELIVEROO DROP:
                                                          &nbsp;&nbsp; &nbsp;{" "}
                                                          {data?.totalDeliverooDrop}
                                                        </b>
                                                      </TableCell>
                                                    }
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Divider />
                                            </Grid>
                                            <br />
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Typography variant="h6">
                                                REFUND DETAIL
                                              </Typography>
                                              <Divider />
                                            </Grid>
                                            <TableContainer component={Paper}>
                                              <Table
                                                aria-label="simple table"
                                                size="small"
                                                dense
                                              >
                                                <TableBody>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    >
                                                      Card refund
                                                    </TableCell>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      {data?.cardRefundAmount}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    >
                                                      Cash refund
                                                    </TableCell>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      {data?.cashRefundAmount}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    >
                                                      Split refund
                                                    </TableCell>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      {data?.splitRefundAmount}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    >
                                                      <b> Total refund</b>
                                                    </TableCell>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      <b>
                                                        {" "}
                                                        {
                                                          data?.totalRefundAmount
                                                        }
                                                      </b>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                            <br />
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Typography variant="h6">
                                                TILL CASH
                                              </Typography>
                                              <Divider />
                                            </Grid>
                                            <TableContainer component={Paper}>
                                              <Table
                                                aria-label="simple table"
                                                size="small"
                                                dense
                                              >
                                                <TableBody>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    >
                                                      Added Cash{" "}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      {data?.tillCash}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    >
                                                      Sales Cash
                                                    </TableCell>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      {data?.totalCashDrop}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    >
                                                      Withdrawn Cash
                                                    </TableCell>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      {data?.withdrawCash}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    >
                                                      <b> Total Till Cash</b>
                                                    </TableCell>
                                                    <TableCell
                                                      style={{ width: "33%" }}
                                                      align="center"
                                                    ></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      <b>
                                                        {" "}
                                                        {data?.totalTillCash}
                                                      </b>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Typography variant="h6">
                                                TOTAL DISCOUNTS
                                              </Typography>
                                              <Divider />
                                            </Grid>
                                            <TableContainer component={Paper}>
                                              <Table
                                                aria-label="simple table"
                                                size="small"
                                                dense
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{ width: "33.3%" }}
                                                      align="center"
                                                    >
                                                      Discount Name
                                                    </TableCell>
                                                    <TableCell
                                                      style={{ width: "33.3%" }}
                                                      align="center"
                                                    >
                                                      Count
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Amount {/* &#163; */}{" "}
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {data?.discounts.map(
                                                    (item, index) => (
                                                      <TableRow>
                                                        <TableCell
                                                          style={{
                                                            width: "33.3%",
                                                          }}
                                                          align="center"
                                                        >
                                                          {item.name}
                                                        </TableCell>
                                                        <TableCell
                                                          style={{
                                                            width: "33.3%",
                                                          }}
                                                          align="center"
                                                        >
                                                          {item.count}
                                                        </TableCell>
                                                        <TableCell
                                                          style={{
                                                            minWidth: "150px",
                                                          }}
                                                          align="center"
                                                        >
                                                          {" "}
                                                          {item.amount}
                                                        </TableCell>
                                                      </TableRow>
                                                    )
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Typography variant="h6">
                                                SALES SUMMARY{" "}
                                              </Typography>
                                              <Divider />
                                            </Grid>
                                            <TableContainer component={Paper}>
                                              <Table
                                                aria-label="simple table"
                                                size="small"
                                                dense
                                              >
                                                <TableBody>
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      <b> Net Sales</b>
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "100px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      <b>{data?.netSales}</b>
                                                    </TableCell>
                                                  </TableRow>
                                                  {/* {data?.itemVat && (<TableRow>
                                                    <TableCell align="center">
                                                      <b>Total Item VAT</b>
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "100px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      <b>{data?.itemVat}</b>
                                                    </TableCell>
                                                  </TableRow>)}
                                                  {Object.entries(
                                                    data?.vatDetailsMap
                                                  ).map(([key, value]) => (
                                                    <>
                                                      <TableRow >
                                                        <TableCell align="center">
                                                          {key}
                                                        </TableCell>
                                                        <TableCell align="center"></TableCell>
                                                        <TableCell align="center">
                                                          {value}
                                                        </TableCell>
                                                      </TableRow>
                                                    </>
                                                  ))}

                                                  <TableRow>
                                                    <TableCell align="center">
                                                      <b> Total Net Sales</b>
                                                    </TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "100px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {" "}
                                                      <b>
                                                        {data?.totalNetSales}
                                                      </b>
                                                    </TableCell>
                                                  </TableRow> */}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Typography variant="h6">
                                                CREDIT CARD BREAKDOWN{" "}
                                              </Typography>
                                              <Divider />
                                            </Grid>
                                            <TableContainer component={Paper}>
                                              <Table
                                                aria-label="simple table"
                                                size="small"
                                                dense
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center">
                                                      Count
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Gross {/* &#163; */}{" "}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Mer Fee {/* &#163; */}{" "}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Txn Fee {/* &#163; */}{" "}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      Net {/* &#163; */}{" "}
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                  <TableRow>
                                                    <TableCell
                                                      align="left"
                                                      colSpan={6}
                                                      style={{
                                                        fontSize: "18px",
                                                        fontWeight: "500",
                                                      }}
                                                    >
                                                      International
                                                    </TableCell>
                                                  </TableRow>
                                                  {Object.entries(
                                                    data?.internationalCardBreakDown ||
                                                    {}
                                                  ).length > 0 ? (
                                                    Object.entries(
                                                      data?.internationalCardBreakDown ||
                                                      {}
                                                    ).map(
                                                      ([
                                                        cardType,
                                                        cardData,
                                                      ]) => (
                                                        <TableRow
                                                          key={cardType}
                                                        >
                                                          <TableCell align="center">
                                                            {cardType}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {cardData.count}
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              minWidth: "150px",
                                                            }}
                                                            align="center"
                                                          >
                                                            {cardData.gross}
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              minWidth: "150px",
                                                            }}
                                                            align="center"
                                                          >
                                                            {
                                                              cardData.merchantFee
                                                            }
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              minWidth: "150px",
                                                            }}
                                                            align="center"
                                                          >
                                                            {
                                                              cardData.transactionFee
                                                            }
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              minWidth: "150px",
                                                            }}
                                                            align="center"
                                                          >
                                                            {cardData.net}
                                                          </TableCell>
                                                        </TableRow>
                                                      )
                                                    )
                                                  ) : (
                                                    <TableRow>
                                                      <TableCell
                                                        colSpan={6}
                                                        align="center"
                                                        style={{
                                                          fontSize: "18px",
                                                        }}
                                                      >
                                                        No transactions
                                                        available
                                                      </TableCell>
                                                    </TableRow>
                                                  )}

                                                  {/* <TableRow >
                                                                                                        <TableCell align="center" >KIOSK {data?.kioskInternational}</TableCell>
                                                                                                        <TableCell align="center"  >{data?.kioskInternationalCount}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center" > {data?.kioskInternationalGrossSale}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.kioskInternationalMerchantFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.kioskInternationalTransactionFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center" >{data?.kioskInternationalNetSales}</TableCell>
                                                                                                    </TableRow>

                                                                                                    <TableRow >
                                                                                                        <TableCell align="center" > EPOS {data?.mposInternational}</TableCell>
                                                                                                        <TableCell align="center"  >{data?.eposInternationalCount}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.eposInternationalGrossSale}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.eposInternationalMerchantFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.eposInternationalTransactionFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center" >{data?.eposInternationalNetSales}</TableCell>
                                                                                                    </TableRow>

                                                                                                    <TableRow >
                                                                                                        <TableCell align="center" > MPOS {data?.mposInternational}</TableCell>
                                                                                                        <TableCell align="center"  >{data?.mposInternationalCount}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.mposInternationalGrossSale}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.mposInternationalMerchantFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.mposInternationalTransactionFee}</TableCell>
                                                                                                        <TableCell align="center" >{data?.mposInternationalNetSales}</TableCell>
                                                                                                    </TableRow>

                                                                                                    <TableRow >
                                                                                                        <TableCell align="center" > TERMINAL {data?.terminalInternational} </TableCell>
                                                                                                        <TableCell align="center"  >{data?.terminalInternationalCount}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.terminalInternationalGrossSale}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.terminalInternationalMerchantFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.terminalInternationalTransactionFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center" >{data?.terminalInternationalNetSales}</TableCell>
                                                                                                    </TableRow> */}

                                                  <TableRow>
                                                    <TableCell
                                                      align="left"
                                                      colSpan={6}
                                                      style={{
                                                        fontSize: "18px",
                                                        fontWeight: "500",
                                                      }}
                                                    >
                                                      Local
                                                    </TableCell>
                                                  </TableRow>
                                                  {Object.entries(
                                                    data?.localCardBreakDown ||
                                                    {}
                                                  ).length > 0 ? (
                                                    Object.entries(
                                                      data?.localCardBreakDown ||
                                                      {}
                                                    ).map(
                                                      ([
                                                        cardType,
                                                        cardData,
                                                      ]) => (
                                                        <TableRow
                                                          key={cardType}
                                                        >
                                                          <TableCell align="center">
                                                            {cardType}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {cardData.count}
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              minWidth: "150px",
                                                            }}
                                                            align="center"
                                                          >
                                                            {cardData.gross}
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              minWidth: "150px",
                                                            }}
                                                            align="center"
                                                          >
                                                            {
                                                              cardData.merchantFee
                                                            }
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              minWidth: "150px",
                                                            }}
                                                            align="center"
                                                          >
                                                            {
                                                              cardData.transactionFee
                                                            }
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              minWidth: "150px",
                                                            }}
                                                            align="center"
                                                          >
                                                            {cardData.net}
                                                          </TableCell>
                                                        </TableRow>
                                                      )
                                                    )
                                                  ) : (
                                                    <TableRow>
                                                      <TableCell
                                                        colSpan={6}
                                                        align="center"
                                                        style={{
                                                          fontSize: "18px",
                                                        }}
                                                      >
                                                        No transactions
                                                        available
                                                      </TableCell>
                                                    </TableRow>
                                                  )}

                                                  {/* <TableRow >
                                                                                                        <TableCell align="center" >KIOSK {data?.kioskLocal}</TableCell>
                                                                                                        <TableCell align="center"  >{data?.kioskLocalCount}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center" > {data?.kioskLocalGrossSale}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.kioskLocalMerchantFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.kioskLocalTransactionFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center" >{data?.kioskLocalNetSales}</TableCell>
                                                                                                    </TableRow>

                                                                                                    <TableRow >
                                                                                                        <TableCell align="center" > EPOS {data?.eposLocal}</TableCell>
                                                                                                        <TableCell align="center"  >{data?.eposLocalCount}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.eposLocalGrossSale}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.eposLocalMerchantFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.eposLocalTransactionFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center" >{data?.eposLocalNetSales}</TableCell>
                                                                                                    </TableRow>

                                                                                                    <TableRow >
                                                                                                        <TableCell align="center" > MPOS {data?.mposLocal}</TableCell>
                                                                                                        <TableCell align="center"  >{data?.mposLocalCount}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.mposLocalGrossSale}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.mposLocalMerchantFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.mposLocalTransactionFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center" >{data?.mposLocalNetSales}</TableCell>
                                                                                                    </TableRow>

                                                                                                    <TableRow >
                                                                                                        <TableCell align="center" > TERMINAL {data?.terminalLocal} </TableCell>
                                                                                                        <TableCell align="center"  >{data?.terminalLocalCount}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.terminalLocalGrossSale}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.terminalLocalMerchantFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.terminalLocalTransactionFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center" >{data?.terminalLocalNetSales}</TableCell>
                                                                                                    </TableRow> */}
                                                  <TableRow>
                                                    <TableCell
                                                      colSpan={6}
                                                      style={{
                                                        fontSize: "18px",
                                                        fontWeight: "500",
                                                      }}
                                                    >
                                                      Others
                                                    </TableCell>
                                                  </TableRow>
                                                  {Object.entries(
                                                    data?.othersCardBreakDown ||
                                                    {}
                                                  ).length > 0 ? (
                                                    Object.entries(
                                                      data?.othersCardBreakDown ||
                                                      {}
                                                    ).map(
                                                      ([
                                                        cardType,
                                                        cardData,
                                                      ]) => (
                                                        <TableRow
                                                          key={cardType}
                                                        >
                                                          <TableCell align="center">
                                                            {cardType}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {cardData.count}
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              minWidth: "150px",
                                                            }}
                                                            align="center"
                                                          >
                                                            {cardData.gross}
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              minWidth: "150px",
                                                            }}
                                                            align="center"
                                                          >
                                                            {
                                                              cardData.merchantFee
                                                            }
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              minWidth: "150px",
                                                            }}
                                                            align="center"
                                                          >
                                                            {
                                                              cardData.transactionFee
                                                            }
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              minWidth: "150px",
                                                            }}
                                                            align="center"
                                                          >
                                                            {cardData.net}
                                                          </TableCell>
                                                        </TableRow>
                                                      )
                                                    )
                                                  ) : (
                                                    <TableRow>
                                                      <TableCell
                                                        colSpan={6}
                                                        align="center"
                                                        style={{
                                                          fontSize: "18px",
                                                        }}
                                                      >
                                                        No transactions
                                                        available
                                                      </TableCell>
                                                    </TableRow>
                                                  )}

                                                  {/* 


                                                                                                    <TableRow >
                                                                                                        <TableCell align="center" >KIOSK {data?.kioskOthers}</TableCell>
                                                                                                        <TableCell align="center"  >{data?.kioskOthersCount}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center" > {data?.kioskOthersGrossSale}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.kioskOthersMerchantFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.kioskOthersTransactionFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center" >{data?.kioskOthersNetSales}</TableCell>
                                                                                                    </TableRow>

                                                                                                    <TableRow >
                                                                                                        <TableCell align="center" > EPOS {data?.eposOthers}</TableCell>
                                                                                                        <TableCell align="center"  >{data?.eposOthersCount}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.eposOthersGrossSale}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.eposOthersMerchantFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.eposOthersTransactionFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center" >{data?.eposOthersNetSales}</TableCell>
                                                                                                    </TableRow>

                                                                                                    <TableRow >
                                                                                                        <TableCell align="center" > MPOS {data?.mposOthers}</TableCell>
                                                                                                        <TableCell align="center"  >{data?.mposOthersCount}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.mposOthersGrossSale}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.mposOthersMerchantFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.mposOthersTransactionFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center" >{data?.mposOthersNetSales}</TableCell>
                                                                                                    </TableRow>

                                                                                                    <TableRow >
                                                                                                        <TableCell align="center" > TERMINAL {data?.terminalOthers} </TableCell>
                                                                                                        <TableCell align="center"  >{data?.terminalOthersCount}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.terminalOthersGrossSale}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.terminalOthersMerchantFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center"  >{data?.terminalOthersTransactionFee}</TableCell>
                                                                                                        <TableCell style={{ minWidth: '150px' }} align="center" >{data?.terminalOthersNetSales}</TableCell>
                                                                                                    </TableRow> */}

                                                  <TableRow>
                                                    <TableCell
                                                      align="left"
                                                      style={{
                                                        fontSize: "20px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <b> Total</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      <b>
                                                        {" "}
                                                        {data?.totalCardTranx}
                                                      </b>
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      <b> {data?.totalGross}</b>
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      <b>
                                                        {data?.totalMerchantFee}
                                                      </b>
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      <b>
                                                        {" "}
                                                        {data?.totalTrnxFee}
                                                      </b>
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                      align="center"
                                                    >
                                                      <b>
                                                        {data?.creditCardSale}
                                                      </b>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Grid>
                                        </Paper>
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                      <div className="ms-panel">
                        <div className="ms-panel-body">
                          <div className="col-md-12">
                            <Typography variant="h5" gutterBottom style={{}}>
                              Inventory of Unsold Items
                            </Typography>
                            <ItemList items={this.state.unSoldItems} />
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </AppBar>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Alert severity="warning">
            To access this feature please complete your account setup.
          </Alert>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
  settings: state.user.settings,
});

export default connect(mapStateToProps, {})(Content);
