import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from "./container/App/App";
import store from "./redux/store";
import * as APIUrl from "./services/APIUrl";
import * as serviceWorker from "./serviceWorker";
import { toast } from "react-toastify";
import "./assets/css/utils.css";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import ReactGA from 'react-ga';
import reportWebVitals from './reportWebVitals';
// import Routes from  '../src/container/_Routes'

let persistor = persistStore(store);
const history = createBrowserHistory();

axios.defaults.baseURL = APIUrl.APIBaseURL;

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
      config.headers["Access-Control-Allow-Origin"] = true;
      config.headers["username"] = localStorage.getItem("username") ? localStorage.getItem("username") : "";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    console.log("Error in axios interceptor :: ", error);
   /*  if(error?.message == "Network Error"){
      toast.error("Server is down.");
    } */
    
  /*   if (window.location.pathname === "/register") {
      toast.error(error.response.data.message);
    } */
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest.url ===
      process.env.REACT_APP_API_BASE_URL+"/business/authentication/renew-token"
    ) {
      if (window.location.pathname !== "/login" || window.location.pathname !== "/") {
        localStorage.clear();
        localStorage.removeItem("activeNavItem");
        Router.push("/");
      }
      // createBrowserHistory().push('/');
      return Promise.reject(error);
    }

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refresh_token");
      axios({
        method: "get",
        url: "/business/authentication/renew-token",
        headers: {
          RefreshToken: refreshToken,
        },
      })
        .then((response) => {
          if (response && response.data && response.data.status) {
            // console.log('Index.js response.data.data.access_token : ', response.data.data.access_token);
            // const res = 'Bearer ' + response.data.data.access_token;
            // localStorage.setItem('access_token', res)
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.data.access_token
            // window.location.reload();

            localStorage.removeItem("access_token");
            const res = "Bearer " + response.data.data.access_token;
            localStorage.setItem("access_token", res);
            window.location.reload();
            return response;
          } else {
            // toast.error('Your session has been ended, Please Relogin.')
            // Router.push('/');
            if (window.location.pathname !== "/" || window.location.pathname !== "/login") {
              localStorage.clear();
              localStorage.removeItem("activeNavItem");
              createBrowserHistory().push("/");
              window.location.reload();
            }
          
          }
        })
        .catch((error) => {
          console.log("Error==>", error);
          if (error.response) {
            console.log('Server responded with: ', error.response.status);
            toast.error('Server responded with: ' + error.response.status)
            if (error.response.status >= 500) {
              console.log('Server is down or encountered an error');
              toast.error('Server is down or encountered an error');
            }
          } else if (error.request) {
            console.log('No response received from the server');
            toast.error('No response received from the server');
          } else {
            console.log('Error: ', error.message);
            toast.error('Error: '+ error.message);
          }
          return Promise.reject(error);
        });
    }
  }
);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router forceRefresh={false} history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("costic")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);